import { FileExcelOutlined } from "@ant-design/icons";
import { Button } from "antd";
import moment from "moment";
import ReactExport from 'react-export-excel-xlsx-fix';
import { listaAreas } from "../FuncionesGenerales";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const TotalPagar = (item) => {
  if (item.afecta_detraccion === false) {
    return(item.total.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }))  
  } else {
    return((item.total *(1-(item.detraccion/100))).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
  }
  
}

const ContenidoExcelFacturas = (facturas) => {
  return [
    {
      ySteps: 1,
      columns: [
        { value: "FECHA" },
        { value: "CÓDIGO" },
        { value: "ORDEN COMPRA", widthPx: 120 },
        { value: "DOC." },
        { value: "SERIE" },
        { value: "N. SERIE" },
        { value: "PROVEEDOR", widthPx: 200 },
        { value: "RUC", widthPx: 150 },
        { value: "MODO PAGO", widthPx: 200 },
        { value: "ÁREA", widthPx: 200 },
        { value: "TOTAL", widthPx: 180 }
      ],
      data: facturas.map(factura => {
        return[
          {value: `${moment.utc(factura.fecha_emision).format("DD/MM/YYYY")}`,style: {
            fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } }
          }},
          {value: `${factura.codigo}`},
          {value: `${factura.numero_orden}`},
          {value: `${factura.tipo_documento}`},
          {value: `${factura.serie}`},
          {value: `${factura.numero_serie}`},
          {value: `${factura.descripcion}`},
          {value: `${factura.ruc?factura.ruc:'-'}`},
          {value: `${factura.tipo_pago}-${factura.forma_pago}`},
          {value: `${factura.id_consolidado === null?factura.nombre_area:listaAreas(factura.nombre_area)}`},
          {value: `${factura.Parc_Valor} ${TotalPagar(factura)}`}
        ]
      })
    }
  ]
}

export const ExcelFileFacturas = (facturasListFiltradas) => {
  return(
    <>
      <ExcelFile element={
        <Button className='bg-color-success' title="Exportar Excel" 
        // onClick={e => ExportarExcel()}
        icon={<FileExcelOutlined />}>EXCEL
        </Button>} filename="Facturas">
        <ExcelSheet dataSet={ContenidoExcelFacturas(facturasListFiltradas)} 
          name="Facturas"/>
      </ExcelFile>
    </>
  )
}