import { CloseOutlined, DeleteOutlined, DownloadOutlined, EyeOutlined, 
  PlusOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Upload,
  message, Table, Select } from 'antd';
import { Rule } from 'antd/lib/form';
import { Option } from 'antd/lib/mentions';
import {useState, useEffect} from "react";
import { DownloadFileArticulo, PreviewFile } from '../FuncionesGenerales';

const ModalArticuloFiles = (props) => {
  const [loadingButton, setLoadingButton] = useState(false)
  const [loadingTabla, setLoadingTabla] = useState(false)
  const [listaArchivosActual, setListaArchivosActual] = useState([])
  const [listaArchivosNuevos, setListaArchivosNuevos] = useState([])

  const optionFilter = (input, option) => {
    return (option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0)
  }

  useEffect(() => {
    if (props.openModal === true) {
      setListaArchivosNuevos([])
      GetArticuloFiles()
    } else {
      setListaArchivosActual([])
      setListaArchivosNuevos([])
    }
  }, [props.openModal]);

  const GetArticuloFiles = async() => {
    try {
      setLoadingTabla(true)
      await fetch(`${process.env.REACT_APP_HOSTNAME}/GetArticuloFiles/${props.articulo.id_articulo}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setListaArchivosNuevos([])
        if (data.message) {
          setListaArchivosActual([])
          setLoadingTabla(false)
        } else {
          setListaArchivosActual(data)
          setLoadingTabla(false)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleCloseModal = () => {
    props.setOpenModal(false)
    setListaArchivosActual([])
    setListaArchivosNuevos([])
  }

  const AddItem = () => {
    setListaArchivosNuevos([...listaArchivosNuevos,{
      ubicacion_archivo: null,
      descripcion: null,
      nombre: null,
      tipo: undefined
    }])
  }

  const RemoveItem = (item) => {
    const listaItems = listaArchivosNuevos.filter(detalle => {
      return(detalle !== item)
    })
    // console.log(listaItems)
    setListaArchivosNuevos(listaItems)
  }

  const detalleChange = (value, campo, item) => {
    const listaItems = listaArchivosNuevos
    const key = listaItems.indexOf(item)

    listaItems[key][campo] = value 

    setListaArchivosNuevos([...listaItems])
  }

  const subirArchivo = (file, item) => {
    // console.log(file.name)
    // console.log(file)
    const listaItems = listaArchivosNuevos
    const key = listaItems.indexOf(item)

    listaItems[key].ubicacion_archivo = [file]
    listaItems[key].nombre = file.name
    
    setListaArchivosNuevos([...listaItems])
    return false
  }

  const columnsActuales = [
    {
      title: 'NOMBRE',
      dataIndex: 'nombre'
    },
    {
      title: 'TIPO',
      dataIndex: 'tipo'
    },
    {
      title: 'DESCRIPCIÓN',
      dataIndex: 'descripcion'
    },
    {
      title:'',
      render: record => {
        return(<>
          <Button className='bg-color-success mr-1 ml-1' 
            title="Ver Archivo" 
            onClick={e => PreviewFile(record)}
            icon={<EyeOutlined />}
          />

          <Button type={'primary'} 
            title="Descargar Archivo" 
            onClick={e => DownloadFileArticulo(record)}
            icon={<DownloadOutlined />} 
          />  
        </>)
      }
    }
  ]

  const columnsNuevos = [
    {
      title: (<div style={{width:80}}></div>),
      render: record => {
        return(
          <>
            <Button size='small' danger title="Quitar" onClick={e=> RemoveItem(record)} icon={<DeleteOutlined />}/>  
          </>
        )
      }
    },
    {
      title: 'NOMBRE',
      render: record => {
        return(<>
          <p>{record.nombre}</p>
        </>)
      }
    },
    {
      title:(<div style={{width:200}}>TIPO</div>),
      render: record => {
        return(<>
          <Select
            value={record.tipo}
            className='mb-lg-0'
            placeholder='Seleccionar'
            optionFilterProp='children'
            filterOption={optionFilter}
            onChange={e=>detalleChange(e, 'tipo', record)}
          >
            <Option value={'MANUAL TÉCNICO'}key={'MANUAL TÉCNICO'}>MANUAL TÉCNICO</Option>
            <Option value={'MANUAL DE USUARIO'}key={'MANUAL DE USUARIO'}>MANUAL DE USUARIO</Option>
            <Option value={'CALIBRACIÓN'}key={'CALIBRACIÓN'}>CALIBRACIÓN</Option>
            <Option value={'MANTENIMIENTO'}key={'MANTENIMIENTO'}>MANTENIMIENTO</Option>
            <Option value={'OTRO'}key={'OTRO'}>OTRO</Option>
          </Select>
        </>)
      }
    },
    {
      title: 'DESCRIPCIÓN',
      render: record => {
        return(
          <>
            <Input name='descripcion' value = {record.descripcion}
              onChange={e=>detalleChange(e.currentTarget.value, 'descripcion', record)}
            />
          </>
        )
      }
    },
    {
      title:'',
      render: record => {
        return(
          <>
            <Upload fileList={[]}
              beforeUpload={ file => subirArchivo(file, record)}
            >
              <Button icon={<UploadOutlined />} title='Seleccionar archivo' size='small' type={'primary'} className='btn-outline'/>
            </Upload>
          </>
        )
      }
    }
  ]

  const UploadFilesArticulo = async() => {
    const body = {
      destination:'Logistica',
      id_articulo: props.articulo.id_articulo,
      lista: listaArchivosNuevos,
      varios: true
    }

    const validar = listaArchivosNuevos.filter(item => {
      return (item.descripcion === '' || item.descripcion === null || item.ubicacion_archivo === null)
    })

    if (validar.length > 0) {
      message.warn('faltan campos o archivos por subir')
    } else {
      const bodyForm = new FormData()
      bodyForm.append('tabla', JSON.stringify(body))
      // console.log(body)
      for (let i = 0; i < listaArchivosNuevos.length; i++) {
        const file = listaArchivosNuevos[i].ubicacion_archivo[0];
        const filename = file['name']
        bodyForm.append('File', file, `${body.id_articulo}_${filename}`)
      }

      try {
        setLoadingButton(true)
        await fetch(`${process.env.REACT_APP_HOSTNAME}/UploadFilesArticulo`,{
          method: "POST",
          body: bodyForm
        }).then(res => {
          message.success('Archivos Subidos')
          setLoadingButton(false)
          handleCloseModal()
        }).catch(err => {
          console.log(err)
          message.error('No se Pudo Subir el Archivo al Servidor')
          setLoadingButton(false)
        });
      } catch (error) {
        console.log(error)
        message.error('No se Pudo Subir el Archivo al Servidor')
      }  
    }
  }

  return(<>
    <Modal
      visible={props.openModal}
      closable={false}
      width='70%'
      title={<h3 className='m-0'>Lista de Archivos - {props.articulo.nombre}</h3>}
      onCancel={handleCloseModal}
      footer={
        <div className='modal-footer d-flex justify-content-between'>
          <Button danger onClick={handleCloseModal} icon={<CloseOutlined />}>
          Cerrar
          </Button>  
          
          <Button className='bg-color-success' loading={loadingButton}
            type='primary' icon={<SaveOutlined />} onClick={e => UploadFilesArticulo()}>
            Guardar
          </Button>
        </div>
      }
    >
      <div className='row'>
        <div className='col-sm-12 col-md-12 col-lg-12'>
          <Button type='primary' className='mb-2' onClick={AddItem} icon={<PlusOutlined />}>
            Agregar 
          </Button>
          <div className='elem-list'>
            {listaArchivosNuevos.length === 0?null:
              <Table
                columns={columnsNuevos}
                dataSource={listaArchivosNuevos}
                size='small'
                pagination={false}
              />
            }
          </div>

          <div className='elem-list'>
            {listaArchivosActual.length === 0?null:
              <Table
                loading={loadingTabla}
                columns={columnsActuales}
                dataSource={listaArchivosActual}
                size='small'
              /> 
            }
          </div>
        </div>
      </div>
    </Modal>
  </>)

}

export default ModalArticuloFiles