import { FileExcelOutlined } from "@ant-design/icons";
import { Button } from "antd";
import moment from "moment";
import ReactExport from 'react-export-excel-xlsx-fix';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ContenidoExcelVales = (vales) => {
  return [
    {
      columns: [
        {
          value: "FECHA"
        },
        {
          value: "VEHÍCULO",
          widthPx: 200
        },
        {
          value: "CONDUCTOR",
          widthPx: 200
        },
        {
          value: "COMBUSTIBLE",
          widthPx: 150
        },
        {
          value: "NÚM. VALE"
        },
        {
          value: "KILOMETRAJE"
        }
      ],
      data: vales.map(vale => {
        return [
          {value: `${moment(vale.fecha_vale).format('DD-MM-YYYY')}`},
          {value: `${vale.vehiculo}`},
          {value: `${vale.conductor}`},
          {value: `${vale.nombre}`},
          {value: `${vale.num_vale}`},
          {value: `${vale.kilometraje}`},
        ]
      })
    }
  ]
}

export const ExcelFileVales = (valesListFiltrados) => {
  return(
    <>
      <ExcelFile element={
        <Button className='bg-color-success' title="Exportar Excel" 
          icon={<FileExcelOutlined />}>EXCEL
        </Button>} filename="Vales">
        <ExcelSheet dataSet={ContenidoExcelVales(valesListFiltrados)} 
          name="Vales"/>
      </ExcelFile>
    </>
  )
}