import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Input, Modal, message } from 'antd';
import { useState, useEffect } from 'react';

const ModalComentarioCot = (props) => {
  const [comentario, setComentario] = useState('')
  const [loadingButton, setLoadingButton] = useState(false)

  useEffect(()=>{
    setComentario(props.cotizacion.comentario)
  },[props.openModal])

  const ComentarCotizacion = async() => {
    setLoadingButton(true)
    const body = { ...props.cotizacion, comentario: comentario } 
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/ComentarCotizacion`,{
        method: "PUT",
        body: JSON.stringify(body),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => {
        setLoadingButton(false)
        // props.handleCloseModal()
        props.closeModalComentario()
      }).catch(err => console.log(err));
    } catch (error) {
      setLoadingButton(false)
      console.log(error)
    }
  }

  return(<>
    <Modal
      visible={props.openModal}
      closable={false}
      width='50%'
      title={<h3 className='m-0'>{props.titulo}</h3>}
      onCancel={props.closeModalComentario}
      footer={
        <div className='modal-footer d-flex justify-content-between'>
          <Button danger onClick={props.closeModalComentario} icon={<CloseOutlined />}>
            Cerrar
          </Button>
          {props.titulo === 'Ver Comentario'?null:
          <Button className='bg-color-success' loading={loadingButton}
            type='primary' icon={<SaveOutlined />} onClick={ComentarCotizacion}>
            Guardar
          </Button>}
        </div>
      }
    >
      <div className='row'>
        <div className='col-sm-12 col-md-12 col-lg-12'>
          <div className='elem-list'>
            <div className='col-sm-11 col-md-11 col-lg-11'>
              <Input.TextArea value={comentario}
                onChange={e=>setComentario(e.currentTarget.value)}
                  placeholder = "Ingresar Comentario" readOnly = {props.titulo === 'Ver Comentario'?true:false}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </>)
}

export default ModalComentarioCot