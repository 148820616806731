import {useEffect, useRef, useState} from "react";
import { IPageData } from "../../../interfaces/page";
import { usePageData } from '../../../hooks/usePage';
import Cookies from "universal-cookie";
import moment from "moment";
import { Card, Table, Button, Select, Input, Form } from 'antd';
import { Option } from "antd/lib/mentions";
import { PlusOutlined, FilePdfOutlined, EditOutlined } from "@ant-design/icons";
import { existeRol } from "../FuncionesGenerales";
import ModalFormularioMovimiento from "./ModalFormularioMovimiento";
import { PdfFileMovimientos } from "../componentesPDF/GenerarPDFMovimientos";
import { ExcelFileMovimientos } from "../componentesExcel/GenerarExcelMovimientos";

const cookies = new Cookies()

const pageData: IPageData = {
  fulFilled: true,
  title: 'Movimientos',
  breadcrumbs: [
    {
      title: 'Movimientos',
    }
  ]
};

const Movimientos = () => {

  usePageData(pageData);
  const componentePDF = useRef(null)
  const [movimientosList, setMovimientosList] = useState([])
  const [areasList, setAreasList] = useState([])
  const [unidadesList, setUnidadesList] = useState([])
  const [almacenesList, setAlmacenesList] = useState([])
  const [transaccionesList, setTransaccionesList] = useState([])
  const [articulosList, setArticulosList] = useState([])
  const [fechaInicio, setFechaInicio] = useState('')
  const [fechaFin, setFechaFin] = useState(moment(new Date()).format("YYYY-MM-DD"))
  const [permisoGestionMovimientos, setPermisoGestionMovimientos] = useState(false)
  const [selectTipoMovimiento, setSelectTipoMovimiento] = useState(undefined)
  const [selectUnidad, setSelectUnidad] = useState(undefined)
  const [selectArticulo, setSelectArticulo] = useState(undefined)
  const [selectAlmacenOrigen, setSelectAlmacenOrigen] = useState(undefined)
  const [selectAlmacenDestino, setSelectAlmacenDestino] = useState(undefined)
  const [selectAreaOrigen, setSelectAreaOrigen] = useState(undefined)
  const [selectAreaDestino, setSelectAreaDestino] = useState(undefined)
  const [tituloModal, setTituloModal] = useState('')
  const [openModal, setOpenModal] = useState(false);
  const [movimiento, setMovimiento] = useState({
    id_movimiento: null,
    id_almacen_origen: null,
    id_almacen_destino: null,
    id_area_origen: null,
    id_area_destino: null,
    fecha_movimiento: moment.utc(new Date()).format('YYYY-MM-DD'),
    usuario: cookies.get('nameid_logis'),
    comentario: null,
    id_transaccion: null
  });
  const [loadingTable, setLoadingTable] = useState(false)
  const [detalleMovimiento, setDetalleMovimiento] = useState([])
  const [rubrosList, setRubrosList] = useState([])
  const [form] = Form.useForm();

  const resetForm = () => form.resetFields();
  
  const optionFilter = (input, option) =>
    option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;

  useEffect(() => {
    setPermisoGestionMovimientos(existeRol('Logistica.Gestion.Movimientos'))
    if (!cookies.get('nameid_logis') || existeRol('Logistica.Acceso.Movimientos') === false) {
      // history.push(`/public/sign-in`)
      window.location.href='/'    
    } else {
      getMovimientos()
      getAreas()
      getUnidades()
      getTransacciones()
      getAlmacenes()
      getArticulos()
      getRubros()
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getMovimientos()
      // getArticulos()
    }, 10000);
    return () => clearInterval(interval);
  }, [movimientosList])

  const getArticulos = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getArticulos`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setArticulosList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getRubros = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getRubros`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          setRubrosList([])
        } else {
          setRubrosList(data)
        }
        
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getMovimientos = async() => {
    setLoadingTable(true)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getMovimientos/${1}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setMovimientosList(data)
        setLoadingTable(false)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getAreas = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getAreas/${1}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setAreasList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getUnidades = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getUnidades`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setUnidadesList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getTransacciones = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getTransacciones`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setTransaccionesList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getAlmacenes = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getAlmacenes/${1}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setAlmacenesList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const columns: any = [
    {
      title: '',
      render: record => {
        return(
          <>
            {permisoGestionMovimientos === true && record.editable === true?
              <Button type='primary' 
                title="Editar" className="bg-color-orange"
                onClick={e=>handleOpenModal('Editar Movimiento', record.id_movimiento)}
              icon={<EditOutlined />}/>
            : null
            }
          </>
        )
      }
    },
    {
      title: (<><div style={{width:120}}>FECHA</div></>),
      dataIndex: 'fecha_creacion',
      // key: 'fecha_creacion',
      defaultSortOrder: 'descend',
      sorter: (a, b) => moment(a.fecha_creacion).unix() - moment(b.fecha_creacion).unix(),
      render: text => (
        moment.utc(text).format('DD/MM/YY HH:mm')
      )
    },
    {
      title:(
        <>
          <div style={{width:130}}>
            <Select
              allowClear
              showSearch
              defaultValue={selectTipoMovimiento}
              className='mb-lg-0'
              placeholder='TIPO MOV.'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={e=>setSelectTipoMovimiento(e)}
            >
              {transaccionesList.map(transaccion =>{
                return(<Option value={transaccion.id_transaccion} key={transaccion.id_transaccion}>{transaccion.tipo}</Option>)
              })}
            </Select>
          </div>
        </>
      ),
      dataIndex: 'tipo',
      key: 'tipo'
    },
    {
      title:'ORIGEN',
      render: record => {
        return(
          <>
            {record.id_almacen_origen !== null? record.nombre_almacen_origen: record.nombre_area_origen}
          </>
        )
      }
    },
    {
      title:'DESTINO',
      render: record => {
        return(
          <>
            {record.id_almacen_destino !== null? record.nombre_almacen_destino: record.nombre_area_destino}
          </>
        )
      }
    },
    {
      title:(
        <>
          <div style={{width:250}}>
            <Select
              allowClear
              showSearch
              defaultValue={selectArticulo}
              className='mb-lg-0'
              placeholder='ARTÍCULO'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={e=>setSelectArticulo(e)}
            >
              {articulosList.map(articulo =>{
                return(<Option value={articulo.id_articulo} key={articulo.id_articulo}>{articulo.nombre}</Option>)
              })}
            </Select>
          </div>
        </>
      ),
      dataIndex: 'nombre_articulo',
      key: 'nombre_articulo'
    },
    {
      title: (
        <>
          <b>DESCRIPCIÓN/</b><br/>
          <b>VALOR UNITARIO</b>
        </>
      ),
      dataIndex: 'descripcion',
      key: 'descripcion'
    },
    {
      title:(
        <>
          <div style={{width:150}}>
            <Select
              allowClear
              showSearch
              defaultValue={selectUnidad}
              className='mb-lg-0'
              placeholder='PRESENTACIÓN'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={e=>setSelectUnidad(e)}
            >
              {unidadesList.map(unidad =>{
                return(<Option value={unidad.id_unidad} key={unidad.id_unidad}>{unidad.nombre} X {unidad.cantidad_unidades}</Option>)
              })}
            </Select>
          </div>
        </>
      ),
      render: record => {
        return(
          <>{record.nombre_unidad} X {record.cantidad_unidades}</>
        )
      }
    },
    {
      title: 'CANTIDAD',
      dataIndex: 'cantidad',
      key: 'cantidad'
    },
    {
      title: 'COMENTARIO',
      dataIndex: 'comentario',
      key: 'comentario',
    },
    {
      title: 'USUARIO',
      dataIndex: 'nombre_usuario',
      key: 'nombre_usuario',
    }
  ]

  const handleOpenModal = async(titulo, id_movimiento) => {
    resetForm()
    setOpenModal(true)
    setTituloModal(titulo)

    if (id_movimiento === null) {
      setMovimiento({
        id_movimiento: null,
        id_almacen_origen: null,
        id_almacen_destino: null,
        id_area_origen: null,
        id_area_destino: null,
        fecha_movimiento: moment.utc(new Date()).format('YYYY-MM-DD'),
        usuario: cookies.get('nameid_logis'),
        comentario: null,
        id_transaccion: null
      })

      setDetalleMovimiento([])
    } else {
      // console.log(id_movimiento)
      try {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/getMovimientoById/${id_movimiento}`)
        .then(res => res.json())
        .then(data => {
          // console.log(data)
          setMovimiento({
            id_movimiento: data.id_movimiento,
            id_almacen_origen: data.id_almacen_origen,
            id_almacen_destino: data.id_almacen_destino,
            id_area_origen: data.id_area_origen,
            id_area_destino: data.id_area_destino,
            fecha_movimiento: moment.utc(data.fecha_movimiento).format('YYYY-MM-DD'),
            usuario: data.usuario,
            comentario: data.comentario,
            id_transaccion: data.id_transaccion
          })

          const item = articulosList.filter( articulo => {
            return( articulo.id_articulo === data.id_articulo)
          })

          setDetalleMovimiento([
            {
              id_articulo: data.id_articulo,
              id_unidad: data.id_unidad,
              cantidad: data.cantidad,
              descripcion: data.descripcion,
              tipo_cambio_unidad: data.tipo_cambio_unidad,
              stock_disponible: item[0].stock_disponible,
              cantidad_unidades: data.cantidad_unidades
            }
          ])
          
          form.setFieldsValue({
            id_almacen_origen: data.id_almacen_origen,
            id_almacen_destino: data.id_almacen_destino,
            id_area_origen: data.id_area_origen,
            id_area_destino: data.id_area_destino,
            fecha_movimiento: moment.utc(data.fecha_movimiento).format('YYYY-MM-DD'),
            comentario: data.comentario,
            id_transaccion: data.id_transaccion
          })
        })
      } catch (error) {
        console.log(error)
      }
    }
  }

  const movimientosListFiltrados = () => {
    const lista = movimientosList.filter(movimiento => {
      return( moment.utc(movimiento.fecha_movimiento).format("YYYY-MM-DD") >= fechaInicio && 
        moment.utc(movimiento.fecha_movimiento).format("YYYY-MM-DD") <= fechaFin &&
        (selectTipoMovimiento === undefined? 
          movimiento.id_transaccion !== selectTipoMovimiento: movimiento.id_transaccion === selectTipoMovimiento) &&
        (selectUnidad === undefined? movimiento.id_unidad !== selectUnidad: movimiento.id_unidad === selectUnidad) &&
        
        (selectAlmacenOrigen === undefined? 
          movimiento.id_almacen_origen !== selectAlmacenOrigen: movimiento.id_almacen_origen === selectAlmacenOrigen) &&
        (selectAreaOrigen === undefined? movimiento.id_area_origen !== selectAreaOrigen: movimiento.id_area_origen === selectAreaOrigen) &&  

        (selectAlmacenDestino === undefined?
          movimiento.id_almacen_destino !== selectAlmacenDestino: movimiento.id_almacen_destino === selectAlmacenDestino) &&
        (selectAreaDestino === undefined? movimiento.id_area_destino !== selectAreaDestino: movimiento.id_area_destino === selectAreaDestino) &&

        (selectArticulo === undefined? movimiento.id_articulo !== selectArticulo: movimiento.id_articulo === selectArticulo)
      )
    })

    return lista.slice(-100)
  }

  const ExportarPDF = () => {
    componentePDF.current.save()
  }

  if (movimientosList.length > 0) {
    return(
      <>
        {PdfFileMovimientos(componentePDF, movimientosListFiltrados(), fechaInicio, fechaFin)}
        <div className='row'>
          <div className='col-md-3 col-lg-3'>
            <label>Desde: </label> <br/> 
            <Input type="date"
              value={fechaInicio}
              onChange={e => setFechaInicio(e.currentTarget.value)}
            />
          </div>

          <div className='col-md-3 col-lg-3'>
            <label>Hasta: </label> <br/> 
            <Input type="date"
              value={fechaFin} 
              onChange={e => setFechaFin(e.currentTarget.value)}
            />  
          </div>

          <div className='elem-list mt-4'>
            {permisoGestionMovimientos === true?
              <>
                <Button type='primary'  
                  title="Nuevo Movimiento" 
                  onClick={e=>handleOpenModal('Nuevo Movimiento', null)}
                  icon={<PlusOutlined />} >Agregar
                </Button> 
                <Button danger 
                  title="Exportar PDF" 
                  onClick={e => ExportarPDF()}
                  icon={<FilePdfOutlined />} >PDF
                </Button>  
                {ExcelFileMovimientos(movimientosListFiltrados(), fechaInicio, fechaFin)}
              </>: null
            }
          </div>
        </div>

        <div className='row mt-2'>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <div className='elem-list'>
              <div className='col-sm-11 col-md-1 col-lg-1 mt-4'>
                <b>Origen:</b>
              </div>
              
              <div className='col-sm-5 col-md-4 col-lg-4'>
                <b>Almacén:</b>
                <Select
                  allowClear
                  showSearch
                  defaultValue={selectAlmacenOrigen}
                  className='mb-lg-0'
                  placeholder='--Seleccionar--'
                  optionFilterProp='children'
                  filterOption={optionFilter}
                  onChange={e => setSelectAlmacenOrigen(e)}
                >
                {almacenesList.map(almacen =>{
                  return(
                    <Option value={almacen.id_almacen} key={almacen.id_almacen}>{almacen.nombre}</Option>
                  )
                })}
                </Select>
              </div>

              <div className='col-sm-1 col-md-1 col-lg-1 mt-4'>
                <b>/</b>
              </div>

              <div className='col-sm-5 col-md-4 col-lg-4'>
                <b>Área:</b>
                <Select
                  allowClear
                  showSearch
                  defaultValue={selectAreaOrigen}
                  className='mb-lg-0'
                  placeholder='--Seleccionar--'
                  optionFilterProp='children'
                  filterOption={optionFilter}
                  onChange={e => setSelectAreaOrigen(e)}
                >
                {areasList.map(area =>{
                  return(
                    <Option value={area.id_area} key={area.id_area}>{area.nombre}</Option>
                  )
                })}
                </Select>
              </div>
            </div>
          </div>
        </div>

        <div className='row mt-2'>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <div className='elem-list'>
              <div className='col-sm-11 col-md-1 col-lg-1 mt-4'>
                <b>Destino:</b>
              </div>
              
              <div className='col-sm-5 col-md-4 col-lg-4'>
                <b>Almacén:</b>
                <Select
                  allowClear
                  showSearch
                  defaultValue={selectAlmacenDestino}
                  className='mb-lg-0'
                  placeholder='--Seleccionar--'
                  optionFilterProp='children'
                  filterOption={optionFilter}
                  onChange={e => setSelectAlmacenDestino(e)}
                >
                {almacenesList.map(almacen =>{
                  return(
                    <Option value={almacen.id_almacen} key={almacen.id_almacen}>{almacen.nombre}</Option>
                  )
                })}
                </Select>
              </div>

              <div className='col-sm-1 col-md-1 col-lg-1 mt-4'>
                <b>/</b>
              </div>

              <div className='col-sm-5 col-md-4 col-lg-4'>
                <b>Área:</b>
                <Select
                  allowClear
                  showSearch
                  defaultValue={selectAreaDestino}
                  className='mb-lg-0'
                  placeholder='--Seleccionar--'
                  optionFilterProp='children'
                  filterOption={optionFilter}
                  onChange={e => setSelectAreaDestino(e)}
                >
                {areasList.map(area =>{
                  return(
                    <Option value={area.id_area} key={area.id_area}>{area.nombre}</Option>
                  )
                })}
                </Select>
              </div>
            </div>
          </div>
        </div>

        <Card>
          <div>
            <Table
              // pagination={{ pageSize: 10 }}
              // loading = {loadingTable}
              columns={columns}
              dataSource={movimientosListFiltrados()/*.slice(-200)*/}
              size='small'
            />
          </div>

          {<ModalFormularioMovimiento
            movimiento = {movimiento}
            setMovimiento = {setMovimiento}
            transaccionesList = {transaccionesList}
            tituloModal = {tituloModal}
            openModal = {openModal}
            setOpenModal = {setOpenModal}
            detalleMovimiento = {detalleMovimiento}
            setDetalleMovimiento = {setDetalleMovimiento}
            almacenesList = {almacenesList}
            areasList = {areasList}
            articulosList = {articulosList}
            unidadesList = {unidadesList}
            getMovimientos = {getMovimientos}
            form = {form}
            getUnidades = {getUnidades}
            getArticulos = {getArticulos}
            getRubros = {getRubros}
            rubrosList = {rubrosList}
          />}
        </Card>
      </>
    )
  } else {
    return(
      <>
        <div className='row'>
          <div className='col-md-3 col-lg-3'>
            <label>Desde: </label> <br/> 
            <Input type="date"/*"datetime-local"*/
              value={fechaInicio}
              onChange={e => setFechaInicio(e.currentTarget.value)}
            />
          </div>

          <div className='col-md-3 col-lg-3'>
            <label>Hasta: </label> <br/> 
            <Input type="date"/*"datetime-local"*/
              value={fechaFin} 
              onChange={e => setFechaFin(e.currentTarget.value)}
            />  
          </div>

          <div className='elem-list mt-4'>
            {permisoGestionMovimientos === true?
              <Button type='primary'  
                title="Nuevo Movimiento" 
                onClick={e=>handleOpenModal('Nuevo Movimiento', null)}
                icon={<PlusOutlined />} >Agregar
              </Button>   
            : null
            }
          </div>
        </div>

        <div className='row mt-2'>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <div className='elem-list'>
              <div className='col-sm-11 col-md-1 col-lg-1 mt-4'>
                <b>Origen:</b>
              </div>
              
              <div className='col-sm-5 col-md-4 col-lg-4'>
                <b>Almacén:</b>
                <Select
                  allowClear
                  showSearch
                  defaultValue={selectAlmacenOrigen}
                  className='mb-lg-0'
                  placeholder='--Seleccionar--'
                  optionFilterProp='children'
                  filterOption={optionFilter}
                  onChange={e => setSelectAlmacenOrigen(e)}
                >
                {almacenesList.map(almacen =>{
                  return(
                    <Option value={almacen.id_almacen} key={almacen.id_almacen}>{almacen.nombre}</Option>
                  )
                })}
                </Select>
              </div>

              <div className='col-sm-1 col-md-1 col-lg-1'>
                <br/><br/>
                <b>/</b>
              </div>

              <div className='col-sm-5 col-md-4 col-lg-4'>
                <b>Área:</b>
                <Select
                  allowClear
                  showSearch
                  defaultValue={selectAreaOrigen}
                  className='mb-lg-0'
                  placeholder='--Seleccionar--'
                  optionFilterProp='children'
                  filterOption={optionFilter}
                  onChange={e => setSelectAreaOrigen(e)}
                >
                {areasList.map(area =>{
                  return(
                    <Option value={area.id_area} key={area.id_area}>{area.nombre}</Option>
                  )
                })}
                </Select>
              </div>
            </div>
          </div>
        </div>

        <div className='row mt-2'>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <div className='elem-list'>
              <div className='col-sm-11 col-md-1 col-lg-1 mt-4'>
                <b>Destino:</b>
              </div>
              
              <div className='col-sm-5 col-md-4 col-lg-4'>
                <b>Almacén:</b>
                <Select
                  allowClear
                  showSearch
                  defaultValue={selectAlmacenDestino}
                  className='mb-lg-0'
                  placeholder='--Seleccionar--'
                  optionFilterProp='children'
                  filterOption={optionFilter}
                  onChange={e => setSelectAlmacenDestino(e)}
                >
                {almacenesList.map(almacen =>{
                  return(
                    <Option value={almacen.id_almacen} key={almacen.id_almacen}>{almacen.nombre}</Option>
                  )
                })}
                </Select>
              </div>

              <div className='col-sm-1 col-md-1 col-lg-1'>
                <br/><br/>
                <b>/</b>
              </div>

              <div className='col-sm-5 col-md-4 col-lg-4'>
                <b>Área:</b>
                <Select
                  allowClear
                  showSearch
                  defaultValue={selectAreaDestino}
                  className='mb-lg-0'
                  placeholder='--Seleccionar--'
                  optionFilterProp='children'
                  filterOption={optionFilter}
                  onChange={e => setSelectAreaDestino(e)}
                >
                {areasList.map(area =>{
                  return(
                    <Option value={area.id_area} key={area.id_area}>{area.nombre}</Option>
                  )
                })}
                </Select>
              </div>
            </div>
          </div>
        </div>

        <Card>
          <div>
            No hay Datos
          </div>

          {<ModalFormularioMovimiento
            movimiento = {movimiento}
            setMovimiento = {setMovimiento}
            transaccionesList = {transaccionesList}
            tituloModal = {tituloModal}
            openModal = {openModal}
            setOpenModal = {setOpenModal}
            detalleMovimiento = {detalleMovimiento}
            setDetalleMovimiento = {setDetalleMovimiento}
            almacenesList = {almacenesList}
            areasList = {areasList}
            articulosList = {articulosList}
            unidadesList = {unidadesList}
            getMovimientos = {getMovimientos}
            form = {form}
            getUnidades = {getUnidades}
            getArticulos = {getArticulos}
            getRubros = {getRubros}
            rubrosList = {rubrosList}
          />}
        </Card>
      </>
    )
  }
  
}

export default Movimientos
