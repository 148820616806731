import { FileExcelOutlined } from "@ant-design/icons";
import { Button } from "antd";
import moment from "moment";
import ReactExport from 'react-export-excel-xlsx-fix';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ContenidoExcelPagos = (pagos, fechaInicio, fechaFin) => {
  return[
    {
      columns: [
        {
          value:`Pagos Desde:`,
          style:{font:{bold:true}},
          widthPx: 120
        },
        {
          value:`${moment(fechaInicio).format("DD/MM/YYYY")}`,
          widthPx: 120
        },
        { value:'', widthPx: 100},
        {
          value:`Hasta:`, widthPx: 150,
          style:{font:{bold:true}}
        },
        {
          value:moment(fechaFin).format("DD/MM/YYYY"),
          widthPx: 120
        },
        { value:'', widthPx: 150},
        { value:'', widthPx: 100},
        { value:'', widthPx: 120},
        { value:'', widthPx: 150},
        { value:'', widthPx: 150},
        { value:'', widthPx: 150},
        { value:''},
        { value:'', widthPx: 150}
      ],
      data:[]
    },
    {
      ySteps: 1,
      columns: [
        { value: "FECHA" },
        { value: "ORDEN COMPRA" },
        { value: "TIPO MOV." },
        { value: "PROVEEDOR" },
        { value: "RUC" },
        { value: "TIPO DOC." },
        { value: "NÚM. DOC." },
        { value: "TIPO PAGO" },
        { value: "FORMA PAGO" },
        { value: "CONCEPTO" },
        { value: "DETALLE CONCEPTO" },
        { value: "MONTO" },
        { value: "RESPONSABLE" }
      ],
      data: pagos.map(pago => {
        return[
          {value: `${moment.utc(pago.fecha_creacion).format("DD/MM/YYYY HH:mm")}`},
          {value: `${pago.numero_orden}`},
          {value: `${pago.tipo_operacion}`},
          {value: `${pago.descripcion}`},
          {value: `${pago.ruc?pago.ruc:'-'}`},
          {value: `${pago.tipo_documento?pago.tipo_documento:'-'}`},
          {value: `${pago.serie?pago.serie:''}-${pago.numero_serie?pago.numero_serie:''}`},
          {value: `${pago.tipo_pago?pago.tipo_pago:'-'}`},
          {value: `${pago.forma_pago?pago.forma_pago:'-'}`},
          {value: `${pago.nombre}`},
          {value: `${pago.detalle_concepto}`},
          {value: `${pago.Parc_Valor} ${pago.monto}`},
          {value: `${pago.responsable}`}
        ]
      })
    }
  ]
}

export const ExcelFilePagos = (pagosListFiltrados, fechaInicio, fechaFin) => {
  return(
    <>
      <ExcelFile element={
        <Button className='bg-color-success' title="Exportar Excel" 
        // onClick={e => ExportarExcel()}
        icon={<FileExcelOutlined />}>EXCEL
        </Button>} filename="Pagos">
        <ExcelSheet dataSet={ContenidoExcelPagos(pagosListFiltrados, fechaInicio, fechaFin)} 
          name="Pagos"/>
      </ExcelFile>
    </>
  )
}