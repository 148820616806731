import {PDFExport} from '@progress/kendo-react-pdf'
import LogoSvg from './../../../assets/img/LogoConFrase.PNG'
import "./styles.css";

const PageTemplate = (props) => {
  return (
    <div style={{ position: "absolute", bottom: "10px", left: "50%" }}>
      Pag. {props.pageNum} de {props.totalPages}
    </div>
  );
};

const getPrecio = (cot, item, detalle) => {
  const itemPrecio = detalle.filter(det => {
    return(det.id_articulo === item.id_articulo && det.id_unidad === item.id_unidad && det.id_cotizacion === cot.id_cotizacion)
  })

  if (itemPrecio.length === 0) {
    return ''
  } else {
    return (cot.Parc_Valor.concat(itemPrecio[0].precio_unitario))
  }
}

export const PdfFileComparativoCot = (componentePDF, cotizaciones, detalle, tipo) => {
  
  let lista = []
  for (let i = 0; i < detalle.length; i++) {
    const element = detalle[i];
    if (lista.length === 0) {
      lista = [{
        id_articulo: element.id_articulo,
        nombre_articulo: element.nombre_articulo,
        id_unidad: element.id_unidad,
        nombre_unidad: element.nombre_unidad,
        cantidad: element.cantidad,
        cantidad_unidades: element.cantidad_unidades
      }]
    } else {
      const itemRepetido = lista.filter(det => {
        return(det.id_articulo === element.id_articulo && det.id_unidad === element.id_unidad && det.cantidad === element.cantidad)
      })

      if (itemRepetido.length === 0) {
        lista = [...lista,
        {
          id_articulo: element.id_articulo,
          nombre_articulo: element.nombre_articulo,
          id_unidad: element.id_unidad,
          nombre_unidad: element.nombre_unidad,
          cantidad: element.cantidad,
          cantidad_unidades: element.cantidad_unidades
        }]
      }
    }
  }
  
  return(
    <>
      <div style={{ position: "absolute", left: "-4000px", top: 0 }}>
        <div className='row'>
          <PDFExport 
            ref={componentePDF} 
            paperSize='A4' 
            margin="1cm" 
            // landscape={true}
            pageTemplate={PageTemplate}
            fileName='Comparativo'
            scale={0.5}
          >
            <div className='d-flex justify-content-between mb-4'>
              <div>
                <img src={LogoSvg} style={{maxWidth:'620px', maxHeight:'120px'}}/>	
              </div>
            </div>

            <table className="table table-sm mt-2">
              <tbody>
                <tr>
                  <th rowSpan={3} style={{background: '#FF3333', color: '#FFFFFF', fontWeight: 'bold', textAlign: 'center'}}>
                    ITEMS
                  </th>
                  <th rowSpan={3} style={{background: '#FF3333', color: '#FFFFFF', fontWeight: 'bold', textAlign: 'center'}}>
                    CANTIDAD
                  </th>
                  <th colSpan={cotizaciones.length} style={{background: '#FF3333', color: '#FFFFFF', fontWeight: 'bold', textAlign: 'center'}}>
                    PROVEEDORES
                  </th>
                </tr>

                <tr>
                  {cotizaciones.map(cot => {
                    return(<th style={{background: '#FF3333', color: '#FFFFFF', fontWeight: 'bold', textAlign: 'center'}}>{cot.descripcion}</th>)
                  })}
                </tr>

                <tr>
                  <th colSpan={cotizaciones.length} style={{background: '#FF3333', color: '#FFFFFF', fontWeight: 'bold', textAlign: 'center'}}>
                    PRECIO
                  </th>
                </tr>

                {lista.map(item => {
                  return(
                    <tr>
                      <td>{item.nombre_articulo} - {item.nombre_unidad}X{item.cantidad_unidades}</td>
                      <td>{item.cantidad}</td>
                      {cotizaciones.map(cot => {
                        return(<td>{getPrecio(cot, item, detalle)}</td>)
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </PDFExport>
        </div>
      </div>
    </>
  )
}

