import { /*Avatar,*/ Dropdown, Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import Cookies from "universal-cookie";

const cookies = new Cookies()

const SettingsDropdown = () => {

  const logout = () => {
    cookies.remove('nameid_logis', {path: '/'})
    cookies.remove('unique_name_logis', {path: '/'})
    cookies.remove('role_logis', {path: '/'})
    window.localStorage.removeItem('permisos')
  }

  const accountMenu = (
    <Menu style={{ minWidth: '180px' }}>

      {/* <Menu.Item className='action-item' key='Ajustes'>
        <NavLink className='d-flex w-100' to='/vertical/settings'>
          <span className={`icon mr-3 icofont-ui-settings`} />
          <span className='text'>Ajustes</span>
        </NavLink>
      </Menu.Item> */}

      <Menu.Item className='action-item' key='Desconectar' onClick={() => logout()}>
        <NavLink className='d-flex w-100' to='/public/sign-in'>
          <span className={`icon mr-3 icofont-logout`} />
          <span className='text'>Desconectar</span>
        </NavLink>
      </Menu.Item>

    </Menu>
  );

  return (
    <Dropdown overlay={accountMenu} trigger={['click']} placement='bottomRight'>
      <div className='item'>
        {/* <Avatar
          size={40}
          className='mr-1'
          src={`${window.location.origin}/content/user-40-2.jpg`}
        /> */}
        <span className='icofont-simple-down' />
      </div>
    </Dropdown>
  );
};

export default SettingsDropdown;
