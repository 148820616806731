import { IRoute } from '../interfaces/routing';

import Requerimientos from '../pages/components/componentesRequerimientos/Requerimientos';
import Articulos from '../pages/components/componentesArticulos/Articulos';
import Inventarios from '../pages/components/componentesInventarios/Inventarios';
import OrdenesCompra from '../pages/components/componentesOrdenesCompras/OrdenesCompra';
import Pagos from '../pages/components/componentesPagos/Pagos';
import Movimientos from '../pages/components/componentesMovimientos/Movimientos';
import Proveedores from '../pages/components/componentesProveedores/Proveedores';
import Facturas from '../pages/components/componentesFacturas/Facturas';
import Responsables from '../pages/components/componentesResponsablesAreas/Responsables';
import Kardex from '../pages/components/componentesKardex/Kardex';
import Inicio from '../pages/components/componentesInicio/Inicio';
import ArticulosPorArea from '../pages/components/componentesArticulos/ArticulosPorArea';
import ControlCombustible from '../pages/components/componentesControlCombustible/ControlCombustible';
import OrdenesTrabajo from '../pages/components/componentesOrdenesTrabajo/OrdenesTrabajo';
import FormularioCorreosPacientes from '../pages/components/componentesCorreosPacientes/FormularioCorreosPacientes';
import Encargados from '../pages/components/componentesEncargadosResiduos/Encargados';
import Residuos from '../pages/components/componentesResiduosSolidos/Residuos';

export const defaultRoutes: IRoute[] = [
  {
    path: 'home',
    component: Inicio
  },
  {
    path: 'articulos',
    component: Articulos
  },
  {
    path: 'coordinadores',
    component: Responsables
  },
  {
    path: 'facturas',
    component: Facturas
  },
  {
    path: 'kardex',
    component: Kardex
  },
  {
    path: 'inventarios',
    component: Inventarios
  },
  {
    path: 'ordenestrabajo',
    component: OrdenesTrabajo
  },
  {
    path: 'movimientos',
    component: Movimientos
  },
  {
    path: 'ordenescompra',
    component: OrdenesCompra
  },
  {
    path: 'pagos',
    component: Pagos
  },
  {
    path: 'proveedores',
    component: Proveedores
  },
  {
    path: 'requerimientos',
    component: Requerimientos
  },
  {
    path: 'stock',
    component: ArticulosPorArea
  },
  {
    path: 'controlcombustible',
    component: ControlCombustible
  },
  {
    path: 'correos',
    component: FormularioCorreosPacientes
  },
  {
    path: 'encargadosresiduos',
    component: Encargados
  },
  {
    path: 'residuossolidos',
    component: Residuos
  }
];
