import { CloseOutlined, DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import {useState, useEffect} from "react";
import { DownloadFile, PreviewFile } from '../FuncionesGenerales';

const ModalOrdenTrabajoArchivos = (props) => {
  const [listaArchivos, setListaArchivos] = useState([])

  useEffect(() => {
    if (props.openModal === true) {
      setListaArchivos([])
      GetOrdenTrabajoFiles()
    } else {
      setListaArchivos([])
    }
  }, [props.openModal]);

  const GetOrdenTrabajoFiles = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/GetOrdenTrabajoFiles/${props.ordenTrabajo.id_orden_trabajo}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          setListaArchivos([])
        } else {
          setListaArchivos(data)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleCloseModal = () => {
    props.setOpenModal(false)
    setListaArchivos([])
    props.setOrdenTrabajo({
      id_orden_trabajo: null,
      id_requerimiento: null,
      id_consolidado: null,
      descripcion: null,
      persona_a_cargo: null,
      estado: null
    })
  }

  return(
    <>
      <Modal
        visible={props.openModal}
        closable={false}
        width='70%'
        title={<h3 className='m-0'>Lista de Archivos</h3>}
        onCancel={handleCloseModal}
        footer={
          <div className='modal-footer d-flex justify-content-between'>
            <Button danger onClick={handleCloseModal} icon={<CloseOutlined />}>
            Cerrar
            </Button>  
          </div>
        }
      >
        {listaArchivos.length > 0? 
        <div className='elem-list'>
        {listaArchivos.map((file, index) => {
          return(
            <>
              <div className='col-sm-2 col-md-2 col-lg-2'>
                <b>Archivo { index + 1 }:</b><br/>
                <Button className='bg-color-success mr-1 ml-1' 
                  title="Ver Archivo" 
                  onClick={e => PreviewFile(file)}
                  icon={<EyeOutlined />}
                />
                <Button type={'primary'} 
                  title="Descargar Archivo" 
                  onClick={e => DownloadFile(`OrdenTrabajo_${file.id_orden_trabajo}`, file, index)}
                  icon={<DownloadOutlined />} 
                />  
              </div>
            </>
          )
        })}
        </div>
        :<div>No hay Archivos</div>}
        
      </Modal>
    </>
  )
}

export default ModalOrdenTrabajoArchivos