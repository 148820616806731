import {PDFExport} from '@progress/kendo-react-pdf'
import { Table } from "antd";
import "./styles.css";

const columns = [
  {
    title: '',
    dataIndex: 'index',
    key: 'index',
    width: 50
  },
  {
    title: 'EQUIPO/MATERIAL',
    dataIndex: 'nombre_material',
    key: 'nombre_material',
    width: 500
  },
  // {
  //   title: 'DESCRIPCIÓN',
  //   dataIndex: 'descripcion',
  //   key: 'descripcion'
  // },
  // {
  //   title: 'MARCA',
  //   dataIndex: 'marca',
  //   key: 'marca'
  // },
  {
    title: 'PRESENTACIÓN',
    render: record => {
      return(<>{record.nombre_unidad} X {record.cantidad_unidades}</>)
    }
  },
  {
    title: 'CANTIDAD',
    dataIndex: 'cantidad',
    key: 'cantidad',
    align: 'right',
    width: 100
  },
  {
    title: 'T. UNID.',
    render: record => {
      return(<>{record.cantidad_unidades * record.cantidad}</>)
    },
    align: 'right',
    width: 100
  }
]

const PageTemplate = (props) => {
  return (
    <div style={{ position: "absolute", bottom: "10px", left: "50%" }}>
      Pag. {props.pageNum} de {props.totalPages}
    </div>
  );
};

const listaCodigos = (requConsolidados, requerimientosList) => {
  let codigos 
  for (let i = 0; i < requConsolidados.length; i++) {
    // const element = requConsolidados[i];
    const req = requerimientosList.filter(r => {
      return(r.id_requerimiento === requConsolidados[i])
    })
    if (i === 0) {
      codigos = req[0].codigo
    } else {
      codigos = codigos.concat(', ', req[0].codigo)
    }
  }
  // console.log(codigos)
  return codigos
}

const listaAreas = (requConsolidados, requerimientosList) => {
  let areas 
  for (let i = 0; i < requConsolidados.length; i++) {
    // const element = requConsolidados[i];
    const req = requerimientosList.filter(r => {
      return(r.id_requerimiento === requConsolidados[i])
    })
    if (i === 0) {
      areas = req[0].nombre_area
    } else {
      areas = areas.concat(', ', req[0].nombre_area)
    }
  }
  // console.log(areas)
  return areas
}

const listaResp = (requConsolidados, requerimientosList) => {
  let areas 
  for (let i = 0; i < requConsolidados.length; i++) {
    // const element = requConsolidados[i];
    const req = requerimientosList.filter(r => {
      return(r.id_requerimiento === requConsolidados[i])
    })
    if (i === 0) {
      areas = req[0].nombre_responsable
    } else {
      areas = areas.concat(', ', req[0].nombre_responsable)
    }
  }
  // console.log(areas)
  return areas
}

export const PdfFileItemsConsolidado = (componentePDF, detalle, requConsolidados, requerimientosList) => {
  // listaCodigos(requConsolidados, requerimientosList)
  return(
    <>
			<div style={{ position: "absolute", left: "-4000px", top: 0 }} >
      <PDFExport 
					ref={componentePDF} 
					paperSize='A4'
					margin="1cm" 
					// landscape={true}
					pageTemplate={PageTemplate}
					fileName={`Items`}
					scale={0.5}
				>
          <div className="mb-2"><b>REQUERIMIENTOS: {listaCodigos(requConsolidados, requerimientosList)}</b></div>
          <div className="mb-2"><b>ÁREAS: {listaAreas(requConsolidados, requerimientosList)}</b></div>
          <div className="mb-2"><b>SOLICITANTES: {listaResp(requConsolidados, requerimientosList)}</b></div>

          <Table
            pagination={false}
            columns={columns}
            dataSource={detalle}
            size='small'
          />
        </PDFExport>
      </div>
    </>
  )
}