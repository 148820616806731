import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, message } from 'antd';
import { Rule } from 'antd/lib/form';
import {useState, useEffect} from "react";

const { Item } = Form;

const rules = {
  required: { required: true, message: <></> } as Rule
};

const ModalVehiculo = (props) => {
  const [loadingButton, setLoadingButton] = useState(false)
  const [vehiculo, setVehiculo] = useState({
    id_vehiculo: null,
    nombre: null,
    placa: null
  })

  useEffect(() => {
    if (props.openModal === true) {
      // setVehiculo(props.vehiculo)
      GetVehiculoById()
    } else {
      setVehiculo({
        id_vehiculo: null,
        nombre: null,
        placa: null
      })
    }
  }, [props.vehiculo]);

  const handleCloseModal = () => {
    props.setOpenModal(false)
    setVehiculo({
      id_vehiculo: null,
      nombre: null,
      placa: null
    })
  }

  const GetVehiculoById = async () => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/GetVehiculoById/${props.vehiculo.id_vehiculo}`)
      .then(res => res.json())
      .then(data => {
        setVehiculo(data)
        props.form.setFieldsValue({
          nombre: data.nombre,
          placa: data.placa
        })
      })
    } catch (error) {
      console.log(error)
    }
  }

  const AddVehiculo = async () => {
    setLoadingButton(true)
    const newVehiculo = {...vehiculo, usuario: props.usuario}
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/AddVehiculo`,{
        method: "POST",
        body: JSON.stringify(newVehiculo),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data => {
        message.success(data.message)
        setLoadingButton(false)
        props.GetVehiculos()
        handleCloseModal()
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error)
    }
  }

  const EditVehiculo = async () => {
    setLoadingButton(true)
    // console.log(vehiculo)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/UpdateVehiculo`,{
        method: "PUT",
        body: JSON.stringify(vehiculo),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data => {
        message.success(data.message)
        setLoadingButton(false)
        props.GetVehiculos()
        handleCloseModal()
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error)
    }
  }

  return(
    <>
      <Modal
        visible={props.openModal}
        closable={false}
        width='80%'
        title={<h3 className='m-0'>{props.tituloModal}</h3>}
        onCancel={handleCloseModal}
        footer={null}
      >
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <Form form={props.form} layout='vertical' onFinish={ props.tituloModal === 'Nuevo Vehículo'? AddVehiculo : EditVehiculo}>
              <div className='elem-list'>
                <div className='col-sm-11 col-md-4 col-lg-4'>
                  <Item label="Nombre" name="nombre" rules ={[
                    {...rules.required, message: 'El Nombre es Requerido'}
                  ]}>
                    <Input defaultValue={vehiculo.nombre} 
                      onChange={e=>setVehiculo({
                        ...vehiculo, nombre:e.currentTarget.value
                      })}
                       placeholder = "Nombre" autoFocus
                    />
                  </Item>
                </div>

                <div className='col-sm-11 col-md-4 col-lg-4'>
                  <Item label="Placa" name="placa" rules ={[
                    {...rules.required, message: 'La Placa es Requerida'}
                  ]}>
                    <Input defaultValue={vehiculo.placa} 
                      onChange={e=>setVehiculo({
                        ...vehiculo, placa:e.currentTarget.value
                      })}
                       placeholder = "Placa"
                    />
                  </Item>
                </div>
              </div>

              <div className='modal-footer d-flex justify-content-between'>
                <Button danger onClick={handleCloseModal} icon={<CloseOutlined />}>
                  Cerrar
                </Button>
                
                <Button className='bg-color-success' loading={loadingButton}
                  type='primary' icon={<SaveOutlined />} htmlType="submit">
                  Guardar
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModalVehiculo