import { CloseOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, 
  Select, message } from 'antd';
import { Rule } from 'antd/lib/form';
import { Option } from 'antd/lib/mentions';
import {useState, useEffect} from "react";
import ModalRubroForm from '../componentesRubros/ModalRubroForm';

const { Item } = Form;

const rules = {
  required: { required: true, message: <></> } as Rule
};

const ModalArticulo = (props) => {
  const [loadingButton, setLoadingButton] = useState(false)
  const [openModalRubro, setOpenModalRubro] = useState(false)
  const [articulo, setArticulo] = useState({
    id_articulo: null,
    nombre: null,
    id_rubro: null,
    ean: null,
    identificador: null
  })

  const optionFilter = (input, option) => {
    return (option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0)
  }

  useEffect(() => {
    if (props.openModal === true) {
      // console.log(props.articulo)
      setArticulo(props.articulo)
    } else {
      setArticulo({
        id_articulo: null,
        nombre: null,
        id_rubro: null,
        ean: null,
        identificador: null
      })
    }
  }, [props.articulo]);

  const handleCloseModal = () => {
    props.setOpenModal(false)
    setArticulo({
      id_articulo: null,
      nombre: null,
      id_rubro: null,
      ean: null,
      identificador: null
    })
  }

  const AddArticulo = async () => {
    setLoadingButton(true)
    const newArticulo = {...articulo, usuario: props.usuario}
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/AddArticulo`,{
        method: "POST",
        body: JSON.stringify(newArticulo),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => {
        message.success("Artículo Registrado")
        setLoadingButton(false)
        props.getArticulos()
        handleCloseModal()
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error)
    }
  }

  const handleOpenModalRubro = () => {
    setOpenModalRubro(true)
  }

  const EditArticulo = async () => {
    setLoadingButton(true)
    // console.log(articulo)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/UpdateArticulo`,{
        method: "PUT",
        body: JSON.stringify(articulo),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => {
        message.success("Artículo Actualizado")
        setLoadingButton(false)
        props.getArticulos()
        handleCloseModal()
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error)
    }
  }

  return(
    <>
      <Modal
        visible={props.openModal}
        closable={false}
        width='80%'
        title={<h3 className='m-0'>{props.tituloModal}</h3>}
        onCancel={handleCloseModal}
        footer={null}
      >
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <Form form={props.form} layout='vertical' onFinish={ props.tituloModal === 'Nuevo Artículo'? AddArticulo : EditArticulo}>
              <div className='elem-list'>
                <div className='col-sm-11 col-md-3 col-lg-3'>
                  <Item label="Identificador" name="identificador">
                    <Input defaultValue={articulo.identificador} 
                      onChange={e=>setArticulo({
                        ...articulo, identificador:e.currentTarget.value
                      })}
                       placeholder = "Identificador"
                    />
                  </Item>
                </div>
                
                <div className='col-sm-11 col-md-4 col-lg-4'>
                  <Item label="Nombre" name="nombre" rules ={[
                    {...rules.required, message: 'El Nombre es Requerido'}
                  ]}>
                    <Input defaultValue={articulo.nombre} 
                      onChange={e=>setArticulo({
                        ...articulo, nombre:e.currentTarget.value
                      })}
                       placeholder = "Nombre" autoFocus
                    />
                  </Item>
                </div>

                <div className='col-sm-11 col-md-3 col-lg-3'>
                  <Item label="Rubro" name="id_rubro" rules={[
                    {...rules.required, message: 'El Rubro es Requerido'}
                  ]}>
                    <Select
                      showSearch
                      defaultValue={articulo.id_rubro}
                      className='mb-lg-0'
                      placeholder='Seleccionar Rubro'
                      optionFilterProp='children'
                      filterOption={optionFilter}
                      onChange={e=>setArticulo({
                        ...articulo, id_rubro: e
                      })}
                    >
                    {props.rubrosList.map(rubro =>{
                      return(
                        <Option value={rubro.id_rubro} key={rubro.id_rubro}>{rubro.nombre}</Option>
                      )
                    })}
                    </Select>
                  </Item>  
                </div>
                
                <div className='col-sm-2 col-md-1 col-lg-1 mt-3'>
                  <br/>
                  <Button type='primary' title="Agregar Rubro" onClick={e => handleOpenModalRubro()} 
                    icon={<PlusOutlined />}/>
                </div>
                {/* <div className='col-sm-11 col-md-4 col-lg-4'>
                  <Item label="Cód. Barras" name="ean">
                    <Input defaultValue={articulo.ean} 
                      onChange={e=>setArticulo({
                        ...articulo, ean:e.currentTarget.value
                      })}
                      placeholder = "cod. barras"
                    />
                  </Item>
                </div> */}
              </div>

              <div className='modal-footer d-flex justify-content-between'>
                <Button danger onClick={handleCloseModal} icon={<CloseOutlined />}>
                  Cerrar
                </Button>
                
                <Button className='bg-color-success' loading={loadingButton}
                  type='primary' icon={<SaveOutlined />} htmlType="submit">
                  Guardar
                </Button>
              </div>
            </Form>
          </div>
        </div>

        {<ModalRubroForm
          openModalRubro = {openModalRubro}
          setOpenModalRubro = {setOpenModalRubro}
          usuario = {props.usuario}
          rubrosList = {props.rubrosList}
          getRubros = {props.getRubros}
        />}
      </Modal>
    </>
  )
}

export default ModalArticulo