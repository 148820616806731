import {useEffect, useState} from "react"
import { IPageData } from "../../../interfaces/page"
import { usePageData } from '../../../hooks/usePage'
import Cookies from "universal-cookie"
import { Card, Table, Button, Select, Input, Popconfirm, message } from 'antd'
import { Option } from "antd/lib/mentions"
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons"
import { existeRol, listaAreas, listaSucursales } from "../FuncionesGenerales"
import ModalEncargado from "./ModalFormEncargado"

const cookies = new Cookies()

const pageData: IPageData = {
  fulFilled: true,
  title: 'Encargados de los Residuos Sólidos',
  breadcrumbs: [
    {
      title: 'Encargados de los Residuos Sólidos',
    }
  ]
}

const Encargados = () => {
  usePageData(pageData)
  const [encargadosList, setEncargadosList] = useState([])
  const [areasList, setAreasList] = useState([])
  const [sucursalesList, setSucursalesList] = useState([])
  const [selectArea, setSelectArea] = useState(undefined)
  const [selectSucursal, setSelectSucursal] = useState(undefined)
  const [openModal, setOpenModal] = useState(false)
  const [inputNombre, setInputNombre] = useState('')
  const [inputDNI, setInputDNI] = useState('')
  const [idEncargado, setIdEncargado] = useState(null)
  const [loadingTable, setLoadingTable] = useState(false)

  const optionFilter = (input, option) =>
    option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;

  useEffect(() => {
    if (!cookies.get('nameid_logis') || existeRol('Logistica.Acceso.EncargadoResiduos') === false) {
      // history.push(`/public/sign-in`)
      window.location.href='/'    
    } else {
      GetEncargadosResiduos()
      getAreas()
      getSucursales()
    }
  }, []);

  const getAreas = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getAllAreas`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setAreasList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getSucursales = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getSucursales`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setSucursalesList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const GetEncargadosResiduos = async() => {
    setLoadingTable(true)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/GetEncargadosResiduos`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          setEncargadosList([])
        } else {
          setEncargadosList(data)
        }
        setLoadingTable(false)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const DarDeBajaEncargado = async (encargado) => {
    // console.log(props.encargado)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/DarDeBajaEncargado`,{
        method: "PUT",
        body: JSON.stringify(encargado),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data =>{
        message.success(data.mensaje)
        GetEncargadosResiduos()
      }).catch(err => {
        console.log(err)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const columns = [
    {
      title: '',
      render: record => {
        return(
          <>
            <Button type='primary' className="bg-color-orange mr-2"
              title="Editar" 
              onClick={e=>handleOpenModal(record.id_encargado)}
            icon={<EditOutlined />} />

            <Popconfirm
              title={`Dar de Baja a Este Personal`}
              onConfirm={e=>DarDeBajaEncargado(record)} 
              okText="Si" cancelText="No"
              >
              <Button danger
                title="Dar de Baja"
                icon={<DeleteOutlined />}
              /> 
            </Popconfirm>
          </>
        )
      }
    },
    {
      title: (
        <>
          <div style={{width:200}}>
            <Input value={inputNombre} 
              onChange={e=>setInputNombre(e.currentTarget.value)}
                placeholder = "PERSONAL"
            />
          </div>
        </>
      ),
      dataIndex: 'nombres',
      key: 'nombres'
    },
    {
      title: (
        <>
          <div style={{width:200}}>
            <Input value={inputDNI} 
              onChange={e=>setInputDNI(e.currentTarget.value)}
                placeholder = "DNI"
            />
          </div>
        </>
      ),
      dataIndex: 'DocumentoIdentidad',
      key: 'DocumentoIdentidad'
    },
    {
      title: (
        <>
          <div style={{width:250}}>
            <Select
              showSearch
              allowClear
              value={selectArea}
              className='mr-2'
              placeholder='ÁREA'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={e => setSelectArea(e)}
            >
              {areasList.map(area =>{
                return(
                  <Option value={area.nombre} key={area.nombre.concat(' - ',area.nombre_sucursal)}>{area.nombre} - {area.nombre_sucursal}</Option>
                )
              })}
            </Select>  
          </div>
        </>
      ),
      render: record => {
          return(<>{listaAreas(record.nombre_area)}</>)  
      }
    },
    {
      title: (
        <>
          <div style={{width:250}}>
          <Select
              showSearch
              allowClear
              value={selectSucursal}
              className='mr-2'
              placeholder='SEDE'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={e => setSelectSucursal(e)}
            >
              {sucursalesList.map(sucursal =>{
                return(
                  <Option value={sucursal.nombre} key={sucursal.nombre}>{sucursal.nombre}</Option>
                )
              })}
            </Select>  
          </div>
        </>
      ),
      render: record => {
          return(<>{listaSucursales(record.nombre_sucursales)}</>)  
      }
    }
  ]

  const EncargadosListFiltrados = () => {
    const lista = encargadosList.filter(enc => {
      return(
        enc.nombres.toUpperCase().includes(inputNombre.toUpperCase()) &&
        enc.DocumentoIdentidad.includes(inputDNI) &&
        (selectArea === undefined? enc.nombre_area !== selectArea: enc.nombre_area.includes(selectArea)) &&
        (selectSucursal === undefined? enc.nombre_sucursales !== selectSucursal: enc.nombre_sucursales.includes(selectSucursal))
      )
    })

    return(lista)
  }

  const handleOpenModal = async(id_encargado) => {
    setOpenModal(true)
    setIdEncargado(id_encargado)
  }

  return(
    <>
      <div className='row'>
        <div className='elem-list ml-2'>
          <Button type='primary'  
            title="Nuevo Personal Encargado" 
            onClick={e=>handleOpenModal(null)}
            icon={<PlusOutlined />} >Agregar
          </Button>  
        </div>
      </div>

      <Card>
        <div>
          <Table
            loading = {loadingTable}
            columns={columns}
            dataSource={EncargadosListFiltrados()}
            size='small'
          />
        </div>
      </Card>

      {<ModalEncargado
        idEncargado = {idEncargado}
        setIdEncargado = {setIdEncargado}
        usuario = {cookies.get('nameid_logis')}
        openModal = {openModal}
        areasList = {areasList}
        setOpenModal = {setOpenModal}
        GetEncargadosResiduos = {GetEncargadosResiduos}
      />}
    </>
  )
  
}

export default Encargados