import { FileExcelOutlined } from "@ant-design/icons";
import { Button } from "antd";
import ReactExport from 'react-export-excel-xlsx-fix';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ContenidoExcelProveedores = (proveedores) => {
  return[
    {
      columns: [
        {
          value: "CÓDIGO"
        },
        {
          value: "RAZÓN SOCIAL",
          widthPx: 250
        },
        {
          value: "RUC",
          widthPx: 120
        },
        {
          value: "RUBRO",
          widthPx: 100
        },
        {
          value: "DIRECCIÓN",
          widthPx: 200
        },
        {
          value: "DEPARTAMENTO"
        },
        {
          value: "UBIGEO"
        },
        {
          value: "REPRESENTANTE",
          widthPx: 100
        },
        {
          value: "TELÉFONO"
        },
        {
          value: "CELULAR"
        },
        {
          value: "EMAIL"
        }
      ],
      data: proveedores.map(articulo => {
        return[
          {value: `${articulo.codigo}`},
          {value: `${articulo.descripcion}`},
          {value: `${articulo.num_ruc}`},
          {value: `${articulo.nombre_rubro === undefined?'':articulo.nombre_rubro}`},
          {value: `${articulo.direccion === null?'':articulo.direccion}`},
          {value: `${articulo.departamento === null?'':articulo.departamento}`},
          {value: `${articulo.ubigeo === null?'':articulo.ubigeo}`},
          {value: `${articulo.representante}`},
          {value: `${articulo.telefono === null?'':articulo.telefono}`},
          {value: `${articulo.celular === null?'':articulo.celular}`},
          {value: `${articulo.email === null?'':articulo.email}`}
        ]
      })
    }
  ]
}

export const ExcelFileProveedores = (proveedoresFiltrados) => {
  return(
    <>
      <ExcelFile element={
        <Button className='bg-color-success' title="Exportar Excel" 
        icon={<FileExcelOutlined />}>EXCEL
        </Button>} filename="Proveedores">
        <ExcelSheet dataSet={ContenidoExcelProveedores(proveedoresFiltrados)} 
          name="Proveedores"/>
      </ExcelFile>
    </>
  )
}