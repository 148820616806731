export const DEFAULT_SETTINGS = {
  boxed: true,
  loaded: false,
  layout: 'vertical',// horizontal | vertical
  sidebarBg: '#2c343b',// string, color del fondo del menu
  sidebarColor: '#ffffff',// string, color de los links del menu
  sidebarOpened: false,// boolean
  topbarBg: '#ffffff',// string
  topbarColor: '#1F2022'// string
};
