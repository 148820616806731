import { FileExcelOutlined } from "@ant-design/icons";
import { Button } from "antd";
import moment from "moment";
import ReactExport from 'react-export-excel-xlsx-fix';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ContenidoExcelOrdenesCompra = (ordenes, fechaInicio, fechaFin) => {
  return[
    {
      columns: [
        {
          value:'',//`Órdenes de Compra Desde:`,
          style:{font:{bold:true}},
          widthPx: 140
        },
        {
          value:/*`${moment(fechaInicio).format("DD/MM/YYYY")}`*/'',
          widthPx: 150
        },
        { value:'',widthPx: 200},
        {
          value:/*`Hasta:`*/'',widthPx: 100,
          style:{font:{bold:true}}
        },
        {value:''},//moment(fechaFin).format("DD/MM/YYYY"),
        { value:'', widthPx: 150},
        { value:'', widthPx: 150}
      ],
      data:[]
    },
    {
      ySteps: 1,
      columns: [
        { value: "FECHA" },
        { value: "NÚM. ORDEN" },
        { value: "PROVEEDOR" },
        { value: "RUC" },
        { value: "TIPO DOC." },
        { value: "TOTAL" },
        { value: "PAGO" },
        { value: "ESTADO" }
      ],
      data: ordenes.map(orden => {
        return[
          {value: `${moment.utc(orden.fecha_orden_compra).format("DD/MM/YYYY")}`},
          {value: `${orden.numero_orden}`},
          {value: `${orden.descripcion}`},
          {value: `${orden.ruc?orden.ruc:'-'}`},
          {value: `${orden.tipo_documento?orden.tipo_documento:'-'}`},
          {value: `${orden.Parc_Valor} ${orden.total}`},
          {value: `${orden.tipo_pago}-${orden.forma_pago}`},
          {value: `${orden.estado}`}
        ]
      })
    }
  ]
}

export const ExcelFileOrdenesCompra = (ordenesCompraListFiltradas, fechaInicio, fechaFin) => {
  return(
    <>
      <ExcelFile element={
        <Button className='bg-color-success' title="Exportar Excel" 
        // onClick={e => ExportarExcel()}
        icon={<FileExcelOutlined />}>EXCEL
        </Button>} filename="Órdenes de Compra">
        <ExcelSheet dataSet={ContenidoExcelOrdenesCompra(ordenesCompraListFiltradas, fechaInicio, fechaFin)} 
          name="Órdenes de Compra"/>
      </ExcelFile>
    </>
  )
}