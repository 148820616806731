import { CloseOutlined, FilePdfOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Input, Modal, message, Form, Table, Checkbox } from 'antd';
import { useEffect, useRef, useState } from 'react';
import moment from "moment";
import { Rule } from 'antd/lib/form';
import { getTipoCambioToday, listaAreas, listaCodigos, listaResp } from '../FuncionesGenerales';
import ModalTipoCambio from '../ModalTipoCambio';

const { Item } = Form;

const rules = {
  required: { required: true, message: <></> } as Rule
};

const ModalFormularioFactura = (props) => {
  const [openModalTipoCambio, setOpenModalTipoCambio] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const input = useRef<HTMLInputElement>(null);
  const inputOC = useRef<HTMLInputElement>(null);
  const inputXML = useRef<HTMLInputElement>(null);

  const handleClick = () => input.current.click();
  const handleClickOC = () => inputOC.current.click();
  const handleClickXML = () => inputXML.current.click();

  const [factura, setFactura] = useState({
    id_orden_compra: null,
    fecha_emision: moment.utc(new Date()).format('YYYY-MM-DD'),
    fecha_vencimiento: moment.utc(new Date()).format('YYYY-MM-DD'),
    serie: null,
    numero_serie: null,
    codigo: null,
    detraccion: null,
    afecta_detraccion: false,
    usuario: props.usuario,
    ubicacion_archivo: null,
    TipCambio: 1,
    total: null,
    resumen: '',
    ubicacion_archivo_xml: null
  });
  const [form] = Form.useForm();

  const handleCloseModal = () => {
    props.setOpenModal(false)
    props.setIdFactura(null)
    props.setEstadoOrden(null)
    props.setDetOrdenesCompraList([])
    setFactura({
      id_orden_compra: null,
      fecha_emision: moment.utc(new Date()).format('YYYY-MM-DD'),
      fecha_vencimiento: moment.utc(new Date()).format('YYYY-MM-DD'),
      serie: null,
      numero_serie: null,
      codigo: null,
      detraccion: null,
      afecta_detraccion: false,
      usuario: props.usuario,
      ubicacion_archivo: null,
      TipCambio: 1,
      total: null,
      resumen: '',
      ubicacion_archivo_xml: null
    })
  }

  useEffect(() => {
    // console.log(props.orden_compra)
    if (props.idFactura === null && props.openModal === true) {
      // console.log(factura)
      form.setFieldsValue({
        serie: null, 
        numero_serie: null,
        fecha_vencimiento: null,
        resumen: null,
        detraccion: null
      })
      props.getDetalleOrdenbyIdOrden(props.orden_compra.id_orden_compra)
      if (props.orden_compra.parv_descripcion === 'DOLARES') {
        getCambioDolar()
      } else {
        setFactura({...factura, 
          TipCambio: 1
        })
      }
    } else {
      // console.log(props.idFactura, props.openModal)
      if (props.idFactura !== null && props.openModal === true) {
        getFacturaById(props.idFactura)
      }
    }
  }, [props.idFactura, props.openModal]);

  const getFacturaById = async(id_factura) => {
    // console.log(props.orden_compra)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getFacturaById/${id_factura}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        // console.log(props.orden_compra)
        setFactura({
          id_orden_compra: data.id_orden_compra,
          fecha_emision: moment.utc(data.fecha_emision).format('YYYY-MM-DD'),
          fecha_vencimiento: moment.utc(data.fecha_vencimiento).format('YYYY-MM-DD'),
          serie: data.serie,
          numero_serie: data.numero_serie,
          codigo: data.codigo,
          detraccion: data.detraccion,
          afecta_detraccion: data.afecta_detraccion,
          usuario: props.usuario,
          ubicacion_archivo: data.ubicacion_archivo,
          TipCambio: props.orden_compra.TipCambio,
          total: null,
          resumen: data.resumen,
          ubicacion_archivo_xml: data.ubicacion_archivo_xml
        })

        form.setFieldsValue({
          fecha_vencimiento: moment.utc(data.fecha_vencimiento).format('YYYY-MM-DD'),
          serie: data.serie,
          numero_serie: data.numero_serie,
          resumen: data.resumen,
          detraccion: data.detraccion
        })
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getCambioDolar = async() => {
    const cambioToday = await getTipoCambioToday()
    // console.log(cambioToday[0])
    if (cambioToday.message) {
      message.warning("No se ha Registrado el tipo de cambio de hoy", 5)
      setFactura({...factura, 
        TipCambio: 1
      })
      handleOpenModalTipoCambio()
    } else {
      setFactura({...factura, 
        TipCambio: cambioToday[0].TipCambio
      })
    }
  }

  const handleOpenModalTipoCambio = () => {
    setOpenModalTipoCambio(true)
    setFactura({...factura, 
      TipCambio: 1
    })
  }

  const Total = () => {
    return(
      <>
        {props.orden_compra.tipo_documento === 'HONORARIO'? null:
        <div className='d-flex justify-content-between mr-4'>
          <b>Base Imponible</b>
          <b className='mr-4'>
            {props.orden_compra.Parc_Valor} { props.detOrdenesCompraList.length === 0? 0:
              (parseFloat(props.detOrdenesCompraList.map(det => det.cantidad * det.precio).reduce((prev, next) => prev + next))/ 1.18)
              .toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            }
          </b> 
        </div>
        }
        
        {props.orden_compra.tipo_documento === 'HONORARIO'?null:
        <div className='d-flex justify-content-between mr-4'>
          <b>IGV</b>
          <b className='mr-4'>
            {props.orden_compra.Parc_Valor} { props.detOrdenesCompraList.length === 0? 0:
            (parseFloat(props.detOrdenesCompraList.map(det => det.cantidad * det.precio).reduce((prev, next) => prev + next))/1.18 * 0.18)
            .toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            }
          </b> 
        </div>
        }
        <div className='d-flex justify-content-between mr-4'>
          <b>{props.orden_compra.tipo_documento === 'HONORARIO'?'Total por Honorarios':'Total'}</b>
          <b className='mr-4'>
            {props.orden_compra.Parc_Valor} { props.detOrdenesCompraList.length === 0? 0:
              parseFloat(props.detOrdenesCompraList.map(det => det.cantidad * det.precio).reduce((prev, next) => prev + next))
              .toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            }
          </b>
        </div>

        <div className='d-flex justify-content-between mr-4'>
          <b>Total a Pagar</b>
          <b className='mr-4'>
            {props.orden_compra.Parc_Valor} { props.detOrdenesCompraList.length === 0? 0:
              factura.afecta_detraccion === false?
                parseFloat(props.detOrdenesCompraList.map(det => det.cantidad * det.precio).reduce((prev, next) => prev + next))
                .toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }):
                (parseFloat(props.detOrdenesCompraList.map(det => det.cantidad * det.precio)
                .reduce((prev, next) => prev + next))*(1-(factura.detraccion/100)))
                .toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            }
          </b>
        </div>
      </>
    )
  }

  const columns = [
    { title: 'MATERIAL/EQUIPO', key: 'nombre_articulo', dataIndex: 'nombre_articulo' },
    { title: 'DESCRIPCIÓN', key: 'descripcion', dataIndex: 'descripcion' },
    { title: 'MARCA', key: 'marca', dataIndex: 'marca' },
    { title: 'PRESENTACIÓN',
      render: record => {
        return(
          <>{record.nombre_unidad} X {record.cantidad_unidades}</>
        )
      }
    },
    { title: 'CANTIDAD', 
      render: record => {
        return(<>{record.cantidad.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>)
      }
    },
    { title: 'PRECIO',
      render: record => {
        return(
          <>
            {props.orden_compra.Parc_Valor} {record.precio.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </>
        )
      } 
    },
    {
      title: 'TOTAL',  
      render: record => {
        return(
          <>
            {props.orden_compra.Parc_Valor} 
            {(record.cantidad * record.precio).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </>
        )
      }
    }
  ]

  const columnsServ = [
    { title: 'SERVICIO SOLICITADO', key: 'descripcion', dataIndex: 'descripcion' },
    { title: 'PRECIO',
      render: record => {
        return(
          <>
            {props.orden_compra.Parc_Valor } {record.precio.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </>
        )
      } 
    }
  ]

  const checkDetraccionChange = (value) => {
    if (value === false) {
      setFactura({...factura,
        afecta_detraccion: value,
        detraccion: 0,
      })
      form.setFieldsValue({detraccion: 0})
    } else {
      setFactura({...factura, afecta_detraccion: value})
    }
  }

  const subirArchivo = (e) => {
    // console.log(e[0].name)
    setFactura({...factura, ubicacion_archivo: e})
  }

  const subirArchivoOC = (e) => {
    // console.log(e[0].name)
    props.setOrden_compra({...props.orden_compra, ubicacion_archivo: e})
  }

  const subirArchivoXML = (e) => {
    // console.log(e[0].name)
    setFactura({...factura, ubicacion_archivo_xml: e})
  }

  const AddFactura = async() => {
    // console.log(factura, props.orden_compra)
    if (props.orden_compra.parv_descripcion === 'DOLARES' && factura.TipCambio === 1) {
      message.error('Falta Ingresar el Tipo de Cambio')
    } else {
      if (factura.ubicacion_archivo === null) {
        message.error('Debe Subir el PDF de la Factura')
      } else {
        if (factura.ubicacion_archivo[0] === undefined) {
          message.error('Debe Subir el PDF de la Factura')
        } else {
          const body = {...factura, usuario: props.usuario, id_orden_compra: props.orden_compra.id_orden_compra,
            tipo_documento: props.orden_compra.tipo_documento,
            total: props.detOrdenesCompraList.map(det => det.cantidad * det.precio).reduce((prev, next) => prev + next),
            guia_remision: props.orden_compra.ubicacion_archivo !== null? props.orden_compra.ubicacion_archivo[0] !== undefined?
              props.orden_compra.ubicacion_archivo:null:null,
            id_consolidado: props.orden_compra.id_consolidado
          }

          try {
            await fetch(`${process.env.REACT_APP_HOSTNAME}/AddFactura`,{
              method: "POST",
              body: JSON.stringify(body),
              headers:{
                'Content-Type': 'application/json'
              }
            }).then(res => res.json())
            .then(data =>{
              // console.log(data)
              UploadFileFactura(data.id_factura, props.orden_compra.id_area)
            }).catch(err => console.log(err)) 
          } catch (error) {
            console.log(error)
          }
        }
      }
    }
  }

  const EditFactura = async() => {
    setLoadingButton(true)
    if (props.orden_compra.parv_descripcion === 'DOLARES' && factura.TipCambio === 1) {
      message.error('Falta Ingresar el Tipo de Cambio')
    } else {
      try {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/UpdateFactura`,{
          method: "PUT",
          body: JSON.stringify({...factura, id_factura: props.idFactura}),
          headers:{
            'Content-Type': 'application/json'
          }
        }).then(res => res.json())
        .then(data =>{
          message.success(data.mensaje)
          setLoadingButton(false)
          // props.setDetOrdenesCompraList([])
          handleCloseModal()
          props.getFacturas()
        }).catch(err => {
          console.log(err)
          setLoadingButton(false)
        });
      } catch (error) {
        console.log(error)
        setLoadingButton(false)
      }
    }
  }

  const UploadFileFactura = async(id_nuevaFactura, id_area) => {
    setLoadingButton(true)
    const body = {
      destination:'Logistica',
      // filename:`Factura_${id_nuevaFactura}.pdf`,
      id_factura: id_nuevaFactura,
      guia_remision: props.orden_compra.ubicacion_archivo !== null? props.orden_compra.ubicacion_archivo[0] !== undefined?
        props.orden_compra.ubicacion_archivo:null:null,
      id_orden_compra: props.orden_compra.id_orden_compra,
      id_sucursal:1,
      id_area: id_area,
      numero_orden: props.orden_compra.numero_orden,
      fecha_movimiento: moment.utc(new Date()).format('YYYY/MM/DD'),
      tipo: props.orden_compra.tipo,
      varios: true
    }

    const bodyForm = new FormData()

    bodyForm.append('tabla', JSON.stringify(body))
    // bodyForm.append('File', factura.ubicacion_archivo[0])
    for (let i = 0; i < factura.ubicacion_archivo.length; i++) {
      const file = factura.ubicacion_archivo[i];
      const filename = file['name']
      const fileExtension = filename.split('.').pop()
      bodyForm.append('File', factura.ubicacion_archivo[i], `Factura_${id_nuevaFactura}_${i}.${fileExtension}`)

    }

    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/${props.estadoOrden === 'ACEPTADA'?'UploadFileFactura':'UploadFileFacturaSinMov'}`, {
        method: "POST",
        body: bodyForm,
      }).then(res => res.json())
      .then(data =>{
        message.success(data.mensaje)

        if (props.orden_compra.ubicacion_archivo !== null) {
          if (props.orden_compra.ubicacion_archivo[0] !== undefined) {
            UpdateGuiaRemision()
          }
        }

        if (factura.ubicacion_archivo_xml !== null) {
          if (factura.ubicacion_archivo_xml[0] !== undefined) {
            UpdateFacturaXML(id_nuevaFactura)
          }
        }
        setLoadingButton(false)
        // props.setDetOrdenesCompraList([])
        props.getOrdenesCompra()
        handleCloseModal()

      }).catch(err => {
        console.log(err)
        message.error('No se Pudo Subir el Archivo al Servidor')
        setLoadingButton(false)
      });
    } catch (error) {
      console.log(error)
      message.error('No se Pudo Subir el Archivo al Servidor')
      setLoadingButton(false)
    }
  }

  const UpdateGuiaRemision = async() => {

    const body = {
      destination:'Logistica',
      filename:`GuiaRemision_${props.orden_compra.id_orden_compra}.pdf`,
      ...props.orden_compra, fecha_movimiento: moment.utc(new Date()).format('YYYY/MM/DD')
    }

    const bodyForm = new FormData()

    bodyForm.append('tabla', JSON.stringify({...body,id_sucursal:1}))
    bodyForm.append('File', props.orden_compra.ubicacion_archivo[0])

    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/UploadFileGuiaRemision`,{
        method: "POST",
        body: bodyForm
      }).then(res => res.json())
      .then(data =>{
        message.success(data.message)
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error)
    }
  }

  const UpdateFacturaXML = async(id_nuevaFactura) => {
    const body = {
      destination:'Logistica',
      filename:`XML_${id_nuevaFactura}.xml`,
      id_factura: id_nuevaFactura,
    }

    const bodyForm = new FormData()

    bodyForm.append('tabla', JSON.stringify({...body,id_sucursal:1}))
    bodyForm.append('File', factura.ubicacion_archivo_xml[0])

    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/UpdateFacturaXML`,{
        method: "POST",
        body: bodyForm
      }).then(res => res.json())
      .then(data =>{
        message.success(data.message)
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error)
    }
  }

  const nombresItems = () => {
    // console.log(requerimiento.ubicacion_archivo)
    let nombres = []
    for (let i = 0; i < factura.ubicacion_archivo.length; i++) {
      const element = factura.ubicacion_archivo[i];
      nombres = [...nombres, {nombre_file: element.name}]
    }
    return nombres
  }

  return(
    <>
      <Modal
        visible={props.openModal}
        closable={false}
        width='90%'
        title={
          <>
            <div className='d-flex justify-content-between'>
              <b style={{fontSize:"20px"}}>
                {props.idFactura === null?`Nueva Factura`:
                `Editar Factura: ${factura.codigo}`}
              </b>

              <b style={{fontSize:"20px"}}>
                {props.orden_compra.id_consolidado === null?props.orden_compra.codigo: listaCodigos(props.orden_compra.codigo)}
              </b>
            </div>

            <div className="d-flex justify-content-between mb-2 mt-2">
              <b style={{fontSize:"20px"}}>
                Área(s): 
                {props.orden_compra.id_consolidado === null?props.orden_compra.nombre_area:listaAreas(props.orden_compra.nombre_area)}
              </b>
              <b style={{fontSize:"20px"}}>Fecha de Orden: {moment(props.orden_compra.fecha_orden_compra).format('DD/MM/YYYY')}
              </b>
            </div>

            <div className="d-flex justify-content-between mb-2 mt-2">
              <b style={{fontSize:"20px"}}>
                Solicitante(s): 
                {props.orden_compra.id_consolidado === null?props.orden_compra.nombre_responsable:
                listaResp(props.orden_compra.nombre_responsable)}
              </b>  
              <b style={{fontSize:"20px"}}>N° Orden: {props.orden_compra.numero_orden}</b>  
            </div>
          </>
        }
        onCancel={handleCloseModal}
        footer={null}
      >
        <Form form={form} layout='vertical' onFinish = {props.idFactura === null? AddFactura: EditFactura}>
          <div className='elem-list'>
            <div className='col-sm-4 col-md-2 col-lg-2'>
              <Item label="Fecha" name="fecha">
                <b>{moment(factura.fecha_emision).format('DD/MM/YYYY')}</b>
              </Item>  
            </div>

            <div className='col-sm-4 col-md-2 col-lg-2'>
              <Item label="Fecha de Vencimiento" name="fecha_vencimiento">
                <Input type="date" 
                  defaultValue={factura.fecha_vencimiento} 
                  onChange={e=>setFactura({...factura, fecha_vencimiento:e.currentTarget.value})}
                  disabled = {props.orden_compra.tipo_pago === "CREDITO"?false:true}
                />
              </Item>
            </div>
            
            <div className='col-sm-6 col-md-2 col-lg-2'>
              <Item label="Tipo Doc." name="tipo_documento" >
                <b>{props.orden_compra.tipo_documento}</b>
              </Item>
            </div>

            <div className='col-sm-4 col-md-1 col-lg-1'>
              <Item label="Moneda" name="parv_descripcion">
                <b>{props.orden_compra.parv_descripcion === 'SOLES'? 'S/.': '$'}</b>
              </Item>
            </div>

            <div className='col-sm-4 col-md-2 col-lg-2'>
              <Item label="Tipo de Cambio" name="TipCambio">
                <b>{factura.TipCambio}</b>
              </Item>
            </div>
          </div>

          <div className='elem-list'>
            <div className='col-sm-11 col-md-5 col-lg-5'>
              <Item label="Razón Social" name="descripcion">
                <b>{props.orden_compra.descripcion}</b>
              </Item>
            </div>

            <div className='col-sm-11 col-md-4 col-lg-4'>
              <Item label="Nombre Comercial" name="nombre_comercial">
                <b>{props.orden_compra.nombre_comercial}</b>
              </Item>
            </div>

            <div className='col-sm-11 col-md-2 col-lg-2'>
              <Item label="RUC" name="ruc">
                <b>{props.orden_compra.ruc}</b>
              </Item>
            </div>
          </div>

          <div className='elem-list'>
            <div className='col-sm-4 col-md-2 col-lg-2'>
              <Item label="Tipo de Pago" name="tipo_pago">
                <b>{props.orden_compra.tipo_pago}</b>
              </Item>  
            </div>

            <div className='col-sm-4 col-md-2 col-lg-2'>
              <Item label="Forma de Pago" name="forma_pago">
                <b>{props.orden_compra.forma_pago}</b>
              </Item>  
            </div>

            <div className='col-sm-5 col-md-2 col-lg-2'>
              <Item label="Serie" name="serie" rules={[
                    {...rules.required, message: 'La Serie es Requerida'}
                  ]}>
                <Input defaultValue={factura.serie}
                  onChange={e=> setFactura({...factura, serie:e.currentTarget.value})}
                />
              </Item>
            </div>

            <div className='col-sm-6 col-md-2 col-lg-2'>
              <Item label="Número" name="numero_serie" rules={[
                    {...rules.required, message: 'El Núm. de Serie es Requerido'}
                  ]}>
                <Input defaultValue={factura.numero_serie}
                  onChange={e=> setFactura({...factura, numero_serie: e.currentTarget.value})}
                />
              </Item>
            </div>
          </div>

          <div className='elem-list'>
            {props.idFactura === null?
            <>
              <div className='col-sm-11 col-md-4 col-lg-4'>
                <b style={{color:"red"}}>* </b><br/>
                {factura.ubicacion_archivo === null?null:
                  factura.ubicacion_archivo[0] === undefined?null:
                  <>
                    {nombresItems().map((item, index) => {
                      return( 
                        <><b className='mr-4'>{index+1}{item.nombre_file}</b><br/></>
                      )
                    })}
                  </>
                }
                <input ref={input} onChange={(e) => subirArchivo(e.target.files)} type='file' style={{ display: 'none' }} multiple/>
                <Button size='small' type={'primary'} className='btn-outline' onClick={handleClick} icon={<FilePdfOutlined />}>
                  Subir PDF Factura
                </Button>
              </div>

              {props.estadoOrden === 'ACEPTADA'?
                <div className='col-sm-11 col-md-4 col-lg-4'>
                  <b className='mr-2'>{props.orden_compra.ubicacion_archivo === null?null:
                    props.orden_compra.ubicacion_archivo[0] === undefined?null:props.orden_compra.ubicacion_archivo[0].name}</b>
                  <br/>
                  <input ref={inputOC} onChange={(e) => subirArchivoOC(e.target.files)} type='file' style={{ display: 'none' }} />
                  <Button size='small' type={'primary'} className='btn-outline mr-2' onClick={handleClickOC} icon={<FilePdfOutlined />}>
                    Subir PDF Guía Rem.
                  </Button>
                </div>
              :null}
              
              <div className='col-sm-11 col-md-3 col-lg-3'>
                <b className='mr-2'>{factura.ubicacion_archivo_xml === null?null:
                  factura.ubicacion_archivo_xml[0] === undefined?null:factura.ubicacion_archivo_xml[0].name}</b>
                <br/>
                <input ref={inputXML} onChange={(e) => subirArchivoXML(e.target.files)} type='file' style={{ display: 'none' }} />
                <Button size='small' type={'primary'} className='btn-outline mr-2' onClick={handleClickXML} icon={<FilePdfOutlined />}>
                  Subir XML
                </Button>
              </div>
            </>
              
            :null}
          </div>


          <div className='col-sm-12 col-md-12 col-lg-12 mt-4'>
            <Table 
              pagination={false} 
              columns={props.orden_compra.tipo === 'COMPRA'?columns: columnsServ}
              dataSource={props.detOrdenesCompraList}
              size="small"
              footer={() => Total()}
            />
          </div>

          <div className='elem-list mt-2'>
            <div className='col-sm-12 col-md-2 col-lg-2'>
              <Checkbox checked={factura.afecta_detraccion} 
                onChange={e => checkDetraccionChange(e.target.checked)}>% Detracción</Checkbox>  
            </div>
          
            <div className='col-sm-12 col-md-2 col-lg-2'>
              <Item name="detraccion">
                <Input defaultValue={factura.detraccion} type='number' placeholder='% Detracción'
                  disabled = {factura.afecta_detraccion === false?true:false}
                  onChange={e=> setFactura({...factura, detraccion: e.currentTarget.value})}
                />  
              </Item>
            </div>
          </div>

          <div className='elem-list mb-2'>
            <div className='col-sm-12 col-md-12 col-lg-12'>
              <Item label='Resumen' name="resumen" rules={[
                {...rules.required, message: 'Resumen del Detalle es Requerido'}
              ]}>
                <Input defaultValue={factura.resumen} placeholder='Resumen'
                  onChange={e=> setFactura({...factura, resumen: e.currentTarget.value})}
                />  
              </Item>
            </div>
            
          </div>

          <div className='modal-footer d-flex justify-content-between'>
            <Button danger onClick={handleCloseModal} icon={<CloseOutlined />}>
              Cerrar
            </Button>
            
            <Button className='bg-color-success' loading={loadingButton}
              type='primary' icon={<SaveOutlined />} htmlType="submit">
              Guardar
            </Button>  
          </div>
        </Form>

        {<ModalTipoCambio
          openModal = {openModalTipoCambio}
          loadingButtonAprobar = {loadingButton}
          setLoadingButtonAprobar = {setLoadingButton}
          setTabla = {setFactura}
          tabla = {factura}
          usuario = {props.usuario}
          setOpenModal = {setOpenModalTipoCambio}
        />}
      </Modal>
    </>
  )

}

export default ModalFormularioFactura