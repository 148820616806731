import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Input, Modal, message, Form } from 'antd';
import { Rule } from 'antd/lib/form';
import { useState, useEffect } from "react";

const { Item } = Form;

const rules = {
  required: { required: true, message: <></> } as Rule
};

const ModalUnidadForm = (props) => {
  const [loadingButton, setLoadingButton] = useState(false)
  const [unidad, setUnidad] = useState({
    nombre: null,
    abreviatura: null,
    cantidad_unidades: 1
  })
  const [form] = Form.useForm();

  const handleCloseModal = () => {
    props.setOpenModalUnidad(false)
  }

  useEffect(() => {
    setUnidad({
      nombre: null,
      abreviatura: null,
      cantidad_unidades: 1
    })
    form.setFieldsValue({
      nombre: null,
      abreviatura: null,
      cantidad_unidades: 1
    })
  }, [props.openModalUnidad])

  const AddUnidad = async () => {
    setLoadingButton(true)
    const body = {...unidad, usuario: props.usuario}
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/AddUnidad`,{
        method: "POST",
        body: JSON.stringify(body),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data =>{
        message.success(data.mensaje)
        setLoadingButton(false)
        props.getUnidades()
        handleCloseModal()
      }).catch(err => {
        console.log(err)
        setLoadingButton(false)
      })
    } catch (error) {
      setLoadingButton(false)
      console.log(error)
    }
  }

  return(
    <>
      <Modal
        visible={props.openModalUnidad}
        closable={false}
        width='60%'
        title={<h3 className='m-0'>Ingresar Nueva Presentación</h3>}
        onCancel={handleCloseModal}
        footer={null}
      >
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <Form form={form} layout='vertical' onFinish={AddUnidad}>
              <div className='elem-list mb-2'>
                <div className='col-sm-11 col-md-5 col-lg-5'>
                  <Item label="Nombre" name="nombre" rules ={[
                    {...rules.required, message: 'El Nombre es Requerido'}
                  ]}>
                    <Input defaultValue={unidad.nombre} 
                      onChange={e=>setUnidad({
                        ...unidad, nombre:e.currentTarget.value
                      })}
                       placeholder = "Nombre"
                    />
                  </Item>
                </div>

                <div className='col-sm-5 col-md-3 col-lg-3'>
                  <Item label="Abreviatura" name="abreviatura">
                    <Input defaultValue={unidad.abreviatura} 
                      onChange={e=>setUnidad({
                        ...unidad, abreviatura:e.currentTarget.value
                      })}
                       placeholder = "Abreviatura"
                    />
                  </Item>
                </div>

                <div className='col-sm-11 col-md-3 col-lg-3'>
                  <Item label="Cant. Unidades" name="cantidad_unidades" rules ={[
                    {...rules.required, message: 'La Cantidad es Requerida'}
                  ]}>
                    <Input type='number' defaultValue={unidad.cantidad_unidades} min={1}
                      onChange={e=>setUnidad({
                        ...unidad, cantidad_unidades :e.currentTarget.valueAsNumber
                      })}
                    />
                  </Item>
                </div>
              </div>

              <div className='modal-footer d-flex justify-content-between'>
                <Button danger onClick={handleCloseModal} icon={<CloseOutlined />}>
                  Cerrar
                </Button>
                
                <Button className='bg-color-success' loading={loadingButton}
                  type='primary' icon={<SaveOutlined />} htmlType="submit">
                  Guardar
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModalUnidadForm