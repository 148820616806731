import moment from "moment";
import {PDFExport} from '@progress/kendo-react-pdf'
import { Table } from "antd";
import "./styles.css";
import { listaAreas } from "../FuncionesGenerales";

const PageTemplate = (props) => {
  return (
    <div style={{ position: "absolute", bottom: "10px", left: "50%" }}>
      Pag. {props.pageNum} de {props.totalPages}
    </div>
  );
};

const TotalPagar = (item) => {
  if (item.afecta_detraccion === false) {
    return(item.total.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }))  
  } else {
    return((item.total *(1-(item.detraccion/100))).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
  }
  
}

const columns = [
  {
    title: 'FECHA',
    dataIndex: 'fecha_emision',
    key: 'fecha_emision',
    render: text => (
      moment.utc(text).format('DD/MM/YY')
    )
  },
  {
    title: 'CÓDIGO',
    dataIndex: 'codigo',
    key: 'codigo'
  },
  {
    title: 'ORDEN DE COMPRA',
    dataIndex: 'numero_orden',
    key: 'numero_orden'
  },
  {
    title: 'DOC.',
    dataIndex: 'tipo_documento',
    key: 'tipo_documento'
  },
  {
    title: 'SERIE',
    dataIndex: 'serie',
    key: 'serie'
  },
  {
    title: 'N. SERIE',
    dataIndex: 'numero_serie',
    key: 'numero_serie'
  },
  {
    title: 'PROVEEDOR',
    dataIndex: 'descripcion',
    key: 'descripcion'
  },
  {
    title: 'RUC',
    dataIndex: 'ruc',
    key: 'ruc'
  },
  {
    title: 'MODO PAGO',
    render: record => {
      return(<>{record.tipo_pago}-{record.forma_pago}</>)
    }
  },
  {
    title: 'ÁREA',
    render: record => {
      if (record.id_consolidado === null) {
        return(<>{record.nombre_area}</>)
      } else {
        return(<>{listaAreas(record.nombre_area)}</>)  
      }
      
    }
  },
  {
    title: 'TOTAL A PAGAR',
    render: record => {
      return(<>{record.Parc_Valor}{TotalPagar(record)}</>)
    }
  },
]

export const PdfFileFacturas = (componentePDF, facturasListFiltradas) => {
  return(
    <>
      <div style={{ position: "absolute", left: "-4000px", top: 0 }}>
        <div className='row'>
          <PDFExport 
            ref={componentePDF} 
            paperSize='A4' 
            margin="1cm" 
            landscape={true}
            pageTemplate={PageTemplate}
            fileName='Facturas'
            scale={0.5}
          >
            <Table
              pagination={false}
              columns={columns}
              dataSource={facturasListFiltradas}
              size='small'
              // footer={() => Total(facturasListFiltradas)}
            />
          </PDFExport>
        </div>
      </div>
    </>
  )
}