import {useEffect, useState} from "react";
import { IPageData } from "../../../interfaces/page";
import { usePageData } from '../../../hooks/usePage';
import { NavLink } from 'react-router-dom';
import Cookies from "universal-cookie";
import LogoSvg from './../../../assets/img/LogoConFrase - copia.png';

const cookies = new Cookies()
const pageData: IPageData = {
  fulFilled: true,
  title: '',
  breadcrumbs: [
    {
      title: '',
    }
  ]
};

const Inicio = () => {
  usePageData(pageData);

  const [unique_name_logis, setUnique_name_logis] = useState('')

  useEffect(() => {
    if (!cookies.get('nameid_logis')) {
      window.location.href='/'
    } else {
      setUnique_name_logis(cookies.get('unique_name_logis')) 
    }
  }, [])

  return (
    <>
      <div className="d-flex justify-content-center">
        <h2>BIENVENIDO(A) {unique_name_logis}</h2>
      </div>

      <div className="d-flex justify-content-center">
        <p>Sistema de Logística</p>
      </div>
      
      <div className="d-flex justify-content-center">
        <NavLink to={'/vertical/requerimientos'}>Ir a la lista de Requerimientos</NavLink>
      </div>

      <div className="d-flex justify-content-center mt-4">
        <img src={LogoSvg} style={{width:'770px', maxHeight:'270px'}}/>	
      </div>
    </>
  )
}

export default Inicio