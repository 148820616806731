import { CloseOutlined, DeleteOutlined, FilePdfOutlined, 
  PlusOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import moment from "moment";
import { getTipoCambioToday } from '../FuncionesGenerales';
import { Button, Form, Input, Modal,
  Select, message, Card, Table, Popconfirm } from 'antd';
import { Rule } from 'antd/lib/form';
import { Option } from 'antd/lib/mentions';
import {useEffect, useRef, useState} from "react";
import ModalTipoCambio from '../ModalTipoCambio';
import ModalProveedor from '../componentesProveedores/ModalProveedor';

const { Item } = Form;

const rules = {
  required: { required: true, message: <></> } as Rule
};

const ModalConsolidadoCotizacion = (props) => {
  const [loadingButton, setLoadingButton] = useState(false)
  const [openModalTipoCambio, setOpenModalTipoCambio] = useState(false)
  const [consolidado, setConsolidado] = useState({
    fecha_consolidado: moment.utc(new Date()).format('YYYY-MM-DD'),
    ubicacion_archivo: null,
    parv_descripcion: null,
    total: 0,
    id_proveedor: null,
    TipCambio: 1,
    req_consolidados: null
  })
  const [detalleConsolidado, setDetalleConsolidado] = useState([])
  const [requConsolidados, setRequConsolidados] = useState([])
  const [idProveedor, setIdProveedor] = useState(null)
  const [tituloModal, setTituloModal] = useState('')
  const [openModalProveedor, setOpenModalProveedor] = useState(false);
  const [bancosList, setBancosList] = useState([])
  const [departamentosList, setDepartamentosList] = useState([])
  const [provinciasList, setProvinciasList] = useState([])
  const [provinciasSubList, setProvinciasSubList] = useState([])
  const [distritosList, setDistritosList] = useState([])
  const [distritosSubList, setDistritosSubList] = useState([])
  const [inputBuscarRUC, setInputBuscarRUC] = useState('');
  const [inputBuscarNombre, setInputBuscarNombre] = useState('');

  const [form] = Form.useForm();
  const input = useRef<HTMLInputElement>(null);

  const optionFilter = (input, option) =>
    option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;

  useEffect(() => {
    if (props.openModal === true) {
      getConsolidadoById(props.idConsolidado)
      getDetalleConsolidadoByIdConsolidado(props.idConsolidado)
      getDetalleReqConsolidadoById(props.idConsolidado)
      getBancos()
      getDepartamentos()
      getProvincias()
      getDistritos()
    }
  }, [props.openModal]);

  const handleClick = () => input.current.click();

  const handleCloseModal = () => {
    props.setOpenModal(false)
    props.setIdConsolidado(null)
    setConsolidado({
      fecha_consolidado: moment.utc(new Date()).format('YYYY-MM-DD'),
      ubicacion_archivo: null,
      parv_descripcion: 'SOLES',
      total: 0,
      id_proveedor: null,
      TipCambio: 1,
      req_consolidados: null
    })
    setDetalleConsolidado([])
    form.setFieldsValue({
      parv_descripcion: 'SOLES',
      id_proveedor: null,
      fecha_consolidado: moment.utc(new Date()).format('YYYY-MM-DD'),
      
    })
  }

  const getConsolidadoById = async(id_consolidado) => {
    // console.log(id_consolidado)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getConsolidadoById/${id_consolidado}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setConsolidado(data)

        form.setFieldsValue({
          parv_descripcion: data.parv_descripcion === null?'SOLES':data.parv_descripcion,
          id_proveedor: data.id_proveedor,
          fecha_consolidado: moment.utc(data.fecha_consolidado).format('YYYY-MM-DD')
        })
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getDetalleConsolidadoByIdConsolidado = async(id_consolidado) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getDetalleConsolidadoByIdConsolidado/${id_consolidado}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        let lista = []
        for (let i = 0; i < data.length; i++) {
          const item = props.articulosList.filter( articulo => {
            return( articulo.id_articulo === data[i].id_articulo)
          })
          lista = [...lista,{
            ...data[i], stock_disponible: item[0].stock_disponible,
          }]
        }
        setDetalleConsolidado(lista)
        
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getDetalleReqConsolidadoById = async(id_consolidado) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getDetalleReqConsolidadoById/${id_consolidado}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        let reqs = []
        for (let i = 0; i < data.length; i++) {
          // console.log(data[i].id_requerimiento)
          reqs = [...reqs, data[i].id_requerimiento]
        }

        setRequConsolidados(reqs)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleOpenModalTipoCambio = () => {
    setOpenModalTipoCambio(true)
  }

  const getBancos = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getBancos`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setBancosList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getDepartamentos = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getDepartamentos`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setDepartamentosList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getProvincias = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getProvincias`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setProvinciasList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getDistritos = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getDistritos`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setDistritosList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const subirArchivo = (e) => {
    setConsolidado({...consolidado, ubicacion_archivo: e})
  }

  const articulosFiltrados = () => {
    // console.log(requConsolidados[0])
    if (requConsolidados.length > 0) {
      const requ = props.requerimientosList.filter(req => {
        return(req.id_requerimiento === requConsolidados[0])
      })
      const lista = props.articulosList.filter(articulo => {
        return (requ[0].id_rubro === null?
          articulo.id_rubro !== requ[0].id_rubro: articulo.id_rubro === requ[0].id_rubro) 
      })

      return lista  
    } else {
      return []
    }
  }

  const AddDetalleCon = () => {
    setDetalleConsolidado([...detalleConsolidado, {
      id_unidad: null,
      cantidad: 1,
      precio: 1,
      id_articulo: null,
      descripcion: null,
      marca: null,
      cantidad_unidades: null,
      stock_disponible: null
    }])  
  }

  const RemoveDetalleCons = (item) => {
    const listaItems = detalleConsolidado.filter(detalle => {
      return(detalle !== item)
    })
    // console.log(listaItems)
    setDetalleConsolidado(listaItems)
  }

  const detalleConsChange = (value, campo, item) => {
    const listaItems = detalleConsolidado
    const key = listaItems.indexOf(item)
    listaItems[key][campo] = value

    if (campo === 'id_articulo') {
      const item = props.articulosList.filter( articulo => {
        return( articulo.id_articulo === value)
      })

      listaItems[key].stock_disponible = item[0].stock_disponible
    }
    
    setDetalleConsolidado([...listaItems])
  }

  const detalleConsChangeUnidadCantidad = (value, campo, item) => {
    const listaItems = detalleConsolidado
    const key = listaItems.indexOf(item)

    if (campo === 'cantidad') {
      listaItems[key][campo] = value
    } else {
      listaItems[key][campo] = value
      const unidad = props.unidadesList.filter(uni => {
        return uni.id_unidad === value
      })
      listaItems[key].cantidad_unidades = unidad[0].cantidad_unidades
    }

    setDetalleConsolidado([...listaItems])
  }

  const columns = [
    {
      title: (
        <>
          <Button type='primary' icon={<PlusOutlined />}
            onClick={e => AddDetalleCon()} 
          />
        </>
      ),
      render: record => {
        return(
          <>
            <Popconfirm
              title="¿Quitar Ítem?"
              onConfirm={e=>RemoveDetalleCons(record)} 
              okText="Si" cancelText="No"
            >
              <Button danger title="Quitar" 
                icon={<DeleteOutlined />} />    
            </Popconfirm>
          </>
        )
      }
    },
    {
      title: (<div style={{width:250}} className="mr-4">MATERIAL/EQUIPO</div>),
      key: 'id_articulo',
      render: record => {
        return(
          <>
            <Select
              showSearch
              value={record.id_articulo}
              className='mb-lg-0'
              style={{width:250}}
              placeholder='Material/Equipo'
              optionFilterProp='children'
              filterOption={props.optionFilter}
              onChange={e => detalleConsChange(e, "id_articulo", record)}
            >
              {articulosFiltrados().map(material => {
                return(
                  <Option value={material.id_articulo} 
                    key={material.id_articulo}>
                    {material.nombre}
                  </Option>
                )
              })}
            </Select>
          </>
        )
      }
    },
    {
      title: (<div style={{width:50}} className="mr-4">STOCK DISP.</div>),
      key: 'stock_disponible',
      dataIndex: 'stock_disponible',
      align: 'right' as 'right',
    },
    {
      title: (<div style={{width:150}} className="mr-4">DESCRIPCIÓN</div>),
      key: 'descripcion',
      render: record => {
        return(
          <>
            <Input name='descripcion' value={record.descripcion}
              onChange={e=>detalleConsChange(e.currentTarget.value, "descripcion", record)}
            />
          </>
        )
      },
      align: 'center' as 'center',
    },
    {
      title: (<div style={{width:150}} className="mr-4">MARCA</div>),
      key: 'marca',
      render: record => {
        return(
          <>
            <Input name='marca' value={record.marca}
              onChange={e=>detalleConsChange(e.currentTarget.value, "marca", record)}
            />
          </>
        )
      }
    },
    {
      title: <div style={{width:140}} className="mr-4">PRESENTACIÓN</div>,
      key: 'id_unidad',
      render: record => {
        return(
        <>
          <Select
            showSearch
            value={record.id_unidad}
            className='mb-lg-0'
            style={{width:140}}
            placeholder='Unidad de Medida'
            optionFilterProp='children'
            filterOption={optionFilter}
            onChange={e => detalleConsChangeUnidadCantidad(e,"id_unidad", record)}
          >
            {props.unidadesList.map(unidad => {
              return(
                <Option value={unidad.id_unidad} 
                  key={unidad.id_unidad}>
                  {unidad.nombre} X {unidad.cantidad_unidades}
                </Option>
              )
            })}
          </Select> 
        </>
        )
      }
    },
    {
      title: <div style={{width:120}} className="mr-4">CANTIDAD</div>,
      key: 'cantidad',
      render: record => {
        return(
          <>
            <Input type="number" name='cantidad' value={record.cantidad} style={{width: 120}}
              onChange={e=> detalleConsChangeUnidadCantidad(e.currentTarget.value, "cantidad", record)}
            />
          </>
        )
      }
    },
    {
      title: 'T. UNID.',
      render: record => {
        return(
          <>
            <b>{record.cantidad_unidades * record.cantidad}</b>
          </>
        )
      },align: 'right' as 'right'
    },
    {
      title: <div style={{width:120}} className="mr-4">PRECIO</div>,
      key: 'precio',
      render: record => {
        return(
          <>
            <Input type="number" name='precio' value={record.precio}
              onChange={e=> detalleConsChange(e.currentTarget.value, "precio", record)}
            />
          </>
        )
      }
    },
    {
      title: 'TOTAL',
      key: 'precio',
      render: record => {
        return(
          <>
            <b>{record.cantidad * record.precio}</b>
          </>
        )
      },align: 'right' as 'right'
    }
  ]

  const Total = () => {
    return(
      <>
        <div className='d-flex justify-content-between mr-4'>
          <b>Total</b>
          <b className='mr-4'>
            {consolidado.parv_descripcion === 'SOLES' || consolidado.parv_descripcion === null?'S/.':'$'} { detalleConsolidado.length === 0? 0:
              detalleConsolidado.map(det => det.cantidad * det.precio).reduce((prev, next) => prev + next)
            }
          </b>
        </div>
      </>
    )
  }

  const ChangeMoneda = async (e) => {
    setLoadingButton(true)
    if (e === 'DOLARES') {
      const cambioToday = await getTipoCambioToday()
      if (cambioToday.message) {
        message.warning("No se ha Registrado el tipo de cambio de hoy", 5)
        handleOpenModalTipoCambio()
      } else {
        setConsolidado({...consolidado, 
          TipCambio: cambioToday[0].TipCambio, 
          parv_descripcion: e
        })
      }
      setLoadingButton(false)
    } else {
      setLoadingButton(false)
      setConsolidado({...consolidado, 
        TipCambio: 1, 
        parv_descripcion: e
      })
    }
  }

  const proveedoresFiltrados = () => {
    const listaConRubros = props.proveedoresList.filter(proveedor => {
      return (proveedor.rubros !== null)
    })
    // console.log(listaConRubros)

    let listaRubrosFiltrada = []

    if (requConsolidados.length > 0) {
      const rubro = props.requerimientosList.filter(req => {
        return(req.id_requerimiento === requConsolidados[0])
      })

      for (let i = 0; i < listaConRubros.length; i++) {
        const rubros = JSON.parse(listaConRubros[i].rubros);
        const findRubro = rubros.filter(ru => {
          return(ru.id_rubro === rubro[0].id_rubro)
        })
        // console.log(findRubro,i)
        if (findRubro.length > 0) {
          listaRubrosFiltrada = [...listaRubrosFiltrada, listaConRubros[i]]
        }
        
      }  
    }
    // console.log(listaRubrosFiltrada)
    return listaRubrosFiltrada
  }

  const nombreRubro = () => {
    if (requConsolidados.length > 0) {
      const rubro = props.requerimientosList.filter(req => {
        return(req.id_requerimiento === requConsolidados[0])
      })
      // console.log(rubro)
      return(rubro[0].nombre_rubro)
    } else {
      return(null)
    }
  }

  const proveedorChange = (id_proveedor) => {
    setConsolidado({...consolidado, id_proveedor: id_proveedor})

    const findFroveedor = props.proveedoresList.filter(prov => {
      return(prov.id_prove === id_proveedor )
    })
    form.setFieldsValue({inputBuscarRUC: findFroveedor[0].ruc, inputBuscarNombre: findFroveedor[0].nombre_comercial})
    setInputBuscarRUC(findFroveedor[0].ruc)
    setInputBuscarNombre(findFroveedor[0].nombre_comercial)
  }

  const BuscarProveedorByRUC = () => {
    // console.log(props.proveedoresList)
    const findFroveedor = props.proveedoresList.filter(prov => {
      return(prov.num_ruc === inputBuscarRUC )
    })
    // console.log(findFroveedor)
    if ( findFroveedor.length === 0 ) {
      message.warn(`No se encontró Proveedor con el RUC ${inputBuscarRUC}`)
    } else {
      setConsolidado({...consolidado, id_proveedor: findFroveedor[0].id_prove})
      form.setFieldsValue({id_proveedor: findFroveedor[0].id_prove})
    }
  }

  const BuscarProveedorByRS = () => {
    const findFroveedor = props.proveedoresList.filter(prov => {
      return(prov.nombre_com === inputBuscarNombre.toUpperCase() )
    })

    // console.log(findFroveedor)
    if ( findFroveedor.length === 0 ) {
      message.warn(`No se encontró Proveedor con el Nombre Comercial ${inputBuscarNombre}`)
    } else {
      setConsolidado({...consolidado, id_proveedor: findFroveedor[0].id_prove})
      form.setFieldsValue({id_proveedor: findFroveedor[0].id_prove})
    }
  }

  const handleOpenModalProveedor = async(titulo, id_prove) => {
    setOpenModalProveedor(true)
    setTituloModal(titulo)
    setIdProveedor(id_prove)
  }

  const CotizarConsolidado = async() => {
    if (consolidado.parv_descripcion === 'DOLARES' && consolidado.TipCambio === 1) {
      message.error('Falta Ingresar el Tipo de Cambio')
    } else {
      if (detalleConsolidado.length === 0) {
        message.error('Debe Ingresar los Materiales a Cotizar')
      } else {
        const body = {consolidado: {...consolidado, id_consolidado: props.idConsolidado,
          parv_descripcion: consolidado.parv_descripcion === null?'SOLES':consolidado.parv_descripcion,
          fecha_consolidado: moment.utc(new Date()).format('YYYY-MM-DD'),
          total: detalleConsolidado.map(det => det.cantidad * det.precio).reduce((prev, next) => prev + next)},
          detalleConsolidado: detalleConsolidado
        }

        const validarDetalle = detalleConsolidado.filter(detalle => {
          return detalle.id_unidad === null || detalle.id_articulo === null
        })

        if (validarDetalle.length > 0) {
          message.error('Faltan campos en el detalle de Equipos')
        } else {
          if (consolidado.ubicacion_archivo === null) {
            message.error('Debe Subir el PDF de la Factura de Guía')
          } else {
            if (consolidado.ubicacion_archivo[0] === undefined) {
              message.error('Debe Subir el PDF de la Factura de Guía')
            } else {
              try {
                setLoadingButton(true)
                await fetch(`${process.env.REACT_APP_HOSTNAME}/CotizarConsolidado`,{
                  method: "PUT",
                  body: JSON.stringify(body),
                  headers: {
                    'Content-Type': 'application/json'
                  }
                }).then(res => res.json())
                .then(data => {
                  setLoadingButton(false)
                  // message.success(data.message)
                  props.getConsolidados()
                  // handleCloseModal()
                  UpdateConsolidadoFile(props.idConsolidado)
                }).catch(err => {
                  console.log(err)
                  setLoadingButton(false)
                });  
              } catch (error) {
                console.log(error)
                setLoadingButton(false)
              }
            }
          }
        }
      }
    }
  }

  const UpdateConsolidadoFile = async(id_consolidado) => {
    setLoadingButton(true)
    const body = {
      destination:'Logistica',
      filename:`Consolidado_${id_consolidado}.pdf`,
      id_consolidado: id_consolidado
    }

    const bodyForm = new FormData()

    bodyForm.append('tabla', JSON.stringify(body))
    bodyForm.append('File', consolidado.ubicacion_archivo[0])

    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/UpdateConsolidadoFile`,{
        method: "POST",
        body: bodyForm
      }).then(res => {
        setLoadingButton(false)
        message.success("Consolidado Cotizado")
        handleCloseModal()
      }).catch(err => {
        console.log(err)
        message.error('No se pudo Acceder al Servidor para Subir el Archivo')
        setLoadingButton(false)
      });
    } catch (error) {
      console.log(error)
      message.error('No se pudo Acceder al Servidor para Subir el Archivo')
      setLoadingButton(false)
    }
  }

  const EditarCotConsolidado = async() => {
    if (consolidado.parv_descripcion === 'DOLARES' && consolidado.TipCambio === 1) {
      message.error('Falta Ingresar el Tipo de Cambio')
    } else {
      if (detalleConsolidado.length === 0) {
        message.error('Debe Ingresar los Materiales a Cotizar')
      } else {
        const body = {consolidado: {...consolidado, id_consolidado: props.idConsolidado,
          total: detalleConsolidado.map(det => det.cantidad * det.precio).reduce((prev, next) => prev + next)},
          detalleConsolidado: detalleConsolidado
        }

        const validarDetalle = detalleConsolidado.filter(detalle => {
          return detalle.id_unidad === null || detalle.id_articulo === null
        })

        if (validarDetalle.length > 0) {
          message.error('Faltan campos en el detalle de Equipos')
        } else {
          try {
            setLoadingButton(true)
            await fetch(`${process.env.REACT_APP_HOSTNAME}/CotizarConsolidado`,{
              method: "PUT",
              body: JSON.stringify(body),
              headers: {
                'Content-Type': 'application/json'
              }
            }).then(res => res.json())
            .then(data => {
              setLoadingButton(false)
              message.success(data.message)
              props.getConsolidados()
              handleCloseModal()
            }).catch(err => {
              console.log(err)
              setLoadingButton(false)
            });  
          } catch (error) {
            console.log(error)
            setLoadingButton(false)
          }
        }
      }
    }
  }

  return(
    <>
      <Modal
        visible={props.openModal}
        closable={false}
        width='95%'
        title={
          <>
            <div className='d-flex justify-content-between'>
              <b style={{fontSize:"20px"}}>{props.tituloModal}</b>
              <b style={{fontSize:"20px"}}>
                {consolidado.req_consolidados === null?consolidado.req_consolidados :props.listaCodigos(consolidado.req_consolidados)}
              </b>
            </div>
          </>
        }
        onCancel={handleCloseModal}
        footer={null}
      >
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <Form form={form} layout='vertical' onFinish={props.tituloModal === 'Cotizar Consolidado'?CotizarConsolidado: EditarCotConsolidado}>
              <div className='elem-list'>
                <div className='col-sm-11 col-md-2 col-lg-2'>
                  <Item label="Fecha" name="fecha_consolidado">
                    <b>{consolidado.fecha_consolidado ===null?moment.utc(new Date()).format('YYYY-MM-DD'):  
                      moment.utc(consolidado.fecha_consolidado).format('DD/MM/YYYY')}</b>
                  </Item>
                </div>

                <div className='col-sm-11 col-md-2 col-lg-2'>
                  <Item label="Moneda" name="parv_descripcion">
                    <Select
                      defaultValue={consolidado.parv_descripcion}
                      className='mb-lg-0'
                      placeholder='Seleccionar Moneda'
                      optionFilterProp='children'
                      filterOption={optionFilter}
                      onChange={(e)=> ChangeMoneda(e)}
                    >
                      <Option value={'SOLES'} key={'SOLES'}>S/.</Option>
                      <Option value={'DOLARES'} key={'DOLARES'}>$</Option>
                    </Select>
                  </Item>
                </div>

                <div className='col-sm-11 col-md-2 col-lg-2'>
                  <label className='mb-3' style={{fontWeight:'bold'}}>Tipo de Cambio</label>
                  <br />
                  <b>{consolidado.TipCambio}</b>
                </div>

                {props.tituloModal === 'Cotizar Consolidado'?
                <div className='col-sm-11 col-md-3 col-lg-3'>
                  <b style={{color:"red"}}>* </b>
                  <b className='mr-4'>{consolidado.ubicacion_archivo === null?null:
                    consolidado.ubicacion_archivo[0] === undefined?null:consolidado.ubicacion_archivo[0].name}</b>
                  <br/>
                  <input ref={input} onChange={(e) => subirArchivo(e.target.files)} type='file' style={{ display: 'none' }} />
                  <Button size='small' type={'primary'} className='btn-outline' onClick={handleClick} icon={<FilePdfOutlined />}>
                    Subir PDF
                  </Button>
                </div>
                :null}
              </div>

              <div className='elem-list'>
                <div className='col-sm-11 col-md-5 col-lg-5'>
                  <Item label="Proveedor" name="id_proveedor" rules={[
                    {...rules.required, message: 'El Proveedor es Requerido'}
                  ]}>
                    <Select
                      showSearch
                      defaultValue={consolidado.id_proveedor}
                      className='mb-lg-0'
                      placeholder='Seleccionar Proveedor'
                      optionFilterProp='children'
                      filterOption={optionFilter}
                      onChange={(e)=>proveedorChange(e)}
                    >
                      {proveedoresFiltrados().map(proveedor =>{
                        return(
                          <Option value={proveedor.id_prove} key={proveedor.id_prove}>{proveedor.descripcion}</Option>
                        )
                      })}
                    </Select>
                  </Item>
                </div>

                <div  className='col-sm-8 col-md-2 col-lg-2'>
                  <Item label="Buscar RUC" name="inputBuscarRUC">
                    <Input defaultValue={inputBuscarRUC}
                      onChange={e=> setInputBuscarRUC(e.currentTarget.value)}
                    />
                  </Item>
                </div>

                <div  className='col-sm-1 col-md-1 col-lg-1'>
                  <br />
                  <Button type={'primary'} className='btn-outline mt-2' onClick={BuscarProveedorByRUC} icon={<SearchOutlined />}/>
                </div>

                <div  className='col-sm-8 col-md-2 col-lg-2'>
                  <Item label="Buscar Nombre" name="inputBuscarNombre">
                    <Input defaultValue={inputBuscarNombre}
                      onChange={e=> setInputBuscarNombre(e.currentTarget.value)}
                    />
                  </Item>
                </div>

                <div  className='col-sm-1 col-md-1 col-lg-1'>
                  <br />
                  <Button type={'primary'} className='btn-outline mt-2 mr-2' onClick={BuscarProveedorByRS} icon={<SearchOutlined />}/>
                  <Button type='primary'  
                      title="Nuevo Proveedor" 
                      onClick={e=>handleOpenModalProveedor('Nuevo Proveedor', null)}
                      icon={<PlusOutlined />} />
                </div> 
              </div>

              <div className='col-sm-12 col-md-12 col-lg-12'>
                <Card title="Equipos/Materiales">
                  <label style={{fontWeight:'bold'}}>Rubro: {nombreRubro()}</label>
                  <Table 
                    pagination={false} 
                    columns={columns}
                    dataSource={detalleConsolidado}
                    size="small"
                    footer={() => Total()}
                  />  
                </Card>
              </div>

              <div className='modal-footer d-flex justify-content-between'>
                <Button danger onClick={handleCloseModal} icon={<CloseOutlined />}>
                  Cerrar
                </Button>
                
                <Button className='bg-color-success' loading={loadingButton}
                  type='primary' icon={<SaveOutlined />} htmlType="submit">
                  Guardar
                </Button>
              </div>
              
            </Form>
          </div>
        </div>

        {<ModalTipoCambio
          openModal = {openModalTipoCambio}
          loadingButtonAprobar = {loadingButton}
          setLoadingButtonAprobar = {setLoadingButton}
          setTabla = {setConsolidado}
          tabla = {consolidado}
          usuario = {props.usuario}
          setOpenModal = {setOpenModalTipoCambio}
        />}

        {<ModalProveedor
          idProveedor = {idProveedor}
          setIdProveedor = {setIdProveedor}
          openModal = {openModalProveedor}
          tituloModal = {tituloModal}
          rubrosList = {props.rubrosList}
          bancosList = {bancosList}
          usuario = {props.usuario}
          setOpenModal = {setOpenModalProveedor}
          getProveedores = {props.getProveedores}
          departamentosList = {departamentosList}
          provinciasList = {provinciasList}
          provinciasSubList = {provinciasSubList}
          distritosList = {distritosList}
          distritosSubList = {distritosSubList}
          setProvinciasSubList = {setProvinciasSubList}
          setDistritosSubList = {setDistritosSubList}
        />}
      </Modal>
    </>
  )
  
}

export default ModalConsolidadoCotizacion