import { useEffect, useState } from 'react';

import axios from 'axios';
// import { NavLink } from 'react-router-dom';

import BaseLayout from '../base/BaseLayout';

import Logo from '../components/logo/Logo';
import Navbar from '../components/navbar/Navbar';
import LogoSvg from './../../assets/img/logoLimatambo.png';

import Menu from '../components/menu/Menu';
// import Search from '../components/search/Search';
import NavLoader from '../components/navbar/NavLoader';
// import AddPatient from '../components/patients/AddPatient';

import Actions from '../components/actions/Actions';
import { toggleSidebar } from '../../redux/settings/actions';

import { useDispatch, useSelector } from 'react-redux';

import { IAppState } from '../../interfaces/app-state';

import './Vertical.scss';
import { menuFilterRoles } from '../../pages/components/FuncionesGenerales';

type Props = {
  children: any;
};

const VerticalLayout = ({ children }: Props) => {
  const dispatch = useDispatch();

  const settings = useSelector((state: IAppState) => state.settings);
  const pageData = useSelector((state: IAppState) => state.pageData);

  const onSidebarToggle = () => dispatch(toggleSidebar());

  const [menuData, setMenuData] = useState([]);

  useEffect(() => {
    async function fetchMenuData() {
      const result = await axios('/data/menu.json');
      // console.log(result.data)
      // console.log(result.data[2])
      // const permisos = JSON.parse(window.localStorage.getItem('permisos'))
      // console.log(permisos)
      const menufiltrado = menuFilterRoles(result.data)
      // for (let i = 0; i < permisos.length; i++) {
      //   const element = permisos[i];
      //   console.log(element)
      // }
      /**PARA MANDARLE PARTE DEL MENU VA ENTRE [] */
      // setMenuData([result.data[2]])
      // setMenuData(result.data);
      setMenuData(menufiltrado)
    }

    fetchMenuData().catch((err) => console.log('Server Error', err));
  }, []);

  const nav = (
    <Navbar
      boxed={settings.boxed}
      color={settings.topbarColor}
      background={settings.topbarBg}
      orientation='horizontal'
    >
      <button className='no-style navbar-toggle'/*'no-style navbar-toggle d-lg-none'*/ onClick={onSidebarToggle}>
        <span />
        <span />
        <span />
      </button>

      <Actions />

      <NavLoader loaded={pageData.loaded} type={'top-bar'} />
    </Navbar>
  );

  const sideNav = (
    <Navbar
      onClickOutside={onSidebarToggle}
      opened={settings.sidebarOpened}
      color={settings.sidebarColor}
      background={settings.sidebarBg}
      orientation='vertical'
    >
      <Logo src={LogoSvg} />

      <Menu
        onCloseSidebar={onSidebarToggle}
        opened={settings.sidebarOpened}
        orientation='vertical'
        data={menuData}
      />

      <NavLoader loaded={pageData.loaded} type={'nav-bar'} />
    </Navbar>
  );

  return (
    <>
      <BaseLayout orientation='vertical' nav={nav} sideNav={sideNav}>
        {children}
      </BaseLayout>
    </>
  );
};

export default VerticalLayout;
