import {useEffect, useRef, useState} from "react";
import { IPageData } from "../../../interfaces/page";
import { usePageData } from '../../../hooks/usePage';
import Cookies from "universal-cookie";
import { Card, Table, Button, Input } from 'antd';
import { EditOutlined, FilePdfOutlined, PlusOutlined } from "@ant-design/icons";
import { existeRol } from "../FuncionesGenerales";
import { PdfFileProveedores } from "../componentesPDF/GenerarPDFProveedores";
import { ExcelFileProveedores } from "../componentesExcel/GenerarExcelProveedores";
import ModalProveedor from "./ModalProveedor";

const cookies = new Cookies()

const pageData: IPageData = {
  fulFilled: true,
  title: 'Proveedores',
  breadcrumbs: [
    {
      title: 'Proveedores',
    }
  ]
};

const Proveedores = () => {
  usePageData(pageData);
  const componentePDF = useRef(null)
  const [proveedoresList, setProveedoresList] = useState([])
  const [rubrosList, setRubrosList] = useState([])
  const [bancosList, setBancosList] = useState([])
  const [departamentosList, setDepartamentosList] = useState([])
  const [provinciasList, setProvinciasList] = useState([])
  const [provinciasSubList, setProvinciasSubList] = useState([])
  const [distritosList, setDistritosList] = useState([])
  const [distritosSubList, setDistritosSubList] = useState([])
  const [tituloModal, setTituloModal] = useState('')
  const [openModal, setOpenModal] = useState(false);
  const [permisosProveedores, setpermisosProveedores] = useState(false);
  const [inputCodigo, setInputCodigo] = useState('');
  const [inputDescripcion, setInputDescripcion] = useState('');
  const [inputRUC, setInputRUC] = useState('');
  const [inputNombreComercial, setInputNombreComercial] = useState('');
  const [idProveedor, setIdProveedor] = useState(null)

  useEffect(() => {
    if (!cookies.get('nameid_logis') || existeRol('Logistica.Acceso.Proveedores') === false) {
      // history.push(`/public/sign-in`)
      window.location.href='/'    
    } else {
      getProveedores()
      getRubros()
      getDepartamentos()
      getProvincias()
      getDistritos()
      getBancos()
    }

    setpermisosProveedores(existeRol('Logistica.Gestion.Proveedores'))

  }, []);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     getProveedores()
  //   }, 10000);
  //   return () => clearInterval(interval);
  // }, [proveedoresList])

  const getProveedores = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getProveedores`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setProveedoresList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getBancos = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getBancos`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setBancosList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getRubros = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getRubros`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setRubrosList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getDepartamentos = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getDepartamentos`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setDepartamentosList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getProvincias = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getProvincias`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setProvinciasList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getDistritos = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getDistritos`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setDistritosList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const listaRubros = (rubros_prov) => {
     
    if (rubros_prov === null) {
      return(null)
    } else {
      const listaRubros = JSON.parse(rubros_prov)
      let rubros
      for (let i = 0; i < listaRubros.length; i++) {
        if (i === 0) {
          rubros = listaRubros[i].nombre
        } else {
          rubros = rubros.concat(', ', listaRubros[i].nombre)
        }
      }
      return(rubros)  
    }
  }

  const columns = [
    {
      title: '',
      render: record => {
        return(
          <>
            {permisosProveedores === true?
              <Button type='primary'  
                title="Editar" className="bg-color-orange mr-2"
                onClick={e=>handleOpenModal('Editar Proveedor', record.id_prove)}
                icon={<EditOutlined />}/>: null
            }
          </>
        )
        
      }
    },
    {
      title: (
        <>
          <div style={{width:100}}>
            <Input defaultValue={inputCodigo} 
              onChange={e=>setInputCodigo(e.currentTarget.value)}
                placeholder = "CÓDIGO"
            />
          </div>
        </>
      ),
      dataIndex: 'codigo',
      key: 'codigo'
    },
    {
      title: (
        <>
          <div style={{width:180}}>RUBROS
            {/* <Input defaultValue={inputCodigo} 
              onChange={e=>setInputCodigo(e.currentTarget.value)}
                placeholder = "RUBRO"
            /> */}
          </div>
        </>
      ),
      render: record => {
        return(
          <>
            {listaRubros(record.rubros)}
          </>
        )
      }
    },
    {
      title: (
        <>
          <div style={{width:300}}>
            <Input defaultValue={inputDescripcion} 
              onChange={e=>setInputDescripcion(e.currentTarget.value)}
                placeholder = "RAZÓN SOCIAL"
            />
          </div>
        </>
      ),
      dataIndex: 'descripcion',
      key: 'descripcion'
    },
    {
      title: (
        <>
          <div style={{width:300}}>
            <Input defaultValue={inputNombreComercial} 
              onChange={e=>setInputNombreComercial(e.currentTarget.value)}
                placeholder = "Nombre Comercial"
            />
          </div>
        </>
      ),
      dataIndex: 'nombre_com',
      key: 'nombre_com'
    },
    {
      title: (
        <>
          <div style={{width:130}}>
            <Input defaultValue={inputRUC} 
              onChange={e=>setInputRUC(e.currentTarget.value)}
                placeholder = "RUC"
            />
          </div>
        </>
      ),
      dataIndex: 'num_ruc',
      key: 'num_ruc'
    },
    {
      title: (<><div style={{width:250}}>DIRECCIÓN</div></>),
      dataIndex: 'direccion',
      key: 'direccion'
    }
  ]

  const proveedoresFiltrados = () => {
    const lista = proveedoresList.filter(proveedor => {
      return( proveedor.codigo === null?proveedor.codigo:proveedor.codigo.includes(inputCodigo.toUpperCase()) &&
        proveedor.descripcion.includes(inputDescripcion.toUpperCase()) &&
        proveedor.nombre_com.includes(inputNombreComercial.toUpperCase()) && 
        proveedor.num_ruc.includes(inputRUC)
      )
    })

    return lista.slice(-100)
  }

  const handleOpenModal = async(titulo, id_prove) => {
    setOpenModal(true)
    setTituloModal(titulo)
    setIdProveedor(id_prove)
  }

  const ExportarPDF = () => {
    componentePDF.current.save()
  }

  if (proveedoresList.length > 0) {
    return(
      <>
        {PdfFileProveedores(componentePDF, proveedoresFiltrados())}
        <div className='row'>
          <div className='elem-list ml-2'>
            {permisosProveedores === true?
              <Button type='primary'  
                title="Nuevo Artículo" 
                onClick={e=>handleOpenModal('Nuevo Proveedor', null)}
                icon={<PlusOutlined />} >Agregar
              </Button>   

            : null
            }
            
              <Button danger 
                title="Exportar PDF" 
                onClick={e => ExportarPDF()}
                icon={<FilePdfOutlined />} >PDF
              </Button>
              {ExcelFileProveedores(proveedoresFiltrados())}
          </div>
        </div>

        <Card>
          <div>
            <Table
              columns={columns}
              dataSource={proveedoresFiltrados()}
              size='small'
            />
          </div>
        
          {<ModalProveedor
            idProveedor = {idProveedor}
            setIdProveedor = {setIdProveedor}
            openModal = {openModal}
            tituloModal = {tituloModal}
            rubrosList = {rubrosList}
            bancosList = {bancosList}
            usuario = {cookies.get('nameid_logis')}
            setOpenModal = {setOpenModal}
            getProveedores = {getProveedores}
            departamentosList = {departamentosList}
            provinciasList = {provinciasList}
            provinciasSubList = {provinciasSubList}
            distritosList = {distritosList}
            distritosSubList = {distritosSubList}
            setProvinciasSubList = {setProvinciasSubList}
            setDistritosSubList = {setDistritosSubList}
          />}
        </Card>
      </>
    )
  } else {
    return(
      <>
        <div className='row'>
          <div className='elem-list ml-2'>
            {permisosProveedores === true?
              <Button type='primary'  
                title="Nuevo Artículo" 
                onClick={e=>handleOpenModal('Nuevo Proveedor', null)}
                icon={<PlusOutlined />} >Agregar
              </Button>   

            : null
            }
          </div>
        </div>

        <Card>
          <div>No hay Datos</div>
        
          {<ModalProveedor
            idProveedor = {idProveedor}
            setIdProveedor = {setIdProveedor}
            openModal = {openModal}
            tituloModal = {tituloModal}
            rubrosList = {rubrosList}
            bancosList = {bancosList}
            usuario = {cookies.get('nameid_logis')}
            setOpenModal = {setOpenModal}
            getProveedores = {getProveedores}
            departamentosList = {departamentosList}
            provinciasList = {provinciasList}
            provinciasSubList = {provinciasSubList}
            distritosList = {distritosList}
            distritosSubList = {distritosSubList}
            setProvinciasSubList = {setProvinciasSubList}
            setDistritosSubList = {setDistritosSubList}
          />}
        </Card>
      </>
    )
  }

}

export default Proveedores