import { FileExcelOutlined } from "@ant-design/icons";
import { Button } from "antd";
import moment from "moment";
import ReactExport from 'react-export-excel-xlsx-fix';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ContenidoExcelKardex = (kardex, articulo) => {
  return [
    {
      ySteps: 1,
      columns: [
        { value: "ARTICULO" },
        { value: `${articulo.nombre}`, widthPx: 300 },
      ],
      data: []
    },
    {
      columns: [
        { value: ``},
        { value: ``},
        { value: ``},
        { value: "        ENTRADAS"},
        { value: ``},
        { value: "         SALIDAS"},
        { value: ``},
        { value: "           SALDO"},
      ],
      data: []
    },
    {
      columns: [
        { value: "FECHA" },
        { value: "DESCRIPCIÓN" },
        { value: "V. UNIT." },
        { value: "CANTIDAD" },
        { value: "VALOR" },
        { value: "CANTIDAD" },
        { value: "VALOR" },
        { value: "CANTIDAD" },
        { value: "VALOR" },
      ],
      data: kardex.map(item => {
        return[
          {value: `${moment.utc(item.fecha).format("DD/MM/YYYY")}`},
          {value: `${item.descripcion}`},
          {value: `${item.valor_unitario}`},
          {value: `${item.cantidad_entrada}`},
          {value: `${item.valor_entrada}`},
          {value: `${item.cantidad_salida}`},
          {value: `${item.valor_salida}`},
          {value: `${item.cantidad_saldo}`},
          {value: `${item.valor_saldo}`}
        ]
      })
    }
  ]
}

export const ExcelFileKardex = (kardex, articulo, loadingButton) => {
  return(
    <>
      <ExcelFile element={
        <Button className='bg-color-success ml-2' title="Exportar Excel" 
        // onClick={e => ExportarExcel()}
        icon={<FileExcelOutlined />} loading={loadingButton}>EXCEL
        </Button>} filename={`Kardex_${articulo.nombre}`}>
        <ExcelSheet dataSet={ContenidoExcelKardex(kardex, articulo)} 
          name={`Kardex_${articulo.nombre}`}/>
      </ExcelFile>
    </>
  )
}