import {PDFExport} from '@progress/kendo-react-pdf'
import { Table } from "antd";
import "./styles.css";
import moment from "moment";

const columns = [
  {
    title: '',
    dataIndex: 'index',
    key: 'index',
    width: 50
  },
  {
    title: 'EQUIPO/MATERIAL',
    dataIndex: 'nombre_material',
    key: 'nombre_material',
    width: 500
  },
  // {
  //   title: 'DESCRIPCIÓN',
  //   dataIndex: 'descripcion',
  //   key: 'descripcion'
  // },
  // {
  //   title: 'MARCA',
  //   dataIndex: 'marca',
  //   key: 'marca'
  // },
  {
    title: 'PRESENTACIÓN',
    render: record => {
      return(<>{record.nombre_unidad} X {record.cantidad_unidades}</>)
    }
  },
  {
    title: 'CANTIDAD',
    dataIndex: 'cantidad',
    key: 'cantidad',
    align: 'right',
    width: 100
  },
  {
    title: 'T. UNID.',
    render: record => {
      return(<>{record.cantidad_unidades * record.cantidad}</>)
    },
    align: 'right',
    width: 100
  }
]

const PageTemplate = (props) => {
  return (
    <div style={{ position: "absolute", bottom: "10px", left: "50%" }}>
      Pag. {props.pageNum} de {props.totalPages}
    </div>
  );
};

export const PdfFileItemsReq = (componentePDF, detalle, requerimiento) => {
  return(
    <>
			<div style={{ position: "absolute", left: "-4000px", top: 0 }} >
      <PDFExport 
					ref={componentePDF} 
					paperSize='A4'
					margin="1cm" 
					// landscape={true}
					pageTemplate={PageTemplate}
					fileName={`Items`}
					scale={0.5}
				>
          <div className="mb-2"><b>{requerimiento.codigo}</b></div>
            
            <div className='d-flex justify-content-between'>
              <b>Área: {requerimiento.nombre_area}</b>
              <b>Fecha de Solicitud: {moment.utc(requerimiento.fecha).format('DD/MM/YYYY')}</b>
            </div>

            <div className="mt-2"><b>Solicitante: {requerimiento.nombre_responsable}</b></div>
          <Table
            pagination={false}
            columns={columns}
            dataSource={detalle}
            size='small'
          />
        </PDFExport>
      </div>
    </>
  )
}