import { Table, Tag, Popconfirm, Button, Form, message } from 'antd';
import { CheckOutlined, CloseOutlined, EditOutlined, FilePdfOutlined, MessageOutlined, ReloadOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import moment from "moment";
import { useRef, useState } from 'react';
import ModalFormularioOrdenCompra from '../componentesOrdenesCompras/ModalFormularioOrdenCompra';
import { DownloadFile } from '../FuncionesGenerales';
import ModalSubirArchivo from '../ModalSubirArchivo';
import { PdfFileOrden } from '../componentesPDF/GenerarPDFOrden';
import { PdfFileComparativoCot } from '../componentesPDF/GenerarPDFComparativoCot';
import ModalComentarioCot from '../ModalComentarioCot';

const CotizacionesByReq = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [openModalComent, setOpenModalComent] = useState(false);
  const [openModalArchivo, setOpenModalArchivo] = useState(false);
  const [cotizacion, setCotizacion] = useState({
    id_cotizacion:null,
    id_requerimiento:null, 
    ubicacion_archivo_comparativo:null,
    id_area: null,
    nombre_area: null,
    nombre_responsable: null,
    Parc_Valor: null,
    reemplazo: null,
    ubicacion_archivo: null,
    nombre_usuario_aprobacion: null,
    comentario: ''
  })
  const [form] = Form.useForm();
  const [nuevaOrdenCompra, setNuevaOrdenCompra] = useState({
    id_orden_compra: null,
    id_cotizacion: cotizacion.id_cotizacion,
    tipo_documento: null,
    tipo_pago: null,
    forma_pago: null,
    comentario: null,
    fecha_orden_compra: moment.utc(new Date()).format('YYYY-MM-DD'),
    ubicacion_archivo_factura_guia: null,
    id_consolidado: null,
    fecha_envio: null,
    tipo_entrega: null,
    dias_plazo: null,
    lugar_ejecucion: null,
    penalidades: null
  })
  const [detalleOrden, setDetalleOrden] = useState([])
  const [detallePDF, setDetallePDF] = useState([])
  const [itemPDF, setItemPDF] = useState({
    nombre_area: null,
    id_consolidado: null,
    nombre_responsable: null,
    id_orden_compra: null
  });
  const [detalleCuentas, setDetalleCuentas] = useState([])
  const componentePDF = useRef(null)
  const componentePDF2 = useRef(null)

  const inputFirma = useRef<HTMLInputElement>(null);
  const handleClick = () => inputFirma.current.click();


  const resetForm = () => form.resetFields();

  const botonAprobar = (rowCotizacion) => {
    // console.log(props.permisoAprobarLogistica, props.rolFinanzas, props.permisoAprobarGerencia, 
    //   rowCotizacion.parv_descripcion, props.tipoCambioHoy, rowCotizacion.total)
    if ( rowCotizacion.parv_descripcion === 'SOLES' ) { /**HASTA S/.1500 Y ES LOGISTICA */
      if ( rowCotizacion.total <= 1500 && (props.permisoAprobarLogistica === true || 
        props.rolFinanzas === true || props.permisoAprobarGerencia === true) ) {
          // console.log('Log')
        return true
      } else {/**ENTRE LOS S/.1500 Y LOS $10000 (PASADO A S/.) Y ES FINANZAS */
        if ( rowCotizacion.total / props.tipoCambioHoy <= 10000 && rowCotizacion.total > 1500 && 
          (props.rolFinanzas === true || props.permisoAprobarGerencia === true) ) {
          // console.log('Finanza')
          return true
        } else {/** ENTRE LOS 10000 HASTA LOS 25000 DOLARES Y ES GERENCIA */
          if ( rowCotizacion.total / props.tipoCambioHoy >= 10000 && rowCotizacion.total / props.tipoCambioHoy <= 25000
            && props.permisoAprobarGerencia === true ) {
            // console.log('Gerencia')
            return true
          } else {
            return false
          }
        }
      }
    } else {
      if (rowCotizacion.total * props.tipoCambioHoy <= 1500 && (props.permisoAprobarLogistica === true 
        || props.rolFinanzas === true || props.permisoAprobarGerencia === true) ) {
        return true
      } else {
        if (rowCotizacion.total <= 10000 && rowCotizacion.total * props.tipoCambioHoy > 1500 && 
          (props.rolFinanzas === true || props.permisoAprobarGerencia === true) ) {
          return true
        } else {
          if ( rowCotizacion.total >= 10000 && rowCotizacion.total <= 25000 && props.permisoAprobarGerencia === true ) {
            return true
          } else {
            return false
          }
        }
      }
    }
  }

  const subirArchivo = async() => {
    // console.log(cotizacion)
    const body = {
      destination:'Logistica',
      filename:`${cotizacion.reemplazo === true?'Cotizacion':'CotizacionComparativo'}_${cotizacion.id_cotizacion}.pdf`,
      id_cotizacion: cotizacion.id_cotizacion,
      usuario_aprobacion: props.usuario,
      nombre_usuario_aprobacion : cotizacion.nombre_usuario_aprobacion
    }

    const bodyForm = new FormData()

    /**AREGAR EL ARCHIVO AL FINAL */
    bodyForm.append('tabla', JSON.stringify(body))
    bodyForm.append('File', cotizacion.reemplazo === true?cotizacion.ubicacion_archivo[0]:cotizacion.ubicacion_archivo_comparativo[0])

    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/${cotizacion.reemplazo === true?'UploadFileCotizacion':'UploadFileComparativaCotizacion'}`,{
        method: "POST",
        body: bodyForm
      }).then(res => res.json())
      .then(data =>{
        message.success(data.message, 5)
        props.getCotizacionesByRequerimiento(cotizacion.id_requerimiento)
        setCotizacion({
          id_cotizacion:null,
          id_requerimiento:null, 
          ubicacion_archivo_comparativo:null,
          id_area: null,
          nombre_area: null,
          nombre_responsable: null,
          Parc_Valor: null,
          reemplazo: null,
          ubicacion_archivo: null,
          nombre_usuario_aprobacion: null,
          comentario: ''
        })
        handleCloseModalArchivo()
      }).catch(err => {
        console.log(err)
        message.error('No se Pudo Subir el Archivo al Servidor')
      });
    } catch (error) {
      console.log(error)
      message.error('No se Pudo Subir el Archivo al Servidor')
    }
  }
  
  const handleOpenModalArchivo = (record) => {
    // console.log(record)
    setOpenModalArchivo(true)
    setCotizacion(record)
  }

  const handleCloseModalArchivo = () => {
    setOpenModalArchivo(false)
    setCotizacion({
      id_cotizacion:null,
      id_requerimiento:null, 
      ubicacion_archivo_comparativo:null,
      id_area: null,
      nombre_area: null,
      nombre_responsable: null,
      Parc_Valor: null,
      reemplazo: null,
      ubicacion_archivo: null,
      nombre_usuario_aprobacion: null,
      comentario: ''
    })
  }

  const ExportarPDF = async(cot) => {
    // console.log(cot)
    try {
      const itemCot = await fetch(`${process.env.REACT_APP_HOSTNAME}/getCotizacionById/${cot.id_cotizacion}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        // setItemPDF(data)
        return (data)
      }).catch(err => {return []})

      const newCorrelativo = await fetch(`${process.env.REACT_APP_HOSTNAME}/getCorrelativoNuevoOrden`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          return ([])
        } else {
          // setDetalleRepr(data)
          return (data)
        }
      }).catch(err => {return []})

      const detItemCot = await fetch(`${process.env.REACT_APP_HOSTNAME}/getDetalleByIdCotizacion/${cot.id_cotizacion}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        // setDetallePDF(data)
        let lista = []
        for (let i = 0; i < data.length; i++) {
          lista = [...lista, {
            id_orden_compra: null,
            id_unidad: data[i].id_unidad,
            cantidad: data[i].cantidad,
            marca: data[i].marca,
            precio: data[i].precio_unitario,
            id_articulo: data[i].id_articulo,
            descripcion: data[i].descripcion,
            cantidad_unidades: data[i].cantidad_unidades,
            nombre_articulo: data[i].nombre_articulo,
            nombre_unidad: data[i].nombre_unidad
          }]
        }
        return (lista)
      }).catch(err => {return []})

      const reprProv = await fetch(`${process.env.REACT_APP_HOSTNAME}/getRepresentantesByIdProveedor/${cot.id_proveedor}`)
      .then(res => res.json())
      .then(data => {
        if (data.message) {
          return ([])
        } else {
          // setDetalleRepr(data)
          return (data)
        }
      }).catch(err => {return []})

      const cuentas = await fetch(`${process.env.REACT_APP_HOSTNAME}/getCuentasProveedor/${cot.id_proveedor}`)
      .then(res => res.json())
      .then(data => {
        if (data.message) {
          return ([])
        } else {
          // setDetalleCuentas(data)
          return (data)
        }
        // console.log(data)
      })
      setItemPDF({
        ...itemCot,
        ...reprProv[0], 
        id_consolidado: null, 
        id_orden_compra: '',
        Parc_Valor: cot.Parc_Valor,
        tipo: cot.tipo,
        nombre_area: cot.nombre_area,
        nombre_responsable: cot.nombre_responsable,
        fecha_orden_compra: cot.fecha_cotizacion,
        descripcion: cot.descripcion,
        ruc: cot.ruc,
        direccion: cot.direccion,
        numero_orden: newCorrelativo[0].correlativoNewOrden,
        comentario: itemCot.comentario_req,
        dias_plazo: null,
        lugar_ejecucion: null,
        penalidades: null
      })
      setDetallePDF(detItemCot)
      // setDetalleRepr(reprProv)
      setDetalleCuentas(cuentas)
      componentePDF.current.save()
      // console.log(itemCot, detItemCot, reprProv, cuentas)
      // console.log(itemPDF)

    } catch (error) {
      console.log(error)
    }
  }

  const VistoBueno = async(cotizacion, valor_visto_bueno) => {
    const body = { ...cotizacion, visto_bueno: valor_visto_bueno }
    props.setLoadingButtonAprobar(true)
    // console.log(cotizacion)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/VistoBuenoCotizacion`,{
        method: "PUT",
        body: JSON.stringify(body),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data => {
        props.setLoadingButtonAprobar(false)
        props.getCotizacionesByRequerimiento(cotizacion.id_requerimiento)
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error)
    }
  }

  const columnsUser = [
    {
      title: 'FECHA',
      key: 'fecha_cotizacion',
      dataIndex: 'fecha_cotizacion',
      render: text => (
        moment.utc(text).format('DD/MM/YY')
      )
    },
    {
      title: 'PROVEEDOR',
      key: 'descripcion',
      dataIndex: 'descripcion'
    },
    {
      title: 'ARCHIVO',
      key: '',
      dataIndex: '',
      render: record => {
        return(
          <>
            {record.ubicacion_archivo === null?null:
              <Button danger 
                title="Descargar Cotización" 
                onClick={e => DownloadFile('Cotizacion', record, record.id_cotizacion)}
                icon={<FilePdfOutlined />} >
              </Button>}
          </>
        )
      },
    },
    {
      title: 'VISTO BUENO',
      key: '',
      dataIndex: '',
      render: record => {
        return(
          <>
            {props.requerimiento.usuario === props.usuario || props.rolResponsableArea === true?
              record.visto_bueno === null? 
                <>
                  <Popconfirm
                    title="¿Dar Visto Bueno?"
                    onConfirm={e=>VistoBueno(record, "SI")}
                    okText="Si" cancelText="No"
                  >
                    <Button size='small' type='primary' className='bg-color-success mr-4'
                      loading = {props.loadingButtonAprobar}
                      icon={<CheckOutlined />}>Si</Button> 
                  </Popconfirm>

                  <Popconfirm
                    title="¿No dar Visto Bueno?"
                    onConfirm={e=>VistoBueno(record, "NO")} 
                    okText="Si" cancelText="No"
                  >
                    <Button size='small' danger 
                      loading = {props.loadingButtonAprobar}
                      icon={<CloseOutlined />}>No</Button> 
                  </Popconfirm>
                </>:<p>{record.visto_bueno}</p>
              :
              <p>{record.visto_bueno === null?'':record.visto_bueno}</p>
            }
          </>
        )
      },
    },
    {
      title: 'COMENTARIO',
      key: '',
      dataIndex: '',
      render: record => {
        return(<>
          <Button type='primary' 
            title="Ver/Ingresar Comentario" 
            onClick={e=>openModalComentario(record)}
            icon={<MessageOutlined />}/>
        </>)
      }
    }
  ]

  const columns = [
    {
      title: '',
      render: record => {
        return(
          <>
            {record.estado !== 'FINALIZADO' && record.aprobado === null && props.rolLogistica === true?
              <Button type='primary' className="bg-color-orange mr-2"
                title="Editar Cotizacion" 
                onClick={e=>handleGetCotizacionById(record.id_cotizacion)}
                icon={<EditOutlined />}/>
            : null}
          </>
        )
      }
    },
    {
      title: 'FECHA',
      key: 'fecha_cotizacion',
      dataIndex: 'fecha_cotizacion',
      render: text => (
        moment.utc(text).format('DD/MM/YY')
      )
    },
    {
      title: 'PROVEEDOR',
      key: 'descripcion',
      dataIndex: 'descripcion'
    },
    {
      title: 'APROBADO',
      key: 'aprobado',
      dataIndex: 'aprobado',
      render : text => {
        switch (text) {
          case null:
            return(<Tag color={'#787878'}>PENDIENTE</Tag>)
          
          case 'NO':
            return(<Tag color={'#AA0404'}>NO</Tag>)
          
          case 'SI':
            return(<Tag color={'#B7CE63'}>SI</Tag>)
          
            default:
            break;
        }
      }
    },
    {
      title: `${props.permisoAprobarLogistica === true? 'APROBAR/COMPARATIVO':'APROBAR'}`,
      key: '',
      dataIndex: '',
      render : record => {
        return(
          <>
            {/* SI ES LOGISTICA (TRUE) Y NO LES CORRESPONDE APROBAR LA COTIZACION (FALSE), SUBIRÁN EL PDF COMPARATIVO */}
            {record.estado !== 'FINALIZADO' && props.permisoAprobarLogistica === true && botonAprobar(record) === false ? 
              <div className='d-flex justify-content-center'>
              {record.ubicacion_archivo_comparativo === null? 
                <>
                <Button size='small' type={'primary'} className='btn-outline mb-2 mr-2' 
                  onClick={e=>handleOpenModalArchivo(record)} icon={<FilePdfOutlined />}
                >Subir PDF
                </Button> 

                {/* <input ref={inputFirma} onChange={(e) => subirArchivoFirma(e.target.files, record)} type='file' style={{ display: 'none' }} />
                <Button type='primary'
                  title="Ver/Ingresar Comentario" 
                  onClick={handleClick}
                >pdf
                </Button> */}
                </>
              :
              null}
              </div>
            :null}

            {record.ubicacion_archivo_comparativo === null?null:
            <Button danger className='mb-2'
                title="Descargar Archivo de Aprobación" 
                onClick={e => DownloadFile('CotizacionComparativo', {
                  ...record, otraUbicacion:record.ubicacion_archivo_comparativo
                }, record.id_cotizacion)}
                icon={<FilePdfOutlined />} >
              </Button>
            }

            {record.estado !== 'FINALIZADO' && botonAprobar(record) === true && record.ubicacion_archivo_comparativo === null? 
              <div className='d-flex justify-content-center'>
                {record.aprobado === "SI"? null:
                  <Popconfirm
                    title="¿Seguro de Aprobar esta Cotización?"
                    onConfirm={e=>AprobarCotizacion(record, "SI")}
                    okText="Si" cancelText="No"
                  >
                    <Button size='small' type='primary' className='bg-color-success mr-4'
                      loading = {props.loadingButtonAprobar} title="Aprobar Cotización"
                      icon={<CheckOutlined />}>Si</Button> 
                  </Popconfirm>
                }

                {record.aprobado === "NO"? null:
                  <Popconfirm
                    title="¿Seguro de Desaprobar esta Cotización?"
                    onConfirm={e=>AprobarCotizacion(record, "NO")} 
                    okText="Si" cancelText="No"
                  >
                    <Button size='small' danger 
                      loading = {props.loadingButtonAprobar} title="Desaprobar Cotización"
                      icon={<CloseOutlined />}>No</Button> 
                  </Popconfirm>
                } 
              </div>
            :null}
          </>
        )
      }
    },
    {
      title: (<><div  style={{width:140}}>APROBADO POR</div></>),
      render: record => {
        return(<>{record.nombre_usuario_aprobacion === null? record.nombre_aprobacion:record.nombre_usuario_aprobacion}</>)
      }
    },
    {
      title: 'COTIZACIÓN',
      key: '',
      dataIndex: '',
      render: record => {
        return(
          <>
            {record.ubicacion_archivo === null?null:
              <Button danger 
                title="Descargar Cotización" 
                onClick={e => DownloadFile('Cotizacion', record, record.id_cotizacion)}
                icon={<FilePdfOutlined />} >
              </Button>}

              {record.estado !== 'FINALIZADO' && record.aprobado !== 'SI' && props.rolLogistica === true? 
              <Button type={'primary'} className='btn-outline ml-2' 
                title='Reemplazar Archivo'
                onClick={e=>handleOpenModalArchivo({...record, reemplazo: true})} icon={<ReloadOutlined />}/>  
              :null}
          </>
        )
      },
    },
    {
      title: 'ORDEN C/S',
      key: '',
      dataIndex: '',
      render: record => {
        return(
          <>
            {record.estado !== 'FINALIZADO' && record.estado !== 'RECHAZADO' && record.aprobado === 'SI' && record.CantOrden === 0?
              <Button className='bg-color-success mr-2' 
                title="Ingresar Orden de Compra a esta Cotización" 
                onClick={e => handleModalOrdenCompra(record)}
                icon={<ShoppingCartOutlined />} >
              </Button>
            :null}

            {record.CantOrden === 0?
            <Button danger 
              title="Exportar Nueva Orden de Compra" 
              onClick={e => ExportarPDF(record)}
              icon={<FilePdfOutlined />}/>
            :null}
          </>
        )
      }
    },
    {
      title: (<><div style={{width:90}}>TOTAL</div></>),
      key: '',
      dataIndex: '',
      render: record => {
        return(
          <>
            {record.Parc_Valor} {record.total.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </>
        )
      }
    },
    {
      title: 'T.C.',
      key: 'TipCambio',
      dataIndex: 'TipCambio'
    },
    {
      title: 'COMENTARIO',
      key: '',
      dataIndex: '',
      render: record => {
        return(<>
          <Button type='primary'
            title="Ver/Ingresar Comentario" 
            onClick={e=>openModalComentario(record)}
            icon={<MessageOutlined />}/>
        </>)
      }
    }
  ]

  const ConvertBase64 = async(file) => {
    return new Promise((resolve, reject) => {
      const filereader = new FileReader()
      filereader.readAsDataURL(file)
      filereader.onload = ( () => {
        const base64_text = filereader.result.toString()
        const base64 = base64_text.substring(base64_text.indexOf(',')+1)
        resolve(base64)
      })
    })
  }

  const subirArchivoFirma = async(e, cotizacion) => {
    console.log(e[0])
    const base64 = await ConvertBase64(e[0])
    // console.log(base64)

    const body = {
      operacion: "enviar_carpeta",
      carpeta_codigo: `LC${cotizacion.id_cotizacion}`,
      titulo: "Documento o carpeta de ejemplo",
      descripcion: "Esta es la descripción de este documento",
      observaciones: "",
      vigencia_horas: 24,
      reemplazar: false,
      firmante: {
          email: "notificaciones@limatambo.com.pe",
          nombre_completo: "WERNHER"
      },
      archivos: [
        {
          nombre: e[0].name.replace('zip','pdf'),
          zip_base64: base64,
          firma_visual: {
            ubicacion_x: "10",
            ubicacion_y: "10",
            largo: "300",
            alto: "40",
            pagina: "1",
            texto: "Firmado digitalmente por: WERNHER\r\nLIMATAMBO TEST\r\nTITULO 123\r\nnotificaciones@limatambo.com.pe\r\njr puno\r\n04/04/2023\r\n Firmado con Watana"
          }
        }
      ]
    }

    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/UploadFileFirma`,{
        method: "POST",
        body: JSON.stringify(body),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
        .then(async data =>{
          console.log(data)
          if (data.error) {
            message.error(data.error)
          } else {
            message.info(data.mensaje)
            await fetch(`${process.env.REACT_APP_HOSTNAME}/SolicitarFirmaAprobacionCotizacion`,{
              method: "PUT",
              body: JSON.stringify(cotizacion),
              headers:{
                'Content-Type': 'application/json'
              }
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    } catch (error) {
      console.log(error)
    }
  } 

  const openModalComentario = (cotizacion) => {
    setOpenModalComent(true)
    setCotizacion({...cotizacion})
  }

  const closeModalComentario = () => {
    setOpenModalComent(false)
    setCotizacion({
      id_cotizacion:null, 
      id_requerimiento:null,
      ubicacion_archivo_comparativo:null,
      id_area: null,
      nombre_area: null,
      nombre_responsable: null,
      Parc_Valor: null,
      reemplazo: null,
      ubicacion_archivo: null,
      nombre_usuario_aprobacion: null,
      comentario: ''
    })
    props.getCotizacionesByRequerimiento(props.requerimiento.id_requerimiento)
  }

  const handleGetCotizacionById = (id_cotizacion) => {
    getCotizacionById(id_cotizacion)
    getDetalleByIdCotizacion(id_cotizacion)
    props.setPestaña('cotizacion')
    props.setNombreCotizacionForm('Editar Cotización')
  }

  const getCotizacionById = async(id_cotizacion) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getCotizacionById/${id_cotizacion}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        props.setCotizacion({
          id_cotizacion: data.id_cotizacion,
          id_requerimiento: data.id_requerimiento,
          total: data.total,
          id_proveedor: data.id_proveedor,
          usuario: data.usuario,
          fecha_cotizacion: moment.utc(data.fecha_cotizacion).format('YYYY-MM-DD'),
          ubicacion_archivo: data.ubicacion_archivo,
          parv_descripcion: data.parv_descripcion,
          TipCambio: data.TipCambio
        })

      })
    } catch (error) {
      console.log(error)
    }
  }

  const getDetalleByIdCotizacion = async(id_cotizacion) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getDetalleByIdCotizacion/${id_cotizacion}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        let lista = []
        for (let i = 0; i < data.length; i++) {
          lista = [...lista,{
            id_cotizacion: data[i].id_cotizacion,
            id_unidad: data[i].id_unidad,
            cantidad: data[i].cantidad,
            precio_unitario: data[i].precio_unitario,
            id_articulo: data[i].id_articulo,
            descripcion: data[i].descripcion,
            marca: data[i].marca,
            cantidad_unidades: data[i].cantidad_unidades
          }]
        }
        props.setDetalleCotList(lista)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleModalOrdenCompra = (cotizacion) => {
    // console.log(cotizacion)
    setOpenModal(true)
    setCotizacion({...cotizacion})
    resetForm()
    setNuevaOrdenCompra({
      id_orden_compra: null,
      id_cotizacion: cotizacion.id_cotizacion,
      tipo_documento: null,
      tipo_pago: null,
      forma_pago: null,
      comentario: null,
      fecha_orden_compra: moment.utc(new Date()).format('YYYY-MM-DD'),
      ubicacion_archivo_factura_guia: null,
      id_consolidado: null,
      fecha_envio: null,
      tipo_entrega: null,
      dias_plazo: null,
      lugar_ejecucion: null,
      penalidades: null
    })
  }

  const handleCloseModalOrdenCompra = () => {
    setOpenModal(false)
    setCotizacion({
      id_cotizacion:null, 
      id_requerimiento:null,
      ubicacion_archivo_comparativo:null,
      id_area: null,
      nombre_area: null,
      nombre_responsable: null,
      Parc_Valor: null,
      reemplazo: null,
      ubicacion_archivo: null,
      nombre_usuario_aprobacion: null,
      comentario: ''
    })
    setDetalleOrden([])
  }

  const AprobarCotizacion = async(cotizacion, valor_aprobado) => {
    const body = { ...cotizacion, aprobado: valor_aprobado, usuario_aprobacion: props.usuario }
    props.setLoadingButtonAprobar(true)
    // console.log(cotizacion)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/AprobarCotizacion`,{
        method: "PUT",
        body: JSON.stringify(body),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data => {
        props.setLoadingButtonAprobar(false)
        props.getCotizacionesByRequerimiento(cotizacion.id_requerimiento)
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error)
    }
  }

  const subTablaUser = (cotizacion) =>{
    const columns = [
      { title: 'MATERIAL/EQUIPO', key: 'nombre_articulo', dataIndex: 'nombre_articulo',
        render: text => {
          return{
            props: {
              style: { background: '#C0E8FF' }
            },
            children: <div>{text}</div>,
          }
        }
      },
      { title: 'DESCRIPCIÓN', key: 'descripcion', dataIndex: 'descripcion',
        render: text => {
          return{
            props: {
              style: { background: '#C0E8FF' }
            },
            children: <div>{text}</div>,
          }
        }
      },
      // { title: 'MARCA', key: 'marca', dataIndex: 'marca',
      //   render: text => {
      //     return{
      //       props: {
      //         style: { background: '#C0E8FF' }
      //       },
      //       children: <div>{text}</div>,
      //     }
      //   }
      // },
      { title: 'PRESENTACIÓN',
        render: record => {
          return{
            props: {
              style: { background: '#C0E8FF' }
            },
            children: <div>{record.nombre_unidad} X {record.cantidad_unidades}</div>,
          }
        }
      },
      { title: 'CANTIDAD', key: 'cantidad', dataIndex: 'cantidad',
        render: text => {
          return{
            props: {
              style: { background: '#C0E8FF' }
            },
            children: <div>{text.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
          }
        }
      }
    ]

    const columnsServ = [
      { title: 'SERVICIO SOLICITADO', key: 'descripcion', dataIndex: 'descripcion',
        render: text => {
          return{
            props: {
              style: { background: '#C0E8FF' }
            },
            children: <div>{text}</div>,
          }
        }
      }
    ]

    const detalleCotizacion = props.detCotizacionesByReq.filter( detalle => {
      return detalle.id_cotizacion === cotizacion.id_cotizacion
    })

    return <Table columns={props.requerimiento.tipo === 'COMPRA'? columns: columnsServ} dataSource={detalleCotizacion} pagination={false} />
  }

  const subTabla = (cotizacion) =>{
    const columns = [
      { title: 'MATERIAL/EQUIPO', key: 'nombre_articulo', dataIndex: 'nombre_articulo',
        render: text => {
          return{
            props: {
              style: { background: '#C0E8FF' }
            },
            children: <div>{text}</div>,
          }
        }
      },
      { title: 'DESCRIPCIÓN', key: 'descripcion', dataIndex: 'descripcion',
        render: text => {
          return{
            props: {
              style: { background: '#C0E8FF' }
            },
            children: <div>{text}</div>,
          }
        }
      },
      // { title: 'MARCA', key: 'marca', dataIndex: 'marca',
      //   render: text => {
      //     return{
      //       props: {
      //         style: { background: '#C0E8FF' }
      //       },
      //       children: <div>{text}</div>,
      //     }
      //   }
      // },
      { title: 'PRESENTACIÓN',
        render: record => {
          return{
            props: {
              style: { background: '#C0E8FF' }
            },
            children: <div>{record.nombre_unidad} X {record.cantidad_unidades}</div>,
          }
        }
      },
      { title: 'CANTIDAD', key: 'cantidad', dataIndex: 'cantidad',
        render: text => {
          return{
            props: {
              style: { background: '#C0E8FF' }
            },
            children: <div>{text.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
          }
        }
      },
      { title: 'PRECIO',
        render: record => {
          return{
            props: {
              style: { background: '#C0E8FF' }
            },
            children: 
              <div>
              {cotizacion.Parc_Valor} {record.precio_unitario.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              </div>
          }
        } 
      },
      {
        title: 'TOTAL',
        key: 'precio_unitario',
        render: record => {
          return{
            props: {
              style: { background: '#C0E8FF' }
            },
            children: 
            <div>
              {cotizacion.Parc_Valor} 
              {(record.cantidad * record.precio_unitario).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </div>
          }
        }
      }
    ]

    const columnsServ = [
      { title: 'SERVICIO SOLICITADO', key: 'descripcion', dataIndex: 'descripcion',
        render: text => {
          return{
            props: {
              style: { background: '#C0E8FF' }
            },
            children: <div>{text}</div>,
          }
        }
      },
      { title: 'PRECIO',
        render: record => {
          return{
            props: {
              style: { background: '#C0E8FF' }
            },
            children: 
              <div>
              {cotizacion.Parc_Valor} {record.precio_unitario.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              </div>
          }
        } 
      }
    ]

    const detalleCotizacion = props.detCotizacionesByReq.filter( detalle => {
      return detalle.id_cotizacion === cotizacion.id_cotizacion
    })

    return <Table columns={props.requerimiento.tipo === 'COMPRA'? columns: columnsServ} dataSource={detalleCotizacion} pagination={false} />
  }

  const ExportarPDF2 = () => {
    componentePDF2.current.save()
  }

  if ( props.cotizacionesByReq.length > 0 ) {
    return(
      <>
      {PdfFileOrden(componentePDF, itemPDF, detallePDF, detalleCuentas)}
      {PdfFileComparativoCot(componentePDF2, props.cotizacionesByReq, props.detCotizacionesByReq, props.requerimiento.tipo)}
        <Button danger 
          title="Exportar PDF Comparativo" 
          onClick={e => ExportarPDF2()}
          icon={<FilePdfOutlined />} >PDF
        </Button>
        <div className='col-sm-12 col-md-12 col-lg-12'>
          <Table 
            pagination={false} 
            columns={props.rolLogistica === true?columns: columnsUser}
            dataSource={props.cotizacionesByReq}
            size="small"
            rowKey={record => record.id_cotizacion}
            expandedRowRender = { record => props.rolLogistica === true?subTabla(record):subTablaUser(record)}
          />
        </div>

        {<ModalFormularioOrdenCompra
          loadingButtonAprobar = {props.loadingButtonAprobar}
          setLoadingButtonAprobar = {props.setLoadingButtonAprobar}
          cotizacion = {cotizacion}
          openModal = {openModal}
          handleCloseModal = {handleCloseModalOrdenCompra}
          nuevaOrdenCompra = {nuevaOrdenCompra}
          setNuevaOrdenCompra = {setNuevaOrdenCompra}
          form = {form}
          materialesList = {props.materialesList}
          unidadesList = {props.unidadesList}
          detalleOrden = {detalleOrden}
          setDetalleOrden = {setDetalleOrden}
          usuario = {props.usuario}
          getOrdenesCompraByRequerimiento = {props.getOrdenesCompraByRequerimiento}
          getDetalleOrdenesByReq = {props.getDetalleOrdenesByReq}
          setCotizacion = {setCotizacion}
          getCotizacionesByRequerimiento = {props.getCotizacionesByRequerimiento}
          requerimiento = {props.requerimiento}
        />}

        {<ModalSubirArchivo
          loadingButtonAprobar = {props.loadingButtonAprobar}
          setLoadingButtonAprobar = {props.setLoadingButtonAprobar}
          openModal = {openModalArchivo}
          handleCloseModal = {handleCloseModalArchivo}
          ubicacion_archivo = {cotizacion.reemplazo === true? cotizacion.ubicacion_archivo:cotizacion.ubicacion_archivo_comparativo}
          setCotizacion = {setCotizacion}
          UploadArchivo = {subirArchivo}
          cotizacion = {cotizacion}
        />}

        {<ModalComentarioCot
          openModal = {openModalComent}
          cotizacion = {cotizacion}
          closeModalComentario = {closeModalComentario}
          titulo = {props.rolLogistica === true?'Ver Comentario':'Ingresar/Editar Comentario'}
        />}
      </>
    )  
  } else {
    return(
      <>
        <div>
          No hay Cotizaciones
        </div>
      </>
    )
  }
}

export default CotizacionesByReq