import { CloseOutlined, DeleteOutlined, PlusOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Modal, message, Select } from 'antd';
import { useState, useEffect } from "react";
import { Option } from "antd/lib/mentions";

const ModalAsignarChofer = (props) => {
  const [loadingButton, setLoadingButton] = useState(false)
  const [detalleChoferes, setDetalleChoferes] = useState([])
  const [vehiculo, setVehiculo] = useState({
    id_vehiculo: null,
    nombre: null,
    placa: null
  })
  const optionFilter = (input, option) => {
    return (option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0)
  }

  useEffect(() => {
    if (props.openModal === true) {
      setVehiculo(props.vehiculo)
      setDetalleChoferes(props.detalleChoferes)
    } else {
      setVehiculo({
        id_vehiculo: null,
        nombre: null,
        placa: null
      })
      setDetalleChoferes([])
    }
  }, [props.detalleChoferes]);

  const handleCloseModal = () => {
    props.setOpenModal(false)
    setDetalleChoferes([])
    setVehiculo({
      id_vehiculo: null,
      nombre: null,
      placa: null
    })
  }

  const Guardar = async() => {
    if (detalleChoferes.length === 0) {
      message.warn('Debe haber al menos un Chofer')
    } else {
      const body = {
        vehiculo: {...vehiculo},
        detalleChoferes: detalleChoferes
      }
      setLoadingButton(true)
      try {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/AsignarChoferes`, {
          method: "POST",
          body: JSON.stringify(body),
          headers:{
            'Content-Type': 'application/json'
          }
        }).then(res => res.json())
        .then(data =>{
          // console.log(data)
          message.success(data.mensaje)
          setLoadingButton(false)
          handleCloseModal()
        }).catch(err => {
          console.log(err)
          setLoadingButton(false)
        })
      } catch (error) {
        console.log(error)
        setLoadingButton(false)
      }
    }
  }

  return(
    <>
      <Modal
        visible={props.openModal}
        closable={false}
        width='80%'
        title={<h3 className='m-0'>Choferes Asignados al {vehiculo.nombre}/{vehiculo.placa}</h3>}
        onCancel={handleCloseModal}
        footer={null}
      >
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <Select
              showSearch
              value={detalleChoferes}
              className='mb-lg-0'
              placeholder='Seleccionar Choferes'
              optionFilterProp='children'
              mode='multiple'
              filterOption={optionFilter}
              onChange={e=>setDetalleChoferes(e)}
            >
            {props.conductoresList.map(conductor =>{
              return(
                <Option value={conductor.id_conductor} key={conductor.id_conductor}>{conductor.nombres} {conductor.apellidos}</Option>
              )
            })}
            </Select>
          </div>
        </div>

        <div className='modal-footer d-flex justify-content-between mt-3'>
          <Button danger onClick={handleCloseModal} icon={<CloseOutlined />}>
            Cerrar
          </Button>
          
          <Button className='bg-color-success' loading={loadingButton}
            type='primary' icon={<SaveOutlined />} onClick={e => Guardar()}>
            Guardar
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default ModalAsignarChofer