import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Input, Modal, message } from 'antd';
import { getTipoCambioToday } from './FuncionesGenerales';

const ModalTipoCambio = (props) => {

  const AddTipoCambioToday = async() => {
    if (props.tabla.TipCambio > 0) {
      const body = { usuario: props.usuario, TipCambio: props.tabla.TipCambio } 
      try {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/AddTipoCambioToday`,{
          method: "POST",
          body: JSON.stringify(body),
          headers:{
            'Content-Type': 'application/json'
          }
        }).then(res => {
          props.setLoadingButtonAprobar(false)
          // props.handleCloseModal()
          handleCloseModalTipoCambio()
        }).catch(err => console.log(err));
      } catch (error) {
        console.log(error)
      }
    } else {
      message.error('Ingresar el Tipo de Cambio')
    }
  }

  const handleCloseModalTipoCambio = async() => {
    props.setOpenModal(false)
    const cambioToday = await getTipoCambioToday()
    if (cambioToday.message) {
      props.setTabla({...props.tabla, 
        TipCambio: 1
      })
    } else {
      props.setTabla({...props.tabla, 
        TipCambio: cambioToday[0].TipCambio
      })
    }
  }

  return(
    <>
      <Modal
        visible={props.openModal}
        closable={false}
        width='50%'
        title={<h3 className='m-0'>Ingresar Tipo de Cambio</h3>}
        onCancel={handleCloseModalTipoCambio}
        footer={
          <div className='modal-footer d-flex justify-content-between'>
            <Button danger onClick={handleCloseModalTipoCambio} icon={<CloseOutlined />}>
              Cerrar
            </Button>
            
            <Button className='bg-color-success' loading={props.loadingButton}
              type='primary' icon={<SaveOutlined />} onClick={AddTipoCambioToday}>
              Guardar
            </Button>
          </div>
        }
      >
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <div className='elem-list'>
              <div className='col-sm-11 col-md-6 col-lg-6'>
                  <Input value={props.tabla.TipCambio} type='number'
                    onChange={e=>props.setTabla({
                      ...props.tabla, TipCambio:e.currentTarget.value
                    })}
                      placeholder = "Ingresar Valor"
                  />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModalTipoCambio