import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Input, Modal, message, Form, Card, Select } from 'antd';
import { useEffect, useState, useRef } from 'react';
import { Rule } from 'antd/lib/form';
import { Option } from 'antd/lib/mentions';

const { Item } = Form;

const rules = {
  required: { required: true, message: <></> } as Rule
};

const ModalFormularioOrdenTrabajo = (props) => {
  const [loadingButton, setLoadingButton] = useState(false)
  const [ordenTrabajo, setOrdenTrabajo] = useState({
    id_orden_trabajo: null,
    id_inventario: null,
    descripcion: null,
    estado: 'ANALISIS',
    id_proveedor: null,
    precio: '',
    nombre_articulo: '',
    marca: '',
    modelo: '',
    serie: '',
    fecha_orden: '',
    tipo_mantenimiento: undefined,
    tipo: undefined,
    notas: null,
    CantFiles: 0
  })

  const [archivos, setArchivos] = useState([undefined])

  const optionFilter = (input, option) => {
    return (option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0)
  }

  const input = useRef<HTMLInputElement>(null);
  const handleClick = () => input.current.click();

  useEffect(() => {
    if (props.openModal === true) {    
      setOrdenTrabajo(props.ordenTrabajo)
    } else {
      setOrdenTrabajo({
        id_orden_trabajo: null,
        id_inventario: null,
        descripcion: null,
        estado: 'ANALISIS',
        id_proveedor: null,
        precio: '',
        nombre_articulo: '',
        marca: '',
        modelo: '',
        serie: '',
        fecha_orden: '',
        tipo_mantenimiento: undefined,
        tipo: undefined,
        notas: null,
        CantFiles: 0
      })
    }
  }, [props.ordenTrabajo]);

  const handleCloseModal = () => {
    props.setOpenModal(false)
    setOrdenTrabajo({
      id_orden_trabajo: null,
      id_inventario: null,
      descripcion: null,
      estado: 'ANALISIS',
      id_proveedor: null,
      precio: '',
      nombre_articulo: '',
      marca: '',
      modelo: '',
      serie: '',
      fecha_orden: '',
      tipo_mantenimiento: undefined,
      tipo: undefined,
      notas: null,
      CantFiles: 0
    })
    setArchivos([undefined])
  }

  const AddOrdenTrabajo = async () => {
    setLoadingButton(true)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/AddOrdenTrabajo`,{
        method: "POST",
        body: JSON.stringify({...ordenTrabajo, usuario:props.usuario}),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data => {
        // setLoadingButton(false)
        if (archivos[0] === undefined) {
          message.success(data.message)
          setLoadingButton(false)
          if (props.getOrdenesTrabajo) {
            props.getOrdenesTrabajo()
          }
          handleCloseModal()
          if (props.getInventarios) {
            props.getInventarios()  
          }
        } else {
          if (props.getInventarios) {
            props.getInventarios()  
          }
          UpdateOrdenTrabajoFile(data.id_orden_trabajo)
        }
        
      }).catch(err => {
        console.log(err)
        setLoadingButton(false)
        handleCloseModal()  
      });
    } catch (error) {
      setLoadingButton(false)
      console.log(error)
      message.error(error)
      handleCloseModal() 
    }
  }

  const UpdateOrdenTrabajoFile = async(id_orden_trabajo) => {
    const body = {
      destination:'Logistica',
      id_orden_trabajo: id_orden_trabajo,
      varios: true
    }
    const bodyForm = new FormData()
    
    bodyForm.append('tabla', JSON.stringify(body))
    for (let i = 0; i < archivos.length; i++) {
      const file = archivos[i];
      const filename = file['name']
      const fileExtension = filename.split('.').pop()
      bodyForm.append('File', archivos[i], `OrdenTrabajo_${id_orden_trabajo}_${ordenTrabajo.CantFiles+i}.${fileExtension}`)
    }

    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/UpdateOrdenTrabajoFile`,{
        method: "POST",
        body: bodyForm
      }).then(res => res.json())
      .then(data => {
        message.success(data.message)
        setLoadingButton(false)
        handleCloseModal()
      }).catch(err => {
        console.log(err)
        message.error('No se Pudo Subir el Archivo al Servidor')
        setLoadingButton(false)
      });
    } catch (error) {
      console.log(error)
      message.error('No se Pudo Subir el Archivo al Servidor')
      setLoadingButton(false)
    }
  }

  const UpdateOrdenTrabajo = async () => {
    setLoadingButton(true)
    
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/UpdateOrdenTrabajo`,{
        method: "PUT",
        body: JSON.stringify(ordenTrabajo),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data => {
        // console.log(data)
        message.success(data.message)
        setLoadingButton(false)
        if (props.getOrdenesTrabajo) {
          props.getOrdenesTrabajo()
        }
        handleCloseModal()
        if (props.getInventarios) {
          props.getInventarios()  
        }
        // console.log(archivos)
        if (archivos[0] === undefined) {
        
        } else {
          UpdateOrdenTrabajoFile(ordenTrabajo.id_orden_trabajo)
        }
      }).catch(err => {
        console.log(err)
        setLoadingButton(false)
        handleCloseModal()  
      });
    } catch (error) {
      setLoadingButton(false)
      console.log(error)
      message.error(error)
      handleCloseModal() 
    }
  }

  const nombresItems = () => {
    // console.log(ordenTrabajo.ubicacion_archivo)
    let nombres = []
    for (let i = 0; i < archivos.length; i++) {
      const element = archivos[i];
      nombres = [...nombres, {nombre_file: element.name}]
    }
    return nombres
  }

  const subirArchivo = (e) => {
    setArchivos(e)
  }

  const ChangeInventario = (value) => {
    const newInv = props.inventariosList.filter(inv => {
      return inv.id_inventario === value
    })
    // console.log(newInv[0])
    setOrdenTrabajo({
      ...ordenTrabajo, 
      id_inventario: value,
      nombre_articulo: newInv[0].nombre_articulo,
      marca: newInv[0].marca,
      modelo: newInv[0].modelo,
      serie: newInv[0].serie
    })
    props.form.setFieldsValue({
      nombre_articulo: newInv[0].nombre_articulo,
      marca: newInv[0].marca,
      modelo: newInv[0].modelo,
      serie: newInv[0].serie
    })
  }

  return(
    <>
      <Modal
        visible={props.openModal}
        closable={false}
        width='80%'
        title={<h3 className='m-0'>{props.tituloModal}</h3>}
        onCancel={handleCloseModal}
        footer={null}
      >
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <Form form={props.form} layout='vertical' onFinish={ props.tituloModal === 'Nueva Orden de Trabajo'? AddOrdenTrabajo : UpdateOrdenTrabajo}>
              <div className='elem-list'>
                <div className='col-sm-11 col-md-11 col-lg-11'>
                  <Item label="Inventario" name="id_inventario">
                    <Select
                      showSearch
                      value={ordenTrabajo.id_inventario}
                      className='mb-lg-0'
                      placeholder='Seleccionar'
                      optionFilterProp='children'
                      filterOption={optionFilter}
                      onChange={e=> ChangeInventario(e)}
                      disabled = {props.tituloModal === 'Nueva Orden de Trabajo'? true: false}
                    >
                      {props.inventariosList.map(inventario => {
                        return(
                          <Option value={inventario.id_inventario} key={inventario.id_inventario}>
                            {inventario.codigo_patrimonial} - {inventario.nombre_articulo}
                          </Option>
                        )
                      })}
                    </Select>    
                  </Item>
                </div>
              </div>

              <div className='elem-list'>
                <div className='col-sm-11 col-md-5 col-lg-5'>
                  <Item label="Equipo" name="nombre_articulo">
                    <label>{ordenTrabajo.nombre_articulo}</label>
                  </Item>
                </div>

                <div className='col-sm-11 col-md-2 col-lg-2'>
                  <Item label="Marca" name="marca">
                    <label>{ordenTrabajo.marca}</label>
                  </Item>
                </div>

                <div className='col-sm-11 col-md-2 col-lg-2'>
                  <Item label="Modelo" name="modelo">
                    <label>{ordenTrabajo.modelo}</label>
                  </Item>
                </div>

                <div className='col-sm-11 col-md-2 col-lg-2'>
                  <Item label="Serie" name="serie">
                    <label>{ordenTrabajo.serie}</label>
                  </Item>
                </div>
              </div>

              <div className='elem-list'>
                <div className='col-sm-11 col-md-2 col-lg-2'>
                  <Item label="Fecha Mant./Cal." name="fecha_orden" rules={[
                    {...rules.required, message: 'La Fecha es Requerida'}
                  ]}>
                    <Input name='fecha_orden' defaultValue = {ordenTrabajo.fecha_orden}
                      type="date" onChange={e=>setOrdenTrabajo({
                        ...ordenTrabajo, fecha_orden: e.currentTarget.value
                      })}
                    />
                  </Item>
                </div>

                <div className='col-sm-11 col-md-2 col-lg-2'>
                  <Item label="Tipo Trabajo" name="tipo_mantenimiento" rules={[
                    {...rules.required, message: 'El Tipo es Requerido'}
                  ]}>
                    <Select
                      showSearch
                      defaultValue={ordenTrabajo.tipo_mantenimiento}
                      className='mb-lg-0'
                      placeholder='Seleccionar'
                      optionFilterProp='children'
                      filterOption={optionFilter}
                      onChange={e=>setOrdenTrabajo({
                        ...ordenTrabajo, tipo_mantenimiento: e
                      })}
                    >
                      <Option value='MANTENIMIENTO' key='MANTENIMIENTO'>MANTENIMIENTO</Option>
                      <Option value='CALIBRACION' key='CALIBRACION'>CALIBRACIÓN</Option>
                      <Option value='PRESTAMO' key='PRESTAMO'>PRÉSTAMO DE EQUIPO</Option>
                      <Option value='CAMBIO' key='CAMBIO'>CAMBIO DE ÁREA</Option>
                      <Option value='BAJA' key='BAJA'>BAJA DE EQUIPO</Option>
                      <Option value='INSTALACION' key='INSTALACION'>INSTALACIÓN</Option>
                      <Option value='CAPACITACION' key='CAPACITACION'>CAPACITACIÓN</Option>
                    </Select>  
                  </Item>
                </div>

                <div className='col-sm-11 col-md-2 col-lg-2'>
                  <Item label="Tipo Mant." name="tipo">
                    <Select
                      showSearch
                      defaultValue={ordenTrabajo.tipo}
                      className='mb-lg-0'
                      placeholder='Seleccionar'
                      optionFilterProp='children'
                      filterOption={optionFilter}
                      onChange={e=>setOrdenTrabajo({
                        ...ordenTrabajo, tipo: e
                      })}
                      allowClear
                    >
                      <Option value='PROGRAMADO' key='PROGRAMADO'>PROGRAMADO</Option>
                      <Option value='CORRECTIVO' key='CORRECTIVO'>CORRECTIVO</Option>
                    </Select>  
                  </Item>
                </div>
              </div>

              <div className='elem-list'>
                <div className='col-sm-11 col-md-4 col-lg-4'>
                  <Item label="Proveedor" name="id_proveedor">
                    <Select
                      showSearch
                      defaultValue={ordenTrabajo.id_proveedor}
                      className='mb-lg-0'
                      placeholder='Proveedor'
                      optionFilterProp='children'
                      filterOption={optionFilter}
                      onChange={e=>setOrdenTrabajo({
                        ...ordenTrabajo, id_proveedor: e
                      })}
                    >
                      {props.proveedoresList.map(proveedor => {
                        return(
                          <Option value={proveedor.id_prove} key={proveedor.id_prove}>{proveedor.descripcion}</Option>
                        )
                      })}
                    </Select>  
                  </Item>
                </div>

                <div className='col-sm-11 col-md-3 col-lg-3'>
                  <Item label="Estado" name="estado" rules={[
                    {...rules.required, message: 'El Estado es Requerido'}
                  ]}>
                    <Select
                      showSearch
                      defaultValue={ordenTrabajo.estado}
                      className='mb-lg-0'
                      placeholder='Estado'
                      optionFilterProp='children'
                      filterOption={optionFilter}
                      onChange={e=>setOrdenTrabajo({
                        ...ordenTrabajo, estado: e
                      })}
                    >
                      <Option value='ANALISIS' key='ANALISIS'>ANÁLISIS</Option>
                      <Option value='PROCESO' key='PROCESO'>PROCESO</Option>
                      <Option value='FINALIZADO' key='FINALIZADO'>FINALIZADO</Option>
                      <Option value='CANCELADO' key='CANCELADO'>CANCELADO</Option>
                    </Select>  
                  </Item>
                </div>

                <div className='col-sm-11 col-md-3 col-lg-3'>
                  <Item label="Precio" name="precio" rules={[
                    {...rules.required, message: 'El Precio es Requerido'}
                  ]}>
                    <Input type="number" name='precio' defaultValue={ordenTrabajo.precio}
                      onChange={e=> setOrdenTrabajo( {
                        ...ordenTrabajo, precio: e.currentTarget.value
                      })}
                    />
                  </Item>
                </div>
              </div>

              <div className='elem-list'>
                <div className='col-sm-11 col-md-11 col-lg-11'>
                  <Item label="Descripcion" name="descripcion" rules ={[
                    {...rules.required, message: 'Campo Requerido'}
                  ]}>
                    <Input.TextArea defaultValue={ordenTrabajo.descripcion} 
                      onChange={e=>setOrdenTrabajo({
                        ...ordenTrabajo, descripcion:e.currentTarget.value
                      })}
                        placeholder = "Descripción de la Tarea"
                    />
                  </Item>
                </div>
              </div>

              <div className='elem-list'>
                <div className='col-sm-11 col-md-11 col-lg-11'>
                  <Item label="Notas" name="notas">
                    <Input.TextArea defaultValue={ordenTrabajo.notas} 
                      onChange={e=>setOrdenTrabajo({
                        ...ordenTrabajo, notas:e.currentTarget.value
                      })}
                        placeholder = "Notas de la Tarea"
                    />
                  </Item>
                </div>
              </div>

              <div className='elem-list mb-4'>
                <div className='col-sm-11 col-md-5 col-lg-5'>
                  {archivos[0] === undefined?null:
                    <>
                      {nombresItems().map((item, index) => {
                        return( 
                          <><b className='mr-4'>{index+1}{item.nombre_file}</b><br/></>
                        )
                      })}
                    </>
                  }
                  <input ref={input} onChange={(e) => subirArchivo(e.target.files)} type='file' style={{ display: 'none' }} multiple/>
                  <Button size='small' type={'primary'} className='btn-outline' onClick={handleClick}>Subir Archivo(s)</Button>
                </div>
              </div>
              
              <div className='modal-footer d-flex justify-content-between'>
                <Button danger onClick={handleCloseModal} icon={<CloseOutlined />}>
                  Cerrar
                </Button>
                
                <Button className='bg-color-success' loading={loadingButton}
                  type='primary' icon={<SaveOutlined />} htmlType="submit">
                  Guardar
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModalFormularioOrdenTrabajo