import {useEffect, useState, useRef} from "react"
import { IPageData } from "../../../interfaces/page"
import { usePageData } from '../../../hooks/usePage'
import Cookies from "universal-cookie"
import moment from "moment"
import { Card, Table, Button, Tag, Select, Input, Form, Modal, Popconfirm, message } from 'antd'
import { CloseOutlined, DeleteOutlined, EditOutlined, EyeOutlined, FileExcelOutlined, PlusOutlined, QrcodeOutlined } from "@ant-design/icons"
import { Option } from 'antd/lib/mentions'
import ModalInventarios from "./ModalInventario"
import { existeRol } from "../FuncionesGenerales"
import ModalFormularioOrdenTrabajo from "../componentesOrdenesTrabajo/ModalFormularioOrdenTrabajo"
import OrdenesTrabajo from "../componentesOrdenesTrabajo/OrdenesTrabajo"
import ModalQRInventario from "./ModalQRInventario"
import { InventariosExcel } from "../componentesExcel/GenerarExcelInventarios"

const cookies = new Cookies()

const pageData: IPageData = {
  fulFilled: true,
  title: 'Inventarios',
  breadcrumbs: [
    {
      title: 'Inventarios',
    }
  ]
}

const Inventarios = () => {
  usePageData(pageData)

  const [permisoInventarios, setPermisoInventarios] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openModalQR, setOpenModalQR] = useState(false)
  const [openModalOrden, setOpenModalOrden] = useState(false)
  const [openModalOrdenesList, setOpenModalOrdenesList] = useState(false)
  const [tituloModal, setTituloModal] = useState('')
  const [tituloModalOrden, setTituloModalOrden] = useState('')
  const [inventariosList, setInventariosList] = useState([])
  const [cantidadInvAreaList, setCantidadInvAreaList] = useState([])
  const [resumenInvAreasList, setResumenInvAreasList] = useState([])
  const [articulosList, setArticulosList] = useState([])
  const [inventarioSeleccionado, setInventarioSeleccionado] = useState({
    id_inventario: null,
    id_area: null,
    id_articulo: null,
    marca: '',
    modelo: '',
    serie: '',
    operativo: false,
    calibracion: false,
    id_proveedor: null,
    codigo_patrimonial: '',
    meses_proximo_mant: '',
    meses_proximo_cali: '',
    nombre_articulo: null,
    descripcion: ''
  })
  const [ordenTrabajo, setOrdenTrabajo] = useState({})
  const [areasList, setAreasList] = useState([])
  const [proveedoresList, setProveedoresList] = useState([])
  const [selectArea, setSelectArea] = useState(undefined)
  const [selectArticulo, setSelectArticulo] = useState(undefined)
  const [selectProveedorMant, setSelectProveedorMant] = useState(undefined)
  const [selectProveedorCal, setSelectProveedorCal] = useState(undefined)
  const [selectProveedor, setSelectProveedor] = useState(undefined)
  const [rubrosList, setRubrosList] = useState([])
  const [inputMarca, setInputMarca] = useState('')
  const [inputModelo, setInputModelo] = useState('')
  const [inputSerie, setInputSerie] = useState('')
  const [inputCodigoPat, setInputCodigoPat] = useState('')

  const componenteExcel = useRef(null)
  const componenteExcel2 = useRef(null)
  const componenteExcel3 = useRef(null)

  const [form] = Form.useForm()
  const resetForm = () => form.resetFields()
  const [formOT] = Form.useForm()
  const resetFormOT = () => formOT.resetFields()

  const optionFilter = (input, option) => {
    return (option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      getInventarios()
    }, 10000);
    return () => clearInterval(interval);
  }, [inventariosList])

  useEffect(() => {
    if (!cookies.get('nameid_logis') || existeRol('Logistica.Acceso.Inventarios') === false) {
      // history.push(`/public/sign-in`)
      window.location.href='/'    
    } else {
      getInventarios()
      getArticulos()
      getAreas()
      getProveedores()
      getRubros()
      GetCantidadInventarioByAreas()
      GetInventariosByAreas()
    }

    setPermisoInventarios(existeRol('Logistica.Gestion.Inventarios'))

  }, [])

  const getRubros = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getRubros`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        const rubro_equipo_biomedico = data.filter(rubro => {
          //EQUIPO BIOMEDICO, MOBILIARIO HOSPITALARIO, INSTRUMENTAL MÉDICO
          return( rubro.checkInventario === true ) 
        })
        setRubrosList(rubro_equipo_biomedico)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getInventarios = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getInventarios`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setInventariosList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const GetCantidadInventarioByAreas = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/GetCantidadInventarioByAreas`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setCantidadInvAreaList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const GetInventariosByAreas = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/GetInventariosByAreas`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setResumenInvAreasList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getProveedores = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getProveedores`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setProveedoresList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getAreas = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getAreas/${1}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (existeRol('Logistica.Acceso.LimatamboFast') === true) {
          const limatamboFast = data.filter(area => {
            return area.id_area === 52
          })
          setAreasList(limatamboFast) 
          setSelectArea(52)
        } else {
          setAreasList(data) 
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getArticulos = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getArticulos`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        const equipos_biomedicos = data.filter(equipo => {
          //50 = rubro "EQUIPO BIOMEDICO", 74 = "MOBILIARIO HOSPITALARIO"
          return( equipo.checkInventario === true )
        })
        setArticulosList(equipos_biomedicos)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const DarDeBajaInventario = async (inventario) => {
    // console.log(props.inventario)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/DarDeBajaInventario`,{
        method: "PUT",
        body: JSON.stringify(inventario),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data =>{
        message.success(data.message)
        getInventarios()
      }).catch(err => {
        console.log(err)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const columns = [
    {
      title: (<div style={{width:200}}></div>),
      render: record => {
        return(
          <>
            {permisoInventarios === true?
            <>
              <Button type='primary' className="bg-color-orange mr-1"
                title="Editar" 
                onClick={e=>handleOpenModal('Editar Inventario', record.id_inventario)}
                icon={<EditOutlined />} />

              <Button type='primary' title="Agregar OT" className='bg-color-success mr-1' 
                onClick={e=>handleOpenModalOrden('Nueva Orden de Trabajo', record)}
                icon={<PlusOutlined />} />

              <Popconfirm
                title={`Dar de Baja`}
                onConfirm={ e =>DarDeBajaInventario(record)} 
                okText="Si" cancelText="No"
                >
                <Button danger className='mr-2'
                  title="Dar de Baja"
                  icon={<DeleteOutlined />}
                /> 
              </Popconfirm>
            </>
            : null
            }
            <Button type='primary' title="Ver Órdenes de Trabajo" className="mr-1"
                onClick={e => handleOpenModalOrdenesList(record)}
                icon={<EyeOutlined />} />

            <Button title="Ver QR" className="mr-1"
              onClick={e => handleOpenModalQR(record)}
              icon={<QrcodeOutlined />} />
          </>
        )
      }
    },
    {
      title: (
        <>
          <div style={{width:170}}>
            <Input defaultValue={inputCodigoPat} 
              onChange={e=>setInputCodigoPat(e.currentTarget.value)}
                placeholder = "CÓD. PATRIMONIAL"
            />
          </div>
        </>
      ),
      dataIndex: 'codigo_patrimonial',
      key: 'codigo_patrimonial'
    },
    {
      title: (
        <>
          <div style={{width:160}} className="mr-4">
            <Select
              showSearch
              allowClear
              defaultValue={selectArea}
              className='mr-2'
              placeholder='ÁREA'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={e => setSelectArea(e)}
              disabled = {existeRol('Logistica.Acceso.LimatamboFast') === true?true:false}
            >
              {areasList.map(area =>{
                return(
                  <Option value={area.id_area} key={area.id_area}>{area.nombre}</Option>
                )
              })}
            </Select>
          </div>
        </>
      ),
      dataIndex: 'nombre_area',
      key: 'nombre_area'
    },
    {
      title: (
        <>
          <div style={{width:180}}>
            <Select
              showSearch
              allowClear
              defaultValue={selectArticulo}
              className='mr-2'
              placeholder='EQUIPO'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={e => setSelectArticulo(e)}
            >
              {articulosList.map(articulo => {
                return(
                  <Option value={articulo.id_articulo} 
                    key={articulo.id_articulo}>
                    {articulo.identificador} - {articulo.nombre}
                  </Option>
                )
              })}
            </Select>
          </div>
        </>
      ),
      // dataIndex: 'nombre_articulo',
      // key: 'nombre_articulo'
      render: record => {
        return(<label>{record.identificador} - {record.nombre_articulo}</label>)
      }
    },
    {
      title: (
        <>
          <div style={{width:120}}>
            <Input defaultValue={inputMarca} 
              onChange={e=>setInputMarca(e.currentTarget.value)}
                placeholder = "MARCA"
            />
          </div>
        </>
      ),
      dataIndex: 'marca',
      key: 'marca'
    },
    {
      title: (
        <>
          <div style={{width:120}}>
            <Input defaultValue={inputModelo} 
              onChange={e=>setInputModelo(e.currentTarget.value)}
                placeholder = "MODELO"
            />
          </div>
        </>
      ),
      dataIndex: 'modelo',
      key: 'modelo'
    },
    {
      title: (
        <>
          <div style={{width:120}}>
            <Input defaultValue={inputSerie} 
              onChange={e=>setInputSerie(e.currentTarget.value)}
                placeholder = "SERIE"
            />
          </div>
        </>
      ),
      dataIndex: 'serie',
      key: 'serie'
    },
    {
      title: (
        <>
          <div style={{width:180}} className="mr-4">
            <Select
              allowClear
              showSearch
              defaultValue={selectProveedor}
              className='mb-lg-0'
              placeholder='PROVEEDOR'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={(e)=>setSelectProveedor(e)}
            >
            {proveedoresList.map(proveedor =>{
              return(
                <Option value={proveedor.id_prove} key={proveedor.id_prove}>{proveedor.descripcion}</Option>
              )
            })}
            </Select>
          </div>
        </>
      ),
      dataIndex: 'proveedor',
      key: 'proveedor'
    },
    {
      title: 'OPERATIVO',
      dataIndex: 'operativo',
      key: 'operativo',
      render: text => (
        text === false?'NO':'SI'
      )
    },
    {
      title: 'F. ULT. MANT.',
      render: record => {
        if (record.ultima_fecha_mant === null) {
          return null
        } else {
          return (<label>{moment.utc(record.ultima_fecha_mant).format('DD/MM/YY')} {`(${record.tipo_mant})`}</label>)
        }
      }
    },
    {
      title: (
        <>
          <div style={{width:200}} className="mr-4">
            <Select
              allowClear
              showSearch
              defaultValue={selectProveedorMant}
              className='mb-lg-0'
              placeholder='PROVEEDOR ÚLT. MANT.'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={(e)=>setSelectProveedorMant(e)}
            >
            {proveedoresList.map(proveedor =>{
              return(
                <Option value={proveedor.id_prove} key={proveedor.id_prove}>{proveedor.descripcion}</Option>
              )
            })}
            </Select>
          </div>
        </>
      ),
      dataIndex: 'proveedor_ultimo_mant',
      key: 'proveedor_ultimo_mant'
    },
    {
      title: 'PRÓX. MANT.',
      render: record => {
        if (record.ultimo_mant_programado === null) {
          return null
        } else {
          return(<label>{moment.utc(record.ultimo_mant_programado).add(record.meses_proximo_mant, 'M').format('DD/MM/YY')}</label>)
        }
      }
    },
    {
      title: 'CALIBRACIÓN',
      dataIndex: 'calibracion',
      key: 'calibracion',
      render: text => (
        text === false?'NO':'SI'
      )
    },
    {
      title: 'F. ULT. CALI.',
      render: record => {
        if (record.ultima_fecha_cali === null) {
          return null
        } else {
          return (<label>{moment.utc(record.ultima_fecha_cali).format('DD/MM/YY')}</label>)
        }
      }
    },
    {
      title: (
        <>
          <div style={{width:200}} className="mr-4">
            <Select
              allowClear
              showSearch
              defaultValue={selectProveedorCal}
              className='mb-lg-0'
              placeholder='PROVEEDOR ÚLT. CALI.'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={(e)=>setSelectProveedorCal(e)}
            >
            {proveedoresList.map(proveedor =>{
              return(
                <Option value={proveedor.id_prove} key={proveedor.id_prove}>{proveedor.descripcion}</Option>
              )
            })}
            </Select>
          </div>
        </>
      ),
      dataIndex: 'proveedor_ultimo_cali',
      key: 'proveedor_ultimo_cali'
    },
    {
      title: 'PRÓX. CALI.',
      render: record => {
        if (record.ultima_fecha_cali === null) {
          return null
        } else {
          return(moment.utc(record.ultima_fecha_cali).add(record.meses_proximo_cali, 'M').format('DD/MM/YY'))
        }
      }
    }
  ]

  const handleOpenModalOrdenesList = async(inventario) => {
    // console.log(inventario)
    setInventarioSeleccionado(inventario)
    setOpenModalOrdenesList(true)
  }

  const handleOpenModalQR = async(inventario) => {
    setInventarioSeleccionado(inventario)
    setOpenModalQR(true)
  }

  const handleCloseModalOrdenesList = () => {
    
    setInventarioSeleccionado({
      id_inventario: null,
      id_area: null,
      id_articulo: null,
      marca: '',
      modelo: '',
      serie: '',
      operativo: false,
      calibracion: false,
      id_proveedor: null,
      codigo_patrimonial: '',
      meses_proximo_mant: '',
      meses_proximo_cali: '',
      nombre_articulo: null,
      descripcion: ''
    })
    setOpenModalOrdenesList(false)
  }

  const handleOpenModalOrden = async(titulo, inventario) => {
    resetFormOT()
    setOpenModalOrden(true)
    setTituloModalOrden(titulo)
    setOrdenTrabajo({
      id_orden_trabajo: null,
      id_inventario: inventario.id_inventario,
      descripcion: null,
      estado: 'ANALISIS',
      id_proveedor: null,
      precio: 0,
      nombre_articulo: inventario.nombre_articulo,
      marca: inventario.marca,
      modelo: inventario.modelo,
      serie: inventario.serie,
      fecha_orden: '',
      tipo_mantenimiento: undefined,
      tipo: undefined,
      notas: null,
      CantFiles: 0
    })

    formOT.setFieldsValue({
      descripcion: null,
      estado: 'ANALISIS',
      id_proveedor: null,
      precio: '',
      nombre_articulo: '',
      marca: '',
      modelo: '',
      serie: '',
      fecha_orden: '',
      tipo_mantenimiento: undefined,
      tipo: undefined,
      id_inventario: inventario.id_inventario
    })
  }

  const handleOpenModal = async(titulo, id_inventario) => {
    resetForm()
    setOpenModal(true)
    setTituloModal(titulo)

    if (id_inventario === null) {
      setInventarioSeleccionado({
        id_inventario: null,
        id_area: null,
        id_articulo: null,
        marca: '',
        modelo: '',
        serie: '',
        operativo: false,
        calibracion: false,
        id_proveedor: null,
        codigo_patrimonial: '',
        meses_proximo_mant: '',
        meses_proximo_cali: '',
        nombre_articulo: null,
        descripcion: ''
      })

      form.setFieldsValue({
        id_area: null,
        id_articulo: null,
        marca: '',
        modelo: '',
        serie: '',
        operativo: false,
        calibracion: false,
        id_proveedor: null,
        codigo_patrimonial: '',
        meses_proximo_mant: '',
        meses_proximo_cali: '',
        descripcion: ''
      })

    } else {
      try {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/getInventarioById/${id_inventario}`)
        .then(res => res.json())
        .then(data => {
          // console.log(data)
          setInventarioSeleccionado(data)
          form.setFieldsValue({
            id_area: data.id_area,
            id_articulo: data.id_articulo,
            marca: data.marca,
            modelo: data.modelo,
            serie: data.serie,
            operativo: data.operativo,
            calibracion: data.calibracion,
            id_proveedor: data.id_proveedor,
            codigo_patrimonial: data.codigo_patrimonial,
            meses_proximo_mant: data.meses_proximo_mant,
            meses_proximo_cali: data.meses_proximo_cali,
            descripcion: data.descripcion
          })
        })
      } catch (error) {
        console.log(error)
      }
    }
  }

  const exportExcel = () => {
    const optionsGridOne = componenteExcel.current.workbookOptions()
    const optionsGridTwo = componenteExcel2.current.workbookOptions()
    const optionsGridtree = componenteExcel3.current.workbookOptions()
    optionsGridOne.sheets[1] = optionsGridTwo.sheets[0]
    optionsGridOne.sheets[2] = optionsGridtree.sheets[0]
    optionsGridOne.sheets[0].title = "LISTA DE INVENTARIO"
    optionsGridOne.sheets[1].title = "CANTIDAD POR ÁREA"
    optionsGridOne.sheets[2].title = "DETALLE EQUIPOS POR ÁREA"
    componenteExcel.current.save(optionsGridOne)
  }

  const InventariosListFiltrados = () => {
    // console.log(inventariosList)
    const lista = inventariosList.filter(inventario => {
      return (
        (selectArticulo === undefined? inventario.id_articulo !== selectArticulo: inventario.id_articulo === selectArticulo) &&
        (selectArea === undefined? inventario.id_area !== selectArea: inventario.id_area === selectArea) &&
        inventario.codigo_patrimonial.toUpperCase().includes(inputCodigoPat.toUpperCase()) && 
        inventario.marca.toUpperCase().includes(inputMarca.toUpperCase()) && 
        inventario.modelo.toUpperCase().includes(inputModelo.toUpperCase()) && 
        inventario.serie.toUpperCase().includes(inputSerie.toUpperCase()) && 
        (selectProveedorMant === undefined? inventario.id_proveedor_ultimo_mant !== selectProveedorMant: 
          inventario.id_proveedor_ultimo_mant === selectProveedorMant) &&
        (selectProveedorCal === undefined? inventario.id_proveedor_ultimo_cali !== selectProveedorCal: 
          inventario.id_proveedor_ultimo_cali === selectProveedorCal) &&
        (selectProveedor === undefined? inventario.id_proveedor !== selectProveedor: 
          inventario.id_proveedor === selectProveedor) &&
        inventario.activo === 'SI'
      )
    })

    return lista
  }

  if (inventariosList.length > 0) {
    return (
      <>
        <div className='row'>
          <div className='col-md-3 col-lg-3'>
            {permisoInventarios === true?
              <>
                <Button type='primary' className="mr-2"
                  title="Nuevo Inventario" 
                  onClick={e=>handleOpenModal('Nuevo Inventario', null)}
                  icon={<PlusOutlined />} >Agregar
                </Button>  
                <Button className='bg-color-success' title="Exportar Excel" icon={<FileExcelOutlined />}
                onClick={exportExcel}/> 
                { InventariosExcel(componenteExcel, InventariosListFiltrados(), componenteExcel2, cantidadInvAreaList, componenteExcel3, resumenInvAreasList) }
              </>
            : null
            }
          </div>
        </div>

        <Card>
          <div>
            <Table
              pagination={{ pageSize: 10 }}
              columns={columns}
              dataSource={InventariosListFiltrados()}
              size='small'
            />
          </div>
        </Card>

        {<ModalInventarios
          openModal = {openModal}
          tituloModal = {tituloModal}
          getInventarios = {getInventarios}
          setOpenModal = {setOpenModal}
          articulosList = {articulosList}
          usuario = {cookies.get('nameid_logis')}
          inventario = {inventarioSeleccionado}
          areasList = {areasList}
          setAreasList = {setAreasList}
          proveedoresList = {proveedoresList}
          form = {form}
          tituloModalArt = {'Nuevo Artículo'}
          getRubros = {getRubros}
          rubrosList = {rubrosList}
          getArticulos = {getArticulos}
        />}

        {<ModalFormularioOrdenTrabajo
          inventariosList = {inventariosList}
          ordenTrabajo = {ordenTrabajo}
          openModal = {openModalOrden}
          setOpenModal = {setOpenModalOrden}
          usuario = {cookies.get('nameid_logis')}
          tituloModal = {tituloModalOrden}
          proveedoresList = {proveedoresList}
          form = {formOT}
          getInventarios = {getInventarios}
        />}

        {<Modal
          visible={openModalOrdenesList}
          closable={false}
          width='100%'
          title={
            <>
              <div>
                <b style={{fontSize:"20px"}}>{`Órdenes de Trabajo de: ${inventarioSeleccionado.nombre_articulo}`}</b>
              </div>
              
              <div className='d-flex justify-content-between'>
                <b style={{fontSize:"20px"}}>Marca: {inventarioSeleccionado.marca}</b>
                <b style={{fontSize:"20px"}}>Modelo: {inventarioSeleccionado.modelo}</b>
                <b style={{fontSize:"20px"}}>Serie: {inventarioSeleccionado.serie}</b>
              </div>
            </>
          }
          onCancel={handleCloseModalOrdenesList}
          footer={
            <div className='modal-footer d-flex justify-content-between'>
              <Button danger onClick={handleCloseModalOrdenesList} icon={<CloseOutlined />}>
              Cerrar
              </Button>  
            </div>
          }
        >
          {<OrdenesTrabajo
            id_inventario = {inventarioSeleccionado.id_inventario}
          />}
        </Modal>}

        {<ModalQRInventario
          openModal = {openModalQR}
          setOpenModal = {setOpenModalQR}
          inventario = {inventarioSeleccionado}
        />}
      </>
    )
  } else {
    return (
      <>
        <div className='row'>
          <div className='col-md-3 col-lg-3'>
            {permisoInventarios === true?
              <Button type='primary'  
                title="Nuevo Inventario" 
                onClick={e=>handleOpenModal('Nuevo Inventario', null)}
                icon={<PlusOutlined />} >Agregar
              </Button>
            : null
            }
          </div>
        </div>

        <Card>
          <div>No hay Datos</div>

        </Card>

        {<ModalInventarios
          openModal = {openModal}
          tituloModal = {tituloModal}
          getInventarios = {getInventarios}
          setOpenModal = {setOpenModal}
          articulosList = {articulosList}
          usuario = {cookies.get('nameid_logis')}
          inventario = {inventarioSeleccionado}
          areasList = {areasList}
          setAreasList = {setAreasList}
          proveedoresList = {proveedoresList}
          form = {form}
          tituloModalArt = {'Nuevo Artículo'}
          getRubros = {getRubros}
          rubrosList = {rubrosList}
          getArticulos = {getArticulos}
        />}
      </>
    )
  }

}

export default Inventarios