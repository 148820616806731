import { ExcelExport, ExcelExportColumn, ExcelExportColumnGroup } from '@progress/kendo-react-excel-export';
import moment from 'moment';

const dataList = (residuosList) => {
  // console.log(residuosList)
  const lista_biomedico = residuosList.filter(re => {
    return re.tipo === 'BIOMEDICO' 
  })

  const lista_especial = residuosList.filter(re => {
    return re.tipo === 'ESPECIAL' 
  })
  // console.log(lista)

  let listaCantidades = {
    mes1: [{ semana1:0 },{ semana2:0 },{ semana3:0 },{ semana4:0 },{ semana5:0 }, {especial:0 }], 
    mes2: [{ semana1:0 },{ semana2:0 },{ semana3:0 },{ semana4:0 },{ semana5:0 }, {especial:0 }], 
    mes3: [{ semana1:0 },{ semana2:0 },{ semana3:0 },{ semana4:0 },{ semana5:0 }, {especial:0 }], 
    mes4: [{ semana1:0 },{ semana2:0 },{ semana3:0 },{ semana4:0 },{ semana5:0 }, {especial:0 }], 
    mes5: [{ semana1:0 },{ semana2:0 },{ semana3:0 },{ semana4:0 },{ semana5:0 }, {especial:0 }], 
    mes6: [{ semana1:0 },{ semana2:0 },{ semana3:0 },{ semana4:0 },{ semana5:0 }, {especial:0 }], 
    mes7: [{ semana1:0 },{ semana2:0 },{ semana3:0 },{ semana4:0 },{ semana5:0 }, {especial:0 }], 
    mes8: [{ semana1:0 },{ semana2:0 },{ semana3:0 },{ semana4:0 },{ semana5:0 }, {especial:0 }], 
    mes9: [{ semana1:0 },{ semana2:0 },{ semana3:0 },{ semana4:0 },{ semana5:0 }, {especial:0 }],
    mes10: [{ semana1:0 },{ semana2:0 },{ semana3:0 },{ semana4:0 },{ semana5:0 }, {especial:0 }],
    mes11: [{ semana1:0 },{ semana2:0 },{ semana3:0 },{ semana4:0 },{ semana5:0 }, {especial:0 }],
    mes12: [{ semana1:0 },{ semana2:0 },{ semana3:0 },{ semana4:0 },{ semana5:0 }, {especial:0 }]
  }
  
// console.log(listaCantidades.mes1[5])
  /**PARA SABER A CUAL MES Y EN CUAL SEMANA SUMAR LA ACNTIDAD*/
  /** SE RECORRE TODA LA LISTA DE RESIDUOS */
  lista_biomedico.forEach( residuo => {
    // console.log(index)
    const num_mes = parseInt(moment.utc(residuo.fecha).format('MM'))
    const num_semana = Math.ceil(moment.utc(residuo.fecha).date() / 7)
    const monto = listaCantidades[`mes`.concat(num_mes)][num_semana-1][`semana`.concat(num_semana)]
    /**CONFORME VA RECORRIENDO LA LISTA, VA SUMANDO SU CANTIDAD AL MES Y SEMANA CORRESPONDIENTE */
    /**EJM  listaCantidades.mes1[0].semana1 */
    listaCantidades[`mes`.concat(num_mes)][num_semana-1][`semana`.concat(num_semana)] = monto + residuo.cantidad
  })

  lista_especial.forEach( residuo => {
    // console.log(residuo)
    const num_mes = parseInt(moment.utc(residuo.fecha).format('MM'))
    const monto = listaCantidades[`mes`.concat(num_mes)][5].especial
    listaCantidades[`mes`.concat(num_mes)][5].especial =  monto + residuo.cantidad
  })

  // console.log(listaCantidades)
  const listaFinal = [
    {
      mes: 'ENERO' , ...listaCantidades.mes1[0], ...listaCantidades.mes1[1], ...listaCantidades.mes1[2],
      ...listaCantidades.mes1[3], ...listaCantidades.mes1[4], total: totalMes(listaCantidades.mes1), totalToneladas: totalMes(listaCantidades.mes1)/1000,
      ...listaCantidades.mes1[5]  
    },
    {
      mes: 'FEBRERO', ...listaCantidades.mes2[0], ...listaCantidades.mes2[1], ...listaCantidades.mes2[2],
      ...listaCantidades.mes2[3], ...listaCantidades.mes2[4], total: totalMes(listaCantidades.mes2)  , totalToneladas: totalMes(listaCantidades.mes2)/1000,
      ...listaCantidades.mes2[5]  
    },
    {
      mes: 'MARZO', ...listaCantidades.mes3[0], ...listaCantidades.mes3[1], ...listaCantidades.mes3[2],
      ...listaCantidades.mes3[3], ...listaCantidades.mes3[4], total: totalMes(listaCantidades.mes3), totalToneladas: totalMes(listaCantidades.mes3)/1000,
      ...listaCantidades.mes3[5]  
    },
    {
      mes: 'ABRIL', ...listaCantidades.mes4[0], ...listaCantidades.mes4[1], ...listaCantidades.mes4[2],
      ...listaCantidades.mes4[3], ...listaCantidades.mes4[4], total: totalMes(listaCantidades.mes4), totalToneladas: totalMes(listaCantidades.mes4)/1000,
      ...listaCantidades.mes4[5]  
    },
    {
      mes: 'MAYO', ...listaCantidades.mes5[0], ...listaCantidades.mes5[1], ...listaCantidades.mes5[2],
      ...listaCantidades.mes5[3], ...listaCantidades.mes5[4], total: totalMes(listaCantidades.mes5), totalToneladas: totalMes(listaCantidades.mes5)/1000,
      ...listaCantidades.mes5[5]  
    },
    {
      mes: 'JUNIO', ...listaCantidades.mes6[0], ...listaCantidades.mes6[1], ...listaCantidades.mes6[2],
      ...listaCantidades.mes6[3], ...listaCantidades.mes6[4], total: totalMes(listaCantidades.mes6), totalToneladas: totalMes(listaCantidades.mes6)/1000,
      ...listaCantidades.mes6[5]  
    },
    {
      mes: 'JULIO', ...listaCantidades.mes7[0], ...listaCantidades.mes7[1], ...listaCantidades.mes7[2],
      ...listaCantidades.mes7[3], ...listaCantidades.mes7[4], total: totalMes(listaCantidades.mes7), totalToneladas: totalMes(listaCantidades.mes7)/1000,
      ...listaCantidades.mes7[5]  
    },
    {
      mes: 'AGOSTO', ...listaCantidades.mes8[0], ...listaCantidades.mes8[1], ...listaCantidades.mes8[2],
      ...listaCantidades.mes8[3], ...listaCantidades.mes8[4], total: totalMes(listaCantidades.mes8), totalToneladas: totalMes(listaCantidades.mes8)/1000,
      ...listaCantidades.mes8[5]  
    },
    {
      mes: 'SETIEMBRE', ...listaCantidades.mes9[0], ...listaCantidades.mes9[1], ...listaCantidades.mes9[2],
      ...listaCantidades.mes9[3], ...listaCantidades.mes9[4], total: totalMes(listaCantidades.mes9), totalToneladas: totalMes(listaCantidades.mes9)/1000,
      ...listaCantidades.mes9[5]  
    },
    {
      mes: 'OCTUBRE', ...listaCantidades.mes10[0], ...listaCantidades.mes10[1], ...listaCantidades.mes10[2],
      ...listaCantidades.mes10[3], ...listaCantidades.mes10[4], total: totalMes(listaCantidades.mes10), totalToneladas: totalMes(listaCantidades.mes10)/1000,
      ...listaCantidades.mes10[5]  
    },
    {
      mes: 'NOVIEMBRE', ...listaCantidades.mes11[0], ...listaCantidades.mes11[1], ...listaCantidades.mes11[2],
      ...listaCantidades.mes11[3], ...listaCantidades.mes11[4], total: totalMes(listaCantidades.mes11), totalToneladas: totalMes(listaCantidades.mes11)/1000,
      ...listaCantidades.mes11[5]  
    },
    {
      mes: 'DICIEMBRE', ...listaCantidades.mes12[0], ...listaCantidades.mes12[1], ...listaCantidades.mes12[2],
      ...listaCantidades.mes12[3], ...listaCantidades.mes12[4], total: totalMes(listaCantidades.mes12), totalToneladas: totalMes(listaCantidades.mes12)/1000,
      ...listaCantidades.mes12[5]  
    }
  ]  

  // console.log(listaFinal)

  return(listaFinal)
}

const residuosPorAreas = (residuosListByAreaYMes) => {
  // console.log(residuosListByAreaYMes)
  let listaAreas = []
  residuosListByAreaYMes.forEach( element => {
    if (listaAreas.length === 0) {
      listaAreas = [ ...listaAreas, 
        {id_area: element.id_area, nombre_area: element.nombre_area, nombre_sucursal: element.nombre_sucursal, id_sucursal: element.id_sucursal}
      ]
    } else {
      const findArea = listaAreas.filter( list => {return element.id_area === list.id_area})
      if (findArea.length === 0) {
        listaAreas = [ ...listaAreas, 
          {id_area: element.id_area, nombre_area: element.nombre_area, nombre_sucursal: element.nombre_sucursal, id_sucursal: element.id_sucursal}
        ]
      }
    }    
  })
  // console.log(listaAreas)
  let listaFinal = [
    { mes: 'ENERO' }, { mes: 'FEBRERO' }, { mes: 'MARZO' }, { mes: 'ABRIL' },{  mes: 'MAYO' }, { mes: 'JUNIO' },
    { mes: 'JULIO' }, { mes: 'AGOSTO' }, { mes: 'SETIEMBRE' }, { mes: 'OCTUBRE' }, { mes: 'NOVIEMBRE' }, { mes: 'DICIEMBRE' } 
  ]

  listaAreas.forEach(element => {
    for (let i = 0; i < 12; i++) {
      const findMes = residuosListByAreaYMes.filter( item => {
        const num_mes = parseInt(moment.utc(item.fecha).format('MM'))
        return num_mes === i + 1 && item.id_area === element.id_area
      })

      if (findMes.length > 0) {
        listaFinal[i] = { ...listaFinal[i], [element.nombre_area]: findMes[0].total_mes } 
      } else {
        listaFinal[i] = { ...listaFinal[i], [element.nombre_area]: 0 } 
      }
    }
  })
  // console.log(listaFinal)
  return {listaFinal, listaAreas}
}

const totalMes = (listaCantidades) => {
  // console.log(listaCantidades)
  let total = 0
  listaCantidades.forEach((element, index) => {
    // console.log(element)
    if (element.especial === undefined) {
      total = total + element[`semana`.concat(index+1)]
      // console.log(index, element[`semana`.concat(index+1)], total)
    } else {
      total = total + element.especial
      // console.log(index, element.especial, total)
    }
  })
  // console.log(total)
  return total
}

const totalAnual = (lista) => {
  // console.log(lista)
  const total = lista.map(item => item.total).reduce((prev, next) => prev + next)
  const totalToneladas = lista.map(item => item.totalToneladas).reduce((prev, next) => prev + next)
  return {total,totalToneladas}
}

const totalArea = (nombre_area, listaFinal) => {
  const total = listaFinal.map(item => item[nombre_area]).reduce((prev, next) => prev + next)
  return total
}

const columns = (listaPorArea) => {
  // console.log(listaPorArea)
  return (
    <>
      {listaPorArea.listaAreas.map(item => {
        return (
        <ExcelExportColumn field={item.nombre_area} title={item.nombre_area} width={180}
          // headerCellOptions ={{ textAlign: "center", background: "#FFCDCD", color: "#000000" }}
          footerCellOptions = {{ background: "#CCFFFF" }} footer={()=>totalArea(item.nombre_area , listaPorArea.listaFinal)}
        />
        )
      })}
    </>
  )
}

export const ConsolidadResiduosExcel = (componenteExcel, componenteExcel2, componenteExcel3, residuosList, residuosListByAreaYMes) => {
  // console.log(residuosList)
  const listaResiduos = dataList(residuosList)
  const listaPorArea = residuosPorAreas(residuosListByAreaYMes)
  // console.log(listaResiduos)
  return (
    <>
      {/* HOJA 1 DEL EXCEL */}
      <ExcelExport 
        data={listaResiduos} 
        fileName="Residuos.xlsx" 
        ref={componenteExcel}
      >
        <ExcelExportColumnGroup
          title="CONSOLIDADO  DE  GENERACIÓN  DE   RESIDUOS  PELIGROSOS  MENSUAL"
          headerCellOptions={{ textAlign: "center" , verticalAlign: "center", background: "#ffffff", color: "#000000" }}
        >
          <ExcelExportColumnGroup
            title="DE LA CLINICA LIMATAMBO CAJAMARCA SAC      AÑO    2022"
            headerCellOptions={{ textAlign: "center" , verticalAlign: "center", background: "#ffffff", color: "#000000" }}
          >
            <ExcelExportColumnGroup
              title=""
              headerCellOptions={{ background: "#ffffff" }}
            >
              <ExcelExportColumn field = "mes" title = "MES" width = {120} 
                headerCellOptions ={{ textAlign: "center", background: "#CCFFFF", color: "#000000" }}
                cellOptions = {{ background: "#FFFF66" }}
                footerCellOptions = {{ background: "#CCFFFF" }}
              /> 
              <ExcelExportColumn field = "especial" title = "ESPECIAL" width = {120} 
                headerCellOptions ={{ textAlign: "center", background: "#92D050", color: "#000000" }}
                cellOptions = {{ background: "#92D050" }}
                footerCellOptions = {{ background: "#CCFFFF" }}
              />
              <ExcelExportColumn field = "semana1" title = "1° SEMANA" width = {100} 
                headerCellOptions = {{ textAlign: "center", background: "#CCFFFF", color: "#000000" }}
                footerCellOptions = {{ background: "#CCFFFF" }}
              />
              <ExcelExportColumn field = "semana2" title = "2° SEMANA" width = {100} 
                headerCellOptions = {{ textAlign: "center", background: "#CCFFFF", color: "#000000" }}
                footerCellOptions = {{ background: "#CCFFFF" }}
              />
              <ExcelExportColumn field = "semana3" title = "3° SEMANA" width = {100} 
                headerCellOptions = {{ textAlign: "center", background: "#CCFFFF", color: "#000000" }}
                footerCellOptions = {{ background: "#CCFFFF" }}
              />
              <ExcelExportColumn field = "semana4" title = "4° SEMANA" width = {100} 
                headerCellOptions = {{ textAlign: "center", background: "#CCFFFF", color: "#000000" }}
                footerCellOptions = {{ background: "#CCFFFF" }} footer={()=>'VOLUMEN'}
              />
              <ExcelExportColumn field = "semana5" title = "5° SEMANA" width = {100} 
                headerCellOptions = {{ textAlign: "center", background: "#CCFFFF", color: "#000000" }}
                footerCellOptions = {{ background: "#CCFFFF" }} footer={()=>'TOTAL ANUAL'}
              />
              <ExcelExportColumn field = "total" title = "Total (KG/MES)" width = {100} 
                headerCellOptions = {{ textAlign: "center", background: "#CCFFFF", color: "#000000" }}
                cellOptions = {{ background: "#CCFFFF" }} footer = { () => totalAnual(listaResiduos).total }
                footerCellOptions = {{ background: "#CCFFFF" }}
              />
            </ExcelExportColumnGroup>
          </ExcelExportColumnGroup>
        </ExcelExportColumnGroup>
      </ExcelExport>

      {/* HOJA 2 DEL EXCEL */}
      <ExcelExport 
        data={listaResiduos} 
        fileName="Residuos.xlsx" 
        ref={componenteExcel2}
      >
        <ExcelExportColumnGroup
          title="CONSOLIDADO  DE  GENERACIÓN  DE   RESIDUOS  PELIGROSOS  MENSUAL"
          headerCellOptions={{ textAlign: "center" , verticalAlign: "center", background: "#ffffff", color: "#000000" }}
        >
          <ExcelExportColumnGroup
            title="DE LA CLINICA LIMATAMBO CAJAMARCA SAC      AÑO    2022"
            headerCellOptions={{ textAlign: "center" , verticalAlign: "center", background: "#ffffff", color: "#000000" }}
          >
            <ExcelExportColumnGroup
              title=""
              headerCellOptions={{ background: "#ffffff" }}
            >
              <ExcelExportColumn field = "mes" title = "MES" width = {200} 
                headerCellOptions ={{ textAlign: "center", background: "#CCFFFF", color: "#000000" }}
                footerCellOptions = {{ background: "#CCFFFF" }} footer={()=>'VOLUMEN TOTAL AL AÑO'}
              />
              <ExcelExportColumn field = "total" title = "Total (KG/MES)" width = {200} 
                headerCellOptions = {{ textAlign: "center", background: "#CCFFFF", color: "#000000" }}
                cellOptions = {{ background: "#CCFFFF" }} footer = { () => totalAnual(listaResiduos).total }
                footerCellOptions = {{ background: "#CCFFFF" }}
              />
              <ExcelExportColumn field = "totalToneladas" title = "Total (T/MES)" width = {200} 
                headerCellOptions = {{ textAlign: "center", background: "#CCFFFF", color: "#000000" }}
                footer = { () => totalAnual(listaResiduos).totalToneladas }
                footerCellOptions = {{ background: "#CCFFFF" }}
              />
            </ExcelExportColumnGroup>
          </ExcelExportColumnGroup>
        </ExcelExportColumnGroup>
      </ExcelExport>

      <ExcelExport 
        data={listaPorArea.listaFinal} 
        fileName="Residuos.xlsx" 
        ref={componenteExcel3}
      >
        <ExcelExportColumnGroup
          title="CONSOLIDADO  DE  RESIDUOS BIOMÉDICOS POR UPSS "
          headerCellOptions={{ textAlign: "center" , verticalAlign: "center", background: "#CCFFFF", color: "#000000" }}
        >
          <ExcelExportColumn field='mes' title='MES/ÁREA' width={120}
            footerCellOptions = {{ background: "#CCFFFF" }} footer={()=>'TOTAL'}
          />
          {columns(listaPorArea)}
        </ExcelExportColumnGroup>
      </ExcelExport>

      
      
    </>
  )
}