import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import moment from 'moment';

const dataList = (inventariosList) => {
  // console.log(inventariosList)
  const lista = inventariosList.map(item => {
    return {...item, 
      nombre_articulo: `${item.identificador} - ${item.nombre_articulo}`,
      operativo: item.operativo === false?'NO':'SI',
      ultima_fecha_mant: item.ultima_fecha_mant === null? '': 
        `${moment.utc(item.ultima_fecha_mant).format('DD/MM/YY')} (${item.tipo_mant})`,
      ultimo_mant_programado: item.ultimo_mant_programado === null ? '': 
        `${moment.utc(item.ultimo_mant_programado).add(item.meses_proximo_mant, 'M').format('DD/MM/YY')}`,
      calibracion: item.calibracion === false?'NO':'SI',
      ultima_fecha_cali: item.ultima_fecha_cali === null? '': 
        `${moment.utc(item.ultima_fecha_cali).add(item.meses_proximo_cali, 'M').format('DD/MM/YY')}`
    }
  })

  return lista
}

const getEquipos = (resumenInvAreasList) => {
  let lista_equipos = []

  resumenInvAreasList.forEach( element => {
    if ( lista_equipos.length === 0 ) {
      lista_equipos = [...lista_equipos, {nombre_equipo: element.nombre_equipo}]
    } else {
      const findEquipo = lista_equipos.filter( list => {return element.nombre_equipo === list.nombre_equipo})
      if (findEquipo.length === 0) {
        lista_equipos = [...lista_equipos, {nombre_equipo: element.nombre_equipo}]
      }
    }
  })

  // console.log(lista_equipos)

  return lista_equipos
}

const getAreas = (resumenInvAreasList) => {
  let lista_areas = []
  
  resumenInvAreasList.forEach( element => {
    if ( lista_areas.length === 0 ) {
      lista_areas = [...lista_areas, {nombre_area: element.nombre_area}]
    } else {
      const findArea = lista_areas.filter( list => {return element.nombre_area === list.nombre_area})
      if ( findArea.length === 0 ) {
        lista_areas = [...lista_areas, {nombre_area: element.nombre_area}]
      }
    }
  })

  // console.log(lista_areas)

  return lista_areas
}

const getResumen = ( resumenInvAreasList, lista_equipos, lista_areas ) => {
  // console.log(resumenInvAreasList, lista_equipos, lista_areas)
  let lista = []

  lista_equipos.forEach( (equipo, index) => {
    lista = [...lista, { nombre_equipo: equipo.nombre_equipo }]
    lista_areas.forEach( area => {
      const findCantArea = resumenInvAreasList.filter( item => {
        return item.nombre_equipo === equipo.nombre_equipo && item.nombre_area === area.nombre_area
      })

      if (findCantArea.length === 0) {
        lista[index] = {...lista[index], [area.nombre_area]: 0}
      } else {
        lista[index] = {...lista[index], [area.nombre_area]: findCantArea[0].cantidad_equipos}
      }
    });
  })

  // console.log(lista)

  return lista
}

const columns = (lista_areas) => {
  // console.log(lista_areas)
  return (
    <>
      {lista_areas.map(item => {
        return (
        <ExcelExportColumn field={item.nombre_area} title={item.nombre_area} width={200}
          // headerCellOptions ={{ textAlign: "center", background: "#FFCDCD", color: "#000000" }}
        />
        )
      })}
    </>
  )
}

export const InventariosExcel = (componenteExcel, inventariosList, componenteExcel2, cantidadInvAreaList, componenteExcel3, resumenInvAreasList) => {
  const data = dataList(inventariosList)
  const lista_equipos = getEquipos(resumenInvAreasList)
  const lista_areas = getAreas(resumenInvAreasList)
  const resumen = getResumen( resumenInvAreasList, lista_equipos, lista_areas )

  return(
    <>
      <ExcelExport 
        data={data} 
        fileName="Inventarios.xlsx" 
        ref={componenteExcel}
      >
        <ExcelExportColumn
          field="codigo_patrimonial" title="CÓD. PATRIMONIAL" width = {120} 
        />
        <ExcelExportColumn
          field="nombre_area" title="ÁREA" width = {150} 
        />
        <ExcelExportColumn
          field="nombre_articulo" title="EQUIPO" width = {120} 
        />
        <ExcelExportColumn
          field="marca" title="MARCA" width = {120} 
        />
        <ExcelExportColumn
          field="modelo" title="MODELO" width = {120} 
        />
        <ExcelExportColumn
          field="serie" title="SERIE" width = {150} 
        />
        <ExcelExportColumn
          field="proveedor" title="PROVEEDOR" width = {200} 
        />
        <ExcelExportColumn
          field="operativo" title="OPERATIVO" width = {100} 
        />
        <ExcelExportColumn
          field="ultima_fecha_mant" title="F. ÚLT. MANT." width = {150} 
        />
        <ExcelExportColumn
          field="proveedor_ultimo_mant" title="PROVEEDOR ÚLT. MANT." width = {200} 
        />
        <ExcelExportColumn
          field="ultimo_mant_programado" title="PRÓX. MANT." width = {120} 
        />
        <ExcelExportColumn
          field="calibracion" title="CALIBRACIÓN" width = {100} 
        />
        <ExcelExportColumn
          field="ultima_fecha_cali" title="F. ULT. CALI." width = {120} 
        />
        <ExcelExportColumn
          field="proveedor_ultimo_cali" title="PROVEEDOR ÚLT. CALI." width = {200} 
        />
        <ExcelExportColumn
          field="prox_cali" title="PRÓX. CALI." width = {120} 
        />
      </ExcelExport>

      <ExcelExport 
        data={cantidadInvAreaList} 
        fileName="Inventarios.xlsx" 
        ref={componenteExcel2}
      >
        <ExcelExportColumn
          field="nombre" title="ÁREA" width = {120} 
        />
        <ExcelExportColumn
          field="cantidad_equipos" title="CANT. EQUIPOS" width = {150} 
        />
      </ExcelExport>

      <ExcelExport 
        data={resumen} 
        fileName="Inventarios.xlsx" 
        ref={componenteExcel3}
      >
        <ExcelExportColumn field='nombre_equipo' title='EQUIPO' width={250}/>
        {columns(lista_areas)}
      </ExcelExport>
    </>
  )
}