import { useState, useEffect } from 'react';
import { Button, Modal } from 'antd';
import { DownloadFile, PreviewFile } from '../FuncionesGenerales';
import { CloseOutlined, DownloadOutlined, EyeOutlined } from '@ant-design/icons';

const ModalFilesOCDespachadas = (props) => {
  const [pdfFacturas, setPdfFacturas] = useState([])

  useEffect(() => {
    if (props.openModal === true) {
      getPdfsFactura(props.idFactura)
    } else {
      setPdfFacturas([])
    }
  }, [props.openModal]);

  const getPdfsFactura = async(id_factura) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getPdfFacturaByFacturaId/${id_factura}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          setPdfFacturas([])
        } else {
          setPdfFacturas(data)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  return(<>
    <Modal
        visible={props.openModal}
        closable={false}
        width='90%'
        title={null}
        onCancel={props.handleCloseModal}
        footer={
          <div className='modal-footer d-flex justify-content-between'>
            <Button danger onClick={props.handleCloseModal} icon={<CloseOutlined />}>
              Cerrar
            </Button>
          </div>
        }
      >
        <div className='elem-list mt-2'>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <b style={{fontSize:"18px"}} className="mr-2">Archivos Adjuntos:</b>
          </div>  

          {pdfFacturas.map((pdf, index) => {
            return(
              <div className='col-sm-2 col-md-2 col-lg-2'>
                <b>Archivo { index + 1 }:</b>
                <Button className='bg-color-success mr-1 ml-1' 
                  title="Ver Archivo" 
                  onClick={e => PreviewFile(pdf)}
                  icon={<EyeOutlined />}
                />

                <Button type={'primary'} 
                  title="Descargar Archivo" 
                  onClick={e => DownloadFile(`PDF_Factura_${pdf.id_factura}`, pdf, index)}
                  icon={<DownloadOutlined />} 
                />  
              </div>
            )
          })}
        </div>
      </Modal>
  </>)
}

export default ModalFilesOCDespachadas