import { CloseOutlined, DownloadOutlined, EyeOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Button, Modal, Table } from 'antd';
import { useEffect, useRef, useState } from 'react';
import moment from "moment";
import { DownloadFile, listaAreas, listaCodigos, listaResp, PreviewFile } from '../FuncionesGenerales';
import { PdfFileOrden } from '../componentesPDF/GenerarPDFOrden';

const ModalDetalleFactura = (props) => {
  const [factura, setFactura] = useState({
    id_orden_compra: null,
    fecha_emision: moment.utc(new Date()).format('YYYY-MM-DD'),
    fecha_vencimiento: null,
    serie: null,
    numero_serie: null,
    codigo: null,
    detraccion: null,
    afecta_detraccion: false,
    usuario: props.usuario,
    ubicacion_archivo: null,
    TipCambio: 1,
    total: null,
    resumen: '',
    nombre_area:null,
    fecha_orden_compra: null,
    nombre_responsable: null,
    numero_orden: null,
    codigo_req: null,
    tipo_documento: null,
    parv_descripcion: null,
    descripcion: null,
    nombre_comercial: null,
    ruc: null,
    tipo_pago: null,
    forma_pago: null,
    Parc_Valor: null,
    id_cotizacion: null,
    id_consolidado: null
  });

  const [pdfFacturas, setPdfFacturas] = useState([])

  const [detOrdenesCompraList, setDetOrdenesCompraList] = useState([])

  const [itemPDF, setItemPDF] = useState({
    nombre_area: null,
    id_consolidado: null,
    nombre_responsable: null,
    id_orden_compra: null
  });
  const [detalleCuentas, setDetalleCuentas] = useState([])
  const componentePDF = useRef(null)

  const handleCloseModal = () => {
    props.setOpenModal(false)
    props.setIdFactura(null)
  }

  useEffect(() => {
    // console.log(props.idFactura)
    if (props.idFactura !== null && props.openModal === true) {
      getFacturaById(props.idFactura)
      getPdfsFactura(props.idFactura)

    } else {
      
    }
  }, [props.idFactura, props.openModal]);

  const getPdfsFactura = async(id_factura) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getPdfFacturaByFacturaId/${id_factura}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          setPdfFacturas([])
        } else {
          setPdfFacturas(data)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getFacturaById = async(id_factura) => {
    // console.log(props.orden_compra)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getFacturaById/${id_factura}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setFactura({
          id_orden_compra: data.id_orden_compra,
          fecha_emision: moment.utc(data.fecha_emision).format('YYYY-MM-DD'),
          fecha_vencimiento: data.fecha_vencimiento,
          serie: data.serie,
          numero_serie: data.numero_serie,
          codigo: data.codigo,
          detraccion: data.detraccion,
          afecta_detraccion: data.afecta_detraccion,
          usuario: data.usuario,
          ubicacion_archivo: data.ubicacion_archivo,
          TipCambio: data.TipCambio,
          total: data.total,
          resumen: data.resumen,
          nombre_area: data.nombre_area,
          fecha_orden_compra: data.fecha_orden_compra,
          nombre_responsable: data.nombre_responsable,
          numero_orden: data.numero_orden,
          codigo_req: data.codigo_req,
          tipo_documento: data.tipo_documento,
          parv_descripcion: data.parv_descripcion,
          descripcion: data.descripcion,
          nombre_comercial: data.nombre_comercial,
          ruc: data.ruc,
          tipo_pago: data.tipo_pago,
          forma_pago: data.forma_pago,
          Parc_Valor: data.Parc_Valor,
          id_cotizacion: data.id_cotizacion,
          id_consolidado: data.id_consolidado
        })

        getDetalleOrdenbyIdOrden(data.id_orden_compra)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getDetalleOrdenbyIdOrden = async(id_orden_compra) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getDetalleOrdenbyIdOrden/${id_orden_compra}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setDetOrdenesCompraList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const columns = [
    { title: 'MATERIAL/EQUIPO', key: 'nombre_articulo', dataIndex: 'nombre_articulo' },
    { title: 'DESCRIPCIÓN', key: 'descripcion', dataIndex: 'descripcion' },
    { title: 'MARCA', key: 'marca', dataIndex: 'marca' },
    { title: 'PRESENTACIÓN',
      render: record => {
        return(
          <>{record.nombre_unidad} X {record.cantidad_unidades}</>
        )
      }
    },
    { title: 'CANTIDAD', 
      render: record => {
        return(<>{record.cantidad.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>)
      }
    },
    { title: 'PRECIO',
      render: record => {
        return(
          <>
            {factura.Parc_Valor} {record.precio.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </>
        )
      } 
    },
    {
      title: 'TOTAL',  
      render: record => {
        return(
          <>
            {factura.Parc_Valor} 
            {(record.cantidad*record.precio).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </>
        )
      }
    }
  ]

  const Total = () => {
    return(
      <>
        {factura.tipo_documento === 'HONORARIO'? null:
          <div className='d-flex justify-content-between mr-4'>
            <b>Base Imponible</b>
            <b className='mr-4'>
              {factura.Parc_Valor} { detOrdenesCompraList.length === 0? 0:
                ((detOrdenesCompraList.map(det => det.cantidad * det.precio).reduce((prev, next) => prev + next)) / 1.18)
                .toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
              }
            </b> 
          </div>
        }
        
        {factura.tipo_documento === 'HONORARIO'?null:
          <div className='d-flex justify-content-between mr-4'>
            <b>IGV</b>
            <b className='mr-4'>
              {factura.Parc_Valor} { detOrdenesCompraList.length === 0? 0:
                ((detOrdenesCompraList.map(det => det.cantidad * det.precio).reduce((prev, next) => prev + next)) / 1.18 * 0.18)
                .toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
              }
            </b> 
          </div>
        }
        
        <div className='d-flex justify-content-between mr-4'>
          <b>{factura.tipo_documento === 'HONORARIO'?'Total por Honorarios':'Total'}</b>
          <b className='mr-4'>
            {factura.Parc_Valor} { detOrdenesCompraList.length === 0? 0:
              (detOrdenesCompraList.map(det => det.cantidad * det.precio).reduce((prev, next) => prev + next))
              .toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            }
          </b>
        </div>

        <div className='d-flex justify-content-between mr-4'>
          <b>Total a Pagar</b>
          <b className='mr-4'>
            {factura.Parc_Valor} { detOrdenesCompraList.length === 0? 0:
              factura.afecta_detraccion === false?
                (detOrdenesCompraList.map(det => det.cantidad * det.precio).reduce((prev, next) => prev + next))
                .toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }):
                ((detOrdenesCompraList.map(det => det.cantidad * det.precio)
                .reduce((prev, next) => prev + next))*(1-(factura.detraccion/100)))
                .toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            }
          </b>
        </div>
      </>
    )
  }

  const ExportarPDF = async() => {
    try {
      const itemOC = await fetch(`${process.env.REACT_APP_HOSTNAME}/getOrdenCompraById/${factura.id_orden_compra}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setItemPDF(data)
        return (data)
      }).catch(err => {return []})

      const reprProv = await fetch(`${process.env.REACT_APP_HOSTNAME}/getRepresentantesByIdProveedor/${itemOC.id_proveedor}`)
      .then(res => res.json())
      .then(data => {
        if (data.message) {
          return ([])
        } else {
          // setDetalleRepr(data)
          return (data)
        }
      }).catch(err => {return []})

      const cuentas = await fetch(`${process.env.REACT_APP_HOSTNAME}/getCuentasProveedor/${itemOC.id_proveedor}`)
      .then(res => res.json())
      .then(data => {
        if (data.message) {
          return ([])
        } else {
          return (data)
        }
      })

      setItemPDF({
        ...itemOC,
        ...reprProv[0]
      })

      setDetalleCuentas(cuentas)

      componentePDF.current.save()
    } catch (error) {
      console.log(error)
    }
  }

  return(
    <>
      <Modal
        visible={props.openModal}
        closable={false}
        width='90%'
        title={
          <>
            {PdfFileOrden(componentePDF, itemPDF, detOrdenesCompraList, detalleCuentas)}
            <div className='d-flex justify-content-between'>
              <b style={{fontSize:"20px"}}>Factura: {factura.codigo}</b>
              <b style={{fontSize:"20px"}}>{factura.id_consolidado === null?factura.codigo_req:listaCodigos(factura.codigo_req)}</b>
            </div>

            <div className="d-flex justify-content-between mb-2 mt-2">
              <b style={{fontSize:"20px"}}>Área: 
                {factura.id_consolidado === null?factura.nombre_area:listaAreas(factura.nombre_area)}
              </b>
              <b style={{fontSize:"20px"}}>Fecha de Orden: {moment(factura.fecha_orden_compra).format('DD/MM/YYYY')}
              </b>
            </div>

            <div className="d-flex justify-content-between mb-2 mt-2">
              <b style={{fontSize:"20px"}}>Solicitante: 
                {factura.id_consolidado === null?factura.nombre_responsable:listaResp(factura.nombre_responsable)}
              </b>  
              <b style={{fontSize:"20px"}}>N° Orden: {factura.numero_orden}</b>  
            </div>
          </>
        }
        onCancel={handleCloseModal}
        footer={
          <div className='modal-footer d-flex justify-content-between'>
            <Button danger onClick={handleCloseModal} icon={<CloseOutlined />}>
              Cerrar
            </Button>
          </div>
        }
      >
        <div className='col-sm-12 col-md-12 col-lg-12'>
          <div className='elem-list'>
            <div className='col-sm-11 col-md-2 col-lg-2'>
              <label className='mb-1' style={{fontWeight:'bold'}}>F. Emisión:</label>
              <br />
              <label>{moment(factura.fecha_emision).format('DD/MM/YYYY')}</label>  
            </div>

            <div className='col-sm-11 col-md-2 col-lg-2'>
              <label className='mb-1' style={{fontWeight:'bold'}}>F. Venc.:</label>
              <br />
              <label>{factura.fecha_vencimiento === null? null:moment(factura.fecha_vencimiento).format('DD/MM/YYYY')}</label>  
            </div>

            <div className='col-sm-11 col-md-2 col-lg-2'>
              <label className='mb-1' style={{fontWeight:'bold'}}>Tipo Doc.:</label>
              <br />
              <label>{factura.tipo_documento}</label>  
            </div>

            <div className='col-sm-11 col-md-2 col-lg-2'>
              <label className='mb-1' style={{fontWeight:'bold'}}>Moneda:</label>
              <br />
              <label>{factura.parv_descripcion}</label>  
            </div>

            <div className='col-sm-11 col-md-2 col-lg-2'>
              <label className='mb-1' style={{fontWeight:'bold'}}>T. Cambio:</label>
              <br />
              <label>{factura.TipCambio}</label>  
            </div>
          </div>

          <div className='elem-list mt-2'>
            <div className='col-sm-11 col-md-4 col-lg-4'>
              <label className='mb-1' style={{fontWeight:'bold'}}>Razón Social:</label>
              <br />
              <label>{factura.descripcion}</label>  
            </div>

            <div className='col-sm-11 col-md-4 col-lg-4'>
              <label className='mb-1' style={{fontWeight:'bold'}}>Nombre Comercial:</label>
              <br />
              <label>{factura.nombre_comercial}</label>  
            </div>

            <div className='col-sm-11 col-md-3 col-lg-3'>
              <label className='mb-1' style={{fontWeight:'bold'}}>RUC:</label>
              <br />
              <label>{factura.ruc}</label>  
            </div>
          </div>

          <div className='elem-list mt-2'>
            <div className='col-sm-11 col-md-2 col-lg-2'>
              <label className='mb-1' style={{fontWeight:'bold'}}>T. Pago:</label>
              <br />
              <label>{factura.tipo_pago}</label>  
            </div>

            <div className='col-sm-11 col-md-2 col-lg-2'>
              <label className='mb-1' style={{fontWeight:'bold'}}>Forma de Pago:</label>
              <br />
              <label>{factura.forma_pago}</label>  
            </div>

            <div className='col-sm-11 col-md-2 col-lg-2'>
              <label className='mb-1' style={{fontWeight:'bold'}}>Serie:</label>
              <br />
              <label>{factura.serie}</label>  
            </div>

            <div className='col-sm-11 col-md-2 col-lg-2'>
              <label className='mb-1' style={{fontWeight:'bold'}}>N° Serie:</label>
              <br />
              <label>{factura.numero_serie}</label>  
            </div>
          </div>

          <div className='elem-list mt-2'>
            <div className='col-sm-12 col-md-12 col-lg-12'>
              <b style={{fontSize:"18px"}} className="mr-2">Archivos:</b>
            </div>

            <div className='col-sm-2 col-md-2 col-lg-2'>
              <b>Orden de Compra:</b>
              <Button danger className=' ml-1'
                title="Exportar Orden de Compra" 
                onClick={e => ExportarPDF()}
                icon={<FilePdfOutlined />}/>
            </div>

            {pdfFacturas.map((pdf, index) => {
              return(
                <div className='col-sm-2 col-md-2 col-lg-2'>
                  <b>Factura { index + 1 }:</b>
                  <Button className='bg-color-success mr-1 ml-1' 
                    title="Ver Archivo" 
                    onClick={e => PreviewFile(pdf)}
                    icon={<EyeOutlined />}
                  />

                  <Button type={'primary'} 
                    title="Descargar Archivo" 
                    onClick={e => DownloadFile(`PDF_Factura_${pdf.id_factura}`, pdf, index)}
                    icon={<DownloadOutlined />} 
                  />  
                </div>
              )
            })}
          </div>

          <div className='col-sm-12 col-md-12 col-lg-12 mt-4'>
            <Table 
              pagination={false} 
              columns={columns}
              dataSource={detOrdenesCompraList}
              size="small"
              footer={() => Total()}
            />
          </div>

          <div className='elem-list mt-2'>
            <div className='col-sm-11 col-md-2 col-lg-2'>
              <label className='mb-1 mr-2' style={{fontWeight:'bold'}}>% Detracción:</label>
              <label>{factura.detraccion}</label>  
            </div>
          </div>

        </div>
          
      </Modal>
    </>
  )
}

export default ModalDetalleFactura
