import moment from "moment";
import {PDFExport} from '@progress/kendo-react-pdf'
import { Table } from "antd";

const columns = [
  {
    title: 'FECHA',
    dataIndex: 'fecha_orden_compra',
    key: 'fecha_orden_compra',
    render: text => (
      moment.utc(text).format('DD/MM/YY')
    )
  },
  {
    title: 'NUM. ORDEN',
    dataIndex: 'numero_orden',
    key: 'numero_orden'
  },
  {
    title: 'PROVEEDOR',
    dataIndex: 'descripcion',
    key: 'descripcion'
  },
  {
    title: 'RUC',
    dataIndex: 'ruc',
    key: 'ruc'
  },
  {
    title: 'TIPO DOC.',
    dataIndex: 'tipo_documento',
    key: 'tipo_documento'
  },
  {
    title: 'PAGO',
    dataIndex: '',
    key: '',
    render: record => {
      return(<><label>{record.tipo_pago}-{record.forma_pago}</label></>)
    }
  },
  {
    title: 'ESTADO',
    dataIndex: 'estado',
    key: 'estado'
  },
  {
    title: 'TOTAL',
    dataIndex: '',
    key: '',
    render: record => {
      return(<><label>{record.Parc_Valor} {record.total.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</label></>)
    },
    align: 'right'
  }
]

const PageTemplate = (props) => {
  return (
    <div style={{ position: "absolute", bottom: "10px", left: "50%" }}>
      Pag. {props.pageNum} de {props.totalPages}
    </div>
  );
};

const Total = (listaOrdenes) => {
  return(
    <>
      <div className='d-flex justify-content-between'>
        <b>Total</b>
        <b className='mr-2'>
          {listaOrdenes.length === 0?'':listaOrdenes[0].Parc_Valor} {listaOrdenes.map(det => det.total).reduce((prev, next) => prev + next)
          .toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        </b>
      </div>
    </>
  )
}

export const PdfFileOrdenes = (componentePDF, ordenesCompraListFiltradas, fechaInicio, fechaFin) => {
  // console.log(componentePDF)
  return(
    <>
      <div style={{ position: "absolute", left: "-4000px", top: 0 }}>
        <div className='row'>
          <PDFExport 
            ref={componentePDF} 
            paperSize='A4' 
            margin="1cm" 
            landscape={true}
            pageTemplate={PageTemplate}
            fileName='Órdenes de Compra'
            scale={0.5}
          >
            <Table
              pagination={false}
              columns={columns}
              dataSource={ordenesCompraListFiltradas}
              size='small'
              footer={() => Total(ordenesCompraListFiltradas)}
            />
          </PDFExport>
        </div>
      </div>
    </>
    
  )
}