import {PDFExport} from '@progress/kendo-react-pdf'
import { Table } from "antd";

const columnsRequerimientos = [
  {
    title: 'CODIGO',
    dataIndex: 'codigo',
    key: 'codigo'
  },
  {
    title: 'NOMBRE',
    dataIndex: 'nombre',
    key: 'nombre'
  },
  {
    title: 'RUBRO',
    dataIndex: 'nombre_rubro',
    key: 'nombre_rubro'
  },
  {
    title: 'COD. BARRAS',
    dataIndex: 'ean',
    key: 'ean'
  },
  {
    title: 'STOCK DISPONIBLE',
    dataIndex: 'stock_disponible',
    key: 'stock_disponible'
  },
]

const PageTemplate = (props) => {
  return (
    <div style={{ position: "absolute", bottom: "10px", left: "50%" }}>
      Pag. {props.pageNum} de {props.totalPages}
    </div>
  );
};

export const PdfFileArticulos = (componentePDF, articulosListFiltrados) => {
  return(
    <>
      <div style={{ position: "absolute", left: "-4000px", top: 0 }}>
        <div className='row'>
          <PDFExport 
            ref={componentePDF} 
            paperSize='A4' 
            margin="1cm" 
            landscape={true}
            pageTemplate={PageTemplate}
            fileName='Articulos'
          >
            <Table
              pagination={false}
              columns={columnsRequerimientos}
              dataSource={articulosListFiltrados}
              size='small'
            />
          </PDFExport>
        </div>
      </div>
    </>
  )
}