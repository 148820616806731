import { FileExcelOutlined } from "@ant-design/icons";
import { Button } from "antd";
import ReactExport from 'react-export-excel-xlsx-fix';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ContenidoExcelArticulo = (articulos) => {
  return[
    {
      columns: [
        {
          value: "CÓDIGO"
        },
        {
          value: "NOMBRE",
          widthPx: 200
        },
        {
          value: "RUBRO",
          widthPx: 200
        },
        {
          value: "CÓD. BARRAS"
        },
        {
          value: "STOCK DISPONIBLE"
        }
      ],
      data: articulos.map(articulo => {
        return [
          {value: `${articulo.codigo}`},
          {value: `${articulo.nombre}`},
          {value: `${articulo.nombre_rubro}`},
          {value: `${articulo.ean === null? '-': articulo.ean}`},
          {value: `${articulo.stock_disponible}`},
        ]
      })
    }
  ]
}

export const ExcelFileArticulos = (articulosListFiltrados) => {
  return(
    <>
      <ExcelFile element={
        <Button className='bg-color-success' title="Exportar Excel" 
        // onClick={e => ExportarExcel()}
        icon={<FileExcelOutlined />}>EXCEL
        </Button>} filename="Articulos">
        <ExcelSheet dataSet={ContenidoExcelArticulo(articulosListFiltrados)} 
          name="Articulos"/>
      </ExcelFile>
    </>
  )
}