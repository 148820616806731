import moment from "moment";
import {PDFExport} from '@progress/kendo-react-pdf'
import { Table } from "antd";

const columns = [
  {
    title: 'FECHA',
    dataIndex: 'fecha_creacion',
    key: 'fecha_creacion',
    render: text => (
      moment.utc(text).format('DD/MM/YY HH:mm')
    )
  },
  {
    title: 'ORDEN COMPRA',
    dataIndex: 'numero_orden',
    key: 'numero_orden'
  },
  {
    title: 'TIPO MOV.',
    dataIndex: 'tipo_operacion',
    key: 'tipo_operacion'
  },
  {
    title: 'PROVEEDOR',
    dataIndex: 'descripcion',
    key: 'descripcion'
  },
  {
    title: 'RUC',
    dataIndex: 'ruc',
    key: 'ruc'
  },
  {
    title: 'DOCUMENTO',
    dataIndex: 'tipo_documento',
    key: 'tipo_documento'
  },
  {
    title: 'N° DOCUMENTO',
    render: record => {
      return(<>{record.serie} {record.numero_serie}</>)
    }
  },
  {
    title: 'PAGO',
    render: record => {
      return(<>{record.tipo_pago}-{record.forma_pago}</>)
    }
  },
  {
    title: 'CONCEPTO',
    dataIndex: 'nombre',
    key: 'nombre'
  },
  {
    title: 'DETALLE CONCEPTO',
    dataIndex: 'detalle_concepto',
    key: 'detalle_concepto'
  },
  {
    title: 'MONTO',
    render: record => {
      return(<>{record.Parc_Valor} {record.monto}</>)
    }
  },
  {
    title: 'RESPONSABLE',
    dataIndex: 'responsable',
    key: 'responsable'
  },
]

const PageTemplate = (props) => {
  return (
    <div style={{ position: "absolute", bottom: "10px", left: "50%" }}>
      Pag. {props.pageNum} de {props.totalPages}
    </div>
  );
};

export const PdfFilePagos = (componentePDF, pagosListFiltrados, fechaInicio, fechaFin) => {
  return(
    <>
      <div style={{ position: "absolute", left: "-4000px", top: 0 }}>
          <PDFExport 
            ref={componentePDF} 
            paperSize='A4'
            margin="1cm" 
            landscape={true}
            pageTemplate={PageTemplate}
            fileName='Pagos'
            scale={0.5}
          >
            <Table
              pagination={false}
              columns={columns}
              dataSource={pagosListFiltrados}
              size='small'
            />
          </PDFExport>
      </div>
    </>
  )
}