import { FileExcelOutlined } from "@ant-design/icons";
import { Button } from "antd";
import moment from "moment";
import ReactExport from 'react-export-excel-xlsx-fix';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ContenidoExcelMovimientos = (movimientos, fechaInicio, fechaFin) => {
  return[
    {
      columns: [
        {
          value:`Pagos Desde:`,
          style:{font:{bold:true}},
          widthPx: 120
        },
        {
          value:`${moment(fechaInicio).format("DD/MM/YYYY")}`,
          widthPx: 120
        },
        { value:'', widthPx: 80},
        {
          value:`Hasta:`, widthPx: 150,
          style:{font:{bold:true}}
        },
        {
          value:moment(fechaFin).format("DD/MM/YYYY"),
          widthPx: 300
        },
        { value:'', widthPx: 100},
        { value:'', widthPx: 100},
        { value:'', widthPx: 300}
      ],
      data:[]
    },
    {
      ySteps: 1,
      columns: [
        { value: "FECHA" },
        { value: "TIPO MOV." },
        { value: "ORIGEN" },
        { value: "DESTINO" },
        { value: "ARTÍCULO" },
        { value: "UNIDAD" },
        { value: "CANTIDAD" },
        { value: "COMENTARIO" }
      ],
      data: movimientos.map(movimiento => {
        return[
          {value: `${moment.utc(movimiento.fecha_creacion).format("DD/MM/YYYY HH:mm")}`},
          {value: `${movimiento.tipo}`},
          {value: `${movimiento.id_almacen_origen !== null? movimiento.nombre_almacen_origen: movimiento.nombre_area_origen}`},
          {value: `${movimiento.id_almacen_destino !== null? movimiento.nombre_almacen_destino: movimiento.nombre_area_destino}`},
          {value: `${movimiento.nombre_articulo} ${movimiento.descripcion === ''? movimiento.descripcion: '- '.concat(movimiento.descripcion)}`},
          {value: `${movimiento.nombre_unidad}`},
          {value: `${movimiento.cantidad}`},
          {value: `${movimiento.comentario}`}
        ]
      })
    }
  ]
}

export const ExcelFileMovimientos = (movimientosListFiltrados, fechaInicio, fechaFin) => {
  return(
    <>
      <ExcelFile element={
        <Button className='bg-color-success' title="Exportar Excel" 
        // onClick={e => ExportarExcel()}
        icon={<FileExcelOutlined />}>EXCEL
        </Button>} filename="Movimientos">
        <ExcelSheet dataSet={ContenidoExcelMovimientos(movimientosListFiltrados, fechaInicio, fechaFin)} 
          name="Movimientos"/>
      </ExcelFile>
    </>
  )
}