import { CheckOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Button, Table, message, Popconfirm } from 'antd';
import { useRef } from "react";
import moment from 'moment';
import { PdfFileOrden } from '../componentesPDF/GenerarPDFOrden';

const PestaniaDetalleOrdenCompra = (props) => {
  const componentePDF = useRef(null)

  const columnsServ = [
    { title: 'DETALLE DEL SERVICIO', key: 'descripcion', dataIndex: 'descripcion' },
    { title: 'PRECIO',
      render: record => {
        return(
          <>
            {props.orden_compra.Parc_Valor} {record.precio.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </>
        )
      } 
    }
  ]

  const columns = [
    { title: 'MATERIAL/EQUIPO', key: 'nombre_articulo', dataIndex: 'nombre_articulo' },
    { title: 'DESCRIPCIÓN', key: 'descripcion', dataIndex: 'descripcion' },
    { title: 'MARCA', key: 'marca', dataIndex: 'marca' },
    { title: 'PRESENTACIÓN',
      render: record => {
        return(
          <>{record.nombre_unidad} X {record.cantidad_unidades}</>
        )
      }
    },
    { title: 'CANTIDAD', 
      render: record => {
        return(<>{record.cantidad.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>)
      }
    },
    { title: 'PRECIO',
      render: record => {
        return(
          <>
            {props.orden_compra.Parc_Valor} {record.precio.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </>
        )
      } 
    },
    {
      title: 'TOTAL',  
      render: record => {
        return(
          <>
            {props.orden_compra.Parc_Valor} 
            {(record.cantidad * record.precio).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </>
        )
      }
    }
  ]

  const DespacharItems = async(orden_compra) => { 
    // console.log(orden_compra)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/DespacharItems`,{
        method: "PUT",
        body: JSON.stringify({...orden_compra, 
          id_sucursal:1, 
          fecha_movimiento: moment.utc(new Date()).format('YYYY/MM/DD'),
          usuario: props.usuario
        }),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data =>{
        message.success(data.message)
        props.setLoadingButton(false)
        props.getOrdenesCompra()
        props.handleCloseModal()
      }).catch(err => {
        console.log(err)
        props.setLoadingButton(false)
        props.handleCloseModal()
      });
    } catch (error) {
      props.setLoadingButton(false)
      console.log(error)
      props.handleCloseModal()
    }
  }

  const ExportarPDF = () => {
    componentePDF.current.save()
  }

  return(
    <>
      {PdfFileOrden(componentePDF, props.orden_compra, props.detOrdenesCompraList, props.detalleBancos)}
      <div className='elem-list mb-4'>
        <div className='col-sm-4 col-md-1 col-lg-1'>
          <label className='mb-3' style={{fontWeight:'bold'}}>Moneda:</label>
          <br />
          <b>{props.orden_compra.parv_descripcion === 'SOLES'? 'S/.': '$'}</b>
        </div>

        <div className='col-sm-4 col-md-2 col-lg-2'>
          <label className='mb-3' style={{fontWeight:'bold'}}>Tipo de Cambio:</label>
          <br />
          <b>{props.orden_compra.TipCambio}</b>
        </div>

        <div className='col-sm-3 col-md-3 col-lg-3'>
          <label className='mb-3' style={{fontWeight:'bold'}}>Documento:</label>
          <br />
          <b>{props.orden_compra.tipo_documento}</b>
        </div>

        <div className='col-sm-7 col-md-3 col-lg-3'>
          <label className='mb-3' style={{fontWeight:'bold'}}>Modo de Pago:</label>
          <br />
          <b>{props.orden_compra.tipo_pago} - {props.orden_compra.forma_pago}</b>
        </div>
        
        <div className='col-sm-4 col-md-2 col-lg-2'>
          <label className='mb-3' style={{fontWeight:'bold'}}>Tipo de Entrega:</label>
          <br />
          <b>{props.orden_compra.tipo_entrega}</b>
        </div>
      </div>

      <div className='elem-list'>
        <div className='col-sm-4 col-md-5 col-lg-4'>
          <label className='mb-3' style={{fontWeight:'bold'}}>Razón Social:</label>
          <br />
          <b>{props.orden_compra.descripcion}</b>
        </div>

        <div className='col-sm-4 col-md-4 col-lg-4'>
          <label className='mb-3' style={{fontWeight:'bold'}}>Nombre Comercial:</label>
          <br />
          <b>{props.orden_compra.nombre_comercial}</b>
        </div>

        <div className='col-sm-5 col-md-2 col-lg-2'>
          <label className='mb-3' style={{fontWeight:'bold'}}>RUC:</label>
          <br />
          <b>{props.orden_compra.ruc}</b>
        </div>

        <div className='col-sm-5 col-md-1 col-lg-1'>
          <label className='mb-3' style={{fontWeight:'bold'}}>Exportar:</label>
          <br />
          <Button danger 
            title="Exportar Orden de Compra" 
            onClick={e => ExportarPDF()}
            icon={<FilePdfOutlined />}/>
        </div>
      </div>

      <div className='col-sm-12 col-md-12 col-lg-12 mt-4'>
        <Table 
          pagination={false} 
          columns={props.orden_compra.tipo === 'COMPRA'?columns:columnsServ}
          dataSource={props.detOrdenesCompraList}
          size="small"
          footer={() => props.Total()}
        />
      </div>

      <div className='col-sm-4 col-md-4 col-lg-4 mt-4'>
          {props.permisoLogistica === true && props.orden_compra.estado === 'DESPACHADA'? 
            <>
              <label style={{fontWeight:'bold'}}>Enviar Items al Área:</label>
                <Popconfirm
                  title="¿Seguro de Enviar los Items a las Áreas Solicitantes?"
                  onConfirm={e=>DespacharItems(props.orden_compra)} 
                  okText="Si" cancelText="No"
                >
                  <Button type='primary' className='bg-color-success ml-2'
                    loading = {props.loadingButton}
                    icon={<CheckOutlined />}/> 
                </Popconfirm>
            </>
          :null}
        </div>
      
      <div className='col-sm-12 col-md-12 col-lg-12 mt-4'>
        <b style={{fontSize:"18px"}}>Comentario:</b>
        <p style={{fontSize:"18px"}}>{props.orden_compra.comentario}</p>
      </div>

      <div className='col-sm-12 col-md-12 col-lg-12'>
        <h4>Términos y Condiciones</h4>
      </div>

      <div className='elem-list'>
        <div className='col-sm-4 col-md-3 col-lg-3'>
          <b style={{fontSize:"18px"}}>Lugar de Ejecución/Entrega:</b><br />
          <b>{props.orden_compra.lugar_ejecucion}</b>
        </div>

        <div className='col-sm-5 col-md-2 col-lg-2'>
          <b style={{fontSize:"18px"}}>Días de plazo:</b><br />
          <b>{props.orden_compra.dias_plazo}</b>
        </div>

        <div className='col-sm-12 col-md-12 col-lg-4'>
          <b style={{fontSize:"18px"}}>Penalidades por mora u otras penalidades:</b>
          <p style={{fontSize:"18px"}}>{props.orden_compra.penalidades}%</p>
        </div>
      </div>

      
    </>
  )

}

export default PestaniaDetalleOrdenCompra