import { CheckOutlined, CloseOutlined, EditOutlined, FileExcelOutlined, FileOutlined, FilePdfOutlined, PlusOutlined } from '@ant-design/icons'
import { Table, Card, Tag, Button, message, Popconfirm, Input, Select, Form } from 'antd'
import moment from 'moment'
import { useRef, useState, useEffect } from 'react'
import { PdfFileOrdenesTrabajo } from '../componentesPDF/GenerarPDFOrdenTrabajos'
import ModalFormularioOrdenTrabajo from './ModalFormularioOrdenTrabajo'
import ModalOrdenTrabajoArchivos from './ModalOrdenTrabajoArchivos'
import { Option } from 'antd/lib/mentions'
import { existeRol } from '../FuncionesGenerales'
import Cookies from 'universal-cookie'
import { IPageData } from "../../../interfaces/page"
import { usePageData } from '../../../hooks/usePage'
import { OrdenesTrabajoExcel } from '../componentesExcel/GenerarExcelOrdenesTrabajo'

const cookies = new Cookies()

let pageData: IPageData = {
  fulFilled: true,
  title: 'Órdenes de Trabajo',
  // loaded: false,
  breadcrumbs: [
    {
      title: 'Órdenes de Trabajo',
    }
  ]
}

const OrdenesTrabajo = (props) => {
  pageData.title = props.id_inventario? 'Inventarios': 'Órdenes de Trabajo'
  usePageData(pageData)
  const [openModal, setOpenModal] = useState(false)
  const [openModalArchivos, setOpenModalArchivos] = useState(false)
  const [tituloModal, setTituloModal] = useState(false)
  const [permisoOrdenes, setPermisoOrdenes] = useState(false)
  const [ordenTrabajo, setOrdenTrabajo] = useState({
    id_orden_trabajo: null,
    id_inventario: null,
    descripcion: null,
    estado: 'ANALISIS',
    id_proveedor: null,
    precio: '',
    nombre_articulo: '',
    marca: '',
    modelo: '',
    serie: '',
    fecha_orden: '',
    tipo_mantenimiento: undefined,
    tipo: undefined,
    notas: null,
    CantFiles: 0
  })
  const [fechaInicio, setFechaInicio] = useState(''/*moment(new Date()).format("YYYY-MM").concat('-01')*/)
  const [fechaFin, setFechaFin] = useState(moment(new Date()).format("YYYY-MM-DD"))
  const [selectArticulo, setSelectArticulo] = useState(undefined)
  const [selectProveedor, setSelectProveedor] = useState(undefined)
  const [selectTipo, setSelectTipo] = useState(undefined)
  const [selectTipoMantenimiento, setSelectTipoMantenimiento] = useState(undefined)
  const [selectEstado, setSelectEstado] = useState(undefined)
  const [inputMarca, setInputMarca] = useState('')
  const [inputModelo, setInputModelo] = useState('')
  const [inputSerie, setInputSerie] = useState('')
  const [inputCodigoPat, setInputCodigoPat] = useState('')
  const [inventariosList, setInventariosList] = useState([])
  const [articulosList, setArticulosList] = useState([])
  const [ordenesTrabajo, setOrdenesTrabajo] = useState([])
  const [proveedoresList, setProveedoresList] = useState([])
  
  const [form] = Form.useForm()
  const resetForm = () => form.resetFields()

  const optionFilter = (input, option) => {
    return (option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0)
  }

  const componenteExcel = useRef(null)

  const handleOpenModalArchivos = (orden) => {
    setOpenModalArchivos(true)
    setOrdenTrabajo(orden)
  }

  useEffect(() => {
    GetOrdenesTrabajo()
    getArticulos()
    getProveedores()

    setPermisoOrdenes(existeRol('Logistica.Gestion.Mantenimientos'))
  }, [])

  useEffect(() => {
    GetOrdenesTrabajo()

    setFechaInicio('')
    setFechaFin(moment(new Date()).format("YYYY-MM-DD"))
    setSelectArticulo(undefined)
    setSelectProveedor(undefined)
    setSelectTipo(undefined)
    setSelectTipoMantenimiento(undefined)
    setSelectEstado(undefined)
    setInputMarca('')
    setInputModelo('')
    setInputSerie('')
    getInventarios()
  }, [props.id_inventario])

  const getInventarios = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getInventarios`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setInventariosList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getProveedores = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getProveedores`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setProveedoresList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getArticulos = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getArticulos`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        const equipos_biomedicos = data.filter(equipo => {
          return(equipo.id_rubro === 50) //50 = rubro "EQUIPO BIOMEDICO"
        })
        setArticulosList(equipos_biomedicos)
      })
    } catch (error) {
      console.log(error)
    }
  }



  const GetOrdenesTrabajo = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/${props.id_inventario?`GetOrdenesTrabajoByIdInventario/${props.id_inventario}`:'GetOrdenesTrabajo'}`)
      .then(res => res.json())
      .then(data => {
        if (data.message) {
          setOrdenesTrabajo([])
        } else {
          if (existeRol('Logistica.Acceso.LimatamboFast') === true) {
            const limatamboFast = data.filter(orden => {
              return orden.id_area === 52
            })
            setOrdenesTrabajo(limatamboFast)
          } else {
            setOrdenesTrabajo(data) 
          }
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const columns = () => {
    let columns: Array<any> = [
      {
        title: (<div style={{width:180}}></div>),
        render: record => {
          return(
            <>
              {record.estado !== 'FINALIZADO' && permisoOrdenes === true && record.estado !== 'CANCELADO'?
                <>
                  {record.estado === "PROCESO" || record.estado === "ANALISIS"? 
                    <>
                        <Button type='primary' className="bg-color-orange mr-2"
                        title="Editar Orden de Trabajo" 
                        onClick={e=>handleOpenModal("Editar Orden de Trabajo", record)}
                        icon={<EditOutlined />}
                      /> 
                      <Popconfirm
                        title="¿Seguro de Finalizar esta Orden de Trabajo?"
                        onConfirm={e => UpdateEstadoOrdenTrabajo(record, "FINALIZADO")}
                        okText="Si" cancelText="No"
                      >
                        <Button className='bg-color-success mr-2'
                          title="Finalizar Orden de Trabajo"
                          icon={<CheckOutlined />}/> 
                      </Popconfirm>
                    </>
                  :null}

                  {record.estado !== "RECHAZADO"? 
                    <Popconfirm
                      title="¿Seguro de Rechazar esta Orden de Trabajo?"
                      onConfirm={e =>UpdateEstadoOrdenTrabajo(record, "RECHAZADO")}
                      okText="Si" cancelText="No"
                    >
                      <Button danger className='mr-2'
                        title="Rechazar Orden de Trabajo"
                        icon={<CloseOutlined />}/> 
                    </Popconfirm>
                  :null}
                </>
              : null}

              <Button title="Ver Archivos" onClick={e=>handleOpenModalArchivos(record)} icon={<FileOutlined />} />
            </>
          )
        }
      },
      {
        title: 'FECHA',
        render: record => {
          return(<div>{moment.utc(record.fecha_orden).format('DD/MM/YY')}</div>)
        }
      },
      {
        title: (
          <>
            <div style={{width:200}}>
              DESCRIPCIÓN
            </div>
          </>
        ),
        key: 'descripcion',
        dataIndex: 'descripcion'
      },  
    ]
    
    if (!props.id_inventario) {
      columns = [...columns,
        {
          title: (
            <>
              <div style={{width:170}}>
                <Input defaultValue={inputCodigoPat} 
                  onChange={e=>setInputCodigoPat(e.currentTarget.value)}
                    placeholder = "CÓD. PATRIMONIAL"
                />
              </div>
            </>
          ),
          dataIndex: 'codigo_patrimonial',
          key: 'codigo_patrimonial'
        },
        {
          title: (
            <>
              <div style={{width:180}}>
                <Select
                  showSearch
                  allowClear
                  defaultValue={selectArticulo}
                  className='mr-2'
                  placeholder='EQUIPO'
                  optionFilterProp='children'
                  filterOption={optionFilter}
                  onChange={e => setSelectArticulo(e)}
                >
                  {articulosList.map(articulo => {
                    return(
                      <Option value={articulo.id_articulo} 
                        key={articulo.id_articulo}>
                        {articulo.nombre}
                      </Option>
                    )
                  })}
                </Select>
              </div>
            </>
          ),
          key: 'nombre_articulo',
          dataIndex: 'nombre_articulo'
        },
        {
          title: (
            <>
              <div style={{width:120}}>
                <Input defaultValue={inputMarca} 
                  onChange={e=>setInputMarca(e.currentTarget.value)}
                    placeholder = "MARCA"
                />
              </div>
            </>
          ),
          key: 'marca',
          dataIndex: 'marca'
        },
        {
          title: (
            <>
              <div style={{width:120}}>
                <Input defaultValue={inputModelo} 
                  onChange={e=>setInputModelo(e.currentTarget.value)}
                    placeholder = "MODELO"
                />
              </div>
            </>
          ),
          dataIndex: 'modelo',
          key: 'modelo'
        },
        {
          title: (
            <>
              <div style={{width:120}}>
                <Input defaultValue={inputSerie} 
                  onChange={e=>setInputSerie(e.currentTarget.value)}
                    placeholder = "SERIE"
                />
              </div>
            </>
          ),
          dataIndex: 'serie',
          key: 'serie'
        }  
      ]
    }

    columns = [...columns,
      {
        title: (
          <>
            <div style={{width:180}} className="mr-4">
              <Select
                allowClear
                showSearch
                defaultValue={selectProveedor}
                className='mb-lg-0'
                placeholder='PROVEEDOR'
                optionFilterProp='children'
                filterOption={optionFilter}
                onChange={(e)=>setSelectProveedor(e)}
              >
              {proveedoresList.map(proveedor =>{
                return(
                  <Option value={proveedor.id_prove} key={proveedor.id_prove}>{proveedor.descripcion}</Option>
                )
              })}
              </Select>
            </div>
          </>
        ),
        key: 'proveedor',
        dataIndex: 'proveedor'
      },
      {
        title: (
          <div style={{width:180}}>
            <Select
              allowClear
              showSearch
              defaultValue={selectTipoMantenimiento}
              className='mb-lg-0'
              placeholder='TIPO MANT.'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={e=>setSelectTipoMantenimiento(e)}
            >
              <Option value='PROGRAMADO' key='PROGRAMADO'>PROGRAMADO</Option>
              <Option value='CORRECTIVO' key='CORRECTIVO'>CORRECTIVO</Option>
              <Option value='PRESTAMO' key='PRESTAMO'>PRÉSTAMO DE EQUIPO</Option>
              <Option value='CAMBIO' key='CAMBIO'>CAMBIO DE ÁREA</Option>
              <Option value='BAJA' key='BAJA'>BAJA DE EQUIPO</Option>
              <Option value='INSTALACION' key='INSTALACION'>INSTALACIÓN</Option>
              <Option value='CAPACITACION' key='CAPACITACION'>CAPACITACIÓN</Option>
            </Select>  
          </div>
        ),
        key: 'tipo_mantenimiento',
        dataIndex: 'tipo_mantenimiento'
      },
      {
        title: (
          <div style={{width:180}}>
            <Select
              allowClear
              showSearch
              defaultValue={selectTipo}
              className='mb-lg-0'
              placeholder='TIPO'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={e=>setSelectTipo(e)}
            >
              <Option value='MANTENIMIENTO' key='MANTENIMIENTO'>MANTENIMIENTO</Option>
              <Option value='CALIBRACION' key='CALIBRACION'>CALIBRACIÓN</Option>
            </Select>  
          </div>
        ),
        key: 'tipo',
        dataIndex: 'tipo'
      },
      {
        title: (
          <>
            <div style={{width:140}}>
              <Select
                allowClear
                defaultValue={selectEstado}
                className=''
                placeholder='ESTADO'
                optionFilterProp='children'
                filterOption={optionFilter}
                onChange={e => setSelectEstado(e)}
              >
                <Option value='ANALISIS'>ANÁLISIS</Option>
                <Option value='PROCESO'>PROCESO</Option>
                <Option value='FINALIZADO'>FINALIZADO</Option>
                <Option value='RECHAZADO'>RECHAZADO</Option>
                <Option value='CANCELADO'>CANCELADO</Option>
              </Select>  
            </div>
          </>
        ),
        render: record => {
          switch (record.estado) {
            case 'ANALISIS':
              return(<Tag color={'#336cfb'}>ANÁLISIS</Tag>)

            case 'PROCESO':
              return(<Tag color={'#FC5F1B'}>PROCESO</Tag>)

            case 'FINALIZADO':
              return(<Tag color={'#b7ce63'}>FINALIZADO</Tag>)

            case 'RECHAZADO':
              return(<Tag color={'#AA0404'}>RECHAZADO</Tag>)

            case 'CANCELADO':
              return(<Tag color={'#AA0404'}>CANCELADO</Tag>)

            default:
              break
          }
        }
      },
      {
        title: 'PRECIO',
        key: 'precio',
        dataIndex: 'precio'
      },
      {
        title: (
          <>
            <div style={{width:200}}>
              NOTAS
            </div>
          </>
        ),
        key: 'notas',
        dataIndex: 'notas'
      }
    ]  

    return columns
  }
  

  const UpdateEstadoOrdenTrabajo = async (orden, estado) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/UpdateEstadoOrdenTrabajo`,{
        method: "PUT",
        body: JSON.stringify({id_orden_trabajo: orden.id_orden_trabajo, estado: estado}),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data => {
        GetOrdenesTrabajo()
        message.success(data.message)
      }).catch(err => console.log(err))
    } catch (error) {
      console.log(error)
    }
  }

  const handleOpenModal = async(titulo, orden) => {
    // console.log(orden)
    resetForm()
    setOpenModal(true)
    setTituloModal(titulo)
    setOrdenTrabajo({
      id_orden_trabajo: orden.id_orden_trabajo,
      id_inventario: orden.id_inventario,
      descripcion: orden.descripcion,
      estado: orden.estado,
      id_proveedor: orden.id_proveedor,
      precio: orden.precio,
      nombre_articulo: orden.nombre_articulo,
      marca: orden.marca,
      modelo: orden.modelo,
      serie: orden.serie,
      fecha_orden: orden.fecha_orden,
      tipo_mantenimiento: orden.tipo_mantenimiento,
      tipo: orden.tipo,
      notas: orden.notas,
      CantFiles: orden.CantFiles
    })

    form.setFieldsValue({
      descripcion: orden.descripcion,
      estado: orden.estado,
      id_proveedor: orden.id_proveedor,
      precio: orden.precio,
      nombre_articulo: orden.nombre_articulo,
      marca: orden.marca,
      modelo: orden.modelo,
      serie: orden.serie,
      fecha_orden: moment.utc(orden.fecha_orden).format('YYYY-MM-DD'),
      tipo_mantenimiento: orden.tipo_mantenimiento,
      tipo: orden.tipo,
      notas: orden.notas,
      id_inventario: orden.id_inventario
    })
  }

  

  const ordenesTrabajoFiltrados = () => {
    const lista = ordenesTrabajo.filter(orden => {
      return( 
        moment.utc(orden.fecha_orden).format("YYYY-MM-DD") >= fechaInicio && 
        moment.utc(orden.fecha_orden).format("YYYY-MM-DD") <= fechaFin && 
        orden.marca.toUpperCase().includes(inputMarca.toUpperCase()) && 
        orden.modelo.toUpperCase().includes(inputModelo.toUpperCase()) && 
        orden.serie.toUpperCase().includes(inputSerie.toUpperCase()) && 
        orden.codigo_patrimonial.toUpperCase().includes(inputCodigoPat.toUpperCase()) && 
        (selectArticulo === undefined? orden.id_articulo !== selectArticulo: orden.id_articulo === selectArticulo) &&
        (selectTipo === undefined? orden.tipo !== selectTipo: orden.tipo === selectTipo) &&
        (selectTipoMantenimiento === undefined? orden.tipo_mantenimiento !== selectTipoMantenimiento: 
          orden.tipo_mantenimiento === selectTipoMantenimiento) &&
        (selectProveedor === undefined? orden.id_proveedor !== selectProveedor: orden.id_proveedor === selectProveedor) &&
        (selectEstado === undefined? orden.estado !== selectEstado: orden.estado === selectEstado)
      )
    })

    return lista
  }

  const exportExcel = () => {
    const optionsGridOne = componenteExcel.current.workbookOptions()
    componenteExcel.current.save(optionsGridOne)
  }

  if (ordenesTrabajo.length > 0) {
    return(
      <>
        <div className='row'>
          <div className='col-md-3 col-lg-3'>
            <label>Desde: </label> <br/> 
            <Input type="date"/*"datetime-local"*/
              value={fechaInicio}
              onChange={e => setFechaInicio(e.currentTarget.value)}
            />
          </div>

          <div className='col-md-3 col-lg-3'>
            <label>Hasta: </label> <br/> 
            <Input type="date"/*"datetime-local"*/
              value={fechaFin} 
              onChange={e => setFechaFin(e.currentTarget.value)}
            />  
          </div>
          {permisoOrdenes === true?
          <>
          <Button className='bg-color-success mt-4' title="Exportar Excel" icon={<FileExcelOutlined />}
              onClick={exportExcel}/>
            { OrdenesTrabajoExcel(componenteExcel, ordenesTrabajoFiltrados()) }
          </>
          :null
          }
        </div>

        <div className='col-sm-12 col-md-12 col-lg-12'>
          <Card>
            <Table 
              columns={columns()}
              dataSource={ordenesTrabajoFiltrados()}
              size="small"
            />
          </Card>
        </div>

        {<ModalFormularioOrdenTrabajo
          inventariosList = {inventariosList}
          ordenTrabajo = {ordenTrabajo}
          openModal = {openModal}
          setOpenModal = {setOpenModal}
          usuario = {cookies.get('nameid_logis')}
          tituloModal = {tituloModal}
          proveedoresList = {proveedoresList}
          form = {form}
          getOrdenesTrabajo = {GetOrdenesTrabajo}
        />}

        {<ModalOrdenTrabajoArchivos
          openModal = {openModalArchivos}
          setOpenModal = {setOpenModalArchivos}
          ordenTrabajo = {ordenTrabajo}
          setOrdenTrabajo = {setOrdenTrabajo}
        />}
      </>
    )
  } else {
    return(
      <>
        {/* <Button type='primary'  
          title="Nueva Orden de Trabajo" 
          onClick={e=>handleOpenModal('Nueva Orden de Trabajo', null)}
          icon={<PlusOutlined />} >Agregar
        </Button> */}
        
        <div>
          No hay Órdenes de Trabajo
        </div>

        {/* {<ModalFormularioOrdenTrabajo
          ordenTrabajo = {ordenTrabajo}
          tituloModal = {tituloModal}
          openModal = {openModal}
          setOpenModal = {setOpenModal}
          setOrdenTrabajo = {setOrdenTrabajo}
        />} */}
      </>
    )
  }
}

export default OrdenesTrabajo