import { CloseOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, Modal, Popconfirm, Table, message } from 'antd';
import { useState } from 'react';

const ModalEnviarPDF = (props) => {
  const [loadingButton, setLoadingButton] = useState(false)
  const [otroEmail, setOtroEmail] = useState('')
  const [checkOtroEmail, setCheckOtroEmail] = useState(false)

  const handleCloseModal = () => {
    setLoadingButton(false)
    
    setOtroEmail('')
    setCheckOtroEmail(false)
    props.setDetalleRepresentantes([])
    props.setOrden_compra({
      id_orden_compra: null,
      id_cotizacion: null,
      tipo_documento: null,
      tipo_pago: null,
      forma_pago: null,
      comentario: null,
      fecha_orden_compra: null,
      ubicacion_archivo_factura_guia: null,
      nombre_rubro: null,
      id_rubro: null,
      nombre_area: null,
      nombre_responsable: null,
      codigo: null,
      numero_orden: null,
      id_consolidado: null,
      ubicacion_archivo: null,
      fecha_envio: null,
      tipo_entrega: null
    })
    props.setOpenModal(false)
    // props.setDetOrdenesCompraList([])
    // props.setRequerimiento({})
  }

  const EnviarPDFOrden = async() => {
    
    let emailEnvio = null
    let body = {}
    if (checkOtroEmail === true) {
      emailEnvio = otroEmail

      body = {
        orden:{...props.orden_compra, email: emailEnvio}, 
        detOrdenesCompraList: props.detOrdenesCompraList, 
        detalleBancos: props.detalleBancos
      }

      try {
        setLoadingButton(true)
        await fetch(`${process.env.REACT_APP_HOSTNAME}/EnviarPDFOrden`,{
          method: "PUT",
          body: JSON.stringify(body),
          headers:{
            'Content-Type': 'application/json'
          }
        }).then(res => res.json())
        .then(data =>{
          // console.log(data)
          // setLoadingButton(false)
          handleCloseModal()
        })
      } catch (error) {
        console.log(error)
      }
      
    } else {
      const emailDefecto = props.detalleRepresentantes.filter(detalle => {
        return(detalle.defecto === true)
      })
      // console.log(emailDefecto)
      if (emailDefecto.length === 0) {
        message.error('Faltan campos en el detalle (nombres/email)')
      } else {
        emailEnvio = emailDefecto[0].email

        body = {
          orden:{...props.orden_compra, email: emailEnvio}, 
          detOrdenesCompraList: props.detOrdenesCompraList, 
          detalleBancos: props.detalleBancos
        }
        // console.log(body)
        try {
          setLoadingButton(true)
          await fetch(`${process.env.REACT_APP_HOSTNAME}/EnviarPDFOrden`,{
            method: "PUT",
            body: JSON.stringify(body),
            headers:{
              'Content-Type': 'application/json'
            }
          }).then(res => res.json())
          .then(data =>{
            // console.log(data)
            // setLoadingButton(false)
            handleCloseModal()
          })
        } catch (error) {
          console.log(error)
        }
      }
    }
  }

  const detalleRepChange = (value, item) => {
    const listaItems = props.detalleRepresentantes
    const key = listaItems.indexOf(item)

    if (value === true) {
      for (let i = 0; i < listaItems.length; i++) {
        if (i !== key) {
          listaItems[i].defecto = false
        }
      }
    } 
    listaItems[key].defecto = value 
    props.setDetalleRepresentantes([...listaItems])
    setCheckOtroEmail(false)
  }

  const checkOtroChange = (value) => {
    setCheckOtroEmail(value)
    const listaItems = props.detalleRepresentantes
    for (let i = 0; i < listaItems.length; i++) {
      listaItems[i].defecto = false
    }
    props.setDetalleRepresentantes([...listaItems])
  }

  const columns = [
    {
      title: (<><div style={{width:150}}> ENVIAR A:</div></>),
      key: 'email',
      render: record => {
        return(<>{record.email}</>)
      }
    },
    {
      title: '',
      key: 'defecto',
      render: record => {
        return(
          <>
            <Checkbox checked={record.defecto} 
              onChange={e => detalleRepChange(e.target.checked, record)}/>
          </>
        )
      }
    }
  ]

  return(
    <>
      <Modal
        visible={props.openModal}
        closable={false}
        width='50%'
        title={<h3 className='m-0'>Enviar PDF</h3>}
        onCancel={handleCloseModal}
        footer={
          <div className='modal-footer d-flex justify-content-between'>
            <Button danger onClick={handleCloseModal} icon={<CloseOutlined />}>
              Cerrar
            </Button>

            <Popconfirm
              title="¿Enviar La orden a este correo?"
              onConfirm={EnviarPDFOrden} 
              okText="Si" cancelText="No"
            >
              <Button type='primary' className='bg-color-success'
                icon={<SendOutlined />} loading={loadingButton}>Enviar
              </Button> 
            </Popconfirm>
          </div>
        }
      >
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <div className='elem-list'>
              <div className='col-sm-11 col-md-11 col-lg-11'>
                <Table 
                  pagination={false} 
                  columns={columns}
                  dataSource={props.detalleRepresentantes}
                  size="small"
                />
              </div>
            </div>

            <div className='elem-list'>
              <div className='col-sm-10 col-md-9 col-lg-9'>
                <b className='mr-4'>Otro:</b>
                <Input value={otroEmail} placeholder = "Ingresar Correo"
                  onChange={e=>setOtroEmail(e.currentTarget.value)}
                />  
              </div>

              <div className='col-sm-1 col-md-1 col-lg-1'>
                <br/>
                <Checkbox checked={checkOtroEmail} 
                onChange={e => checkOtroChange(e.target.checked)}/>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModalEnviarPDF