import { CloseOutlined, DeleteOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, 
  Select, Table, Card, message, Checkbox } from 'antd';
import { Option } from 'antd/lib/mentions';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { Rule } from 'antd/lib/form';
import ModalArticulo from '../componentesArticulos/ModalArticulo';

const { Item } = Form;
const rules = {
  required: { required: true, message: <></> } as Rule
};

const ModalInventario = (props) => {
  const [loadingButton, setLoadingButton] = useState(false)
  const [inventario, setInventario] = useState({
    id_area: null,
    id_articulo: null,
    marca: '',
    modelo: '',
    serie: '',
    operativo: false,
    calibracion: false,
    id_proveedor: null,
    codigo_patrimonial: '',
    meses_proximo_mant: '',
    meses_proximo_cali: '',
    descripcion: ''
  })
  const [articuloNuevo, setArticuloNuevo] = useState({})
  const [openModalArticulo, setOpenModalArticulo] = useState(false);
  const [form] = Form.useForm();
  const resetForm = () => form.resetFields();

  const optionFilter = (input, option) => {
    return (option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0)
  }

  useEffect(() => {
    if (props.openModal === true) {
    // console.log(props.inventario)
      setInventario(props.inventario)
    } else {
      setInventario({
        id_area: null,
        id_articulo: null,
        marca: '',
        modelo: '',
        serie: '',
        operativo: false,
        calibracion: false,
        id_proveedor: null,
        codigo_patrimonial: '',
        meses_proximo_mant: '',
        meses_proximo_cali: '',
        descripcion: ''
      })
    }
  }, [props.inventario]);

  const handleCloseModal = () => {
    props.setOpenModal(false)
    setInventario({
      id_area: null,
      id_articulo: null,
      marca: '',
      modelo: '',
      serie: '',
      operativo: false,
      calibracion: false,
      id_proveedor: null,
      codigo_patrimonial: '',
      meses_proximo_mant: '',
      meses_proximo_cali: '',
      descripcion: ''
    })
  }

  const AddInventario = async () => {
    setLoadingButton(true)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/AddInventario`,{
        method: "POST",
        body: JSON.stringify({...inventario, usuario:props.usuario}),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
        .then(data =>{
        message.success(data.message)
        setLoadingButton(false)
        props.getInventarios()
        
        handleCloseModal()
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error)
    }
  }

  const EditInventario = async () => {
    // console.log(props.inventario)
    setLoadingButton(true)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/UpdateInventario`,{
        method: "PUT",
        body: JSON.stringify(inventario),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
        .then(data =>{
        message.success(data.message)
        setLoadingButton(false)
        props.getInventarios()
        
        handleCloseModal()
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error)
    }
  }

  
  const handleOpenModalArticulo = () => {
    resetForm()
    setOpenModalArticulo(true)

    setArticuloNuevo({
      id_articulo: null,
      nombre: null,
      id_rubro: null,
      ean: null,
      identificador: null
    })
  }

  return(
    <>
      <Modal
        visible={props.openModal}
        closable={false}
        width='90%'
        title={<h3 className='m-0'>{props.tituloModal}</h3>}
        onCancel={handleCloseModal}
        footer={null}
      >
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <Form form={props.form} layout='vertical' onFinish={props.tituloModal === 'Nuevo Inventario'?AddInventario: EditInventario}>
              <div className='elem-list'>
                <div className='col-sm-11 col-md-2 col-lg-2'>
                  <Item label="Cód. Patrimonial" name="codigo_patrimonial" rules={[
                    {...rules.required, message: 'El Código es Requerido'}
                  ]}>
                    <Input name='codigo_patrimonial' defaultValue = {inventario.codigo_patrimonial}
                      onChange={e=>setInventario({
                        ...inventario, codigo_patrimonial: e.currentTarget.value
                      })}
                    />
                  </Item>
                </div>

                <div className='col-sm-11 col-md-2 col-lg-2'>
                  <Item label="Descripción" name="descripcion">
                    <Input name='descripcion' defaultValue = {inventario.descripcion}
                      onChange={e=>setInventario({
                        ...inventario, descripcion: e.currentTarget.value
                      })}
                    />
                  </Item>
                </div>

                <div className='col-sm-11 col-md-3 col-lg-3'>
                  <Item label="Área" name="id_area" rules={[
                    {...rules.required, message: 'El Área es Requerida'}
                  ]}>
                    <Select
                      showSearch
                      defaultValue={inventario.id_area}
                      className='mb-lg-0'
                      placeholder='Seleccionar Área'
                      optionFilterProp='children'
                      filterOption={optionFilter}
                      onChange={e=>setInventario({
                        ...inventario, id_area: e
                      })}
                    >
                    {props.areasList.map(area =>{
                      return(
                        <Option value={area.id_area} key={area.id_area}>{area.nombre}</Option>
                      )
                    })}
                    </Select>
                  </Item>  
                </div>

                <div className='col-sm-10 col-md-3 col-lg-3'>
                  <Item label="Tipo de Equipo" name="id_articulo" rules={[
                    {...rules.required, message: 'El Equipo es Requerido'}
                  ]}>
                    <Select
                      showSearch
                      defaultValue={inventario.id_articulo}
                      className='mb-lg-0'
                      placeholder='Seleccionar'
                      optionFilterProp='children'
                      filterOption={optionFilter}
                      onChange={e=>setInventario({
                        ...inventario, id_articulo: e
                      })}
                    >
                      {props.articulosList.map(articulo => {
                        return(
                          <Option value={articulo.id_articulo} key={articulo.id_articulo}>{articulo.identificador} - {articulo.nombre}</Option>
                        )
                      })}
                    </Select>  
                  </Item>  
                </div>

                <div className='col-sm-1 col-md-1 col-lg-1'>
                  <br />
                  <Button type='primary' onClick={e => handleOpenModalArticulo()} 
                    title="Registrar Nuevo Artículo" icon={<PlusOutlined />} />
                </div>
              </div>

              <div className='elem-list'>
                <div className='col-sm-11 col-md-2 col-lg-2'>
                  <Item label="Marca" name="marca">
                    <Input name='marca' defaultValue = {inventario.marca}
                      onChange={e=>setInventario({
                        ...inventario, marca: e.currentTarget.value
                      })}
                    />
                  </Item> 
                </div>

                <div className='col-sm-11 col-md-3 col-lg-3'>
                  <Item label="Modelo" name="modelo">
                    <Input name='modelo' defaultValue = {inventario.modelo}
                      onChange={e=>setInventario({
                        ...inventario, modelo: e.currentTarget.value
                      })}
                    />
                  </Item> 
                </div>

                <div className='col-sm-11 col-md-2 col-lg-2'>
                  <Item label="Serie" name="serie">
                    <Input name='serie' defaultValue = {inventario.serie}
                      onChange={e=>setInventario({
                        ...inventario, serie: e.currentTarget.value
                      })}
                    />
                  </Item> 
                </div>

                <div className='col-sm-11 col-md-4 col-lg-4'>
                  <Item label="Proveedor" name="id_proveedor">
                    <Select
                      showSearch
                      defaultValue={inventario.id_proveedor}
                      className='mb-lg-0'
                      placeholder='Proveedor'
                      optionFilterProp='children'
                      filterOption={optionFilter}
                      onChange={e=>setInventario({
                        ...inventario, id_proveedor: e
                      })}
                    >
                      {props.proveedoresList.map(proveedor => {
                        return(
                          <Option value={proveedor.id_prove} key={proveedor.id_prove}>{proveedor.descripcion}</Option>
                        )
                      })}
                    </Select>  
                  </Item>
                </div>
              </div>

              <div className='elem-list mb-3'>
                <div className='col-sm-5 col-md-3 col-lg-3'>
                  <Item label="Meses Próx. Mant." name="meses_proximo_mant">
                    <Input name='meses_proximo_mant' type='number' defaultValue = {inventario.meses_proximo_mant}
                      onChange={e=>setInventario({
                        ...inventario, meses_proximo_mant: e.currentTarget.value
                      })}
                    />
                  </Item>   
                </div>

                <div className='col-sm-5 col-md-3 col-lg-3'>
                  <Item label="Meses Próx. Cal." name="meses_proximo_cali">
                    <Input name='meses_proximo_cali' type='number' defaultValue = {inventario.meses_proximo_cali}
                      onChange={e=>setInventario({
                        ...inventario, meses_proximo_cali: e.currentTarget.value
                      })}
                    />
                  </Item>   
                </div>

                <div className='col-sm-5 col-md-5 col-lg-1'>
                  <Item label="Operativo" name="operativo">
                    <Checkbox checked={inventario.operativo} 
                      onChange={e=>setInventario({
                        ...inventario, operativo: e.target.checked
                      })}/>
                  </Item>
                </div>

                <div className='col-sm-5 col-md-5 col-lg-1'>
                  <Item label="Calibración" name="calibracion">
                    <Checkbox checked={inventario.calibracion} 
                      onChange={e=>setInventario({
                        ...inventario, calibracion: e.target.checked
                      })}/>
                  </Item>
                </div>
              </div>

              <div className='modal-footer d-flex justify-content-between'>
                <Button danger onClick={handleCloseModal} icon={<CloseOutlined />}>
                  Cerrar
                </Button>
                
                <Button className='bg-color-success' loading={loadingButton}
                  type='primary' icon={<SaveOutlined />} htmlType="submit">
                  Guardar
                </Button>
              </div>
            </Form>
          </div>
        </div>

        {<ModalArticulo
          openModal = {openModalArticulo}
          tituloModal = {props.tituloModalArt}
          articulo = {articuloNuevo}
          setArticulo = {setArticuloNuevo}
          form = {form}
          getArticulos = {props.getArticulos}
          setOpenModal = {setOpenModalArticulo}
          rubrosList = {props.rubrosList}
          usuario = {props.usuario}
          getRubros = {props.getRubros}
        />}
      </Modal>
    </>
  )
}

export default ModalInventario