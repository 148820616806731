import { CloseOutlined, DeleteOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Checkbox,
  Select, message, Card, Table } from 'antd';
import { Rule } from 'antd/lib/form';
import { Option } from 'antd/lib/mentions';
import {useEffect, useState} from "react";

const { Item } = Form;

const rules = {
  required: { required: true, message: <></> } as Rule
};

const ModalProveedor = (props) => {
  const [pestaña, setPestaña] = useState('datos');
  const [loadingButton, setLoadingButton] = useState(false)
  const [proveedor, setProveedor] = useState({
    ruc: '',
    descripcion: '',
    direccion: '',
    usuario: props.usuario,
    idDepartamento: null,
    idProvincia: null,
    idDistrito: null,
    comentario: null,
    nombre_comercial: '',
    pago_efectivo: false
  });
  const [detalleRepresentantes, setDetalleRepresentantes] = useState([])
  const [detalleRubros, setDetalleRubros] = useState([])
  const [detalleBancos, setDetalleBancos] = useState([])
  
  const [form] = Form.useForm();
  
  const optionFilter = (input, option) => {
    return (option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0)
  }

  const onTabChange = (setter: (val: string) => void) => (key: string) => {
    setter(key);
  };
  
  const handleCloseModal = () => {
    props.setOpenModal(false)
    props.setIdProveedor(null)
    setDetalleRepresentantes([])
    setDetalleRubros([])
    setDetalleBancos([])

    setProveedor({
      ruc: '',
      descripcion: '',
      direccion: '',
      usuario: props.usuario,
      idDepartamento: null,
      idProvincia: null,
      idDistrito: null,
      comentario: null,
      nombre_comercial: '',
      pago_efectivo: false
    })

    form.setFieldsValue({
      ruc: '',
      descripcion: '',
      direccion: '',
      idDepartamento: null,
      idProvincia: null,
      idDistrito: null,
      rubros: undefined,
      comentario: null,
      nombre_comercial: '',
      pago_efectivo: false
    })
  }

  useEffect(() => {
    setPestaña('datos')
    if (props.idProveedor === null) {
      setProveedor({
        ruc: '',
        descripcion: '',
        direccion: '',
        usuario: props.usuario,
        idDepartamento: null,
        idProvincia: null,
        idDistrito: null,
        comentario: null,
        nombre_comercial: '',
        pago_efectivo: false
      })

      form.setFieldsValue({
        ruc: '',
        descripcion: '',
        direccion: '',
        idDepartamento: null,
        idProvincia: null,
        idDistrito: null,
        rubros: undefined,
        comentario: null,
        nombre_comercial: '',
        pago_efectivo: false
      })

      setDetalleRepresentantes([])
      setDetalleRubros([])
      setDetalleBancos([])
    } else {
      getProveedorById(props.idProveedor)
      getRepresentantesByIdProveedor(props.idProveedor)
      getRubrosProveedor(props.idProveedor)
      getCuentasProveedor(props.idProveedor)
    }
  }, [props.idProveedor]);

  const getRepresentantesByIdProveedor = async(id_prove) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getRepresentantesByIdProveedor/${id_prove}`)
      .then(res => res.json())
      .then(data => {
        if (data.message) {
          setDetalleRepresentantes([])
        } else {
          setDetalleRepresentantes(data)
        }
        
        // console.log(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getCuentasProveedor = async(id_prove) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getCuentasProveedor/${id_prove}`)
      .then(res => res.json())
      .then(data => {
        if (data.message) {
          setDetalleBancos([])
        } else {
          setDetalleBancos(data)
        }
        
        // console.log(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getRubrosProveedor = async(id_prove) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getRubrosProveedor/${id_prove}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          setDetalleRubros([])
          form.setFieldsValue({
            rubros: undefined
          })
        } else {
          let rubros = []
          for (let i = 0; i < data.length; i++) {
            // console.log(data[i].id_rubro)
            rubros = [...rubros, data[i].id_rubro]
          }
          setDetalleRubros(rubros)
          form.setFieldsValue({
            rubros: rubros
          })
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getProveedorById = async(id_prove) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getProveedorById/${id_prove}`)
      .then(res => res.json())
      .then(data => {
        setProveedor({...proveedor,
          ruc: data.ruc,
          descripcion: data.descripcion,
          direccion: data.direccion,
          idDepartamento: data.idDepartamento,
          idProvincia: data.idProvincia,
          idDistrito: data.idDistrito,
          comentario: data.comentario,
          nombre_comercial: data.nombre_comercial,
          pago_efectivo: data.pago_efectivo === null?false: data.pago_efectivo
        })

        const listaProv = props.provinciasList.filter(provincia => {
          return(provincia.idDepartamento === data.idDepartamento)
        })
    
        props.setProvinciasSubList(listaProv)

        const listaDistr = props.distritosList.filter(distrito => {
          return(distrito.idProvincia === data.idProvincia && distrito.idDepartamento === data.idDepartamento)
        })
    
        props.setDistritosSubList(listaDistr)

        form.setFieldsValue({
          ruc: data.ruc,
          descripcion: data.descripcion,
          direccion: data.direccion,
          idDepartamento: data.idDepartamento,
          idProvincia: data.idProvincia,
          idDistrito: data.idDistrito,
          comentario: data.comentario,
          nombre_comercial: data.nombre_comercial,
          pago_efectivo: data.pago_efectivo
        })
      })
    } catch (error) {
      console.log(error)
    }
  }

  const AddProveedor = async() => {
    if (detalleRepresentantes.length === 0) {
      message.error('Debe Ingresar al menos un Representante')
    } else {
      const body = {
        proveedor: {...proveedor},
        detalleRepresentantes: detalleRepresentantes,
        detalleRubros: detalleRubros,
        detalleBancos: proveedor.pago_efectivo === false? detalleBancos: []
      }

      const validarDetalle = detalleRepresentantes.filter(detalle => {
        return detalle.nombre === null || validarCorreo(detalle.email) === 'correo no válido'
      })

      if (validarDetalle.length > 0) {
        message.error('Faltan campos en el detalle (nombres/email)')
      } else {
        const validarCorreoDefault = detalleRepresentantes.filter(detalle => {
          return detalle.defecto === true
        })

        if (validarCorreoDefault.length === 0) {
          message.error('Escoger un correo por Defecto')
        } else {
          const validarbancos = detalleBancos.filter(detalle => {
            return detalle.cod_Banco === null || detalle.moneda === null || detalle.numero_cuenta === null
          })
          if (validarbancos.length > 0 && proveedor.pago_efectivo === false) {
            message.error('Faltan campos en las cuentas del Proveedor')
          } else {
            setLoadingButton(true)
            try {
              await fetch(`${process.env.REACT_APP_HOSTNAME}/AddProveedor`, {
                method: "POST",
                body: JSON.stringify(body),
                headers:{
                  'Content-Type': 'application/json'
                }
              }).then(res => res.json())
              .then(data =>{
                // console.log(data)
                message.success(data.mensaje)
                setLoadingButton(false)
                props.getProveedores()
                handleCloseModal()
              }).catch(err => {
                console.log(err)
                setLoadingButton(false)
              });

            } catch (error) {
              console.log(error)
              setLoadingButton(false)
            }     
          }  
        }
      }
    }
  }

  const EditProveedor = async() => {
    
    if (detalleRepresentantes.length === 0) {
      message.error('Debe Ingresar al menos un Representante')
    } else {
      const body = {
        proveedor: {...proveedor, id_prove: props.idProveedor},
        detalleRepresentantes: detalleRepresentantes,
        detalleRubros: detalleRubros,
        detalleBancos: detalleBancos
      }

      const validarDetalle = detalleRepresentantes.filter(detalle => {
        return detalle.nombre === null || validarCorreo(detalle.email) === 'correo no válido'
      })

      if (validarDetalle.length > 0) {
        message.error('Faltan campos en el detalle (nombres/email)')
      } else {
        const validarCorreoDefault = detalleRepresentantes.filter(detalle => {
          return detalle.defecto === true
        })
        if (validarCorreoDefault.length === 0) {
          message.error('Escoger un correo por Defecto')
        } else {
          const validarbancos = detalleBancos.filter(detalle => {
            return detalle.cod_Banco === null || detalle.moneda === null || detalle.numero_cuenta === null
          })
          
          if (validarbancos.length > 0 && proveedor.pago_efectivo === false) {
            message.error('Faltan campos en las cuentas del Proveedor')
          } else {
            setLoadingButton(true)
            try {
              await fetch(`${process.env.REACT_APP_HOSTNAME}/UpdateProveedor`,{
                method: "PUT",
                body: JSON.stringify(body),
                headers: {
                  'Content-Type': 'application/json'
                }
              }).then(res => res.json())
              .then(data =>{
                message.success(data.mensaje)
                setLoadingButton(false)
                props.getProveedores()
                handleCloseModal()
              }).catch(err => {
                console.log(err)
                setLoadingButton(false)
              });
            } catch (error) {
              console.log(error)
              setLoadingButton(false)
            }    
          }  
        }
      }
    }
  }

  const changeDepartamento = (value) => {
    setProveedor({...proveedor, 
      idDepartamento: value,
      idProvincia: null,
      idDistrito: null
    })

    form.setFieldsValue({idProvincia: null, idDistrito: null})

    const lista = props.provinciasList.filter(provincia => {
      return(provincia.idDepartamento === value)
    })

    props.setProvinciasSubList(lista)
  }

  const changeProvincia = (value) => {
    setProveedor({...proveedor, 
      idProvincia: value,
      idDistrito: null
    })

    form.setFieldsValue({idDistrito: null})

    const lista = props.distritosList.filter(distrito => {
      return(distrito.idProvincia === value && distrito.idDepartamento === proveedor.idDepartamento)
    })

    props.setDistritosSubList(lista)
  }

  const AddDetalleRep = () => {
    setDetalleRepresentantes([...detalleRepresentantes,{
      nombre: null,
      telefono: '',
      celular: '',
      email: null,
      defecto: false
    }])
  }

  const RemoveDetalleRep = (item) => {
    const listaItems = detalleRepresentantes.filter(detalle => {
      return(detalle !== item)
    })
    setDetalleRepresentantes(listaItems)
  }

  const detalleRepChange = (value, campo, item) => {
    const listaItems = detalleRepresentantes
    const key = listaItems.indexOf(item)

    if (campo === 'defecto' && value === true) {
      for (let i = 0; i < listaItems.length; i++) {
        if (i !== key) {
          listaItems[i].defecto = false
        }
      }
    } 
      
    listaItems[key][campo] = value 
    setDetalleRepresentantes([...listaItems])
    
  }

  const validarCorreo = (email) => {
    const validate = /\S+@\S+\.\S+/.test(email)
    // console.log(validate)

    if (validate === true) {
      return ''
    } else {
      return 'correo no válido'
    } 
  }

  const AddDetalleBanco = () => {
    setDetalleBancos([...detalleBancos,{
      cod_Banco: null,
      moneda: null,
      numero_cuenta: null,
      cci: null
    }])
  }

  const RemoveDetalleBanco = (item) => {
    const listaItems = detalleBancos.filter(detalle => {
      return(detalle !== item)
    })

    setDetalleBancos(listaItems)
  }

  const detalleBancoChange = (value, campo, item) => {
    const listaItems = detalleBancos
    const key = listaItems.indexOf(item)

    listaItems[key][campo] = value
    setDetalleBancos([...listaItems])
  }

  const columns = [
    {
      title: (
        <>
          <Button type='primary' onClick={e => AddDetalleRep()} title="Agregar Item" icon={<PlusOutlined />}/>
        </>
      ),
      render: record => {
        return(
          <>
            <Button danger title="Quitar" onClick={e=> RemoveDetalleRep(record)} icon={<DeleteOutlined />}/>  
          </>
        )
      }
    },
    {
      title: 'APELLIDOS Y NOMBRES',
      key: 'nombre',
      render: record => {
        return(
          <>
            <Input name='nombre' value={record.nombre}
              onChange={e=>detalleRepChange(e.currentTarget.value, "nombre", record)}
            />
          </>
        )
      }
    },
    {
      title: 'TELEFONO',
      key: 'telefono',
      render: record => {
        return(
          <>
            <Input name='telefono' value={record.telefono} 
              onChange={e=>detalleRepChange(e.currentTarget.value.replace(/\D/g, ''), "telefono", record)}
            /> 
          </>
        )
      }
    },
    {
      title: 'CELULAR',
      key: 'celular',
      render: record => {
        return(
          <>
            <Input name='celular' value={record.celular}
              onChange={e=>detalleRepChange(e.currentTarget.value.replace(/\D/g, ''), "celular", record)}
            />
          </>
        )
      }
    },
    {
      title: 'EMAIL',
      key: 'email',
      render: record => {
        return(
          <>
            <Input name='email' value={record.email}
              onChange={e=>detalleRepChange(e.currentTarget.value, "email", record)}
            />
            <b style={{color:"red"}}>{validarCorreo(record.email)}</b>
          </>
        )
      }
    },
    {
      title: 'EMAIL PREDET.',
      key: 'defecto',
      render: record => {
        return(
          <>
            <Checkbox checked={record.defecto} 
              onChange={e => detalleRepChange(e.target.checked, "defecto", record)}/>
          </>
        )
      }
    }
  ]

  const columnsBancos = [
    {
      title: (
        <>
          <Button type='primary' onClick={e => AddDetalleBanco()} title="Agregar Item" icon={<PlusOutlined />}/>
        </>
      ),
      render: record => {
        return(
          <>
            <Button danger title="Quitar" onClick={e=> RemoveDetalleBanco(record)} icon={<DeleteOutlined />}/>  
          </>
        )
      }
    },
    {
      title: (<><div style={{width:150}}>BANCO</div></>),
      key: 'cod_Banco',
      render: record => {
        return(<Select
          showSearch
          value={record.cod_Banco}
          className='mb-lg-0'
          placeholder='Banco'
          optionFilterProp='children'
          filterOption={optionFilter}
          onChange={e => detalleBancoChange(e, "cod_Banco", record)}
        >
          {props.bancosList.map(banco => {
            return(
              <Option value={banco.cod_Banco} 
                key={banco.cod_Banco}>
                {banco.Descripcion}
              </Option>
            )
          })}
        </Select>)
      }
    },
    {
      title: 'MONEDA',
      key: 'moneda',
      render: record => {
        return(<Select
          showSearch
          value={record.moneda}
          className='mb-lg-0'
          placeholder='Banco'
          optionFilterProp='children'
          filterOption={optionFilter}
          onChange={e => detalleBancoChange(e, "moneda", record)}
        >
          <Option value={'SOLES'} key={'SOLES'}>SOLES</Option>
          <Option value={'DOLARES'} key={'DOLARES'}>DÓLARES</Option>
        </Select>)
      }
    },
    {
      title: 'NÚM. CUENTA',
      key: 'numero_cuenta',
      render: record => {
        return(
          <>
            <Input name='numero_cuenta' value={record.numero_cuenta}
              onChange={e=>detalleBancoChange(e.currentTarget.value.replace(/\D/g, ''), "numero_cuenta", record)}
            />
          </>
        )
      }
    },
    {
      title: 'CCI',
      key: 'cci',
      render: record => {
        return(
          <>
            <Input name='cci' value={record.cci}
              onChange={e=>detalleBancoChange(e.currentTarget.value.replace(/\D/g, ''), "cci", record)}
            />
          </>
        )
      }
    }
  ]

  let pestañasList = [
    { key: 'datos', tab: 'Info. Proveedor' }
  ]

  if (proveedor.pago_efectivo === false) {
    pestañasList = [ ...pestañasList,
      { key: 'cuentas', tab: 'Cuentas Proveedor' }
    ]
  }

  const contenidoPestañas = {
    datos:
      <>
        
          <div className='elem-list'>
            <div className='col-sm-11 col-md-5 col-lg-5'>
              <Item label="Razón Social" name="descripcion" rules={[
                  {...rules.required, message: 'Razón Social Requerido'}
              ]}>
                <Input defaultValue={proveedor.descripcion}
                  onChange={e=> setProveedor({...proveedor, descripcion: e.currentTarget.value})}
                />
              </Item>
            </div>

            <div className='col-sm-5 col-md-2 col-lg-2'>
              <Item label="RUC" name="ruc" rules={[
                  {...rules.required, message: 'RUC Requerido'},
                  { min: 11, max: 11, message: 'El RUC debe tener 11 dígitos' }
              ]}>
                <Input defaultValue={proveedor.ruc} 
                  onChange={e=> {
                    setProveedor({...proveedor, ruc: e.currentTarget.value.replace(/\D/g, '')})
                    form.setFieldsValue({ruc: e.currentTarget.value.replace(/\D/g, '')})
                  }}
                />
              </Item>
            </div>

            <div className='col-sm-11 col-md-4 col-lg-4'>
              <Item label="Nombre Comercial" name="nombre_comercial" rules={[
                  {...rules.required, message: 'Nombre Comercial Requerido'}
              ]}>
                <Input defaultValue={proveedor.nombre_comercial}
                  onChange={e=> setProveedor({...proveedor, nombre_comercial: e.currentTarget.value})}
                />
              </Item>
            </div>
          </div>

          <div className='elem-list'>
            <div className='col-sm-11 col-md-7 col-lg-7'>
              <Item label="Rubros" name="rubros" rules={[
                  {...rules.required, message: 'Rubros Requeridos'}
              ]}>
                <Select
                  showSearch
                  defaultValue={detalleRubros}
                  className='mb-lg-0'
                  placeholder='Seleccionar Rubros'
                  optionFilterProp='children'
                  mode='multiple'
                  filterOption={optionFilter}
                  onChange={e=>setDetalleRubros(e)}
                >
                {props.rubrosList.map(rubro =>{
                  return(
                    <Option value={rubro.id_rubro} key={rubro.id_rubro}>{rubro.nombre}</Option>
                  )
                })}
                </Select>
              </Item>  
            </div> 
            
            <div className='col-sm-11 col-md-4 col-lg-4'>
              <br/> <br/>
              <Checkbox checked={proveedor.pago_efectivo} 
                onChange={e => setProveedor({...proveedor, pago_efectivo: e.target.checked})}>Pago en Efectivo</Checkbox>
            </div>
          </div>

          <div className='elem-list'>
            <div className='col-sm-8 col-md-2 col-lg-2'>
              <Item label="Departamento" name="idDepartamento" rules={[
                  {...rules.required, message: 'Departamento Requerido'}
              ]}>
                <Select
                  showSearch
                  defaultValue={proveedor.idDepartamento}
                  className='mb-lg-0'
                  placeholder='--Departamento--'
                  optionFilterProp='children'
                  filterOption={optionFilter}
                  onChange={e=>changeDepartamento(e)}
                >
                {props.departamentosList.map(departamento =>{
                  return(
                    <Option value={departamento.idDepartamento} key={departamento.idDepartamento}>{departamento.nombreDepartamento}</Option>
                  )
                })}
                </Select>
              </Item>  
            </div>

            <div className='col-sm-8 col-md-2 col-lg-2'>
              <Item label="Provincia" name="idProvincia" rules={[
                  {...rules.required, message: 'Provincia Requerida'}
              ]}>
                <Select
                  showSearch
                  disabled = {proveedor.idDepartamento === null?true:false}
                  defaultValue={proveedor.idProvincia}
                  className='mb-lg-0'
                  placeholder='--Provincia--'
                  optionFilterProp='children'
                  filterOption={optionFilter}
                  onChange={e=>changeProvincia(e)}
                >
                {props.provinciasSubList.map(provincia =>{
                  return(
                    <Option value={provincia.idProvincia} key={provincia.idProvincia}>{provincia.nombreProvincia}</Option>
                  )
                })}
                </Select>
              </Item>  
            </div>

            <div className='col-sm-8 col-md-2 col-lg-2'>
              <Item label="Distrito" name="idDistrito" rules={[
                  {...rules.required, message: 'Distrito Requerido'}
              ]}>
                <Select
                  showSearch
                  disabled = {proveedor.idProvincia === null?true:false}
                  defaultValue={proveedor.idDistrito}
                  className='mb-lg-0'
                  placeholder='--Distrito--'
                  optionFilterProp='children'
                  filterOption={optionFilter}
                  onChange={e=>setProveedor({...proveedor, idDistrito: e})}
                >
                {props.distritosSubList.map(distrito =>{
                  return(
                    <Option value={distrito.idDistrito} key={distrito.idDistrito}>{distrito.nombreDistrito}</Option>
                  )
                })}
                </Select>
              </Item>  
            </div>

            <div className='col-sm-11 col-md-5 col-lg-5'>
              <Item label="Dirección" name="direccion" rules={[
                {...rules.required, message: 'Dirección Requerida'}
              ]}>
                <Input defaultValue={proveedor.direccion}
                  onChange={e=> setProveedor({...proveedor, direccion: e.currentTarget.value})}
                />
              </Item>
            </div>
          </div>

          <div className='col-sm-12 col-md-12 col-lg-12'>
            <Card title='Representantes'> 
              <Table 
                pagination={false} 
                columns={columns}
                dataSource={detalleRepresentantes}
                size="small"
              />
            </Card>                    
          </div>

          <div className='elem-list'>
            <div className='col-sm-11 col-md-11 col-lg-11'>
              <Item label="Comentario" name="comentario">
                <Input.TextArea defaultValue={proveedor.comentario} 
                  onChange={e=>setProveedor({
                    ...proveedor, comentario:e.currentTarget.value
                  })}
                  placeholder = "Ingrese algún comentario adicional..."
                />
              </Item>
            </div>
          </div>
          
      </>,

      cuentas:
        <>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <Card title='Cuentas de Proveedores'> 
              <Table 
                pagination={false} 
                columns={columnsBancos}
                dataSource={detalleBancos}
                size="small"
              />
            </Card>                    
          </div>
        </>
  }

  return(
    <>
      <Modal
        visible={props.openModal}
        closable={false}
        width='90%'
        title={<h3 className='m-0'>{props.tituloModal}</h3>}
        onCancel={handleCloseModal}
        footer={null}
      >
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <Form form={form} layout='vertical' onFinish={ props.idProveedor === null? AddProveedor : EditProveedor}>
              <Card
                tabList={pestañasList}
                activeTabKey={pestaña}
                onTabChange={onTabChange(setPestaña)}
              >
              {contenidoPestañas[pestaña]}
              </Card>

              <div className='modal-footer d-flex justify-content-between'>
                <Button danger onClick={handleCloseModal} icon={<CloseOutlined />}>
                  Cerrar
                </Button>
                
                <Button className='bg-color-success' loading={loadingButton}
                  type='primary' icon={<SaveOutlined />} htmlType="submit">
                  Guardar
                </Button>
              </div>
            </Form>
            
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModalProveedor