
import { useState, useEffect } from "react";

import './Actions.scss';

import Notifications from './Notifications';
import SettingsDropdown from './SettingsDropdown';
import Cookies from "universal-cookie";
import { existeRol } from "../../../pages/components/FuncionesGenerales";
import NotificationsTesoreria from "./NotificationsTesoreria";

const cookies = new Cookies()

const Actions = () => {
  const [unique_name_logis, setUnique_name_logis] = useState('')
  const [rolLogistica, setRolLogistica] = useState(false);
  const [rolTesoreria, setRolTesoreria] = useState(false);

  useEffect(() => {
    setUnique_name_logis(cookies.get('unique_name_logis'))
    setRolLogistica(existeRol('Logistica.Gestion.Requerimiento'))
    setRolTesoreria(existeRol('Tesoreria.Gestion.Pagos'))
  }, [])

  return(
    <div className='actions'>
      {rolLogistica === true ?<Notifications />:null}
      {rolTesoreria === true ?<NotificationsTesoreria />:null}
      <b>{unique_name_logis}</b>
      <SettingsDropdown />
    </div>  
  )
  
};

export default Actions;
