import { CloseOutlined, DeleteOutlined, FilePdfOutlined, PlusOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Table, Card, Select, Form, Input, message, Checkbox } from 'antd';
import moment from "moment";
import { Option } from 'antd/lib/mentions';
import { Rule } from 'antd/lib/form';
import { useRef, useState, useEffect } from 'react';
import { getTipoCambioToday } from '../FuncionesGenerales';
import ModalTipoCambio from '../ModalTipoCambio';
import ModalProveedor from '../componentesProveedores/ModalProveedor';
import ModalArticulo from '../componentesArticulos/ModalArticulo';
import ModalUnidadForm from '../componentesUnidades/ModalUnidadForm';

const { Item } = Form;

const rules = {
  required: { required: true, message: <></> } as Rule
};

const FormularioCotizacion = (props) => {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const resetForm = () => form.resetFields();
  const resetForm2 = () => form2.resetFields();
  const input = useRef<HTMLInputElement>(null);
  const [openModal, setOpenModal] = useState(false);
  const [openModalProveedor, setOpenModalProveedor] = useState(false);
  const [inputBuscarRUC, setInputBuscarRUC] = useState('');
  const [inputBuscarNombre, setInputBuscarNombre] = useState('');
  const [tituloModal, setTituloModal] = useState('')
  const [idProveedor, setIdProveedor] = useState(null)
  const [bancosList, setBancosList] = useState([])
  const [departamentosList, setDepartamentosList] = useState([])
  const [provinciasList, setProvinciasList] = useState([])
  const [provinciasSubList, setProvinciasSubList] = useState([])
  const [distritosList, setDistritosList] = useState([])
  const [distritosSubList, setDistritosSubList] = useState([])
  const [tituloModalArticulo, setTituloModalArticulo] = useState('')
  const [openModalArticulo, setOpenModalArticulo] = useState(false);
  const [articuloNuevo, setArticuloNuevo] = useState({})
  const [openModalUnidad, setOpenModalUnidad] = useState(false);
  const [checkIGV, setCheckIGV] = useState(true);

  useEffect(() => {
    if (props.cotizacion.id_cotizacion !== null) {
      form.setFieldsValue({
        fecha: props.cotizacion.fecha_cotizacion,
        parv_descripcion: props.cotizacion.parv_descripcion,
        id_proveedor: props.cotizacion.id_proveedor
      })
      setCheckIGV(true)
    }
  }, [props.cotizacion.id_cotizacion])

  useEffect(() => {
    getBancos()
    getDepartamentos()
    getProvincias()
    getDistritos()
  }, []);

  const getBancos = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getBancos`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setBancosList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getDepartamentos = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getDepartamentos`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setDepartamentosList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getProvincias = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getProvincias`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setProvinciasList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getDistritos = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getDistritos`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setDistritosList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const AddDetalleCot = () => {
    props.setDetalleCotList([...props.detalleCotList,{
      id_cotizacion: null,
      id_unidad: null,
      cantidad: 1,
      precio_unitario: 1,
      id_articulo: null,
      descripcion: '',
      marca: null,
      cantidad_unidades: 1
    }])
  }

  const detalleCotChange = (option, campo, item) => {
    // console.log(item)
    const listaItems = props.detalleCotList
    const key = listaItems.indexOf(item)

    if (campo === "precio_unitario" || campo === "descripcion" || campo === "marca") {
      listaItems[key][campo] = option 
    } else {
      listaItems[key][campo] = option.value
    }
    // console.log(listaItems[key])
    props.setDetalleCotList([...listaItems])
  }

  const detalleCotChangeUnidadCantidad = (option, campo, item) => {
    const listaItems = props.detalleCotList
    const key = listaItems.indexOf(item)

    if (campo === 'cantidad') {
      listaItems[key][campo] = option
    } else {
      listaItems[key][campo] = option.value
      const unidad = props.unidadesList.filter(uni => {
        return uni.id_unidad === option.value
      })
      listaItems[key].cantidad_unidades = unidad[0].cantidad_unidades
    }
    // console.log(listaItems[key])
    props.setDetalleCotList([...listaItems])

  }

  const RemoveDetalleCot = (item) => {
    const listaItems = props.detalleCotList.filter(detalle => {
      return(detalle !== item)
    })
    // console.log(listaItems)
    props.setDetalleCotList(listaItems)
  }

  const articulosFiltrados = () => {
    const lista = props.materialesList.filter(articulo => {
      return (props.requerimiento.id_rubro === null?
        articulo.id_rubro !== props.requerimiento.id_rubro: articulo.id_rubro === props.requerimiento.id_rubro) 
    })
    // console.log(lista)
    return lista
  }

  const proveedoresFiltrados = () => {
    const listaConRubros = props.proveedoresList.filter(proveedor => {
      return (proveedor.rubros !== null)
    })
    // console.log(listaConRubros)

    let listaRubrosFiltrada = []

    for (let i = 0; i < listaConRubros.length; i++) {
      const rubros = JSON.parse(listaConRubros[i].rubros);
      const findRubro = rubros.filter(rubro => {
        return(rubro.id_rubro === props.requerimiento.id_rubro)
      })
      // console.log(findRubro,i)
      if (findRubro.length > 0) {
        listaRubrosFiltrada = [...listaRubrosFiltrada, listaConRubros[i]]
      }
      
    }
    // console.log(listaRubrosFiltrada)
    return listaRubrosFiltrada
  }

  const columnsServ = [
    {
      title: 'DESCRIPCIÓN',
      key: 'descripcion',
      render: record => {
        return(
          <>
            <Input.TextArea allowClear name='descripcion' value={record.descripcion}
              onChange={e=>detalleCotChange(e.currentTarget.value, "descripcion", record)}
              rows={3}
            />
          </>
        )
      }
    },
    {
      title: 'PRECIO',
      key: 'precio_unitario',
      render: record => {
        return(
          <>
            <Input type="number" name='precio_unitario' value={record.precio_unitario}
              onChange={e=> detalleCotChange(e.currentTarget.value, "precio_unitario", record)}
            />
          </>
        )
      }
    }
  ]

  const handleOpenModalArticulo = (titulo, id_articulo) => {
    resetForm2()
    setOpenModalArticulo(true)
    setTituloModalArticulo(titulo)

    setArticuloNuevo({
      id_articulo: null,
      nombre: null,
      id_rubro: null,
      ean: null,
      identificador: null
    })
  }

  const handleOpenModalUnidad = () => {
    setOpenModalUnidad(true)
  }

  const checkIGVChange = (value) => {
    setCheckIGV(value)
    if (value === false) {
      message.warn('SI INGRESA EL V. UNIT. EN LUGAR DEL PRECIO, SE SUMARÁ EL IGV AL PRECIO DE LOS ÍTEMS AL GUARDAR EL REGISTRO', 10)
    }
  }

  const columns = [
    {
      title: (
        <>
          <Button type='primary' disabled = {props.requerimiento.aprobado === 'NO' || 
            props.requerimiento.estado === 'RECHAZADO' || props.requerimiento.estado === 'FINALIZADO'? true: false}
            onClick={e => AddDetalleCot()} icon={<PlusOutlined />} title="Agregar Item"/>
        </>
      ),
      render: (_, record) => {
        return(
          <>
            <Button danger
              title="Quitar" 
              onClick={e=> RemoveDetalleCot(record)}
              icon={<DeleteOutlined />} />  
          </>
        )
      }
    },
    {
      title: (
        <div style={{width:350}}>
          MATERIAL/EQUIPO
          <Button type='primary' onClick={e => handleOpenModalArticulo('Nuevo Artículo', null)} 
            title="Registrar Nuevo Artículo" icon={<PlusOutlined />} className='ml-2 mr-4'/>
        </div>
      ),
      key: 'id_articulo',
      render: record => {
        return(
          <>
            <Select
              showSearch
              value={record.id_articulo}
              className='mb-lg-0'
              style={{width:350}}
              placeholder='Material/Equipo'
              optionFilterProp='children'
              filterOption={props.optionFilter}
              onChange={(e, option) => detalleCotChange(option, "id_articulo", record)}
            >
              {articulosFiltrados().map(material => {
                return(
                  <Option value={material.id_articulo} 
                    key={material.id_articulo}>
                    {material.nombre}
                  </Option>
                )
              })}
            </Select>
          </>
        )
      }
    },
    {
      title: 'DESCRIPCIÓN',
      key: 'descripcion',
      render: record => {
        return(
          <>
            <Input name='descripcion' value={record.descripcion}
              onChange={e=>detalleCotChange(e.currentTarget.value, "descripcion", record)}
            />
          </>
        )
      }
    },
    // {
    //   title: 'MARCA',
    //   key: 'marca',
    //   render: record => {
    //     return(
    //       <>
    //         <Input name='marca' value={record.marca}
    //           onChange={e=>detalleCotChange(e.currentTarget.value, "marca", record)}
    //         />
    //       </>
    //     )
    //   }
    // },
    {
      title: (
        <div style={{width:150}}>
          PRESENTACIÓN
          <Button type='primary' onClick={e => handleOpenModalUnidad()} title="Agregar Presentación"
            icon={<PlusOutlined />} className='ml-2 mr-4'/>
        </div>
      ),
      key: 'id_unidad',
      render: record => {
        return(
        <>
          <Select
            showSearch
            value={record.id_unidad}
            className='mb-lg-0'
            style={{width:140}}
            placeholder='Unidad de Medida'
            optionFilterProp='children'
            filterOption={props.optionFilter}
            onChange={(e, option) => detalleCotChangeUnidadCantidad(option,"id_unidad", record)}
          >
            {props.unidadesList.map(unidad => {
              return(
                <Option value={unidad.id_unidad} 
                  key={unidad.id_unidad}>
                  {unidad.nombre} X {unidad.cantidad_unidades}
                </Option>
              )
            })}
          </Select> 
        </>
        )
      }
    },
    {
      title: (<><div style={{width:100}}>CANTIDAD</div></>),
      key: 'cantidad',
      render: record => {
        return(
          <>
            <Input type="number" name='cantidad' value={record.cantidad}
              onChange={e=> detalleCotChangeUnidadCantidad(e.currentTarget.value, "cantidad", record)}
            />
          </>
        )
      }
    },
    {
      title: 'TOTAL UNID.',
      render: record => {
        return(
          <>
            <b>{(record.cantidad_unidades * record.cantidad).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</b>
          </>
        )
      }
    },
    {
      title: (<>
        <b>PRECIO/</b><br/>
        <b><Checkbox checked={checkIGV} onChange={e => checkIGVChange(e.target.checked)}>incl. IGV</Checkbox> </b>
      </>),
      key: 'precio_unitario',
      render: record => {
        return(
          <>
            <Input type="number" name='precio_unitario' value={record.precio_unitario}
              onChange={e=> detalleCotChange(e.currentTarget.value, "precio_unitario", record)}
            />
          </>
        )
      }
    },
    {
      title: 'TOTAL',
      key: 'precio_unitario',
      render: record => {
        return(
          <>
            <b>{(record.cantidad * record.precio_unitario).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</b>
          </>
        )
      }
    }
  ]

  const AddCotizacion = async() => {
    // console.log('add', props.cotizacion)
    if (props.cotizacion.parv_descripcion === 'DOLARES' && props.cotizacion.TipCambio === 1) {
      message.error('Falta Ingresar el Tipo de Cambio')
    } else {
      
      if (props.cotizacion.ubicacion_archivo === null) {
        message.error('Debe Subir el PDF de la Cotización')
      } else {

        if (props.cotizacion.ubicacion_archivo[0] === undefined) {
          message.error('Debe Subir el PDF de la Cotización')
        } else {
          
          if (props.detalleCotList.length === 0) {
            message.error('Debe Ingresar los Materiales Cotizados')
          } else {

            const body = {
              cotizacion: {...props.cotizacion, usuario: props.usuario, checkIGV: checkIGV, 
                total: checkIGV === true? props.detalleCotList.map(det => det.cantidad * det.precio_unitario).reduce((prev, next) => prev + next):
                  props.detalleCotList.map(det => det.cantidad * det.precio_unitario).reduce((prev, next) => prev + next) * 1.18
              },
              detalleCotList: props.detalleCotList
            }

            const validarDetalle = props.detalleCotList.filter(detalle => {
              return detalle.id_unidad === null || detalle.id_articulo === null
            })

            if (validarDetalle.length > 0) {
              message.error('Faltan campos en el detalle de Equipos')
            } else {
              try {
                await fetch(`${process.env.REACT_APP_HOSTNAME}/AddCotizacion`,{
                  method: "POST",
                  body: JSON.stringify(body),
                  headers:{
                    'Content-Type': 'application/json'
                  }
                }).then(res => res.json())
                  .then(data =>{
                  UploadFileCotizacion(data.id_cotizacion)
                }).catch(err => console.log(err));
                
              } catch (error) {
                console.log(error)
              }
            }
          }  
        }
      }  
    }
  }

  const UploadFileCotizacion = async(id_nuevaCotizacion) => {
    props.setLoadingButtonAprobar(true)
    const body = {
      destination:'Logistica',
      filename:`Cotizacion_${id_nuevaCotizacion}.pdf`,
      id_cotizacion: id_nuevaCotizacion
    }
    const bodyForm = new FormData()

    /**AREGAR EL ARCHIVO AL FINAL */
    bodyForm.append('tabla', JSON.stringify(body))
    bodyForm.append('File', props.cotizacion.ubicacion_archivo[0])
    // console.log(Object.fromEntries(bodyForm))
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/UploadFileCotizacion`,{
        method: "POST",
        body: bodyForm,
        //CON MULTER EN NODE NO HACE FALTA ENVIARLE EL HEADER (SOLO ARCHIVO)
        /*headers:{
          'Content-Type': 'multipart/form-data'
        }*/
      }).then(res => {
        message.success("Cotización Registrada")
        props.setLoadingButtonAprobar(false)
        props.setCotizacion({
          id_cotizacion: null,
          id_requerimiento: props.cotizacion.id_requerimiento,
          total: 0,
          id_proveedor: null,
          usuario: props.usuario,
          fecha_cotizacion: moment.utc(new Date()).format('YYYY-MM-DD'),
          ubicacion_archivo: null,
          parv_descripcion: 'SOLES',
          TipCambio: 1
        })
        // props.setDetalleCotList([])
        let lista = []
        for (let i = 0; i < props.detalleReqList.length; i++) {
          lista = [...lista,{
            id_cotizacion: null,
            id_unidad: props.detalleReqList[i].id_unidad,
            cantidad: props.detalleReqList[i].cantidad,
            precio_unitario: 1,
            id_articulo: props.detalleReqList[i].id_articulo,
            descripcion: props.detalleReqList[i].descripcion,
            marca: null,
            cantidad_unidades: props.detalleReqList[i].cantidad_unidades
          }]
        }
        props.setDetalleCotList(lista)
        resetForm()
        props.getCotizacionesByRequerimiento(props.cotizacion.id_requerimiento)
        props.setNombreCotizacionForm('Nueva Cotización')
        props.getDetalleCotizacionByReq(props.cotizacion.id_requerimiento)
        props.setPestaña('listaCotizaciones')
        setCheckIGV(true)
      }).catch(err => {
        console.log(err)
        message.error('No se Pudo Subir el Archivo al Servidor')
        props.setLoadingButtonAprobar(false)
      });
    } catch (error) {
      console.log(error)
      props.setLoadingButtonAprobar(false)
      message.error('No se Pudo Subir el Archivo al Servidor')
    }
  }

  const Total = () => {
    if (checkIGV === true) {
      return(
        <>
          <div className='d-flex justify-content-between'>
            <b>Base Imponible</b>
            <b>
              {props.cotizacion.parv_descripcion === 'SOLES'?'S/.':'$'} { props.detalleCotList.length === 0? 0:
                ((props.detalleCotList.map(det => det.cantidad * det.precio_unitario).reduce((prev, next) => prev + next)) / 1.18)
                .toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
              }
            </b>
          </div>

          <div className='d-flex justify-content-between'>
            <b>IGV</b>
            <b>
              {props.cotizacion.parv_descripcion === 'SOLES'?'S/.':'$'} { props.detalleCotList.length === 0? 0:
                ((props.detalleCotList.map(det => det.cantidad * det.precio_unitario).reduce((prev, next) => prev + next)) / 1.18 * 0.18)
                .toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
              }
            </b>
          </div>

          <div className='d-flex justify-content-between'>
            <b>Total</b>
            <b>
              {props.cotizacion.parv_descripcion === 'SOLES'?'S/.':'$'} { props.detalleCotList.length === 0? 0:
                props.detalleCotList.map(det => det.cantidad * det.precio_unitario).reduce((prev, next) => prev + next)
                .toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
              }
            </b>
          </div>
        </>
      )  
    } else {
      return(
        <>
          <div className='d-flex justify-content-between'>
            <b>Base Imponible</b>
            <b>
              {props.cotizacion.parv_descripcion === 'SOLES'?'S/.':'$'} { props.detalleCotList.length === 0? 0:
                ((props.detalleCotList.map(det => det.cantidad * det.precio_unitario).reduce((prev, next) => prev + next)))
                .toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
              }
            </b>
          </div>

          <div className='d-flex justify-content-between'>
            <b>IGV</b>
            <b>
              {props.cotizacion.parv_descripcion === 'SOLES'?'S/.':'$'} { props.detalleCotList.length === 0? 0:
                ((props.detalleCotList.map(det => det.cantidad * det.precio_unitario).reduce((prev, next) => prev + next)) * 0.18)
                .toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
              }
            </b>
          </div>

          <div className='d-flex justify-content-between'>
            <b>Total</b>
            <b>
              {props.cotizacion.parv_descripcion === 'SOLES'?'S/.':'$'} { props.detalleCotList.length === 0? 0:
                ((props.detalleCotList.map(det => det.cantidad * det.precio_unitario).reduce((prev, next) => prev + next)) * 1.18)
                .toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
              }
            </b>
          </div>
        </>
      )
    }
    
  }

  const handleClick = () => input.current.click();

  const subirArchivo = (e) => {
    // console.log(e[0].name)
    props.setCotizacion({...props.cotizacion, ubicacion_archivo: e})
  }

  const ChangeMoneda = async (e) => {
    props.setLoadingButtonAprobar(true)
    if (e === 'DOLARES') {
      const cambioToday = await getTipoCambioToday()
      // console.log(cambioToday)
      if (cambioToday.message) {
        message.warning("No se ha Registrado el tipo de cambio de hoy", 5)
        handleOpenModalTipoCambio()
        props.setCotizacion({...props.cotizacion, 
          parv_descripcion: e
        })
      } else {
        props.setCotizacion({...props.cotizacion, 
          TipCambio: cambioToday[0].TipCambio, 
          parv_descripcion: e
        })
      }
      props.setLoadingButtonAprobar(false)
    } else {
      props.setLoadingButtonAprobar(false)
      props.setCotizacion({...props.cotizacion, 
        TipCambio: 1, 
        parv_descripcion: e
      })
    }
  }

  const handleOpenModalTipoCambio = () => {
    setOpenModal(true)
    props.setCotizacion({...props.cotizacion, 
      TipCambio: 1
    })
  }

  const Cancelar = () => {
    props.setCotizacion({
      id_cotizacion: null,
      id_requerimiento: props.cotizacion.id_requerimiento,
      total: 0,
      id_proveedor: null,
      usuario: props.usuario,
      fecha_cotizacion: moment.utc(new Date()).format('YYYY-MM-DD'),
      ubicacion_archivo: null,
      parv_descripcion: 'SOLES',
      TipCambio: 1
    })

    let lista = []
    for (let i = 0; i < props.detalleReqList.length; i++) {
      lista = [...lista,{
        id_cotizacion: null,
        id_unidad: props.detalleReqList[i].id_unidad,
        cantidad: props.detalleReqList[i].cantidad,
        precio_unitario: 1,
        id_articulo: props.detalleReqList[i].id_articulo,
        descripcion: props.detalleReqList[i].descripcion,
        marca: null,
        cantidad_unidades: props.detalleReqList[i].cantidad_unidades
      }]
    }
    props.setDetalleCotList(lista)
    // props.setDetalleCotList([])
    resetForm()
    props.setNombreCotizacionForm('Nueva Cotización')
  }

  const EditCotizacion = async() => {
    // console.log('edit', props.cotizacion)
    if (props.cotizacion.parv_descripcion === 'DOLARES' && props.cotizacion.TipCambio === 1) {
      message.error('Falta Ingresar el Tipo de Cambio')
    } else {
      if (props.detalleCotList.length === 0) {
        message.error('Debe Ingresar los Materiales Cotizados')
      } else {
        const body = {
          cotizacion: {...props.cotizacion, checkIGV: checkIGV,
            total: checkIGV === true? props.detalleCotList.map(det => det.cantidad * det.precio_unitario).reduce((prev, next) => prev + next):
            props.detalleCotList.map(det => det.cantidad * det.precio_unitario).reduce((prev, next) => prev + next) * 1.18
          },
          detalleCotList: props.detalleCotList
        }

        const validarDetalle = props.detalleCotList.filter(detalle => {
          return detalle.id_unidad === null || detalle.id_articulo === null
        })

        if (validarDetalle.length > 0) {
          message.error('Faltan campos en el detalle de Equipos')
        } else {
          props.setLoadingButtonAprobar(true)
          try {
            await fetch(`${process.env.REACT_APP_HOSTNAME}/UpdateCotizacion`,{
              method: "PUT",
              body: JSON.stringify(body),
              headers: {
                'Content-Type': 'application/json'
              }
            }).then(res => res.json())
            .then(data =>{
              message.success(data.mensaje)
              props.setLoadingButtonAprobar(false)
              props.setCotizacion({
                id_cotizacion: null,
                id_requerimiento: props.cotizacion.id_requerimiento,
                total: 0,
                id_proveedor: null,
                usuario: props.usuario,
                fecha_cotizacion: moment.utc(new Date()).format('YYYY-MM-DD'),
                ubicacion_archivo: null,
                parv_descripcion: 'SOLES',
                TipCambio: 1
              })
              let lista = []
              for (let i = 0; i < props.detalleReqList.length; i++) {
                lista = [...lista,{
                  id_cotizacion: null,
                  id_unidad: props.detalleReqList[i].id_unidad,
                  cantidad: props.detalleReqList[i].cantidad,
                  precio_unitario: 1,
                  id_articulo: props.detalleReqList[i].id_articulo,
                  descripcion: props.detalleReqList[i].descripcion,
                  marca: null,
                  cantidad_unidades: props.detalleReqList[i].cantidad_unidades
                }]
              }
              props.setDetalleCotList(lista)
              // props.setDetalleCotList([])
              resetForm()
              props.getCotizacionesByRequerimiento(props.cotizacion.id_requerimiento)
              props.setNombreCotizacionForm('Nueva Cotización')
              props.getDetalleCotizacionByReq(props.cotizacion.id_requerimiento)
              props.setPestaña('listaCotizaciones')
              setCheckIGV(true)
            }).catch(err => {
              console.log(err)
              props.setLoadingButtonAprobar(false)
            });
          } catch (error) {
            console.log(error)
            props.setLoadingButtonAprobar(false)
          }
        }
      }
    }
  }

  const BuscarProveedorByRUC = () => {
    // console.log(props.proveedoresList)
    const findFroveedor = props.proveedoresList.filter(prov => {
      return(prov.num_ruc === inputBuscarRUC )
    })
    // console.log(findFroveedor)
    if ( findFroveedor.length === 0 ) {
      message.warn(`No se encontró Proveedor con el RUC ${inputBuscarRUC}`)
    } else {
      props.setCotizacion({...props.cotizacion, id_proveedor: findFroveedor[0].id_prove})
      form.setFieldsValue({id_proveedor: findFroveedor[0].id_prove})
    }
  }

  const BuscarProveedorByRS = () => {
    const findFroveedor = props.proveedoresList.filter(prov => {
      return(prov.nombre_com === inputBuscarNombre.toUpperCase() )
    })

    // console.log(findFroveedor)
    if ( findFroveedor.length === 0 ) {
      message.warn(`No se encontró Proveedor con el Nombre Comercial ${inputBuscarNombre}`)
    } else {
      props.setCotizacion({...props.cotizacion, id_proveedor: findFroveedor[0].id_prove})
      form.setFieldsValue({id_proveedor: findFroveedor[0].id_prove})
    }
  }

  const proveedorChange = (id_proveedor) => {
    props.setCotizacion({...props.cotizacion, id_proveedor: id_proveedor})

    const findFroveedor = props.proveedoresList.filter(prov => {
      return(prov.id_prove === id_proveedor )
    })
    form.setFieldsValue({inputBuscarRUC: findFroveedor[0].ruc, inputBuscarNombre: findFroveedor[0].nombre_comercial})
    setInputBuscarRUC(findFroveedor[0].ruc)
    setInputBuscarNombre(findFroveedor[0].nombre_comercial)
  }

  const handleOpenModalProveedor = async(titulo, id_prove) => {
    setOpenModalProveedor(true)
    setTituloModal(titulo)
    setIdProveedor(id_prove)
  }

  return(
    <>
      <Form form={form} layout='vertical' onFinish={props.cotizacion.id_cotizacion === null? AddCotizacion: EditCotizacion}>
        <div className='elem-list'>
          <div className='col-sm-11 col-md-2 col-lg-2'>
            <Item label="Fecha" name="fecha">
              <b>{moment(props.cotizacion.fecha_cotizacion).format('DD/MM/YYYY')}</b>
            </Item>
          </div>

          <div className='col-sm-11 col-md-2 col-lg-2'>
            <Item label="Moneda" name="parv_descripcion">
              <Select
                disabled = {props.requerimiento.aprobado === 'NO' || 
                  props.requerimiento.estado === 'RECHAZADO' || props.requerimiento.estado === 'FINALIZADO'? true: false}
                defaultValue={props.cotizacion.parv_descripcion}
                className='mb-lg-0'
                placeholder='Seleccionar Moneda'
                optionFilterProp='children'
                filterOption={props.optionFilter}
                onChange={(e)=> ChangeMoneda(e)}
              >
                <Option value={'SOLES'} key={'SOLES'}>S/.</Option>
                <Option value={'DOLARES'} key={'DOLARES'}>$</Option>
              </Select>
            </Item>
          </div>

          <div className='col-sm-11 col-md-2 col-lg-2'>
            <label className='mb-3' style={{fontWeight:'bold'}}>Tipo de Cambio</label>
            <br />
            <b>{props.cotizacion.TipCambio}</b>
          </div>

          {props.cotizacion.id_cotizacion === null?
            <div className='col-sm-11 col-md-3 col-lg-3'>
              <b style={{color:"red"}}>* </b>
              <b className='mr-4'>{props.cotizacion.ubicacion_archivo === null?null:
                props.cotizacion.ubicacion_archivo[0] === undefined?null:props.cotizacion.ubicacion_archivo[0].name}</b>
              <br/>
              <input ref={input} onChange={(e) => subirArchivo(e.target.files)} type='file' style={{ display: 'none' }} />
              <Button size='small' type={'primary'} className='btn-outline' onClick={handleClick} icon={<FilePdfOutlined />}
                disabled = {props.requerimiento.aprobado === 'NO' || 
                  props.requerimiento.estado === 'RECHAZADO' || props.requerimiento.estado === 'FINALIZADO'? true: false}
                >
                Subir PDF
              </Button>
            </div>
          :null}
          
        </div>

        <div className='elem-list'>
          <div className='col-sm-11 col-md-5 col-lg-5'>
            <Item label="Proveedor" name="id_proveedor" rules={[
                {...rules.required, message: 'El Proveedor es Requerido'}
            ]}>
              <Select
                disabled = {props.requerimiento.aprobado === 'NO' || 
                  props.requerimiento.estado === 'RECHAZADO' || props.requerimiento.estado === 'FINALIZADO'? true: false}
                showSearch
                defaultValue={props.id_prove}
                className='mb-lg-0'
                placeholder='Seleccionar Proveedor'
                optionFilterProp='children'
                filterOption={props.optionFilter}
                onChange={(e)=>proveedorChange(e)}
              >
              {proveedoresFiltrados().map(proveedor =>{
                return(
                  <Option value={proveedor.id_prove} key={proveedor.id_prove}>{proveedor.descripcion}</Option>
                )
              })}
              </Select>
              
            </Item>
          </div>

          <div  className='col-sm-8 col-md-2 col-lg-2'>
            <Item label="Buscar RUC" name="inputBuscarRUC">
              <Input defaultValue={inputBuscarRUC}
                onChange={e=> setInputBuscarRUC(e.currentTarget.value)}
              />
            </Item>
          </div>

          <div  className='col-sm-1 col-md-1 col-lg-1'>
            <br />
            <Button type={'primary'} className='btn-outline mt-2' onClick={BuscarProveedorByRUC} icon={<SearchOutlined />}/>
          </div>

          <div  className='col-sm-8 col-md-2 col-lg-2'>
            <Item label="Buscar Nombre" name="inputBuscarNombre">
              <Input defaultValue={inputBuscarNombre}
                onChange={e=> setInputBuscarNombre(e.currentTarget.value)}
              />
            </Item>
          </div>

          <div  className='col-sm-1 col-md-1 col-lg-1'>
            <br />
            <Button type={'primary'} className='btn-outline mt-2 mr-2' onClick={BuscarProveedorByRS} icon={<SearchOutlined />}/>
            <Button type='primary'  
                title="Nuevo Proveedor" 
                onClick={e=>handleOpenModalProveedor('Nuevo Proveedor', null)}
                icon={<PlusOutlined />} />
          </div>
        </div>

        <div className='col-sm-12 col-md-12 col-lg-12'>
          <Card title="Equipos/Materiales">
            <div className='col-sm-11 col-md-2 col-lg-2'>
              <label className='mb-3' style={{fontWeight:'bold'}}>Rubro:</label>
              <br />
              <b>{props.requerimiento.nombre_rubro}</b>
            </div>
            
            <Table 
              pagination={false} 
              columns={props.requerimiento.tipo === 'COMPRA' || props.requerimiento.tipo === 'ATENCION'? columns: columnsServ}
              dataSource={props.detalleCotList}
              size="small"
              footer={() => Total()}
            />
          </Card>
        </div>

        <div className='d-flex justify-content-center'>

          <Button className='mr-2' loading={props.loadingButtonAprobar}
            danger icon={<CloseOutlined />} onClick={e=>Cancelar()}>Cancelar
          </Button>

          <Button className='bg-color-success' loading={props.loadingButtonAprobar}
            disabled = {props.requerimiento.aprobado === 'NO' || props.requerimiento.estado === 'RECHAZADO' ||
               props.requerimiento.estado === 'FINALIZADO' || props.requerimiento.estado === 'ESPERA DE CONFORMIDAD'? true: false}
            type='primary' icon={<SaveOutlined />} htmlType="submit">
            Guardar
          </Button>
        </div>

      </Form>
      {<ModalTipoCambio
        openModal = {openModal}
        loadingButtonAprobar = {props.loadingButtonAprobar}
        setLoadingButtonAprobar = {props.setLoadingButtonAprobar}
        setTabla = {props.setCotizacion}
        tabla = {props.cotizacion}
        usuario = {props.usuario}
        setOpenModal = {setOpenModal}
      />}

      {<ModalProveedor
        idProveedor = {idProveedor}
        setIdProveedor = {setIdProveedor}
        openModal = {openModalProveedor}
        tituloModal = {tituloModal}
        rubrosList = {props.rubrosList}
        bancosList = {bancosList}
        usuario = {props.usuario}
        setOpenModal = {setOpenModalProveedor}
        getProveedores = {props.getProveedores}
        departamentosList = {departamentosList}
        provinciasList = {provinciasList}
        provinciasSubList = {provinciasSubList}
        distritosList = {distritosList}
        distritosSubList = {distritosSubList}
        setProvinciasSubList = {setProvinciasSubList}
        setDistritosSubList = {setDistritosSubList}
      />}

      {<ModalArticulo
        openModal = {openModalArticulo}
        tituloModal = {tituloModalArticulo}
        articulo = {articuloNuevo}
        setArticulo = {setArticuloNuevo}
        form = {form2}
        getArticulos = {props.getArticulos}
        setOpenModal = {setOpenModalArticulo}
        rubrosList = {props.rubrosList}
        usuario = {props.usuario}
        getRubros = {props.getRubros}
      />}

      {<ModalUnidadForm
        openModalUnidad = {openModalUnidad}
        setOpenModalUnidad = {setOpenModalUnidad}
        getUnidades = {props.getUnidades}
        usuario = {props.usuario}
      />}
    </>
  )
}

export default FormularioCotizacion