import {useEffect, useRef, useState} from "react";
import { IPageData } from "../../../interfaces/page";
import { usePageData } from '../../../hooks/usePage';
import Cookies from "universal-cookie";
import moment from "moment";
import { Card, Table, Button, Select, Input } from 'antd';
import { Option } from "antd/lib/mentions";
import { DownloadOutlined, EditOutlined, EyeOutlined, FilePdfOutlined } from "@ant-design/icons";
import { DownloadFile, existeRol, PreviewFile } from "../FuncionesGenerales";
import { PdfFilePagos } from "../componentesPDF/GenerarPDFPagos";
import { ExcelFilePagos } from "../componentesExcel/GenerarExcelPagos";
import FormularioPago from "./FormularioPago";

const cookies = new Cookies()

const pageData: IPageData = {
  fulFilled: true,
  title: 'Pagos',
  breadcrumbs: [
    {
      title: 'Pagos',
    }
  ]
};

const Pagos = () => {
  
  usePageData(pageData);
  const componentePDF = useRef(null)
  const [pagosList, setPagosList] = useState([])
  const [fechaInicio, setFechaInicio] = useState(''/*moment(new Date()).format("YYYY-MM").concat('-01')*/)
  const [fechaFin, setFechaFin] = useState(moment(new Date()).format("YYYY-MM-DD"))
  const [proveedoresList, setProveedoresList] = useState([])
  const [conceptosList, setConceptosList] = useState([])
  // const [areasList, setAreasList] = useState([])
  const [selectProveedor, setSelectProveedor] = useState(undefined)
  const [selectMoneda, setSelectMoneda] = useState(undefined)
  const [selectTipoDoc, setSelectTipoDoc] = useState(undefined)
  const [selectTipoMovimiento, setSelectTipoMovimiento] = useState(undefined)
  const [selectConcepto, setSelectConcepto] = useState(undefined)
  const [selectTipoPago, setSelectTipoPago] = useState(undefined)
  const [selectFormaPago, setSelectFormaPago] = useState(undefined)
  const [inputNumOrden, setInputNumOrden] = useState('')
  const [inputSerie, setInputSerie] = useState('')
  const [inputNumeroSerie, setInputNumeroSerie] = useState('')
  const [inputDetalleConcepto, setInputDetalleConcepto] = useState('')
  const [permisosPagos, setPermisosPagos] = useState(false);
  const [idPago, setIdPago] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [factura, setFactura] = useState({
    forma_pago: null,
    tipo_pago: null
  });

  const optionFilter = (input, option) =>
    option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;

  useEffect(() => {
    if (!cookies.get('nameid_logis') || existeRol('Tesoreria.Acceso.Pagos') === false) {
      // history.push(`/public/sign-in`)
      window.location.href='/'    
    } else {
      getPagos()
      getProveedores()
      getConceptos()
      // getAreas()
    }

    setPermisosPagos(existeRol('Tesoreria.Gestion.Pagos'))
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getPagos()
    }, 10000);
    return () => clearInterval(interval);
  }, [pagosList])

  const getPagos = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getPagos/${1}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setPagosList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  // const getAreas = async() => {
  //   try {
  //     await fetch(`${process.env.REACT_APP_HOSTNAME}/getAreas/${1}`)
  //     .then(res => res.json())
  //     .then(data => {
  //       // console.log(data)
  //       setAreasList(data)
  //     })
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  const getProveedores = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getProveedores`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setProveedoresList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getConceptos = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getConceptos`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setConceptosList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  // const handleOpenModalArchivo = (record) => {
  //   console.log(record)
  //   setOpenModalArchivo(true)
  //   setUbicacionArchivo(null)
  //   setFactura(record)
  // }

  const columns = [
    {
      title: (<div style={{width:120}}></div>),
      render: record => {
        return(
          <>
            {permisosPagos === true && moment.utc(new Date()).format("YYYY-MM-DD") === moment.utc(record.fecha_pago).format("YYYY-MM-DD")?
            <Button type='primary' className="bg-color-orange"
              title="Editar Pago" 
              onClick={e=>handleOpenModal(record)}
              icon={<EditOutlined />} />
            :null}

            {record.ubicacion_archivo === null?null:
              <>
                <Button className='bg-color-success mr-1 ml-1' 
                  title="Ver Archivo" 
                  onClick={e => PreviewFile(record)}
                  icon={<EyeOutlined />}
                />

                <Button type={'primary'} 
                  title="Descargar Archivo" 
                  onClick={e => DownloadFile(`Pago_`, record, record.id_pago)}
                  icon={<DownloadOutlined />} 
                /> 
              </>
            }

            {/* {permisosPagos === true?
            <Button type={'primary'} className='btn-outline mr-2' 
              title='Reemplazar Archivo' icon={<ReloadOutlined />}
              onClick={e=>handleOpenModalArchivo(record)} 
            /> 
            :null} */}
          </>
        )
      }
    },
    {
      title: (<><div style={{width:120}}>FECHA</div></>),
      dataIndex: 'fecha_creacion',
      key: 'fecha_creacion',
      render: text => (
        moment.utc(text).format('DD/MM/YY HH:mm')
      )
    },
    {
      title: (
        <>
          <div style={{width:150}}>
            <Input defaultValue={inputNumOrden} 
              onChange={e=>setInputNumOrden(e.currentTarget.value)}
                placeholder = "ORDEN COMPRA"
            />
          </div>
        </>
      ),
      dataIndex: 'numero_orden',
      key: 'numero_orden'
    },
    {
      title: (
        <>
          <div style={{width:140}} className="mr-4">
            <Select
              allowClear
              defaultValue={selectTipoMovimiento}
              className='mb-lg-0'
              placeholder='TIPO MOV.'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={(e)=>setSelectTipoMovimiento(e)}
            >
              <Option value={'INGRESO'} key={'INGRESO'}>INGRESO</Option>
              <Option value={'EGRESO'} key={'EGRESO'}>EGRESO</Option>
            </Select>
          </div>
        </>
      ),
      dataIndex: 'tipo_operacion',
      key: 'tipo_operacion'
    },
    {
      title: (
        <>
          <div style={{width:180}} className="mr-4">
            <Select
              allowClear
              showSearch
              defaultValue={selectProveedor}
              className='mb-lg-0'
              placeholder='PROVEEDOR'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={(e)=>setSelectProveedor(e)}
            >
            {proveedoresList.map(proveedor =>{
              return(
                <Option value={proveedor.id_prove} key={proveedor.id_prove}>{proveedor.descripcion}</Option>
              )
            })}
            </Select>
          </div>
        </>
      ),
      dataIndex: 'descripcion',
      key: 'descripcion'
    },
    {
      title: 'RUC',
      dataIndex: 'ruc',
      key: 'ruc'
    },
    {
      title: (<><div style={{width:180}}>BANCO</div></>),
      dataIndex: 'nombre_banco',
      key: 'nombre_banco'
    },
    {
      title: 'N° CUENTA',
      dataIndex: 'numero_cuenta',
      key: 'numero_cuenta'
    },
    {
      title: 'CCI',
      dataIndex: 'cci',
      key: 'cci'
    },
    {
      title: (
        <>
          <div style={{width:140}} className="mr-4">
            <Select
              allowClear
              defaultValue={selectTipoDoc}
              className='mb-lg-0'
              placeholder='DOCUMENTO'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={(e)=>setSelectTipoDoc(e)}
            >
              <Option value={'BOLETA'} key={'BOLETA'}>BOLETA</Option>
              <Option value={'FACTURA'} key={'FACTURA'}>FACTURA</Option>
              <Option value={'RECIBO POR HONORARIOS'} key={'RECIBO POR HONORARIOS'}>RECIBO POR HONORARIOS</Option>
              <Option value={'VALE INTERNO'} key={'VALE INTERNO'}>VALE INTERNO</Option>
              <Option value={'NOTA DE CRÉDITO'} key={'NOTA DE CRÉDITO'}>NOTA DE CRÉDITO</Option>
              <Option value={'NOTA DE DÉBITO'} key={'NOTA DE DÉBITO'}>NOTA DE DÉBITO</Option>
            </Select>
          </div>
        </>
      ),
      dataIndex: 'tipo_documento',
      key: 'tipo_documento'
    },
    {
      title: (
        <>
          <div style={{width:120}} className="mr-4">
            <Select
              allowClear
              defaultValue={selectTipoPago}
              className='mb-lg-0'
              placeholder='TIPO PAGO'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={(e)=>setSelectTipoPago(e)}
            >
              <Option value={'CREDITO'} key={'CREDITO'}>CRÉDITO</Option>
              <Option value={'CONTADO'} key={'CONTADO'}>CONTADO</Option>
            </Select>
          </div>
        </>
      ),
      dataIndex: 'tipo_pago',
      key: 'tipo_pago'
    },
    {
      title: (
        <>
          <div style={{width:140}} className="mr-4">
            <Select
              allowClear
              defaultValue={selectFormaPago}
              className='mb-lg-0'
              placeholder='FORMA PAGO'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={(e)=>setSelectFormaPago(e)}
            >
              <Option value={'EFECTIVO'} key={'EFECTIVO'}>EFECTIVO</Option>
              <Option value={'TRANSFERENCIA'} key={'TRANSFERENCIA'}>TRANSFERENCIA</Option>
            </Select>
          </div>
        </>
      ),
      dataIndex: 'forma_pago',
      key: 'forma_pago'
    },
    {
      title: (
        <>
          <div style={{width:120}}>
            <Input defaultValue={inputSerie} 
              onChange={e=>setInputSerie(e.currentTarget.value)}
                placeholder = "SERIE"
            />
          </div>
        </>
      ),
      dataIndex: 'serie',
      key: 'serie'
    },
    {
      title: (
        <>
          <div style={{width:120}}>
            <Input defaultValue={inputNumeroSerie} 
              onChange={e=>setInputNumeroSerie(e.currentTarget.value)}
                placeholder = "NÚM. SERIE"
            />
          </div>
        </>
      ),
      dataIndex: 'numero_serie',
      key: 'numero_serie'
    },
    {
      title: (
        <>
          <div style={{width:160}} className="mr-4">
            <Select
                allowClear
                defaultValue={selectConcepto}
                className='mb-lg-0'
                placeholder='CONCEPTO'
                optionFilterProp='children'
                filterOption={optionFilter}
                onChange={(e)=> setSelectConcepto(e)}
              >
                {conceptosList.map(concepto =>{
                  return(
                    <Option value={concepto.id_concepto} key={concepto.id_concepto}>{concepto.nombre}</Option>
                  )
                })}
              </Select> 
          </div>
        </>
      ),
      dataIndex: 'nombre',
      key: 'nombre'
    },
    // {
    //   title: (
    //     <>
    //       <div style={{width:120}}>
    //         <Input defaultValue={inputDetalleConcepto} 
    //           onChange={e=>setInputDetalleConcepto(e.currentTarget.value)}
    //             placeholder = "DET. CONCEPTO"
    //         />
    //       </div>
    //     </>
    //   ),
    //   dataIndex: 'detalle_concepto',
    //   key: 'detalle_concepto'
    // },
    {
      title: (
        <>
          <div style={{width:110}} className="mr-4">
            <Select
              allowClear
              defaultValue={selectMoneda}
              className='mb-lg-0'
              placeholder='MONEDA'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={(e)=>setSelectMoneda(e)}
            >
              <Option value='S/.' key='S/.'>S/.</Option>
              <Option value='$  ' key='$  '>$</Option>
            </Select>
          </div>
        </>
      ),
      dataIndex: 'Parc_Valor',
      key: 'Parc_Valor'
    },
    {
      title: 'MONTO',
      dataIndex: 'monto',
      key: 'monto'
    },
    {
      title: 'TIPO CAMBIO',
      dataIndex: 'TipCambio',
      key: 'TipCambio'
    },
    {
      title: 'RESPONSABLE',
      dataIndex: 'responsable',
      key: 'responsable'
    }
  ]

  const pagosListFiltrados = () => {
    // console.log(pagosList[0].serie === null? 'nulo': pagosList[0].serie.toUpperCase().includes(inputSerie.toUpperCase()))
    const lista = pagosList.filter(pago => {
      return( moment.utc(pago.fecha_pago).format("YYYY-MM-DD") >= fechaInicio && 
        moment.utc(pago.fecha_pago).format("YYYY-MM-DD") <= fechaFin && 
        (selectTipoDoc === undefined? pago.tipo_documento !== selectTipoDoc: pago.tipo_documento === selectTipoDoc) && 
        (selectTipoPago === undefined? pago.tipo_pago !== selectTipoPago: pago.tipo_pago === selectTipoPago) && 
        (selectFormaPago === undefined? pago.forma_pago !== selectFormaPago: pago.forma_pago === selectFormaPago) && 
        (selectProveedor === undefined? pago.id_proveedor !== selectProveedor: pago.id_proveedor === selectProveedor) &&
        (selectMoneda === undefined? pago.Parc_Valor !== selectMoneda: pago.Parc_Valor === selectMoneda) &&
        (selectConcepto === undefined? pago.id_concepto !== selectConcepto: pago.id_concepto === selectConcepto) &&
        pago.numero_orden.toUpperCase().includes(inputNumOrden.toUpperCase()) && 
        pago.serie.toUpperCase().includes(inputSerie.toUpperCase() ) && 
        pago.numero_serie.toUpperCase().includes(inputNumeroSerie.toUpperCase()) && 
        pago.detalle_concepto.toUpperCase().includes(inputDetalleConcepto.toUpperCase())
      )
    })
    return lista
  }

  const ExportarPDF = () => {
    componentePDF.current.save()
  }

  const handleOpenModal = async(pago) => {
    // console.log(pago)
    setOpenModal(true)
    setIdPago(pago.id_pago)
    setFactura({
      forma_pago: pago.forma_pago,
      tipo_pago: pago.tipo_pago
    })
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setIdPago(null)
  }

  if (pagosList.length > 0) {
    return(
      <>
        {PdfFilePagos(componentePDF, pagosListFiltrados(), fechaInicio, fechaFin)}
        <div className='row'>
          <div className='col-md-3 col-lg-3'>
            <label>Desde: </label> <br/> 
            <Input type="date"/*"datetime-local"*/
              value={fechaInicio}
              onChange={e => setFechaInicio(e.currentTarget.value)}
            />
          </div>

          <div className='col-md-3 col-lg-3'>
            <label>Hasta: </label> <br/> 
            <Input type="date"/*"datetime-local"*/
              value={fechaFin} 
              onChange={e => setFechaFin(e.currentTarget.value)}
            />  
          </div>

          <div className='elem-list mt-4'>
            {/* {permisosPagos === false?
              <Button type='primary'  
                title="Nuevo Pago" 
                onClick={e=>handleOpenModal('Nuevo Pago', null)}
                icon={<PlusOutlined />} >Agregar
              </Button>   
            : null
            } */}
            <Button danger 
              title="Exportar PDF" 
              onClick={e => ExportarPDF()}
              icon={<FilePdfOutlined />} >PDF
            </Button>
            {ExcelFilePagos(pagosListFiltrados(), fechaInicio, fechaFin)}
          </div>
        </div>

        <Card>
          <div>
            <Table
              columns={columns}
              dataSource={pagosListFiltrados()}
              size='small'
            />
          </div>

          {/* {<Modal
            visible={openModal}
            closable={false}
            width='80%'
            title={<h3 className='m-0'>{tituloModal}</h3>}
            onCancel={handleCloseModal}
            footer={
              <div className='modal-footer d-flex justify-content-between'>
                <Button danger onClick={handleCloseModal} icon={<CloseOutlined />}>
                  Cerrar
                </Button>
              </div>
            }
          >
          <div className='row'>
            <div className='col-sm-12 col-md-12 col-lg-12'>
              <FormularioPago
                operacion = ''
                disabled = {false}
                usuario = {cookies.get('nameid_logis')}
                conceptosList = {conceptosList}
                idPago = {idPago}
                setIdPago = {setIdPago}
                getPagos = {getPagos}
                handleCloseModal = {handleCloseModal}
                proveedoresList = {proveedoresList}
                areasList = {areasList}
              />
            </div>
          </div>
          </Modal>} */}
          {<FormularioPago
            operacion = 'EGRESO'
            disabled = {true}
            usuario = {cookies.get('nameid_logis')}
            conceptosList = {conceptosList}
            idPago = {idPago}
            setIdPago = {setIdPago}
            getPagos = {getPagos}
            handleCloseModal = {handleCloseModal}
            openModal = {openModal}
            factura = {factura}
          />}
        </Card>
      </>
    )
  } else {
    return(
      <>
        <div className='row'>
          <div className='col-md-3 col-lg-3'>
            <label>Desde: </label> <br/> 
            <Input type="date"/*"datetime-local"*/
              value={fechaInicio}
              onChange={e => setFechaInicio(e.currentTarget.value)}
            />
          </div>

          <div className='col-md-3 col-lg-3'>
            <label>Hasta: </label> <br/> 
            <Input type="date"/*"datetime-local"*/
              value={fechaFin} 
              onChange={e => setFechaFin(e.currentTarget.value)}
            />  
          </div>

          {/* <div className='elem-list mt-4'>
            {permisosPagos === false?
              <Button type='primary'  
                title="Nuevo Pago" 
                onClick={e=>handleOpenModal('Nuevo Pago', null)}
                icon={<PlusOutlined />} >Agregar
              </Button>   
            : null
            }
          </div> */}
        </div>

        <Card>
          <div>
            No hay Datos
          </div>

          {/* {<FormularioPago
            operacion = ''
            disabled = {true}
            usuario = {cookies.get('nameid_logis')}
            conceptosList = {conceptosList}
            idPago = {idPago}
            setIdPago = {setIdPago}
            getPagos = {getPagos}
            handleCloseModal = {handleCloseModal}
            proveedoresList = {proveedoresList}
            areasList = {areasList}
            openModal = {openModal}
            tituloModal = {tituloModal}
            
          />} */}
        </Card>
      </>
    )
  }
}

export default Pagos