import {useEffect, useState} from "react";
import { IPageData } from "../../../interfaces/page";
import { usePageData } from '../../../hooks/usePage';
import Cookies from "universal-cookie";
import { Table, Select, Input, message, Card, Button } from 'antd';
import { Option } from 'antd/lib/mentions';
import { existeRol } from "../FuncionesGenerales";

const cookies = new Cookies()

const pageData: IPageData = {
  fulFilled: true,
  title: 'Stock',
  breadcrumbs: [
    {
      title: 'Stock',
    }
  ]
};

const ArticulosPorArea = () => {
  usePageData(pageData);
  const [selectArea, setSelectArea] = useState(undefined);
  const [selectRubro, setSelectRubro] = useState(undefined);
  const [areasList, setAreasList] = useState([])
  const [articulosList, setArticulosList] = useState([])
  const [loadingButton, setLoadingButton] = useState(false);
  const [inputNombre, setInputNombre] = useState('');
  const [inputCodigo, setInputCodigo] = useState('');
  const [rubrosList, setRubrosList] = useState([])
  const [responsablesAreasList, setResponsablesAreasList] = useState([])
  const [rolLogistica, setRolLogistica] = useState(false);
  const [user, setUser] = useState({DocumentoIdentidad:null})

  const optionFilter = (input, option) => {
    return (option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0)
  }

  useEffect(() => {
    if (!cookies.get('nameid_logis')) {
      // history.push(`/public/sign-in`)
      window.location.href='/'    
    } else {
      getAreas()
      getRubros()
      getResponsables()
      getUser()
      setRolLogistica(existeRol('Logistica.Gestion.Requerimiento'))
    }

  }, []);

  const getUser = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getUser/${cookies.get('nameid_logis')}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data, data.DocumentoIdentidad)
        // console.log(responsablesAreasList)
        if (data.message) {
          setUser({DocumentoIdentidad: null})
        } else {
          setUser(data)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getAreas = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getAreas/${1}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          setAreasList([])
        } else {
          setAreasList(data)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getResponsables = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getResponsables`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setResponsablesAreasList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getRubros = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getRubros`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          setRubrosList([])
        } else {
          setRubrosList(data)
        }
        
      })
    } catch (error) {
      console.log(error)
    }
  }

  const AreaChange = async(id_area) => {
    setSelectArea(id_area)
    if (id_area !== undefined) {
      try {
        setLoadingButton(true)
        await fetch(`${process.env.REACT_APP_HOSTNAME}/getMovimientosByArea/${id_area}`)
          .then(res => res.json())
          .then(data => {
            // console.log(data)
            setLoadingButton(false)
            if (data.message) {
              setArticulosList([])
              message.warn(data.message)
            } else {
              setArticulosList(data)
            }
          })
      } catch (error) {
        console.log(error)
        setLoadingButton(false)
        message.error('Ocurrió un error al traer la info.')
      }  
    } else {
      setArticulosList([])
    }
  }

  const columns = [
    {
      title: (
        <>
          <div style={{width:100}}>
            <Input value={inputCodigo} 
              onChange={e=>setInputCodigo(e.currentTarget.value)}
                placeholder = "CÓDIGO"
            />
          </div>
        </>
      ),
      dataIndex: 'codigo',
      key: 'codigo'
    },
    {
      title: (
        <>
          <div style={{width:200}}>
            <Input defaultValue={inputNombre} 
              onChange={e=>setInputNombre(e.currentTarget.value)}
                placeholder = "Nombre"
            />
          </div>
        </>
      ),
      dataIndex: 'nombre',
      key: 'nombre'
    },
    {
      title:(
        <>
          <div style={{width:250}}>
            <Select
              allowClear
              showSearch
              value={selectRubro}
              className='mb-lg-0'
              placeholder='RUBRO'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={e=>setSelectRubro(e)}
            >
              {rubrosList.map(rubro =>{
                return(<Option value={rubro.id_rubro} key={rubro.id_rubro}>{rubro.nombre}</Option>)
              })}
            </Select>
          </div>
        </>
      ),
      dataIndex: 'nombre_rubro',
      key: 'nombre_rubro'
    },
    {
      title: 'STOCK DISP.',
      render: record => {
        if (record./*stock_disponible*/consumo <= 0) {
          return(<p style={{color:'#EE0000'}}>{record./*stock_disponible*/consumo}</p>)
        } else {
          return(<p>{record./*stock_disponible*/consumo}</p>)
        }
      }
    }

  ]

  const articulosListFiltrados = () => {
    const lista = articulosList.filter(articulo => {
      return(articulo.codigo.toUpperCase().includes(inputCodigo.toUpperCase()) && 
      articulo.nombre.includes(inputNombre.toUpperCase()) && 
      (selectRubro === undefined?
        articulo.id_rubro !== selectRubro: articulo.id_rubro === selectRubro))
    })

    return lista
  }

  const filtrarAreas = () => {
    const listaAreasByUser = responsablesAreasList.filter(res => {
      return( res.DocumentoIdentidad === user.DocumentoIdentidad && res.activo === 'SI' )
    })

    let listaFinalAreas = []
    for (let i = 0; i < areasList.length; i++) {
      const element = listaAreasByUser.filter(lis =>{
        return(areasList[i].id_area === lis.id_area)
      });
      if (element.length > 0) {
        listaFinalAreas = [...listaFinalAreas, areasList[i]]
      }
    }

    return (listaFinalAreas)
  }

  // const pdf = async() => {
  //   await fetch(`${process.env.REACT_APP_HOSTNAME}/GenerarPDF`,{
  //     method: "POST",
  //     body: JSON.stringify({}),
  //     headers:{
  //       'Content-Type': 'application/json'
  //     }
  //   }).then(res => res.json()).then( async data => {

  //     console.log(data)
  //     const base64 = await fetch(data.base64);
  //     const blob = await base64.blob();

  //     console.log(blob)

  //     const file = window.URL.createObjectURL(blob)
  //     // const file = window.URL.createObjectURL(new Blob([blob]))

  //     window.open(file, '', 'height=650, width=840')
  //   })
  // }

  return(
    <>
    {/* <Button type='primary'  
                title="Nuevo Requerimiento" 
                onClick={e=>pdf()}
                 >Agregar
              </Button>  */}
      <div className='elem-list'>
        <div className='col-sm-11 col-md-5 col-lg-5'>
          <Select
            loading={loadingButton}
            allowClear
            showSearch
            value={selectArea}
            className='mb-lg-0'
            placeholder='Seleccionar Área'
            optionFilterProp='children'
            filterOption={optionFilter}
            onChange={e => AreaChange(e)}
          >
            {rolLogistica === true? 
              areasList.map(area => {
                return(
                  <Option value={area.id_area} key={area.id_area}>{area.nombre}</Option>
                )
              }):filtrarAreas().map(area =>{
                return(
                  <Option value={area.id_area} key={area.id_area}>{area.nombre}</Option>
                )
              })
            }
          </Select>
        </div>
      </div>

      <Card>
        <div>
          <Table
            columns={columns}
            dataSource={articulosListFiltrados()}
            size='small'
          />
        </div>
      </Card>
    </>
  )    

}

export default ArticulosPorArea