import {PDFExport} from '@progress/kendo-react-pdf'
import { Table } from "antd";

const columns = [
  {
    title: 'CODIGO',
    dataIndex: 'codigo',
    key: 'codigo'
  },
  {
    title: 'RAZON SOCIAL',
    dataIndex: 'descripcion',
    key: 'descripcion'
  },
  {
    title: 'RUC',
    dataIndex: 'num_ruc',
    key: 'num_ruc'
  },
  {
    title: 'RUBRO',
    dataIndex: 'nombre_rubro',
    key: 'nombre_rubro'
  },
  {
    title: 'DIRECCION',
    dataIndex: 'direccion',
    key: 'direccion'
  },
  {
    title: 'DEPARTAMENTO',
    dataIndex: 'departamento',
    key: 'departamento'
  },
  {
    title: 'UBIGEO',
    dataIndex: 'ubigeo',
    key: 'ubigeo'
  },
  {
    title: 'REPRESENTANTE',
    dataIndex: 'nombre_rep',
    key: 'nombre_rep'
  },
  {
    title: 'TELEFONO',
    dataIndex: 'telefono',
    key: 'telefono'
  },
  {
    title: 'CELULAR',
    dataIndex: 'celular',
    key: 'celular'
  },
  {
    title: 'EMAIL',
    dataIndex: 'email',
    key: 'email'
  }
]

const PageTemplate = (props) => {
  return (
    <div style={{ position: "absolute", bottom: "10px", left: "50%" }}>
      Pag. {props.pageNum} de {props.totalPages}
    </div>
  );
};

export const PdfFileProveedores = (componentePDF, proveedoresFiltrados) => {
  return(
    <>
      <div style={{ position: "absolute", left: "-4000px", top: 0 }}>
          <PDFExport 
            ref={componentePDF} 
            paperSize='A4'
            margin="1cm" 
            landscape={true}
            pageTemplate={PageTemplate}
            fileName='Proveedores'
            scale={0.5}
          >
            <Table
              pagination={false}
              columns={columns}
              dataSource={proveedoresFiltrados}
              size='small'
            />
          </PDFExport>
      </div>
    </>
  )
}

