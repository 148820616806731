import {useEffect, useState} from "react";
import { IPageData } from "../../../interfaces/page";
import { usePageData } from '../../../hooks/usePage';
import Cookies from "universal-cookie";
import { Card, Table, Button, Input, Form, Select } from "antd";
import { CarOutlined, CloseOutlined, DeleteOutlined, EditOutlined, FilePdfOutlined, PictureOutlined, PlusOutlined, SendOutlined } from "@ant-design/icons";
import moment from "moment";
import { existeRol, PreviewFile } from "../FuncionesGenerales";
import { Rule } from 'antd/lib/form';
import { Option } from "antd/lib/mentions";

const cookies = new Cookies()

const pageData: IPageData = {
  fulFilled: true,
  title: 'Correos',
  breadcrumbs: [
    {
      title: 'correos',
    }
  ]
};

const { Item } = Form;

const rules = {
  required: { required: true, message: <></> } as Rule
};

const FormularioCorreosPacientes = () => {
  usePageData(pageData);

  const [detalleCorreos, setDetalleCorreos] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [cabecera, setCabecera] = useState(undefined);

  const [form] = Form.useForm();
  const resetForm = () => form.resetFields();

  const optionFilter = (input, option) => {
    return (option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0)
  }

  const AddCorreo = () => {
    setDetalleCorreos([...detalleCorreos,''])
  }

  const CorreoChange = (item, value) => {
    const listaItems = detalleCorreos
    const key = listaItems.indexOf(item)

    listaItems[key] = value
    
    setDetalleCorreos([...listaItems])
  }

  const RemoveCorreo = (item) => {
    const listaItems = detalleCorreos.filter(detalle => {
      return(detalle !== item)
    })
    // console.log(listaItems)
    setDetalleCorreos(listaItems)
  }

  const columns = [
    {
      title: (
        <>
          <Button type='primary' onClick={e => AddCorreo()} title="Agregar Correo" icon={<PlusOutlined />}/>
        </>
      ),
      render: record => {
        return(
          <>
            <Button danger title="Quitar" onClick={e=> RemoveCorreo(record)} icon={<DeleteOutlined />}/>  
          </>
        )
      }
    },
    {
      title: 'CORREO',
      render: record => {
        return(
          <>
            <Input name='correo' value = {record} 
              onChange={e=>CorreoChange(record, e.currentTarget.value)}
            />
          </>
        )
      }
    },
  ]

  return(<>
    <div className='row'>
      <div className='col-sm-12 col-md-6 col-lg-6'>
        <Form form={form} layout='vertical' onFinish={e => console.log(detalleCorreos)}>
          <Item label="Cabecera" name="cabecera" rules={[
            {...rules.required, message: 'Cabecera Requerida'}
          ]}>
            <Select
              showSearch
              defaultValue={cabecera}
              className='mb-lg-0'
              placeholder='Cabecera'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={(e)=>setCabecera(e)}
            >
              <Option value='general' key='general'>General</Option>
              <Option value='piel' key='piel'>Piel</Option>
              <Option value='prostata' key='prostata'>Próstata</Option>
              <Option value='utero' key='utero'>Útero</Option>
              <Option value='colon' key='colon'>Colon</Option>
              <Option value='mama' key='mama'>Mama</Option>
              <Option value='estomago' key='estomago'>Estómago</Option>
            </Select>
          </Item>  
          <Table 
            pagination={false} 
            columns={columns}
            dataSource={detalleCorreos}
            size="small"
          />
        </Form>

        <div className='modal-footer d-flex justify-content-between mt-2'>
          <Button danger /*onClick={}*/ icon={<CloseOutlined />}>Cerrar</Button>
        
          <Button className='bg-color-success' loading={loadingButton} onClick={e=>console.log(detalleCorreos)}
            type='primary' icon={<SendOutlined />} htmlType="submit">Guardar
          </Button>   
        </div>
      </div>
    </div>
  </>)

}

export default FormularioCorreosPacientes