import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';

const ModalQRInventario = (props) => {
  const [idInventario, setIdInventario] = useState(null)

  useEffect(() => {
    if (props.openModal === true) {
    // console.log(props.inventario)
    setIdInventario(props.inventario.id_inventario)
    } else {
      setIdInventario(null)
    }
  }, [props.inventario]);

  const handleCloseModal = () => {
    props.setOpenModal(false)
    setIdInventario(null)
  }

  return(
    <>
      <Modal
        visible={props.openModal}
        closable={false}
        width='90%'
        title={
          <>
            <div className='d-flex justify-content-center mb-2'>
              {/* <b style={{fontSize:"20px"}}>{props.inventario.identificador} - {props.inventario.nombre_articulo}</b>   */}
              <b style={{fontSize:"20px"}} className='mr-2'>CÓD.PATRIMONIAL:</b>
              <b style={{fontSize:"20px"}}>{props.inventario.codigo_patrimonial}</b>  
            </div>
            <div className='d-flex justify-content-between'>
              <div>
                <b style={{fontSize:"20px"}}>EQUIPO:</b><br/>
                <b style={{fontSize:"20px"}}>{props.inventario.identificador} - {props.inventario.nombre_articulo}</b> 
              </div>

              <div>
                <b style={{fontSize:"20px"}}>MARCA:</b><br/>
                <b style={{fontSize:"20px"}}>{props.inventario.marca}</b>  
              </div>

              <div>
                <b style={{fontSize:"20px"}}>SERIE:</b><br/>
                <b style={{fontSize:"20px"}}>{props.inventario.serie}</b> 
              </div>
            </div>
          </>
        }
        onCancel={handleCloseModal}
        footer={null}
      >
        <div className='d-flex justify-content-center'>
          <b style={{fontSize:"20px"}}>ESCANEA EL SIG. QR PARA VER LA INFO DEL EQUIPO:</b>
        </div>
        <div className='d-flex justify-content-center'>
        <QRCode value={`https://logistica.limatambo.com.pe/public/infoinventario?id=${idInventario}`}/>
        </div>
      </Modal>
      
    </>
  )
}

export default ModalQRInventario