import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Select, message } from 'antd';
import { Rule } from 'antd/lib/form';
import { Option } from 'antd/lib/mentions';
import moment from 'moment';
import {useEffect, useRef, useState} from "react";

const { Item } = Form;

const rules = {
  required: { required: true, message: <></> } as Rule
};

const ModalFormResiduos = (props) => {
  const [loadingButton, setLoadingButton] = useState(false)
  const [residuosSolidos, setResiduosSolidos] = useState({
    id_encargado: undefined,
    id_area: undefined,
    cantidad: null,
    fecha: null,
    tipo: undefined,
    cantFiles:0
  })
  const [idSucursal, setIdSucursal] = useState(undefined)
  const [archivos, setArchivos] = useState([undefined])

  const [form] = Form.useForm();

  const optionFilter = (input, option) => {
    return (option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0)
  }

  const input = useRef<HTMLInputElement>(null);
  const handleClick = () => input.current.click();

  const handleCloseModal = () => {
    props.setOpenModal(false)
    props.setIdResiduo(null)
    setResiduosSolidos({
      id_encargado: undefined,
      id_area: undefined,
      cantidad: null,
      fecha: null,
      tipo: undefined,
      cantFiles:0
    })
    setIdSucursal(undefined)
    setArchivos([undefined])
    form.setFieldsValue({
      id_encargado: undefined,
      id_area: undefined,
      cantidad: null,
      fecha: null,
      tipo: undefined,
      id_sucursal: undefined
    })
  }

  useEffect(() => {
    if (props.idResiduo === null) {
      // console.log(props.encargado.length)
      setResiduosSolidos({
        id_encargado: undefined,
        id_area: undefined,
        cantidad: null,
        fecha: null,
        tipo: undefined,
        cantFiles:0
      })
      setArchivos([undefined])
      setIdSucursal(undefined)
      form.setFieldsValue({
        id_encargado: undefined,
        id_area: undefined,
        cantidad: null,
        fecha: null,
        tipo: undefined,
        id_sucursal: undefined
      })

    } else {
      GetResiduosById(props.idResiduo)
    }
  }, [props.idResiduo]);

  useEffect(() => {
    setResiduosSolidos({
      ...residuosSolidos, 
      id_encargado: props.encargado.length>0?props.encargado[0].id_encargado:undefined
    })

    form.setFieldsValue({
      id_encargado: props.encargado.length>0?props.encargado[0].id_encargado:undefined
    })
  }, [props.encargado]);

  const GetResiduosById = async(id_residuo) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/GetResiduosById/${id_residuo}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setResiduosSolidos({
          id_encargado: data[0].id_encargado,
          id_area: data[0].id_area,
          cantidad: data[0].cantidad,
          fecha: moment.utc(data[0].fecha).format('YYYY-MM-DD'),
          tipo: data[0].tipo,
          cantFiles: data[0].cantFiles
        })
        setIdSucursal(filtrarSucursalByAreas(data[0].id_area))
        form.setFieldsValue({
          id_encargado: data[0].id_encargado,
          id_area: data[0].id_area,
          cantidad: data[0].cantidad,
          fecha: moment.utc(data[0].fecha).format('YYYY-MM-DD'),
          tipo: data[0].tipo,
          id_sucursal: filtrarSucursalByAreas(data[0].id_area)
        })
      })
    } catch (error) {
      console.log(error)
    }
  }

  const UpdateResiduosSolidos = async() => {
    setLoadingButton(true)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/UpdateResiduosSolidos`,{
        method: "PUT",
        body: JSON.stringify({...residuosSolidos, id_residuo: props.idResiduo}),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data =>{
        // console.log(data.mensaje)
        if (archivos[0] === undefined) {
        message.success(data.mensaje)
        setLoadingButton(false)
        props.GetResiduosSolidos()
        handleCloseModal()
        } else {
          UploadResiduosSolidosFiles(props.idResiduo)
        }
      }).catch(err => {
        console.log(err)
        setLoadingButton(false)
      });
    } catch (error) {
      console.log(error)
      setLoadingButton(false)
    }
  }

  const AddResiduosSolidos = async() => {
    setLoadingButton(true)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/AddResiduosSolidos`, {
        method: "POST",
        body: JSON.stringify({...residuosSolidos, usuario: props.usuario}),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data =>{
        // console.log(data)
        if (archivos[0] === undefined) {
          message.success(data.mensaje)
          setLoadingButton(false)
          props.GetResiduosSolidos()
          handleCloseModal()
        } else {
          UploadResiduosSolidosFiles(data.id_residuo)
        }
      }).catch(err => {
        console.log(err)
        setLoadingButton(false)
      })
    } catch (error) {
      console.log(error)
      setLoadingButton(false)
    }
  }

  const UploadResiduosSolidosFiles = async(id_residuo) => {
    const body = {
      destination:'Logistica',
      id_residuo: id_residuo,
      varios: true
    }

    const bodyForm = new FormData()

    bodyForm.append('tabla', JSON.stringify(body))
    for (let i = 0; i < archivos.length; i++) {
      const file = archivos[i];
      const filename = file['name']
      const fileExtension = filename.split('.').pop()
      bodyForm.append('File', archivos[i], `ResiduosSolidos_${id_residuo}_${residuosSolidos.cantFiles+i}.${fileExtension}`)
    }

    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/UploadResiduosSolidosFiles`,{
        method: "POST",
        body: bodyForm
      }).then(res => res.json())
      .then(data => {
        message.success(data.message)
        setLoadingButton(false)
        props.GetResiduosSolidos()
        handleCloseModal()
      }).catch(err => {
        console.log(err)
        message.error('No se Pudo Subir el Archivo al Servidor')
        setLoadingButton(false)
      })
    } catch (error) {
      console.log(error)
      message.error('No se Pudo Subir el Archivo al Servidor')
      setLoadingButton(false)
    }
  }

  const subirArchivo = (e) => {
    setArchivos(e)
  }

  const nombresItems = () => {
    let nombres = []
    for (let i = 0; i < archivos.length; i++) {
      const element = archivos[i];
      nombres = [...nombres, {nombre_file: element.name}]
    }
    return nombres
  }

  const filtrarAreasBySucursal = () => {
    const areasFiltradas = props.areasList.filter( area => {
      return area.id_sucursal === idSucursal
    })
    return areasFiltradas//props.areasList
  }

  const changeSucursal = (value) =>{
    setIdSucursal(value)
    setResiduosSolidos({...residuosSolidos, id_area: undefined})
    form.setFieldsValue({id_area: undefined})
  }

  const filtrarSucursalByAreas = (id_area) => {
    const area = props.areasList.filter( area => {
      return area.id_area === id_area
    })
    return area.length > 0? area[0].id_sucursal: undefined
  }

  return(
    <>
      <Modal
        visible={props.openModal}
        closable={false}
        width='70%'
        title={<h3 className='m-0'>{props.idResiduo === null?'Nuevo Residuo Sólido':'Editar Residuo Sólido'}</h3>}
        onCancel={handleCloseModal}
        footer={null}
      >
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <Form form={form} layout='vertical' onFinish={ props.idResiduo === null? AddResiduosSolidos : UpdateResiduosSolidos}>
              <div className='elem-list'>
                <div className='col-sm-11 col-md-2 col-lg-2'>
                  <Item label="Fecha" name="fecha" >
                    <Input type="date" 
                      defaultValue={residuosSolidos.fecha} 
                      onChange={e=>setResiduosSolidos({...residuosSolidos, fecha:e.currentTarget.value})}
                    />
                  </Item>
                </div>

                <div className='col-sm-11 col-md-4 col-lg-4'>
                  <Item label="Encargado" name="id_encargado" rules={[
                      {...rules.required, message: 'Encargado Requerido'}
                  ]}>
                    <Select
                      showSearch
                      defaultValue={residuosSolidos.id_encargado}
                      className='mb-lg-0'
                      placeholder='-'
                      optionFilterProp='children'
                      filterOption={optionFilter}
                      onChange={e=>setResiduosSolidos(({...residuosSolidos, id_encargado:e}))}
                      disabled
                    >
                      {props.encargadosList.map(encargado =>{
                        return(
                          <Option value={encargado.id_encargado} key={encargado.id_encargado}>{encargado.nombres}</Option>
                        )
                      })}
                    </Select>
                  </Item>  
                </div> 
              </div>

              <div className='elem-list'>
                <div className='col-sm-11 col-md-4 col-lg-4'>
                  <Item label="Sede" name="id_sucursal" rules={[
                      {...rules.required, message: 'Sede Requerida'}
                  ]}>
                    <Select
                      showSearch
                      defaultValue={idSucursal}
                      className='mb-lg-0'
                      placeholder='Seleccionar Sede'
                      optionFilterProp='children'
                      filterOption={optionFilter}
                      onChange={e=> changeSucursal(e)}
                    >
                      {props.sucursalesList.map(sucursal =>{
                        return(
                          <Option value={sucursal.id_sucursal} key={sucursal.id_sucursal}>{sucursal.nombre}</Option>
                        )
                      })}
                    </Select>
                  </Item>  
                </div> 

                <div className='col-sm-11 col-md-6 col-lg-6'>
                  <Item label="Áreas" name="id_area" rules={[
                      {...rules.required, message: 'Área Requerida'}
                  ]}>
                    <Select
                      showSearch
                      defaultValue={residuosSolidos.id_area}
                      className='mb-lg-0'
                      placeholder='Seleccionar Área'
                      optionFilterProp='children'
                      filterOption={optionFilter}
                      onChange={e=>setResiduosSolidos(({...residuosSolidos, id_area:e}))}
                    >
                      {/*props.*/filtrarAreasBySucursal()/*areasList*/.map(area =>{
                        return(
                          <Option value={area.id_area} key={area.id_area}>{area.nombre}</Option>
                        )
                      })}
                    </Select>
                  </Item>  
                </div> 
              </div>

              <div className='elem-list'>
                <div className='col-sm-11 col-md-3 col-lg-3'>
                  <Item label="Tipo" name="tipo" rules={[
                      {...rules.required, message: 'Tipo Requerido'}
                  ]}>
                    <Select
                      showSearch
                      defaultValue={residuosSolidos.tipo}
                      className='mb-lg-0'
                      placeholder='Seleccionar Área'
                      optionFilterProp='children'
                      filterOption={optionFilter}
                      onChange={e=>setResiduosSolidos(({...residuosSolidos, tipo:e}))}
                    >
                      <Option value={'ESPECIAL'} key={'ESPECIAL'}>ESPECIAL</Option>
                      <Option value={'BIOMEDICO'} key={'BIOMEDICO'}>BIOMÉDICO</Option>
                    </Select>
                  </Item>  
                </div> 

                <div className='col-sm-11 col-md-3 col-lg-3'>
                  <Item label="Cantidad (Kg)" name="cantidad" rules={[
                    {...rules.required, message: 'Cantidad Requerida'}
                  ]}>
                    <Input type='number' defaultValue={residuosSolidos.cantidad} min={0} step="any"
                      onChange={e=> setResiduosSolidos({...residuosSolidos, cantidad:e.currentTarget.value})}
                    />
                  </Item>
                </div>

                <div className='col-sm-11 col-md-3 col-lg-3 mt-4'>
                  <input ref={input} onChange={(e) => subirArchivo(e.target.files)} type='file' style={{ display: 'none' }} multiple/>
                  <Button size='small' type={'primary'} className='btn-outline mt-4' onClick={handleClick}>Subir Archivo(s)</Button>
                </div>
              </div>

              <div className='elem-list mb-4'>
                {archivos[0] === undefined?null:
                  <>
                    {nombresItems().map((item, index) => {
                      return( 
                        <><b className='mr-4'>{index+1}.- {item.nombre_file}</b><br/></>
                      )
                    })}
                  </>
                }
              </div>

              <div className='modal-footer d-flex justify-content-between'>
                <Button danger onClick={handleCloseModal} icon={<CloseOutlined />}>
                  Cerrar
                </Button>
                
                <Button className='bg-color-success' loading={loadingButton}
                  type='primary' icon={<SaveOutlined />} htmlType="submit">
                  Guardar
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModalFormResiduos