// import Cookies from "universal-cookie";
// import axios from "axios";

// const cookies = new Cookies()

// const getIP = async()=>{
//   try {
//     const response = await axios.get('https://api.ipify.org?format=json');
//     console.log(response);
//   } catch (error) {
//     console.error(error);
//   }
// }

export const existeRol = (nombre_rol) => {
  const rolesList = JSON.parse(window.localStorage.getItem('permisos'))
  
  const roles = rolesList.filter(rol => {
    return rol.palabraClave === nombre_rol && rol.permitir === true
  })

  if (roles.length > 0) {
    return true
  } else {
    return false
  }
}

export const menuFilterRoles = (menuGeneral) => {
  let menufiltrado = menuGeneral

  /**PARA MENÚ ARTICULOS */
  if (existeRol('Logistica.Acceso.Articulos') === false) {
    menufiltrado = menufiltrado.filter(menu => {
      return(menu.title !== 'Artículos')
    })
  }
  /**PARA MENÚ ÓRDENES DE COMPRA */
  if (existeRol('Logistica.Acceso.OrdenCompra') === false) {
    menufiltrado = menufiltrado.filter(menu => {
      return(menu.title !== 'Órdenes de Compra')
    })
  }

  if (existeRol('Logistica.Acceso.inventarios') === false) {
    menufiltrado = menufiltrado.filter(menu => {
      return(menu.title !== 'inventarios')
    })
  }
  if (existeRol('Logistica.Acceso.Facturas') === false) {
    menufiltrado = menufiltrado.filter(menu => {
      return(menu.title !== 'Facturas')
    })
  }
  if (existeRol('Tesoreria.Acceso.Pagos') === false) {
    menufiltrado = menufiltrado.filter(menu => {
      return(menu.title !== 'Pagos')
    })
  }

  if (existeRol('Logistica.Gestion.Requerimiento') === false) {
    menufiltrado = menufiltrado.filter(menu => {
      return(menu.title !== 'Coordinadores')
    })
  }

  if (existeRol('Logistica.Acceso.Proveedores') === false) {
    menufiltrado = menufiltrado.filter(menu => {
      return(menu.title !== 'Proveedores')
    })
  }

  if (existeRol('Logistica.Acceso.Movimientos') === false) {
    menufiltrado = menufiltrado.filter(menu => {
      return(menu.title !== 'Movimientos')
    })
  }

  if (existeRol('Logistica.Acceso.Movimientos') === false) {
    menufiltrado = menufiltrado.filter(menu => {
      return(menu.title !== 'Kardex')
    })
  }

  if (existeRol('Logistica.Responsable.Area') === false) {
    menufiltrado = menufiltrado.filter(menu => {
      return(menu.title !== 'Requerimientos')
    })
  }

  if (existeRol('Logistica.Responsable.Area') === false) {
    menufiltrado = menufiltrado.filter(menu => {
      return(menu.title !== 'Stock')
    })
  }

  if (existeRol('Conductor.Acceso.Vales') === false) {
    menufiltrado = menufiltrado.filter(menu => {
      return(menu.title !== 'Combustible')
    })
  }

  if (existeRol('Logistica.Acceso.Inventarios') === false) {
    menufiltrado = menufiltrado.filter(menu => {
      return(menu.title !== 'Inventarios')
    })
  }

  if (existeRol('Logistica.Acceso.Mantenimientos') === false) {
    menufiltrado = menufiltrado.filter(menu => {
      return(menu.title !== 'Órdenes de Trabajo')
    })
  }

  if (existeRol('Logistica.Acceso.Movimientos') === false) {
    menufiltrado = menufiltrado.filter(menu => {
      return(menu.title !== 'Correos')
    })
  }

  if (existeRol('Logistica.Acceso.EncargadoResiduos') === false) {
    menufiltrado = menufiltrado.filter(menu => {
      return(menu.title !== 'Personal de Residuos Sólidos')
    })
  }
  
  if (existeRol('Logistica.Acceso.ResiduosSolidos') === false) {
    menufiltrado = menufiltrado.filter(menu => {
      return(menu.title !== 'Residuos Sólidos')
    })
  }

  return (menufiltrado)
}

export const getTipoCambioToday = async() => {
  try {
    const cambio = await fetch(`${process.env.REACT_APP_HOSTNAME}/getTipoCambioToday`)
    .then(res => res.json())
    .then(data => {
      //  console.log(data)
       return data
    })
    return cambio
  } catch (error) {
    return(error)
  }
}

export const DownloadFileArticulo = async(tabla) => {
  try {
    await fetch(`${process.env.REACT_APP_HOSTNAME}/DownloadFile`,{
      method: "POST",
      body: JSON.stringify(tabla),
      headers:{
        'Content-Type': 'application/json'
      }
    }).then(res => res.blob())
    .then(blob =>{
      const file = window.URL.createObjectURL(blob)
      const link = document.createElement('a');
      link.href = file;
      link.setAttribute('download',`${tabla.nombre}`);

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }).catch(err => console.log(err));
  } catch (error) {
    console.log(error)
  }
}

export const DownloadFile = async(nombre, tabla, id) => {
  // setOpenModal(true)
  const ubicacion = tabla.otraUbicacion?tabla.otraUbicacion:tabla.ubicacion_archivo
  // console.log(ubicacion)
  const extension = ubicacion.split('.').pop()
  // console.log(extension)

  try {
    await fetch(`${process.env.REACT_APP_HOSTNAME}/DownloadFile`,{
      method: "POST",
      body: JSON.stringify(tabla),
      headers:{
        'Content-Type': 'application/json'
      }
      /**BLOB PARA Q BAJE LOS PDFS, PARA OTRO TIPO DE FILE PARECE Q NO HACE FALTA -_- */
    }).then(res => res.blob())
    .then(blob =>{
      // console.log(blob)
      const file = window.URL.createObjectURL(blob)
      // const file = window.URL.createObjectURL(new Blob([blob]))

      const link = document.createElement('a');

      link.href = file;
      link.setAttribute(
        'download',
        `${nombre}_${id}.${extension}`,
      );

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

    }).catch(err => console.log(err));
    
  } catch (error) {
    console.log(error)
  }
}

export const PreviewFile = async(tabla) => {
  try {
    await fetch(`${process.env.REACT_APP_HOSTNAME}/DownloadFile`,{
      method: "POST",
      body: JSON.stringify(tabla),
      headers:{
        'Content-Type': 'application/json'
      }
      /**BLOB PARA Q BAJE LOS PDFS, PARA OTRO TIPO DE FILE PARECE Q NO HACE FALTA -_- */
    }).then(res => res.blob())
    .then(blob =>{
      // console.log('blob', blob)
      const file = window.URL.createObjectURL(blob)
      // console.log('file', file)
      window.open(file, '', 'height=650, width=840')
    }).catch(err => console.log(err));
    
  } catch (error) {
    console.log(error)
  }
}

export const listaAreas = (req_areas) => {
  // console.log(JSON.parse(req_areas))
  if (req_areas === null) {
    return('')
  } else {
    const listaReq = JSON.parse(req_areas)
    let areas 
    for (let i = 0; i < listaReq.length; i++) {
      if (i === 0) {
        areas = listaReq[i].nombre
      } else {
        areas = areas.concat(', ', listaReq[i].nombre)
      }
    }
    // console.log(areas)
    return(areas)  
  }
}

export const listaSucursales = (req_sucursales) => {
  // console.log(JSON.parse(req_sucursales))
  if (req_sucursales === null) {
    return('')
  } else {
    const listaReq = JSON.parse(req_sucursales)
    let sucursales 
    for (let i = 0; i < listaReq.length; i++) {
      if (i === 0) {
        sucursales = listaReq[i].nombre
      } else {
        sucursales = sucursales.concat(', ', listaReq[i].nombre)
      }
    }
    return(sucursales)  
  }
}

export const listaResp = (req_res) => {
  // console.log(JSON.parse(req_consolidados))
  if (req_res === null) {
    return('')
  } else {
    const listaReq = JSON.parse(req_res)
    let areas 
    for (let i = 0; i < listaReq.length; i++) {
      if (i === 0) {
        areas = listaReq[i].nombre
      } else {
        areas = areas.concat(', ', listaReq[i].nombre)
      }
    }
    return(areas)  
  }
}


export const listaCodigos = (req_consolidados) => {
  // console.log(JSON.parse(req_consolidados))
  if (req_consolidados === null) {
    return('')
  } else {
    const listaReq = JSON.parse(req_consolidados)
    let codigos 
    for (let i = 0; i < listaReq.length; i++) {
      if (i === 0) {
        codigos = listaReq[i].codigo
      } else {
        codigos = codigos.concat(', ', listaReq[i].codigo)
      }
    }
    return(codigos)  
  }
}

export const NumerosALetras = (numero) => {
  // console.log('NumerosALetras', numero)
  if ( numero === null || isNaN(numero) ) {
    return 'CERO'
  } else {

    let data = {
      numero: numero,
      enteros: Math.floor(numero),
      decimales: (((Math.round(numero * 100)) - (Math.floor(numero) * 100))),
      letrasDecimales: ''
    }

    if (data.decimales > 0) {
      data.letrasDecimales = getCentenas(data.decimales)
    }

    if (data.enteros > 0) {
      return {enteros: getCentenas(numero), decimales: data.letrasDecimales}
    } else {
      return 'CERO'
    }
  }
}

const getCentenas = (numero) => {
  const centenas = Math.floor(numero / 100)
  const decenas = numero - (centenas * 100)
  // console.log('centena', centenas)
  // console.log('decenas c', decenas)
  switch(centenas)
    {
        case 1:
            if (decenas > 0) {
              return 'CIENTO ' + getDecenas(decenas)
            }
            else {
              return 'CIEN'
            }
        case 2: return 'DOSCIENTOS ' + getDecenas(decenas)
        case 3: return 'TRESCIENTOS ' + getDecenas(decenas)
        case 4: return 'CUATROCIENTOS ' + getDecenas(decenas)
        case 5: return 'QUINIENTOS ' + getDecenas(decenas)
        case 6: return 'SEISCIENTOS ' + getDecenas(decenas)
        case 7: return 'SETECIENTOS ' + getDecenas(decenas)
        case 8: return 'OCHOCIENTOS ' + getDecenas(decenas)
        case 9: return 'NOVECIENTOS ' + getDecenas(decenas)
    }

  return getDecenas(decenas)
}

const getDecenas = (numero) => {
  // console.log('getDecenas', numero)
  const decena = Math.floor(numero/10)
  const unidad = numero - (decena * 10)
  // console.log('decena d', decena)
  // console.log('unidad d', unidad)

  // console.log(decena, ' ... ', unidad)
  switch(decena)
    {
        case 1:
            switch(parseInt(unidad))
            {
                case 0: return 'DIEZ'
                case 1: return 'ONCE'
                case 2: return 'DOCE'
                case 3: return 'TRECE'
                case 4: return 'CATORCE'
                case 5: return 'QUINCE'
                default: return 'DIECI' + getUnidades(unidad)
            }
        case 2:
            switch(unidad)
            {
                case 0: return 'VEINTE'
                default: return 'VEINTI' + getUnidades(unidad)
            }
        case 3: return getDecenasY('TREINTA', unidad)
        case 4: return getDecenasY('CUARENTA', unidad)
        case 5: return getDecenasY('CINCUENTA', unidad)
        case 6: return getDecenasY('SESENTA', unidad)
        case 7: return getDecenasY('SETENTA', unidad)
        case 8: return getDecenasY('OCHENTA', unidad)
        case 9: return getDecenasY('NOVENTA', unidad)
        case 0: return getUnidades(unidad);
    }
}

const getDecenasY = (strSin, numero) => {
  if (numero > 0) {
    return strSin + ' Y ' + getUnidades(numero)
  } else {
    return strSin
  }
}

const getUnidades = (numero) => {
  // console.log('getUnidades', numero)
  switch (parseInt(numero)) {
    case 1: return 'UNO'
    case 2: return 'DOS'
    case 3: return 'TRES'
    case 4: return 'CUATRO'
    case 5: return 'CINCO'
    case 6: return 'SEIS'
    case 7: return 'SIETE'
    case 8: return 'OCHO'
    case 9: return 'NUEVE'
    default: return ''
  }
}