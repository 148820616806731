import moment from "moment";
import {PDFExport} from '@progress/kendo-react-pdf'
import { Table } from "antd";

const columnsPDFReq = (estado) => {
  let columnsRequerimientos = [
    {
      title: '',
      dataIndex: 'index',
      key: 'index',
      width: 50
    },
    {
      title: 'FECHA',
      dataIndex: 'fecha',
      key: 'fecha',
      render: text => (
        moment.utc(text).format('DD/MM/YY')
      )
    },
    {
      title: 'CODIGO',
      dataIndex: 'codigo',
      key: 'codigo'
    },
    {
      title: 'AREA',
      dataIndex: 'nombre_area',
      key: 'nombre_area'
    },
    {
      title: 'RESPONSABLE',
      dataIndex: 'nombre_responsable',
      key: 'nombre_responsable'
    },
    {
      title: 'PRIORIDAD',
      dataIndex: 'prioridad',
      key: 'prioridad',
      render : text => (
        text === null? "PENDIENTE": text
      )
    },
    {
      title: 'TIPO',
      dataIndex: 'tipo',
      key: 'tipo',
      render : text => (
        text === null? "PENDIENTE": text
      )
    }
  ]  

  if (estado === undefined) {
    columnsRequerimientos = [...columnsRequerimientos,
      {
        title: 'ESTADO',
        dataIndex: 'estado',
        key: 'estado'
      }
    ]
  }

  columnsRequerimientos = [...columnsRequerimientos,
    {
      title: 'APROBADO',
      dataIndex: 'aprobado',
      key: 'aprobado',
      render: text => (
        text === null?'PENDIENTE': text
      )
    },
    {
      title: 'FECHA ENTREGA',
      dataIndex: 'fecha_entrega',
      key: 'fecha_entrega',
      render: text => (
        text === null?'PENDIENTE':moment.utc(text).format('DD/MM/YY')
      )
    }
  ]

  return columnsRequerimientos
}

const PageTemplate = (props) => {
  return (
    <div style={{ position: "absolute", bottom: "10px", left: "50%" }}>
      Pag. {props.pageNum} de {props.totalPages}
    </div>
  );
};

export const PdfFileRequerimiento = (componentePDF, requerimientosListFiltrados, fechaInicio, fechaFin, estado) => {
    // console.log(requerimientosListFiltrados, fechaInicio, fechaFin, estado)
  return(
      <>
        <div style={{ position: "absolute", left: "-4000px", top: 0 }}>
          <div className='row'>
            <PDFExport 
              ref={componentePDF} 
              paperSize='A4' 
              margin="1cm" 
              landscape={true}
              pageTemplate={PageTemplate}
              fileName='Requerimientos'
              scale={0.7}
              >

              <Table
                pagination={false}
                columns={columnsPDFReq(estado)}
                dataSource={requerimientosListFiltrados}
                size='small'
              />
            </PDFExport>
          </div>
        </div>
      </>
    )
}