import { useEffect, useState } from 'react';
import { Badge, Dropdown, Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import { INotification } from '../../../interfaces/notification';
import moment from "moment";

let notificaciones = []

type Props = {
  data?: INotification[];
};

const homeRoute = '/vertical/requerimientos';

const Notifications = ({ data = notificaciones }: Props) => {
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setNotifications(data);
    getRequerimientosPendientes()
  }, [data]);

  const handleClearAll = () => setNotifications([]);

  const getRequerimientosPendientes = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getRequerimientosPendientes/${1}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          setNotifications([])
        } else {
          let lista = []
          for (let i = 0; i < data.length; i++) {
            lista = [...lista, {
              text: `${data[i].codigo}`,
              icon: 'icofont-calendar',
              time: `Fecha de Entrega: ${data[i].fecha_entrega === null?'PENDIENTE':
                moment.utc(data[i].fecha_entrega).format('YYYY-MM-DD')}`
            }]
          }
          setNotifications(lista)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const notificationsMenu = (
    <Menu className='action-menu' style={{ minWidth: '400px' }}>
      <span className='dropdown-header'>
        <h3 className='dropdown-title'>Requerimientos Pendientes</h3>

        <a onClick={handleClearAll} className='text-danger'>
          Limpiar Lista
        </a>
      </span>

      {notifications.length > 0?
        notifications.map((item, index) => (
          <Menu.Item className='action-item' key={index}>
            <NavLink className='d-flex w-100' to={homeRoute} >
              <span className={`icon mr-3 ${item.icon}`} />
              <span className='text'>
                <span className='message'>{item.text}</span>
                <span className='sub-text'>{item.time}</span>
              </span>
            </NavLink>
          </Menu.Item>
        )):<span className='empty-item'>Sin notificationes</span>}


    </Menu>
  );
  return (
    <Dropdown
      className='mr-3'
      overlay={notificationsMenu}
      trigger={['click']}
      visible={visible}
      onVisibleChange={setVisible}
      placement='bottomRight'
      overlayStyle={{overflowY:'scroll', maxHeight:'280px'}}
    >
      <Badge className='action-badge' count={notifications.length}>
        <span
          className={`notification-icon icofont-notification ${
            visible ? 'active' : null
          }`}
          style={{ fontSize: '22px', cursor: 'pointer' }}
        />
      </Badge>
    </Dropdown>
  );
};

export default Notifications;
