import { CloseOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Input, Form, Modal, message, Select } from 'antd';
import { Rule } from 'antd/lib/form';
import { useState, useEffect, useRef } from "react";
import { Option } from "antd/lib/mentions";
import moment from "moment";

const { Item } = Form;

const rules = {
  required: { required: true, message: <></> } as Rule
};

const ModalValeCombustible = (props) => {
  const [loadingButton, setLoadingButton] = useState(false)
  const [vale, setVale] = useState({
    id_vale: null,
    fecha_vale: moment().format('YYYY-MM-DD'),
    num_vale: null,
    kilometraje: null,
    ubicacion_archivo: null,
    ubicacion_archivo_vale: null,
    id_vehiculo: null,
    id_conductor: null,
    id_combustible: null
  })

  const optionFilter = (input, option) => {
    return (option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0)
  }

  const input = useRef<HTMLInputElement>(null);
  const handleClick = () => input.current.click();

  const inputvale = useRef<HTMLInputElement>(null);
  const handleClickvale = () => inputvale.current.click();

  useEffect(() => {
    if (props.openModal === true) {
      // console.log(props.vale)
      setVale(props.vale)
    } else {
      setVale({
        id_vale: null,
        fecha_vale: moment().format('YYYY-MM-DD'),
        num_vale: null,
        kilometraje: null,
        ubicacion_archivo: null,
        ubicacion_archivo_vale: null,
        id_vehiculo: null,
        id_conductor: null,
        id_combustible: null
      })
    }
  }, [props.vale]);

  const handleCloseModal = () => {
    props.setOpenModal(false)
    setVale({
      id_vale: null,
      fecha_vale: moment().format('YYYY-MM-DD'),
      num_vale: null,
      kilometraje: null,
      ubicacion_archivo: null,
      ubicacion_archivo_vale: null,
      id_vehiculo: null,
      id_conductor: null,
      id_combustible: null
    })
  }

  const AddVale = async () => {
    // setLoadingButton(true)
    const newVale = {...vale, usuario: props.usuario}
    if (vale.ubicacion_archivo === null || vale.ubicacion_archivo_vale === null) {
      message.error('Faltan archivos')
    } else {
      if (vale.ubicacion_archivo[0] === undefined || vale.ubicacion_archivo_vale[0] === undefined) {
        message.error('Faltan archivos')
      } else {
        try {
          await fetch(`${process.env.REACT_APP_HOSTNAME}/AddVale`,{
            method: "POST",
            body: JSON.stringify(newVale),
            headers:{
              'Content-Type': 'application/json'
            }
          }).then(res => res.json())
          .then(data => {
            if (data.id_vale !== undefined) {
              UploadFileValeCombustible(data.id_vale)
            }
          }).catch(err => console.log(err));
        } catch (error) {
          console.log(error)
        }
      }
    }
    
  }

  const UploadFileValeCombustible = async(id_vale) => {
    const body = {
      destination:'Logistica',
      id_vale: id_vale,
      varios: true
    }
    const bodyForm = new FormData()
    
    bodyForm.append('tabla', JSON.stringify(body))
    const file = vale.ubicacion_archivo[0];
    const filename = file['name']
    const fileExtension = filename.split('.').pop()
    bodyForm.append('File', vale.ubicacion_archivo[0], `Vale_${id_vale}_${0}.${fileExtension}`)
    const file2 = vale.ubicacion_archivo[0];
    const filename2 = file2['name']
    const fileExtension2 = filename2.split('.').pop()
    bodyForm.append('File', vale.ubicacion_archivo_vale[0], `Vale_${id_vale}_${1}.${fileExtension2}`)

    setLoadingButton(true)

    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/UploadFileValeCombustible`,{
        method: "POST",
        body: bodyForm
      }).then(res => {
        // message.success("Requerimiento Registrado")
        setLoadingButton(false)
        props.GetVales()
        handleCloseModal()
      }).catch(err => {
        console.log(err)
        message.error('No se Pudo Subir el Archivo al Servidor')
        setLoadingButton(false)
      });
    } catch (error) {
      console.log(error)
      message.error('No se Pudo Subir el Archivo al Servidor')
      setLoadingButton(false)
    }
  }

  const EditVale = async () => {
    setLoadingButton(true)
    // console.log(vale)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/UpdateVale`,{
        method: "PUT",
        body: JSON.stringify(vale),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data => {
        message.success(data.message)
        setLoadingButton(false)
        props.GetVales()
        handleCloseModal()
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error)
    }
  }

  const subirArchivo = (e) => {
    console.log(e[0].name)
    setVale({...vale, ubicacion_archivo: e})
  }

  const subirArchivoVale = (e) => {
    console.log(e[0].name)
    setVale({...vale, ubicacion_archivo_vale: e})
  }

  const filtrarConductores = () => {
    if (props.rolLogistica === true) {
      return props.conductoresList
    } else {
      const listaConductByUser = props.conductoresList.filter(conductor => {
        return ( conductor.DocumentoIdentidad === props.user.DocumentoIdentidad )
      })

      let listaFinalConductores = []
      for (let i = 0; i < props.conductoresList.length; i++) {
        const element = listaConductByUser.filter(lis =>{
          return (props.conductoresList[i].id_area === lis.id_area)
        });
        if (element.length > 0) {
          listaFinalConductores = [...listaFinalConductores, props.conductoresList[i]]
        }
      }
      return listaConductByUser
    }
  }

  const filtrarVehiculos = () => {
    if (props.rolLogistica === true) {
      return props.vehiculosList
    } else {
      const listaConductByUser = props.conductoresList.filter(conductor => {
        return ( conductor.DocumentoIdentidad === props.user.DocumentoIdentidad )
      })

      if (listaConductByUser.length > 0) {
        const listaFinalVehiculos = props.totalChoferes.filter(chofer => {
          return (chofer.id_conductor === listaConductByUser[0].id_conductor)
        })

        return listaFinalVehiculos  
      } else {
        return []
      }
    }
  }

  return(
    <>
      <Modal
        visible={props.openModal}
        closable={false}
        width='80%'
        title={<h3 className='m-0'>{vale.id_vale === null?'Nuevo Vale':'Editar Vale'}</h3>}
        onCancel={handleCloseModal}
        footer={null}
      >
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <Form form={props.form} layout='vertical' onFinish={ vale.id_vale === null? AddVale : EditVale}>
              <div className='elem-list'>
                <div className='col-sm-11 col-md-1 col-lg-1'>
                  <Item label="Fecha" name="fecha_vale">
                    <b>{moment(vale.fecha_vale).format('DD/MM/YY')}</b>
                  </Item>
                </div>

                <div className='col-sm-11 col-md-3 col-lg-3'>
                  <Item label="Vehículo" name="id_vehiculo" rules ={[
                    {...rules.required, message: 'El Vehículo es Requerido'}
                  ]}>
                    <Select
                      showSearch
                      defaultValue={vale.id_vehiculo}
                      className='mb-lg-0'
                      placeholder='Seleccionar Vehículo'
                      optionFilterProp='children'
                      filterOption={optionFilter}
                      onChange={e=>setVale({
                        ...vale, id_vehiculo: e
                      })}
                    >
                    {/*props.vehiculosList*/filtrarVehiculos().map(vehiculo =>{
                      return(
                        <Option value={vehiculo.id_vehiculo} key={vehiculo.id_vehiculo}>{vehiculo.nombre} - {vehiculo.placa}</Option>
                      )
                    })}
                    </Select>
                  </Item>
                </div>

                <div className='col-sm-11 col-md-4 col-lg-4'>
                  <Item label="Conductor" name="id_conductor" rules ={[
                    {...rules.required, message: 'El Conductor es Requerido'}
                  ]}>
                    <Select
                      showSearch
                      defaultValue={vale.id_conductor}
                      className='mb-lg-0'
                      placeholder='Seleccionar Conductor'
                      optionFilterProp='children'
                      filterOption={optionFilter}
                      onChange={e=>setVale({
                        ...vale, id_conductor: e
                      })}
                    >
                    {/*props.conductoresList*/filtrarConductores().map(conductor =>{
                      return(
                        <Option value={conductor.id_conductor} key={conductor.id_conductor}>{conductor.nombres} {conductor.apellidos}</Option>
                      )
                    })}
                    </Select>
                  </Item>
                </div>

                <div className='col-sm-11 col-md-3 col-lg-3'>
                  <Item label="Combustible" name="id_combustible" rules ={[
                    {...rules.required, message: 'El Combustible es Requerido'}
                  ]}>
                    <Select
                      showSearch
                      defaultValue={vale.id_combustible}
                      className='mb-lg-0'
                      placeholder='Seleccionar Combustible'
                      optionFilterProp='children'
                      filterOption={optionFilter}
                      onChange={e=>setVale({
                        ...vale, id_combustible: e
                      })}
                    >
                    {props.combustiblesList.map(combustible =>{
                      return(
                        <Option value={combustible.id_combustible} key={combustible.id_combustible}>{combustible.nombre}</Option>
                      )
                    })}
                    </Select>
                  </Item>
                </div>
              </div>

              <div className='elem-list'>
                <div className='col-sm-11 col-md-3 col-lg-3'>
                  <Item label="N° Vale" name="num_vale" rules ={[
                    {...rules.required, message: 'El núm. de Vale es Requerido'}
                  ]}>
                    <Input defaultValue={vale.num_vale} 
                      onChange={e=>setVale({
                        ...vale, num_vale:e.currentTarget.value
                      })}
                        placeholder = "N° Vale"
                    />
                  </Item>
                </div>

                <div className='col-sm-11 col-md-2 col-lg-2'>
                  <b >{vale.ubicacion_archivo_vale === null?null:
                    vale.ubicacion_archivo_vale[0] === undefined?null:vale.ubicacion_archivo_vale[0].name}</b>
                  <br/>
                  <input ref={inputvale} onChange={(e) => subirArchivoVale(e.target.files)} type='file' style={{ display: 'none' }} />
                  {vale.id_vale === null?
                  <Button size='small' type={'primary'} className='btn-outline mr-2' onClick={handleClickvale} icon={<UploadOutlined />}>
                    Foto del Vale
                  </Button>
                  :null}
                </div>

                <div className='col-sm-11 col-md-3 col-lg-3'>
                  <Item label="Kilometraje" name="kilometraje" rules ={[
                    {...rules.required, message: 'El Kilometraje es Requerido'}
                  ]}>
                    <Input defaultValue={vale.kilometraje} 
                      onChange={e=>setVale({
                        ...vale, kilometraje:e.currentTarget.value
                      })}
                        placeholder = "Kilometraje" type="number"
                    />
                  </Item>
                </div>

                <div className='col-sm-11 col-md-2 col-lg-2'>
                  <b >{vale.ubicacion_archivo === null?null:
                    vale.ubicacion_archivo[0] === undefined?null:vale.ubicacion_archivo[0].name}</b>
                  <br/>
                  <input ref={input} onChange={(e) => subirArchivo(e.target.files)} type='file' style={{ display: 'none' }} />
                  {vale.id_vale === null?
                  <Button size='small' type={'primary'} className='btn-outline mr-2' onClick={handleClick} icon={<UploadOutlined />}>
                    Foto del Kilometraje
                  </Button>
                  :null}
                </div>
              </div>

              <div className='modal-footer d-flex justify-content-between'>
                <Button danger onClick={handleCloseModal} icon={<CloseOutlined />}>
                  Cerrar
                </Button>
                
                <Button className='bg-color-success' loading={loadingButton}
                  type='primary' icon={<SaveOutlined />} htmlType="submit">
                  Guardar
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  )

}

export default ModalValeCombustible