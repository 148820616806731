import { Table, Card, Tag } from 'antd';
import moment from "moment";

const OrdenesCompraByReq = (props) => {

  const columns = [
    {
      title: 'FECHA',
      key: 'fecha_orden_compra',
      dataIndex: 'fecha_orden_compra',
      render: text => (
        moment.utc(text).format('DD/MM/YY')
      )
    },
    {
      title: 'N° ORDEN COMPRA',
      key: 'numero_orden',
      dataIndex: 'numero_orden'
    },
    {
      title: 'PROVEEDOR',
      key: 'descripcion',
      dataIndex: 'descripcion'
    },
    {
      title: 'ESTADO',
      key: 'estado',
      dataIndex: 'estado',
      render : text => {
        switch (text) {
          case 'PENDIENTE':
            return(<Tag color={'#336cfb'}>PENDIENTE</Tag>)

          case 'ACEPTADA':
            return(<Tag color={'#B7CE63'}>ACEPTADA</Tag>)
          
          case 'FACTURADA':
            return(<Tag color={'#B7CE63'}>FACTURADA</Tag>)
            
          case 'DESPACHADA':
            return(<Tag color={'#B7CE63'}>DESPACHADA</Tag>)

          case 'ESPERA DE CONFORMIDAD':
            return(<Tag color={'#FC5F1B'}>ESPERA DE CONFORMIDAD</Tag>)

          case 'FINALIZADA':
            return(<Tag color={'#B7CE63'}>FINALIZADA</Tag>)
          
          case 'RECHAZADA':
            return(<Tag color={'#AA0404'}>RECHAZADA</Tag>)

            default:
            break;
        }
      },
    },
    {
      title: 'TIPO CAMBIO',
      key: 'TipCambio',
      dataIndex: 'TipCambio'
    },
    {
      title: 'TOTAL',
      render: record => {
        return(
          <>
            {record.Parc_Valor} {record.total.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </>
        )
      }
    }
  ]

  const subTabla = (orden_compra) =>{
    const subcolumns = [
      { title: 'MATERIAL/EQUIPO', key: 'nombre_articulo', dataIndex: 'nombre_articulo',
        render: text => {
          return{
            props: {
              style: { background: '#C0E8FF' },
            },
            children: <div>{text}</div>,

          }
        } 
      },
      { title: 'DESCRIPCIÓN', key: 'descripcion', dataIndex: 'descripcion',
        render: text => {
          return{
            props: {
              style: { background: '#C0E8FF' }
            },
            children: <div>{text}</div>,
          }
        }
      },
      { title: 'MARCA', key: 'marca', dataIndex: 'marca',
        render: text => {
          return{
            props: {
              style: { background: '#C0E8FF' }
            },
            children: <div>{text}</div>
          }
        }
      },
      { title: 'PRESENTACIÓN',
        render: record => {
          return{
            props: {
              style: { background: '#C0E8FF' }
            },
            children: <div>{record.nombre_unidad} X {record.cantidad_unidades}</div>,
          }
        }
      },
      { title: 'CANTIDAD', key: 'cantidad', dataIndex: 'cantidad',
        render: text => {
          return{
            props: {
              style: { background: '#C0E8FF' }
            },
            children: <div>{text.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
          }
        }
      },
      { title: 'PRECIO',
        render: record => {
          return{
            props: {
              style: { background: '#C0E8FF' }
            },
            children: 
              <div>
                {orden_compra.Parc_Valor} {record.precio.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              </div>
          }
        }
      },
      {
        title: 'TOTAL',
        key: '',
        render: record => {
          return{
            props: {
              style: { background: '#C0E8FF' }
            },
            children: 
              <div>
                {orden_compra.Parc_Valor} 
                {(record.cantidad * record.precio).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              </div>
          }
        }
      }
    ]

    const detalleOrden = props.detOrdenesCompraByReq.filter( detalle => {
      return detalle.id_orden_compra === orden_compra.id_orden_compra
    })

    return <Table columns={subcolumns} dataSource={detalleOrden} pagination={false} />
  }

  const Total = (listaOrdenes) => {
    return(
      <>
        <div className='d-flex justify-content-between'>
          <b>Total</b>
          <b className='mr-4'>
            {listaOrdenes[0].Parc_Valor} {listaOrdenes.map(det => det.total).reduce((prev, next) => prev + next)
            .toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </b>
        </div>
      </>
    )
  }

  if (props.ordenesCompraByReq.length > 0) {
    return(
      <>
        <div className='col-sm-12 col-md-12 col-lg-12'>
          <Card title="Órdenes de Compra">
            <Table 
              pagination={false} 
              columns={columns}
              dataSource={props.ordenesCompraByReq}
              size="small"
              rowKey={record => record.id_orden_compra}
              expandedRowRender = { record => subTabla(record)}
              footer={() => Total(props.ordenesCompraByReq)}
            />
          </Card>
        </div>
      </>
    )
  } else {
    return(
      <>
        <div>
          No hay Órdenes de Compra
        </div>
      </>
    )
  }
}

export default OrdenesCompraByReq