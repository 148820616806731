import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Input, Modal, message, Form, Checkbox } from 'antd';
import { Rule } from 'antd/lib/form';
import { useState, useEffect } from "react";

const { Item } = Form;

const rules = {
  required: { required: true, message: <></> } as Rule
};

const ModalRubroForm = (props) => {
  const [loadingButton, setLoadingButton] = useState(false)
  const [rubro, setRubro] = useState({
    nombre: null,
    codigo: null,
    checkInventario: false
  })

  const [form] = Form.useForm();

  const handleCloseModal = () => {
    props.setOpenModalRubro(false)
  }

  useEffect(() => {
    setRubro({
      nombre: null,
      codigo: null,
      checkInventario: false
    })
    form.setFieldsValue({
      nombre: null,
      codigo: null,
      checkInventario: false
    })
  }, [props.openModalRubro])

  const AddRubro = async () => {
    
    const rubroRepetido = props.rubrosList.filter(item => {
      return item.codigo === rubro.codigo.toUpperCase() || item.nombre === rubro.nombre.toUpperCase()
    })
    // console.log(rubroRepetido)
    if (rubroRepetido.length > 0) {
      for (let i = 0; i < rubroRepetido.length; i++) {
        message.warn(`El Nombre o Código ya es encuentra Registrado: ${rubroRepetido[i].nombre} - ${rubroRepetido[i].codigo}`)
      }
    } else {
      try {
        setLoadingButton(true)
        await fetch(`${process.env.REACT_APP_HOSTNAME}/AddRubro`,{
          method: "POST",
          body: JSON.stringify({...rubro, usuario: props.usuario}),
          headers:{
            'Content-Type': 'application/json'
          }
        }).then(res => res.json())
        .then(data =>{
          message.success(data.mensaje)
          setLoadingButton(false)
          props.getRubros()
          handleCloseModal()
        }).catch(err => {
          console.log(err)
          setLoadingButton(false)
        })
      } catch (error) {
        setLoadingButton(false)
        console.log(error)
      }  
    }
  }

  return(
    <>
      <Modal
        visible={props.openModalRubro}
        closable={false}
        width='60%'
        title={<h3 className='m-0'>Nuevo Rubro</h3>}
        onCancel={handleCloseModal}
        footer={null}
      >
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <Form form={form} layout='vertical' onFinish={AddRubro}>
              <div className='elem-list mb-2'>
                <div className='col-sm-11 col-md-5 col-lg-5'>
                  <Item label="Nombre" name="nombre" rules = {[
                    {...rules.required, message: 'El Nombre es Requerido'}
                  ]}>
                    <Input defaultValue={rubro.nombre} 
                      onChange={e=>setRubro({
                        ...rubro, nombre:e.currentTarget.value
                      })}
                       placeholder = "Nombre"
                    />
                  </Item>
                </div>

                <div className='col-sm-5 col-md-3 col-lg-3'>
                  <Item label="Código" name="codigo" rules={[
                    {...rules.required, message: 'Código Requerido'},
                    { min: 2, max: 2, message: 'El Código debe tener 2 caracteres' }
                  ]}>
                    <Input defaultValue={rubro.codigo} 
                      onChange={e=>setRubro({
                        ...rubro, codigo:e.currentTarget.value
                      })}
                       placeholder = "Código"
                    />
                  </Item>
                </div>

                <div className='col-sm-5 col-md-3 col-lg-3'>
                  <Item label="Inventario" name="checkInventario">
                    <Checkbox checked={rubro.checkInventario}
                      onChange={e=>setRubro({
                        ...rubro, checkInventario: e.target.checked
                      })}/>
                  </Item>
                </div>
              </div>

              <div className='modal-footer d-flex justify-content-between'>
                <Button danger onClick={handleCloseModal} icon={<CloseOutlined />}>
                  Cerrar
                </Button>
                
                <Button className='bg-color-success' loading={loadingButton}
                  type='primary' icon={<SaveOutlined />} htmlType="submit">
                  Guardar
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  )

}

export default ModalRubroForm