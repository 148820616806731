import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Input, Form, Modal, message } from 'antd';
import { Rule } from 'antd/lib/form';
import {useState, useEffect} from "react";

const { Item } = Form;

const rules = {
  required: { required: true, message: <></> } as Rule
};

const ModalCombustible = (props) => {
  const [loadingButton, setLoadingButton] = useState(false)
  const [combustible, setCombustible] = useState({
    id_combustible: null,
    nombre: null
  })

  useEffect(() => {
    if (props.openModal === true) {
      // console.log(props.combustible)
      setCombustible(props.combustible)
    } else {
      setCombustible({
        id_combustible: null,
        nombre: null
      })
    }
  }, [props.combustible]);

  const handleCloseModal = () => {
    props.setOpenModal(false)
    setCombustible({
      id_combustible: null,
      nombre: null
    })
  }

  const AddCombustible = async () => {
    setLoadingButton(true)
    const newCombustible = {...combustible, usuario: props.usuario}
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/AddCombustible`,{
        method: "POST",
        body: JSON.stringify(newCombustible),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data => {
        message.success(data.message)
        setLoadingButton(false)
        props.GetCombustibles()
        handleCloseModal()
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error)
    }  
  }

  const EditCombustible = async () => {
    setLoadingButton(true)
    // console.log(combustible)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/UpdateCombustible`,{
        method: "PUT",
        body: JSON.stringify(combustible),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data => {
        message.success(data.message)
        setLoadingButton(false)
        props.GetCombustibles()
        handleCloseModal()
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error)
    }
  }

  return(
    <>
      <Modal
        visible={props.openModal}
        closable={false}
        width='80%'
        title={<h3 className='m-0'>{combustible.id_combustible === null?'Nuevo Combustible':'Editar Combustible'}</h3>}
        onCancel={handleCloseModal}
        footer={null}
      >
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <Form form={props.form} layout='vertical' onFinish={ combustible.id_combustible === null? AddCombustible : EditCombustible}>
              <div className='elem-list mb-4'>
                <div className='col-sm-11 col-md-4 col-lg-4'>
                  <Item label="Nombre" name="nombre" rules ={[
                    {...rules.required, message: 'El Nombre es Requerido'}
                  ]}>
                    <Input defaultValue={combustible.nombre} 
                      onChange={e=>setCombustible({
                        ...combustible, nombre:e.currentTarget.value
                      })}
                        placeholder = "Nombre"
                    />
                  </Item>
                </div>
              </div>

              <div className='modal-footer d-flex justify-content-between'>
                <Button danger onClick={handleCloseModal} icon={<CloseOutlined />}>
                  Cerrar
                </Button>
                
                <Button className='bg-color-success' loading={loadingButton}
                  type='primary' icon={<SaveOutlined />} htmlType="submit">
                  Guardar
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  )

}

export default ModalCombustible