import { CheckOutlined, CloseOutlined, DownloadOutlined, EyeOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Button, Modal, Table, Card, 
  Popconfirm, message } from 'antd';
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import { DownloadFile, existeRol, getTipoCambioToday, PreviewFile } from '../FuncionesGenerales';
import FormularioCotizacion from '../componentesCotizaciones/FormularioCotizacion';
import FormularioRevisionReq from './FormularioRevisionReq';
import CotizacionesByReq from '../componentesCotizaciones/CotizacionesByReq';
import OrdenesCompraByReq from '../componentesOrdenesCompras/OrdenesCompraByReq';
import ModalTipoCambio from '../ModalTipoCambio';
import { PdfFileItemsReq } from '../componentesPDF/GenerarPDFItemsReq';

const ModalDetalleRequerimiento = (props) => {
  const [pestaña, setPestaña] = useState('detalle');
  const [rolFinanzas, setRolFinanzas] = useState(false);
  const [permisoAprobarLogistica, setpermisoAprobarLogistica] = useState(false);
  const [permisoAprobarGerencia, setpermisoAprobarGerencia] = useState(false);
  const [cotizacionesByReq, setCotizacionesByReq] = useState([])
  const [detCotizacionesByReq, setDetCotizacionesByReq] = useState([])
  const [detalleReqList, setDetalleReqList] = useState([])
  const [detalleCotList, setDetalleCotList] = useState([])
  const [imgReq, setImgReq] = useState([])
  const [ordenesCompraByReq, setOrdenesCompraByReq] = useState([])
  const [detOrdenesCompraByReq, setDetOrdenesCompraByReq] = useState([])
  const [tipoCambioHoy, setTipoCambioHoy] = useState({TipCambio: 1})
  const [openModalTipoCambio, setOpenModalTipoCambio] = useState(false);
  const [nombreCotizacionForm, setNombreCotizacionForm] = useState('Nueva Cotización');
  const componentePDF = useRef(null)

  useEffect(() => {
    if (props.openModal === true) {
      getCotizacionesByRequerimiento(props.requerimiento.id_requerimiento)
      getDetalleCotizacionByReq(props.requerimiento.id_requerimiento)
      getDetalleByIdRequerimiento(props.requerimiento.id_requerimiento)
      getImgByRequerimientoId(props.requerimiento.id_requerimiento)
      getOrdenesCompraByRequerimiento(props.requerimiento.id_requerimiento)
      getDetalleOrdenesByReq(props.requerimiento.id_requerimiento)
      if (props.rolLogistica === true || existeRol('Finanzas.Aprobar.Requerimiento') === true) {
        getCambioDolar()
      }
      setPestaña('detalle')
      setRolFinanzas(existeRol('Finanzas.Aprobar.Requerimiento'))
      setpermisoAprobarLogistica(existeRol('Logistica.Aprobar.Cotizacion'))
      setpermisoAprobarGerencia(existeRol('Gerencia.Aprobar.Cotizacion'))
      setNombreCotizacionForm('Nueva Cotización')
    }
  }, [props.openModal]);

  const onTabChange = (setter: (val: string) => void) => (key: string) => {
    setter(key);
  };

  const optionFilter = (input, option) => {
    return (option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0)
  }

  const getCambioDolar = async() => {
    const cambioToday = await getTipoCambioToday()
    // console.log(cambioToday)
    if (cambioToday.message) {
      message.warning("No se ha Registrado el tipo de cambio de hoy", 5)
      handleOpenModalTipoCambio()
    } else {
      setTipoCambioHoy({TipCambio: cambioToday[0].TipCambio})
    }
  }

  const handleOpenModalTipoCambio = () => {
    setOpenModalTipoCambio(true)
  }

  const handleCloseModalTipoCambio = async() => {
    setOpenModalTipoCambio(false)
  }

  const getCotizacionesByRequerimiento = async(id_requerimiento) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getCotizacionesByRequerimiento/${id_requerimiento}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setCotizacionesByReq(data)
      })
    } catch (error) {
      console.log(error)
    }
  }
  
  const getOrdenesCompraByRequerimiento = async(id_requerimiento) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getOrdenesCompraByRequerimiento/${id_requerimiento}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setOrdenesCompraByReq(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getDetalleCotizacionByReq = async(id_requerimiento) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getDetalleCotizacionByReq/${id_requerimiento}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setDetCotizacionesByReq(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getDetalleOrdenesByReq = async(id_requerimiento) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getDetalleOrdenesByReq/${id_requerimiento}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setDetOrdenesCompraByReq(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getDetalleByIdRequerimiento = async(id_requerimiento) => {
    // console.log(id_requerimiento)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getDetalleByIdRequerimiento/${id_requerimiento}`)
      .then(res => res.json())
      .then(data => {
        let datos = []

        for (let i = 0; i < data.length; i++) {
          datos = [...datos,{...data[i], index: i+1}]
        }

        setDetalleReqList(datos)
        // console.log(data)
        let lista = []
        for (let i = 0; i < data.length; i++) {
          lista = [...lista,{
            id_cotizacion: null,
            id_unidad: data[i].id_unidad,
            cantidad: data[i].cantidad,
            precio_unitario: 1,
            id_articulo: data[i].id_articulo,
            descripcion: data[i].descripcion,
            marca: null,
            cantidad_unidades: data[i].cantidad_unidades
          }]
        }

        setDetalleCotList(lista)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getImgByRequerimientoId = async(id_requerimiento) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getImgByRequerimientoId/${id_requerimiento}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          setImgReq([])
        } else {
          setImgReq(data)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  let pestañasList = [
    { key: 'detalle', tab: 'Datos' }
  ]

  if ( props.rolLogistica === true ) {
    pestañasList = [...pestañasList,
      { key: 'revision', tab: 'Análisis' }
    ]
  }

  if ( props.rolLogistica === true ) {
    pestañasList = [...pestañasList,
      { key: 'cotizacion', tab: `${nombreCotizacionForm}` }
    ]
  }

  // if (props.rolLogistica === true || rolFinanzas === true) {
    pestañasList = [...pestañasList,
      { key: 'listaCotizaciones', tab: 'Cotizaciones' }
    ]
  // }

  if (props.rolLogistica === true || rolFinanzas === true) {
    pestañasList = [...pestañasList,
      { key: 'listaOrdenes', tab: 'Órdenes de Compra' }
    ]
  }

  const getStock = (item) => {
    const stock = props.materialesList.filter( articulo => {
      return( articulo.id_articulo === item.id_articulo)
    })

    return stock[0].stock_disponible
  }

  const columns = [
    { title: 'MATERIAL/EQUIPO', key: 'nombre_material', dataIndex: 'nombre_material' },
    { title: 'DESCRIPCION', key: 'descripcion', dataIndex: 'descripcion' },
    { 
      title: 'PRESENTACIÓN', 
      render : record => {
        return(<>{record.nombre_unidad} X {record.cantidad_unidades}</>)
      }
    },
    { title: 'CANTIDAD', key: 'cantidad', dataIndex: 'cantidad' },
    { 
      title: 'STOCK', 
      render: record => {
        return(<>{getStock(record)}</>)
      }
    }
  ]

  const columnsServ = [{ title: 'SERVICIO SOLICITADO', key: 'descripcion', dataIndex: 'descripcion' }]

  const ExportarPDF = () => {
    if (detalleReqList.length > 0) {
      componentePDF.current.save()
    }
  }

  const contenidoPestañas = {
    detalle:
      <>
        {PdfFileItemsReq(componentePDF, detalleReqList, props.requerimiento)}
        <Button danger 
          title="Exportar PDF" className='mr-2'
          onClick={e => ExportarPDF()}
          icon={<FilePdfOutlined />} >PDF
        </Button>  

        <Table 
          pagination={false} 
          columns={props.requerimiento.tipo === 'COMPRA' || props.requerimiento.tipo === 'ATENCION'? columns: columnsServ}
          dataSource={detalleReqList}
          size="small"
        />

        <div className='col-sm-12 col-md-12 col-lg-12 mt-4 mb-2'>
          <b style={{fontSize:"18px"}}>Necesidad o Justificación:</b>
          <p style={{fontSize:"18px"}}>{props.requerimiento.comentario}</p>
        </div>

        {imgReq.length > 0? 
          <>
            <div className='col-sm-12 col-md-12 col-lg-12'>
              <b style={{fontSize:"18px"}} className="mr-2">Archivos Adjuntos:</b>
            </div>

            <div className='elem-list'>
              {imgReq.map((img, index) => {
                return(
                  <div className='col-sm-2 col-md-2 col-lg-2'>
                    <b>Archivo { index + 1 }:</b>
                    <Button className='bg-color-success mr-1 ml-1' 
                      title="Ver Archivo" 
                      onClick={e => PreviewFile(img)}
                      icon={<EyeOutlined />}
                    />
                    <Button type={'primary'} 
                      title="Descargar Archivo" 
                      onClick={e => DownloadFile(`Archivo_Requerimiento_${img.id_requerimiento}`, img, index)}
                      icon={<DownloadOutlined />} 
                    />  
                  </div>
                )
              })}
            </div>
          </>
        :null}
      </>,
    revision: 
      <>
        <FormularioRevisionReq
          setLoadingButtonAprobar = {props.setLoadingButtonAprobar}
          requerimiento = {props.requerimiento}
          getRequerimientos = {props.getRequerimientos}
          handleCloseModal = {props.handleCloseModal}
          setRequerimiento = {props.setRequerimiento}
          form = {props.form}
          loadingButtonAprobar = {props.loadingButtonAprobar}
          articulosList = {props.materialesList}
          detalleReqList = {detalleReqList}
        />
      </>,
      listaCotizaciones:
        <>
        {/* LISTA DE COTIZACIONES (EXPANDIBLE PARA EL DETALLE) */}
          <CotizacionesByReq
            cotizacionesByReq = {cotizacionesByReq}
            detCotizacionesByReq = {detCotizacionesByReq}
            rolFinanzas = {rolFinanzas}
            loadingButtonAprobar = {props.loadingButtonAprobar}
            setLoadingButtonAprobar = {props.setLoadingButtonAprobar}
            getCotizacionesByRequerimiento = {getCotizacionesByRequerimiento}
            materialesList = {props.materialesList}
            unidadesList = {props.unidadesList}
            usuario = {props.usuario}
            getOrdenesCompraByRequerimiento = {getOrdenesCompraByRequerimiento}
            getDetalleOrdenesByReq = {getDetalleOrdenesByReq}
            permisoAprobarLogistica = {permisoAprobarLogistica}
            permisoAprobarGerencia = {permisoAprobarGerencia}
            tipoCambioHoy = {tipoCambioHoy.TipCambio}
            setDetalleCotList = {setDetalleCotList}
            setCotizacion = {props.setCotizacion}
            setPestaña = {setPestaña}
            setNombreCotizacionForm = {setNombreCotizacionForm}
            areasList = {props.areasList}
            requerimiento = {props.requerimiento}
            rolLogistica = {props.rolLogistica}
            rolResponsableArea = {props.rolResponsableArea}
          />
        </>,
      cotizacion:
        <>
          <FormularioCotizacion
            proveedoresList = {props.proveedoresList}
            form = {props.form}
            cotizacion = {props.cotizacion}
            setCotizacion = {props.setCotizacion}
            optionFilter = {optionFilter}
            materialesList = {props.materialesList}
            detalleCotList = {detalleCotList}
            setDetalleCotList = {setDetalleCotList}
            unidadesList = {props.unidadesList}
            loadingButtonAprobar = {props.loadingButtonAprobar}
            setLoadingButtonAprobar = {props.setLoadingButtonAprobar}
            usuario = {props.usuario}
            getCotizacionesByRequerimiento = {getCotizacionesByRequerimiento}
            getRequerimientos = {props.getRequerimientos}
            getDetalleCotizacionByReq = {getDetalleCotizacionByReq}
            requerimiento = {props.requerimiento}
            setNombreCotizacionForm = {setNombreCotizacionForm}
            detalleReqList = {detalleReqList}
            rubrosList = {props.rubrosList}
            bancosList = {props.bancosList}
            getProveedores = {props.getProveedores}
            setPestaña = {setPestaña}
            getRubros = {props.getRubros}
            getArticulos = {props.getArticulos}
            getUnidades = {props.getUnidades}
          />
        </>,
      listaOrdenes:
        <>
          <OrdenesCompraByReq
            detOrdenesCompraByReq = {detOrdenesCompraByReq}
            ordenesCompraByReq = {ordenesCompraByReq}
            getOrdenesCompraByRequerimiento = {getOrdenesCompraByRequerimiento}
            getDetalleOrdenesByReq = {getDetalleOrdenesByReq}
            rolLogistica = {props.rolLogistica}
            loadingButtonAprobar = {props.loadingButtonAprobar}
            setLoadingButtonAprobar = {props.setLoadingButtonAprobar}
          />
        </>
  }

  const AprobarRequerimiento = async(requerimiento, valor_aprobado) => {
    const body = { ...requerimiento, aprobado: valor_aprobado, usuario_aprobacion: props.usuario }
    props.setLoadingButtonAprobar(true)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/AprobarRequerimiento`,{
        method: "PUT",
        body: JSON.stringify(body),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data => {
        message.success(data.message)
        props.setLoadingButtonAprobar(false)
        props.getRequerimientos()
        props.handleCloseModal()
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error)
    }
  }

  return(
    <>
      <Modal
        visible={props.openModal}
        closable={false}
        width='100%'
        title={
          <>
            <div className="mb-2"><b style={{fontSize:"20px"}} >{props.requerimiento.codigo}</b></div>
            
            <div className='d-flex justify-content-between'>
              <b style={{fontSize:"20px"}}>Área: {props.requerimiento.nombre_area}</b>
              <b style={{fontSize:"20px"}}>Fecha de Solicitud: {moment.utc(props.requerimiento.fecha).format('DD/MM/YYYY')}</b>
            </div>

            <div className="mt-2"><b style={{fontSize:"20px"}}>Solicitante: {props.requerimiento.nombre_responsable}</b></div>
          </>
        }
        onCancel={props.handleCloseModal}
        footer={
          <div className='modal-footer d-flex justify-content-between'>
            <Button danger onClick={props.handleCloseModal} icon={<CloseOutlined />}>
              Cerrar
            </Button>
          </div>
        }
      >
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <Card
              tabList={pestañasList}
              activeTabKey={pestaña}
              onTabChange={onTabChange(setPestaña)}
            >
             {contenidoPestañas[pestaña]}

            </Card>
          </div>
          
          {rolFinanzas === true && (pestaña === 'detalle' || pestaña === 'revision')?
            <div className='col-sm-12 col-md-12 col-lg-12'>
              <b style={{fontSize:"18px"}} className="mr-2">Aprobar: </b>
              {props.requerimiento.aprobado === "SI"? null:
                <Popconfirm
                  title="¿Seguro de Aprobar este Requerimiento?"
                  onConfirm={e=>AprobarRequerimiento(props.requerimiento, "SI")} 
                  okText="Si" cancelText="No"
                >
                  <Button type='primary' className='bg-color-success mr-4'
                    loading = {props.loadingButtonAprobar}
                    icon={<CheckOutlined />}>Si</Button> 
                </Popconfirm>
              }

              {props.requerimiento.aprobado === "NO"? null:
                <Popconfirm
                  title="¿Seguro de Desaprobar este Requerimiento?"
                  onConfirm={e=>AprobarRequerimiento(props.requerimiento, "NO")} 
                  okText="Si" cancelText="No"
                >
                  <Button danger 
                    loading = {props.loadingButtonAprobar}
                    icon={<CloseOutlined />}>No</Button> 
                </Popconfirm>
              }
            </div>:null
          }
        </div>

        {<ModalTipoCambio
          openModal = {openModalTipoCambio}
          handleCloseModal = {handleCloseModalTipoCambio}
          loadingButtonAprobar = {props.loadingButtonAprobar}
          setLoadingButtonAprobar = {props.setLoadingButtonAprobar}
          setTabla = {props.setCotizacion}
          tabla = {props.cotizacion}
          usuario = {props.usuario}
          setOpenModal = {setOpenModalTipoCambio}
          />}
      </Modal>
    </>
  )
}

export default ModalDetalleRequerimiento
