import { CloseOutlined, FilePdfOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Input, Modal } from 'antd';
import { useRef } from 'react';

const ModalSubirArchivo = (props) => {
  const input = useRef<HTMLInputElement>(null);
  const handleClick = () => input.current.click()

  const subirArchivo = async(e) => {
    // console.log(props.ubicacion_archivo)
    if (props.cotizacion) {
      if (props.cotizacion.reemplazo === true) {
        props.setCotizacion({...props.cotizacion, ubicacion_archivo: e})
      } else {
        props.setCotizacion({...props.cotizacion, ubicacion_archivo_comparativo: e})
      }
    } else {
      props.setUbicacionArchivo(e)
    }
  }

  return(
    <>
      <Modal
        visible={props.openModal}
        closable={false}
        width='50%'
        title={<h3 className='m-0'>Subir Archivo</h3>}
        onCancel={props.handleCloseModal}
        footer={
          <div className='modal-footer d-flex justify-content-between'>
            <Button danger onClick={props.handleCloseModal} icon={<CloseOutlined />}>
              Cerrar
            </Button>
            
            <Button className='bg-color-success' loading={props.loadingButtonAprobar}
              type='primary' icon={<SaveOutlined />} onClick={props.UploadArchivo}>
              Guardar
            </Button>
          </div>
        }
      >
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <div className='elem-list'>
              <div className='col-sm-11 col-md-6 col-lg-6'>
                <b className='mr-4'>{props.ubicacion_archivo === null?null:
                  props.ubicacion_archivo[0] === undefined?null:props.ubicacion_archivo[0].name}</b>
                <br/>
                <input ref={input} onChange={(e) => subirArchivo(e.target.files)} type='file' style={{ display: 'none' }} />
                  <Button size='small' type={'primary'} className='btn-outline' onClick={handleClick} icon={<FilePdfOutlined />}
                    >
                    Subir PDF
                  </Button> 
              </div>
              {props.cotizacion?props.cotizacion.reemplazo === true?null:
              <>
                <div className='col-sm-11 col-md-5 col-lg-5'>
                  <b className='mr-4'>Aprobado por:</b>
                  <Input defaultValue={props.cotizacion.nombre_usuario_aprobacion} placeholder = "¿Quién lo aprobó?"
                    onChange={e=>props.setCotizacion({...props.cotizacion, nombre_usuario_aprobacion: e.currentTarget.value})}
                  />
                </div>
              </>
              :null}
            </div>
          </div>
        </div>
      </Modal>
    </>  
  )
}

export default ModalSubirArchivo