import { SaveOutlined } from '@ant-design/icons';
import { Button, Tag, Select, Form, Input, message } from 'antd';
import moment from "moment";
import { Option } from 'antd/lib/mentions';
import { Rule } from 'antd/lib/form';

const { Item } = Form;

const FormularioRevisionReq = (props) => {

  const UpdateRequerimiento = async() => {
    // console.log(props.requerimiento)
    props.setLoadingButtonAprobar(true)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/UpdateRequerimiento`,{
        method: "PUT",
        body: JSON.stringify(props.requerimiento),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data => {
        message.success("Requerimiento Actualizado")
        props.setLoadingButtonAprobar(false)
        props.getRequerimientos()
        props.handleCloseModal()
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error)
    }
  }

  const cambiarTiempoAtencion = (value) => {
    props.setRequerimiento({
      ...props.requerimiento, 
      tiempo_atencion: value,
      fecha_entrega: moment.utc(props.requerimiento.fecha).add(value, 'd').format('YYYY-MM-DD')
    })
  }

  const tipoChange = (value) => {
    if (value === 'ATENCION') {
      const validarStock = props.detalleReqList.filter(det => {
        return (getStock(det) < (det.cantidad * det.cantidad_unidades))
      })

      if (validarStock.length > 0) {
        message.error('No hay Stock Suficiente')
        props.form.setFieldsValue({tipo: props.requerimiento.tipo})

      } else {
        props.setRequerimiento({...props.requerimiento, tipo: value})
      }
    } else {
      props.setRequerimiento({...props.requerimiento, tipo: value})
    }

  }

  const getStock = (item) => {
    const stock = props.articulosList.filter( articulo => {
      return( articulo.id_articulo === item.id_articulo)
    })

    return stock[0].stock_disponible
  }

  return(
    <>
      <Form form={props.form} layout='vertical' onFinish={UpdateRequerimiento}>
        <div className='elem-list'>
          <div className='col-sm-5 col-md-2 col-lg-2'>
            <Item label="Prioridad" name="prioridad">
              <Select
                defaultValue={props.requerimiento.prioridad}
                disabled = {props.requerimiento.aprobado === 'NO'? true: false}
                className='mb-lg-0'
                placeholder='Prioridad'
                optionFilterProp='children'
                filterOption={props.optionFilter}
                onChange={e => props.setRequerimiento({...props.requerimiento, prioridad:e})}
              >
                <option value={1}>
                  <Tag color={'#AA0404'}>1 - ALTO</Tag>
                </option>
                <option value={2}>
                  <Tag color={'#AA9104'}>2 - MEDIO</Tag>
                </option>
                <option value={3}>
                  <Tag color={'#2D38A7 '}>3 - BAJO</Tag>
                </option>
              </Select>
            </Item>
          </div>    

          <div className='col-sm-6 col-md-3 col-lg-3'>
            <Item label="Tipo" name="tipo">
              {props.requerimiento.tipo === 'SERVICIO'?
              <b>{props.requerimiento.tipo}</b>
              :<Select
                defaultValue={props.requerimiento.tipo}
                disabled = {props.requerimiento.aprobado === 'NO'? true: false}
                className='mb-lg-0'
                placeholder='Seleccionar Tipo'
                optionFilterProp='children'
                filterOption={props.optionFilter}
                onChange={e => tipoChange(e)}
              >
                <Option value='COMPRA' key='COMPRA'>COMPRA</Option>
                {/* <Option value='SERVICIO' key='SERVICIO'>SERVICIO</Option> */}
                <Option value='ATENCION' key='ATENCION'>ATENCIÓN</Option>
              </Select>
              }
              
            </Item>  
          </div>

          <div className='col-sm-6 col-md-3 col-lg-3'>
            <Item label="Estado" name="estado">
              {props.requerimiento.estado === 'FINALIZADO'?
              <b>{props.requerimiento.estado}</b>
              :<Select
                defaultValue={props.requerimiento.estado}
                disabled = {props.requerimiento.aprobado === 'NO'? true: false}
                className='mb-lg-0'
                placeholder='Seleccionar Estado'
                optionFilterProp='children'
                filterOption={props.optionFilter}
                onChange={e => props.setRequerimiento({...props.requerimiento, estado:e})}
              >
                <Option value='ANALISIS' key='ANALISIS'>
                  <Tag color={'#336cfb'}>ANÁLISIS</Tag>
                </Option>
                <Option value='PROCESO' key='PROCESO'>
                  <Tag color={'#FC5F1B'}>PROCESO</Tag>
                </Option>
                {/* <Option value='FINALIZADO' key='FINALIZADO'>
                  <Tag color={'#b7ce63'}>FINALIZADO</Tag>
                </Option> */}
                <Option value='RECHAZADO' key='RECHAZADO'>
                  <Tag color={'#AA0404'}>RECHAZADO</Tag>
                </Option>
              </Select>}
              
            </Item>  
          </div>
        </div>

        <div className='elem-list'>
          <div className='col-sm-6 col-md-2 col-lg-2'>
            <Item label="Días de Atención" name="tiempo_atencion">
              <Input type="number" min={0} defaultValue={props.requerimiento.tiempo_atencion} 
                onChange={e=>cambiarTiempoAtencion(e.currentTarget.value)}
                disabled = {props.requerimiento.aprobado === 'NO'? true: false}
              />
            </Item>
          </div>

          <div className='col-sm-6 col-md-3 col-lg-3'>
            <b>Fecha de Entrega:</b> <br/>
            <label className='mt-4'>{props.requerimiento.fecha_entrega === null? '__/__/___':
              moment.utc(props.requerimiento.fecha_entrega).format('DD/MM/YYYY')}
            </label>
          </div>

          <div className='col-sm-5 col-md-3 col-lg-3'><br/>
            <Button className='bg-color-success' loading={props.loadingButtonAprobar}
              type='primary' icon={<SaveOutlined />} //htmlType="submit"
              disabled = {props.requerimiento.aprobado === 'NO' || props.requerimiento.estado === 'FINALIZADO'? true: false}
              onClick={UpdateRequerimiento}
            >
              Guardar
            </Button>
          </div>
        </div>
      </Form>
    </>
  )
}

export default FormularioRevisionReq
