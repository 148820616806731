import { CloseOutlined } from '@ant-design/icons';
import { Button, Modal, Card} from 'antd';
import { useState, useEffect } from "react";
import moment from "moment";
import { existeRol, listaAreas, listaCodigos, listaResp } from '../FuncionesGenerales';
import PestaniaDetalleOrdenCompra from './PestaniaDetalleOrdenCompra';

const ModalOrdenCompraDetalle = (props) => {
  const [detOrdenesCompraList, setDetOrdenesCompraList] = useState([])
  const [pestaña, setPestaña] = useState('detalle');
  const [loadingButton, setLoadingButton] = useState(false);
  const [permisoLogistica, setpermisoLogistica] = useState(false);
  const [detalleBancos, setDetalleBancos] = useState([])

  useEffect(() => {
    if (props.openModal === true) {
      getDetalleOrdenbyIdOrden(props.orden_compra.id_orden_compra)
      getCuentasProveedor(props.orden_compra.id_proveedor)
      setpermisoLogistica(existeRol('Logistica.Gestion.Requerimiento'))
      setPestaña('detalle')
    }
  }, [props.openModal]);

  const onTabChange = (setter: (val: string) => void) => (key: string) => {
    setter(key);
  };

  const getDetalleOrdenbyIdOrden = async(id_orden_compra) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getDetalleOrdenbyIdOrden/${id_orden_compra}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setDetOrdenesCompraList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getCuentasProveedor = async(id_prove) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getCuentasProveedor/${id_prove}`)
      .then(res => res.json())
      .then(data => {
        if (data.message) {
          setDetalleBancos([])
        } else {
          setDetalleBancos(data)
        }
        // console.log(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  let pestañasList = [
    { key: 'detalle', tab: 'Datos' }
  ]


  const handleCloseModal = () => {
    props.setOpenModal(false)
    setDetOrdenesCompraList([])
  }

  const Total = () => {
    return(
      <>
        <div className='d-flex justify-content-between mr-4'>
          <b>Base Imponible</b>
          <b>
            {props.orden_compra.Parc_Valor} { detOrdenesCompraList.length === 0? 0:
              (detOrdenesCompraList.map(det => det.cantidad * det.precio).reduce((prev, next) => prev + next) / 1.18)
              .toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            }
          </b>
        </div>

        <div className='d-flex justify-content-between mr-4'>
          <b>IGV</b>
          <b>
            {props.orden_compra.Parc_Valor} { detOrdenesCompraList.length === 0? 0:
              (detOrdenesCompraList.map(det => det.cantidad * det.precio).reduce((prev, next) => prev + next) / 1.18 * 0.18)
              .toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            }
          </b>
        </div>

        <div className='d-flex justify-content-between mr-4'>
          <b>Total</b>
          <b>
            {props.orden_compra.Parc_Valor} { detOrdenesCompraList.length === 0? 0:
              detOrdenesCompraList.map(det => det.cantidad * det.precio).reduce((prev, next) => prev + next)
              .toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            }
          </b>
        </div>
      </>
    )
  }

  const contenidoPestañas = {
    detalle:
      <PestaniaDetalleOrdenCompra
        orden_compra = {props.orden_compra}
        setOrden_compra = {props.setOrden_compra}
        getOrdenesCompra = {props.getOrdenesCompra}
        handleCloseModal = {handleCloseModal}
        detOrdenesCompraList = {detOrdenesCompraList}
        loadingButton = {loadingButton}
        setLoadingButton = {setLoadingButton}
        permisoLogistica = {permisoLogistica}
        Total = {Total}
        usuario = {props.usuario}
        detalleBancos = {detalleBancos}
      />
  }

  return(
    <>
      <Modal
        visible={props.openModal}
        closable={false}
        width='90%'
        title={
          <>
            <div className='d-flex justify-content-between'>
              <b style={{fontSize:"20px"}}>
                Fecha de Orden: {moment.utc(props.orden_compra.fecha_orden_compra).format('DD/MM/YYYY')}
              </b>
              <b style={{fontSize:"20px"}}>
                {props.orden_compra.id_consolidado === null?props.orden_compra.codigo :listaCodigos(props.orden_compra.codigo)}
              </b>
            </div>

            <div className='d-flex justify-content-between mb-2 mt-2'>
              <b style={{fontSize:"20px"}}>
                Fecha de Envío: {props.orden_compra.fecha_envio === null?null:moment.utc(props.orden_compra.fecha_envio).format('DD/MM/YYYY')}
              </b>
              <b style={{fontSize:"20px"}}>Áreas: 
                {props.orden_compra.id_consolidado === null?props.orden_compra.nombre_area :listaAreas(props.orden_compra.nombre_area)}
              </b>
            </div>

            <div className='d-flex justify-content-between mb-2 mt-2'>
              <b style={{fontSize:"20px"}}>Núm. Orden: {props.orden_compra.numero_orden}</b>
              <b style={{fontSize:"20px"}}>Estado: {props.orden_compra.estado}</b>
            </div>

            <div className='d-flex justify-content-between mb-2 mt-2'>
              <b style={{fontSize:"20px"}}>Solicitante(s): 
                {props.orden_compra.id_consolidado === null?props.orden_compra.nombre_responsable :listaResp(props.orden_compra.nombre_responsable)}
              </b>
            </div>
          </>}
        onCancel={handleCloseModal}
        footer={
          <div className='modal-footer d-flex justify-content-between'>
            <Button danger onClick={handleCloseModal} icon={<CloseOutlined />}>
              Cerrar
            </Button>
          </div>
        }
      >
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <Card
              tabList={pestañasList}
              activeTabKey={pestaña}
              onTabChange={onTabChange(setPestaña)}
            >
             {contenidoPestañas[pestaña]}

            </Card>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModalOrdenCompraDetalle