import {useEffect, useRef, useState} from "react";
import { IPageData } from "../../../interfaces/page";
import { usePageData } from '../../../hooks/usePage';
import Cookies from "universal-cookie";
import moment from "moment";
import { Card, Table, Button, Tag, Select, Input, Form, message, Popconfirm } from 'antd';
import { Option } from "antd/lib/mentions";
import { EditOutlined, EyeOutlined, FilePdfOutlined, PlusOutlined, SendOutlined, CloseOutlined } from "@ant-design/icons";
import { DownloadFile, existeRol, listaAreas, listaResp } from "../FuncionesGenerales";
import { PdfFileOrdenes } from "../componentesPDF/GenerarPDFOrdenesCompra";
import { ExcelFileOrdenesCompra } from "../componentesExcel/GenerarExcelOrdenesCompra";
import ModalOrdenCompraDetalle from "./ModalOrdenCompraDetalle";
import ModalFormularioOrdenCompra from "./ModalFormularioOrdenCompra";
import ModalFormularioFactura from "../componentesFacturas/ModalFormularioFactura";
import ModalEnviarPDF from "../ModalEnviarPDF";

const cookies = new Cookies()

const pageData: IPageData = {
  fulFilled: true,
  title: 'Órdenes de Compra',
  breadcrumbs: [
    {
      title: 'Órdenes de Compra',
    }
  ]
};

const OrdenesCompra = () => {
  usePageData(pageData);
  const componentePDF = useRef(null)
  const [ordenesCompraList, setOrdenesCompraList] = useState([])
  const [proveedoresList, setProveedoresList] = useState([])
  const [areasList, setAreasList] = useState([])
  const [fechaInicio, setFechaInicio] = useState(''/*moment(new Date()).format("YYYY-MM").concat('-01')*/)
  const [fechaFin, setFechaFin] = useState(moment(new Date()).format("YYYY-MM-DD"))
  const [selectProveedor, setSelectProveedor] = useState(undefined)
  const [selectMoneda, setSelectMoneda] = useState(undefined)
  const [selectTipoDoc, setSelectTipoDoc] = useState(undefined)
  const [inputNumOrden, setInputNumOrden] = useState('')
  const [inputRUC, setInputRUC] = useState('')
  const [selectEstado, setSelectEstado] = useState(undefined)
  const [openModal, setOpenModal] = useState(false);
  const [openModalForm, setOpenModalForm] = useState(false);
  const [openModalFactura, setOpenModalFactura] = useState(false);
  const [openModalEnviarPDF, setOpenModalEnviarPDF] = useState(false);
  const [ordenSeleccionado, setOrdenSeleccionado] = useState({
    id_orden_compra: null,
    id_cotizacion: null,
    tipo_documento: null,
    tipo_pago: null,
    forma_pago: null,
    comentario: null,
    fecha_orden_compra: null,
    ubicacion_archivo_factura_guia: null,
    nombre_rubro: null,
    id_rubro: null,
    nombre_area: null,
    nombre_responsable: null,
    codigo: null,
    numero_orden: null,
    id_consolidado: null,
    ubicacion_archivo: null,
    fecha_envio: null,
    tipo_entrega: null,
    dias_plazo: null,
    lugar_ejecucion: null,
    penalidades: null
  })
  const [rolLogistica, setRolLogistica] = useState(false);
  const [rolGerencia, setRolGerencia] = useState(false);
  const [rolFinanzas, setRolFinanzas] = useState(false);
  const [cotizacion, setCotizacion] = useState({});
  const [detOrdenesCompraList, setDetOrdenesCompraList] = useState([])
  const [detalleBancos, setDetalleBancos] = useState([])
  const [detalleRepresentantes, setDetalleRepresentantes] = useState([])
  const [loadingButton, setLoadingButton] = useState(false)
  const [materialesList, setMaterialesList] = useState([])
  const [unidadesList, setUnidadesList] = useState([])
  const [idFactura, setIdFactura] = useState(null);
  const [estadoOrden, setEstadoOrden] = useState(null);
  const [form] = Form.useForm();
  const [selectArea, setSelectArea] = useState(undefined)

  const optionFilter = (input, option) =>
    option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;

  useEffect(() => {
    if (!cookies.get('nameid_logis') || existeRol('Logistica.Acceso.OrdenCompra') === false) {
      // history.push(`/public/sign-in`)
      window.location.href='/'    
    } else {
      getOrdenesCompra()
      getProveedores()
      getArticulos()
      getUnidades()
      getAreas()
    }

    setRolLogistica(existeRol('Logistica.Gestion.Requerimiento'))
    setRolGerencia(existeRol('Gerencia.Aprobar.Cotizacion'))
    setRolFinanzas(existeRol('Finanzas.Aprobar.Requerimiento'))
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getOrdenesCompra()
    }, 10000);
    return () => clearInterval(interval);
  }, [ordenesCompraList])

  const getOrdenesCompra = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getOrdenesCompra/${1}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setOrdenesCompraList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getAreas = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getAreas/${1}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setAreasList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getArticulos = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getArticulos`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setMaterialesList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getUnidades = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getUnidades`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setUnidadesList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getProveedores = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getProveedores`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setProveedoresList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleModalFactura = (orden) => {
    // console.log(orden)
    if (orden.tipo_documento === null) {
      message.warning("Indicar el Tipo de Documento de la Orden", 5)
    } else {
      setIdFactura(null)
      setEstadoOrden(orden.estado)
      setOrdenSeleccionado(orden)
      setOpenModalFactura(true)
    }
  }

  const handleModalPDFCorreo = (orden) => {
    setOrdenSeleccionado(orden)
    getDetalleOrdenbyIdOrden(orden.id_orden_compra)
    getCuentasProveedor(orden.id_proveedor)
    getRepresentantesByIdProveedor(orden.id_proveedor)
    setOpenModalEnviarPDF(true)
  }

  const getCuentasProveedor = async(id_prove) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getCuentasProveedor/${id_prove}`)
      .then(res => res.json())
      .then(data => {
        if (data.message) {
          setDetalleBancos([])
        } else {
          setDetalleBancos(data)
        }
        // console.log(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getRepresentantesByIdProveedor = async(id_prove) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getRepresentantesByIdProveedor/${id_prove}`)
      .then(res => res.json())
      .then(data => {
        if (data.message) {
          setDetalleRepresentantes([])
        } else {
          setDetalleRepresentantes(data)
        }
        
        // console.log(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const columns: any = [
    {
      title: (<div style={{width:200}}></div>),
      render: record => {
        return(
          <>
            <Button type='primary' className="mr-2"
              title="Ver Datos de la Orden de Compra" 
              onClick={e => handleOpenModal(record)}
              icon={<EyeOutlined />} />

            {rolLogistica === true && record.estado === 'ACEPTADA'? 
              <Button type='primary' className="bg-color-orange mr-2"
                title="Editar Orden de Compra" 
                onClick={e => handleOpenModalForm(record.id_orden_compra)}
                icon={<EditOutlined />} />
            :null}

            {rolLogistica === true?
            <Button type='primary' className="bg-color-orange mr-2"
              title="Enviar PDF de la Orden de Compra Al Proveedor" 
              onClick={e => handleModalPDFCorreo(record)}
              icon={<FilePdfOutlined />} >
            </Button>
            :null}

            {record.ubicacion_archivo === null?null:
            <Button danger className='mr-2'
              title="Descargar Guía de Remisión" 
              onClick={e => DownloadFile('GuiaRemision', record, record.id_orden_compra)}
              icon={<FilePdfOutlined />} >
            </Button>}

            {/*record.CantFacturas === 0 &&*/ rolLogistica === true && record.estado !== 'RECHAZADA' && record.estado !== 'FINALIZADA'?
              <Button className='bg-color-success mr-2' 
                title="Ingresar/Agregar Factura(s)" 
                onClick={e => handleModalFactura(record)}
                icon={<PlusOutlined />} >
              </Button>
            :null}

            {record.estado === 'DESPACHADA' && rolLogistica === true?
            <Popconfirm
              title="¿Seguro de Enviar los Items a las Áreas Solicitantes?"
              onConfirm={e=>DespacharItems(record)} 
              okText="Si" cancelText="No"
            >
              <Button type='primary' className='bg-color-success mr-2'
                title="Enviar los Items a las Áreas Solicitantes"
                icon={<SendOutlined />}/> 
            </Popconfirm>
            :null}

            {record.estado === 'ACEPTADA' && (rolLogistica === true || rolGerencia === true || rolFinanzas === true)?
            <Popconfirm
              title="¿Seguro de Rechazar esta Orden de Compra?"
              onConfirm={e=>RechazarOrdenCompra(record)}
              okText="Si" cancelText="No"
            >
              <Button danger
                title="Rechazar Orden de Compra"
                icon={<CloseOutlined />}/> 
            </Popconfirm>
            :null}
          </>
        )
      }
    },
    {
      title: 'FECHA',
      dataIndex: 'fecha_creacion',
      // key: 'fecha_creacion',
      defaultSortOrder: 'descend',
      sorter: (a, b) => moment(a.fecha_creacion).unix() - moment(b.fecha_creacion).unix(),
      render: text => (
        moment.utc(text).format('DD/MM/YY')
      )
    },
    {
      title: 'F. ENVÍO',
      dataIndex: 'fecha_envio',
      key: 'fecha_envio',
      render: text => (
        text === null?null:moment.utc(text).format('DD/MM/YY')
      )
    },
    {
      title: (
        <>
          <div style={{width:120}}>
            <Input defaultValue={inputNumOrden} 
              onChange={e=>setInputNumOrden(e.currentTarget.value)}
                placeholder = "NÚM. ORDEN"
            />
          </div>
        </>
      ),
      dataIndex: 'numero_orden',
      key: 'numero_orden'
    },
    {
      title: (
        <>
          <div style={{width:250}}>
          <Select
              showSearch
              allowClear
              defaultValue={selectArea}
              className='mr-2'
              placeholder='ÁREA'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={e => setSelectArea(e)}
            >
              {areasList.map(area =>{
                return(
                  <Option value={area.nombre} key={area.nombre}>{area.nombre}</Option>
                )
              })}
            </Select>  
          </div>
        </>
      ),
      render: record => {
        if (record.id_consolidado === null) {
          return(<>{record.nombre_area}</>)
        } else {
          return(<>{listaAreas(record.nombre_area)}</>)  
        }
      }
    },
    {
      title: (
        <>
          <div style={{width:180}} className="mr-4">
            <Select
              allowClear
              showSearch
              defaultValue={selectProveedor}
              className='mb-lg-0'
              placeholder='PROVEEDOR'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={(e)=>setSelectProveedor(e)}
            >
            {proveedoresList.map(proveedor =>{
              return(
                <Option value={proveedor.id_prove} key={proveedor.id_prove}>{proveedor.descripcion}</Option>
              )
            })}
            </Select>
          </div>
        </>
      ),
      dataIndex: 'descripcion',
      key: 'descripcion'
    },
    {
      title: (
        <>
          <div style={{width:130}}>
            <Input defaultValue={inputRUC} 
              onChange={e=>setInputRUC(e.currentTarget.value)}
                placeholder = "RUC"
            />
          </div>
        </>
      ),
      dataIndex: 'ruc',
      key: 'ruc'
    },
    {
      title: (
        <>
          <div style={{width:110}}>
            <Select
              allowClear
              defaultValue={selectMoneda}
              className='mb-lg-0'
              placeholder='MONEDA'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={(e)=>setSelectMoneda(e)}
            >
              <Option value='S/.' key='S/.'>S/.</Option>
              <Option value='$  ' key='$  '>$</Option>
            </Select>
          </div>
        </>
      ),
      dataIndex: 'Parc_Valor',
      key: 'Parc_Valor'
    },
    {
      title: 'TOTAL',
      render: record => {
        return(<>{record.total.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>)
      }
    },
    {
      title: (
        <>
          <div style={{width:140}} className="mr-4">
            <Select
              allowClear
              defaultValue={selectTipoDoc}
              className='mb-lg-0'
              placeholder='DOCUMENTO'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={(e)=>setSelectTipoDoc(e)}
            >
              <Option value='BOLETA' key='BOLETA'>BOLETA</Option>
              <Option value='FACTURA' key='FACTURA'>FACTURA</Option>
              <Option value='HONORARIO' key='HONORARIO'>HONORARIO</Option>
            </Select>
          </div>
        </>
      ),
      dataIndex: 'tipo_documento',
      key: 'tipo_documento'
    },
    {
      title: (
        <>
          <div style={{width:230}}>
            <Select
              allowClear
              defaultValue={selectEstado}
              className=''
              placeholder='ESTADO'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={e => setSelectEstado(e)}
            >
              <Option value='ACEPTADA'>ACEPTADA</Option>
              <Option value='FACTURADA'>FACTURADA</Option>
              <Option value='DESPACHADA'>DESPACHADA</Option>
              <Option value='ESPERA DE CONFORMIDAD'>ESPERA DE CONFORMIDAD</Option>
              <Option value='FINALIZADA'>FINALIZADA</Option>
              <Option value='RECHAZADA'>RECHAZADA</Option>
            </Select>  
          </div>
        </>
      ),
      dataIndex: 'estado',
      key: 'estado',
      render : text => {
        switch (text) {
          case 'ACEPTADA':
            return(<Tag color={'#336cfb'}>ACEPTADA</Tag>)

          case 'FACTURADA':
            return(<Tag color={'#336cfb'}>FACTURADA</Tag>)
          
          case 'DESPACHADA':
            return(<Tag color={'#FC5F1B'}>DESPACHADA</Tag>)

          case 'ESPERA DE CONFORMIDAD':
            return(<Tag color={'#FC5F1B'}>ESPERA DE CONFORMIDAD</Tag>)

          case 'FINALIZADA':
            return(<Tag color={'#B7CE63'}>FINALIZADA</Tag>)
            
          case 'RECHAZADA':
            return(<Tag color={'#AA0404'}>RECHAZADA</Tag>)

          default:
            break;
        }
      }
    },
    {
      title: 'APROBADO POR',
      dataIndex: 'nombre_usuario_aprobacion',
      key: 'nombre_usuario_aprobacion'
    }
  ]

  const handleOpenModal = (orden) => {
    // console.log(orden)
    setOpenModal(true)
    setOrdenSeleccionado(orden)
  }

  const handleOpenModalForm = (id_orden_compra) => {
    // setOpenModalForm(true)
    getOrdenCompraById(id_orden_compra)
    getDetalleOrdenbyIdOrden(id_orden_compra)
  }

  const handleCloseModalForm = () => {
    setOpenModalForm(false)
  }

  const DespacharItems = async(orden_compra) => { 
    // console.log(orden_compra)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/DespacharItems`,{
        method: "PUT",
        body: JSON.stringify({...orden_compra, 
          id_sucursal:1, 
          fecha_movimiento: moment.utc(new Date()).format('YYYY/MM/DD'),
          usuario: cookies.get('nameid_logis')
        }),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data =>{
        message.success(data.message)
        getOrdenesCompra()
      }).catch(err => {
        console.log(err)
      });
    } catch (error) {
      console.log(error)
    }
  }

  const RechazarOrdenCompra = async(orden_compra) => { 
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/RechazarOrdenCompra`,{
        method: "PUT",
        body: JSON.stringify({...orden_compra}),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data =>{
        message.success(data.message)
        getOrdenesCompra()
      }).catch(err => {
        console.log(err)
      });
    } catch (error) {
      console.log(error)
    }
  }

  const getOrdenCompraById = async(id_orden_compra) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getOrdenCompraById/${id_orden_compra}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setOrdenSeleccionado({
          id_orden_compra: data.id_orden_compra,
          id_cotizacion: data.id_cotizacion,
          tipo_documento: data.tipo_documento,
          tipo_pago: data.tipo_pago,
          forma_pago: data.forma_pago,
          comentario: data.comentario,
          fecha_orden_compra: moment.utc(data.fecha_orden_compra).format('YYYY-MM-DD'),
          ubicacion_archivo_factura_guia: data.ubicacion_archivo_factura_guia,
          nombre_rubro: data.nombre_rubro,
          id_rubro: data.id_rubro,
          nombre_area: data.nombre_area,
          nombre_responsable: data.nombre_responsable,
          codigo: data.codigo,
          numero_orden: data.numero_orden,
          id_consolidado: data.id_consolidado,
          ubicacion_archivo: data.ubicacion_archivo,
          fecha_envio: data.fecha_envio === null? null:moment.utc(data.fecha_envio).format('YYYY-MM-DD'),
          tipo_entrega: data.tipo_entrega,
          dias_plazo: data.dias_plazo,
          lugar_ejecucion: data.lugar_ejecucion,
          penalidades: data.penalidades
        })

        setCotizacion({
          parv_descripcion: data.parv_descripcion,
          TipCambio: data.TipCambio,
          descripcion: data.descripcion,
          ruc: data.ruc,
          nombre_area: data.id_consolidado === null?data.nombre_area:listaAreas(data.nombre_area),
          nombre_responsable: data.id_consolidado === null? data.nombre_responsable: listaResp(data.nombre_responsable),
          Parc_Valor: data.Parc_Valor,
          tipo:data.tipo
        })

        form.setFieldsValue({
          fecha: moment.utc(data.fecha_orden_compra).format('YYYY-MM-DD'),
          tipo_documento: data.tipo_documento,
          parv_descripcion: data.parv_descripcion,
          TipCambio: data.TipCambio,
          descripcion: data.descripcion,
          ruc: data.ruc,
          tipo_pago: data.tipo_pago,
          forma_pago: data.forma_pago,
          comentario: data.comentario,
          fecha_envio: data.fecha_envio === null? null:moment.utc(data.fecha_envio).format('YYYY-MM-DD'),
          tipo_entrega: data.tipo_entrega,
          dias_plazo: data.dias_plazo,
          lugar_ejecucion: data.lugar_ejecucion,
          penalidades: data.penalidades
        })

      })
      setOpenModalForm(true)
    } catch (error) {
      console.log(error)
    }
  }

  const getDetalleOrdenbyIdOrden = async(id_orden_compra) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getDetalleOrdenbyIdOrden/${id_orden_compra}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setDetOrdenesCompraList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }
  

  const ordenesCompraListFiltradas = () => {
    const lista = ordenesCompraList.filter(orden => {
      return( 
        (selectEstado === undefined? orden.estado !== selectEstado: orden.estado === selectEstado) &&
        moment.utc(orden.fecha_creacion).format("YYYY-MM-DD") >= fechaInicio && 
        moment.utc(orden.fecha_creacion).format("YYYY-MM-DD") <= fechaFin &&
        orden.numero_orden.toUpperCase().includes(inputNumOrden.toUpperCase()) && orden.ruc.includes(inputRUC) &&
        (selectProveedor === undefined? orden.id_proveedor !== selectProveedor: orden.id_proveedor === selectProveedor) &&
        (selectTipoDoc === undefined? orden.tipo_documento !== selectTipoDoc: orden.tipo_documento === selectTipoDoc) &&
        (selectMoneda === undefined? orden.Parc_Valor !== selectMoneda: orden.Parc_Valor === selectMoneda)  &&
        (selectArea === undefined? orden.nombre_area !== selectArea: orden.nombre_area.includes(selectArea))
      )
    })

    return lista
  }

  const ExportarPDF = () => {
    componentePDF.current.save()
  }

  if (ordenesCompraList.length > 0) {
    return (
      <>
        {ordenesCompraListFiltradas().length === 0?null:PdfFileOrdenes(componentePDF, ordenesCompraListFiltradas(), fechaInicio, fechaFin)}
        <div className='row'>
          <div className='col-md-3 col-lg-3'>
            <label>Desde: </label> <br/> 
            <Input type="date"/*"datetime-local"*/
              value={fechaInicio}
              onChange={e => setFechaInicio(e.currentTarget.value)}
            />
          </div>

          <div className='col-md-3 col-lg-3'>
            <label>Hasta: </label> <br/> 
            <Input type="date"/*"datetime-local"*/
              value={fechaFin} 
              onChange={e => setFechaFin(e.currentTarget.value)}
            />  
          </div>

          <div className='elem-list mt-4'>
            <Button danger 
              title="Exportar PDF" 
              onClick={e => ordenesCompraListFiltradas().length === 0?message.warn('La lista de órdenes filtrada esta vacía'):ExportarPDF()}
              icon={<FilePdfOutlined />} >PDF
            </Button>
            {ExcelFileOrdenesCompra(ordenesCompraListFiltradas(), fechaInicio, fechaFin)}
          </div>
        </div>

        <Card>
          <div>
            <Table
              columns={columns}
              dataSource={ordenesCompraListFiltradas()}
              size='small'
            />
          </div>
        </Card>

        {<ModalOrdenCompraDetalle
          orden_compra = {ordenSeleccionado}
          setOrden_compra = {setOrdenSeleccionado}
          openModal = {openModal}
          setOpenModal = {setOpenModal}
          getOrdenesCompra = {getOrdenesCompra}
          usuario= {cookies.get('nameid_logis')}
          proveedoresList = {proveedoresList}
          areasList = {areasList}
        />}

        {<ModalFormularioOrdenCompra
          loadingButtonAprobar = {loadingButton}
          setLoadingButtonAprobar = {setLoadingButton}
          cotizacion = {cotizacion}
          openModal = {openModalForm}
          handleCloseModal = {handleCloseModalForm}
          nuevaOrdenCompra = {ordenSeleccionado}
          setNuevaOrdenCompra = {setOrdenSeleccionado}
          form = {form}
          materialesList = {materialesList}
          unidadesList = {unidadesList}
          detalleOrden = {detOrdenesCompraList}
          setDetalleOrden = {setDetOrdenesCompraList}
          usuario = {cookies.get('nameid_logis')}
          setCotizacion = {setCotizacion}
          getOrdenesCompra = {getOrdenesCompra}
          requerimiento = {{
            nombre_rubro: ordenSeleccionado.nombre_rubro, 
            id_rubro: ordenSeleccionado.id_rubro,
            codigo: ordenSeleccionado.codigo
          }}
        />}

        {<ModalFormularioFactura
          orden_compra = {ordenSeleccionado}
          usuario = {cookies.get('nameid_logis')}
          openModal = {openModalFactura}
          setOpenModal = {setOpenModalFactura}
          idFactura = {idFactura}
          setIdFactura = {setIdFactura}
          setEstadoOrden = {setEstadoOrden}
          estadoOrden = {estadoOrden}
          getDetalleOrdenbyIdOrden = {getDetalleOrdenbyIdOrden}
          setDetOrdenesCompraList = {setDetOrdenesCompraList}
          detOrdenesCompraList = {detOrdenesCompraList}
          getOrdenesCompra = {getOrdenesCompra}
          setOrden_compra = {setOrdenSeleccionado}
        />}

        <ModalEnviarPDF
          openModal = {openModalEnviarPDF}
          setOpenModal = {setOpenModalEnviarPDF}
          orden_compra = {ordenSeleccionado}
          setOrden_compra = {setOrdenSeleccionado}
          detOrdenesCompraList = {detOrdenesCompraList}
          setDetOrdenesCompraList = {setDetOrdenesCompraList}
          detalleBancos = {detalleBancos}
          setDetalleBancos = {setDetalleBancos}
          detalleRepresentantes = {detalleRepresentantes}
          setDetalleRepresentantes = {setDetalleRepresentantes}
        />
      </>
    )
  } else {
    return (
      <>
        <div className='row'>
          <div className='col-md-3 col-lg-3'>
            <label>Desde: </label> <br/> 
            <Input type="date"/*"datetime-local"*/
              value={fechaInicio}
              onChange={e => setFechaInicio(e.currentTarget.value)}
            />
          </div>

          <div className='col-md-3 col-lg-3'>
            <label>Hasta: </label> <br/> 
            <Input type="date"/*"datetime-local"*/
              value={fechaFin} 
              onChange={e => setFechaFin(e.currentTarget.value)}
            />  
          </div>
        </div>

        <Card>
          <div>
            No hay Datos
          </div>
        </Card>
      </>
    )
  }

}


export default OrdenesCompra