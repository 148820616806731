import { CloseOutlined } from '@ant-design/icons';
import { Button, Modal, Table, Card } from 'antd';
  import {useState, useEffect} from "react";

const ModalArticulosStockDisponible = (props) => {
  const [pestaña, setPestaña] = useState('almacenes');

  useEffect(() => {
    if (props.openModal === true) {
      setPestaña('almacenes')
    }
  }, [props.openModal]);

  const onTabChange = (setter: (val: string) => void) => (key: string) => {
    setter(key);
  };

  const pestañasList = [
    { key: 'almacenes', tab: 'Almacenes' },
    { key: 'areas', tab: 'Áreas' }
  ]

  const columnsAlmacen = [
    { title: 'ALMACÉN', key: 'destino', dataIndex: 'destino' },
    { title: 'STOCK (UNIDADES)',
      render: record=>{
        // console.log(record.ingresos)
        // console.log(record.egresos)
        if ((record.ingresos - (record.egresos === undefined?0:record.egresos)) <= 0) {
          return(<p style={{color:'#EE0000'}}>{record.ingresos - (record.egresos === undefined?0:record.egresos)}</p>)
        } else {
          return(<p>{record.ingresos - (record.egresos === undefined?0:record.egresos)}</p>)
        }
      }
    }
  ]

  const columnsAreas = [
    { title: 'ÁREAS', key: 'destino', dataIndex: 'destino' },
    { title: 'STOCK (UNIDADES)', /*key: 'disponible', dataIndex: 'disponible'*/
      render: record=>{
        if (record.disponible <= 0) {
          return(<p style={{color:'#EE0000'}}>{record.disponible}</p>)
        } else {
          return(<p>{record.disponible}</p>)
        }
      }
    }
  ]

  const contenidoPestañas = {
    almacenes:
      <>
        {props.stockAlmacenDisponible.length > 0? 
        <Table 
          pagination={false} 
          columns={columnsAlmacen}
          dataSource={props.stockAlmacenDisponible}
          size="small"
        />
        :'No hay Datos'}
      </>,
    areas:
      <>
        {props.stockAreasDisponible.length > 0? 
        <Table 
          pagination={false} 
          columns={columnsAreas}
          dataSource={props.stockAreasDisponible}
          size="small"
        />
        :'No hay Datos'}
      </>
  }

  const handleCloseModal = () => {
    props.setOpenModal(false)
    props.setStockAlmacenDisponible([])
    props.setstockAreasDisponible([])
    props.setArticulo({})
  }

  return(
    <>
      <Modal
        visible={props.openModal}
        closable={false}
        width='70%'
        title={<div className="mb-2"><b style={{fontSize:"20px"}} >{props.articulo.codigo} - {props.articulo.nombre}</b></div>}
        onCancel={handleCloseModal}
        footer={
          <div className='modal-footer d-flex justify-content-between'>
            <Button danger onClick={handleCloseModal} icon={<CloseOutlined />}>
              Cerrar
            </Button>
          </div>
        }
      >
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <Card
              tabList={pestañasList}
              activeTabKey={pestaña}
              onTabChange={onTabChange(setPestaña)}
            >
             {contenidoPestañas[pestaña]}

            </Card>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModalArticulosStockDisponible