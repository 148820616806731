import { FileExcelOutlined } from "@ant-design/icons";
import { Button } from "antd";
import moment from "moment";
import ReactExport from 'react-export-excel-xlsx-fix';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ContenidoExcelReq = (requerimientos, fechaInicio, fechaFin) => {
  return[
    /**EL "widthPx", SE TOMA EN CUENTA SOLO EL DE LA PRIMERA COLUMNA */
    /** SI NO TIENE NADA, SE TOMA EN CUENTA EL POR DEFECTO */
    {
      columns: [
        {
          value:``,
          style:{font:{bold:true}},
          widthPx: 130
        },
        {value:''},
        {
          value:``,
          widthPx: 150
        },
        { value:'',widthPx: 150},
        {
          value:``,
          style:{font:{bold:true}}
        },
        {value:''},
      ],
      data:[]
    },
    {
      ySteps: 1,
      columns: [
        {
          value: "FECHA"
        },
        {
          value: "CÓDIGO"
        },
        {
          value: "AREA"
          // widthPx: 130 //NO SE TOMA EN CUENTA, XQ YA HAY EN LA COLUMNA DE FECHA
        },
        {
          value: "RESPONSABLE"
        },
        {
          value: "PRIORIDAD"
        },
        {
          value: "TIPO"
        },
        {
          value: "ESTADO"
        },
        {
          value: "APROBADO"
        },
        {
          value: "FECHA ENTREGA",
          widthPx: 120
        }
      ],
      data: requerimientos.map(req => {
        return[
          {value: `${moment.utc(req.fecha).format("DD/MM/YYYY")}`},
          {value: `${req.codigo}`},
          {value: `${req.nombre_area}`},
          {value: `${req.nombre_responsable}`},
          {value: `${req.prioridad === null? 'PENDIENTE': req.prioridad}`},
          {value: `${req.tipo === null? 'PENDIENTE':req.tipo}`},
          {value: `${req.estado}`},
          {value: `${req.aprobado === null? 'PENDIENTE':req.aprobado}`},
          {value: `${req.fecha_entrega === null? 'PENDIENTE': moment.utc(req.fecha_entrega).format("DD/MM/YYYY")}`}
        ]
      })
    }
  ]
}

export const ExcelFileRequerimiento = (requerimientosListFiltrados, fechaInicio, fechaFin) => {
  return(
    <>
      <ExcelFile element={
        <Button className='bg-color-success' title="Exportar Excel" 
        // onClick={e => ExportarExcel()}
        icon={<FileExcelOutlined />}>EXCEL
        </Button>} filename="Requerimientos">
        <ExcelSheet dataSet={ContenidoExcelReq(requerimientosListFiltrados, fechaInicio, fechaFin)} 
          name="Requerimientos"/>
      </ExcelFile>
    </>
  )
}