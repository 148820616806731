import {useEffect, useRef, useState} from "react";
import { IPageData } from "../../../interfaces/page";
import { usePageData } from '../../../hooks/usePage';
import Cookies from "universal-cookie";
import moment from "moment";
import { Card, Table, Button, Select, Input, message } from 'antd';
import { Option } from "antd/lib/mentions";
import { EditOutlined, EyeOutlined, FileFilled, FilePdfOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { DownloadFile, existeRol, listaAreas } from "../FuncionesGenerales";
import ModalFormularioFactura from "./ModalFormularioFactura";
import ModalDetalleFactura from "./ModalDetalleFactura";
import FormularioPago from "../componentesPagos/FormularioPago";
import ModalSubirArchivo from "../ModalSubirArchivo";
import { PdfFileFacturas } from "../componentesPDF/GenerarPDFFacturas";
import { ExcelFileFacturas } from "../componentesExcel/GenerarExcelFacturas";

const cookies = new Cookies()

const pageData: IPageData = {
  fulFilled: true,
  title: 'Facturas',
  breadcrumbs: [
    {
      title: 'Facturas',
    }
  ]
};

const Facturas = () => {
  usePageData(pageData);
  const componentePDF = useRef(null)

  const [facturasList, setFacturasList] = useState([])
  const [proveedoresList, setProveedoresList] = useState([])
  const [fechaInicio, setFechaInicio] = useState(moment(new Date()).format("YYYY-MM-DD"))
  const [fechaFin, setFechaFin] = useState(moment(new Date()).format("YYYY-MM-DD"))
  const [selectProveedor, setSelectProveedor] = useState(undefined)
  const [selectMoneda, setSelectMoneda] = useState(undefined)
  const [selectArea, setSelectArea] = useState(undefined)
  const [selectTipoDoc, setSelectTipoDoc] = useState(undefined)
  const [selectTipoPago, setSelectTipoPago] = useState(undefined)
  const [selectFormaPago, setSelectFormaPago] = useState(undefined)
  const [selectTipo, setSelectTipo] = useState(undefined)
  const [inputNumOrden, setInputNumOrden] = useState('')
  const [inputCodigo, setInputCodigo] = useState('')
  const [inputRUC, setInputRUC] = useState('')
  const [inputSerie, setInputSerie] = useState('')
  const [inputNumeroSerie, setInputNumeroSerie] = useState('')
  const [openModal, setOpenModal] = useState(false);
  const [openModalDetalle, setOpenModalDetalle] = useState(false);
  const [openModalPago, setOpenModalPago] = useState(false);
  const [rolLogistica, setRolLogistica] = useState(false);
  const [permisoRegistroPago, setPermisoRegistroPago] = useState(false);
  const [idFactura, setIdFactura] = useState(null);
  const [detOrdenesCompraList, setDetOrdenesCompraList] = useState([])
  const [ordenCompra, setOrdenCompra] = useState({
    id_consolidado: null,
    ubicacion_archivo: null
  })
  const [selectPendiente, setSelectPendiente] = useState(undefined)
  const [factura, setFactura] = useState({id_factura: null});
  const [idPago, setIdPago] = useState(null);
  const [conceptosList, setConceptosList] = useState([])
  const [areasList, setAreasList] = useState([])
  const [ubicacionArchivo, setUbicacionArchivo] = useState(null);
  const [openModalArchivo, setOpenModalArchivo] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [estadoOrden, setEstadoOrden] = useState(null);

  const optionFilter = (input, option) =>
    option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;

  useEffect(() => {
    if (!cookies.get('nameid_logis') || existeRol('Logistica.Acceso.Facturas') === false) {
      // history.push(`/public/sign-in`)
      window.location.href='/'    
    } else {
      getFacturas()
      getProveedores()
      getConceptos()
      getAreas()
    }

    setRolLogistica(existeRol('Logistica.Gestion.Facturas'))
    setPermisoRegistroPago(existeRol('Tesoreria.Gestion.Pagos'))
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getFacturas()
    }, 10000);
    return () => clearInterval(interval);
  }, [facturasList])

  const getFacturas = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getFacturas/${1}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          setFacturasList([])
        } else {
          setFacturasList(data)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getConceptos = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getConceptos`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setConceptosList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getAreas = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getAreas/${1}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setAreasList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getProveedores = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getProveedores`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setProveedoresList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getDetalleOrdenbyIdOrden = async(id_orden_compra) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getDetalleOrdenbyIdOrden/${id_orden_compra}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setDetOrdenesCompraList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getOrdenCompraById = async(factura) => {
    // console.log(factura)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getOrdenCompraById/${factura.id_orden_compra}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setOrdenCompra(data)
        setIdFactura(factura.id_factura)
        setOpenModal(true)
        setEstadoOrden(data.estado)
        getDetalleOrdenbyIdOrden(factura.id_orden_compra)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const baseImponible = (item) => {
    // parseFloat(props.detOrdenesCompraList.map(det => det.cantidad * 0.82 * det.precio).reduce((prev, next) => prev + next)).toFixed(2)
    return((parseFloat(item.total)/ 1.18).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
  }

  const TotalIgv = (item) => {
    // parseFloat(props.detOrdenesCompraList.map(det => det.cantidad * 0.82 * det.precio).reduce((prev, next) => prev + next)).toFixed(2)
    return((parseFloat(item.total)/1.18 * 0.18).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
  }

  const TotalPagar = (item) => {
    if (item.afecta_detraccion === false) {
      return(item.total.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }))  
    } else {
      return((item.total *(1-(item.detraccion/100))).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
    }
    
  }

  const handleOpenModalForm = (factura) => {
    // console.log(factura)
    getOrdenCompraById(factura)
  }

  const handleModalPago = (item) => {
    // console.log(item)
    setFactura(item)
    setIdPago(null)
    setOpenModalPago(true)
  }

  const handleOpenModalDetalle = (item) => {
    setOpenModalDetalle(true)
    setIdFactura(item.id_factura)
  }

  const handleCloseModal = () => {
    setOpenModalPago(false)
    setIdPago(null)
    // setFactura({})
  }

  // const handleOpenModalArchivo = (record) => {
  //   // console.log(record)
  //   setOpenModalArchivo(true)
  //   setUbicacionArchivo(null)
  //   setFactura(record)
  // }

  const handleCloseModalArchivo = () => {
    setOpenModalArchivo(false)
    setUbicacionArchivo(null)
    setFactura({id_factura: null})
  }

  const columns: any = [
    {
      title: (<div style={{width:200}}></div>),
      render: record => {
        return(
          <>
            <Button type='primary' className="mr-2"
              title="Ver Datos de la Factura" 
              onClick={e => handleOpenModalDetalle(record)}
              icon={<EyeOutlined />} />

            {rolLogistica === true? 
              <Button type='primary' className="bg-color-orange mr-2"
                title="Editar Factura" 
                onClick={e => handleOpenModalForm(record)}
                icon={<EditOutlined />} />
            :null}

            {/* {record.ubicacion_archivo === null?null:
            <Button danger className='mr-2'
              title="Descargar Factura" 
              onClick={e => DownloadFile('Factura', record, record.id_factura)}
              icon={<FilePdfOutlined />} >
            </Button>} */}

            {/* {rolLogistica === true? 
              <Button type={'primary'} className='btn-outline mr-2' 
                title='Reemplazar PDF Factura' icon={<ReloadOutlined />}
                onClick={e=>handleOpenModalArchivo(record)} 
              />  
            :null} */}

            {record.ubicacion_archivo_xml === null?null:
            <Button type={"ghost"} className='mr-2'
              title="Descargar XML" 
              onClick={e => DownloadFile('XML', {
                ...record, otraUbicacion:record.ubicacion_archivo_xml
              }, record.id_factura)}
              icon={<FileFilled />} >
            </Button>}

            {/* {permisoRegistroPago === true && record.pago_pendiente !== 0? */}
              <Button className='bg-color-success' 
                title="Registrar Pago" 
                onClick={e => handleModalPago(record)}
                icon={<PlusOutlined />} >
              </Button>
            {/* :null} */}
          </>
        )
      }
    },
    {
      title: 'FECHA',
      dataIndex: 'fecha_emision',
      // key: 'fecha_emision',
      defaultSortOrder: 'descend',
      sorter: (a, b) => moment(a.fecha_emision).unix() - moment(b.fecha_emision).unix(),
      render: text => (
        moment.utc(text).format('DD/MM/YY')
      )
    },
    {
      title: 'F. VENC.',
      render: record => {
        if ( /* && */record.pago_pendiente > 0 ) {
          if ( moment(record.fecha_vencimiento) < moment(new Date) ) {
            // return(<p>{record.dias_vencido}</p>)
            return(<p>{moment.utc(record.fecha_vencimiento).format('DD/MM/YY')}</p>)  
          } else {
            // return(<p>{record.dias_vencido}</p>)
            return(<p>{moment.utc(record.fecha_vencimiento).format('DD/MM/YY')}</p>)  
          }
        } else {
          return(<p>{moment.utc(record.fecha_vencimiento).format('DD/MM/YY')}</p>)  
        }
      }
    },
    {
      title: (
        <>
          <div style={{width:120}}>
            <Input defaultValue={inputCodigo} 
              onChange={e=>setInputCodigo(e.currentTarget.value)}
                placeholder = "CÓDIGO"
            />
          </div>
        </>
      ),
      dataIndex: 'codigo',
      key: 'codigo'
    },
    {
      title: (
        <>
          <div style={{width:140}} className="mr-4">
            <Select
              allowClear
              defaultValue={selectTipo}
              className='mb-lg-0'
              placeholder='TIPO'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={(e)=>setSelectTipo(e)}
            >
              <Option value={'COMPRA'} key={'COMPRA'}>COMPRA</Option>
              <Option value={'SERVICIO'} key={'SERVICIO'}>SERVICIO</Option>
            </Select>
          </div>
        </>
      ),
      dataIndex: 'tipo',
      key: 'tipo'
    },
    {
      title: (
        <>
          <div style={{width:120}}>
            <Input defaultValue={inputNumOrden} 
              onChange={e=>setInputNumOrden(e.currentTarget.value)}
                placeholder = "NÚM. ORDEN"
            />
          </div>
        </>
      ),
      dataIndex: 'numero_orden',
      key: 'numero_orden'
    },
    {
      title: (
        <>
          <div style={{width:140}} className="mr-4">
            <Select
              allowClear
              defaultValue={selectTipoDoc}
              className='mb-lg-0'
              placeholder='DOCUMENTO'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={(e)=>setSelectTipoDoc(e)}
            >
              <Option value='BOLETA' key='BOLETA'>BOLETA</Option>
              <Option value='FACTURA' key='FACTURA'>FACTURA</Option>
              <Option value='HONORARIO' key='HONORARIO'>HONORARIO</Option>
            </Select>
          </div>
        </>
      ),
      dataIndex: 'tipo_documento',
      key: 'tipo_documento'
    },
    {
      title: (
        <>
          <div style={{width:130}}>
            <Input defaultValue={inputSerie} 
              onChange={e=>setInputSerie(e.currentTarget.value)}
                placeholder = "SERIE"
            />
          </div>
        </>
      ),
      dataIndex: 'serie',
      key: 'serie'
    },
    {
      title: (
        <>
          <div style={{width:130}}>
            <Input defaultValue={inputNumeroSerie} 
              onChange={e=>setInputNumeroSerie(e.currentTarget.value)}
                placeholder = "NÚM. SERIE"
            />
          </div>
        </>
      ),
      dataIndex: 'numero_serie',
      key: 'numero_serie'
    },
    {
      title: (
        <>
          <div style={{width:130}}>
            <Input defaultValue={inputRUC} 
              onChange={e=>setInputRUC(e.currentTarget.value)}
                placeholder = "RUC"
            />
          </div>
        </>
      ),
      dataIndex: 'ruc',
      key: 'ruc'
    },
    {
      title: (
        <>
          <div style={{width:180}} className="mr-4">
            <Select
              allowClear
              showSearch
              defaultValue={selectProveedor}
              className='mb-lg-0'
              placeholder='PROVEEDOR'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={(e)=>setSelectProveedor(e)}
            >
            {proveedoresList.map(proveedor =>{
              return(
                <Option value={proveedor.id_prove} key={proveedor.id_prove}>{proveedor.descripcion}</Option>
              )
            })}
            </Select>
          </div>
        </>
      ),
      dataIndex: 'descripcion',
      key: 'descripcion'
    },
    {
      title: (<><div style={{width:180}}>RESUMEN</div></>),
      dataIndex: 'resumen',
      key: 'resumen'
    },
    {
      title: (
        <>
          <div style={{width:110}}>
            <Select
              allowClear
              defaultValue={selectMoneda}
              className='mb-lg-0'
              placeholder='MONEDA'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={(e)=>setSelectMoneda(e)}
            >
              <Option value='S/.' key='S/.'>S/.</Option>
              <Option value='$  ' key='$  '>$</Option>
            </Select>
          </div>
        </>
      ),
      dataIndex: 'Parc_Valor',
      key: 'Parc_Valor'
    },
    {
      title: 'BASE IMPONIBLE',
      render: record => {
        return(
          <>
            <div>{baseImponible(record)}</div>
          </>
        )
      }
    },
    {
      title: 'IGV',
      render: record => {
        return(
          <>
            <div>{TotalIgv(record)}</div>
          </>
        )
      }
    },
    {
      title: 'TOTAL',
      render: record => {
        return(<>{record.total.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>)
      }
    },
    {
      title: '% DET.',
      render: record => {
        return(<>{record.detraccion === null?0:record.detraccion}</>)
      }
    },
    {
      title: 'TOTAL A PAGAR',
      render: record => {
        return(<>{TotalPagar(record)}</>)
      }
    },
    {
      title: 'MONTO PENDIENTE',
      render: record => {
        return(<>{record.pago_pendiente.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>)
      }
    },
    {
      title: (
        <>
          <div style={{width:140}} className="mr-4">
            <Select
              allowClear
              defaultValue={selectTipoPago}
              className='mb-lg-0'
              placeholder='TIPO PAGO'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={(e)=>setSelectTipoPago(e)}
            >
              <Option value={'CREDITO'} key={'CREDITO'}>CRÉDITO</Option>
              <Option value={'CONTADO'} key={'CONTADO'}>CONTADO</Option>
              <Option value={'ADELANTO'} key={'ADELANTO'}>PAGO ADELANTADO</Option>
            </Select>
          </div>
        </>
      ),
      dataIndex: 'tipo_pago',
      key: 'tipo_pago'
    },
    {
      title: (
        <>
          <div style={{width:140}} className="mr-4">
            <Select
              allowClear
              defaultValue={selectFormaPago}
              className='mb-lg-0'
              placeholder='FORMA PAGO'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={(e)=>setSelectFormaPago(e)}
            >
              <Option value={'EFECTIVO'} key={'EFECTIVO'}>EFECTIVO</Option>
              <Option value={'TRANSFERENCIA'} key={'TRANSFERENCIA'}>TRANSFERENCIA</Option>
            </Select>
          </div>
        </>
      ),
      dataIndex: 'forma_pago',
      key: 'forma_pago'
    },
    {
      title: (
        <>
          <div style={{width:250}}>
          <Select
              showSearch
              allowClear
              defaultValue={selectArea}
              className='mr-2'
              placeholder='ÁREA'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={e => setSelectArea(e)}
            >
              {areasList.map(area =>{
                return(
                  <Option value={area.nombre} key={area.nombre}>{area.nombre}</Option>
                )
              })}
            </Select>  
          </div>
        </>
      ),
      render: record => {
        if (record.id_consolidado === null) {
          return(<>{record.nombre_area}</>)
        } else {
          return(<>{listaAreas(record.nombre_area)}</>)  
        }
      }
    }
  ]

  const facturasListFiltradas = () => {
    // console.log(facturasList)
    const lista = facturasList.filter(factura => {
      return(
        moment.utc(factura.fecha_emision).format("YYYY-MM-DD") >= fechaInicio && 
        moment.utc(factura.fecha_emision).format("YYYY-MM-DD") <= fechaFin &&
        factura.numero_orden.toUpperCase().includes(inputNumOrden.toUpperCase()) && factura.ruc.includes(inputRUC) && 
        factura.codigo.toUpperCase().includes(inputCodigo.toUpperCase()) &&
        (selectProveedor === undefined? factura.id_proveedor !== selectProveedor: factura.id_proveedor === selectProveedor) &&
        (selectTipoDoc === undefined? factura.tipo_documento !== selectTipoDoc: factura.tipo_documento === selectTipoDoc) &&
        (selectMoneda === undefined? factura.Parc_Valor !== selectMoneda: factura.Parc_Valor === selectMoneda) &&
        (selectTipo === undefined? factura.tipo !== selectTipo: factura.tipo === selectTipo) &&
        factura.serie.toUpperCase().includes(inputSerie.toUpperCase()) && factura.numero_serie.includes(inputNumeroSerie) &&
        (selectPendiente === undefined? factura.pago_pendiente !== null:
          selectPendiente === 'SI'? factura.pago_pendiente > 0: factura.pago_pendiente === 0) &&
        (selectFormaPago === undefined? factura.forma_pago !== selectFormaPago: factura.forma_pago === selectFormaPago) &&
        (selectTipoPago === undefined? factura.tipo_pago !== selectTipoPago: factura.tipo_pago === selectTipoPago) &&
        (selectArea === undefined? factura.nombre_area !== selectArea: factura.nombre_area.includes(selectArea))
      )
    })

    return lista
  }

  const subirArchivo = async() => {
    // console.log(ubicacionArchivo)
    // console.log(factura)
    const body = {
      destination:'Logistica',
      filename:`Factura_${factura.id_factura}.pdf`,
      id_factura: factura.id_factura
    }

    if (ubicacionArchivo === null) {
      message.error('Debe Subir el PDF de la Factura')
    } else {
      if (ubicacionArchivo[0] === undefined) {
        message.error('Debe Subir el PDF de la Factura')
      } else {
        const bodyForm = new FormData()

        bodyForm.append('tabla', JSON.stringify(body))
        bodyForm.append('File', ubicacionArchivo[0])

        try {
          await fetch(`${process.env.REACT_APP_HOSTNAME}/UploadFileFactura`,{
            method: "POST",
            body: bodyForm,
          }).then(res => res.json())
          .then(data =>{
            message.success(data.mensaje)
            setLoadingButton(false)
            handleCloseModalArchivo()
            getFacturas()
          }).catch(err => {
            console.log(err)
            message.error('No se Pudo Subir el Archivo al Servidor')
            setLoadingButton(false)
          });
        } catch (error) {
          console.log(error)
          message.error('No se Pudo Subir el Archivo al Servidor')
          setLoadingButton(false)
        }
      }
    }
  }

  const ExportarPDF = () => {
    componentePDF.current.save()
  }

  const CuadroResumen = (listaFiltrada) => {
    // console.log(facturasList)

    const cantOCFiltradas = listaFiltrada.filter( oc => {
      return(oc.tipo === 'COMPRA')
    })

    const cantOSFiltradas = listaFiltrada.filter( oc => {
      return(oc.tipo === 'SERVICIO')
    })

    const cantOC = facturasList.filter( oc => {
      return(oc.tipo === 'COMPRA')
    })

    const cantOS = facturasList.filter( oc => {
      return(oc.tipo === 'SERVICIO')
    })

    const cantPagadas = listaFiltrada.filter( oc => {
      return(oc.pago_pendiente === 0)
    })

    const cantPendientes = listaFiltrada.filter( oc => {
      return(oc.pago_pendiente > 0)
    })

    return (
      <>
        <div className="ml-2">
          <b>Órdenes Compra: {cantOCFiltradas.length}</b>
        </div>

        <div className="ml-2">
          <b>Órdenes Servicio: {cantOSFiltradas.length}</b> 
        </div>

        <div className="d-flex justify-content-between ml-2 mr-2 mb-2">
          <b>Pagadas: {cantPagadas.length}</b>
          <b>Pendientes: {cantPendientes.length}</b>
        </div>

        <hr className="k-hr" style={{borderColor:'black'}}/>

        <div className="d-flex justify-content-between ml-2 mr-2 mb-2">
          <b>Total OC: {cantOC.length}</b>
          <b>Total OS: {cantOS.length}</b>
          <b>Total General: {facturasList.length}</b>
        </div>
      </>
    )
  } 

  if (facturasList.length > 0) {
    return(
      <>
        {PdfFileFacturas(componentePDF, facturasListFiltradas())}
        <div className='row'>
          <div className='col-md-3 col-lg-3'>
            <label>Desde: </label> <br/> 
            <Input type="date"/*"datetime-local"*/
              value={fechaInicio}
              onChange={e => setFechaInicio(e.currentTarget.value)}
            />
          </div>

          <div className='col-md-3 col-lg-3'>
            <label>Hasta: </label> <br/> 
            <Input type="date"/*"datetime-local"*/
              value={fechaFin} 
              onChange={e => setFechaFin(e.currentTarget.value)}
            />  
          </div>
        </div>

        <br />

        <div className="elem-list mb-2">
          <div className='col-sm-6 col-md-3 col-lg-3'>
            <label>Cuentas: </label>
            <div style={{width:190}}>
              <Select
                allowClear
                defaultValue={selectTipoDoc}
                className='mb-lg-0'
                placeholder='Seleccionar'
                optionFilterProp='children'
                filterOption={optionFilter}
                onChange={(e)=>setSelectPendiente(e)}
              >
                <Option value='SI' key='SI'>Cuentas por Pagar</Option>
                <Option value='NO' key='NO'>Cuentas Pagadas</Option>
              </Select>
            </div>  
          </div>

          <div className='elem-list mt-4'>
            <Button danger 
              title="Exportar PDF" 
              onClick={e => ExportarPDF()}
              icon={<FilePdfOutlined />} >PDF
            </Button>
            {ExcelFileFacturas(facturasListFiltradas())}
          </div>
        </div>

        <Card 
          style={{background:'#D6D6D6', maxWidth: 400 }} 
        >
          {CuadroResumen(facturasListFiltradas())}
        </Card>

        <Card>
          <div>
            <Table
              columns={columns}
              dataSource={facturasListFiltradas()}
              size='small'
            />
          </div>
        </Card>

        {<ModalFormularioFactura
          setOpenModal = {setOpenModal}
          openModal = {openModal}
          idFactura = {idFactura}
          orden_compra = {ordenCompra}
          detOrdenesCompraList = {detOrdenesCompraList}
          setDetOrdenesCompraList = {setDetOrdenesCompraList}
          setIdFactura = {setIdFactura}
          getDetalleOrdenbyIdOrden = {getDetalleOrdenbyIdOrden}
          getFacturas = {getFacturas}
          setEstadoOrden = {setEstadoOrden}
          estadoOrden = {estadoOrden}
        />}

        {<ModalDetalleFactura
          setOpenModal = {setOpenModalDetalle}
          openModal = {openModalDetalle}
          idFactura = {idFactura}
          detOrdenesCompraList = {detOrdenesCompraList}
          setDetOrdenesCompraList = {setDetOrdenesCompraList}
          setIdFactura = {setIdFactura}
          getFacturas = {getFacturas}
        />}

        {<FormularioPago
          operacion = 'EGRESO'
          disabled = {true}
          usuario = {cookies.get('nameid_logis')}
          conceptosList = {conceptosList}
          idPago = {idPago}
          setIdPago = {setIdPago}
          handleCloseModal = {handleCloseModal}
          openModal = {openModalPago}
          factura = {factura}
          getFacturas = {getFacturas}
        />}

        {<ModalSubirArchivo
          loadingButtonAprobar = {loadingButton}
          setLoadingButtonAprobar = {setLoadingButton}
          openModal = {openModalArchivo}
          handleCloseModal = {handleCloseModalArchivo}
          ubicacion_archivo = {ubicacionArchivo}
          setUbicacionArchivo = {setUbicacionArchivo}
          UploadArchivo = {subirArchivo}
        />}
      </>
    )
  } else {
    return (
      <>
        <div className='row'>
          <div className='col-md-3 col-lg-3'>
            <label>Desde: </label> <br/> 
            <Input type="date"/*"datetime-local"*/
              value={fechaInicio}
              onChange={e => setFechaInicio(e.currentTarget.value)}
            />
          </div>

          <div className='col-md-3 col-lg-3'>
            <label>Hasta: </label> <br/> 
            <Input type="date"/*"datetime-local"*/
              value={fechaFin} 
              onChange={e => setFechaFin(e.currentTarget.value)}
            />  
          </div>
        </div>

        <div className="row">
          <div className='col-sm-12 col-md-4 col-lg-4'>
            <label>Cuentas: </label>
            <div style={{width:190}} className="mr-4">
              <Select
                allowClear
                defaultValue={selectTipoDoc}
                className='mb-lg-0'
                placeholder='Seleccionar'
                optionFilterProp='children'
                filterOption={optionFilter}
                onChange={(e)=>setSelectPendiente(e)}
              >
                <Option value='SI' key='SI'>Cuentas por Pagar</Option>
                <Option value='NO' key='NO'>Cuentas Pagadas</Option>
              </Select>
            </div>  
          </div>
        </div>

        <Card>
          <div>
            No hay Datos
          </div>
        </Card>
      </>
    )
  }
}

export default Facturas