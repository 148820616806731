import {useEffect, useState} from "react"
import moment from "moment";
import { Table } from 'antd'

const InfoInventario = () => {
  const [inventario, setInventario] = useState({
    codigo_patrimonial: null,
    nombre_articulo: null,
    nombre_area: null,
    identificador: null,
    marca: null,
    modelo: null,
    serie: null,
    operativo: null,
    proveedor: null,
    ultima_fecha_mant: null,
    proveedor_ultimo_mant: null,
    meses_proximo_mant: null,
    ultima_fecha_cali: null,
    proveedor_ultimo_cali: null,
    meses_proximo_cali: null,
    descripcion: null,
    tipo_mant: null,
    ultimo_mant_programado: null
  })

  const [listaOrdenes, setListaOrdenes] = useState([])

  const queryParameters = new URLSearchParams(window.location.search)
  const id = queryParameters.get("id")

  useEffect(() => {
    // console.log(id)
    GetInventarioInfoById()
    GetOrdenesTrabajoByIdInventario()
  }, [])

  const GetInventarioInfoById = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/GetInventarioInfoById/${id}`)
        .then(res => res.json())
        .then(data => {
          // console.log(data)
          setInventario(data)
        })
    } catch (error) {
      console.log(error)
    }
  }

  const GetOrdenesTrabajoByIdInventario = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/GetOrdenesTrabajoByIdInventario/${id}`)
        .then(res => res.json())
        .then(data => {
          // console.log(data)
          setListaOrdenes(data)
        })
    } catch (error) {
      console.log(error)
    }
  }

  const columns = [
    {
      title: 'FECHA',
      render: record => {
        return(<div>{moment.utc(record.fecha_orden).format('DD/MM/YY')}</div>)
      }
    },
    {
      title: 'DESCRIPCIÓN',
      key: 'descripcion',
      dataIndex: 'descripcion'
    },  
    {
      title: 'PROVEEDOR',
      key: 'proveedor',
      dataIndex: 'proveedor'
    },  
    {
      title: 'TIPO MANT.',
      key: 'tipo_mantenimiento',
      dataIndex: 'tipo_mantenimiento'
    }, 
    {
      title: 'TIPO',
      key: 'tipo',
      dataIndex: 'tipo'
    }, 
    {
      title: 'ESTADO',
      key: 'estado',
      dataIndex: 'estado'
    }, 
    {
      title: 'PRECIO',
      key: 'precio',
      dataIndex: 'precio'
    }
  ]

  return(
    <>
      <div className='mt-4 ml-4 mb-4' style={{height: '100%', overflowX: 'hidden', overflowY: 'auto'}}>
        <h4>DATOS DEL INVENTARIO:</h4>
        <div className='elem-list'>
          <div className='col-sm-12 col-md-3 col-lg-3'>
            <b>CÓD. PATRIMONIAL:</b> <br />
            <p>{inventario.codigo_patrimonial}</p>
          </div>

          <div className='col-sm-12 col-md-4 col-lg-4'>
            <b>EQUIPO:</b> <br />
            <p>{inventario.identificador} - {inventario.nombre_articulo}</p>
          </div>

          <div className='col-sm-12 col-md-4 col-lg-4'>
            <b>UBICACIÓN:</b> <br />
            <p>{inventario.nombre_area}</p>
          </div>
        </div>

        <div className='elem-list'>
          <div className='col-sm-12 col-md-3 col-lg-3'>
            <b>MARCA:</b> <br />
            <p>{inventario.marca}</p>
          </div>

          <div className='col-sm-12 col-md-4 col-lg-4'>
            <b>MODELO:</b> <br />
            <p>{inventario.modelo}</p>
          </div>

          <div className='col-sm-12 col-md-4 col-lg-4'>
            <b>SERIE:</b> <br />
            <p>{inventario.serie}</p>
          </div>
        </div>

        <div className='elem-list'>
          <div className='col-sm-12 col-md-3 col-lg-3'>
            <b>OPERATIVO:</b> <br />
            <p>{inventario.operativo === false?'NO':'SI'}</p>
          </div>

          <div className='col-sm-12 col-md-8 col-lg-8'>
            <b>PROVEEDOR:</b> <br />
            <p>{inventario.proveedor}</p>
          </div>
        </div>

        <h4>DATOS DE MANTENIMIENTO:</h4>
        <div className='elem-list'>
          <div className='col-sm-12 col-md-3 col-lg-3'>
            <b>FECHA ÚLT. MANTENIMIENTO:</b> <br />
            <p>{moment.utc(inventario.ultima_fecha_mant).format('DD/MM/YY')} {`(${inventario.tipo_mant})`}</p>
          </div>

          <div className='col-sm-12 col-md-4 col-lg-4'>
            <b>PROVEEDOR:</b> <br />
            <p>{inventario.proveedor_ultimo_mant}</p>
          </div>

          <div className='col-sm-12 col-md-3 col-lg-3'>
            <b>FECHA PRÓX. MANTENIMIENTO:</b> <br />
            <p>{inventario.ultimo_mant_programado === null?null:
              moment.utc(inventario.ultimo_mant_programado).add(inventario.meses_proximo_mant, 'M').format('DD/MM/YY')}
            </p>
          </div>
        </div>

        <h4>DATOS DE CALIBRACIÓN:</h4>
        <div className='elem-list mb-4'>
          <div className='col-sm-12 col-md-3 col-lg-3'>
            <b>FECHA ÚLT. CALIBRACIÓN:</b> <br />
            <p>{inventario.ultima_fecha_cali === null?null:moment.utc(inventario.ultima_fecha_cali).format('DD/MM/YY')}</p>
          </div>

          <div className='col-sm-12 col-md-4 col-lg-4'>
            <b>PROVEEDOR:</b> <br />
            <p>{inventario.proveedor_ultimo_cali}</p>
          </div>

          <div className='col-sm-12 col-md-3 col-lg-3'>
            <b>FECHA PRÓX. CALIBRACIÓN:</b> <br />
            <p>{inventario.ultima_fecha_cali === null?null:
              moment.utc(inventario.ultima_fecha_cali).add(inventario.meses_proximo_cali, 'M').format('DD/MM/YY')}
            </p>
          </div>
        </div>

        <h4>ÓRDENES DE TRABAJO:</h4>
        {listaOrdenes.length > 0? 
        <div className='col-sm-12 col-md-12 col-lg-12'>
          <Table 
              pagination={false} 
              columns={columns}
              dataSource={listaOrdenes}
              size="small"
            />
        </div>
        :<b>NO HAY ÓRDENES REGISTRADAS</b>
        }
      </div>
    </>
  )
}

export default InfoInventario;
