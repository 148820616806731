import {useEffect, useState, useRef } from "react"
import { IPageData } from "../../../interfaces/page"
import { usePageData } from '../../../hooks/usePage'
import Cookies from "universal-cookie"
import { Card, Table, Button, Select, Input } from 'antd'
import { Option } from "antd/lib/mentions"
import { PlusOutlined, EditOutlined, FileOutlined, FileExcelOutlined } from "@ant-design/icons"
import { existeRol } from "../FuncionesGenerales"
import moment from "moment"
import ModalFormResiduos from "./ModalFormResiduos"
import ModalFilesResiduos from "./ModalFilesResiduos"
import { ConsolidadResiduosExcel } from "../componentesExcel/GenerarExcelResiduos"

const cookies = new Cookies()

const pageData: IPageData = {
  fulFilled: true,
  title: 'Residuos Sólidos',
  breadcrumbs: [
    {
      title: 'Residuos Sólidos',
    }
  ]
}

const Residuos = () => {
  usePageData(pageData)
  const [residuosList, setResiduosList] = useState([])
  const [residuosListByAreaYMes, setResiduosListByAreaYMes] = useState([])
  const [areasList, setAreasList] = useState([])
  const [sucursalesList, setSucursalesList] = useState([])
  const [selectTipo, setSelectTipo] = useState(undefined)
  const [selectArea, setSelectArea] = useState(undefined)
  const [selectSucursal, setSelectSucursal] = useState(undefined)
  const [openModal, setOpenModal] = useState(false)
  const [openModalArchivos, setOpenModalArchivos] = useState(false)
  const [inputNombre, setInputNombre] = useState('')
  const [fechaInicio, setFechaInicio] = useState(''/*moment(new Date()).format("YYYY-MM").concat('-01')*/)
  const [fechaFin, setFechaFin] = useState(moment(new Date()).format("YYYY-MM-DD"))
  const [idResiduo, setIdResiduo] = useState(null)
  const [encargadosList, setEncargadosList] = useState([])
  const [user, setUser] = useState({DocumentoIdentidad:null})
  const componenteExcel = useRef(null)
  const componenteExcel2 = useRef(null)
  const componenteExcel3 = useRef(null)

  const optionFilter = (input, option) =>
    option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0

  useEffect(() => {
    if (!cookies.get('nameid_logis') || existeRol('Logistica.Acceso.ResiduosSolidos') === false) {
      // history.push(`/public/sign-in`)
      window.location.href='/'    
    } else {
      GetEncargadosResiduos()
      GetResiduosSolidosAcumuladosByAreas()
      GetResiduosSolidos()
      getAreas()
      getSucursales()
      getUser()
    }
  }, [])

  const GetResiduosSolidos = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/GetResiduosSolidos`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          setResiduosList([])
        } else {
          setResiduosList(data)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const GetResiduosSolidosAcumuladosByAreas = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/GetResiduosSolidosAcumuladosByAreas`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          setResiduosListByAreaYMes([])
        } else {
          setResiduosListByAreaYMes(data)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getUser = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getUser/${cookies.get('nameid_logis')}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data, data.DocumentoIdentidad)
        // console.log(responsablesAreasList)
        if (data.message) {
          setUser({DocumentoIdentidad: null})
        } else {
          setUser(data)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const GetEncargadosResiduos = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/GetEncargadosResiduos`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          setEncargadosList([])
        } else {
          setEncargadosList(data)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getAreas = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getAllAreas`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setAreasList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getSucursales = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getSucursales`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setSucursalesList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleOpenModalArchivos = (residuo) => {
    setOpenModalArchivos(true)
    setIdResiduo(residuo.id_residuo)
  }

  const findUsuario = () => {
    // console.log(user)
    const usuario_lista = encargadosList.filter(enc => {
      return( enc.DocumentoIdentidad === user.DocumentoIdentidad && enc.activo === 'SI' )
    })
    // console.log(usuario_lista)
    return usuario_lista.length > 0? usuario_lista: []

  }

  const filtrarAreas = () => {
    // console.log(user)
    const usuario = findUsuario()
    // console.log(usuario)
    // console.log(JSON.parse(usuario[0].nombre_area))
    const areas = usuario.length > 0? JSON.parse(usuario[0].nombre_area): []
    // console.log(areas) 
    let listaFinalAreas = []
    for (let i = 0; i < areasList.length; i++) {
      const element = areas.filter(ar => {
        return(areasList[i].id_area === ar.id_area)
      });
      if (element.length > 0) {
        listaFinalAreas = [...listaFinalAreas, areasList[i]]
      }
    }
    // console.log(listaFinalAreas)
    return listaFinalAreas
  }

  const filtrarSucursales = () => {
    const usuario = findUsuario()
    const sucursales = usuario.length > 0? JSON.parse(usuario[0].nombre_sucursales): []
    let listaFinalSucursales = []
    for (let i = 0; i < sucursalesList.length; i++) {
      const element = sucursales.filter(suc => {
        return(sucursalesList[i].id_sucursal === suc.id_sucursal)
      });
      if (element.length > 0) {
        listaFinalSucursales = [...listaFinalSucursales, sucursalesList[i]]
      }
    }
    return listaFinalSucursales
  }

  const columns: any = [
    {
      title: <div style={{width:80}}/>,
      render: record => {
        return(
          <>
            {record.usuario === cookies.get('nameid_logis')?
              <Button type='primary' className="bg-color-orange mr-2" icon={<EditOutlined />} 
                title="Editar" onClick={e=>handleOpenModal(record.id_residuo)}
              />
            :null}
            <Button title="Ver Archivos" onClick={e=>handleOpenModalArchivos(record)} icon={<FileOutlined />} />
          </>
        )
      }
    },
    {
      title: 'FECHA',
      dataIndex: 'fecha',
      // key: 'fecha',
      defaultSortOrder: 'descend',
      sorter: (a, b) => moment(a.fecha).unix() - moment(b.fecha).unix(),
      render: text => (
        moment.utc(text).format('DD/MM/YY')
      )
    },
    {
      title: (
        <>
          <div style={{width:150}}>
            <Select
              showSearch
              allowClear
              value={selectTipo}
              className='mr-2'
              placeholder='TIPO'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={e => setSelectTipo(e)}
            >
              <Option value={'ESPECIAL'} key={'ESPECIAL'}>ESPECIAL</Option>
              <Option value={'BIOMEDICO'} key={'BIOMEDICO'}>BIOMÉDICO</Option>
            </Select>  
          </div>
        </>
      ),
      dataIndex: 'tipo',
      key: 'tipo'
    },
    {
      title: (
        <>
          <div style={{width:200}}>
            <Input value={inputNombre} 
              onChange={e=>setInputNombre(e.currentTarget.value)}
                placeholder = "PERSONAL"
            />
          </div>
        </>
      ),
      dataIndex: 'nombres',
      key: 'nombres'
    },
    {
      title: (
        <>
          <div style={{width:250}}>
            <Select
              showSearch
              allowClear
              value={selectArea}
              className='mr-2'
              placeholder='ÁREA'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={e => setSelectArea(e)}
            >
              {filtrarAreas().map(area =>{
                return(
                  <Option value={area.id_area} key={area.id_area}>{area.nombre}</Option>
                )
              })}
            </Select>  
          </div>
        </>
      ),
      dataIndex: 'nombre_area',
      key: 'nombre_area'
    },
    {
      title: (
        <>
          <div style={{width:250}}>
          <Select
              showSearch
              allowClear
              value={selectSucursal}
              className='mr-2'
              placeholder='SEDE'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={e => setSelectSucursal(e)}
            >
              {filtrarSucursales()/*sucursalesList*/.map(sucursal =>{
                return(
                  <Option value={sucursal.id_sucursal} key={sucursal.id_sucursal}>{sucursal.nombre}</Option>
                )
              })}
            </Select>  
          </div>
        </>
      ),
      dataIndex: 'nombre_sucursal',
      key: 'nombre_sucursal'
    },
    {
      title: 'CANTIDAD',
      dataIndex: 'cantidad',
      key: 'cantidad'
    }
  ]

  const ResiduosListFiltrados = () => {
    if (findUsuario().length > 0) {
      // console.log(residuosList)
      let lista_por_areas = []

      const areas = filtrarAreas()

      for (let i = 0; i < residuosList.length; i++) {
        // const element = lista[i];
        const elementArea = areas.filter( a => {
          return a.id_area === residuosList[i].id_area
        })
        // console.log(elementArea)
        if (elementArea.length > 0) {
          lista_por_areas = [...lista_por_areas, residuosList[i]]
        }
      }

      const lista = lista_por_areas.filter(residuo => {
        return(
          moment.utc(residuo.fecha).format("YYYY-MM-DD") >= fechaInicio && 
          moment.utc(residuo.fecha).format("YYYY-MM-DD") <= fechaFin && 
          residuo.nombres.toUpperCase().includes(inputNombre.toUpperCase()) &&
          (selectArea === undefined? residuo.id_area !== selectArea: residuo.id_area === selectArea) &&
          (selectSucursal === undefined? residuo.id_sucursal !== selectSucursal: residuo.id_sucursal === selectSucursal) &&
          (selectTipo === undefined? residuo.tipo !== selectTipo: residuo.tipo === selectTipo) 
        )
      })

      // console.log(lista_por_areas)

      return lista  
    } else {
      return []
    }
    
  }

  const handleOpenModal = async(id_residuo) => {
    setOpenModal(true)
    setIdResiduo(id_residuo)
  }

  const exportExcel = () => {
    const optionsGridOne = componenteExcel.current.workbookOptions()
    const optionsGridTwo = componenteExcel2.current.workbookOptions()
    const optionsGridtree = componenteExcel3.current.workbookOptions()
    optionsGridOne.sheets[1] = optionsGridTwo.sheets[0]
    optionsGridOne.sheets[2] = optionsGridtree.sheets[0]
    optionsGridOne.sheets[0].title = "R.S. CONSOLIDADO (KG)"
    optionsGridOne.sheets[1].title = "R.S. CONSOLIDADO (T)"
    optionsGridOne.sheets[2].title = "R.S. UPSS"
    componenteExcel.current.save(optionsGridOne)
  }

  return (
    <>
      <div className='row'>
        <div className='col-md-3 col-lg-3'>
          <label>Desde: </label> <br/> 
          <Input type="date"
            value={fechaInicio}
            onChange={e => setFechaInicio(e.currentTarget.value)}
          />
        </div>

        <div className='col-md-3 col-lg-3'>
          <label>Hasta: </label> <br/> 
          <Input type="date"/*"datetime-local"*/
            value={fechaFin} 
            onChange={e => setFechaFin(e.currentTarget.value)}
          />  
        </div>

        <div className='elem-list mt-4'>
          <Button type='primary'  
            title="Nuevo Personal Encargado" 
            onClick={e=>handleOpenModal(null)}
            icon={<PlusOutlined />} >Agregar
          </Button>  

          <Button className='bg-color-success' title="Exportar Excel" icon={<FileExcelOutlined />}
            onClick={exportExcel}>CONSOLIDADO
          </Button>
          {ConsolidadResiduosExcel(componenteExcel, componenteExcel2, componenteExcel3, residuosList, residuosListByAreaYMes)}
        </div>
      </div>

      <Card>
        <div>
          <Table
            columns={columns}
            dataSource={ResiduosListFiltrados()}
            size='small'
          />
        </div>
      </Card>

      {<ModalFormResiduos
        encargadosList = {encargadosList}
        idResiduo = {idResiduo}
        setIdResiduo = {setIdResiduo}
        usuario = {cookies.get('nameid_logis')}
        openModal = {openModal}
        setOpenModal = {setOpenModal}
        areasList = {filtrarAreas()}
        encargado = {findUsuario()}
        sucursalesList = {filtrarSucursales()}
        GetResiduosSolidos = {GetResiduosSolidos}
      />}

      {<ModalFilesResiduos
        openModal = {openModalArchivos}
        setOpenModal = {setOpenModalArchivos}
        idResiduo = {idResiduo}
        setIdResiduo = {setIdResiduo}
      />}
    </>
  )
}
 
export default Residuos