import {useEffect, useRef, useState} from "react";
import { IPageData } from "../../../interfaces/page";
import { usePageData } from '../../../hooks/usePage';
import Cookies from "universal-cookie";
import { Button, Select, message } from 'antd';
import { FilePdfOutlined } from "@ant-design/icons";
import { Option } from 'antd/lib/mentions';
import { existeRol } from "../FuncionesGenerales";
// import moment from "moment";
import { PdfFileKardex } from "../componentesPDF/GenerarPDFKardex";
import { ExcelFileKardex } from "../componentesExcel/GenerarExcelKardex";

const cookies = new Cookies()

const pageData: IPageData = {
  fulFilled: true,
  title: 'Kardex',
  breadcrumbs: [
    {
      title: 'Kardex',
    }
  ]
};

const Kardex = () => {
  usePageData(pageData);
  const componentePDF = useRef(null)

  const [articulosList, setArticulosList] = useState([])
  const [selectArticulo, setSelectArticulo] = useState(undefined);
  const [loadingButton, setLoadingButton] = useState(false);
  const [kardex, setKardex] = useState([])
  const [articulo, setArticulo] = useState({})


  useEffect(() => {
    if (!cookies.get('nameid_logis') || existeRol('Logistica.Acceso.Articulos') === false) {
      // history.push(`/public/sign-in`)
      window.location.href='/'    
    } else {
      getArticulos()
    }

    // setRolLogistica(existeRol('Logistica.Gestion.Articulos'))

  }, []);

  const optionFilter = (input, option) => {
    return (option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0)
  }

  const getArticulos = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getArticulos`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          setArticulosList([])
        } else {
          setArticulosList(data)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const articuloChange = async(id_articulo) => {
    setSelectArticulo(id_articulo)
    const art = articulosList.filter(item => {
      return item.id_articulo === id_articulo
    })
    // console.log(art)
    setArticulo(art[0])

    try {
      setLoadingButton(true)
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getKardexByIdArticulo`,{
        method: "POST",
        body: JSON.stringify({id_articulo: id_articulo, id_sucursal: 1}),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data => {
        // console.log(data)
        setLoadingButton(false)
        if (data.message) {
          setKardex([])
        } else {
          // setKardex(data)
          valoresKardex(data)
        }
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error)
      setLoadingButton(false)
      message.error('Ocurrió un error al traer la info.')
    }
  }

  const valoresKardex = (data) => {
    // console.log(data)
    if (data[0].tipo === 'SALIDA') {
      message.warn('El primer registro de movimiento del item es una SALIDA')
    }

    let lista = []
    
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      // console.log(element)
      let item = {
        fecha: element.fecha, descripcion: element.descripcion,
        valor_unitario: i === 0? parseFloat((element.precio * element.tipo_cambio).toFixed(2)): 0,
        cantidad_entrada: element.tipo === 'ENTRADA'? parseFloat((element.cantidad * element.cantidad_unidades).toFixed(2)): 0,
        valor_entrada: 0,
        cantidad_salida: element.tipo === 'SALIDA'? parseFloat((element.cantidad * element.cantidad_unidades).toFixed(2)): 0,
        valor_salida: 0,
        cantidad_saldo: 0,
        valor_saldo: 0
      }
      
      if (element.tipo === 'ENTRADA') {
        item.valor_entrada = i === 0?parseFloat((item.valor_unitario * item.cantidad_entrada).toFixed(2)):
          parseFloat((item.cantidad_entrada * element.precio).toFixed(2))
        item.cantidad_saldo = i === 0? item.cantidad_entrada: item.cantidad_entrada + lista[i-1].cantidad_saldo
        item.valor_saldo = i === 0? item.cantidad_saldo * item.valor_unitario: lista[i-1].valor_saldo + item.valor_entrada
        item.valor_unitario = parseFloat((item.valor_saldo / item.cantidad_saldo).toFixed(2))
      } else {
        item.valor_unitario = i === 0? 0: lista[i-1].valor_unitario
        item.valor_salida = parseFloat((item.valor_unitario * item.cantidad_salida).toFixed(2))
        item.cantidad_saldo = i === 0? item.cantidad_salida: lista[i-1].cantidad_saldo - item.cantidad_salida
        item.valor_saldo = parseFloat((item.cantidad_saldo * item.valor_unitario).toFixed(2))
      }
      // console.log(item)

      lista = [...lista, item]
    }

    setKardex(lista)
  }

  const ExportarPDF = () => {
    componentePDF.current.save()
  }

  
  return(
    <>
      {PdfFileKardex(componentePDF, articulo, kardex)}
      <div className='elem-list'>
        <div className='col-sm-11 col-md-5 col-lg-5'>
        <Select
          loading={loadingButton}
          showSearch
          value={selectArticulo}
          className='mb-lg-0'
          placeholder='Material/Equipo'
          optionFilterProp='children'
          filterOption={optionFilter}
          onChange={(e) => articuloChange(e)}
        >
          {articulosList.map(material => {
            return(
              <Option value={material.id_articulo} 
                key={material.id_articulo}>
                {material.nombre}
              </Option>
            )
          })}
        </Select>
        </div>

        <div className='col-sm-11 col-md-5 col-lg-5'>
          <Button danger 
            title="Exportar PDF" loading={loadingButton}
            onClick={e => ExportarPDF()}
            icon={<FilePdfOutlined />} >PDF
          </Button>
          {ExcelFileKardex(kardex, articulo, loadingButton)}
        </div>

        {/* <table className="table table-sm mt-2">
          <tbody>
            <tr>
              <th rowSpan={2} style={{textAlign: 'center'}}>FECHA</th>
              <th rowSpan={2} style={{textAlign: 'center'}}>DESCRIPCION</th>
              <th rowSpan={2} style={{textAlign: 'center'}}>VALOR UNITARIO</th>
              <th colSpan={2} style={{textAlign: 'center'}}>ENTRADAS</th>
              <th colSpan={2} style={{textAlign: 'center'}}>SALIDAS</th>
              <th colSpan={2} style={{textAlign: 'center'}}>SALDO</th>
            </tr>

            <tr>
              <th style={{textAlign: 'center'}}>CANTIDAD</th>
              <th style={{textAlign: 'center'}}>VALOR</th>

              <th style={{textAlign: 'center'}}>CANTIDAD</th>
              <th style={{textAlign: 'center'}}>VALOR</th>

              <th style={{textAlign: 'center'}}>CANTIDAD</th>
              <th style={{textAlign: 'center'}}>VALOR</th>
            </tr>

            {kardex.map(item => {
              return(
                <tr>
                  <td>{moment.utc(item.fecha).format('DD/MM/YYYY')}</td>
                  <td>{item.descripcion}</td>
                  <td style={{textAlign: 'right'}}>{item.valor_unitario}</td>
                  <td style={{textAlign: 'right'}}>{item.cantidad_entrada}</td>
                  <td style={{textAlign: 'right'}}>{item.valor_entrada}</td>
                  <td style={{textAlign: 'right'}}>{item.cantidad_salida}</td>
                  <td style={{textAlign: 'right'}}>{item.valor_salida}</td>
                  <td style={{textAlign: 'right'}}>{item.cantidad_saldo}</td>
                  <td style={{textAlign: 'right'}}>{item.valor_saldo}</td>
                </tr>
              )
            })}
          </tbody>
        </table> */}
      </div>
    </>
  )
}

export default Kardex