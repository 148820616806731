import moment from "moment";
import {PDFExport} from '@progress/kendo-react-pdf'
import { Table } from "antd";

const PageTemplate = (props) => {
  return (
    <div style={{ position: "absolute", bottom: "10px", left: "50%" }}>
      Pag. {props.pageNum} de {props.totalPages}
    </div>
  );
};

const columns = [
  {
    title: 'FECHA',
    render: record => {
      return (<>{moment.utc(record.fecha).format('DD/MM/YY')}</>)
    }
  },
  { title: 'DESCRIPCION', key: 'descripcion', dataIndex: 'descripcion' },
  { title: 'V. UNIT.', key: 'valor_unitario', dataIndex: 'valor_unitario', align: 'right' },
  {
    title: 'ENTRADAS', 
    children: [
      {title: 'CANTIDAD', key: 'cantidad_entrada', dataIndex: 'cantidad_entrada', align: 'right'},
      {title: 'VALOR', key: 'valor_entrada', dataIndex: 'valor_entrada', align: 'right'}
    ]
  },
  {
    title: 'SALIDAS', 
    children: [
      {title: 'CANTIDAD', key: 'cantidad_salida', dataIndex: 'cantidad_salida', align: 'right'},
      {title: 'VALOR', key: 'valor_salida', dataIndex: 'valor_salida', align: 'right'}
    ]
  },
  {
    title: 'SALDO', 
    children: [
      {title: 'CANTIDAD', key: 'cantidad_saldo', dataIndex: 'cantidad_saldo', align: 'right'},
      {title: 'VALOR', key: 'valor_saldo', dataIndex: 'valor_saldo', align: 'right'}
    ]
  }
]

export const PdfFileKardex = (componentePDF, articulo, kardex) => {
  console.log(kardex)
  return(
    <>
			<div style={{ position: "absolute", left: "-4000px", top: 0 }}>
        <PDFExport 
					ref={componentePDF} 
					paperSize='A4'
					margin="1cm" 
					landscape={true}
					pageTemplate={PageTemplate}
					fileName={`Kardex_${articulo.nombre}`}
					scale={0.6}
				>
					<div className='d-flex justify-content-between'>
            <b>ARTICULO: {articulo.nombre}</b>
            {/* <b>ALMACEN PRINCIPAL</b> */}
          </div>

          <Table
            pagination={false}
            dataSource={kardex}
            size='small'
            columns={columns}
          />

        </PDFExport>
      </div>
    </>
  )
}