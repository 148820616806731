import {useState} from "react";
import Cookies from "universal-cookie";
import { Table, Button, Tag, Form } from 'antd';
// import { Option } from "antd/lib/mentions";
import { EyeOutlined, FilePdfOutlined, EditOutlined, 
   ShoppingCartOutlined, DollarOutlined } from "@ant-design/icons";
import ModalConsolidadoDetalle from "./ModalConsolidadoDetalle";
import { DownloadFile, listaCodigos } from "../FuncionesGenerales";
import moment from "moment";
import ModalFormularioOrdenCompra from "../componentesOrdenesCompras/ModalFormularioOrdenCompra";
import ModalConsolidadoCotizacion from "./ModalConsolidadoCotizacion";

const cookies = new Cookies()

const Consolidados = (props) => {
  const [openModalDetalleConsolidado, setOpenModalDetalleConsolidado] = useState(false);
  const [openModalCotConsolidado, setOpenModalCotConsolidado] = useState(false);
  const [loadingButtonAprobar, setLoadingButtonAprobar] = useState(false)
  const [idConsolidado, setIdConsolidado] = useState(null)
  const [detalleOrden, setDetalleOrden] = useState([])
  const [consolidado, setConsolidado] = useState({
    nombre_rubro: null,
    id_consolidado:null, 
    ubicacion_archivo_comparativo:null,
    id_area: null,
    nombre_area: null,
    nombre_responsable: null,
    id_rubro: null
  })
  const [openModal, setOpenModal] = useState(false);
  const [tituloCot, setTituloCot] = useState(false);
  const [nuevaOrdenCompra, setNuevaOrdenCompra] = useState({
    id_orden_compra: null,
    id_cotizacion: null,
    tipo_documento: null,
    tipo_pago: null,
    forma_pago: null,
    comentario: null,
    fecha_orden_compra: moment.utc(new Date()).format('YYYY-MM-DD'),
    ubicacion_archivo_factura_guia: null,
    id_consolidado: null
  })
  const [form] = Form.useForm();
  const resetForm = () => form.resetFields();

  // const optionFilter = (input, option) =>
  //   option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const rubroConsolidado = (rubro_cons) => {
    const rubro = JSON.parse(rubro_cons)
    return(rubro[0].nombre)
  }

  const idRubroConsolidado = (rubro_cons) => {
    const rubro = JSON.parse(rubro_cons)
    return(rubro[0].id_rubro)
  }

  const handleOpenModalDetalleConsolidado = (item) => {
    setOpenModalDetalleConsolidado(true)
    setIdConsolidado(item.id_consolidado)
  }

  const handleOpenModalCotConsolidado = (tituloCot, id_consolidado) => {
    setOpenModalCotConsolidado(true)
    setTituloCot(tituloCot)
    setIdConsolidado(id_consolidado)
  }

  const handleModalOrdenCompra = (consolidado) => {
    // console.log(consolidado)
    setOpenModal(true)
    setConsolidado({...consolidado, 
      nombre_rubro: rubroConsolidado(consolidado.rubro),
      id_rubro: idRubroConsolidado(consolidado.rubro)
    })

    resetForm()
    setNuevaOrdenCompra({
      id_orden_compra: null,
      id_cotizacion: null,
      tipo_documento: null,
      tipo_pago: null,
      forma_pago: null,
      comentario: null,
      fecha_orden_compra: moment.utc(new Date()).format('YYYY-MM-DD'),
      ubicacion_archivo_factura_guia: null,
      id_consolidado: consolidado.id_consolidado
    })
  }

  const handleCloseModalOrdenCompra = () => {
    setOpenModal(false)
    setConsolidado({
      id_consolidado:null, 
      ubicacion_archivo_comparativo:null,
      id_area: null,
      nombre_area: null,
      nombre_responsable: null,
      nombre_rubro: null,
      id_rubro: null
    })
    setDetalleOrden([])
  }
  
  const columnsConsolidados = [
    {
      title: (<div style={{width:160}}></div>),
      render: record => {
        return(
          <>
            {props.rolLogistica === true || props.rolFinanzas === true ?
              <Button type='primary' className="mr-2"
                title="Ver Datos del Consolidado" 
                onClick={e => handleOpenModalDetalleConsolidado(record)}
                icon={<EyeOutlined />} />
            :null}

            {props.rolLogistica === true && record.aprobado === null?
              <><Button type='primary' className="bg-color-orange mr-2"
                title="Editar Consolidado" 
                onClick={e=>props.handleOpenModalConsolidado('Editar Consolidado', record.id_consolidado)}
                icon={<EditOutlined />}/> 
              {record.id_proveedor === null?
                <Button type='primary' className="bg-color-success mr-2"
                  title="Cotizar" 
                  onClick={e=>handleOpenModalCotConsolidado('Cotizar Consolidado', record.id_consolidado)}
                  icon={<DollarOutlined />}/> 
              : <Button type='primary' className="bg-color-success mr-2"
                  title="Editar Cotización" 
                  onClick={e=>handleOpenModalCotConsolidado('Editar Cotización Consolidada', record.id_consolidado)}
                  icon={<EditOutlined />}/> } 
              </>
            :null}

            {record.ubicacion_archivo === null && (props.rolLogistica === true || props.rolFinanzas === true)?null:
            <Button danger
              title="Descargar Archivo" className="mr-2" 
              onClick={e => DownloadFile('Consolidado', record, record.id_consolidado)}
              icon={<FilePdfOutlined />} >
            </Button>}

            {record.ubicacion_archivo_comparativo === null && (props.rolLogistica === true || props.rolFinanzas === true)?null:
            <Button danger
              title="Descargar Comparativo" className="mr-2"
              onClick={e => DownloadFile('ConsolidadoComparativo', {
                ...record, otraUbicacion:record.ubicacion_archivo_comparativo
              }, record.id_consolidado)}
              icon={<FilePdfOutlined />} >
            </Button>}

            {record.CantOrden === 0 && record.aprobado === 'SI' && props.rolLogistica === true?
              <Button className='bg-color-success' 
                title="Registrar Orden de Compra" 
                onClick={e => handleModalOrdenCompra(record)}
                icon={<ShoppingCartOutlined />} >
              </Button>
            :null}
          </>
        )
      }
    },
    {
      title: (
        <>
          <div style={{width:120}}>CÓDIGO
            {/* <Input defaultValue={inputCodigo} 
              onChange={e=>setInputCodigo(e.currentTarget.value)}
                placeholder = "CÓDIGO"
            /> */}
          </div>
        </>
      ),
      render: record => {
        return(
          <>
            {listaCodigos(record.req_consolidados)}
          </>
        )
      }
    },
    {
      title: (
        <>
          <div style={{width:120}}>RUBRO
            {/* <Input defaultValue={inputCodigo} 
              onChange={e=>setInputCodigo(e.currentTarget.value)}
                placeholder = "RUBRO"
            /> */}
          </div>
        </>
      ),
      render: record => {
        return(
          <>
            {rubroConsolidado(record.rubro)}
          </>
        )
      }
    },
    {
      title: 'FECHA COTIZADA',
      dataIndex: 'fecha_consolidado',
      key: 'fecha_consolidado',
      render: text => (
        text === null?null: moment.utc(text).format('DD/MM/YY')
      )
    },
    {
      title: 'PROVEEDOR',
      key: 'descripcion',
      dataIndex: 'descripcion'
    },
    {
      title: 'RUC',
      key: 'ruc',
      dataIndex: 'ruc'
    },
    {
      title: /*(
        <>
          <div style={{width:120}}>
            <Select
              allowClear
              defaultValue={selectAprobado}
              placeholder='APROBADO'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={e => setSelectAprobado(e)}
            >
              <Option value='SI' key='SI'>SI</Option>
              <Option value='NO' key='NO'>NO</Option>
            </Select>
          </div>
        </>
      )*/'APROBADO',
      key: 'aprobado',
      dataIndex: 'aprobado',
      render : text => {
        switch (text) {
          case null:
            return(<Tag color={'#787878'}>PENDIENTE</Tag>)
          
          case 'NO':
            return(<Tag color={'#AA0404'}>NO</Tag>)
          
          case 'SI':
            return(<Tag color={'#B7CE63'}>SI</Tag>)
          
            default:
            break;
        }
      }
    },
    {
      title: (<><div  style={{width:140}}>APROBADO POR</div></>),
      render: record => {
        return(<>{record.nombre_usuario_aprobacion === null? record.nombre_aprobacion:record.nombre_usuario_aprobacion}</>)
      }
    },
    {
      title: 'TOTAL',
      key: '',
      dataIndex: '',
      render: record => {
        return(
          <>
            {record.Parc_Valor} {record.total}
          </>
        )
      }
    },
    {
      title: 'T.C.',
      key: 'TipCambio',
      dataIndex: 'TipCambio'
    }
  ]

  if (props.consolidadosList.length > 0) {
    return(
      <>
        <Table
          // pagination={{ pageSize: 10 }}
          columns={columnsConsolidados}
          dataSource={props.consolidadosList}
          size='small'
        />

        {<ModalConsolidadoDetalle
          idConsolidado = {idConsolidado}
          setIdConsolidado = {setIdConsolidado}
          openModal = {openModalDetalleConsolidado}
          setOpenModal = {setOpenModalDetalleConsolidado}
          usuario = {cookies.get('nameid_logis')}
          getConsolidados = {props.getConsolidados}
        />}

        {<ModalFormularioOrdenCompra
          loadingButtonAprobar = {loadingButtonAprobar}
          setLoadingButtonAprobar = {setLoadingButtonAprobar}
          cotizacion = {consolidado}
          openModal = {openModal}
          handleCloseModal = {handleCloseModalOrdenCompra}
          nuevaOrdenCompra = {nuevaOrdenCompra}
          setNuevaOrdenCompra = {setNuevaOrdenCompra}
          form = {form}
          materialesList = {props.materialesList}
          unidadesList = {props.unidadesList}
          detalleOrden = {detalleOrden}
          setDetalleOrden = {setDetalleOrden}
          usuario = {cookies.get('nameid_logis')}
          setCotizacion = {setConsolidado}
          requerimiento = {{
            codigo: null, 
            nombre_rubro: 
            consolidado.nombre_rubro,
            id_rubro: consolidado.id_rubro
          }}
          getConsolidados = {props.getConsolidados}
        />}

        {<ModalConsolidadoCotizacion
          idConsolidado = {idConsolidado}
          setIdConsolidado = {setIdConsolidado}
          openModal = {openModalCotConsolidado}
          setOpenModal = {setOpenModalCotConsolidado}
          tituloModal = {tituloCot}
          usuario = {cookies.get('nameid_logis')}
          articulosList = {props.materialesList}
          unidadesList = {props.unidadesList}
          requerimientosList = {props.requerimientosList}
          proveedoresList = {props.proveedoresList}
          rubrosList = {props.rubrosList}
          getProveedores = {props.getProveedores}
          getConsolidados = {props.getConsolidados}
          listaCodigos = {listaCodigos}
        />}
      </>
    )
  } else {
    return(
      <>
        <div>
          No hay Datos
        </div>
      </>
    )
    
  }
}

export default Consolidados
