import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Input, Modal, message, Form, Select, Card, Table } from 'antd';
import { useEffect, useState } from 'react';
import moment from "moment";
import { Option } from 'antd/lib/mentions';
import { Rule } from 'antd/lib/form';
import { getTipoCambioToday, listaCodigos } from '../FuncionesGenerales';
import ModalTipoCambio from '../ModalTipoCambio';

const { Item } = Form;

const rules = {
  required: { required: true, message: <></> } as Rule
};

const ModalFormularioOrdenCompra = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [nuevaOrdenCompra, setNuevaOrdenCompra] = useState({
    id_orden_compra: null,
    id_cotizacion: null,
    tipo_documento: null,
    tipo_pago: null,
    forma_pago: null,
    comentario: null,
    fecha_orden_compra: null,
    ubicacion_archivo_factura_guia: null,
    nombre_rubro: null,
    id_rubro: null,
    nombre_area: null,
    nombre_responsable: null,
    codigo: null,
    numero_orden: null,
    id_consolidado: null,
    ubicacion_archivo: null,
    fecha_envio: null,
    tipo_entrega: null,
    dias_plazo: null,
    lugar_ejecucion: null,
    penalidades: null
  });

  const [requerimiento, setRequerimiento] = useState({
    codigo: null,
    nombre_rubro: null
  });

  useEffect(() => {
    if (props.openModal === true && props.nuevaOrdenCompra.id_orden_compra === null) {
      if (props.cotizacion.parv_descripcion === 'DOLARES') {
        getCambioDolar()
      }
      if (props.nuevaOrdenCompra.id_cotizacion !== null) {
        getDetalleByIdCotizacion(props.nuevaOrdenCompra.id_cotizacion)  
      } else {
        getDetalleConsolidadoByIdConsolidado(props.nuevaOrdenCompra.id_consolidado)
      }
    }
  }, [props.openModal]);

  useEffect(() => {
    if (props.openModal === true) {    
      setNuevaOrdenCompra(props.nuevaOrdenCompra)
      setRequerimiento(props.requerimiento)
    } else {
      setNuevaOrdenCompra({
        id_orden_compra: null,
        id_cotizacion: null,
        tipo_documento: null,
        tipo_pago: null,
        forma_pago: null,
        comentario: null,
        fecha_orden_compra: null,
        ubicacion_archivo_factura_guia: null,
        nombre_rubro: null,
        id_rubro: null,
        nombre_area: null,
        nombre_responsable: null,
        codigo: null,
        numero_orden: null,
        id_consolidado: null,
        ubicacion_archivo: null,
        fecha_envio: null,
        tipo_entrega: null,
        dias_plazo: null,
        lugar_ejecucion: null,
        penalidades: null
      })

      setRequerimiento({
        codigo: null,
        nombre_rubro: null
      })
    }
  }, [props.openModal]);

  const optionFilter = (input, option) => {
    return (option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0)
  }

  const getDetalleByIdCotizacion = async(id_cotizacion) => {
    // console.log(props.nuevaOrdenCompra.id_cotizacion)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getDetalleByIdCotizacion/${id_cotizacion}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        let lista = []
        for (let i = 0; i < data.length; i++) {
          lista = [...lista, {
            id_orden_compra: null,
            id_unidad: data[i].id_unidad,
            cantidad: data[i].cantidad,
            marca: data[i].marca,
            precio: data[i].precio_unitario,
            id_articulo: data[i].id_articulo,
            descripcion: data[i].descripcion,
            cantidad_unidades: data[i].cantidad_unidades,
            nombre_articulo: data[i].nombre_articulo,
            nombre_unidad: data[i].nombre_unidad
          }]
        }

        props.setDetalleOrden(lista)
      })
    } catch (error) {
      console.log(error)
    }  
  }

  const getDetalleConsolidadoByIdConsolidado = async(id_consolidado) => {
    // console.log(props.nuevaOrdenCompra.id_consolidado)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getDetalleConsolidadoByIdConsolidado/${id_consolidado}`)
      .then(res => res.json())
      .then(data => {
        props.setDetalleOrden(data)
      })
    } catch (error) {
      console.log(error)
    }  
  }

  const getCambioDolar = async() => {
    const cambioToday = await getTipoCambioToday()
    if (cambioToday.message) {
      message.warning("No se ha Registrado el tipo de cambio de hoy", 5)
      props.setCotizacion({...props.cotizacion, 
        TipCambio: 1
      })
      handleOpenModalTipoCambio()
    } else {
      props.setCotizacion({...props.cotizacion, 
        TipCambio: cambioToday[0].TipCambio
      })
    }
  }

  const handleOpenModalTipoCambio = () => {
    setOpenModal(true)
    props.setCotizacion({...props.cotizacion, 
      TipCambio: 1
    })
  }

  const columnsServ = [
    { title: 'SERVICIO SOLICITADO', key: 'descripcion', dataIndex: 'descripcion' },
    { title: 'PRECIO',
      render: record => {
        return(
          <>
            {props.cotizacion.Parc_Valor } {record.precio.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </>
        )
      } 
    }
  ]

  const columns = [
    { title: 'MATERIAL/EQUIPO', key: 'nombre_articulo', dataIndex: 'nombre_articulo' },
    { title: 'DESCRIPCIÓN', key: 'descripcion', dataIndex: 'descripcion' },
    { title: 'MARCA', key: 'marca', dataIndex: 'marca' },
    { title: 'PRESENTACIÓN',
      render: record => {
        return(
          <>{record.nombre_unidad} X {record.cantidad_unidades}</>
        )
      }
    },
    { title: 'CANTIDAD', 
      render: record => {
        return(<>{record.cantidad.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>)
      }
    },
    { title: 'PRECIO',
      render: record => {
        return(
          <>
            {props.cotizacion.Parc_Valor } {record.precio.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </>
        )
      } 
    },
    {
      title: 'TOTAL',  
      render: record => {
        return(
          <>
            {props.cotizacion.Parc_Valor } 
            {(record.cantidad * record.precio).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </>
        )
      }
    }
  ]

  const Total = () => {
    return(
      <>
        <div className='d-flex justify-content-between'>
          <b>Base Imponible</b>
          <b className='mr-4'>
            {props.cotizacion.parv_descripcion === 'SOLES'?'S/.':'$'} { props.detalleOrden.length === 0? 0:
              (props.detalleOrden.map(det => det.cantidad * det.precio).reduce((prev, next) => prev + next) / 1.18)
              .toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            }
          </b>
        </div>

        <div className='d-flex justify-content-between'>
          <b>IGV</b>
          <b className='mr-4'>
            {props.cotizacion.parv_descripcion === 'SOLES'?'S/.':'$'} { props.detalleOrden.length === 0? 0:
              (props.detalleOrden.map(det => det.cantidad * det.precio).reduce((prev, next) => prev + next) / 1.18 * 0.18)
              .toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            }
          </b>
        </div>

        <div className='d-flex justify-content-between'>
          <b>Total</b>
          <b className='mr-4'>
            {props.cotizacion.parv_descripcion === 'SOLES'?'S/.':'$'} { props.detalleOrden.length === 0? 0:
              props.detalleOrden.map(det => det.cantidad * det.precio).reduce((prev, next) => prev + next)
              .toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            }
          </b>
        </div>
      </>
    )
  }

  const AddOrdenCompra = async() => {
    if (props.cotizacion.parv_descripcion === 'DOLARES' && props.cotizacion.TipCambio === 1) {
      message.error('Falta Ingresar el Tipo de Cambio')
    } else {

      if (props.detalleOrden.length === 0 ) {
        message.error('Debe Ingresar los Materiales Requeridos')
      } else {

        const body = {orden_compra: {...nuevaOrdenCompra, usuario: props.usuario,
          total: props.detalleOrden.map(det => det.cantidad * det.precio).reduce((prev, next) => prev + next)},
          detalleOrden: props.detalleOrden
        }

        const validarDetalle = props.detalleOrden.filter(detalle => {
          return detalle.id_unidad === null || detalle.id_articulo === null
        })

        if (validarDetalle.length > 0) {
          message.error('Faltan campos en el detalle de Equipos')
        } else {
          props.setLoadingButtonAprobar(true)
          try {
            await fetch(`${process.env.REACT_APP_HOSTNAME}/AddOrdenCompra`,{
              method: "POST",
              body: JSON.stringify(body),
              headers:{
                'Content-Type': 'application/json'
              }
            }).then(res => res.json())
            .then(data => {
              props.setLoadingButtonAprobar(false)
              message.success("Orden de Compra Registrada")
              if (nuevaOrdenCompra.id_consolidado === null) {
                props.getOrdenesCompraByRequerimiento(props.cotizacion.id_requerimiento)
                props.getDetalleOrdenesByReq(props.cotizacion.id_requerimiento)
                props.getCotizacionesByRequerimiento(props.cotizacion.id_requerimiento)  
              } else {
                props.getConsolidados()
              }
              
              props.handleCloseModal()
            }).catch(err => {
              console.log(err)
              props.setLoadingButtonAprobar(false)
            });
          } catch (error) {
            console.log(error)
            props.setLoadingButtonAprobar(false)
          } 
        }
      }  
    }
  }

  const EditOrdenCompra = async() => {
    // console.log(props.cotizacion)
    if (props.detalleOrden.length === 0) {
      message.error('Debe Ingresar los Materiales Requeridos')
    } else{
      const body = {orden_compra: {...nuevaOrdenCompra,
        total: props.detalleOrden.map(det => det.cantidad * det.precio).reduce((prev, next) => prev + next)},
        detalleOrden: props.detalleOrden
      }

      const validarDetalle = props.detalleOrden.filter(detalle => {
        return detalle.id_unidad === null || detalle.id_articulo === null
      })

      if (validarDetalle.length > 0) {
        message.error('Faltan campos en el detalle de Equipos')
      } else {
        props.setLoadingButtonAprobar(true)
        try {
          await fetch(`${process.env.REACT_APP_HOSTNAME}/UpdateOrdenCompra`,{
            method: "PUT",
            body: JSON.stringify(body),
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(res => res.json())
          .then(data =>{
            message.success(data.mensaje)
            props.setLoadingButtonAprobar(false)
            props.getOrdenesCompra()
            props.handleCloseModal()
          }).catch(err => {
            console.log(err)
            props.setLoadingButtonAprobar(false)
          });
        } catch (error) {
          console.log(error)
          props.setLoadingButtonAprobar(false)
        }
      }
    }
  }

  return(
    <>
      <Modal
        visible={props.openModal}
        closable={false}
        width='90%'
        title={
          <>
            <div className='d-flex justify-content-between'>
              <b style={{fontSize:"20px"}}>
                {nuevaOrdenCompra.id_orden_compra === null?`Nueva Orden de Compra`:
                `Editar Orden de Compra`}
              </b>

              <b style={{fontSize:"20px"}}>
                {nuevaOrdenCompra.id_consolidado === null?requerimiento.codigo:listaCodigos(requerimiento.codigo)}
              </b>
            </div>
            
            <div className="d-flex justify-content-between mb-2 mt-2">
              <b style={{fontSize:"20px"}}>Área: {props.cotizacion.nombre_area}
              </b>
              <b style={{fontSize:"20px"}}>N° Orden: {nuevaOrdenCompra.id_orden_compra === null?'-':nuevaOrdenCompra.numero_orden}</b>
            </div>

            <div className="mt-2">
              <b style={{fontSize:"20px"}}>Solicitante: {props.cotizacion.nombre_responsable}</b>
            </div>
          </>
        }
        onCancel={props.handleCloseModal}
        footer={null}
      >
        <Form form={props.form} layout='vertical' onFinish = {nuevaOrdenCompra.id_orden_compra === null? AddOrdenCompra: EditOrdenCompra}>
          <div className='elem-list'>
            <div className='col-sm-4 col-md-2 col-lg-2'>
              <Item label="Fecha" name="fecha">
                <b>{moment(nuevaOrdenCompra.fecha_orden_compra).format('DD/MM/YYYY')}</b>
              </Item>
            </div>

            <div className='col-sm-4 col-md-2 col-lg-2'>
              <Item label="Fecha Envío" name="fecha_envio">
                <Input type="date"
                  defaultValue={moment(nuevaOrdenCompra.fecha_envio).format("YYYY-MM-DD")} 
                  onChange={e => setNuevaOrdenCompra({...nuevaOrdenCompra, fecha_envio:e.currentTarget.value})}
                />
              </Item>
            </div>

            <div className='col-sm-6 col-md-3 col-lg-3'>
              <Item label="Tipo Doc." name="tipo_documento" rules={[
                {...rules.required, message: 'Ingresar Tipo de Documento'}
              ]}>
                <Select
                  allowClear
                  defaultValue={nuevaOrdenCompra.tipo_documento}
                  className='mb-lg-0'
                  placeholder='Seleccionar Doc.'
                  optionFilterProp='children'
                  filterOption={optionFilter}
                  onChange={(e)=> setNuevaOrdenCompra({...nuevaOrdenCompra, tipo_documento:e})}
                >
                  <Option value={'BOLETA'} key={'BOLETA'}>BOLETA</Option>
                  <Option value={'FACTURA'} key={'FACTURA'}>FACTURA</Option>
                  <Option value={'HONORARIO'} key={'HONORARIO'}>RECIBO POR HONORARIO</Option>
                </Select>
              </Item>
            </div>

            <div className='col-sm-4 col-md-2 col-lg-2'>
              <Item label="Moneda" name="parv_descripcion">
                <b>{props.cotizacion.parv_descripcion === 'SOLES'? 'S/.': '$'}</b>
              </Item>
            </div>

            <div className='col-sm-4 col-md-2 col-lg-2'>
              <Item label="Tipo de Cambio" name="TipCambio">
                <b>{props.cotizacion.TipCambio}</b>
              </Item>
            </div>
          </div>

          <div className='elem-list'>
            <div className='col-sm-11 col-md-7 col-lg-7'>
              <Item label="Proveedor" name="descripcion">
                <b>{props.cotizacion.descripcion}</b>
              </Item>
            </div>

            <div className='col-sm-11 col-md-4 col-lg-4'>
              <Item label="RUC" name="ruc">
                <b>{props.cotizacion.ruc}</b>
              </Item>
            </div>
          </div>

          <div className='elem-list'>
            <div className='col-sm-5 col-md-3 col-lg-3'>
              <Item label="Tipo de Pago" name="tipo_pago" rules={[
                {...rules.required, message: 'Ingresar Tipo de Pago'}
              ]}>
                <Select
                  defaultValue={nuevaOrdenCompra.tipo_pago}
                  className='mb-lg-0'
                  placeholder='Seleccionar T. Pago'
                  optionFilterProp='children'
                  filterOption={optionFilter}
                  onChange={(e)=> setNuevaOrdenCompra({...nuevaOrdenCompra, tipo_pago:e})}
                >
                  <Option value={'CREDITO'} key={'CREDITO'}>CRÉDITO</Option>
                  <Option value={'CONTADO'} key={'CONTADO'}>CONTADO</Option>
                  <Option value={'ADELANTO'} key={'ADELANTO'}>PAGO ADELANTADO</Option>
                </Select>
              </Item>
            </div>

            <div className='col-sm-5 col-md-4 col-lg-4'>
              <Item label="Forma de Pago" name="forma_pago" rules={[
                {...rules.required, message: 'Ingresar Forma de Pago'}
              ]}>
                <Select
                  defaultValue={nuevaOrdenCompra.forma_pago}
                  className='mb-lg-0'
                  placeholder='Seleccionar F. Pago'
                  optionFilterProp='children'
                  filterOption={optionFilter}
                  onChange={(e)=> setNuevaOrdenCompra({...nuevaOrdenCompra, forma_pago:e})}
                >
                  <Option value={'EFECTIVO'} key={'EFECTIVO'}>EFECTIVO</Option>
                  <Option value={'TRANSFERENCIA'} key={'TRANSFERENCIA'}>TRANSFERENCIA</Option>
                </Select>
              </Item>
            </div>

            <div className='col-sm-5 col-md-4 col-lg-4'>
              <Item label="Tipo de Entrega" name="tipo_entrega">
                <Select
                  allowClear
                  defaultValue={nuevaOrdenCompra.tipo_entrega}
                  className='mb-lg-0'
                  placeholder='Seleccionar F. Pago'
                  optionFilterProp='children'
                  filterOption={optionFilter}
                  onChange={(e)=> setNuevaOrdenCompra({...nuevaOrdenCompra, tipo_entrega:e})}
                >
                  <Option value={'A DOMICILIO'} key={'A DOMICILIO'}>A DOMICILIO</Option>
                  <Option value={'TIENDA'} key={'TIENDA'}>TIENDA</Option>
                  <Option value={'AGENCIA'} key={'AGENCIA'}>AGENCIA</Option>
                </Select>
              </Item>
            </div>
          </div>

          <div className='col-sm-12 col-md-12 col-lg-12'>
            <Card title="Equipos/Materiales" >
              <div className='col-sm-11 col-md-2 col-lg-2'>
                <label className='mb-3' style={{fontWeight:'bold'}}>Rubro:</label>
                <br />
                <b>{requerimiento.nombre_rubro}</b>
              </div>

              <Table 
                pagination={false} 
                columns={props.cotizacion.tipo === 'COMPRA' || props.cotizacion.tipo === 'ATENCION'? columns: columnsServ}
                dataSource={props.detalleOrden}
                size="small"
                footer={() => Total()}
              />
            </Card>
          </div>

          <div className='elem-list'>
            <div className='col-sm-11 col-md-9 col-lg-9'>
              <Item label="Comentario" name="comentario">
                <Input.TextArea defaultValue={nuevaOrdenCompra.comentario} 
                  onChange={e=>setNuevaOrdenCompra({
                    ...nuevaOrdenCompra, comentario:e.currentTarget.value
                  })}
                    placeholder = "Ingresar Algún Comentario Adicional"
                />
              </Item>
            </div>
          </div>

          <div className='elem-list mb-2'>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <Item label="Lugar de Ejecución/Entrega" name="lugar_ejecucion">
                <Input defaultValue={nuevaOrdenCompra.lugar_ejecucion} 
                  onChange={e=>setNuevaOrdenCompra({...nuevaOrdenCompra, lugar_ejecucion: e.currentTarget.value})}
                />
              </Item>
            </div>

            <div className='col-sm-5 col-md-3 col-lg-3'>
              <Item label="Días de Plazo" name="dias_plazo">
                <Input defaultValue={nuevaOrdenCompra.dias_plazo} type='number'
                  onChange={e=>setNuevaOrdenCompra({...nuevaOrdenCompra, dias_plazo: e.currentTarget.value})}
                />
              </Item>
            </div>

            <div className='col-sm-11 col-md-3 col-lg-3'>
              <Item label="Penalidades %" name="penalidades">
                <Input type='number' defaultValue={nuevaOrdenCompra.penalidades} 
                  onChange={e=>setNuevaOrdenCompra({
                    ...nuevaOrdenCompra, penalidades:e.currentTarget.value
                  })}
                    placeholder = "Penalidades por mora u otras penalidades"
                />
              </Item>
            </div>
          </div>

          <div className='modal-footer d-flex justify-content-between'>
            <Button danger onClick={props.handleCloseModal} icon={<CloseOutlined />}>
              Cerrar
            </Button>
            
            <Button className='bg-color-success' loading={props.loadingButtonAprobar}
              type='primary' icon={<SaveOutlined />} htmlType="submit">
              Guardar
            </Button>  
          </div>
        </Form>

        {<ModalTipoCambio
          openModal = {openModal}
          loadingButtonAprobar = {props.loadingButtonAprobar}
          setLoadingButtonAprobar = {props.setLoadingButtonAprobar}
          setTabla = {props.setCotizacion}
          tabla = {props.cotizacion}
          usuario = {props.usuario}
          setOpenModal = {setOpenModal}
        />}
      </Modal>
    </>
  )
}

export default ModalFormularioOrdenCompra