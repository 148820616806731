import moment from "moment";
import {PDFExport} from '@progress/kendo-react-pdf'
import { Table } from "antd";

const PageTemplate = (props) => {
  return (
    <div style={{ position: "absolute", bottom: "10px", left: "50%" }}>
      Pag. {props.pageNum} de {props.totalPages}
    </div>
  );
};

const columns = [
  {
    title: 'FECHA',
    dataIndex: 'fecha_creacion',
    key: 'fecha_creacion',
    render: text => (
      moment.utc(text).format('DD/MM/YY HH:mm')
    )
  },
  {
    title: 'TIPO MOV.',
    dataIndex: 'tipo',
    key: 'tipo'
  },
  {
    title: 'ORIGEN',
    render: record => {
      return(
        <>
          {record.id_almacen_origen !== null? record.nombre_almacen_origen: record.nombre_area_origen}
        </>
      )
    }
  },
  {
    title:'DESTINO',
    render: record => {
      return(
        <>
          {record.id_almacen_destino !== null? record.nombre_almacen_destino: record.nombre_area_destino}
        </>
      )
    }
  },
  {
    title: 'ARTÍCULO',
    render: record => {
      return(
        <>
          {record.nombre_articulo} {record.descripcion === ''? record.descripcion: '-'.concat(record.descripcion)}
        </>
      )
    }
  },
  {
    title: 'UNIDAD',
    dataIndex: 'nombre_unidad',
    key: 'nombre_unidad'
  },
  {
    title: 'CANTIDAD',
    dataIndex: 'cantidad',
    key: 'cantidad'
  },
  {
    title: 'COMENTARIO',
    dataIndex: 'comentario',
    key: 'comentario',
  }
]

export const PdfFileMovimientos = (componentePDF, movimientosListFiltrados, fechaInicio, fechaFin) => {
  return(
    <>
      <div style={{ position: "absolute", left: "-4000px", top: 0 }}>
        <PDFExport 
            ref={componentePDF} 
            paperSize='A4'
            margin="1cm" 
            landscape={true}
            pageTemplate={PageTemplate}
            fileName='Movimientos'
            scale={0.7}
          >
            <Table
              pagination={false}
              columns={columns}
              dataSource={movimientosListFiltrados}
              size='small'
            />
          </PDFExport>
      </div>
    </>
  )
}