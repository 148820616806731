import { CloseOutlined, DeleteOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Input, Modal, message, Form, Select, Card, Table } from 'antd';
import moment from "moment";
import { Option } from 'antd/lib/mentions';
import { Rule } from 'antd/lib/form';
import { useState, useEffect } from 'react';
import ModalUnidadForm from '../componentesUnidades/ModalUnidadForm';
import ModalArticulo from '../componentesArticulos/ModalArticulo';

const { Item } = Form;

const rules = {
  required: { required: true, message: <></> } as Rule
};

const ModalFormularioMovimiento = (props) => {
  const [loading, setLoading] = useState(false)
  const [openModalUnidad, setOpenModalUnidad] = useState(false);
  const [openModalArticulo, setOpenModalArticulo] = useState(false);
  const [articuloSeleccionado, setArticuloSeleccionado] = useState({})
  const [tituloModalArticulo, setTituloModalArticulo] = useState('')
  const [movimiento, setMovimiento] = useState({
    id_movimiento: null,
    id_almacen_origen: null,
    id_almacen_destino: null,
    id_area_origen: null,
    id_area_destino: null,
    fecha_movimiento: moment.utc(new Date()).format('YYYY-MM-DD'),
    usuario: null,
    comentario: null,
    id_transaccion: null
  });

  const [detalleMovimiento, setDetalleMovimiento] = useState([])

  const [form] = Form.useForm();
  const resetForm = () => form.resetFields();

  const optionFilter = (input, option) => {
    return (option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0)
  }

  useEffect(() => {
    if (props.openModal === true) {
      // console.log(props.movimiento)
      setMovimiento(props.movimiento)
    } else {
      setMovimiento({
        id_movimiento: null,
        id_almacen_origen: null,
        id_almacen_destino: null,
        id_area_origen: null,
        id_area_destino: null,
        fecha_movimiento: moment.utc(new Date()).format('YYYY-MM-DD'),
        usuario: null,
        comentario: null,
        id_transaccion: null
      })
    }
  }, [props.movimiento]);

  useEffect(() => {
    if (props.openModal === true) {
      setDetalleMovimiento(props.detalleMovimiento)
    }
    
  }, [props.detalleMovimiento]);

  const handleCloseModal = () => {
    props.setOpenModal(false)
    setMovimiento({
      id_movimiento: null,
      id_almacen_origen: null,
      id_almacen_destino: null,
      id_area_origen: null,
      id_area_destino: null,
      fecha_movimiento: moment.utc(new Date()).format('YYYY-MM-DD'),
      usuario: null,
      comentario: null,
      id_transaccion: null
    })
    setDetalleMovimiento([])
  }

  const AddDetalleMov = () => {
    setDetalleMovimiento([...detalleMovimiento,{
      id_articulo: null,
      id_unidad: null,
      cantidad: 1,
      descripcion: '',
      cantidad_unidades: 1,
      stock_disponible: null
    }])
  }

  const RemoveDetalleMov = (item) => {
    const listaItems = detalleMovimiento.filter(detalle => {
      return(detalle !== item)
    })
    // console.log(listaItems)
    setDetalleMovimiento(listaItems)
  }

  const detalleMovChange = (value, campo, item) => {
    // console.log(item)
    const listaItems = detalleMovimiento
    const key = listaItems.indexOf(item)

    listaItems[key][campo] = value 

    if (campo === 'id_articulo') {
      const item = props.articulosList.filter( articulo => {
        return( articulo.id_articulo === value)
      })

      listaItems[key].stock_disponible = item[0].stock_disponible
    }

    setDetalleMovimiento([...listaItems])
  }

  const detalleMovChangeUnidadCantidad = (value, campo, item) => {
    const listaItems = detalleMovimiento
    const key = listaItems.indexOf(item)

    if (campo === 'cantidad') {
      listaItems[key][campo] = value
    } else {
      listaItems[key][campo] = value
      const unidad = props.unidadesList.filter(uni => {
        return uni.id_unidad === value
      })
      listaItems[key].cantidad_unidades = unidad[0].cantidad_unidades
    }
    // console.log(listaItems[key])
    setDetalleMovimiento([...listaItems])

  }

  const handleOpenModalUnidad = () => {
    setOpenModalUnidad(true)
  }

  const handleOpenModalArticulo = (titulo, id_articulo) => {
    resetForm()
    setOpenModalArticulo(true)
    setTituloModalArticulo(titulo)

    setArticuloSeleccionado({
      id_articulo: null,
      nombre: null,
      id_rubro: null,
      ean: null,
      identificador: null
    })
  }

  const columnasDetalle = () => {
    let columns = [
      {
        title: (
          <div style={{width:350}}>
            MATERIAL/EQUIPO
            <Button type='primary' onClick={e => handleOpenModalArticulo('Nuevo Artículo', null)} 
              title="Agregar Artículo" icon={<PlusOutlined />} className='ml-2 mr-4'/>
          </div>
        ),
        key: 'id_articulo',
        render: (_, record) => {
          return(<Select
            showSearch
            value={record.id_articulo}
            className='mb-lg-0'
            placeholder='Material/Equipo'
            optionFilterProp='children'
            filterOption={optionFilter}
            onChange={e => detalleMovChange(e, "id_articulo", record)}
          >
            {props.articulosList.map(articulo => {
              return(
                <Option value={articulo.id_articulo} 
                  key={articulo.id_articulo}>
                  {articulo.nombre}
                </Option>
              )
            })}
          </Select>)
        }
      },
      {
        title: 'STOCK DISP.',
        // key: 'stock_disponible',
        // dataIndex: 'stock_disponible'
        render: record => {
          if (record.stock_disponible <= 0) {
            return(<p style={{color:'#EE0000'}}>{record.stock_disponible}</p>)
          } else {
            return(<p>{record.stock_disponible}</p>)
          }
        }
      },
      {
        title: (<div style={{width:120}}>{movimiento.id_transaccion === 6?'VALOR UNITARIO':'DESCRIPCIÓN'}</div>),
        key: 'descripcion',
        render: record => {
          // console.log(movimiento)
          return(
            <>
              {movimiento.id_transaccion === 6?
              <Input type="number" name='descripcion' defaultValue={record.descripcion}
                onChange={e=>detalleMovChange(e.currentTarget.value, "descripcion", record)}
              />
              :<Input name='descripcion' defaultValue={record.descripcion}
                onChange={e=>detalleMovChange(e.currentTarget.value, "descripcion", record)}
              />}
              
            </>
          )
        }
      },
      {
        title: (
          <div style={{width:150}}>
            PRESENTACIÓN
            <Button type='primary' onClick={e => handleOpenModalUnidad()} title="Agregar Presentación"
              icon={<PlusOutlined />} className='ml-2 mr-4'/>
          </div>
        ),
        key: 'id_unidad',
        render: record => {
          return(<Select
            showSearch
            value={record.id_unidad}
            className='mb-lg-0'
            placeholder='Unidad de Medida'
            optionFilterProp='children'
            filterOption={optionFilter}
            onChange={e => detalleMovChangeUnidadCantidad(e,"id_unidad", record)}
          >
            {props.unidadesList.map(unidad => {
              return(
                <Option value={unidad.id_unidad} 
                  key={unidad.id_unidad}>
                  {unidad.nombre} X {unidad.cantidad_unidades}
                </Option>
              )
            })}
          </Select>)
        }
      },
      {
        title: <div style={{width:100}}>CANTIDAD</div>,
        key: 'cantidad',
        render: record => {
          return(
            <>
              <Input type="number" name='cantidad' defaultValue={record.cantidad}
                onChange={e=>detalleMovChangeUnidadCantidad(e.currentTarget.value, "cantidad", record)}
              />
            </>
          )
        }
      },
      {
        title: 'TOTAL UNID.',
        render: record => {
          return(
            <>
              <b>{record.cantidad_unidades * record.cantidad}</b>
            </>
          )
        }
      }
    ]

    if (movimiento.id_movimiento === null) {
      columns = [
        {
          title: (
            <>
              <Button type='primary' onClick={e => AddDetalleMov()} title="Agregar Item" icon={<PlusOutlined />}/>
            </>
          ),
          key: '',
          render: record => {
            return(
              <>
                <Button danger title="Quitar" onClick={e=> RemoveDetalleMov(record)} icon={<DeleteOutlined />}/>  
              </>
            )
          }
        },
        ...columns
      ]
    }

    return columns
  }

  const AddMovimiento = async () => {
    
    // console.log(movimiento)
    // console.log(detalleMovimiento)
    if ( (movimiento.id_almacen_origen === null || movimiento.id_almacen_origen === undefined) && 
      (movimiento.id_almacen_destino === null || movimiento.id_almacen_destino === undefined) &&
      (movimiento.id_area_origen === null || movimiento.id_area_origen === undefined) && 
      (movimiento.id_area_destino === null || movimiento.id_area_destino === undefined) ) {
      message.error('Debe haber al menos un Origen o Destino')
    } else {
      if (detalleMovimiento.length === 0) {
        message.error('Debe Ingresar los Materiales a Movilizar')
      } else {
        const validarDetalle = detalleMovimiento.filter(detalle => {
          return detalle.id_unidad === null || detalle.id_articulo === null
        })

        if (validarDetalle.length > 0) {
          message.error('Faltan campos en el detalle de Equipos')
        } else {

          const validarStock = detalleMovimiento.filter(det => {
            return (det.stock_disponible < (det.cantidad * det.cantidad_unidades))
          })

          if (validarStock.length > 0 && (movimiento.id_transaccion === 2 || movimiento.id_transaccion === 3)) {
            message.error('No hay Stock Suficiente')
          } else {
            let listaMovimientos = []
            for (let i = 0; i < detalleMovimiento.length; i++) {
              const detalle = detalleMovimiento[i];

              listaMovimientos = [...listaMovimientos, {
                id_almacen_origen: movimiento.id_almacen_origen,
                id_articulo: detalle.id_articulo,
                id_unidad: detalle.id_unidad,
                cantidad: detalle.cantidad,
                id_area_origen: movimiento.id_area_origen,
                id_almacen_destino: movimiento.id_almacen_destino,
                fecha_movimiento: movimiento.fecha_movimiento,
                usuario: movimiento.usuario,
                id_transaccion: movimiento.id_transaccion,
                comentario: movimiento.comentario,
                descripcion: detalle.descripcion,
                id_area_destino: movimiento.id_area_destino,
                editable: 1
              }]
            }

            const body = {listaMovimientos: listaMovimientos}
            // console.log(body)
            setLoading(true)
            try {
              await fetch(`${process.env.REACT_APP_HOSTNAME}/AddMovimiento`,{
                method: "POST",
                body: JSON.stringify(body),
                headers:{
                  'Content-Type': 'application/json'
                }
              }).then(res => {
                message.success("Movimientos Registrado")
                setLoading(false)
                props.getMovimientos()
                props.getArticulos()
                handleCloseModal()
              }).catch(err => {
                message.error('No se Pudo Subir los Movimientos')
                setLoading(false)
                console.log(err)
              });
            } catch (error) {
              message.error('No se Pudo Subir los Movimientos')
              setLoading(false)
              console.log(error)
            }  
          }
        }
      }  
    }
  }

  const EditMovimiento = async () => {
    if ( (movimiento.id_almacen_origen === null || movimiento.id_almacen_origen === undefined) && 
      (movimiento.id_almacen_destino === null || movimiento.id_almacen_destino === undefined) &&
      (movimiento.id_area_origen === null || movimiento.id_area_origen === undefined) && 
      (movimiento.id_area_destino === null || movimiento.id_area_destino === undefined) ) {
      message.error('Debe haber al menos un Origen o Destino')
    } else {
      setLoading(true)
      const body = {
        id_movimiento: movimiento.id_movimiento,
        id_almacen_origen: movimiento.id_almacen_origen,
        id_articulo: detalleMovimiento[0].id_articulo,
        id_unidad: detalleMovimiento[0].id_unidad,
        cantidad: detalleMovimiento[0].cantidad,
        id_area_origen: movimiento.id_area_origen,
        id_almacen_destino: movimiento.id_almacen_destino,
        id_transaccion: movimiento.id_transaccion,
        comentario: movimiento.comentario,
        descripcion: detalleMovimiento[0].descripcion,
        id_area_destino: movimiento.id_area_destino
      }

      try {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/UpdateMovimiento`,{
          method: "PUT",
          body: JSON.stringify(body),
          headers:{
            'Content-Type': 'application/json'
          }
        }).then(res => {
          message.success("Movimiento Actualizado")
          setLoading(false)
          props.getMovimientos()
          props.getArticulos()
          handleCloseModal()
        }).catch(err => {
          message.error('No se Pudo Subir los Cambios')
          setLoading(false)
          console.log(err)
        });
      } catch (error) {
        message.error('No se Pudo Subir los Cambios')
        setLoading(false)
        console.log(error)
      } 
    }
  }

  const changeArea = (value, origen_destino) => {
    // console.log(value)
    if (origen_destino === 'origen') {
      setMovimiento({
        ...movimiento, id_area_origen: value,
        id_almacen_origen: null
      })
      props.form.setFieldsValue({ id_almacen_origen: null })  
    } else {
      setMovimiento({
        ...movimiento, id_area_destino: value,
        id_almacen_destino: null
      })
      props.form.setFieldsValue({ id_almacen_destino: null })  
    }
  }

  const changeAlmacen = (value, origen_destino) => {
    // console.log(value)
    if (origen_destino === 'origen') {
      setMovimiento({
        ...movimiento, id_almacen_origen: value,
        id_area_origen: null
      })
      props.form.setFieldsValue({ id_area_origen: null })  
    } else {
      setMovimiento({
        ...movimiento, id_almacen_destino: value,
        id_area_destino: null
      })
      props.form.setFieldsValue({ id_area_destino: null })  
    }
  }

  const changeTipoMov = (value) => {
    switch (value) {
      case 1: //ENTRADA A ALMACÉN
        setMovimiento({
          ...movimiento, id_transaccion: value,
          id_area_destino: null
        })
        props.form.setFieldsValue({ id_area_destino: null }) 
        break;
      
      case 2: //SALIDA DE ALMACEN
        setMovimiento({
          ...movimiento, id_transaccion: value,
          id_area_origen: null
        })
        props.form.setFieldsValue({ id_area_origen: null }) 
        break;
      
      case 3: //PRÉSTAMO
      case 5: //DONACIÓN
      case 7: //DESCARTE
        setMovimiento({
          ...movimiento, id_transaccion: value,
          id_area_destino: null, id_almacen_destino: null
        })
        props.form.setFieldsValue({ id_area_destino: null, id_almacen_destino: null }) 
        break;

      case 6:
        setMovimiento({
          ...movimiento, id_transaccion: value,
          id_area_origen: null, id_almacen_origen: null,
          comentario: 'STOCK INICIAL'
        })
        props.form.setFieldsValue({ id_area_origen: null, id_almacen_origen: null, comentario: 'STOCK INICIAL' }) 
        break;
      
      default:
        break;
    }
  }
  

  return(
    <>
      <Modal
        visible={props.openModal}
        closable={false}
        width='95%'
        title={<h3 className='m-0'>{props.tituloModal}</h3>}
        onCancel={handleCloseModal}
        footer={null}
      >
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <Form form={props.form} layout='vertical' onFinish={ props.tituloModal === 'Nuevo Movimiento'? AddMovimiento : EditMovimiento}>
              <div className='elem-list'>
                <div className='col-sm-2 col-md-1 col-lg-1'>
                  <Item label="Fecha" name="fecha_movimiento">
                    <b>{moment(movimiento.fecha_movimiento).format('DD/MM/YY')}</b>
                  </Item>
                </div>

                <div className='col-sm-4 col-md-4 col-lg-4'>
                  <Item label="Tipo" name="id_transaccion" rules ={[
                    {...rules.required, message: 'El Tipo de Mov. es Requerido'}
                  ]}>
                    <Select
                      showSearch
                      defaultValue={movimiento.id_transaccion}
                      className='mb-lg-0'
                      placeholder='Seleccionar Tipo'
                      optionFilterProp='children'
                      filterOption={optionFilter}
                      onChange={e=> changeTipoMov(e)}
                    >
                    {props.transaccionesList.map(transaccion =>{
                      return(
                        <Option value={transaccion.id_transaccion} key={transaccion.id_transaccion}>{transaccion.tipo}</Option>
                      )
                    })}
                    </Select>
                  </Item>
                </div>
              </div>

              <div className='elem-list'>
                <div className='col-sm-11 col-md-1 col-lg-1'>
                  <br/><br/>
                  <b>Origen:</b>
                </div>

                <div className='col-sm-5 col-md-4 col-lg-4'>
                  <Item label="Almacén" name="id_almacen_origen">
                    <Select
                      disabled = {movimiento.id_transaccion === 6? true: false}
                      allowClear
                      showSearch
                      defaultValue={movimiento.id_almacen_origen}
                      className='mb-lg-0'
                      placeholder='--Seleccionar--'
                      optionFilterProp='children'
                      filterOption={optionFilter}
                      onChange={e => changeAlmacen(e, 'origen')}
                    >
                    {props.almacenesList.map(almacen =>{
                      return(
                        <Option value={almacen.id_almacen} key={almacen.id_almacen}>{almacen.nombre}</Option>
                      )
                    })}
                    </Select>
                  </Item>
                </div>

                <div className='col-sm-1 col-md-1 col-lg-1'>
                  <br/><br/>
                  <b>Ó</b>
                </div>

                <div className='col-sm-5 col-md-4 col-lg-4'>
                  <Item label="Área" name="id_area_origen">
                    <Select
                      disabled = {movimiento.id_transaccion === 2 || movimiento.id_transaccion === 6? true: false}
                      allowClear
                      showSearch
                      defaultValue={movimiento.id_area_origen}
                      className='mb-lg-0'
                      placeholder='--Seleccionar--'
                      optionFilterProp='children'
                      filterOption={optionFilter}
                      onChange={e => changeArea(e, 'origen')}
                    >
                    {props.areasList.map(area =>{
                      return(
                        <Option value={area.id_area} key={area.id_area}>{area.nombre}</Option>
                      )
                    })}
                    </Select>
                  </Item>
                </div>
              </div>

              <div className='elem-list'>
                <div className='col-sm-11 col-md-1 col-lg-1'>
                  <br/><br/>
                  <b>Destino:</b>
                </div>

                <div className='col-sm-5 col-md-4 col-lg-4'>
                  <Item label="Almacén" name="id_almacen_destino">
                    <Select
                      disabled = {movimiento.id_transaccion === 3 || 
                        movimiento.id_transaccion === 5 || movimiento.id_transaccion === 7? true: false}
                      allowClear
                      showSearch
                      defaultValue={movimiento.id_almacen_destino}
                      className='mb-lg-0'
                      placeholder='--Seleccionar--'
                      optionFilterProp='children'
                      filterOption={optionFilter}
                      onChange={e => changeAlmacen(e, 'destino')}
                    >
                    {props.almacenesList.map(almacen =>{
                      return(
                        <Option value={almacen.id_almacen} key={almacen.id_almacen}>{almacen.nombre}</Option>
                      )
                    })}
                    </Select>
                  </Item>
                </div>

                <div className='col-sm-1 col-md-1 col-lg-1'>
                <br/><br/>
                  <b>Ó</b>
                </div>

                <div className='col-sm-5 col-md-4 col-lg-4'>
                  <Item label="Área" name="id_area_destino">
                    <Select
                      disabled = {movimiento.id_transaccion === 1 || movimiento.id_transaccion === 3 ||
                        movimiento.id_transaccion === 6 || movimiento.id_transaccion === 5 || 
                        movimiento.id_transaccion === 7? true: false}
                      allowClear
                      showSearch
                      defaultValue={movimiento.id_area_destino}
                      className='mb-lg-0'
                      placeholder='--Seleccionar--'
                      optionFilterProp='children'
                      filterOption={optionFilter}
                      onChange={e => changeArea(e, 'destino')}
                    >
                    {props.areasList.map(area =>{
                      return(
                        <Option value={area.id_area} key={area.id_area}>{area.nombre}</Option>
                      )
                    })}
                    </Select>
                  </Item>
                </div>
              </div>
              
              <div className='col-sm-12 col-md-12 col-lg-12'>
                <Card title="Equipos/Materiales">
                  <Table 
                    pagination={false} 
                    columns={columnasDetalle()}
                    dataSource={detalleMovimiento}
                    size="small"
                  />
                </Card>
              </div>

              <div className='elem-list mb-2'>
                <div className='col-sm-11 col-md-11 col-lg-11'>
                  <Item label="Comentario" name="comentario" rules ={[
                    {...rules.required, message: 'Debe justificar el Movimiento'}
                  ]}>
                    <Input.TextArea defaultValue={movimiento.comentario} 
                      onChange={e=>setMovimiento({
                        ...movimiento, comentario:e.currentTarget.value
                      })}
                      placeholder = "Ingresar Motivo del Movimiento"
                    />
                  </Item>
                </div>
              </div>

              <div className='modal-footer d-flex justify-content-between'>
                <Button danger onClick={handleCloseModal} icon={<CloseOutlined />}>
                  Cerrar
                </Button>
                
                <Button className='bg-color-success' loading={loading}
                  type='primary' icon={<SaveOutlined />} htmlType="submit">
                  Guardar
                </Button>
              </div>
            </Form>
          </div>
        </div>

        {<ModalUnidadForm
          openModalUnidad = {openModalUnidad}
          setOpenModalUnidad = {setOpenModalUnidad}
          getUnidades = {props.getUnidades}
          usuario = {movimiento.usuario}
        />}

        {<ModalArticulo
          openModal = {openModalArticulo}
          tituloModal = {tituloModalArticulo}
          articulo = {articuloSeleccionado}
          setArticulo = {setArticuloSeleccionado}
          form = {form}
          getArticulos = {props.getArticulos}
          setOpenModal = {setOpenModalArticulo}
          rubrosList = {props.rubrosList}
          usuario = {movimiento.usuario}
          getRubros = {props.getRubros}
        />}
      </Modal>
    </>
  )
}

export default ModalFormularioMovimiento