import { CloseOutlined, FilePdfOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Select, Form, Input, message, Modal, Card, Table, Checkbox } from 'antd';
import moment from "moment";
import { Option } from 'antd/lib/mentions';
import { Rule } from 'antd/lib/form';
import { useRef, useState, useEffect } from 'react';
import { getTipoCambioToday } from '../FuncionesGenerales';
import ModalTipoCambio from '../ModalTipoCambio';

const { Item } = Form;

const rules = {
  required: { required: true, message: <></> } as Rule
};

const FormularioPago = (props) => {
  const [form] = Form.useForm();
  const [nuevoPago, setNuevoPago] = useState({
    id_concepto: 2 /**AUTORIZACION DIRECTA */,
    tipo_operacion: props.operacion,
    detalle_concepto: '',
    tipo_documento: null,
    serie: '',
    numero_serie: '',
    monto: null,
    responsable: null,
    observacion: null,
    fecha_pago: moment.utc(new Date()).format('YYYY-MM-DD'),
    id_factura: props.factura?props.factura.id_factura:null,
    usuario: props.usuario,
    id_sucursal: props.factura?props.factura.id_sucursal:1,
    ubicacion_archivo: null,
    parv_descripcion: props.factura?props.factura.parv_descripcion:'SOLES',
    TipCambio: 1,
    id_proveedor: props.factura?props.factura.id_proveedor:null,
    id_area: props.factura? props.factura.id_area: null,
    descripcion: props.factura?props.factura.descripcion:null,
    ruc: props.factura?props.factura.ruc:null,
    nombre_comercial: props.factura?props.factura.nombre_comercial:null,
    numero_cuenta: null,
    cci: null,
    tipo_pago: props.factura?props.factura.tipo_pago: null,
    forma_pago: props.factura?props.factura.forma_pago: null
  });
  const [detalleBancos, setDetalleBancos] = useState([])
  const [loadingButton, setLoadingButton] = useState(false)
  const [openModal, setOpenModal] = useState(false);

  const input = useRef<HTMLInputElement>(null);
  const handleClick = () => input.current.click();

  const optionFilter = (input, option) => {
    return (option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0)
  }


  useEffect(() => {
    //  console.log(props.openModal)
    if (props.openModal === true) {
      if (props.idPago === null) {
        // console.log(props.factura)
        setNuevoPago({
          id_concepto: 2,
          tipo_operacion: props.operacion,
          detalle_concepto: '',
          tipo_documento: null,
          serie: '',
          numero_serie: '',
          monto: null,
          responsable: null,
          observacion: null,
          fecha_pago: moment.utc(new Date()).format('YYYY-MM-DD'),
          id_factura: props.factura?props.factura.id_factura:null,
          usuario: props.usuario,
          id_sucursal: props.factura?props.factura.id_sucursal:1,
          ubicacion_archivo: null,
          parv_descripcion: props.factura?props.factura.parv_descripcion:null,
          TipCambio: 1,
          id_proveedor: props.factura?props.factura.id_proveedor:null,
          id_area: props.factura? props.factura.id_area: null,
          descripcion: props.factura?props.factura.descripcion: null,
          ruc: props.factura?props.factura.ruc:null,
          nombre_comercial: props.factura?props.factura.nombre_comercial:null,
          numero_cuenta: null,
          cci: null,
          tipo_pago: props.factura?props.factura.tipo_pago: null,
          forma_pago: props.factura?props.factura.forma_pago: null
        })

        if (props.factura) {
          getCuentasProveedor(props.factura.id_proveedor)
        } else {
          setDetalleBancos([])
        }

        form.setFieldsValue({
          id_concepto: 2,
          tipo_operacion: props.operacion,
          detalle_concepto: '',
          tipo_documento: null,
          serie: null,
          numero_serie: null,
          monto: null,
          responsable: null,
          observacion: null,
          parv_descripcion: props.factura?props.factura.parv_descripcion: null,
          TipCambio: 1,
          id_proveedor: props.factura?props.factura.id_proveedor: null,
          fecha: moment.utc(new Date()).format('YYYY-MM-DD'),
          id_area: props.factura?props.factura.id_area: null
        })  

      } else {
        // console.log('pago',props.idPago)
        getPagoById(props.idPago)  
      }
    }
  }, [props.openModal]);

  const getPagoById = async(id_pago) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getPagoById/${id_pago}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setNuevoPago({...nuevoPago,
          id_concepto: data.id_concepto,
          fecha_pago: moment.utc(data.fecha_pago).format('YYYY-MM-DD'),
          tipo_operacion: data.tipo_operacion,
          detalle_concepto: data.detalle_concepto,
          tipo_documento: data.tipo_documento,
          serie: data.serie,
          numero_serie: data.numero_serie,
          monto: data.monto,
          responsable: data.responsable,
          observacion: data.observacion,
          TipCambio: data.TipCambio,
          ubicacion_archivo: data.ubicacion_archivo,
          parv_descripcion: data.parv_descripcion,
          id_proveedor: data.id_provee,
          id_factura: data.id_factura,
          id_area: data.id_area,
          descripcion: data.descripcion,
          ruc: data.ruc,
          nombre_comercial: data.nombre_comercial,
          numero_cuenta: data.numero_cuenta,
          cci: data.cci,
          tipo_pago: data.tipo_pago,
          forma_pago: data.forma_pago
        })

        getCuentasProveedor(data.id_provee)

        form.setFieldsValue({
          id_concepto: data.id_concepto,
          tipo_operacion: data.tipo_operacion,
          detalle_concepto: data.detalle_concepto,
          tipo_documento: data.tipo_documento,
          serie: data.serie,
          numero_serie: data.numero_serie,
          monto: data.monto,
          responsable: data.responsable,
          observacion: data.observacion,
          parv_descripcion: data.parv_descripcion,
          TipCambio: data.TipCambio,
          id_proveedor: data.id_provee,
          fecha: moment(data.fecha_pago).format('DD/MM/YYYY'),
          id_area: data.id_area
        })
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getCambioDolar = async() => {
    const cambioToday = await getTipoCambioToday()
    if (cambioToday.message) {
      message.warning("No se ha Registrado el tipo de cambio de hoy", 5)
      setNuevoPago({...nuevoPago, 
        TipCambio: 1
      })
      handleOpenModalTipoCambio()
    } else {
      setNuevoPago({...nuevoPago, 
        TipCambio: cambioToday[0].TipCambio
      })
      form.setFieldsValue({TipCambio:cambioToday[0].TipCambio})
    }
  }

  const handleOpenModalTipoCambio = () => {
    setOpenModal(true)
    setNuevoPago({...nuevoPago, 
      TipCambio: 1
    })
  }

  const getCuentasProveedor = async(id_prove) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getCuentasProveedor/${id_prove}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          setDetalleBancos([])
        } else {
          setDetalleBancos(data)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const AddPago = async() => {
    // console.log(nuevoPago.monto, props.totalMontoPagos, props.factura.total)
    // console.log(props.factura)
    if (nuevoPago.parv_descripcion === 'DOLARES' && nuevoPago.TipCambio === 1) {
      // message.error('Falta Ingresar el Tipo de Cambio')
      if (props.factura.parv_descripcion === 'DOLARES') {
        getCambioDolar()
      }
    } else {
      if (nuevoPago.ubicacion_archivo === null) {
        message.error('Debe Subir el PDF del Pago')
      } else {
        if (nuevoPago.ubicacion_archivo[0] === undefined) {
          message.error('Debe Subir el PDF del Pago')
        } else {
          if (props.factura) {
            if ( ( props.factura.pago_pendiente - nuevoPago.monto ) > props.factura.total ) {
              message.error('El monto total de los Pagos excede al total de la Orden de Compra')
            } else {
              try {
                await fetch(`${process.env.REACT_APP_HOSTNAME}/AddPago`, {
                  method: "POST",
                  body: JSON.stringify({...nuevoPago, id_proveedor: null}),
                  headers:{
                    'Content-Type': 'application/json'
                  }
                }).then(res => res.json())
                .then(data =>{
                  UploadFilePago(data.id_pago)
                }).catch(err => console.log(err));
    
              } catch (error) {
                console.log(error)
              }    
            }
          } else {
            if (nuevoPago.id_proveedor === null) {
              message.error('Seleccionar Proveedor')
            } else {
              try {
                await fetch(`${process.env.REACT_APP_HOSTNAME}/AddPago`, {
                  method: "POST",
                  body: JSON.stringify(nuevoPago),
                  headers:{
                    'Content-Type': 'application/json'
                  }
                }).then(res => res.json())
                .then(data =>{
                  UploadFilePago(data.id_pago)
                }).catch(err => console.log(err));

              } catch (error) {
                console.log(error)
              }   
            }
          }
        }
      }
    }
  }

  const EditPago = async() => {
    if (nuevoPago.parv_descripcion === 'DOLARES' && nuevoPago.TipCambio === 1) {
      message.error('Falta Ingresar el Tipo de Cambio')
    } else {
      if (nuevoPago.id_proveedor === null) {
        message.error('Seleccionar Proveedor')
      } else {
        setLoadingButton(true)
        try {
          await fetch(`${process.env.REACT_APP_HOSTNAME}/UpdatePago`,{
            method: "PUT",
            body: JSON.stringify({...nuevoPago, id_pago: props.idPago}),
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(res => {
            message.success("Pago Actualizado")
            setLoadingButton(false)
            props.getPagos()
            props.handleCloseModal()
          }).catch(err => {
            console.log(err)
            setLoadingButton(false)
          });
        } catch (error) {
          console.log(error)
          setLoadingButton(false)
        }
      }
    }
  }

  const UploadFilePago = async(id_nuevoPago) => {
    // message.success("Pago Registrado")
    setLoadingButton(true)
    const body = {
      destination:'Logistica',
      filename:`Pago_${id_nuevoPago}.pdf`,
      id_pago: id_nuevoPago
    }

    const bodyForm = new FormData()

    bodyForm.append('tabla', JSON.stringify(body))
    bodyForm.append('File', nuevoPago.ubicacion_archivo[0])

    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/UploadFilePago`, {
        method: "POST",
        body: bodyForm
      }).then(res => res.json())
      .then(data =>{
        setLoadingButton(false)
        message.success(data.mensaje)
        // resetForm()
        setNuevoPago({
          id_concepto: 2,
          tipo_operacion: props.operacion,
          detalle_concepto: '',
          tipo_documento: null,
          serie: '',
          numero_serie: '',
          monto: null,
          responsable: null,
          observacion: null,
          fecha_pago: moment.utc(new Date()).format('YYYY-MM-DD'),
          id_factura: props.factura?props.factura.id_factura:null,
          usuario: props.usuario,
          id_sucursal: props.factura?props.factura.id_sucursal:1,
          ubicacion_archivo: null,
          parv_descripcion: props.factura?props.factura.parv_descripcion:null,
          TipCambio: 1,
          id_proveedor: props.factura?props.factura.id_proveedor:null,
          id_area: props.factura? props.factura.id_area: null,
          descripcion: props.factura?props.factura.descripcion: null,
          ruc: props.factura?props.factura.ruc:null,
          nombre_comercial: props.factura?props.factura.nombre_comercial:null,
          numero_cuenta: null,
          cci: null,
          tipo_pago: props.factura?props.factura.tipo_pago: null,
          forma_pago: props.factura?props.factura.forma_pago: null
        })
        setDetalleBancos([])
        form.setFieldsValue({
          id_concepto: 2,
          tipo_operacion: props.operacion,
          detalle_concepto: '',
          tipo_documento: null,
          serie: null,
          numero_serie: null,
          monto: null,
          responsable: null,
          observacion: null,
          parv_descripcion: props.factura?props.factura.parv_descripcion:null,
          TipCambio: nuevoPago.TipCambio,
          id_proveedor: null,
          id_area: props.factura? props.factura.id_area: null
        })
        if (props.factura) {/**SE EXISTE PROPS.FACTURA, ESTÁ REGISTRANDO DESDE LA PESTAÑA */
          props.getFacturas()
          props.handleCloseModal()
        } else {/**SINO, ESTÁ REGISTRANDO DESDE MODAL DE MENÚ PAGOS */
          props.getPagos()
          props.handleCloseModal()
        }
      }).catch(err => {
        console.log(err)
        message.error('No se Pudo Subir el Archivo al Servidor')
        setLoadingButton(false)
      });
    } catch (error) {
      console.log(error)
      message.error('No se Pudo Subir el Archivo al Servidor')
      setLoadingButton(false)
    }
  }

  const subirArchivo = (e) => {
    // console.log(e[0].name)
    setNuevoPago({...nuevoPago, ubicacion_archivo: e})
  }

  const ChangeMoneda = async (e) => {
    setLoadingButton(true)
    if (e === 'DOLARES') {
      const cambioToday = await getTipoCambioToday()
      if (cambioToday.message) {
        message.warning("No se ha Registrado el tipo de cambio de hoy", 5)
        handleOpenModalTipoCambio()
      } else {
        setNuevoPago({...nuevoPago, 
          TipCambio: cambioToday[0].TipCambio, 
          parv_descripcion: e
        })
      }
      setLoadingButton(false)
    } else {
      setLoadingButton(false)
      setNuevoPago({...nuevoPago, 
        TipCambio: 1, 
        parv_descripcion: e
      })
    }
  }

  const cuentaChange = (value, item) => {
    const listaItems = detalleBancos
    const key = listaItems.indexOf(item)

    if (value === false) {
      setNuevoPago({...nuevoPago, numero_cuenta: null})
    } else {
      setNuevoPago({...nuevoPago, numero_cuenta: listaItems[key].numero_cuenta, cci: null})
    }
  }

  const cciChange = (value, item) => {
    const listaItems = detalleBancos
    const key = listaItems.indexOf(item)

    if (listaItems[key].cci !== null && listaItems[key].cci !== '') {
      if (value === false) {
        setNuevoPago({...nuevoPago, cci: null})
      } else {
        setNuevoPago({...nuevoPago, cci: listaItems[key].cci, numero_cuenta: null})
      }  
    }
  }

  const columnsBancos = [
    { title: 'BANCO', key: 'Descripcion', dataIndex: 'Descripcion' },
    { title: 'MONEDA', key: 'moneda', dataIndex: 'moneda' },
    { title: 'N° CUENTA', key: 'numero_cuenta', dataIndex: 'numero_cuenta' },
    {
      title: '',
      render: record => {
        return(
          <>
            <Checkbox checked={record.numero_cuenta === nuevoPago.numero_cuenta? true: false} 
              onChange={e => cuentaChange(e.target.checked, record)}/>
          </>
        )
      }
    },
    { title: 'CCI', key: 'cci', dataIndex: 'cci' },
    {
      title: '',
      render: record => {
        return(
          <>
            <Checkbox checked={record.cci === nuevoPago.cci && record.cci !== null? true: false} 
              onChange={e => cciChange(e.target.checked, record)}/>
          </>
        )
      }
    },
  ]

  return(
    <>
      <Modal
        visible={props.openModal}
        closable={false}
        width='90%'
        title={
          <h3 className='m-0'>
            {props.idPago === null?'Nuevo Pago':`Editar Pago ${nuevoPago.serie} - ${nuevoPago.numero_serie}`}
          </h3>}
        onCancel={props.handleCloseModal}
        footer={null}
      >
        <Form form={form} layout='vertical' onFinish={props.idPago === null? AddPago: EditPago}>
          <div className='elem-list'>
            <div className='col-sm-5 col-md-2 col-lg-2'>
              <Item label="Fecha" name="fecha">
                <b>{moment(nuevoPago.fecha_pago).format('DD/MM/YYYY')}</b>
              </Item>
            </div>

            <div className='col-sm-6 col-md-2 col-lg-2'>
              <Item label="Documento" name="tipo_documento">
                <Select
                  allowClear
                  defaultValue={nuevoPago.tipo_documento}
                  className='mb-lg-0'
                  placeholder='Seleccionar Doc.'
                  optionFilterProp='children'
                  filterOption={optionFilter}
                  onChange={(e)=> setNuevoPago({...nuevoPago, tipo_documento:e})}
                >
                  <Option value={'BOLETA'} key={'BOLETA'}>BOLETA</Option>
                  <Option value={'FACTURA'} key={'FACTURA'}>FACTURA</Option>
                  <Option value={'RECIBO POR HONORARIOS'} key={'RECIBO POR HONORARIOS'}>RECIBO POR HONORARIOS</Option>
                  <Option value={'VALE INTERNO'} key={'VALE INTERNO'}>VALE INTERNO</Option>
                  <Option value={'NOTA DE CRÉDITO'} key={'NOTA DE CRÉDITO'}>NOTA DE CRÉDITO</Option>
                  <Option value={'NOTA DE DÉBITO'} key={'NOTA DE DÉBITO'}>NOTA DE DÉBITO</Option>
                </Select>
              </Item>
            </div>

            <div className='col-sm-5 col-md-2 col-lg-2'>
              <Item label="Serie" name="serie">
                <Input defaultValue={nuevoPago.serie}
                  onChange={e=> setNuevoPago({...nuevoPago, serie:e.currentTarget.value})}
                />
              </Item>
            </div>

            <div className='col-sm-6 col-md-2 col-lg-2'>
              <Item label="Número" name="numero_serie">
                <Input defaultValue={nuevoPago.numero_serie}
                  onChange={e=> setNuevoPago({...nuevoPago, numero_serie: e.currentTarget.value})}
                />
              </Item>
            </div>

            {props.idPago === null?
            <div className='col-sm-5 col-md-2 col-lg-2'>
              <b style={{color:"red"}}>* </b>
              <b className='mr-4'>{nuevoPago.ubicacion_archivo === null?null:
                nuevoPago.ubicacion_archivo[0] === undefined?null:nuevoPago.ubicacion_archivo[0].name}</b>
              <br/>
              <input ref={input} onChange={(e) => subirArchivo(e.target.files)} type='file' style={{ display: 'none' }} />
              <Button size='small' type={'primary'} className='btn-outline mt-2' onClick={handleClick} icon={<FilePdfOutlined />}>
                Subir PDF
              </Button>
            </div>
            :null}
            {/* <div className='col-sm-6 col-md-2 col-lg-2'>
              <Item label="Tipo" name="tipo_operacion">
                <Select
                  defaultValue={nuevoPago.tipo_operacion}
                  disabled = {props.disabled}
                  className='mb-lg-0'
                  placeholder='Seleccionar Estado'
                  optionFilterProp='children'
                  filterOption={optionFilter}
                  onChange={(e)=> setNuevoPago({...nuevoPago, tipo_operacion:e})}
                >
                  <Option value='EGRESO' key='EGRESO'>EGRESO</Option>
                  <Option value='INGRESO' key='INGRESO'>INGRESO</Option>
                </Select>
              </Item>
            </div> */}

            {/* <div className='col-sm-11 col-md-3 col-lg-3'>
              <Item label="Concepto" name="id_concepto" rules={[
                  {...rules.required, message: 'El Concepto es Requerido'}
              ]}>
                <Select
                  defaultValue={nuevoPago.id_concepto}
                  className='mb-lg-0'
                  placeholder='Seleccionar Concepto'
                  optionFilterProp='children'
                  filterOption={optionFilter}
                  onChange={(e)=> setNuevoPago({...nuevoPago, id_concepto: e})}
                >
                  {props.conceptosList.map(concepto =>{
                    return(
                      <Option value={concepto.id_concepto} key={concepto.id_concepto}>{concepto.nombre}</Option>
                    )
                  })}
                </Select>
              </Item>
            </div> */}

            {/* <div className='col-sm-11 col-md-3 col-lg-3'>
              <Item label="Detalle de Concepto" name="detalle_concepto" rules={[
                  {...rules.required, message: 'El Detalle es Requerido'}
              ]}>
                <Input defaultValue={nuevoPago.detalle_concepto}
                  onChange={e=> setNuevoPago({...nuevoPago, detalle_concepto: e.currentTarget.value})}
                />
              </Item>
            </div> */}
          </div>

          <div className='elem-list'>
            <div className='col-sm-6 col-md-2 col-lg-2'>
              <Item label="Moneda" name="parv_descripcion">
                <Select
                  disabled = {nuevoPago.id_factura === null? false: true}
                  defaultValue={nuevoPago.parv_descripcion}
                  className='mb-lg-0'
                  placeholder='Seleccionar Moneda'
                  optionFilterProp='children'
                  filterOption={props.optionFilter}
                  onChange={(e)=> ChangeMoneda(e)}
                >
                  <Option value={'SOLES'} key={'SOLES'}>S/.</Option>
                  <Option value={'DOLARES'} key={'DOLARES'}>$</Option>
                </Select>
              </Item>
            </div>

            <div className='col-sm-5 col-md-2 col-lg-2'>
                <Item label="Tipo de Cambio" name="TipCambio">
                  <b>{nuevoPago.TipCambio}</b>
                </Item>
            </div>

            <div className='col-sm-5 col-md-2 col-lg-2'>
              <Item label="Monto" name="monto" rules={[
                  {...rules.required, message: 'El Monto es Requerido'}
              ]}>
                <Input type='number' defaultValue={nuevoPago.monto}
                  onChange={e=> setNuevoPago({...nuevoPago, monto: parseFloat(e.currentTarget.value)})}
                />
              </Item>
            </div>

            <div className='col-sm-6 col-md-3 col-lg-3'>
              <label className='mb-3' style={{fontWeight:'bold'}}>Modo de Pago:</label>
              <br />
              <b>{nuevoPago.tipo_pago} - {nuevoPago.forma_pago}</b>  
            </div>
          </div>

          <div className='elem-list'>
            <div className='col-sm-11 col-md-4 col-lg-4'>
              <label className='mb-3' style={{fontWeight:'bold'}}>Proveedor</label>
              <br />
              <b>{nuevoPago.descripcion}</b>
            </div>

            <div className='col-sm-11 col-md-4 col-lg-4'>
              <label className='mb-3' style={{fontWeight:'bold'}}>Nombre Comercial</label>
              <br />
              <b>{nuevoPago.nombre_comercial}</b>
            </div>

            <div className='col-sm-11 col-md-3 col-lg-3'>
              <label className='mb-3' style={{fontWeight:'bold'}}>RUC</label>
              <br />
              <b>{nuevoPago.ruc}</b>
            </div>
          </div>

          {props.factura.forma_pago === 'EFECTIVO'? null:
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <Card title='Cuentas'> 
              <Table 
                pagination={false} 
                columns={columnsBancos}
                dataSource={detalleBancos}
                size="small"
              />
            </Card>                    
          </div>}

          <div className='elem-list mt-2'>
            <div className='col-sm-8 col-md-6 col-lg-6'>
              <Item label="Responsable del Pago" name="responsable" rules={[
                  {...rules.required, message: 'El Responsable es Requerido'}
              ]}>
                <Input defaultValue={nuevoPago.responsable}
                  onChange={e=> setNuevoPago({...nuevoPago, responsable: e.currentTarget.value})}
                />
              </Item>
            </div>
          </div>

          <div className='col-sm-11 col-md-11 col-lg-11'>
            <Item label="Observación" name="observacion">
              <Input.TextArea defaultValue={nuevoPago.observacion} placeholder = "Ingresar Alguna Observación Adicional"
                onChange={e=> setNuevoPago({...nuevoPago, observacion: e.currentTarget.value})}
              />
            </Item>
          </div>

          <div className='d-flex justify-content-between'>
            <Button danger onClick={props.handleCloseModal} icon={<CloseOutlined />}>
              Cerrar
            </Button>

            <Button className='bg-color-success' loading={loadingButton} type='primary' icon={<SaveOutlined />} htmlType="submit">
              Guardar
            </Button>
          </div>
        </Form>

        {<ModalTipoCambio
          openModal = {openModal}
          setOpenModal = {setOpenModal}
          loadingButtonAprobar = {loadingButton}
          setLoadingButtonAprobar = {setLoadingButton}
          setTabla = {setNuevoPago}
          tabla = {nuevoPago}
          usuario = {props.usuario}
        />}  
      </Modal>
    </>
  )
}

export default FormularioPago