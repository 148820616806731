import {useEffect, useRef, useState} from "react";
import { IPageData } from "../../../interfaces/page";
import { usePageData } from '../../../hooks/usePage';
import Cookies from "universal-cookie";
import { Card, Table, Button, Select, Input, Form } from 'antd';
import { EditOutlined, FilePdfOutlined, PlusOutlined, StockOutlined, FileOutlined } from "@ant-design/icons";
import ModalArticulo from "./ModalArticulo";
import { PdfFileArticulos } from "../componentesPDF/GenerarPDFArticulo";
import { ExcelFileArticulos } from "../componentesExcel/GenerarExcelArticulo";
import { Option } from 'antd/lib/mentions';
import { existeRol } from "../FuncionesGenerales";
import ModalArticulosStockDisponible from "./ModalArticulosStockDisponible";
import ModalRubroForm from "../componentesRubros/ModalRubroForm";
import ModalUnidadForm from "../componentesUnidades/ModalUnidadForm";
import ModalArticuloFiles from "./ModalArticuloFiles";

const cookies = new Cookies()

const pageData: IPageData = {
  fulFilled: true,
  title: 'Artículos',
  breadcrumbs: [
    {
      title: 'Artículos',
    }
  ]
};

const Articulos = () => {
  usePageData(pageData);
  const componentePDF = useRef(null)
  const [pestaña, setPestaña] = useState('items');

  const [articulosList, setArticulosList] = useState([])
  const [stockAlmacenDisponible, setStockAlmacenDisponible] = useState([])
  const [stockAreasDisponible, setstockAreasDisponible] = useState([])
  const [rubrosList, setRubrosList] = useState([])
  const [unidadesList, setUnidadesList] = useState([])
  const [articuloSeleccionado, setArticuloSeleccionado] = useState({})
  const [tituloModal, setTituloModal] = useState('')
  const [openModal, setOpenModal] = useState(false);
  const [openModalStock, setOpenModalStock] = useState(false);
  const [inputCodigo, setInputCodigo] = useState('');
  const [inputNombre, setInputNombre] = useState('');
  const [inputCodigoRubro, setInputCodigoRubro] = useState('');
  const [inputNombreRubro, setInputNombreRubro] = useState('');
  const [selectRubro, setSelectRubro] = useState(undefined);
  const [selectStock, setSelectStock] = useState(undefined);
  const [selectCheckInventario, setSelectCheckInventario] = useState(undefined);
  const [inputNombreUnidad, setInputNombreUnidad] = useState('');

  const [rolLogistica, setRolLogistica] = useState(false);

  const [openModalRubro, setOpenModalRubro] = useState(false)
  const [openModalUnidad, setOpenModalUnidad] = useState(false)
  const [openModalArchivos, setOpenModalArchivos] = useState(false)

  const [form] = Form.useForm();
  const resetForm = () => form.resetFields();

  useEffect(() => {
    if (!cookies.get('nameid_logis') || existeRol('Logistica.Acceso.Articulos') === false) {
      // history.push(`/public/sign-in`)
      window.location.href='/'      
    } else {
      getArticulos()
      getRubros()
      getUnidades()
    }

    setRolLogistica(existeRol('Logistica.Gestion.Articulos'))

  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getArticulos()
    }, 10000);
    return () => clearInterval(interval);
  }, [articulosList])

  const getArticulos = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getArticulos`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          setArticulosList([])
        } else {
          setArticulosList(data)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getRubros = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getRubros`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          setRubrosList([])
        } else {
          setRubrosList(data)
        }
        
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getUnidades = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getUnidades`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          setUnidadesList([])
        } else {
          setUnidadesList(data)
        }
        
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleOpenModalArchivos = (articulo) => {
    setOpenModalArchivos(true)
    setArticuloSeleccionado(articulo)
  }

  const handleOpenModal = async(titulo, id_articulo) => {
    resetForm()
    setOpenModal(true)
    setTituloModal(titulo)

    /**NUEVO ARTÍCULO */
    if (id_articulo === null) {
      setArticuloSeleccionado({
        id_articulo: null,
        nombre: null,
        id_rubro: null,
        ean: null,
        identificador: null
      })
    } else {
      /**EDITAR ARTÍCULO */
      try {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/getArticuloById/${id_articulo}`)
        .then(res => res.json())
        .then(data => {
          setArticuloSeleccionado(data)
          form.setFieldsValue({
            nombre: data.nombre,
            id_rubro: data.id_rubro,
            ean: data.ean,
            identificador: data.identificador
          })
        })
      } catch (error) {
        console.log(error)
      }
    }
  }

  let pestañasList = [
    { key: 'items', tab: 'Items' },
    { key: 'rubros', tab: 'Rubros' },
    { key: 'unidades', tab: 'Presentaciones' }
  ]

  const handleOpenModalStock = async(articulo) => {
    setOpenModalStock(true)
    setArticuloSeleccionado(articulo)
    const body = {id_articulo: articulo.id_articulo, id_sucursal: 1}
    getStockAlmacenes(body)
    getStockAreas(body)
  }

  const getStockAlmacenes = async(body) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getMovimientosAlmacenesByArticulo`,{
        method: "POST",
        body: JSON.stringify(body),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          setStockAlmacenDisponible([])
        }else {
          const total_entradas = data.filter(item => {return item.tipo === 'ENTRADA A ALMACEN' || item.tipo === 'STOCK INICIAL'})
          const total_salidas = data.filter(item => {
            return (item.tipo === 'SALIDA DE ALMACEN' || item.tipo === 'PRESTAMO' || item.tipo === 'DONACION'|| item.tipo === 'DESCARTE')
          })
          let entradas_destinos = []
          for (let i = 0; i < total_entradas.length; i++) {
            const element = total_entradas[i];
            if (entradas_destinos.length === 0) {
              entradas_destinos = [{destino: element.destino}]
            } else {
              const destino_repetido = entradas_destinos.filter(item => element.destino === item.destino)
              if (destino_repetido.length === 0) {
                entradas_destinos = [...entradas_destinos, {destino: element.destino}]
              }
            }
          }

          for (let i = 0; i < entradas_destinos.length; i++) {
            const element = entradas_destinos[i];
            const entradas = total_entradas.filter(item => item.destino === element.destino)
            // console.log(entradas)
            if (entradas.length > 0) {
              entradas_destinos[i] = {...entradas_destinos[i], 
                ingresos: entradas.map(item => item.ingresos).reduce((prev, next) => prev + next)
              }
            }

            const salidas = total_salidas.filter(item => item.origen === element.destino)
            // console.log(salidas)
            if (salidas.length > 0) {
              entradas_destinos[i] = {...entradas_destinos[i], 
                egresos: salidas.map(item => item.egresos).reduce((prev, next) => prev + next)
              }
            }
          }
          // console.log(entradas_destinos)
          setStockAlmacenDisponible(entradas_destinos)
        }
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error)
    }
  }

  const getStockAreas = async(body) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getMovimientosAreasByArticulo`,{
        method: "POST",
        body: JSON.stringify(body),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          setstockAreasDisponible([])
        } else {
          let entradas = data.filter(item => {return item.tipo === 'SALIDA DE ALMACEN'})
          for (let i = 0; i < entradas.length; i++) {
            const entrada = entradas[i]
            const salidas = data.filter(item => {
              return (item.tipo === 'ENTRADA A ALMACEN' || item.tipo === 'PRESTAMO' || item.tipo === 'DONACION' || item.tipo === 'DESCARTE') && 
                item.id_area_origen === entradas[i].id_area_destino
            })
            entradas[i] = {...entrada, 
              disponible: salidas[0] === undefined? entrada.ingresos: entrada.ingresos - salidas[0].egresos}  
          }
          // console.log(entradas)
          setstockAreasDisponible(entradas)
        }
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error)
    }
  }

  const optionFilter = (input, option) => {
    return (option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0)
  }

  const onTabChange = (setter: (val: string) => void) => (key: string) => {
    setter(key);
  };

  const columns = [
    {
      title: (<div style={{width:80}}></div>),
      render: record => {
        return(
          <>
            {rolLogistica === true?
            <>
              <Button type='primary' className="bg-color-orange mr-2"
                title="Editar" 
                onClick={e=>handleOpenModal('Editar Artículo', record.id_articulo)}
              icon={<EditOutlined />} />

              <Button type='primary' className='bg-color-success mr-2'
                title="Ver Stock Detallado" 
                onClick={e=>handleOpenModalStock(record)}
              icon={<StockOutlined />} />

              <Button title="Ver Archivos" 
                onClick={e=>handleOpenModalArchivos(record)}
              icon={<FileOutlined />} />
            </>
            : null
            }
          </>
        )
      }
    },
    {
      title: (
        <>
          <div style={{width:100}}>
            <Input value={inputCodigo} 
              onChange={e=>setInputCodigo(e.currentTarget.value)} placeholder = "CÓDIGO"
            />
          </div>
        </>
      ),
      dataIndex: 'codigo',
      key: 'codigo'
    },
    {
      title:(
        <>
          <div style={{width:250}}>
            <Input value={inputNombre} 
              onChange={e=>setInputNombre(e.currentTarget.value)} placeholder = "NOMBRE"
            />
          </div>
        </>
      ),
      dataIndex: 'nombre',
      key: 'nombre'
    },
    {
      title:(
        <>
          <div style={{width:250}}>
            <Select
              allowClear
              showSearch
              value={selectRubro}
              className='mb-lg-0'
              placeholder='RUBRO'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={e=>setSelectRubro(e)}
            >
              {rubrosList.map(rubro =>{
                return(<Option value={rubro.id_rubro} key={rubro.id_rubro}>{rubro.nombre}</Option>)
              })}
            </Select>
          </div>
        </>
      ),
      dataIndex: 'nombre_rubro',
      key: 'nombre_rubro'
    },
    /*{
      title:'COD. BARRAS',
      dataIndex: 'ean',
      key: 'ean'
    },*/
    {
      title:(
        <>
          <div style={{width:220}}>
            <Select
              allowClear
              showSearch
              value={selectStock}
              style={{width:220}}
              className='mb-lg-0'
              placeholder='STOCK DISP. (ALMACENES)'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={e=>setSelectStock(e)}
            >
              <Option value={'SI'} key={'SI'}>SI</Option>
              <Option value={'NO'} key={'NO'}>NO</Option>
            </Select>  
          </div>
          
        </>),
      render: record => {
        if (record.stock_disponible <= 0) {
          return(<p style={{color:'#EE0000'}}>{record.stock_disponible}</p>)
        } else {
          return(<p>{record.stock_disponible}</p>)
        }
      }
    }
  ]

  const columnsRubros = [
    {
      title:(
        <>
          <div style={{width:250}}>
            <Input value={inputNombreRubro} 
              onChange={e=>setInputNombreRubro(e.currentTarget.value)}
                placeholder = "NOMBRE"
            />
          </div>
        </>
      ),
      dataIndex: 'nombre',
      key: 'nombre'
    },
    {
      title: (
        <>
          <div style={{width:100}}>
            <Input value={inputCodigoRubro} 
              onChange={e=>setInputCodigoRubro(e.currentTarget.value)}
                placeholder = "CÓDIGO"
            />
          </div>
        </>
      ),
      dataIndex: 'codigo',
      key: 'codigo'
    },
    {
      title:(
        <>
          <div style={{width:100}}>
            <Select
              allowClear
              showSearch
              value={selectCheckInventario}
              style={{width:220}}
              className='mb-lg-0'
              placeholder='ART. DE INVENTARIO'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={e=>setSelectCheckInventario(e)}
            >
              <Option value={'SI'} key={'SI'}>SI</Option>
              <Option value={'NO'} key={'NO'}>NO</Option>
            </Select>  
          </div>
        </>
      ),
      dataIndex: 'checkInventario',
      key: 'checkInventario',
      render: text => (
        text === false?'NO':'SI'
      )
    }
  ]

  const columnsUnidades = [
    {
      title:(
        <>
          <div style={{width:250}}>
            <Input value={inputNombreUnidad} 
              onChange={e=>setInputNombreUnidad(e.currentTarget.value)}
                placeholder = "PRESENTACIÓN"
            />
          </div>
        </>
      ),
      render: record => {
        return(<><div>{record.nombre}X{record.cantidad_unidades}</div></>)
      }
    },
    {
      title: 'ABREVIATURA',
      dataIndex: 'abreviatura',
      key: 'abreviatura'
    }
  ]

  const articulosListFiltrados = () => {
    const lista = articulosList.filter(articulo => {
      return ( articulo.codigo.toUpperCase().includes(inputCodigo.toUpperCase()) && 
        articulo.nombre.includes(inputNombre.toUpperCase()) && 
        (selectRubro === undefined?
          articulo.id_rubro !== selectRubro: articulo.id_rubro === selectRubro) &&
        (selectStock === undefined? articulo.stock_disponible !== selectRubro: 
          selectStock === 'SI'? articulo.stock_disponible > 0: articulo.stock_disponible <= 0) 
        )
    })

    return lista
  }

  const rubrosListFiltrados = () => {
    const lista = rubrosList.filter(rubro => {
      return ( rubro.codigo.toUpperCase().includes(inputCodigoRubro.toUpperCase()) && 
        rubro.nombre.includes(inputNombreRubro.toUpperCase()) &&
        (selectCheckInventario === undefined?
          rubro.checkInventario !== selectCheckInventario:
          selectCheckInventario === 'SI'? rubro.checkInventario === true: rubro.checkInventario === false
          )
      )
    })

    return lista
  }

  const unidadesListFiltrados = () => {
    const lista = unidadesList.filter(unidad => {
      return ( unidad.nombre.toUpperCase().includes(inputNombreUnidad.toUpperCase()) )
    })

    return lista
  }

  const contenidoPestañas = {
    items:
      <>
        <Table
          columns={columns}
          dataSource={articulosListFiltrados()}
          size='small'
        />
      </>,
    rubros:
      <>
        <Table
          columns={columnsRubros}
          dataSource={rubrosListFiltrados()}
          size='small'
        />
      </>,
    unidades:
      <>
        <Table
          columns={columnsUnidades}
          dataSource={unidadesListFiltrados()}
          size='small'
        />
      </>
  }

  const ExportarPDF = () => {
    componentePDF.current.save()
  }

  const handleOpenModalRubro = () => {
    setOpenModalRubro(true)
  }
  
  const handleOpenModalUnidad = () => {
    setOpenModalUnidad(true)
  }

  if (articulosList.length > 0) {
    return(
      <>
        {PdfFileArticulos(componentePDF, articulosListFiltrados())}
        <div className='row'>
          <div className='elem-list ml-2'>
            {rolLogistica === true?
              <Button type='primary'  
                title="Nuevo Artículo" 
                onClick={e=>handleOpenModal('Nuevo Artículo', null)}
                icon={<PlusOutlined />} >Item
              </Button>   

            : null
            }

            {rolLogistica === true?
              <Button type='primary'  
                title="Nuevo Rubro" 
                onClick={e=>handleOpenModalRubro()}
                icon={<PlusOutlined />} >Rubro
              </Button>   

            : null
            }

            {rolLogistica === true?
              <Button type='primary'  
                title="Nueva Presentación" 
                onClick={e=>handleOpenModalUnidad()}
                icon={<PlusOutlined />} >Presentación
              </Button>   

            : null
            }
            
            <Button danger 
              title="Exportar PDF" 
              onClick={e => ExportarPDF()}
              icon={<FilePdfOutlined />} >PDF
            </Button>
            {ExcelFileArticulos(articulosListFiltrados())}
          </div>
        </div>

        <Card
          tabList={pestañasList}
          activeTabKey={pestaña}
          onTabChange={onTabChange(setPestaña)}
        >
          {contenidoPestañas[pestaña]}
        
          {<ModalArticulo
            openModal = {openModal}
            tituloModal = {tituloModal}
            articulo = {articuloSeleccionado}
            setArticulo = {setArticuloSeleccionado}
            form = {form}
            getArticulos = {getArticulos}
            setOpenModal = {setOpenModal}
            rubrosList = {rubrosList}
            usuario = {cookies.get('nameid_logis')}
            getRubros = {getRubros}
          />}

          {<ModalArticulosStockDisponible
            stockAlmacenDisponible = {stockAlmacenDisponible}
            stockAreasDisponible = {stockAreasDisponible}
            setStockAlmacenDisponible = {setStockAlmacenDisponible}
            setstockAreasDisponible = {setstockAreasDisponible}
            articulo = {articuloSeleccionado}
            openModal = {openModalStock}
            setArticulo = {setArticuloSeleccionado}
            setOpenModal = {setOpenModalStock}
          />}

        {<ModalRubroForm
          openModalRubro = {openModalRubro}
          setOpenModalRubro = {setOpenModalRubro}
          usuario = {cookies.get('nameid_logis')}
          rubrosList = {rubrosList}
          getRubros = {getRubros}
        />}

        {<ModalUnidadForm
          openModalUnidad = {openModalUnidad}
          setOpenModalUnidad = {setOpenModalUnidad}
          getUnidades = {getUnidades}
          usuario = {cookies.get('nameid_logis')}
        />}

        {<ModalArticuloFiles
          openModal = {openModalArchivos}
          setOpenModal = {setOpenModalArchivos}
          articulo = {articuloSeleccionado}
        />}
        </Card>
      </>
    )
  } else {
    return(
      <>
        <div className='row'>
          <div className='elem-list mt-4'>
            {rolLogistica === true?
              <Button type='primary'  
                title="Nuevo Artículo" 
                onClick={e=>handleOpenModal('Nuevo Artículo', null)}
                icon={<PlusOutlined />} >Agregar
              </Button>   
            : null
            }

            {rolLogistica === true?
              <Button type='primary'  
                title="Nuevo Rubro" 
                onClick={e=>handleOpenModalRubro()}
                icon={<PlusOutlined />} >Rubro
              </Button>   

            : null
            }
          </div>
        </div>

        <Card>
          <div>No hay Datos</div>

          {<ModalArticulo
            openModal = {openModal}
            tituloModal = {tituloModal}
            articulo = {articuloSeleccionado}
            setArticulo = {setArticuloSeleccionado}
            form = {form}
            getArticulos = {getArticulos}
            setOpenModal = {setOpenModal}
            rubrosList = {rubrosList}
            usuario = {cookies.get('nameid_logis')}
            getRubros = {getRubros}
          />}

          {<ModalRubroForm
            openModalRubro = {openModalRubro}
            setOpenModalRubro = {setOpenModalRubro}
            usuario = {cookies.get('nameid_logis')}
            rubrosList = {rubrosList}
            getRubros = {getRubros}
          />}
        </Card>
      </>
    )
  }
  
}

export default Articulos