import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import moment from 'moment';

const dataList = (ordenesTrabajo) => {
  const lista = ordenesTrabajo.map(item => {
    return {...item, 
      fecha_orden: moment.utc(item.fecha_orden).format('DD/MM/YY'),

    }
  })

  return lista
}

export const OrdenesTrabajoExcel = (componenteExcel, ordenesTrabajo) => {
  const data = dataList(ordenesTrabajo)
  return(
    <>
      <ExcelExport 
        data={data} 
        fileName="Ordenes de Trabajo.xlsx" 
        ref={componenteExcel}
      >
        <ExcelExportColumn
          field="fecha_orden" title="FECHA" width = {120} 
        />
        <ExcelExportColumn
          field="descripcion" title="DESCRIPCIÓN" width = {120} 
        />
        <ExcelExportColumn
          field="codigo_patrimonial" title="CÓD. PATRIMONIAL" width = {120} 
        />
       <ExcelExportColumn
          field="nombre_articulo" title="EQUIPO" width = {120} 
        />
        <ExcelExportColumn
          field="marca" title="MARCA" width = {120} 
        />
        <ExcelExportColumn
          field="modelo" title="MODELO" width = {120} 
        />
        <ExcelExportColumn
          field="serie" title="SERIE" width = {150} 
        />
        <ExcelExportColumn
          field="proveedor" title="PROVEEDOR" width = {200} 
        />
        <ExcelExportColumn
          field="tipo_mantenimiento" title="TIPO MANT." width = {120} 
        />
        <ExcelExportColumn
          field="tipo" title="TIPO" width = {120} 
        />
        <ExcelExportColumn
          field="estado" title="ESTADO" width = {120} 
        />
        <ExcelExportColumn
          field="precio" title="PRECIO" width = {120} 
        />
        <ExcelExportColumn
          field="notas" title="NOTAS" width = {120} 
        />
      </ExcelExport>
    </>
  )
}