import { CloseOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal, Select, message } from 'antd'
import { Rule } from 'antd/lib/form'
import { Option } from 'antd/lib/mentions'
import {useEffect, useState} from "react"

const { Item } = Form

const rules = {
  required: { required: true, message: <></> } as Rule
}

const ModalFormEncargado = (props) => {
  const [loadingButton, setLoadingButton] = useState(false)
  const [encargado, setEncargado] = useState({
    nombres: null,
    DocumentoIdentidad: null
  })
  const [detalleAreas, setDetalleAreas] = useState([])
  const [form] = Form.useForm()
  
  const optionFilter = (input, option) => {
    return (option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0)
  }

  const handleCloseModal = () => {
    props.setOpenModal(false)
    props.setIdEncargado(null)
    setDetalleAreas([])
    setEncargado({
      nombres: null,
      DocumentoIdentidad: null
    })

    form.setFieldsValue({
      areas: undefined,
      nombres: null,
      DocumentoIdentidad: null
    })
  }

  useEffect(() => {
    if (props.idEncargado === null) {
      setDetalleAreas([])
      setEncargado({
        nombres: null,
        DocumentoIdentidad: null
      })
  
      form.setFieldsValue({
        areas: undefined,
        nombres: null,
        DocumentoIdentidad: null
      })
    } else {
      GetEncargadoById(props.idEncargado)
      GetAreasByEncargado(props.idEncargado)
    }
  }, [props.idEncargado])

  const GetEncargadoById = async(id_encargado) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/GetEncargadoById/${id_encargado}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setEncargado({
          nombres: data[0].nombres,
          DocumentoIdentidad: data[0].DocumentoIdentidad
        })

        form.setFieldsValue({
          nombres: data[0].nombres,
          DocumentoIdentidad: data[0].DocumentoIdentidad
        })
      })
    } catch (error) {
      console.log(error)
    }
  }
  
  const GetAreasByEncargado = async(id_encargado) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/GetAreasByEncargado/${id_encargado}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          setDetalleAreas([])
          form.setFieldsValue({
            areas: undefined
          })
        } else {
          let areas = []
          for (let i = 0; i < data.length; i++) {
            // console.log(data[i].id_area)
            areas = [...areas, data[i].id_area]
          }
          setDetalleAreas(areas)
          form.setFieldsValue({
            areas: areas
          })
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const AddEncargado = async() => {
    if (detalleAreas.length === 0) {
      message.error('Debe Ingresar al menos una Área')
    } else {
      const body = {
        encargado: {...encargado, usuario: props.usuario},
        detalleAreas: detalleAreas
      }

      setLoadingButton(true)

      try {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/AddEncargado`, {
          method: "POST",
          body: JSON.stringify(body),
          headers:{
            'Content-Type': 'application/json'
          }
        }).then(res => res.json())
        .then(data =>{
          // console.log(data)
          message.success(data.mensaje)
          setLoadingButton(false)
          props.GetEncargadosResiduos()
          handleCloseModal()
        }).catch(err => {
          console.log(err)
          setLoadingButton(false)
        })
      } catch (error) {
        console.log(error)
        setLoadingButton(false)
      }
    }
  }

  const UpdateEncargado = async() => {
    if (detalleAreas.length === 0) {
      message.error('Debe Ingresar al menos una Área')
    } else {
      const body = {
        encargado: {...encargado, id_encargado: props.idEncargado},
        detalleAreas: detalleAreas
      }

      setLoadingButton(true)

      try {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/UpdateEncargado`,{
          method: "PUT",
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => res.json())
        .then(data =>{
          message.success(data.mensaje)
          setLoadingButton(false)
          props.GetEncargadosResiduos()
          handleCloseModal()
        }).catch(err => {
          console.log(err)
          setLoadingButton(false)
        })
      } catch (error) {
        console.log(error)
        setLoadingButton(false)
      }
    }
  }

  return(
    <>
      <Modal
        visible={props.openModal}
        closable={false}
        width='90%'
        title={<h3 className='m-0'>{props.idEncargado === null?'Nuevo Personal':'Editar Personal'}</h3>}
        onCancel={handleCloseModal}
        footer={null}
      >
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <Form form={form} layout='vertical' onFinish={ props.idEncargado === null? AddEncargado : UpdateEncargado}>
              <div className='elem-list'>
                <div className='col-sm-11 col-md-5 col-lg-5'>
                  <Item label="Nombres" name="nombres" rules={[
                    {...rules.required, message: 'Nombres Requeridos'}
                  ]}>
                    <Input defaultValue={encargado.nombres}
                      onChange={e=> setEncargado({...encargado, nombres: e.currentTarget.value})}
                    />
                  </Item>
                </div>

                <div className='col-sm-11 col-md-5 col-lg-5'>
                  <Item label="DNI" name="DocumentoIdentidad" rules={[
                      {...rules.required, message: 'DNI Requerido'},
                      { min: 8, max: 8, message: 'El DNI debe tener 8 dígitos' }
                  ]}>
                    <Input defaultValue={encargado.DocumentoIdentidad} 
                      onChange={e=> {
                        setEncargado({...encargado, DocumentoIdentidad: e.currentTarget.value.replace(/\D/g, '')})
                        form.setFieldsValue({DocumentoIdentidad: e.currentTarget.value.replace(/\D/g, '')})
                      }}
                    />
                  </Item>
                </div>
              </div>

              <div className='elem-list'>
                <div className='col-sm-11 col-md-11 col-lg-11'>
                  <Item label="Áreas" name="areas" rules={[
                      {...rules.required, message: 'Áreas Requeridas'}
                  ]}>
                    <Select
                      showSearch
                      defaultValue={detalleAreas}
                      className='mb-lg-0'
                      placeholder='Seleccionar Áreas'
                      optionFilterProp='children'
                      mode='multiple'
                      filterOption={optionFilter}
                      onChange={e=>setDetalleAreas(e)}
                      // style={heigh}
                    >
                      {props.areasList.map(area =>{
                        return(
                          <Option value={area.id_area} key={area.id_area}>{area.nombre} - {area.nombre_sucursal}</Option>
                        )
                      })}
                    </Select>
                  </Item>  
                </div> 
              </div>

              <div className='modal-footer d-flex justify-content-between'>
                <Button danger onClick={handleCloseModal} icon={<CloseOutlined />}>
                  Cerrar
                </Button>
                
                <Button className='bg-color-success' loading={loadingButton}
                  type='primary' icon={<SaveOutlined />} htmlType="submit">
                  Guardar
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModalFormEncargado