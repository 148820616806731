import { FileExcelOutlined } from "@ant-design/icons";
import { Button } from "antd";
import ReactExport from 'react-export-excel-xlsx-fix';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ContenidoExcelItemsConsolidado = (detalle) => {
  return[
    {
      columns: [
        {
          value: "EQUIPO/MATERIAL",
          widthPx: 250
        },
        {
          value: "DESCRIPCIÓN",
          widthPx: 100
        },
        {
          value: "MARCA",
          widthPx: 100
        },
        {
          value: "PRESENTACIÓN",
          widthPx: 100
        },
        {
          value: "CANTIDAD"
        },
        {
          value: "T. UNID."
        }
      ],
      data: detalle.map(det => {
        return[
          {value: `${det.nombre_material === null?'':det.nombre_material}`},
          {value: `${det.descripcion === null?'':det.descripcion}`},
          {value: `${det.marca === null?'':det.marca}`},
          {value: `${det.nombre_unidad} X ${det.cantidad_unidades}`},
          {value: `${det.cantidad}`},
          {value: `${det.cantidad_unidades * det.cantidad}`},
        ]
      })
    }
  ]
}

export const ExcelFileItemsConsolidado = (detalle) => {
  return(
    <>
      <ExcelFile element={
        <Button className='bg-color-success' title="Exportar Excel" 
        icon={<FileExcelOutlined />}>EXCEL
        </Button>} filename="Items">
        <ExcelSheet dataSet={ContenidoExcelItemsConsolidado(detalle)} 
          name="Items"/>
      </ExcelFile>
    </>
  )
}