import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Input, Form, Modal, message } from 'antd';
import { Rule } from 'antd/lib/form';
import {useState, useEffect} from "react";

const { Item } = Form;

const rules = {
  required: { required: true, message: <></> } as Rule
};

const ModalConductor = (props) => {
  const [loadingButton, setLoadingButton] = useState(false)
  const [conductor, setConductor] = useState({
    id_conductor: null,
    nombres: null,
    apellidos: null,
    DocumentoIdentidad: null
  })

  useEffect(() => {
    if (props.openModal === true) {
      setConductor(props.conductor)
    } else {
      setConductor({
        id_conductor: null,
        nombres: null,
        apellidos: null,
        DocumentoIdentidad: null
      })
    }
  }, [props.conductor]);

  const handleCloseModal = () => {
    props.setOpenModal(false)
    setConductor({
      id_conductor: null,
      nombres: null,
      apellidos: null,
      DocumentoIdentidad: null
    })
  }

  const AddConductor = async () => {
    setLoadingButton(true)
    const newConductor = {...conductor, usuario: props.usuario}
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/AddConductor`,{
        method: "POST",
        body: JSON.stringify(newConductor),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data => {
        message.success(data.message)
        setLoadingButton(false)
        props.GetConductores()
        handleCloseModal()
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error)
    }  
  }

  const EditConductor = async () => {
    setLoadingButton(true)
    // console.log(conductor)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/UpdateConductor`,{
        method: "PUT",
        body: JSON.stringify(conductor),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data => {
        message.success(data.message)
        setLoadingButton(false)
        props.GetConductores()
        handleCloseModal()
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error)
    }
  }

  return(
    <>
      <Modal
        visible={props.openModal}
        closable={false}
        width='80%'
        title={<h3 className='m-0'>{conductor.id_conductor === null?'Nuevo Conductor':'Editar Conductor'}</h3>}
        onCancel={handleCloseModal}
        footer={null}
      >
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <Form form={props.form} layout='vertical' onFinish={ conductor.id_conductor === null? AddConductor : EditConductor}>
              <div className='elem-list mb-4'>
                <div className='col-sm-11 col-md-4 col-lg-4'>
                  <Item label="Nombres" name="nombres" rules ={[
                    {...rules.required, message: 'Los Nombres son Requeridos'}
                  ]}>
                    <Input defaultValue={conductor.nombres} 
                      onChange={e=>setConductor({
                        ...conductor, nombres:e.currentTarget.value
                      })}
                        placeholder = "Nombres"
                    />
                  </Item>
                </div>

                <div className='col-sm-11 col-md-4 col-lg-4'>
                  <Item label="Apellidos" name="apellidos" rules ={[
                    {...rules.required, message: 'Los Apellidos son Requeridos'}
                  ]}>
                    <Input defaultValue={conductor.apellidos} 
                      onChange={e=>setConductor({
                        ...conductor, apellidos:e.currentTarget.value
                      })}
                        placeholder = "Apellidos"
                    />
                  </Item>
                </div>

                <div className='col-sm-11 col-md-3 col-lg-3'>
                  <Item label="DNI" name="DocumentoIdentidad" rules ={[
                    {...rules.required, message: 'El DNI es Requerido'}
                  ]}>
                    <Input defaultValue={conductor.DocumentoIdentidad} 
                      onChange={e=>setConductor({
                        ...conductor, DocumentoIdentidad:e.currentTarget.value
                      })}
                        placeholder = "DNI"
                    />
                  </Item>
                </div>
              </div>

              <div className='modal-footer d-flex justify-content-between'>
                <Button danger onClick={handleCloseModal} icon={<CloseOutlined />}>
                  Cerrar
                </Button>
                
                <Button className='bg-color-success' loading={loadingButton}
                  type='primary' icon={<SaveOutlined />} htmlType="submit">
                  Guardar
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  )

}

export default ModalConductor