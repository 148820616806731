import {useEffect, useState} from "react";
import { IPageData } from "../../../interfaces/page";
import { usePageData } from '../../../hooks/usePage';
import Cookies from "universal-cookie";
import { Card, Table, Button, Input, Form } from "antd";
import { CarOutlined, EditOutlined, FilePdfOutlined, PictureOutlined, PlusOutlined } from "@ant-design/icons";
import ModalVehiculo from "./ModalVehiculo";
import ModalCombustible from "./ModalCombustible";
import ModalConductor from "./ModalConductor";
import moment from "moment";
import ModalValeCombustible from "./ModalValeCombustible";
import { existeRol, PreviewFile } from "../FuncionesGenerales";
import ModalAsignarChofer from "./ModalAsignarChofer";
import GraficosControlCombustible from "./GraficosControlCombustible";
import { ExcelFileVales } from "../componentesExcel/GenerarExcelVales";

const cookies = new Cookies()

const pageData: IPageData = {
  fulFilled: true,
  title: 'Combustible',
  breadcrumbs: [
    {
      title: 'Combustible',
    }
  ]
};

const ControlCombustible = () => {
  usePageData(pageData);

  const [vehiculosList, setVehiculosList] = useState([])
  const [combustiblesList, setCombustiblesList] = useState([])
  const [conductoresList, setConductoresList] = useState([])
  const [valesList, setValesList] = useState([])
  const [inputNombre, setInputNombre] = useState('');
  const [inputNombreVale, setInputNombreVale] = useState('');
  const [inputNumeroVale, setInputNumeroVale] = useState('');
  const [inputNombreCombustible, setInputNombreCombustible] = useState('');
  const [inputNombreConductor, setInputNombreConductor] = useState('');
  const [inputDNI, setInputDNI] = useState('');
  const [inputApellidoConductor, setInputApellidoConductor] = useState('');
  const [inputPlaca, setInputPlaca] = useState('');
  const [inputConductor, setInputConductor] = useState('');
  const [inputVehiculo, setInputVehiculo] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openModalCombustible, setOpenModalCombustible] = useState(false);
  const [openModalConductor, setOpenModalConductor] = useState(false);
  const [openModalVale, setOpenModalVale] = useState(false);
  const [openModalChofer, setOpenModalChofer] = useState(false);
  const [vehiculoSeleccionado, setVehiculoSeleccionado] = useState({})
  const [combustibleSeleccionado, setCombustibleSeleccionado] = useState({})
  const [conductorSeleccionado, setConductorSeleccionado] = useState({})
  const [valeSeleccionado, setValeSeleccionado] = useState({})
  const [tituloModal, setTituloModal] = useState('')
  const [pestaña, setPestaña] = useState('vales');
  const [fechaInicio, setFechaInicio] = useState('')
  const [fechaFin, setFechaFin] = useState(moment(new Date()).format("YYYY-MM-DD"))
  const [rolLogistica, setRolLogistica] = useState(false);
  const [detalleChoferes, setDetalleChoferes] = useState([])
  const [user, setUser] = useState({DocumentoIdentidad:null})
  const [totalChoferes, setTotalChoferes] = useState([])

  const [form] = Form.useForm();
  const resetForm = () => form.resetFields();

  const [form2] = Form.useForm();
  const resetForm2 = () => form2.resetFields();

  const [form3] = Form.useForm();
  const resetForm3 = () => form3.resetFields();

  const [form4] = Form.useForm();
  const resetForm4 = () => form4.resetFields();

  useEffect(() => {
    if (!cookies.get('nameid_logis') || existeRol('Conductor.Acceso.Vales') === false) {
      // history.push(`/public/sign-in`)
      window.location.href='/'      
    } else {
      GetVehiculos()
      GetCombustibles()
      GetVales()
      GetConductores()
      getUser()
      GetChoferes()
    }

    setRolLogistica(existeRol('Conductor.Gestion.Vehiculos'))

  }, []);

  const onTabChange = (setter: (val: string) => void) => (key: string) => {
    setter(key);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      GetVehiculos()
      GetCombustibles()
      GetConductores()
      GetVales()

    }, 10000);
    return () => clearInterval(interval);
  }, [vehiculosList])

  const getUser = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getUser/${cookies.get('nameid_logis')}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data.Id)
        // console.log(cookies.get('nameid_logis'))
        if (data.message) {
          setUser({DocumentoIdentidad: null})
        } else {
          setUser(data)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const GetChoferes = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/GetChoferes`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          setTotalChoferes([])
        } else {
          setTotalChoferes(data)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const GetVehiculos = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/GetVehiculos`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          setVehiculosList([])
        } else {
          setVehiculosList(data)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const GetVales = async() => {
    const usuario = cookies.get('nameid_logis')
    const logistica = existeRol('Logistica.Gestion.Requerimiento')
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/${logistica === true?'GetVales':`GetValesByUser/${usuario}`}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          setValesList([])
        } else {
          setValesList(data)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const GetCombustibles = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/GetCombustibles`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          setCombustiblesList([])
        } else {
          setCombustiblesList(data)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const GetConductores = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/GetConductores`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          setConductoresList([])
        } else {
          setConductoresList(data)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const columnsVales = [
    {
      title: (<div style={{width:80}}></div>),
      render: record => {
        return(
          <>
            {record.usuario === cookies.get('nameid_logis') || rolLogistica === true? 
            <Button type='primary' className="bg-color-orange mr-2"
              title="Editar" icon={<EditOutlined />} 
              onClick={e=>handleOpenModalVale(record.id_vale)}
            />
            :null}
            
            {record.ubicacion_archivo === null?null:
            <Button type="primary" className="mr-2"
              title="Previsualizar Foto Kilometraje" icon={<PictureOutlined />} 
              onClick={e => PreviewFile(record)}
            />}

            {record.ubicacion_archivo_vale === null?null:
            <Button type="primary" icon={<PictureOutlined />}
              title="Previsualizar Foto Vale" 
              onClick={e => PreviewFile({...record, otraUbicacion: record.ubicacion_archivo_vale})}
            />}
          </>
        )
      }
    },
    {
      title: 'FECHA',
      render: record => {
        return(<><b>{moment.utc(record.fecha_vale).format('DD/MM/YY')}</b></>)
      }
    },
    {
      title: (
        <>
          <div style={{width:140}}>
            <Input value={inputVehiculo} 
              onChange={e=>setInputVehiculo(e.currentTarget.value)} placeholder = "VEHÍCULO"
            />
          </div>
        </>
      ),
      dataIndex: 'vehiculo',
      key: 'vehiculo'
    },
    {
      title: (
        <>
          <div style={{width:140}}>
            <Input value={inputConductor} 
              onChange={e=>setInputConductor(e.currentTarget.value)} placeholder = "CONDUCTOR"
            />
          </div>
        </>
      ),
      dataIndex: 'conductor',
      key: 'conductor'
    },
    {
      title: (
        <>
          <div style={{width:100}}>
            <Input value={inputNombreVale} 
              onChange={e=>setInputNombreVale(e.currentTarget.value)} placeholder = "COMBUSTIBLE"
            />
          </div>
        </>
      ),
      dataIndex: 'nombre',
      key: 'nombre'
    },
    {
      title: (
        <>
          <div style={{width:100}}>
            <Input value={inputNumeroVale} 
              onChange={e=>setInputNumeroVale(e.currentTarget.value)} placeholder = "NÚM. VALE"
            />
          </div>
        </>
      ),
      dataIndex: 'num_vale',
      key: 'num_vale'
    },
    {
      title: 'KILOMETRAJE',
      dataIndex: 'kilometraje',
      key: 'kilometraje'
    }
  ]

  const columns = [
    {
      title: (<div style={{width:80}}></div>),
      render: record => {
        return(
          <>
            <Button type='primary' className="bg-color-orange mr-2"
              title="Editar" icon={<EditOutlined />}
              onClick={e=>handleOpenModal('Editar Vehículo', record.id_vehiculo)}
            />

            <Button type='primary'
              title="Asignar Chofer(es)" icon={<CarOutlined />}
              onClick={e=>handleOpenModalChofer(record)}
            />
          </>
        )
      }
    },
    {
      title: (
        <>
          <div style={{width:140}}>
            <Input value={inputNombre} 
              onChange={e=>setInputNombre(e.currentTarget.value)} placeholder = "NOMBRE"
            />
          </div>
        </>
      ),
      dataIndex: 'nombre',
      key: 'nombre'
    },
    {
      title: (
        <>
          <div style={{width:140}}>
            <Input value={inputPlaca} 
              onChange={e=>setInputPlaca(e.currentTarget.value)} placeholder = "PLACA"
            />
          </div>
        </>
      ),
      dataIndex: 'placa',
      key: 'placa'
    }
  ]

  const columnsCombustible = [
    {
      title: (<div style={{width:80}}></div>),
      render: record => {
        return(
          <>
            <Button type='primary' className="bg-color-orange mr-2"
              title="Editar" 
              onClick={e=>handleOpenModalCombustible(record.id_combustible)}
            icon={<EditOutlined />} />
          </>
        )
      }
    },
    {
      title: (
        <>
          <div style={{width:140}}>
            <Input value={inputNombreCombustible} 
              onChange={e=>setInputNombreCombustible(e.currentTarget.value)} placeholder = "NOMBRE"
            />
          </div>
        </>
      ),
      dataIndex: 'nombre',
      key: 'nombre'
    }
  ]

  const columnsConductor = [
    {
      title: (<div style={{width:80}}></div>),
      render: record => {
        return(
          <>
            <Button type='primary' className="bg-color-orange mr-2"
              title="Editar" 
              onClick={e=>handleOpenModalConductor(record.id_conductor)}
            icon={<EditOutlined />} />
          </>
        )
      }
    },
    {
      title: (
        <>
          <div style={{width:140}}>
            <Input value={inputNombreConductor} 
              onChange={e=>setInputNombreConductor(e.currentTarget.value)} placeholder = "NOMBRES"
            />
          </div>
        </>
      ),
      dataIndex: 'nombres',
      key: 'nombres'
    },
    {
      title: (
        <>
          <div style={{width:140}}>
            <Input value={inputApellidoConductor} 
              onChange={e=>setInputApellidoConductor(e.currentTarget.value)} placeholder = "APELLIDOS"
            />
          </div>
        </>
      ),
      dataIndex: 'apellidos',
      key: 'apellidos'
    },
    {
      title: (
        <>
          <div style={{width:140}}>
            <Input value={inputDNI} 
              onChange={e=>setInputDNI(e.currentTarget.value)} placeholder = "DNI"
            />
          </div>
        </>
      ),
      dataIndex: 'DocumentoIdentidad',
      key: 'DocumentoIdentidad'
    }
  ]

  const vehiculosFiltrados = () => {
    const lista = vehiculosList.filter(vehiculo => {
      return ( vehiculo.nombre.toUpperCase().includes(inputNombre.toUpperCase()) && 
        vehiculo.placa.includes(inputPlaca.toUpperCase()) )
    })

    return lista
  }

  const combustiblesListFiltrados = () => {
    const lista = combustiblesList.filter(combustible => {
      return ( combustible.nombre.toUpperCase().includes(inputNombreCombustible.toUpperCase()) )
    })

    return lista
  }

  const conductoresListFiltrados = () => {
    const lista = conductoresList.filter(conductor => {
      return ( conductor.nombres.toUpperCase().includes(inputNombreConductor.toUpperCase()) &&
        conductor.apellidos.toUpperCase().includes(inputApellidoConductor.toUpperCase()) &&
        conductor.DocumentoIdentidad.includes(inputDNI) 
      )
    })

    return lista
  }

  const valesListFiltrados = () => {
    const lista = valesList.filter(vale => {
      return ( vale.vehiculo.toUpperCase().includes(inputVehiculo.toUpperCase()) &&
        vale.conductor.toUpperCase().includes(inputConductor.toUpperCase()) &&
        vale.nombre.toUpperCase().includes(inputNombreVale.toUpperCase()) &&
        vale.num_vale.toUpperCase().includes(inputNumeroVale.toUpperCase()) &&
        moment.utc(vale.fecha_vale).format("YYYY-MM-DD") >= fechaInicio && 
        moment.utc(vale.fecha_vale).format("YYYY-MM-DD") <= fechaFin )
    })

    return lista
  }

  const handleOpenModal = async(titulo, id_vehiculo) => {
    resetForm()
    setOpenModal(true)
    setTituloModal(titulo)

    if (id_vehiculo === null) {
      setVehiculoSeleccionado({
        id_vehiculo: null,
        nombre: null,
        placa: null
      })
    } else {
      setVehiculoSeleccionado({id_vehiculo: id_vehiculo})
    }
  }

  const handleOpenModalChofer = async(vehiculo) => {
    setOpenModalChofer(true)
    setVehiculoSeleccionado(vehiculo)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/GetChoferesByIdVehiculo/${vehiculo.id_vehiculo}`)
      .then(res => res.json())
      .then(data => {
        if (data.message) {
          setDetalleChoferes([])
        } else {
          let detalle = []
          for (let i = 0; i < data.length; i++) {
            detalle = [...detalle, data[i].id_conductor]
          }
          setDetalleChoferes([...detalle])
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleOpenModalCombustible = async(id_combustible) => {
    resetForm2()
    setOpenModalCombustible(true)
    if (id_combustible === null) {
      setCombustibleSeleccionado({
        id_combustible: null,
        nombre: null
      })
    } else {
      try {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/GetCombustibleById/${id_combustible}`)
        .then(res => res.json())
        .then(data => {
          // console.log(data)
          setCombustibleSeleccionado(data)
          form2.setFieldsValue({
            nombre: data.nombre
          })
        })
      } catch (error) {
        console.log(error)
      }
    }
  }

  const handleOpenModalConductor = async(id_conductor) => {
    resetForm3()
    setOpenModalConductor(true)
    if (id_conductor === null) {
      setConductorSeleccionado({
        id_conductor: null,
        nombres: null,
        apellidos: null,
        DocumentoIdentidad: null
      })
    } else {
      try {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/GetConductorById/${id_conductor}`)
        .then(res => res.json())
        .then(data => {
          // console.log(data)
          setConductorSeleccionado(data)
          form3.setFieldsValue({
            nombres: data.nombres,
            apellidos: data.apellidos,
            DocumentoIdentidad: data.DocumentoIdentidad
          })
        })
      } catch (error) {
        console.log(error)
      }
    }
  }

  const handleOpenModalVale = async(id_vale) => {
    resetForm4()
    setOpenModalVale(true)
    if (id_vale === null) {
      setValeSeleccionado({
        id_vale: null,
        fecha_vale: moment().format('YYYY-MM-DD'),
        num_vale: null,
        kilometraje: null,
        ubicacion_archivo: null,
        ubicacion_archivo_vale: null,
        id_vehiculo: null,
        id_conductor: null,
        id_combustible: null
      })
    } else {
      try {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/GetValeById/${id_vale}`)
        .then(res => res.json())
        .then(data => {
          // console.log(data)
          setValeSeleccionado(data)
          form4.setFieldsValue({
            fecha_vale: data.fecha_vale,
            num_vale: data.num_vale,
            kilometraje: data.kilometraje,
            ubicacion_archivo: data.ubicacion_archivo,
            ubicacion_archivo_vale: data.ubicacion_archivo_vale,
            id_vehiculo: data.id_vehiculo,
            id_conductor: data.id_conductor,
            id_combustible: data.id_combustible
          })
        })
      } catch (error) {
        console.log(error)
      }
    }
  }

  let pestañasList = [
    { key: 'vales', tab: 'Vales' }
  ]

  if (rolLogistica === true) {
    pestañasList = [...pestañasList,
      { key: 'vehiculos', tab: 'Vehículos' },
      { key: 'combustibles', tab: 'Combustibles' },
      { key: 'conductores', tab: 'Conductores' },
      { key: 'graficos', tab: 'Gráficos' }
    ]
  }

  const contenidoPestañas = {
    vales: 
      <>
        {ExcelFileVales(valesListFiltrados())}
        <Table
          columns={columnsVales}
          dataSource={valesListFiltrados()}
          size='small'
        />
      </>,
    vehiculos:
      <>
        <Table
          columns={columns}
          dataSource={vehiculosFiltrados()}
          size='small'
        />
      </>,
    combustibles:
      <>
        <Table
          columns={columnsCombustible}
          dataSource={combustiblesListFiltrados()}
          size='small'
        />
      </>,
    conductores:
      <>
        <Table
          columns={columnsConductor}
          dataSource={conductoresListFiltrados()}
          size='small'
        />
      </>,
    graficos:
      <>
        <GraficosControlCombustible
          vehiculosList = {vehiculosList}
          combustiblesList = {combustiblesList}
          conductoresList = {conductoresList}
          valesList = {valesList}
          fechaInicio = {fechaInicio}
          fechaFin = {fechaFin}
        />
      </>
  }

  return(
    <>
      <div className='row'>
        <div className='elem-list ml-2'>
          <Button type='primary'  
            title="Nuevo Vale" 
            onClick={e => handleOpenModalVale(null)}
            icon={<PlusOutlined />} >Vale
          </Button> 
          {rolLogistica === true? 
          <>
            <Button type='primary'  
              title="Nuevo Vehículo" 
              onClick={e => handleOpenModal('Nuevo Vehículo', null)}
              icon={<PlusOutlined />} >Vehículo
            </Button>  

            <Button type='primary'  
              title="Agregar Combustible" 
              onClick={e => handleOpenModalCombustible(null)}
              icon={<PlusOutlined />} >Combustible
            </Button>

            <Button type='primary'  
              title="Agregar Conductor" 
              onClick={e => handleOpenModalConductor(null)}
              icon={<PlusOutlined />} >Conductor
            </Button> 
          </>
          :null}
        </div>
      </div>

      <div className='elem-list mt-2'>
        <div className='col-md-3 col-lg-3'>
          <label>Desde: </label> <br/> 
          <Input type="date"
            value={fechaInicio}
            onChange={e => setFechaInicio(e.currentTarget.value)}
          />
        </div>

        <div className='col-md-3 col-lg-3'>
          <label>Hasta: </label> <br/>
          <Input type="date"
            value={fechaFin} 
            onChange={e => setFechaFin(e.currentTarget.value)}
          />  
        </div>
      </div>

      <Card
        tabList={pestañasList}
        activeTabKey={pestaña}
        onTabChange={onTabChange(setPestaña)}
      >
        {contenidoPestañas[pestaña]}

      </Card>

      {<ModalVehiculo
        openModal = {openModal}
        tituloModal = {tituloModal}
        vehiculo = {vehiculoSeleccionado}
        setVehiculo = {setVehiculoSeleccionado}
        form = {form}
        GetVehiculos = {GetVehiculos}
        setOpenModal = {setOpenModal}
        usuario = {cookies.get('nameid_logis')}
      />}

      {<ModalCombustible
        openModal = {openModalCombustible}
        combustible = {combustibleSeleccionado}
        setCombustible = {setCombustibleSeleccionado}
        form = {form2}
        GetCombustibles = {GetCombustibles}
        setOpenModal = {setOpenModalCombustible}
        usuario = {cookies.get('nameid_logis')}
      />}

      {<ModalConductor
        openModal = {openModalConductor}
        conductor = {conductorSeleccionado}
        setConductor = {setConductorSeleccionado}
        form = {form3}
        GetConductores = {GetConductores}
        setOpenModal = {setOpenModalConductor}
        usuario = {cookies.get('nameid_logis')}
      />}

      {<ModalValeCombustible
        vehiculosList = {vehiculosList}
        conductoresList = {conductoresList}
        combustiblesList = {combustiblesList}
        form = {form4}
        setVale = {setValeSeleccionado}
        vale = {valeSeleccionado}
        usuario = {cookies.get('nameid_logis')}
        setOpenModal = {setOpenModalVale}
        GetVales = {GetVales}
        openModal = {openModalVale}
        totalChoferes = {totalChoferes}
        rolLogistica = {rolLogistica}
        user = {user}
      />}

      {<ModalAsignarChofer
        vehiculo = {vehiculoSeleccionado}
        conductoresList = {conductoresList}
        openModal = {openModalChofer}
        setOpenModal = {setOpenModalChofer}
        detalleChoferes = {detalleChoferes}
      />}
    </>
  )
}

export default ControlCombustible