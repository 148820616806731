import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Input, Modal, message, Form, Select } from 'antd';
import { Rule } from 'antd/lib/form';
import { useState } from "react";
import { Option } from 'antd/lib/mentions';

const { Item } = Form;

const rules = {
  required: { required: true, message: <></> } as Rule
};

const ModalFormResponsable = (props) => {
  const [loadingButton, setLoadingButton] = useState(false)

  const optionFilter = (input, option) => {
    return (option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0)
  }

  const handleCloseModal = () => {
    props.setOpenModal(false)
    props.setCoordinador({})
  }

  const AddResponsable = async () => {
    setLoadingButton(true)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/AddResponsable`,{
        method: "POST",
        body: JSON.stringify(props.coordinador),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data =>{
        props.setCoordinador({})
        props.form.setFieldsValue({
          id_area: null,
          nombre: null,
          DocumentoIdentidad: null,
          tipo: null
        })
        props.getResponsables()
        message.success(data.message)
        setLoadingButton(false)
        handleCloseModal()
      }).catch(err => {
        console.log(err)
        setLoadingButton(false)
      });
    } catch (error) {
      setLoadingButton(false)
      console.log(error)
    }
  }

  const EditCoordinador = async () => {
    setLoadingButton(true)
    // console.log(props.coordinador)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/UpdateResponsable`,{
        method: "PUT",
        body: JSON.stringify(props.coordinador),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data =>{
        props.setCoordinador({})
        props.form.setFieldsValue({
          id_area: null,
          nombre: null,
          DocumentoIdentidad: null,
          tipo: null
        })
        props.getResponsables()
        message.success(data.message)
        setLoadingButton(false)
        handleCloseModal()
      }).catch(err => {
        console.log(err)
        setLoadingButton(false)
      })
    } catch (error) {
      setLoadingButton(false)
      console.log(error)
    }
  }

  return(
    <>
      <Modal
        visible={props.openModal}
        closable={false}
        width='80%'
        title={<h3 className='m-0'>{props.tituloModal}</h3>}
        onCancel={handleCloseModal}
        footer={null}
      >
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <Form form={props.form} layout='vertical' onFinish={ props.coordinador.id_responsable === null? AddResponsable : EditCoordinador}>
              <div className='elem-list'>
                <div className='col-sm-11 col-md-7 col-lg-7'>
                  <Item label="Coordinador" name="nombre" rules ={[
                    {...rules.required, message: 'El Coordinador es Requerido'}
                  ]}>
                    <Input defaultValue={props.coordinador.nombre} 
                      onChange={e=>props.setCoordinador({
                        ...props.coordinador, nombre:e.currentTarget.value
                      })}
                       placeholder = "Nombre"
                    />
                  </Item>
                </div>

                <div className='col-sm-11 col-md-3 col-lg-3'>
                  <Item label="DNI" name="DocumentoIdentidad" rules ={[
                    {...rules.required, message: 'El DNI es Requerido'},
                    { min: 8, max: 8, message: 'El DNI debe tener 8 dígitos' }
                  ]}>
                    <Input defaultValue={props.coordinador.DocumentoIdentidad} 
                      // onChange={e=>props.setCoordinador({
                      //   ...props.coordinador, DocumentoIdentidad:e.currentTarget.value
                      // })}
                      onChange={e=> {
                        props.setCoordinador({...props.coordinador, DocumentoIdentidad: e.currentTarget.value.replace(/\D/g, '')})
                        props.form.setFieldsValue({DocumentoIdentidad: e.currentTarget.value.replace(/\D/g, '')})
                      }}
                       placeholder = "DNI"
                    />
                  </Item>
                </div>
              </div>

              <div className='elem-list'>
                <div className='col-sm-11 col-md-4 col-lg-4'>
                  <Item label="ÁREA" name="id_area" rules ={[
                    {...rules.required, message: 'El Área es Requerida'}
                  ]}>
                    <Select
                      showSearch
                      allowClear
                      defaultValue={props.coordinador.id_area}
                      className='mr-2'
                      placeholder='ÁREA'
                      optionFilterProp='children'
                      filterOption={optionFilter}
                      onChange={e => props.setCoordinador({
                        ...props.coordinador, id_area:e
                      })}
                    >
                      {props.areasList.map(area =>{
                        return(
                          <Option value={area.id_area} key={area.id_area}>{area.nombre}</Option>
                        )
                      })}
                    </Select>  
                  </Item>
                </div>

                <div className='col-sm-11 col-md-4 col-lg-4'>
                  <Item label="Tipo" name="tipo" rules ={[
                    {...rules.required, message: 'Seleccionar Tipo'}
                  ]}>
                    <Select
                      showSearch
                      allowClear
                      defaultValue={props.coordinador.tipo}
                      className='mr-2'
                      placeholder='TIPO'
                      optionFilterProp='children'
                      filterOption={optionFilter}
                      onChange={e => props.setCoordinador({
                        ...props.coordinador, tipo:e
                      })}
                    >
                      <Option value={'COORDINADOR'} key={'COORDINADOR'}>COORDINADOR</Option>
                      <Option value={'ASISTENTE'} key={'ASISTENTE'}>ASISTENTE</Option>
                    </Select>  
                  </Item>
                </div>
              </div>

              <div className='modal-footer d-flex justify-content-between mt-4'>
                <Button danger onClick={handleCloseModal} icon={<CloseOutlined />}>
                  Cerrar
                </Button>
                
                <Button className='bg-color-success' loading={loadingButton}
                  type='primary' icon={<SaveOutlined />} htmlType="submit">
                  Guardar
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModalFormResponsable