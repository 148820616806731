import {useEffect, useState} from "react";
import { IPageData } from "../../../interfaces/page";
import { usePageData } from '../../../hooks/usePage';
import Cookies from "universal-cookie";
import { Card, Table, Button, Select, Input, Form, Popconfirm, message } from 'antd';
import { Option } from "antd/lib/mentions";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { existeRol } from "../FuncionesGenerales";
import ModalFormResponsable from "./ModalFormResponsable";

const cookies = new Cookies()

const pageData: IPageData = {
  fulFilled: true,
  title: 'Coordinadores',
  breadcrumbs: [
    {
      title: 'Coordinadores',
    }
  ]
};

const Responsables = () => {
  usePageData(pageData);
  const [responsablesAreasList, setResponsablesAreasList] = useState([])
  const [areasList, setAreasList] = useState([])
  const [selectArea, setSelectArea] = useState(undefined)
  const [selectArea2, setSelectArea2] = useState(undefined)
  const [openModal, setOpenModal] = useState(false);
  const [inputNombre, setInputNombre] = useState('');
  const [inputNombre2, setInputNombre2] = useState('');
  const [coordinador, setCoordinador] = useState({})
  const [tituloModal, setTituloModal] = useState('')
  const [pestaña, setPestaña] = useState('coordinadores');

  const [form] = Form.useForm();

  const optionFilter = (input, option) =>
    option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const onTabChange = (setter: (val: string) => void) => (key: string) => {
    setter(key);
  };

  useEffect(() => {
    if (!cookies.get('nameid_logis') || existeRol('Logistica.Gestion.Requerimiento') === false) {
      // history.push(`/public/sign-in`)
      window.location.href='/'    
    } else {
      getResponsables()
      getAreas()
    }
  }, []);

  const getResponsables = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getResponsables`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setResponsablesAreasList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getAreas = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getAreas/${1}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setAreasList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleOpenModal = async(titulo, id_responsable) => {
    setOpenModal(true)
    setTituloModal(titulo)
    if (id_responsable === null) {
      setCoordinador({
        id_responsable: null,
        id_area: null,
        nombre: null,
        DocumentoIdentidad: null
      })  

      form.setFieldsValue({
        id_area: null,
        nombre: null,
        DocumentoIdentidad: null,
        tipo: null
      })

    } else {
      // console.log(id_responsable)
      try {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/getResponsableById/${id_responsable}`)
        .then(res => res.json())
        .then(data => {
          setCoordinador(data)
          form.setFieldsValue({
            id_area: data.id_area,
            nombre: data.nombre,
            DocumentoIdentidad: data.DocumentoIdentidad,
            tipo: data.tipo
          })
        })
      } catch (error) {
        console.log(error)
      }
    }
    
  }

  const DarDeBajaResponsable = async (coordinador) => {
    // console.log(props.coordinador)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/DarDeBajaResponsable`,{
        method: "PUT",
        body: JSON.stringify(coordinador),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data =>{
        message.success(data.message)
        getResponsables()
      }).catch(err => {
        console.log(err)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const columns = [
    {
      title: '',
      render: record => {
        return(
          <>
            <Button type='primary' className="bg-color-orange mr-2"
              title="Editar" 
              onClick={e=>handleOpenModal('Editar Coordinador/Asistente', record.id_responsable)}
            icon={<EditOutlined />} />

            <Popconfirm
              title={`Dar de Baja a Este Coordinador/Asistente`}
              onConfirm={e=>DarDeBajaResponsable(record)} 
              okText="Si" cancelText="No"
              >
              <Button danger className='mr-2'
                title="Dar de Baja"
                icon={<DeleteOutlined />}
              /> 
            </Popconfirm>
          </>
        )
      }
    },
    {
      title: (
        <>
          <div style={{width:200}}>
            <Input value={inputNombre} 
              onChange={e=>setInputNombre(e.currentTarget.value)}
                placeholder = "COORDINADOR"
            />
          </div>
        </>
      ),
      dataIndex: 'nombre',
      key: 'nombre'
    },
    {
      title: 'DNI',
      dataIndex: 'DocumentoIdentidad',
      key: 'DocumentoIdentidad'
    },
    {
      title: (
        <>
          <div style={{width:200}} className="mr-4">
            <Select
              showSearch
              allowClear
              value={selectArea}
              className='mr-2'
              placeholder='ÁREA'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={e => setSelectArea(e)}
            >
              {areasList.map(area =>{
                return(
                  <Option value={area.id_area} key={area.id_area}>{area.nombre}</Option>
                )
              })}
            </Select>  
          </div>
        </>
      ),
      dataIndex: 'nombre_area',
      key: 'nombre_area'
    }
  ]

  const columns2 = [
    {
      title: '',
      render: record => {
        return(
          <>
            <Button type='primary' className="bg-color-orange mr-2"
              title="Editar" 
              onClick={e=>handleOpenModal('Editar Coordinador/Asistente', record.id_responsable)}
              icon={<EditOutlined />} 
            />

            <Popconfirm
              title={`Dar de Baja a Este Coordinador/Asistente`}
              onConfirm={e=>DarDeBajaResponsable(record)} 
              okText="Si" cancelText="No"
              >
              <Button danger className='mr-2'
                title="Dar de Baja"
                icon={<DeleteOutlined />}
              /> 
            </Popconfirm>
          </>
        )
      }
    },
    {
      title: (
        <>
          <div style={{width:200}}>
            <Input value={inputNombre2} 
              onChange={e=>setInputNombre2(e.currentTarget.value)}
                placeholder = "ASISTENTE"
            />
          </div>
        </>
      ),
      dataIndex: 'nombre',
      key: 'nombre'
    },
    {
      title: 'DNI',
      dataIndex: 'DocumentoIdentidad',
      key: 'DocumentoIdentidad'
    },
    {
      title: (
        <>
          <div style={{width:200}} className="mr-4">
            <Select
              showSearch
              allowClear
              value={selectArea2}
              className='mr-2'
              placeholder='ÁREA'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={e => setSelectArea2(e)}
            >
              {areasList.map(area =>{
                return(
                  <Option value={area.id_area} key={area.id_area}>{area.nombre}</Option>
                )
              })}
            </Select>  
          </div>
        </>
      ),
      dataIndex: 'nombre_area',
      key: 'nombre_area'
    }
  ]

  const responsablesFiltrados = () => {
    const lista = responsablesAreasList.filter(resp => {
      return( resp.nombre.toUpperCase().includes(inputNombre.toUpperCase()) && 
      (selectArea === undefined?
        resp.id_area !== selectArea: resp.id_area === selectArea)  &&
      resp.tipo === 'COORDINADOR')
    })

    return(lista)
  }

  const asistentesFiltrados = () => {
    const lista = responsablesAreasList.filter(resp => {
      return( resp.nombre.toUpperCase().includes(inputNombre2.toUpperCase()) && 
      (selectArea2 === undefined?
        resp.id_area !== selectArea2: resp.id_area === selectArea2)  &&
      resp.tipo === 'ASISTENTE')
    })

    return(lista)
  }

  const contenidoPestañas = {
    coordinadores:
      <>
        <Table
          columns={columns}
          dataSource={responsablesFiltrados()}
          size='small'
        />
      </>,

    asistentes:
      <>
        <Table
          columns={columns2}
          dataSource={asistentesFiltrados()}
          size='small'
        />
      </>
  }

  const pestañasList = [
    { key: 'coordinadores', tab: 'Coordinadores' },
    { key: 'asistentes', tab: 'Asistentes' }
  ]

  if (responsablesAreasList.length > 0) {
    return(
      <>
        <div className='row'>
          <div className='elem-list ml-2'>
            <Button type='primary'  
              title="Nuevo Coordinador/Asistente" 
              onClick={e=>handleOpenModal('Nuevo Coordinador/Asistente', null)}
              icon={<PlusOutlined />} >Agregar
            </Button>  
          </div>
        </div>

        <Card
          tabList={pestañasList}
          activeTabKey={pestaña}
          onTabChange={onTabChange(setPestaña)}
        >
          {contenidoPestañas[pestaña]}

          {<ModalFormResponsable
            areasList = {areasList}
            coordinador = {coordinador}
            setCoordinador = {setCoordinador}
            setOpenModal = {setOpenModal}
            openModal = {openModal}
            tituloModal = {tituloModal}
            form = {form}
            getResponsables = {getResponsables}
          />}
        </Card>
      </>
    )
  } else {
    return(
      <>
        <div className='row'>
          <div className='elem-list ml-2'>
            <Button type='primary'  
              title="Nuevo Coordinador" 
              onClick={e=>handleOpenModal('Nuevo Coordinador', null)}
              icon={<PlusOutlined />} >Agregar
            </Button>  
          </div>

          <Card>
            <div>No hay Datos</div>

            {<ModalFormResponsable
              areasList = {areasList}
              coordinador = {coordinador}
              setCoordinador = {setCoordinador}
              setOpenModal = {setOpenModal}
              openModal = {openModal}
              tituloModal = {tituloModal}
              form = {form}
              getResponsables = {getResponsables}
            />}
          </Card>
        </div>
      </>
    )
  }
}

export default Responsables