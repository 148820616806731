import {useEffect, useRef, useState} from "react";
import { IPageData } from "../../../interfaces/page";
import { usePageData } from '../../../hooks/usePage';
import Cookies from "universal-cookie";
import moment from "moment";
import { Card, Table, Button, Tag, Select, Input, Form, Popconfirm, message } from 'antd';
import { Option } from "antd/lib/mentions";
import { EyeOutlined, PlusOutlined, FilePdfOutlined, EditOutlined, 
  AliyunOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import ModalRequerimiento from "./ModalRequerimiento";
import ModalDetalleRequerimiento from "./ModalDetalleRequerimiento";
import { PdfFileRequerimiento } from "../componentesPDF/GenerarPDFRequerimiento";
import { ExcelFileRequerimiento } from "../componentesExcel/GenerarExcelRequerimiento";
// import axios from "axios";
import { existeRol } from "../FuncionesGenerales";
import ModalConsolidadoForm from "../componentesConsolidados/ModalConsolidadoForm";
import Consolidados from "../componentesConsolidados/Consolidados";
import ModalOrdenesCompraDespachadas from "../componentesOrdenesCompras/ModalOrdenesCompraDespachadas";

const cookies = new Cookies()

const pageData: IPageData = {
  fulFilled: true,
  title: 'Requerimientos',
  breadcrumbs: [
    {
      title: 'Requerimientos',
    }
  ]
};

const Requerimientos = () => {
  usePageData(pageData);
  const componentePDF = useRef(null)
  const [pestaña, setPestaña] = useState('requerimientos');
  const [requerimientosList, setRequerimientosList] = useState([])
  const [detalleReqList, setDetalleReqList] = useState([])
  const [detReqServicio, setDetReqServicio] = useState([
    {
      id_requerimiento: null,
      id_unidad: 2,
      cantidad: 1,
      id_articulo: 387,
      descripcion: null
    }
  ]);
  const [areasList, setAreasList] = useState([])
  const [responsablesAreasList, setResponsablesAreasList] = useState([])
  const [respList, setRespList] = useState([])
  const [responsablesSubList, setResponsablesSubList] = useState([])
  const [fechaInicio, setFechaInicio] = useState(''/*moment(new Date()).format("YYYY-MM").concat('-01')*/)
  const [fechaFin, setFechaFin] = useState(moment(new Date()).format("YYYY-MM-DD"))

  const [selectEstado, setSelectEstado] = useState(undefined)
  const [selectArea, setSelectArea] = useState(undefined)
  const [selectPrioridad, setSelectPrioridad] = useState(undefined)
  const [selectTipo, setSelectTipo] = useState(undefined)
  const [selectAprobado, setSelectAprobado] = useState(undefined)
  const [selectRubro, setSelectRubro] = useState(undefined)
  const [inputCodigo, setInputCodigo] = useState('');
  
  const [openModal, setOpenModal] = useState(false);
  const [openModalComprasDespachadas, setOpenModalComprasDespachadas] = useState(false);
  const [openModalDetalle, setOpenModalDetalle] = useState(false);
  const [tituloModal, setTituloModal] = useState('')
  const [loadingButton, setLoadingButton] = useState(false)
  const [loadingButtonAprobar, setLoadingButtonAprobar] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)
  const [requerimientoSeleccionado, setRequerimientoSeleccionado] = useState({
    id_requerimiento: null,
    fecha: moment.utc(new Date()).format('YYYY-MM-DD'),
    id_responsable: null,
    nombre_responsable: null,
    prioridad: null,
    tipo: null,
    fecha_entrega: null,
    tiempo_atencion: null,
    comentario: null,
    nombre_area: null,
    ubicacion_archivo: null,
    id_rubro: null,
    codigo: null
  })
  const [nuevaCotizacion, setNuevaCotizacion] = useState({})

  const [id_area, setId_area] = useState(null)
  const [materialesList, setMaterialesList] = useState([])
  const [rubrosList, setRubrosList] = useState([])
  const [unidadesList, setUnidadesList] = useState([])
  const [proveedoresList, setProveedoresList] = useState([])

  const [rolResponsableArea, setRolResponsableArea] = useState(false);
  const [rolLogistica, setRolLogistica] = useState(false);
  const [rolFinanzas, setRolFinanzas] = useState(false);
  const [rolGerencia, setRolGerencia] = useState(false);

  const [idConsolidado, setIdConsolidado] = useState(null)
  const [openModalConsolidado, setOpenModalConsolidado] = useState(false);
  const [consolidadosList, setConsolidadosList] = useState([])
  const [user, setUser] = useState({DocumentoIdentidad:null})

  const [form] = Form.useForm();

  const resetForm = () => form.resetFields();

  const optionFilter = (input, option) =>
    option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const onTabChange = (setter: (val: string) => void) => (key: string) => {
    setter(key);
  };

  useEffect(() => {
    if (!cookies.get('nameid_logis') || existeRol('Logistica.Responsable.Area') === false) {
      window.location.href='/'    
    } else {
      getRequerimientos()
      getAreas()
      getResponsables()
      getMaterialesNombres()
      getRubros()
      getUnidades()
      getProveedores()
      getConsolidados()
    }

    setRolResponsableArea(existeRol('Logistica.Responsable.Area'))
    setRolLogistica(existeRol('Logistica.Gestion.Requerimiento'))
    setRolFinanzas(existeRol('Finanzas.Aprobar.Requerimiento'))
    setRolGerencia(existeRol('Gerencia.Aprobar.Cotizacion'))
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getRequerimientos()
      getConsolidados()
    }, 10000);
    return () => clearInterval(interval);
  }, [requerimientosList])

  useEffect(() => {
    getUser()
  }, [responsablesAreasList])

  const getProveedores = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getProveedores`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setProveedoresList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getRequerimientos = async() => {
    setLoadingTable(true)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getRequerimientos/${1}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          setRequerimientosList([])
        } else {
          setRequerimientosList(data)
        }
        setLoadingTable(false)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getUser = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getUser/${cookies.get('nameid_logis')}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data, data.DocumentoIdentidad)
        // console.log(responsablesAreasList)
        if (data.message) {
          setUser({DocumentoIdentidad: null})
        } else {
          setUser(data)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getConsolidados = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getConsolidados/${1}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          setConsolidadosList([])
        } else {
          setConsolidadosList(data)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getAreas = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getAreas/${1}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setAreasList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getResponsables = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getResponsables`)
      .then(res => res.json())
      .then(data => {
        let listaCoor = []
        for (let i = 0; i < data.length; i++) {
          if (data[i].tipo === 'COORDINADOR') {
            listaCoor = [...listaCoor, data[i]]
          }
        }
        setResponsablesAreasList(listaCoor)
        setRespList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getMaterialesNombres = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getArticulos`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setMaterialesList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getRubros = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getRubros`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setRubrosList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getUnidades = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getUnidades`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setUnidadesList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const filtrarAreas = () => {
    const listaAreasByUser = respList.filter(res => {
      return( res.DocumentoIdentidad === user.DocumentoIdentidad && res.activo === 'SI' )
    })

    let listaFinalAreas = []
    for (let i = 0; i < areasList.length; i++) {
      const element = listaAreasByUser.filter(lis =>{
        return(areasList[i].id_area === lis.id_area)
      });
      if (element.length > 0) {
        listaFinalAreas = [...listaFinalAreas, areasList[i]]
      }
    }

    return (listaFinalAreas)
  }

  const handleModalOrdenesCompras = (item) => {
    setRequerimientoSeleccionado(item)
    setOpenModalComprasDespachadas(true)
  }

  const ConformidadRequerimiento = async(req, conformidad_req) => {
    // setLoadingButton(true)
    // console.log(req)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/ConformidadRequerimiento`,{
        method: "PUT",
        body: JSON.stringify({...req, 
          conformidad_req:conformidad_req, 
          usuario:cookies.get('nameid_logis'),
          fecha_movimiento: moment.utc(new Date()).format('YYYY/MM/DD')
        }),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data => {
        message.success(data.message)
        getRequerimientos()
      }).catch(err => {
        console.log(err)
        // setLoadingButton(false)
      });
    } catch (error) {
      console.log(error)
    }
  }

  const columns: any  = [
    {
      title: (<div style={{width:80}}></div>),
      render: record => {
        return(
          <>
            <Button type='primary' className="mr-2"
              title="Ver Datos del Requerimiento" 
              onClick={e => handleOpenModalDetalle(record)}
              icon={<EyeOutlined />} />

            {rolResponsableArea === true && (record.usuario === cookies.get('nameid_logis') || rolLogistica === true) && 
              record.estado !== 'FINALIZADO' && record.estado !== 'ESPERA DE CONFORMIDAD'?
              <Button type='primary' className="bg-color-orange mr-2"
                title="Editar Requerimiento" 
                onClick={e=>handleOpenModal('Editar Requerimiento', record.id_requerimiento)}
                icon={<EditOutlined />}/>   
            : null}
          </>
        )
      }
    },
    {
      title: (<div style={{width:80}}></div>),
      render: record => {
        return(
          <>
            {(record.usuario === cookies.get('nameid_logis') || rolLogistica === true) && 
            (record.estado === 'ESPERA DE CONFORMIDAD' || record.estado === 'FINALIZADO') && record.tipo !== 'ATENCION'?
            <Button className='bg-color-success' 
              title="Ver Datos de Compra" 
              onClick={e => handleModalOrdenesCompras(record)}
              icon={<EyeOutlined />} >
            </Button>
            :null}

            {(record.usuario === cookies.get('nameid_logis')) && record.tipo === 'ATENCION'?
            <>
              {record.conformidad_req === null?
              <>
              <Popconfirm
                title={`¿Fue conforme la Entrega?`+`\n`+
                `(Sólo se sumará la cantidad solicitada en el Requerimiento)`}
                onConfirm={e=>ConformidadRequerimiento(record, "SI")} 
                okText="Si" cancelText="No"
                >
                <Button type='primary' className='bg-color-success mr-2'
                  loading = {loadingButton} title = 'Conforme'
                  icon={<CheckOutlined />} 
                /> 
              </Popconfirm>

              <Popconfirm
                title={`¿Rechaza esta Entrega de Unidades al Área `+`\n`+
                  `(Este paso es irreversible)`}
                onConfirm={e=>ConformidadRequerimiento(record, "NO")} 
                okText="Si" cancelText="No"
                >
                <Button danger
                  loading = {loadingButton} title = 'No Conforme'
                  icon={<CloseOutlined />} 
                /> 
              </Popconfirm>
              </>: <b>{record.conformidad_req === 'SI'?null:record.conformidad_req} CONFORME</b>}
            </>
            : rolLogistica === true && record.tipo === 'ATENCION'?
              <>
              {record.conformidad_req === null?
              <b>PENDIENTE</b>
              :<b>{record.conformidad_req === 'SI'?null:record.conformidad_req} CONFORME</b>}
              </>
            :null}
          </>
        )
      }
    },
    {
      title: 'FECHA',
      dataIndex: 'fecha_creacion',
      // key: 'fecha_creacion',
      defaultSortOrder: 'descend',
      sorter: (a, b) => moment(a.fecha_creacion).unix() - moment(b.fecha_creacion).unix(),
      render: text => (
        moment.utc(text).format('DD/MM/YY')
      )
    },
    {
      title: (
        <>
          <div style={{width:120}}>
            <Input defaultValue={inputCodigo} 
              onChange={e=>setInputCodigo(e.currentTarget.value)}
                placeholder = "CÓDIGO"
            />
          </div>
        </>
      ),
      dataIndex: 'codigo',
      key: 'codigo'
    },
    {
      title: (
        <>
          <div style={{width:160}} className="mr-4">
            <Select
              showSearch
              allowClear
              defaultValue={selectRubro}
              className='mr-2'
              placeholder='RUBRO'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={e => setSelectRubro(e)}
            >
              {rubrosList.map(rubro =>{
                return(
                  <Option value={rubro.id_rubro} key={rubro.id_rubro}>{rubro.nombre}</Option>
                )
              })}
            </Select>  
          </div>
        </>
      ),
      dataIndex: 'nombre_rubro',
      key: 'nombre_rubro',
    },
    {
      title: (
        <>
          <div style={{width:160}} className="mr-4">
            <Select
              showSearch
              allowClear
              defaultValue={selectArea}
              className='mr-2'
              placeholder='ÁREA'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={e => setSelectArea(e)}
            >
              {rolLogistica === true || rolFinanzas === true? areasList.map(area =>{
                return(
                  <Option value={area.id_area} key={area.id_area}>{area.nombre}</Option>
                )
              })
              :filtrarAreas().map(area =>{
                return(
                  <Option value={area.id_area} key={area.id_area}>{area.nombre}</Option>
                )
              })
              }
            </Select>  
          </div>
        </>
      ),
      dataIndex: 'nombre_area',
      key: 'nombre_area'
    },
    {
      title: 'RESPONSABLE',
      dataIndex: 'nombre_responsable',
      key: 'nombre_responsable'
    },
    {
      title: (
        <>
          <div style={{width:125}}>
            <Select
              allowClear
              defaultValue={selectPrioridad}
              placeholder='PRIORIDAD'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={e => setSelectPrioridad(e)}
            >
              <option value={null}>PENDIENTE</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
            </Select>  
          </div>
        </>
      ),
      dataIndex: 'prioridad',
      key: 'prioridad',
      // sorter: (a, b) => a.prioridad - b.prioridad,
      render : text => {
        switch (text) {
          case 1:
            return(<Tag color={'#AA0404'}>1</Tag>)

          case 2:
            return(<Tag color={'#AA9104'}>2</Tag>)

          case 3:
            return(<Tag color={'#2D38A7'}>3</Tag>)

          case null:
            return(<Tag color={'#787878'}>PENDIENTE</Tag>)
          default:
            break;
        }
      }
    },
    {
      title: (
        <>
          <div style={{width:110}}>
            <Select
              allowClear
              defaultValue={selectTipo}
              placeholder='TIPO'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={e => setSelectTipo(e)}
            >
              <Option value='COMPRA' key='COMPRA'>COMPRA</Option>
              <Option value='SERVICIO' key='SERVICIO'>SERVICIO</Option>
              <Option value='ATENCION' key='ATENCION'>ATENCIÓN</Option>
            </Select>
          </div>
        </>
      ),
      dataIndex: 'tipo',
      key: 'tipo',
      render : text => (
        text === null? "PENDIENTE": text
      )
    },
    {
      title: (
        <>
          <div style={{width:230}}>
            <Select
              allowClear
              defaultValue={selectEstado}
              className=''
              placeholder='ESTADO'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={e => setSelectEstado(e)}
            >
              <Option value='ANALISIS'>ANÁLISIS</Option>
              <Option value='PROCESO'>PROCESO</Option>
              <Option value='ESPERA DE CONFORMIDAD'>ESPERA DE CONFORMIDAD</Option>
              <Option value='FINALIZADO'>FINALIZADO</Option>
              <Option value='RECHAZADO'>RECHAZADO</Option>
            </Select>  
          </div>
        </>
      ),
      dataIndex: 'estado',
      key: 'estado',
      render : text => {
        switch (text) {
          case 'ANALISIS':
            return(<Tag color={'#336cfb'}>ANÁLISIS</Tag>)

          case 'PROCESO':
            return(<Tag color={'#FC5F1B'}>PROCESO</Tag>)

          case 'ESPERA DE CONFORMIDAD':
            return(<Tag color={'#FC5F1B'}>ESPERA DE CONFORMIDAD</Tag>)

          case 'FINALIZADO':
            return(<Tag color={'#b7ce63'}>FINALIZADO</Tag>)
           
          case 'RECHAZADO':
              return(<Tag color={'#AA0404'}>RECHAZADO</Tag>)

          default:
            break;
        }
      }
    },
    {
      title: 'FECHA ENTREGA',
      render: (record) => {
        if (record.fecha_entrega === null) {
          return(<p>PENDIENTE</p>)
        } else {
          if (moment.utc(record.fecha_entrega) < moment.utc(new Date())
            && record.estado !== 'FINALIZADO' && record.estado !== 'RECHAZADO') {
            return(<p style={{color:'#EE0000'}}>{moment.utc(record.fecha_entrega).format('DD/MM/YY')}</p>)
          } else {
            return(<p>{moment.utc(record.fecha_entrega).format('DD/MM/YY')}</p>)
          }
        }
      }
    },
    {
      title: 'DÍAS DE ATENCIÓN',
      dataIndex: 'tiempo_atencion',
      key: 'tiempo_atencion',
      render : text => (
        text === null? "PENDIENTE": text
      )
    },
    {
      title: (<><div style={{width:110}}>USUARIO</div></>),
      dataIndex: 'usuario_creacion',
      key: 'usuario_creacion'
    },
    // {
    //   title: (
    //     <>
    //       <div style={{width:120}}>
    //         <Select
    //           allowClear
    //           defaultValue={selectAprobado}
    //           placeholder='APROBADO'
    //           optionFilterProp='children'
    //           filterOption={optionFilter}
    //           onChange={e => setSelectAprobado(e)}
    //         >
    //           <Option value='SI' key='SI'>SI</Option>
    //           <Option value='NO' key='NO'>NO</Option>
    //         </Select>
    //       </div>
    //     </>
    //   ),
    //   dataIndex: 'aprobado',
    //   key: 'aprobado',
    //   render : text => {
    //     switch (text) {
    //       case null:
    //         return(<Tag color={'#787878'}>PENDIENTE</Tag>)
          
    //       case 'NO':
    //         return(<Tag color={'#AA0404'}>NO</Tag>)
          
    //       case 'SI':
    //         return(<Tag color={'#B7CE63'}>SI</Tag>)
          
    //         default:
    //         break;
    //     }
    //   }
    // },
    // {
    //   title: (<><div  style={{width:140}}>APROBADO POR</div></>),
    //   dataIndex: 'nombre_aprobacion',
    //   key: 'nombre_aprobacion'
    // }
  ]

  const requerimientosListFiltrados = () => {
    const lista = requerimientosList.filter(requerimiento => {
      return ( (selectEstado === undefined?
        requerimiento.estado !== selectEstado: requerimiento.estado === selectEstado) &&
        moment.utc(requerimiento.fecha_creacion).format("YYYY-MM-DD") >= fechaInicio && 
        moment.utc(requerimiento.fecha_creacion).format("YYYY-MM-DD") <= fechaFin && 
        requerimiento.codigo.toUpperCase().includes(inputCodigo.toUpperCase()) && 
        (selectArea === undefined?
          requerimiento.id_area !== selectArea: requerimiento.id_area === selectArea) &&
        (selectPrioridad === undefined?
          requerimiento.prioridad !== selectPrioridad: requerimiento.prioridad === selectPrioridad) &&
        (selectTipo === undefined?
          requerimiento.tipo !== selectTipo: requerimiento.tipo === selectTipo) &&
        (selectAprobado === undefined?
          requerimiento.aprobado !== selectAprobado: requerimiento.aprobado === selectAprobado) &&
        (selectRubro === undefined?
          requerimiento.id_rubro !== selectRubro: requerimiento.id_rubro === selectRubro) )
    })

    let listaFinal =[]

    if (rolLogistica === true || rolFinanzas === true || rolGerencia === true ) {
      for (let i = 0; i < lista.length; i++) {
        listaFinal = [...listaFinal, {
          ...lista[i], index: i+1
        }]
      }
      return listaFinal
    } else {

      const listaF = filtrarReqPorAreas(lista)
      for (let i = 0; i < listaF.length; i++) {
        listaFinal = [...listaFinal, {
          ...listaF[i], index: i+1
        }]
      }
      return(listaFinal)
    }
  }

  const filtrarReqPorAreas = (listaReq) => {
    // console.log(listaReq)
    /**LISTA DE LAS AREAS ASIGNADAS AL USUARIO */
    const listaAreasByUser = respList.filter(res => {
      return( res.DocumentoIdentidad === user.DocumentoIdentidad && res.activo === 'SI' )
    })

    let listaReqByUser = []
    for (let i = 0; i < listaReq.length; i++) {
      const element = listaAreasByUser.filter(lis =>{
        return(listaReq[i].id_area === lis.id_area)
      });
      if (element.length > 0) {
        listaReqByUser = [...listaReqByUser, listaReq[i]]
      }
    }
    // console.log(listaReqByUser)
    return(listaReqByUser)
  }

  

  const handleOpenModal = (titulo, id_requerimiento) => {
    resetForm()
    setOpenModal(true)
    setTituloModal(titulo)
    if (titulo === 'Nuevo Requerimiento') {
      setRequerimientoSeleccionado({
        id_requerimiento: null,
        fecha: moment.utc(new Date()).format('YYYY-MM-DD'),
        id_responsable: null,
        nombre_responsable: null,
        prioridad: null,
        tipo: null,
        fecha_entrega: null,
        tiempo_atencion: null,
        comentario: null,
        nombre_area: null,
        ubicacion_archivo: null,
        id_rubro: null,
        codigo: null
      }) 
      setId_area(null)
    } else {
      getRequerimientoById(id_requerimiento)
      // getDetalleByIdRequerimiento(id_requerimiento)
    }
  }

  const handleOpenModalConsolidado = (titulo, id_consolidado) => {
    setOpenModalConsolidado(true)
    setTituloModal(titulo)
    setIdConsolidado(id_consolidado)
  }

  const getRequerimientoById = async(id_requerimiento) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getRequerimientoById/${id_requerimiento}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setRequerimientoSeleccionado({
          id_requerimiento: data.id_requerimiento,
          fecha: moment.utc(data.fecha).format('YYYY-MM-DD'),
          id_responsable: data.id_responsable,
          nombre_responsable: data.nombre_responsable,
          prioridad: data.prioridad,
          tipo: data.tipo,
          fecha_entrega: data.fecha_entrega,
          tiempo_atencion: data.tiempo_atencion,
          comentario: data.comentario,
          nombre_area: data.nombre_area,
          ubicacion_archivo: data.ubicacion_archivo,
          id_rubro: data.id_rubro,
          codigo: data.codigo
        }) 

        const area = responsablesAreasList.filter(responsable_area => {
          return responsable_area.id_responsable === data.id_responsable
        })

        const lista = responsablesAreasList.filter(responsable_area => {
          return responsable_area.id_area === area[0].id_area
        })
    
        setResponsablesSubList(lista)

        form.setFieldsValue({
          fecha: data.fecha,
          id_area: area[0].id_area,
          id_responsable: data.id_responsable,
          comentario: data.comentario,
          id_rubro: data.id_rubro
        })

        getDetalleByIdRequerimiento(id_requerimiento, data.tipo)
      })

    } catch (error) {
      console.log(error)
    }
  }

  const getDetalleByIdRequerimiento = async(id_requerimiento, tipo) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getDetalleByIdRequerimiento/${id_requerimiento}`)
      .then(res => res.json())
      .then(data => {
        if (tipo === 'SERVICIO') {
          setDetReqServicio(data)
        } else {
          setDetalleReqList(data)
        }
        
        // console.log(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleOpenModalDetalle = (requerimiento) => {
    // resetForm()
    setRequerimientoSeleccionado(requerimiento)
    form.setFieldsValue({
      prioridad: requerimiento.prioridad,
      tipo: requerimiento.tipo,
      estado: requerimiento.estado,
      tiempo_atencion: requerimiento.tiempo_atencion
    })
    setOpenModalDetalle(true)
    
    setNuevaCotizacion({
      id_cotizacion: null,
      id_requerimiento: requerimiento.id_requerimiento,
      total: 0,
      id_proveedor: null,
      usuario: cookies.get('nameid_logis'),
      fecha_cotizacion: moment.utc(new Date()).format('YYYY-MM-DD'),
      ubicacion_archivo: null,
      parv_descripcion: 'SOLES',
      TipCambio: 1
    })
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setDetalleReqList([])
    setDetReqServicio([{
      id_requerimiento: null,
      id_unidad: 2,
      cantidad: 1,
      id_articulo: 387,
      descripcion: null
    }])
  }

  const handleCloseModalDetalle = () => {
    setOpenModalDetalle(false)
    setDetalleReqList([])
  }

  const changeArea = (option) =>{
    setId_area(option.value)
    filtrarResponsables(option.value)
  }

  const filtrarResponsables = (area_value) => {
    const lista = responsablesAreasList.filter(responsable_area => {
      return responsable_area.id_area === area_value
    })

    setResponsablesSubList(lista)
    // console.log(lista)
    form.setFieldsValue({id_responsable: null})
  }


  const ExportarPDF = () => {
    componentePDF.current.save()
  }

  let pestañasList = [
    { key: 'requerimientos', tab: 'Lista General Requerimientos' }
    
  ]

  if (rolLogistica === true || rolFinanzas === true) {
    pestañasList = [...pestañasList,
      { key: 'consolidados', tab: 'Requerimientos Consolidados' }
    ]
  }

  const contenidoPestañas = {
    requerimientos:
      <>
        <Table
          // loading = {loadingTable}
          // pagination={{ pageSize: 10 }}
          columns={columns}
          dataSource={requerimientosListFiltrados()}
          size='small'
        />
      </>,
    consolidados:
      <>
        {<Consolidados
          rolLogistica = {rolLogistica}
          rolFinanzas = {rolFinanzas}
          handleOpenModalConsolidado = {handleOpenModalConsolidado}
          tituloModal = {tituloModal}
          setTituloModal = {setTituloModal}
          requerimientosList = {requerimientosList}
          proveedoresList = {proveedoresList}
          materialesList = {materialesList}
          unidadesList = {unidadesList}
          rubrosList = {rubrosList}
          getProveedores = {getProveedores}
          getConsolidados = {getConsolidados}
          consolidadosList = {consolidadosList}
          setConsolidadosList = {setConsolidadosList}
          nombre_usuario_aprobacion = {cookies.get('unique_name_logis')}
        />}
      </>
  }
  
  if (requerimientosList.length > 0) {
    return(
      <>
        {PdfFileRequerimiento(componentePDF, requerimientosListFiltrados(), fechaInicio, fechaFin, selectEstado)}
        <div className='row'>
          <div className='col-md-3 col-lg-3'>
            <label>Desde: </label> <br/> 
            <Input type="date"/*"datetime-local"*/
              value={fechaInicio}
              onChange={e => setFechaInicio(e.currentTarget.value)}
            />
          </div>

          <div className='col-md-3 col-lg-3'>
            <label>Hasta: </label> <br/> 
            <Input type="date"/*"datetime-local"*/
              value={fechaFin} 
              onChange={e => setFechaFin(e.currentTarget.value)}
            />  
          </div>

          <div className='elem-list mt-4'>
            {rolResponsableArea === true?
              <>
                <Button type='primary'  
                  title="Nuevo Requerimiento" 
                  onClick={e=>handleOpenModal('Nuevo Requerimiento', null)}
                  icon={<PlusOutlined />} >Agregar
                </Button> 

                {rolLogistica === true ?
                <Button type='primary'  
                  title="Consolidar Requerimientos" 
                  onClick={e=>handleOpenModalConsolidado('Nuevo Consolidado', null)}
                  icon={<AliyunOutlined />} >Consolidar
                </Button> 
                :null}
              </>
            : null
            }
            <Button danger 
              title="Exportar PDF" 
              onClick={e => ExportarPDF()}
              icon={<FilePdfOutlined />} >PDF
            </Button>
            {ExcelFileRequerimiento(requerimientosListFiltrados(), fechaInicio, fechaFin)}
          </div>
        </div>

        <Card
          tabList={pestañasList}
          activeTabKey={pestaña}
          onTabChange={onTabChange(setPestaña)}
        >
          {contenidoPestañas[pestaña]}
          

          {<ModalRequerimiento
            resetForm = {resetForm}
            openModal = {openModal}
            handleCloseModal = {handleCloseModal}
            requerimiento = {requerimientoSeleccionado}
            tituloModal = {tituloModal}
            loadingButton = {loadingButton}
            areasList = {rolLogistica === true || rolFinanzas === true?areasList: filtrarAreas()}
            id_area = {id_area}
            setRequerimiento = {setRequerimientoSeleccionado}
            form = {form}
            changeArea = {changeArea}
            responsablesSubList = {responsablesSubList}
            detalleReqList = {detalleReqList}
            rubrosList = {rubrosList}
            materialesList = {materialesList}
            unidadesList = {unidadesList}
            setLoadingButton = {setLoadingButton}
            getRequerimientos = {getRequerimientos}
            usuario = {cookies.get('nameid_logis')}
            setDetalleReqList = {setDetalleReqList}
            getUnidades = {getUnidades}
            detReqServicio = {detReqServicio}
            setDetReqServicio = {setDetReqServicio}
            getArticulos = {getMaterialesNombres}
            rolLogistica = {rolLogistica}
            getRubros = {getRubros}
          />}

          {<ModalDetalleRequerimiento
            form = {form}
            openModal = {openModalDetalle}
            handleCloseModal = {handleCloseModalDetalle}
            requerimiento = {requerimientoSeleccionado}
            getRequerimientos = {getRequerimientos}
            loadingButtonAprobar = {loadingButtonAprobar}
            setLoadingButtonAprobar = {setLoadingButtonAprobar}
            setRequerimiento = {setRequerimientoSeleccionado}
            rolLogistica = {rolLogistica}
            proveedoresList = {proveedoresList}
            cotizacion = {nuevaCotizacion}
            setCotizacion = {setNuevaCotizacion}
            usuario = {cookies.get('nameid_logis')}
            materialesList = {materialesList}
            unidadesList = {unidadesList}
            areasList = {rolLogistica === true || rolFinanzas === true?areasList: filtrarAreas()}
            rubrosList = {rubrosList}
            getProveedores = {getProveedores}
            getUnidades = {getUnidades}
            nombre_usuario_aprobacion = {cookies.get('unique_name_logis')}
            getRubros = {getRubros}
            getArticulos = {getMaterialesNombres}
            rolResponsableArea = {rolResponsableArea}
          />}

          {<ModalConsolidadoForm
            idConsolidado = {idConsolidado}
            setIdConsolidado = {setIdConsolidado}
            openModal = {openModalConsolidado}
            setOpenModal = {setOpenModalConsolidado}
            usuario = {cookies.get('nameid_logis')}
            tituloModal = {tituloModal}
            setTituloModal = {setTituloModal}
            requerimientosList = {requerimientosList}
            proveedoresList = {proveedoresList}
            materialesList = {materialesList}
            unidadesList = {unidadesList}
            getConsolidados = {getConsolidados}
            rubrosList = {rubrosList}
            getProveedores = {getProveedores}
        />}

        {<ModalOrdenesCompraDespachadas
          openModal = {openModalComprasDespachadas}
          setOpenModal = {setOpenModalComprasDespachadas}
          requerimiento = {requerimientoSeleccionado}
          setRequerimiento = {setRequerimientoSeleccionado}
          usuario = {cookies.get('nameid_logis')}
          getRequerimientos = {getRequerimientos}
        />}
        </Card>
      </>
    )  
  } else {
    return (
      <>
        <div className='row'>
          <div className='col-md-3 col-lg-3'>
            <label>Desde: </label> <br/> 
            <Input type="date"/*"datetime-local"*/
              value={fechaInicio}
              onChange={e => setFechaInicio(e.currentTarget.value)}
            />
          </div>

          <div className='col-md-3 col-lg-3'>
            <label>Hasta: </label> <br/> 
            <Input type="date"/*"datetime-local"*/
              value={fechaFin} 
              onChange={e => setFechaFin(e.currentTarget.value)}
            />  
          </div>

          <div className='elem-list mt-4'>
            {rolResponsableArea === true?
              <Button type='primary'  
                title="Nuevo Requerimiento" 
                onClick={e=>handleOpenModal('Nuevo Requerimiento', null)}
                icon={<PlusOutlined />} >Agregar
              </Button>   
            : null
            }
          </div>
        </div>

        <Card>

          <div>
            No hay Datos
          </div>

          {<ModalRequerimiento
            resetForm = {resetForm}
            openModal = {openModal}
            handleCloseModal = {handleCloseModal}
            requerimiento = {requerimientoSeleccionado}
            tituloModal = {tituloModal}
            loadingButton = {loadingButton}
            areasList = {rolLogistica === true || rolFinanzas === true?areasList: filtrarAreas()}
            id_area = {id_area}
            setRequerimiento = {setRequerimientoSeleccionado}
            form = {form}
            changeArea = {changeArea}
            responsablesSubList = {responsablesSubList}
            detalleReqList = {detalleReqList}
            rubrosList = {rubrosList}
            materialesList = {materialesList}
            unidadesList = {unidadesList}
            setLoadingButton = {setLoadingButton}
            getRequerimientos = {getRequerimientos}
            usuario = {cookies.get('nameid_logis')}
            setDetalleReqList = {setDetalleReqList}
            getUnidades = {getUnidades}
            detReqServicio = {detReqServicio}
            setDetReqServicio = {setDetReqServicio}
            getArticulos = {getMaterialesNombres}
            rolLogistica = {rolLogistica}
            getRubros = {getRubros}
          />}
        </Card>
      </>
    )
  }
  
}

export default Requerimientos