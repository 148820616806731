import { CloseOutlined, DeleteOutlined, FilePdfOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, 
  Select, Table, Card, message } from 'antd';
import { Rule } from 'antd/lib/form';
import { Option } from 'antd/lib/mentions';
import moment from 'moment';
import { useRef, useState, useEffect } from 'react';
import ModalUnidadForm from '../componentesUnidades/ModalUnidadForm';
import Cookies from "universal-cookie";
import ModalArticulo from '../componentesArticulos/ModalArticulo';
const cookies = new Cookies()

const { Item } = Form;

const rules = {
  required: { required: true, message: <></> } as Rule
};

const ModalRequerimiento = (props) => {
  const [openModalUnidad, setOpenModalUnidad] = useState(false);
  const [pestaña, setPestaña] = useState('compra');
  const [openModalArticulo, setOpenModalArticulo] = useState(false);
  const [tituloModalArticulo, setTituloModalArticulo] = useState('')
  const [articuloNuevo, setArticuloNuevo] = useState({})
  const [requerimiento, setRequerimiento] = useState({
    id_requerimiento: null,
    fecha: moment.utc(new Date()).format('YYYY-MM-DD'),
    id_responsable: undefined,
    nombre_responsable: null,
    prioridad: null,
    tipo: null,
    fecha_entrega: null,
    tiempo_atencion: null,
    comentario: null,
    ubicacion_archivo: null,
    id_rubro: null,
    codigo: null,
    aprobado: null,
    nombre_area: null
  })

  const [detalleReqList, setDetalleReqList] = useState([])
  const [detReqServicio, setDetReqServicio] = useState([
    {
      id_requerimiento: null,
      id_unidad: 2,
      cantidad: 1,
      id_articulo: 387,
      descripcion: null
    }
  ]);

  const [form] = Form.useForm();
  const resetForm = () => form.resetFields();
  const input = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setRequerimiento(props.requerimiento)
    if (props.openModal === true) {
      props.resetForm()

      if (props.requerimiento.tipo === 'SERVICIO') {
        setPestaña('servicio')
      } else {
        setPestaña('compra')
      }
    } else {
      props.resetForm()
    }
  }, [props.requerimiento]);

  useEffect(() => {
    setDetalleReqList(props.detalleReqList)
  }, [props.detalleReqList]);

  useEffect(() => {
    setDetReqServicio(props.detReqServicio)
  }, [props.detReqServicio]);

  const optionFilter = (input, option) => {
    return (option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0)
  }

  const onTabChange = (setter: (val: string) => void) => (key: string) => {
    setter(key);
  };

  const handleClick = () => input.current.click();

  const pestañasList = [
    { key: 'compra', tab: 'Compras' },
    { key: 'servicio', tab: 'Servicio' }
  ]

  const AddRequerimiento = () => {
    // console.log(props.detalleReqList.length)
    // console.log(requerimiento)
    // console.log(props.id_area)
    // console.log(props.requerimiento)
    if (pestaña === 'compra') {
      AddReqCompra()
    } else {
      AddReqServicio()
    }
  }

  const AddReqCompra = async() => {
    if (detalleReqList.length === 0) {
      message.error('Debe Ingresar los Materiales Requeridos')
    } else {

      const responsable = props.responsablesSubList.filter(res => {
        return(res.id_responsable === requerimiento.id_responsable)
      })
      // console.log(responsable)

      const area = props.areasList.filter(area => {
        return (area.id_area === props.id_area)
      })
      const body = {
        requerimiento: {...requerimiento, usuario: props.usuario, nombre_area: area[0].nombre,
          email:cookies.get('email_logis'), tipo: 'COMPRA', nombre_responsable: responsable[0].nombre},
        detalleReqList: detalleReqList
      }

      const validarDetalle = detalleReqList.filter(detalle => {
        return detalle.id_unidad === null || detalle.id_articulo === null
      })
      // console.log(body)
      if (validarDetalle.length > 0) {
        message.error('Faltan campos en el detalle de Equipos')
      } else {
        props.setLoadingButton(true)
        try {
          await fetch(`${process.env.REACT_APP_HOSTNAME}/AddRequerimiento`,{
            method: "POST",
            body: JSON.stringify(body),
            headers:{
              'Content-Type': 'application/json'
            }
          }).then(res => res.json())
          .then(data => {
            if (requerimiento.ubicacion_archivo === null) {
              // console.log(data)
              message.success("Requerimiento Registrado")
              props.setLoadingButton(false)
              props.getRequerimientos()
              props.handleCloseModal()
            } else {
              if (requerimiento.ubicacion_archivo[0] === undefined) {
                message.success("Requerimiento Registrado")
                props.setLoadingButton(false)
                props.getRequerimientos()
                props.handleCloseModal()
              } else {
                UpdateReqFile(data.id_requerimiento)
              }
            }
          }).catch(err => {
            console.log(err)
            props.setLoadingButton(false)
          });
        } catch (error) {
          props.setLoadingButton(false)
          console.log(error)
        }
      }
    }
  }

  const AddReqServicio = async() => {
    if (detReqServicio[0].descripcion === null) {
      message.error('Ingresar el servicio que solicita')
    } else {
      props.setLoadingButton(true)

      const responsable = props.responsablesSubList.filter(res => {
        return(res.id_responsable === requerimiento.id_responsable)
      })
      // console.log(responsable)
      const area = props.areasList.filter(area => {
        return (area.id_area === props.id_area)
      })

      const body = {
        requerimiento: {...requerimiento, usuario: props.usuario, nombre_area: area[0].nombre,
          email:cookies.get('email_logis'), tipo: 'SERVICIO', nombre_responsable: responsable[0].nombre},
        detalleReqList: detReqServicio
      }
      try {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/AddRequerimiento`,{
          method: "POST",
          body: JSON.stringify(body),
          headers:{
            'Content-Type': 'application/json'
          }
        }).then(res => res.json())
        .then(data => {
          if (requerimiento.ubicacion_archivo === null) {
            message.success("Requerimiento Registrado")
            props.setLoadingButton(false)
            props.getRequerimientos()
            props.handleCloseModal()
          } else {
            if (requerimiento.ubicacion_archivo[0] === undefined) {
              message.success("Requerimiento Registrado")
              props.setLoadingButton(false)
              props.getRequerimientos()
              props.handleCloseModal()
            } else {
              UpdateReqFile(data.id_requerimiento)
            }
          }
        }).catch(err => {
          console.log(err)
          props.setLoadingButton(false)
        });
      } catch (error) {
        props.setLoadingButton(false)
        console.log(error)
      }
    }
  }

  const EditRequerimiento = () => {
    // console.log(detalleReqList)
    // console.log(requerimiento)
    // console.log(props.detReqServicio)
    if (pestaña === 'compra') {
      // console.log('compra')
      EditReqCompra()
    } else {
      // console.log('servicio')
      EditReqServicio()
    }
    
  }

  const EditReqCompra = async() => {
    if (detalleReqList.length === 0) {
      message.error('Debe Ingresar los Materiales Requeridos')
    } else {
      const body = {
        requerimiento: {...requerimiento, tipo: 'COMPRA'},
        detalleReqList: detalleReqList
      }

      const validarDetalle = detalleReqList.filter(detalle => {
        return detalle.id_unidad === null || detalle.id_articulo === null
      })

      if (validarDetalle.length > 0) {
        message.error('Faltan campos en el detalle de Equipos')
      } else {
        props.setLoadingButton(true)
        try {
          await fetch(`${process.env.REACT_APP_HOSTNAME}/UpdateSolicitud`, {
            method: "PUT",
            body: JSON.stringify(body),
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(res => res.json())
          .then(data =>{
            message.success(data.mensaje)
            props.setLoadingButton(false)
            props.getRequerimientos()
            props.handleCloseModal()
          }).catch(err => {
            console.log(err)
            props.setLoadingButton(false)
          });
        } catch (error) {
          props.setLoadingButton(false)
          console.log(error)
        }
      }
    }
  }

  const EditReqServicio = async() => {
    if (detReqServicio[0].descripcion === null) {
      message.error('Ingresar el servicio que solicita')
    } else {
      props.setLoadingButton(true)
      const body = {
        requerimiento: {...requerimiento, tipo: 'SERVICIO'},
        detalleReqList: detReqServicio
      }

      try {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/UpdateSolicitud`, {
          method: "PUT",
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => res.json())
        .then(data =>{
          message.success(data.mensaje)
          props.setLoadingButton(false)
          props.getRequerimientos()
          props.handleCloseModal()
        }).catch(err => {
          console.log(err)
          props.setLoadingButton(false)
        });
      } catch (error) {
        props.setLoadingButton(false)
        console.log(error)
      }
    }
  }

  const UpdateReqFile = async(id_requerimiento) => {

    // const filename = requerimiento.ubicacion_archivo[0]['name']
    // const fileExtension = filename.split('.').pop()
    // console.log(fileExtension)

    const body = {
      destination:'Logistica',
      // filename:`Requerimiento_${id_requerimiento}.${fileExtension}`,
      id_requerimiento: id_requerimiento,
      varios: true
    }
    const bodyForm = new FormData()
    
    bodyForm.append('tabla', JSON.stringify(body))
    // bodyForm.append('File', requerimiento.ubicacion_archivo[0])
    for (let i = 0; i < requerimiento.ubicacion_archivo.length; i++) {
      const file = requerimiento.ubicacion_archivo[i];
      const filename = file['name']
      const fileExtension = filename.split('.').pop()
      bodyForm.append('File', requerimiento.ubicacion_archivo[i], `Requerimiento_${id_requerimiento}_${i}.${fileExtension}`)
    }

    // console.log(Object.fromEntries(bodyForm))
    // const objeto = Object.fromEntries(bodyForm)
    // console.log(bodyForm.getAll('File')[0]['name'])
    // console.log(bodyForm.getAll('File'))
    // console.log(bodyForm.getAll('File').length)

    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/UploadFileReq`,{
        method: "POST",
        body: bodyForm
      }).then(res => {
        // message.success("Requerimiento Registrado")
        props.setLoadingButton(false)
        props.getRequerimientos()
        props.handleCloseModal()
      }).catch(err => {
        console.log(err)
        message.error('No se Pudo Subir el Archivo al Servidor')
        props.setLoadingButton(false)
      });
    } catch (error) {
      console.log(error)
      message.error('No se Pudo Subir el Archivo al Servidor')
      props.setLoadingButton(false)
    }
  }

  const AddDetalleReq = () => {
    if (requerimiento.id_rubro === null) {
      message.warning('Especificar Rubro')
    } else {
      setDetalleReqList([...detalleReqList,{
        id_requerimiento: null,
        id_rubro: null,
        id_unidad: null,
        cantidad: 1,
        id_articulo: null,
        descripcion: null
      }])  
    }
  }

  const RemoveDetalleReq = (item) => {
    const listaItems = detalleReqList.filter(detalle => {
      return(detalle !== item)
    })
    // console.log(listaItems)
    setDetalleReqList(listaItems)
  }

  const detalleReqChange = (option, campo, item) => {
    // console.log(item)
    const listaItems = detalleReqList
    const key = listaItems.indexOf(item)

    if (campo === "cantidad" || campo === "descripcion") {
      listaItems[key][campo] = option 
    } else {
      listaItems[key][campo] = option.value
    }
    // console.log(listaItems[key])
    setDetalleReqList([...listaItems])
  }

  const articulosFiltrados = () => {
    const lista = props.materialesList.filter(articulo => {
      return (requerimiento.id_rubro === null?
        articulo.id_rubro !== requerimiento.id_rubro: articulo.id_rubro === requerimiento.id_rubro) 
    })
    // console.log(lista)
    return lista
  }

  const handleOpenModalUnidad = () => {
    setOpenModalUnidad(true)
  }

  const columns = [
    {
      title: (
        <>
          <Button type='primary' onClick={e => AddDetalleReq()} title="Agregar Item" icon={<PlusOutlined />}/>
        </>
      ),
      render: record => {
        return(
          <>
            <Button danger title="Quitar" onClick={e=> RemoveDetalleReq(record)} icon={<DeleteOutlined />}/>  
          </>
        )
      }
    },
    {
      title: (
        <div style={{width:150}}>
          MATERIAL/EQUIPO
          {props.rolLogistica === true? 
          <Button type='primary' onClick={e => handleOpenModalArticulo('Nuevo Artículo', null)} 
            title="Registrar Nuevo Artículo" icon={<PlusOutlined />} className='ml-2 mr-4'/>:null}
        </div>
      ),
      key: 'id_articulo',
      render: (_, record) => {
        return(<Select
          showSearch
          value={record.id_articulo}
          className='mb-lg-0'
          placeholder='Material/Equipo'
          optionFilterProp='children'
          filterOption={optionFilter}
          onChange={(e, option) => detalleReqChange(option, "id_articulo", record)}
        >
          {articulosFiltrados().map(material => {
            return(
              <Option value={material.id_articulo} 
                key={material.id_articulo}>
                {material.nombre}
              </Option>
            )
          })}
        </Select>)
      }
    },
    {
      title: 'DESCRIPCIÓN',
      key: 'descripcion',
      render: record => {
        return(
          <>
            <Input name='descripcion' defaultValue = {record.descripcion} //value={record.descripcion}
              onChange={e=>detalleReqChange(e.currentTarget.value, "descripcion", record)}
            />
          </>
        )
      }
    },
    {
      title: (
        <div style={{width:150}}>
          PRESENTACIÓN
          <Button type='primary' onClick={e => handleOpenModalUnidad()} title="Agregar Presentación"
            icon={<PlusOutlined />} className='ml-2 mr-4'/>
        </div>
      ),
      key: 'id_unidad',
      render: record => {
        return(<Select
          showSearch
          value={record.id_unidad}
          className='mb-lg-0'
          placeholder='Unidad de Medida'
          optionFilterProp='children'
          filterOption={optionFilter}
          onChange={(e, option) => detalleReqChange(option,"id_unidad", record)}
        >
          {props.unidadesList.map(unidad => {
            return(
              <Option value={unidad.id_unidad} 
                key={unidad.id_unidad}>
                {unidad.nombre} X {unidad.cantidad_unidades}
              </Option>
            )
          })}
        </Select>)
      }
    },
    {
      title: (<div style={{width:80}}>CANTIDAD</div>),
      key: 'cantidad',
      render: record => {
        return(
          <>
            <Input type="number" name='cantidad' defaultValue={record.cantidad}
              onChange={e=>detalleReqChange(e.currentTarget.value, "cantidad", record)}
            />
          </>
        )
      }
    }
  ]

  const subirArchivo = (e) => {
    // console.log(e[0].name)
    // console.log(e)
    setRequerimiento({...requerimiento, ubicacion_archivo: e})
  }

  const handleOpenModalArticulo = (titulo, id_articulo) => {
    resetForm()
    setOpenModalArticulo(true)
    setTituloModalArticulo(titulo)

    setArticuloNuevo({
      id_articulo: null,
      nombre: null,
      id_rubro: null,
      ean: null,
      identificador: null
    })
  }

  const changeRubro = (id_rubro) => {
    setRequerimiento({
      ...requerimiento,
      id_rubro: id_rubro
    })
    setDetalleReqList([])
  }

  
  const contenidoPestañas = {
    compra:
      <>
        <Table 
          pagination={false} 
          columns={columns}
          dataSource={detalleReqList}
          size="small"
        />
      </>,
    
    servicio:
      <>
        <Input.TextArea allowClear value={detReqServicio[0].descripcion} 
          onChange={e=>setDetReqServicio([{
            ...detReqServicio[0], descripcion:e.currentTarget.value
          }])}
          placeholder = "Ingresar el Servicio que solicita" 
          rows={3}
        />
      </>
  }

  const ChangeResponsable = (option) => {
    setRequerimiento({
      ...requerimiento,
      id_responsable: option.value,
      nombre_responsable: option.children
    })
  }

  const nombresItems = () => {
    // console.log(requerimiento.ubicacion_archivo)
    let nombres = []
    for (let i = 0; i < requerimiento.ubicacion_archivo.length; i++) {
      const element = requerimiento.ubicacion_archivo[i];
      nombres = [...nombres, {nombre_file: element.name}]
    }
    return nombres
  }

  return(
    <>
      <Modal
        visible={props.openModal}
        closable={false}
        width='90%'
        title={<h3 className='m-0'>{props.tituloModal}</h3>}
        onCancel={props.handleCloseModal}
        footer={null}
      >
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <Form form={props.form} layout='vertical' 
              onFinish={requerimiento.id_requerimiento === null?AddRequerimiento: EditRequerimiento}>
              <div className='elem-list'>
                <div className='col-sm-11 col-md-2 col-lg-2'>
                  <Item label="Fecha" name="fecha">
                    <b>{moment(requerimiento.fecha).format('DD/MM/YYYY')}</b>
                  </Item>
                </div>
              
                <div className='col-sm-11 col-md-5 col-lg-5'>
                  <Item label="Área" name="id_area" rules={[
                    {...rules.required, message: 'El Área es Requerida'}
                  ]}>
                    <Select
                      showSearch
                      defaultValue={props.id_area}
                      className='mb-lg-0'
                      placeholder='Seleccionar Área'
                      optionFilterProp='children'
                      filterOption={optionFilter}
                      onChange={(e, option)=>{
                        props.changeArea(option)
                        setRequerimiento({
                          ...requerimiento, 
                          id_responsable: null
                        })
                      }}
                    >
                    {props.areasList.map(area =>{
                      return(
                        <Option value={area.id_area} key={area.id_area}>{area.nombre}</Option>
                      )
                    })}
                    </Select>
                  </Item>  
                </div>  

                <div className='col-sm-11 col-md-4 col-lg-4'>
                  <Item label="Responsable" name="id_responsable" rules ={[
                    {...rules.required, message: 'El Responsable es Requerido'}
                  ]}>
                    <Select
                      // allowClear
                      defaultValue={requerimiento.id_responsable}
                      className='mb-lg-0'
                      placeholder='Seleccionar Responsable'
                      optionFilterProp='children'
                      filterOption={optionFilter}
                      onChange={(e, option) => ChangeResponsable(option)}
                    >
                      {props.responsablesSubList.map(responsable => {
                        return(
                          <Option value={responsable.id_responsable} 
                            key={responsable.id_responsable}>
                            {responsable.nombre}
                          </Option>
                        )
                      })}
                    </Select>
                  </Item>  
                </div>
              </div>

              <div className='col-sm-12 col-md-12 col-lg-12'>
                <Card title='Equipos/Materiales'>
                  <div className='col-sm-11 col-md-5 col-lg-5'>
                    <Item label="Rubro" name="id_rubro" rules ={[
                      {...rules.required, message: 'El Rubro es Requerido'}
                    ]}>
                      <Select
                        showSearch
                        defaultValue={requerimiento.id_rubro}
                        className='mb-lg-0'
                        placeholder='Seleccionar Rubro'
                        optionFilterProp='children'
                        filterOption={optionFilter}
                        onChange={e => changeRubro(e)}
                      >
                        {props.rubrosList.map(rubro => {
                          return(
                            <Option value={rubro.id_rubro} 
                              key={rubro.id_rubro}>
                              {rubro.nombre}
                            </Option>
                          )
                        })}
                      </Select>
                    </Item>
                  </div>

                  <Card
                    tabList={pestañasList}
                    activeTabKey={pestaña}
                    onTabChange={onTabChange(setPestaña)}
                  >
                    {contenidoPestañas[pestaña]}
                  </Card>
                </Card>
              </div>

              <div className='elem-list mb-2'>
                {requerimiento.id_requerimiento === null?
                  <div className='col-sm-11 col-md-11 col-lg-11 mb-2'>
                    {requerimiento.ubicacion_archivo === null?null:
                      requerimiento.ubicacion_archivo[0] === undefined?null:
                      <>
                        {nombresItems().map((item, index) => {
                          return( 
                            <><b className='mr-4'>{index+1}{item.nombre_file}</b><br/></>
                          )
                        })}
                      </>
                    }
                    <input ref={input} onChange={(e) => subirArchivo(e.target.files)} type='file' style={{ display: 'none' }} multiple/>
                    <Button size='small' type={'primary'} className='btn-outline' onClick={handleClick} icon={<FilePdfOutlined />}
                      disabled = {requerimiento.aprobado === 'NO'? true: false}
                    >Subir Archivo(s)
                    </Button>
                  </div>
                :null}  
              </div>

              <div className='elem-list mb-2'>
                <div className='col-sm-11 col-md-9 col-lg-9'>
                  <Item label="Necesidad de la Solicitud" name="comentario" rules ={[
                    {...rules.required, message: 'Debe justificar la solicitud'}
                  ]}>
                    <Input.TextArea /*allowClear*/ defaultValue={requerimiento.comentario} 
                      onChange={e=>setRequerimiento({
                        ...requerimiento, comentario:e.currentTarget.value
                      })}
                    />
                  </Item>
                </div>
              </div>

              <div className='modal-footer d-flex justify-content-between'>
                <Button danger onClick={props.handleCloseModal} icon={<CloseOutlined />}>Cerrar</Button>
              
                <Button className='bg-color-success' loading={props.loadingButton}
                  type='primary' icon={<SaveOutlined />} htmlType="submit">Guardar
                </Button>   
              </div>
            </Form>
          </div>
        </div>

        {<ModalUnidadForm
          openModalUnidad = {openModalUnidad}
          setOpenModalUnidad = {setOpenModalUnidad}
          getUnidades = {props.getUnidades}
          usuario = {props.usuario}
        />}

        {<ModalArticulo
          openModal = {openModalArticulo}
          tituloModal = {tituloModalArticulo}
          articulo = {articuloNuevo}
          setArticulo = {setArticuloNuevo}
          form = {form}
          getArticulos = {props.getArticulos}
          setOpenModal = {setOpenModalArticulo}
          rubrosList = {props.rubrosList}
          usuario = {props.usuario}
          getRubros = {props.getRubros}
        />}
      </Modal>
    </>
  )
}

export default ModalRequerimiento