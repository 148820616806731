import moment from "moment";
import {PDFExport} from '@progress/kendo-react-pdf'
import { Table } from "antd";
import LogoSvg from './../../../assets/img/LogoConFrase.PNG'
import imgcompra from './../../../assets/img/ordencompra.PNG'
import imgservicio from './../../../assets/img/ordenservicio.PNG'
import { listaAreas, NumerosALetras } from "../FuncionesGenerales";
import "./styles.css";

const PageTemplate = (props) => {
  return (
    <div style={{ position: "absolute", bottom: "10px", left: "50%" }}>
      Pag. {props.pageNum} de {props.totalPages}
    </div>
  );
};

const columns = (ordenCompra) => {
// console.log(ordenCompra)
	const columns = [
		{
			title: 'Material/Equipo',
			render: record => {
				return(<>{record.nombre_articulo} {record.marca} {record.descripcion}</>)
			},
			align: 'center'
		},
		{
			title: 'Presentación',
			render: record => {
				return(<>{record.nombre_unidad} X {record.cantidad_unidades}</>)
			},
			align: 'center'
		},
		{
			title: 'Cantidad',
			render: record => {
				return(<>{record.cantidad.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>)
			},
			align: 'center'
		},
		{
			title: 'Precio',
			render: record => {
				return(
					<>
					{ordenCompra.Parc_Valor} {
					record.precio.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
					</>
				)
			},
			align: 'center'
		},
		{
			title: 'Total',
			render: record => {
				return(
					<>
					{ordenCompra.Parc_Valor} 
					{(record.cantidad * record.precio).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
					</>
				)
			},
			align: 'right'
		}
	]

	const columnsServ = [
		{ title: 'DETALLE DEL SERVICIO', key: 'descripcion', dataIndex: 'descripcion' },
		{ title: 'PRECIO',
			render: record => {
				return(
				<>
					{ordenCompra.Parc_Valor} {record.precio.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
				</>
				)
			},
			align: 'right'
		}
	]

	if (ordenCompra.tipo === 'COMPRA') {
		return columns
	} else {
		return columnsServ
	}
	
}


const Total = (ordenCompra, detalleOrden) => {
	const moneda = ordenCompra.parv_descripcion === 'SOLES'?'S/.':'$'
	// console.log(ordenCompra)
	return(
		<>
			{ordenCompra.tipo_documento === 'HONORARIO'?null:
			<div className='d-flex justify-content-end mr-1'>
				<b>Base Imponible </b>
				<b className="ml-2">
					{moneda} { detalleOrden.length === 0? 0:
						((detalleOrden.map(det => det.cantidad * det.precio).reduce((prev, next) => prev + next)) / 1.18)
						.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
					}
				</b> 
			</div>
			}

			{ordenCompra.tipo_documento === 'HONORARIO'?null:
			<div className='d-flex justify-content-end mr-1'>
				<b>IGV </b>
				<b className="ml-2">
					{moneda} { detalleOrden.length === 0? 0:
						((detalleOrden.map(det => det.cantidad * det.precio).reduce((prev, next) => prev + next)) / 1.18 * 0.18)
						.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
					}
				</b> 
			</div>
			}

			<div className='d-flex justify-content-end mr-1'>
				<b>Total </b>
				<b className="ml-2">
					{moneda} { detalleOrden.length === 0? 0:
						(detalleOrden.map(det => det.cantidad * det.precio).reduce((prev, next) => prev + next))
						.toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
					}
				</b>
			</div>
		</>
	)
}

export const PdfFileOrden = (componentePDF, ordenCompra, detalleOrden, detalleBancos) => {
	// console.log(ordenCompra)
	// console.log(NumerosALetras(ordenCompra.penalidades))
	const num_letras = NumerosALetras(ordenCompra.penalidades)
	return(
		<>
			<div style={{ position: "absolute", left: "-4000px", top: 0 }} >
				<PDFExport 
					ref={componentePDF} 
					paperSize='A4'
					margin="1cm" 
					// landscape={true}
					pageTemplate={PageTemplate}
					fileName={`OrdenCompra_${ordenCompra.id_orden_compra}`}
					scale={0.5}
				>
					<div className='d-flex justify-content-between'>
						<div>
							<img src={LogoSvg} style={{maxWidth:'620px', maxHeight:'120px'}}/>	
						</div>

						<div>
							<img src={ordenCompra.tipo === 'COMPRA'?imgcompra:imgservicio} style={{maxWidth:'600px', maxHeight:'150px'}}/>	
						</div>
					</div>

					<hr className="k-hr" style={{borderColor:'black'}}/>

					<div className='d-flex justify-content-between'>
						<b>Orden N°: {ordenCompra.tipo === 'COMPRA'?'OC': ordenCompra.tipo === 'SERVICIO'?'OS':null}-{ordenCompra.numero_orden}</b>
						<b>Fecha: {moment.utc(ordenCompra.fecha_orden_compra).format('DD/MM/YYYY')}</b>
					</div>

					<table className="table table-sm mb-0">
						<tbody>
							<tr>
								<th style={{background: '#FF3333', color: '#FFFFFF', fontWeight: 'bold'}}>Proveedor</th>
								<th style={{background: '#FF3333', color: '#FFFFFF', fontWeight: 'bold'}}>Datos de Entrega</th>
							</tr>

							<tr>
								<td>
									RUC: {ordenCompra.ruc} <br/>
									Razón Social: {ordenCompra.descripcion} <br/>
									Dirección: {ordenCompra.direccion} <br/>
									Correo: {ordenCompra.email} <br/>
									Telf./Cel.: {ordenCompra.telefono} / {ordenCompra.celular}
								</td>

								<td>
									RUC: 20113711834 <br/>
									Razón Social: CLINICA LIMATAMBO S.A.C. <br/>
									Dirección: JR. PUNO N° 263 BARRIO CHONTAPACCHA <br/>
									Correo: logistica@limatambo.com.pe <br/>
									Telf./Cel.: + 51 987 390 914 /+51 975 495 041
								</td>
							</tr>
						</tbody>
					</table>

					<table className="table table-sm mt-0">
						<tbody>
							<tr>
								<th style={{background: '#FF3333', color: '#FFFFFF', fontWeight: 'bold'}}>Banco</th>
								<th style={{background: '#FF3333', color: '#FFFFFF', fontWeight: 'bold'}}>Moneda</th>
								<th style={{background: '#FF3333', color: '#FFFFFF', fontWeight: 'bold'}}>N° Cuenta</th>
								<th style={{background: '#FF3333', color: '#FFFFFF', fontWeight: 'bold'}}>CCI</th>
							</tr>

							{detalleBancos.map(cuenta => {
								return(
									<tr>
										<td>{cuenta.Descripcion}</td>
										<td>{cuenta.moneda}</td>
										<td>{cuenta.numero_cuenta}</td>
										<td>{cuenta.cci}</td>
									</tr>
								)
							})}
						</tbody>
					</table>

					<table className="table table-sm mt-2">
						<tbody>
							<tr>
								<th style={{background: '#FF3333', color: '#FFFFFF', fontWeight: 'bold'}}>F. de Envío</th>
								<th style={{background: '#FF3333', color: '#FFFFFF', fontWeight: 'bold'}}>Tipo de Pago</th>
								<th style={{background: '#FF3333', color: '#FFFFFF', fontWeight: 'bold'}}>Tipo de Entrega</th>
							</tr>

							<tr>
								<td>{ordenCompra.fecha_envio === null?null:moment.utc(ordenCompra.fecha_envio).format('DD/MM/YYYY')}</td>
								<td>{ordenCompra.tipo_pago}</td>
								<td>{ordenCompra.tipo_entrega}</td>
							</tr>
						</tbody>
					</table>

					<Table
						pagination={false}
						columns={columns(ordenCompra)}
						dataSource={detalleOrden}
						size='small'
						footer={() => Total(ordenCompra, detalleOrden)}
					/>

					<br/>
					<div>
						<b>COMENTARIO:</b> <br/>
						<p>{ordenCompra.comentario}</p> 
					</div>
					<br/>

					<b>TÉRMINOS y CONDICIONES</b> <br/>

					<table className="table table-sm mt-2">
						<tbody>
							<tr>
								<th>LUGAR DE EJECUCIÓN/ENTREGA:</th>
								<th>DÍAS DE PLAZO:</th>
								{/* <th style={{textAlign: 'right'}}>PENALIDADES:</th> */}
							</tr>

							<tr>
								<td>{ordenCompra.lugar_ejecucion === null?'-':ordenCompra.lugar_ejecucion}</td>
								<td>{ordenCompra.dias_plazo === null?'-':ordenCompra.dias_plazo}</td>
								{/* <td style={{textAlign: 'right'}}>{ordenCompra.penalidades === null?'-':ordenCompra.penalidades}</td> */}
							</tr>
						</tbody>
					</table>

					<div>
						<b>CAUSAL DE AMPLIACIÓN DE PLAZO:</b> <br/>
						<p>
							Conforme a lo previsto en el artículo 1315° del Código Civil, únicamente se 
							otorgará ampliación de plazo por causas de caso fortuito y fuerza mayor.
						</p>
					</div>

					<div className="mt-2" >
						<b>CAUSALES DE RESOLUCIÓN:</b> <br/>
						<p style={{textAlign: 'justify'}}>
							Las partes acuerdan facultar a LA CLÍNICA para que resuelva el contrato, en caso de que {ordenCompra.descripcion} incumpla sus 
							obligaciones contractuales y/o las obligaciones, bastando para ello una comunicación a la contraparte y de verse 
							perjudicada procederá conforme a derecho. Esto conforme a lo prescrito en los artículos 1428° y 1429° del Código Civil.
						</p>
					</div>

					<div className="mt-2">
						<b>PENALIDAD:</b> <br/>
						<p style={{textAlign: 'justify'}}>
							Si {ordenCompra.descripcion} incurre en el retraso injustificado en la ejecución de la prestación del servicio, objeto del contrato, 
							LA CLÍNICA le aplicará a {ordenCompra.descripcion} una penalidad por cada día de atraso, hasta por un monto máximo equivalente 
							al {num_letras.enteros} {num_letras.decimales === ''?'':` CON ${num_letras.decimales}`} por ciento ({ordenCompra.penalidades}%) del monto del contrato vigente. Para el cobro de la 
							penalidad no se requerirá comunicación previa  podrá ser descontada de la factura que se 
							yencuentre pendiente de pago a favor de {ordenCompra.descripcion}
						</p>
					</div>

					<div className='d-flex justify-content-between mt-4'>
						<div>
							<b>ÁREAS SOLICITANTES:</b> <br/>
							<b>{ordenCompra.id_consolidado === null?ordenCompra.nombre_area:listaAreas(ordenCompra.nombre_area)}</b>
						</div>

						<div>
							<b>SOLICITADO POR:</b> <br/>
							{ordenCompra.id_consolidado === null?<b>{ordenCompra.nombre_responsable}</b>:
								JSON.parse(ordenCompra.nombre_responsable).map(res => {
									return(<><b>{res.nombre}</b><br/></>)
								})
							}
						</div>

						<div>
							<b>APROBADO POR:</b> <br/>
							<b>
								{ordenCompra.nombre_usuario_aprobacion === null? 
								ordenCompra.nombre_aprobacion:ordenCompra.nombre_usuario_aprobacion}
							</b>
						</div>
					</div>
				</PDFExport>
			</div>
		</>
	)
}