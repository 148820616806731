import { CheckOutlined, CloseOutlined, EyeOutlined, FileFilled, FilePdfOutlined } from '@ant-design/icons';
import { Button, Modal, Table, Card, Tag,
  Popconfirm, message } from 'antd';
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import { DownloadFile } from '../FuncionesGenerales';
import { PdfFileOrden } from '../componentesPDF/GenerarPDFOrden';
import ModalFilesOCDespachadas from './ModalFilesOCDespachadas';

const ModalOrdenesCompraDespachadas = (props) => {
  const [ordenesCompraByReq, setOrdenesCompraByReq] = useState([])
  const [detOrdenesCompraByReq, setDetOrdenesCompraByReq] = useState([])
  const [detalleReqList, setDetalleReqList] = useState([])
  const [loadingButtonAprobar, setLoadingButtonAprobar] = useState(false)
  const [detalleBancos, setDetalleBancos] = useState([])
  const [idFactura, setIdFactura] = useState(null)
  const [openModalFiles, setOpenModalFiles] = useState(false)
  
  const componentePDF = useRef(null)

  useEffect(() => {
    if (props.openModal === true) {
      getOrdenesCompraDespachadasByRequerimiento(props.requerimiento.id_requerimiento)
      getDetalleOrdenesDespachadasByReq(props.requerimiento.id_requerimiento)
      getDetalleByIdRequerimiento(props.requerimiento.id_requerimiento)
      // getCuentasProveedor(props.requerimiento.id_proveedor)
    }
  }, [props.openModal]);

  const getOrdenesCompraDespachadasByRequerimiento = async(id_requerimiento) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getOrdenesCompraDespachadasByRequerimiento/${id_requerimiento}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setOrdenesCompraByReq(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getDetalleOrdenesDespachadasByReq = async(id_requerimiento) => {
    // console.log(id_requerimiento)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getDetalleOrdenesDespachadasByReq/${id_requerimiento}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setDetOrdenesCompraByReq(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getDetalleByIdRequerimiento = async(id_requerimiento) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getDetalleByIdRequerimiento/${id_requerimiento}`)
      .then(res => res.json())
      .then(data => {
        setDetalleReqList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const ExportarPDF = async(orden) => {
    // componentePDF.current.save()
    // console.log(orden)
    try {
      const cuentas = await fetch(`${process.env.REACT_APP_HOSTNAME}/getCuentasProveedor/${orden.id_proveedor}`)
      .then(res => res.json())
      .then(async data => {
        // console.log(data)
        if (data.message) {
          return([])
        } else {
          return(data)
        }
        // console.log(data)
      })
      setDetalleBancos(cuentas)
      componentePDF.current.save()
    } catch (error) {
      console.log(error)
    }
  }

  const ConformidadOC = async(orden_compra, aprobado) => {
    let listaFinalItems = []
    // console.log(orden_compra.usuario_req)
    // console.log(props.usuario)
    // setLoadingButtonAprobar(true)
    if (aprobado === 'SI') {
      /**DETALLE DE LA ORDEN DE COMPRA */
      const detalleOrden = detOrdenesCompraByReq.filter( detalle => {
        return detalle.id_orden_compra === orden_compra.id_orden_compra
      })
      // console.log(detalleReqList, detalleOrden)
      
      /**DETALLE DEL REQ Q ESTA EN LA ORDEN */
      for (let i = 0; i < detalleOrden.length; i++) {
        const element = detalleReqList.filter(det => {
          return(det.id_articulo === detalleOrden[i].id_articulo && det.id_unidad === detalleOrden[i].id_unidad)
        });

        if (element.length > 0) {
          listaFinalItems = [...listaFinalItems, element[0]]
        }
      }
    }

    const body = {
      requerimiento: {...orden_compra, usuario: props.usuario, conformidad_final:aprobado,
        fecha_movimiento: moment.utc(new Date()).format('YYYY/MM/DD')},
      detalleReqList: listaFinalItems
    }

    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/${orden_compra.id_consolidado === null?'ConformidadOrdenCompra':'ConformidadOrdenCompraConsolidado'}`,{
        method: "PUT",
        body: JSON.stringify(body),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data => {
        message.success(data.message)
        handleCloseModal()
        setLoadingButtonAprobar(false)
        props.getRequerimientos()
      }).catch(err => {
        console.log(err)
        setLoadingButtonAprobar(false)
      });
    } catch (error) {
      console.log(error)
      setLoadingButtonAprobar(false)
    }
    
  }

  const handleOpenModalFiles = (orden) => {
    setIdFactura(orden.id_factura)
    setOpenModalFiles(true)
  }

  const handleCloseModalFiles = () => {
    setIdFactura(null)
    setOpenModalFiles(false)
  }

  const columns = [
    {
      title: (<><div style={{width:120}}/></>),
      render: record => {
        return(
          <>
          {PdfFileOrden(componentePDF, {...record, id_consolidado:null}, subTablaList(record), detalleBancos)}
          <Button danger 
              title="Exportar Orden de Compra" 
              onClick={e => ExportarPDF(record)} className='mr-2'
              icon={<FilePdfOutlined />}/>

            {record.ubicacion_archivo === null?null:
            <Button danger className='mr-2'
              title="Descargar Guía de Remisión" 
              onClick={e => DownloadFile('GuiaRemision', record, record.id_orden_compra)}
              icon={<FilePdfOutlined />}>
            </Button>}

            <Button type='primary' className='mr-2'
              title="Ver Facturas" 
              onClick={e => handleOpenModalFiles(record)}
              icon={<EyeOutlined />}>
            </Button>

            {record.ubicacion_archivo_xml === null?null:
            <Button className='mr-2'
              title="Descargar XML" 
              onClick={e => DownloadFile('XML', {...record, 
                otraUbicacion:record.ubicacion_archivo_xml
              }, record.id_factura)}
              icon={<FileFilled />}>
            </Button>}
          </>
        )
      }
    },
    {
      title: (<><div style={{width:120}}>¿ES CONFORME?</div></>),
      render: record => {
        return(
          <>
            {record.conformidad_final === null && props.usuario === record.usuario_req?
            <>
              <Popconfirm
                title={`¿Es Conforme la Entrega?`}
                onConfirm={e=>ConformidadOC(record, "SI")} 
                okText="Si" cancelText="No"
                >
                <Button type='primary' className='bg-color-success mr-2'
                  loading = {loadingButtonAprobar} title = 'Conforme'
                  icon={<CheckOutlined />}
                /> 
              </Popconfirm>

              <Popconfirm
                title={`¿Rechaza esta Entrega de Unidades al Área `+`\n`+
                  `(Este paso es irreversible)`}
                onConfirm={e=>ConformidadOC(record, "NO")} 
                okText="Si" cancelText="No"
                >
                <Button danger
                  loading = {loadingButtonAprobar} title = 'No Conforme'
                  icon={<CloseOutlined />}
                /> 
              </Popconfirm>
            </>
            :<div>{record.conformidad_final === null?'PENDIENTE':record.conformidad_final}</div>}
          </>
        )
      }
    },
    {
      title: 'FECHA DE EMISIÓN',
      key: 'fecha_emision',
      dataIndex: 'fecha_emision',
      render: text => (
        moment.utc(text).format('DD/MM/YY')
      )
    },
    {
      title: 'ORDEN COMPRA',
      key: 'numero_orden',
      dataIndex: 'numero_orden'
    },
    {
      title: (<><div style={{width:180}}>RAZÓN SOCIAL</div></>),
      key: 'descripcion',
      dataIndex: 'descripcion'
    },
    {
      title: 'NOMBRE COMERCIAL',
      key: 'nombre_comercial',
      dataIndex: 'nombre_comercial'
    },
    {
      title: 'RUC',
      key: 'ruc',
      dataIndex: 'ruc'
    },
    {
      title: 'T. CAMBIO',
      key: 'TipCambio',
      dataIndex: 'TipCambio'
    },
    {
      title: (<><div style={{width:90}}>TOTAL</div></>),
      render: record => {
        return(
          <>
            {record.Parc_Valor} {record.total}
          </>
        )
      }
    },
    {
      title: (<><div style={{width:160}}>ESTADO</div></>),
      key: 'estado',
      dataIndex: 'estado',
      render : text => {
        switch (text) {
          case 'PENDIENTE':
            return(<Tag color={'#336cfb'}>PENDIENTE</Tag>)

          case 'ACEPTADA':
            return(<Tag color={'#B7CE63'}>ACEPTADA</Tag>)
          
          case 'FACTURADA':
            return(<Tag color={'#B7CE63'}>FACTURADA</Tag>)
            
          case 'DESPACHADA':
            return(<Tag color={'#B7CE63'}>DESPACHADA</Tag>)

          case 'ESPERA DE CONFORMIDAD':
            return(<Tag color={'#FC5F1B'}>ESPERA DE CONFORMIDAD</Tag>)

          case 'FINALIZADA':
            return(<Tag color={'#B7CE63'}>FINALIZADA</Tag>)
          
          case 'RECHAZADA':
            return(<Tag color={'#AA0404'}>RECHAZADA</Tag>)

            default:
            break;
        }
      },
    }
  ]

  const subTabla = (orden_compra) =>{
    const subcolumns = [
      { title: 'DESCRIPCIÓN',
        render: record => {
          return{
            props: {
              style: { background: '#C0E8FF' },
            },
            children: <div>{record.nombre_articulo} {record.marca} {record.descripcion}</div>,

          }
        } 
      },
      { title: 'PRESENTACIÓN',
        render: record => {
          return{
            props: {
              style: { background: '#C0E8FF' }
            },
            children: <div>{record.nombre_unidad} X {record.cantidad_unidades}</div>,
          }
        }
      },
      { title: 'CANTIDAD', key: 'cantidad', dataIndex: 'cantidad',
        render: text => {
          return{
            props: {
              style: { background: '#C0E8FF' }
            },
            children: <div>{text}</div>
          }
        }
      },
      { title: 'PRECIO',
        render: record => {
          return{
            props: {
              style: { background: '#C0E8FF' }
            },
            children: <div>{orden_compra.Parc_Valor} {record.precio}</div>
          }
        }
      },
      {
        title: 'TOTAL',
        key: '',
        render: record => {
          return{
            props: {
              style: { background: '#C0E8FF' }
            },
            children: <div>{orden_compra.Parc_Valor} {record.cantidad * record.precio}</div>
          }
        }
      }
    ]

    const detalleOrden = detOrdenesCompraByReq.filter( detalle => {
      return detalle.id_orden_compra === orden_compra.id_orden_compra
    })

    return <Table columns={subcolumns} dataSource={detalleOrden} pagination={false} />
  }

  const subTablaList = (orden_compra) => {
    const detalleOrden = detOrdenesCompraByReq.filter( detalle => {
      return detalle.id_orden_compra === orden_compra.id_orden_compra
    })

    return detalleOrden
  }

  const handleCloseModal = () => {
    props.setOpenModal(false)
    props.setRequerimiento({
      id_requerimiento: null,
      fecha: null,
      id_responsable: null,
      nombre_responsable: null,
      prioridad: null,
      tipo: null,
      fecha_entrega: null,
      tiempo_atencion: null,
      comentario: null,
      nombre_area: null,
      ubicacion_archivo: null,
      id_rubro: null
    })

    setOrdenesCompraByReq([])
    setDetOrdenesCompraByReq([])
  }

  if (ordenesCompraByReq.length > 0) {
    return(
      <>
        <Modal
          visible={props.openModal}
          closable={false}
          width='100%'
          title = {null}
          onCancel={handleCloseModal}
          footer={
            <div className='modal-footer d-flex justify-content-between'>
              <Button danger onClick={handleCloseModal} icon={<CloseOutlined />}>
                Cerrar
              </Button>
            </div>
          }
        >
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <Card title="Órdenes de Compra">
              <Table 
                pagination={false} 
                columns={columns}
                dataSource={ordenesCompraByReq}
                size="small"
                rowKey={record => record.id_orden_compra}
                expandedRowRender = { record => subTabla(record)}
              />
            </Card>
          </div>

          {<ModalFilesOCDespachadas
            openModal= {openModalFiles}
            idFactura = {idFactura}
            handleCloseModal = {handleCloseModalFiles}
          />}
        </Modal>
      </>
    )
  } else {
    return(
      <>
        <Modal
          visible={props.openModal}
          closable={false}
          width='100%'
          title = {null}
          onCancel={handleCloseModal}
          footer={
            <div className='modal-footer d-flex justify-content-between'>
              <Button danger onClick={handleCloseModal} icon={<CloseOutlined />}>
                Cerrar
              </Button>
            </div>
          }
        >
          <div>
            No hay Órdenes de Compra
          </div>
        </Modal>
      </>
    )
  }
}

export default ModalOrdenesCompraDespachadas