import { CloseOutlined, DeleteOutlined, FilePdfOutlined, 
  PlusOutlined, SaveOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal,
  Select, message, Card, Table, Popconfirm } from 'antd';
import { Rule } from 'antd/lib/form';
import { Option } from 'antd/lib/mentions';
import {useEffect, useRef, useState} from "react";
import ModalTipoCambio from '../ModalTipoCambio';
import ModalProveedor from '../componentesProveedores/ModalProveedor';
import { PdfFileItemsConsolidado } from '../componentesPDF/GenerarPDFItemsConsolidado';
import { ExcelFileItemsConsolidado } from '../componentesExcel/GenerarExcelItemsConsolidado';

const { Item } = Form;

const rules = {
  required: { required: true, message: <></> } as Rule
};

const ModalConsolidadoForm = (props) => {
  const [loadingButton, setLoadingButton] = useState(false)
  const [openModalTipoCambio, setOpenModalTipoCambio] = useState(false)
  const [consolidado, setConsolidado] = useState({
    usuario: props.usuario
  });
  const [detalleConsolidado, setDetalleConsolidado] = useState([])
  const [requConsolidados, setRequConsolidados] = useState([])
  const [idProveedor, setIdProveedor] = useState(null)
  const [openModalProveedor, setOpenModalProveedor] = useState(false);
  const [bancosList, setBancosList] = useState([])
  const [departamentosList, setDepartamentosList] = useState([])
  const [provinciasList, setProvinciasList] = useState([])
  const [provinciasSubList, setProvinciasSubList] = useState([])
  const [distritosList, setDistritosList] = useState([])
  const [distritosSubList, setDistritosSubList] = useState([])

  const [form] = Form.useForm();

  const componentePDF = useRef(null)

  const handleCloseModal = () => {
    props.setOpenModal(false)
    props.setIdConsolidado(null)

    setConsolidado({
      usuario: props.usuario,
    })

    setRequConsolidados([])
    setDetalleConsolidado([])
    form.setFieldsValue({
      requerimientos: undefined
    })
  }

  useEffect(() => {
    if (props.idConsolidado === null) {
      setConsolidado({
        usuario: props.usuario,
      })
      setRequConsolidados([])
      setDetalleConsolidado([])
      form.setFieldsValue({
        requerimientos: undefined
      })
    } else {
      getConsolidadoById(props.idConsolidado)
      getDetalleReqConsolidadoById(props.idConsolidado)
      getDetalleConsolidadoByIdConsolidado(props.idConsolidado)
    }
  }, [props.idConsolidado]);

  useEffect(() => {
    getBancos()
    getDepartamentos()
    getProvincias()
    getDistritos()
  }, []);

  const getBancos = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getBancos`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setBancosList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getDepartamentos = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getDepartamentos`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setDepartamentosList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getProvincias = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getProvincias`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setProvinciasList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getDistritos = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getDistritos`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setDistritosList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getConsolidadoById = async(id_consolidado) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getConsolidadoById/${id_consolidado}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setConsolidado({
          usuario: data.usuario,
        })
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getDetalleReqConsolidadoById = async(id_consolidado) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getDetalleReqConsolidadoById/${id_consolidado}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        let reqs = []
        for (let i = 0; i < data.length; i++) {
          // console.log(data[i].id_requerimiento)
          reqs = [...reqs, data[i].id_requerimiento]
        }

        setRequConsolidados(reqs)
        form.setFieldsValue({
          requerimientos: reqs
        })
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getDetalleConsolidadoByIdConsolidado = async(id_consolidado) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getDetalleConsolidadoByIdConsolidado/${id_consolidado}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        let lista = []
        for (let i = 0; i < data.length; i++) {
          const item = props.materialesList.filter( articulo => {
            return( articulo.id_articulo === data[i].id_articulo)
          })
          lista = [...lista,{
            ...data[i], stock_disponible: item[0].stock_disponible,
          }]
        }
        setDetalleConsolidado(lista)
        
      })
    } catch (error) {
      console.log(error)
    }
  }

  const requerimientosChange = (e) => {
    // setRequConsolidados(e)
    // console.log(e, e.length)
    if (e.length > 1) {
      const req0 = props.requerimientosList.filter(requerimiento => {
        return(requerimiento.id_requerimiento === e[0])
      })

      const req = props.requerimientosList.filter(requerimiento => {
        return(requerimiento.id_requerimiento === e[e.length-1])
      })
      
      // console.log(req[0].id_rubro, req0[0].id_rubro)
      if (req[0].id_rubro === req0[0].id_rubro && req[0].tipo === req0[0].tipo) {
        setRequConsolidados(e)
        // getDetallesReqsByIdsRequerimiento(e)
      } else {
        form.setFieldsValue({requerimientos: requConsolidados})
        message.warn(`Los Requerimientos deben pertenecer al mismo Rubro: ${req0[0].nombre_rubro} y Tipo`)
      }

    } else {
      // console.log(e)
      setRequConsolidados(e)
      // getDetallesReqsByIdsRequerimiento(e)
    }
  }

  const getDetallesReqsByIdsRequerimiento = async(idsReq) => {
    // console.log(idsReq)
    try {
      setLoadingButton(true)
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getDetallesReqsByIdsRequerimiento`,{
        method: "POST",
        body: JSON.stringify(idsReq),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data => {
        setLoadingButton(false)
        // console.log(data)
        if (data.mensaje) {
          setDetalleConsolidado([])
        } else {
          // setDetalleConsolidado(data)
          let lista = []
          for (let i = 0; i < data.length; i++) {
            const item = props.materialesList.filter( articulo => {
              return( articulo.id_articulo === data[i].id_articulo)
            })

            lista = [...lista,{
              id_unidad: data[i].id_unidad,
              cantidad: data[i].cantidad,
              precio: 1,
              id_articulo: data[i].id_articulo,
              descripcion: data[i].descripcion,
              marca: null,
              cantidad_unidades: data[i].cantidad_unidades,
              nombre_material: data[i].nombre_material,
              nombre_unidad: data[i].nombre_unidad,
              stock_disponible: item[0].stock_disponible,
              index: i+1
            }]
          }
          setDetalleConsolidado(lista)
        }
      }).catch(err => {
        setLoadingButton(false)
        console.log(err)
      });
    } catch (error) {
      setLoadingButton(false)
      console.log(error)
    }
  }

  const nombreRubro = () => {
    if (requConsolidados.length > 0) {
      const rubro = props.requerimientosList.filter(req => {
        return(req.id_requerimiento === requConsolidados[0])
      })
      // console.log(rubro)
      return(rubro[0].nombre_rubro)
    } else {
      return(null)
    }
  }

  const articulosFiltrados = () => {
    // console.log(requConsolidados[0])
    if (requConsolidados.length > 0) {
      const requ = props.requerimientosList.filter(req => {
        return(req.id_requerimiento === requConsolidados[0])
      })
      const lista = props.materialesList.filter(articulo => {
        return (requ[0].id_rubro === null?
          articulo.id_rubro !== requ[0].id_rubro: articulo.id_rubro === requ[0].id_rubro) 
      })

      return lista  
    } else {
      return []
    }
  }

  const AddDetalleCon = () => {
    if (requConsolidados.length > 0) {
      setDetalleConsolidado([...detalleConsolidado, {
        id_unidad: null,
        cantidad: 1,
        precio: 1,
        id_articulo: null,
        descripcion: null,
        marca: null,
        cantidad_unidades: null,
        stock_disponible: null
      }])  
    } else {
      message.warn('Seleccionar Requerimientos')
    }
  }

  const detalleConsChange = (value, campo, item) => {
    const listaItems = detalleConsolidado
    const key = listaItems.indexOf(item)
    listaItems[key][campo] = value

    if (campo === 'id_articulo') {
      const item = props.materialesList.filter( articulo => {
        return( articulo.id_articulo === value)
      })

      listaItems[key].stock_disponible = item[0].stock_disponible
    }
    
    setDetalleConsolidado([...listaItems])
  }

  const detalleConsChangeUnidadCantidad = (value, campo, item) => {
    const listaItems = detalleConsolidado
    const key = listaItems.indexOf(item)

    if (campo === 'cantidad') {
      listaItems[key][campo] = value
    } else {
      listaItems[key][campo] = value
      const unidad = props.unidadesList.filter(uni => {
        return uni.id_unidad === value
      })
      listaItems[key].cantidad_unidades = unidad[0].cantidad_unidades
    }

    setDetalleConsolidado([...listaItems])
  }

  const RemoveDetalleCons = (item) => {
    const listaItems = detalleConsolidado.filter(detalle => {
      return(detalle !== item)
    })
    // console.log(listaItems)
    setDetalleConsolidado(listaItems)
  }

  const AddConsolidado = async() => {
    if (detalleConsolidado.length === 0) {
      message.error('Debe Ingresar los Materiales Requeridos')
    } else {
      const body = {consolidado: {...consolidado},
        detalleConsolidado: detalleConsolidado,
        requConsolidados: requConsolidados
      }

      const validarDetalle = detalleConsolidado.filter(detalle => {
        return detalle.id_unidad === null || detalle.id_articulo === null
      })

      if (validarDetalle.length > 0) {
        message.error('Faltan campos en el detalle de Equipos')
      } else {
        try {
          setLoadingButton(true)
          await fetch(`${process.env.REACT_APP_HOSTNAME}/AddConsolidado`,{
            method: "POST",
            body: JSON.stringify(body),
            headers:{
              'Content-Type': 'application/json'
            }
          }).then(res => res.json())
          .then(data => {
            message.success("Consolidado Registrado")
            setLoadingButton(false)
            props.getConsolidados()
            handleCloseModal()
          }).catch(err => {
            console.log(err)
            setLoadingButton(false)
          });
        } catch (error) {
          console.log(error)
          setLoadingButton(false)
        }
      }
    }
  }

  const EditConsolidado = async() => {
    if (detalleConsolidado.length === 0) {
      message.error('Debe Ingresar los Materiales Requeridos')
    } else {
      const body = {consolidado: {...consolidado},
        detalleConsolidado: detalleConsolidado,
        requConsolidados: requConsolidados
      }

      const validarDetalle = detalleConsolidado.filter(detalle => {
        return detalle.id_unidad === null || detalle.id_articulo === null
      })

      if (validarDetalle.length > 0) {
        message.error('Faltan campos en el detalle de Equipos')
      } else {
        try {
          setLoadingButton(true)
          await fetch(`${process.env.REACT_APP_HOSTNAME}/UpdateConsolidado`,{
            method: "PUT",
            body: JSON.stringify(body),
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(res => res.json())
          .then(data => {
            // console.log(data)
            setLoadingButton(false)
            message.success(data.mensaje)
            props.getConsolidados()
            handleCloseModal()
          }).catch(err => {
            console.log(err)
            setLoadingButton(false)
          });  
        } catch (error) {
          console.log(error)
          setLoadingButton(false)
        }
      }
    }
  }

  const columns = [
    {
      title: (
        <>
          <Button type='primary' icon={<PlusOutlined />}
            onClick={e => AddDetalleCon()} 
          />
        </>
      ),
      render: record => {
        return(
          <>
            <Popconfirm
              title="¿Quitar Ítem?"
              onConfirm={e=>RemoveDetalleCons(record)} 
              okText="Si" cancelText="No"
            >
              <Button danger title="Quitar" 
                icon={<DeleteOutlined />} />    
            </Popconfirm>
          </>
        )
      }
    },
    {
      title: (<div style={{width:250}} className="mr-4">MATERIAL/EQUIPO</div>),
      key: 'id_articulo',
      render: record => {
        return(
          <>
            <Select
              showSearch
              value={record.id_articulo}
              className='mb-lg-0'
              style={{width:250}}
              placeholder='Material/Equipo'
              optionFilterProp='children'
              filterOption={props.optionFilter}
              onChange={e => detalleConsChange(e, "id_articulo", record)}
            >
              {articulosFiltrados().map(material => {
                return(
                  <Option value={material.id_articulo} 
                    key={material.id_articulo}>
                    {material.nombre}
                  </Option>
                )
              })}
            </Select>
          </>
        )
      }
    },
    {
      title: 'STOCK DISP.',
      key: 'stock_disponible',
      dataIndex: 'stock_disponible',
      align: 'right' as 'right',
    },
    {
      title: 'DESCRIPCIÓN',
      key: 'descripcion',
      render: record => {
        return(
          <>
            <Input name='descripcion' value={record.descripcion}
              onChange={e=>detalleConsChange(e.currentTarget.value, "descripcion", record)}
            />
          </>
        )
      }
    },
    {
      title: 'MARCA',
      key: 'marca',
      render: record => {
        return(
          <>
            <Input name='marca' value={record.marca}
              onChange={e=>detalleConsChange(e.currentTarget.value, "marca", record)}
            />
          </>
        )
      }
    },
    {
      title: <div style={{width:140}} className="mr-4">PRESENTACIÓN</div>,
      key: 'id_unidad',
      render: record => {
        return(
        <>
          <Select
            showSearch
            value={record.id_unidad}
            className='mb-lg-0'
            style={{width:140}}
            placeholder='Unidad de Medida'
            optionFilterProp='children'
            filterOption={props.optionFilter}
            onChange={e => detalleConsChangeUnidadCantidad(e,"id_unidad", record)}
          >
            {props.unidadesList.map(unidad => {
              return(
                <Option value={unidad.id_unidad} 
                  key={unidad.id_unidad}>
                  {unidad.nombre} X {unidad.cantidad_unidades}
                </Option>
              )
            })}
          </Select> 
        </>
        )
      }
    },
    {
      title: <div style={{width:120}} className="mr-4">CANTIDAD</div>,
      key: 'cantidad',
      render: record => {
        return(
          <>
            <Input type="number" name='cantidad' value={record.cantidad} style={{width: 120}}
              onChange={e=> detalleConsChangeUnidadCantidad(e.currentTarget.value, "cantidad", record)}
            />
          </>
        )
      }
    },
    {
      title: 'T. UNID.',
      render: record => {
        return(
          <>
            <b>{record.cantidad_unidades * record.cantidad}</b>
          </>
        )
      },align: 'right' as 'right',
    }
  ]

  const filtrarReqs = () => {
    // console.log(props.requerimientosList)
    const lista = props.requerimientosList.filter(req => {
      return (req.estado === 'ANALISIS' || req.estado === 'PROCESO')
    })

    return lista
  }

  const ExportarPDF = () => {
    if (detalleConsolidado.length > 0) {
      componentePDF.current.save()
    }
  }

  const GetListaItems = () => {
    // console.log(requConsolidados)
    getDetallesReqsByIdsRequerimiento(requConsolidados)
  }

  return(
    <>
      <Modal
        visible={props.openModal}
        closable={false}
        width='95%'
        title={<h3 className='m-0'>{props.tituloModal}</h3>}
        onCancel={handleCloseModal}
        footer={null}
      >
        {PdfFileItemsConsolidado(componentePDF, detalleConsolidado, requConsolidados, props.requerimientosList)}
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <Form form={form} layout='vertical' onFinish={ props.idConsolidado === null? AddConsolidado : EditConsolidado}>
              <div className='elem-list'>
                <div className='col-sm-10 col-md-10 col-lg-10'>
                  <Item label="Requerimientos (código-área-rubro-tipo)" name="requerimientos" rules={[
                      {...rules.required, message: 'Requerimientos Requeridos'}
                  ]}>
                    <Select
                      showSearch
                      disabled={loadingButton}
                      defaultValue={requConsolidados}
                      className='mb-lg-0'
                      placeholder='Seleccionar Requerimientos'
                      optionFilterProp='children'
                      mode='multiple'
                      filterOption={props.optionFilter}
                      onChange={e=>requerimientosChange(e)}
                    >
                    {filtrarReqs().map(requerimiento =>{
                      return(
                        <Option value={requerimiento.id_requerimiento} key={requerimiento.id_requerimiento}>
                          {requerimiento.codigo} - {requerimiento.nombre_area} - {requerimiento.nombre_rubro} - {requerimiento.tipo}
                        </Option>
                      )
                    })}
                    </Select>
                  </Item>  
                </div>

                <div className='col-sm-1 col-md-1 col-lg-1'>
                  <br/>
                  <Button type='primary' icon={<ReloadOutlined />} className='mt-2'
                    title='Actualizar Lista de Ítems' onClick={e => GetListaItems()} 
                  />
                </div>
              </div>

              <div className='col-sm-12 col-md-12 col-lg-12'>
                <Card title="Equipos/Materiales">
                  <div className='d-flex justify-content-between mb-2'>
                    <label style={{fontWeight:'bold'}}>Rubro: {nombreRubro()}</label>

                    <div>
                      <Button danger 
                        title="Exportar PDF" className='mr-2'
                        onClick={e => ExportarPDF()}
                        icon={<FilePdfOutlined />} >PDF
                      </Button>  
                      {ExcelFileItemsConsolidado(detalleConsolidado)}
                    </div>
                    
                  </div>

                  <Table 
                    pagination={false} 
                    columns={columns}
                    dataSource={detalleConsolidado}
                    size="small"
                  />
                </Card>
              </div>

              <div className='modal-footer d-flex justify-content-between'>
                <Button danger onClick={handleCloseModal} icon={<CloseOutlined />}>
                  Cerrar
                </Button>
                
                <Button className='bg-color-success' loading={loadingButton}
                  type='primary' icon={<SaveOutlined />} htmlType="submit">
                  Guardar
                </Button>
              </div>
            </Form>

          </div>
        </div>
        {<ModalTipoCambio
        openModal = {openModalTipoCambio}
        loadingButtonAprobar = {loadingButton}
        setLoadingButtonAprobar = {setLoadingButton}
        setTabla = {setConsolidado}
        tabla = {consolidado}
        usuario = {props.usuario}
        setOpenModal = {setOpenModalTipoCambio}
      />}

      {<ModalProveedor
        idProveedor = {idProveedor}
        setIdProveedor = {setIdProveedor}
        openModal = {openModalProveedor}
        tituloModal = {'Nuevo Proveedor'}
        rubrosList = {props.rubrosList}
        bancosList = {bancosList}
        usuario = {props.usuario}
        setOpenModal = {setOpenModalProveedor}
        getProveedores = {props.getProveedores}
        departamentosList = {departamentosList}
        provinciasList = {provinciasList}
        provinciasSubList = {provinciasSubList}
        distritosList = {distritosList}
        distritosSubList = {distritosSubList}
        setProvinciasSubList = {setProvinciasSubList}
        setDistritosSubList = {setDistritosSubList}
      />}
      </Modal>
    </>
  )
}

export default ModalConsolidadoForm