import pieChart from "../../charts/chart-js/options/pie-chart";
import { Pie } from "react-chartjs-2";
import {useEffect, useRef, useState} from "react";
import { Card, Table, Button, Input, Form, message } from "antd";
import moment from "moment";

const GraficosControlCombustible = (props) => {
  // const [backgroundColor, setBackgroundColor] = useState([])

  // useEffect(() => {
  //   // const interval = setInterval(() => {
  //     if (backgroundColor.length === 0) {
  //       setBackgroundColor(props.vehiculosList.map(vehiculo => { return `#${Math.floor(Math.random()*16777215).toString(16)}`}))
  //     }
  //   // }, 10000);
  //   // return () => clearInterval(interval);
  // }, [])

  const valesListFiltrados = () => {
    const lista = props.valesList.filter(vale => {
      return( moment.utc(vale.fecha_vale).format("YYYY-MM-DD") >= props.fechaInicio && 
        moment.utc(vale.fecha_vale).format("YYYY-MM-DD") <= props.fechaFin)
    })

    return lista
  } 

  const CamposGraficoVehiculos = () => {
    const labelsVehiculos = props.vehiculosList.map(vehiculo => { return vehiculo.nombre.concat(` ${vehiculo.placa}`)})
    const dataValesByVehiculos = labelsVehiculos.map(vehiculo => { 
      let cantVales = 0
      for (let i = 0; i < valesListFiltrados().length; i++) {
        const element = valesListFiltrados()[i]
        if (element.vehiculo === vehiculo) {
          cantVales++
        }
      }
      return cantVales
    })

    return {labelsVehiculos, dataValesByVehiculos}
  }

  const CamposGraficoCombustible = () => {
    const labelsCombustibles = props.combustiblesList.map(combustible => { return combustible.nombre})
    const dataValesByCombustibles = labelsCombustibles.map(combustible => {
      let cantVales = 0
      for (let i = 0; i < valesListFiltrados().length; i++) {
        const element = valesListFiltrados()[i];
        if (element.nombre === combustible) {
          cantVales++
        }
      }
      return cantVales
    })

    return {labelsCombustibles, dataValesByCombustibles}
  }

  const CamposGraficoConductor = () => {
    const labelsConductores = props.conductoresList.map(conductor => { return conductor.nombres.concat(` ${conductor.apellidos}`)} )
    const dataValesByConductores = labelsConductores.map(conductor => {
      let cantVales = 0
      for (let i = 0; i < valesListFiltrados().length; i++) {
        const element = valesListFiltrados()[i];
        if (element.conductor === conductor) {
          cantVales++
        }
      }
      return cantVales
    })
    return {labelsConductores, dataValesByConductores}
  }

  const coloresVehiculos = {
    backgroundColor: ['#475FBF','#CD320C','#FF7D37','#086100','#43028C','#00ADB8','#C5005D','#32A702','#A80404'],
    // backgroundColor: backgroundColor,
    borderColor: []
  };

  const coloresCombustibles = {
    backgroundColor: ['#C51B1B','#2741C2','#E34A0C'],
    borderColor: []
  };

  const coloresConductores = {
    backgroundColor: ['#C51B1B','#2741C2','#E34A0C'],
    borderColor: []
  };

  const dataGraficoVehiculos = {
    data: {
      labels: CamposGraficoVehiculos().labelsVehiculos,
      type: 'pie',
      datasets: [
        {
          data: CamposGraficoVehiculos().dataValesByVehiculos,
          ...coloresVehiculos
        }
      ]
    }
  }

  const dataGraficoCombustibles = {
    data: {
      labels: CamposGraficoCombustible().labelsCombustibles,
      type: 'pie',
      datasets: [
        {
          data: CamposGraficoCombustible().dataValesByCombustibles,
          ...coloresCombustibles
        }
      ]
    }
  }

  const dataGraficoConductores = {
    data: {
      labels: CamposGraficoConductor().labelsConductores,
      type: 'pie',
      datasets: [
        {
          data: CamposGraficoConductor().dataValesByConductores,
          ...coloresConductores
        }
      ]
    }
  }

  return(
    <>
      <div className="row">
        <div className='col-sm-12 col-md-12 col-lg-6'>
          <Card title='Vehículos'>
            <Pie {...dataGraficoVehiculos} />
          </Card>
        </div>

        <div className='col-sm-12 col-md-12 col-lg-6'>
          <Card title='Combustibles'>
            <Pie {...dataGraficoCombustibles} />
          </Card>
        </div>
      </div>

      <div className="row">
        <div className='col-sm-12 col-md-12 col-lg-6'>
          <Card title='Conductores'>
            <Pie {...dataGraficoConductores} />
          </Card>
        </div>
      </div>
      
    </>
  )
}

export default GraficosControlCombustible