import { CheckOutlined, CloseOutlined, FilePdfOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Modal, Table, 
  Popconfirm, message, Input } from 'antd';
  import { useState, useEffect, useRef } from "react";
  import moment from "moment";
  import { DownloadFile, existeRol, getTipoCambioToday } from '../FuncionesGenerales';
import ModalTipoCambio from '../ModalTipoCambio';
import { PdfFileOrden } from '../componentesPDF/GenerarPDFOrden';

  const ModalConsolidadoDetalle = (props) => {
    const input = useRef<HTMLInputElement>(null);
    
    const [consolidado, setConsolidado] = useState({
      id_consolidado: null,
      Parc_Valor: null,
      fecha_consolidado: null,
      parv_descripcion: null,
      TipCambio: null,
      descripcion: null,
      nombre_comercial: null,
      ruc: null,
      req_consolidados: null,
      rubro: null,
      ubicacion_archivo: null,
      aprobado: null,
      ubicacion_archivo_comparativo: null,
      total: null,
      nombre_usuario_aprobacion: null,
      CantOrden: null,
      id_proveedor: null,
      direccion: null
    });
    const [detalleConsolidado, setDetalleConsolidado] = useState([])
    const [rolFinanzas, setRolFinanzas] = useState(false);
    const [permisoAprobarLogistica, setpermisoAprobarLogistica] = useState(false);
    const [permisoAprobarGerencia, setpermisoAprobarGerencia] = useState(false);
    const [tipoCambioHoy, setTipoCambioHoy] = useState({TipCambio: 1})
    const [openModalTipoCambio, setOpenModalTipoCambio] = useState(false);
    const [loadingButtonAprobar, setLoadingButtonAprobar] = useState(false);
    const [itemPDF, setItemPDF] = useState({
      nombre_area: null,
      id_consolidado: null,
      nombre_responsable: null,
      id_orden_compra: null,
      numero_orden: null
    });
    const [detalleCuentas, setDetalleCuentas] = useState([])
    const componentePDF = useRef(null)

    useEffect(() => {
      if (props.openModal === true) {
        // console.log(props.idConsolidado)
        getConsolidadoById(props.idConsolidado)
        getDetalleConsolidadoByIdConsolidado(props.idConsolidado)
        setRolFinanzas(existeRol('Finanzas.Aprobar.Requerimiento'))
        setpermisoAprobarLogistica(existeRol('Logistica.Aprobar.Cotizacion'))
        setpermisoAprobarGerencia(existeRol('Gerencia.Aprobar.Cotizacion'))
        getCambioDolar()
      }
    }, [props.openModal]);

    const handleClick = () => input.current.click();

    const handleCloseModal = () => {
      props.setOpenModal(false)
    }

    const getConsolidadoById = async(id_consolidado) => {
      try {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/getConsolidadoById/${id_consolidado}`)
        .then(res => res.json())
        .then(data => {
          // console.log(data)
          setConsolidado(data)
        })
      } catch (error) {
        console.log(error)
      }
    }

    const getDetalleConsolidadoByIdConsolidado = async(id_consolidado) => {
      try {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/getDetalleConsolidadoByIdConsolidado/${id_consolidado}`)
        .then(res => res.json())
        .then(data => {
          // console.log(data)
          setDetalleConsolidado(data)
          
        })
      } catch (error) {
        console.log(error)
      }
    }

    const getCambioDolar = async() => {
      const cambioToday = await getTipoCambioToday()
      if (cambioToday.message) {
        message.warning("No se ha Registrado el tipo de cambio de hoy", 5)
        handleOpenModalTipoCambio()
      } else {
        setTipoCambioHoy({TipCambio: cambioToday[0].TipCambio})
      }
    }

    const handleOpenModalTipoCambio = () => {
      setOpenModalTipoCambio(true)
    }

    const handleCloseModalTipoCambio = async() => {
      setOpenModalTipoCambio(false)
    }

    const botonAprobar = (consolidado) => {
      // console.log(consolidado.total, tipoCambioHoy.tipoCambio)
      // console.log(permisoAprobarLogistica, rolFinanzas, permisoAprobarGerencia, 
      //   consolidado.parv_descripcion, tipoCambioHoy.tipoCambio, consolidado.total)
      if ( consolidado.parv_descripcion === 'SOLES' ) { /**HASTA S/.1500 Y ES LOGISTICA */
        if ( consolidado.total <= 1500 && (permisoAprobarLogistica === true || 
          rolFinanzas === true || permisoAprobarGerencia === true) ) {
            // console.log('Log')
          return true
        } else {/**ENTRE LOS S/.1500 Y LOS $10000 (PASADO A S/.) Y ES FINANZAS */
          if ( consolidado.total / tipoCambioHoy.TipCambio <= 10000 && consolidado.total > 1500 && 
            (rolFinanzas === true || permisoAprobarGerencia === true) ) {
            // console.log('Finanza')
            return true
          } else {/** ENTRE LOS 10000 HASTA LOS 25000 DOLARES Y ES GERENCIA */
            if ( consolidado.total / tipoCambioHoy.TipCambio >= 10000 && consolidado.total / tipoCambioHoy.TipCambio <= 25000
              && permisoAprobarGerencia === true ) {
              // console.log('Gerencia')
              return true
            } else {
              return false
            }
          }
        }
      } else {
        if (consolidado.total * tipoCambioHoy.TipCambio <= 1500 && (permisoAprobarLogistica === true 
          || rolFinanzas === true || permisoAprobarGerencia === true) ) {
          return true
        } else {
          if (consolidado.total <= 10000 && consolidado.total * tipoCambioHoy.TipCambio > 1500 && 
            (rolFinanzas === true || permisoAprobarGerencia === true) ) {
            return true
          } else {
            if ( consolidado.total >= 10000 && consolidado.total <= 25000 && permisoAprobarGerencia === true ) {
              return true
            } else {
              return false
            }
          }
        }
      }
    }

    const columns = [
      { title: 'MATERIAL/EQUIPO', key: 'nombre_articulo', dataIndex: 'nombre_articulo' },
      { title: 'DESCRIPCION', key: 'descripcion', dataIndex: 'descripcion' },
      { title: 'MARCA', key: 'marca', dataIndex: 'marca' },
      { 
        title: 'PRESENTACIÓN', 
        render : record => {
          return(<>{record.nombre_unidad} X {record.cantidad_unidades}</>)
        }
      },
      { title: 'CANTIDAD', key: 'cantidad', dataIndex: 'cantidad' },
      { 
        title: 'PRECIO', 
        render : record => {
          return(<>{consolidado.Parc_Valor} {record.precio}</>)
        }
      },
      {
        title: 'TOTAL',
        render: record => {
          return(
            <>
              <b>{consolidado.Parc_Valor} {record.cantidad * record.precio}</b>
            </>
          )
        }
      }
    ]

    const Total = () => {
      return(
        <>
          <div className='d-flex justify-content-between mr-4'>
            <b>Total</b>
            <b className='mr-4'>
              {consolidado.Parc_Valor} { detalleConsolidado.length === 0? 0:
                detalleConsolidado.map(det => det.cantidad * det.precio).reduce((prev, next) => prev + next)
              }
            </b>
          </div>
        </>
      )
    }

    const reqList = () => {
      if (consolidado.req_consolidados === null) {
        return('')
      } else {
        const listaReq = JSON.parse(consolidado.req_consolidados)
        let codigos 
        for (let i = 0; i < listaReq.length; i++) {
          if (i === 0) {
            codigos = listaReq[i].codigo
          } else {
            codigos = codigos.concat(', ', listaReq[i].codigo)
          }
        }
        return(codigos)
      }
    }

    const rubroConsolidado = () => {
      if (consolidado.rubro === null) {
        return('')
      } else {
        const rubro = JSON.parse(consolidado.rubro)
        return(rubro[0].nombre)
      }
    }

    const subirArchivo = (e) => {
      // console.log(e[0].name)
      setConsolidado({...consolidado, ubicacion_archivo_comparativo: e})
    }

    const subirComparativo = async() => {
      const body = {
        destination:'Logistica',
        filename:`ConsolidadoComparativo_${consolidado.id_consolidado}.pdf`,
        id_consolidado: consolidado.id_consolidado,
        usuario_aprobacion: props.usuario,
        nombre_usuario_aprobacion: consolidado.nombre_usuario_aprobacion
      }

      const bodyForm = new FormData()

      /**AREGAR EL ARCHIVO AL FINAL */
      bodyForm.append('tabla', JSON.stringify(body))
      bodyForm.append('File', consolidado.ubicacion_archivo_comparativo[0])

      try {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/UploadFileComparativaConsolidado`,{
          method: "POST",
          body: bodyForm
        }).then(res => {
          message.success("Comparativa de Consolidado Subida", 5)
          props.getConsolidados()
          handleCloseModal()
        }).catch(err => {
          console.log(err)
          message.error('No se Pudo Subir el Archivo al Servidor')
        });
      } catch (error) {
        
      }
    }

    const AprobarConsolidado = async(consolidado, valor_aprobado) => {
      const body = { ...consolidado, aprobado: valor_aprobado, usuario_aprobacion: props.usuario }
      setLoadingButtonAprobar(true)
      try {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/AprobarConsolidado`,{
          method: "PUT",
          body: JSON.stringify(body),
          headers:{
            'Content-Type': 'application/json'
          }
        }).then(res => res.json())
        .then(data => {
          setLoadingButtonAprobar(false)
          props.getConsolidados()
          handleCloseModal()
        }).catch(err => console.log(err));
      } catch (error) {
        console.log(error)
      }
    }

    const ExportarPDF = async() => {
      try {
        const newCorrelativo = await fetch(`${process.env.REACT_APP_HOSTNAME}/getCorrelativoNuevoOrden`)
        .then(res => res.json())
        .then(data => {
          // console.log(data)
          if (data.message) {
            return ([])
          } else {
            // setDetalleRepr(data)
            return (data)
          }
        }).catch(err => {return []})

        const reprProv = await fetch(`${process.env.REACT_APP_HOSTNAME}/getRepresentantesByIdProveedor/${consolidado.id_proveedor}`)
        .then(res => res.json())
        .then(data => {
          if (data.message) {
            return ([])
          } else {
            // setDetalleRepr(data)
            return (data)
          }
        }).catch(err => {return []})

        const findEmail = reprProv.filter(det => {return (det.defecto === true)})
        // console.log(findEmail)

        const cuentas = await fetch(`${process.env.REACT_APP_HOSTNAME}/getCuentasProveedor/${consolidado.id_proveedor}`)
        .then(res => res.json())
        .then(data => {
          if (data.message) {
            return ([])
          } else {
            // setDetalleCuentas(data)
            return (data)
          }
          // console.log(data)
        })

        setItemPDF({
          ...consolidado,
          ...findEmail[0],
          id_orden_compra: '',
          numero_orden: newCorrelativo[0].correlativoNewOrden,
          dias_plazo: null,
          lugar_ejecucion: null,
          penalidades: null
        })
        setDetalleCuentas(cuentas)
        componentePDF.current.save()
        // console.log(itemPDF)
      } catch (error) {
        console.log(error)
      }
    }

    return(
      <>
        {PdfFileOrden(componentePDF, itemPDF, detalleConsolidado, detalleCuentas)}
        <Modal
          visible={props.openModal}
          closable={false}
          width='100%'
          title={
            <div className='modal-footer d-flex justify-content-between'>
              <div>
                <b style={{fontSize:"20px"}}>Requerimientos: {reqList()}</b>
              </div>

              <div>
                <b style={{fontSize:"20px"}}>Rubro: {rubroConsolidado()}</b>
              </div>
            </div>
          }
          onCancel={handleCloseModal}
          footer={
            <div className='modal-footer d-flex justify-content-between'>
              <Button danger onClick={handleCloseModal} icon={<CloseOutlined />}>
                Cerrar
              </Button>
            </div>
          }
        >
          <div className='row mt-4'>
            <div className='col-sm-12 col-md-12 col-lg-12'>
              <div className='elem-list'>
                <div className='col-sm-11 col-md-2 col-lg-2'>
                  <label className='mb-1' style={{fontWeight:'bold'}}>Fecha:</label>
                  <br />
                  <label>
                    {consolidado.fecha_consolidado === null?'NO COTIZADO':moment(consolidado.fecha_consolidado).format('DD/MM/YYYY')}
                  </label>  
                </div>

                <div className='col-sm-11 col-md-2 col-lg-2'>
                  <label className='mb-1' style={{fontWeight:'bold'}}>Moneda:</label>
                  <br />
                  <label>{consolidado.parv_descripcion}</label>  
                </div>

                <div className='col-sm-11 col-md-2 col-lg-2'>
                  <label className='mb-1' style={{fontWeight:'bold'}}>T. Cambio:</label>
                  <br />
                  <label>{consolidado.TipCambio}</label>  
                </div>

                <div className='col-sm-11 col-md-2 col-lg-2'>
                  {consolidado.ubicacion_archivo === null?null:
                  <><label className='mb-1' style={{fontWeight:'bold'}}>Cotización:</label>
                  <br />
                  <Button danger title="Descargar Archivo"  
                    onClick={e => DownloadFile('Consolidado', consolidado, consolidado.id_consolidado)}
                    icon={<FilePdfOutlined />} >
                  </Button></>}
                </div>

                <div className='col-sm-11 col-md-2 col-lg-2'>
                  { consolidado.descripcion === null && consolidado.CantOrden > 0 && consolidado.aprobado !== 'SI'?null:
                  <><label className='mb-1' style={{fontWeight:'bold'}}>Exportar Nueva OC/S:</label>
                  <br />
                  <Button danger title="Descargar Archivo"  
                    onClick={e => ExportarPDF()}
                    icon={<FilePdfOutlined />} >
                  </Button></>}
                </div>
              </div>

              <div className='elem-list'>
                <div className='col-sm-11 col-md-5 col-lg-5'>
                  <label className='mb-1' style={{fontWeight:'bold'}}>Proveedor Razón Social:</label>
                  <br />
                  <label>{consolidado.descripcion === null?'PENDIENTE':consolidado.descripcion}</label>  
                </div>

                <div className='col-sm-11 col-md-4 col-lg-4'>
                  <label className='mb-1' style={{fontWeight:'bold'}}>Nombre Comercial:</label>
                  <br />
                  <label>{consolidado.nombre_comercial}</label>  
                </div>

                <div className='col-sm-11 col-md-2 col-lg-2'>
                  <label className='mb-1' style={{fontWeight:'bold'}}>RUC:</label>
                  <br />
                  <label>{consolidado.ruc}</label>  
                </div>
              </div>

              <div className='col-sm-12 col-md-12 col-lg-12'>
                <Table 
                  pagination={false} 
                  columns={columns}
                  dataSource={detalleConsolidado}
                  size="small"
                  footer={() => Total()}
                />
              </div>

              <div className='elem-list'>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                  {permisoAprobarLogistica === true && botonAprobar(consolidado) === false && consolidado.aprobado === null? 
                    consolidado.ubicacion_archivo_comparativo === null || consolidado.aprobado === null ?
                      <>
                        <b className='mr-4'>{consolidado.ubicacion_archivo_comparativo === null?null:
                          consolidado.ubicacion_archivo_comparativo[0] === undefined?null:consolidado.ubicacion_archivo_comparativo[0].name}</b>
                        <br/>
                        <input ref={input} onChange={(e) => subirArchivo(e.target.files)} type='file' style={{ display: 'none' }} />
                        <Button size='small' type={'primary'} className='btn-outline mr-2' onClick={handleClick} icon={<FilePdfOutlined />}
                          disabled = {consolidado.aprobado === 'NO'? true: false}
                          >
                          Subir PDF Aprobación
                        </Button> 
                        <br/>
                        <b className='mr-4'>Aprobado por:</b>
                        <Input defaultValue={consolidado.nombre_usuario_aprobacion} placeholder = "¿Quién lo aprobó?"
                          onChange={e=>setConsolidado({...consolidado, nombre_usuario_aprobacion: e.currentTarget.value})}
                        />

                        <Button className='bg-color-success' loading={props.loadingButton}
                          type='primary' icon={<SaveOutlined />} onClick={subirComparativo}>
                          Guardar
                        </Button>
                      </>
                    :
                    <Button danger className='mb-2'
                      title="Descargar Comparativo" 
                      onClick={e => DownloadFile('CotizacionComparativo', {
                        ...consolidado, otraUbicacion:consolidado.ubicacion_archivo_comparativo
                      }, consolidado.id_consolidado)}
                      icon={<FilePdfOutlined />} >
                    </Button>
                  :
                  consolidado.ubicacion_archivo_comparativo !== null? 
                      <Button danger className='mb-2'
                        title="Descargar Comparativo" loading={loadingButtonAprobar}
                        onClick={e => DownloadFile('CotizacionComparativo', {
                          ...consolidado, otraUbicacion:consolidado.ubicacion_archivo_comparativo
                        }, consolidado.id_consolidado)}
                        icon={<FilePdfOutlined />} >
                      </Button>
                  :null}

                  {botonAprobar(consolidado) === true && consolidado.aprobado === null? 
                    <>
                      <b style={{fontSize:"18px"}} className="mr-2">Aprobar: </b>
                      {consolidado.fecha_consolidado !==null?
                        <>
                          <Popconfirm
                            title="¿Seguro de Aprobar este Consolidado?"
                            onConfirm={e=>AprobarConsolidado(consolidado, "SI")}
                            okText="Si" cancelText="No"
                          >
                            <Button type='primary' className='bg-color-success mr-4'
                              // loading = {loading} title="Aprobar Consolidado"
                              icon={<CheckOutlined />}>Si</Button> 
                          </Popconfirm>

                          <Popconfirm
                            title="¿Seguro de Desaprobar este Consolidado?"
                            onConfirm={e=>AprobarConsolidado(consolidado, "NO")} 
                            okText="Si" cancelText="No"
                          >
                            <Button danger 
                              loading = {loadingButtonAprobar} title="Desaprobar Consolidado"
                              icon={<CloseOutlined />}>No</Button> 
                          </Popconfirm>
                        </>
                      :'FALTA COTIZAR'}
                    </>
                    :
                    consolidado.ubicacion_archivo_comparativo !== null && permisoAprobarLogistica === false? 
                      <Button danger className='mb-2'
                        title="Descargar Comparativo" 
                        onClick={e => DownloadFile('CotizacionComparativo', {
                          ...consolidado, otraUbicacion:consolidado.ubicacion_archivo_comparativo
                        }, consolidado.id_consolidado)}
                        icon={<FilePdfOutlined />} >
                      </Button>
                  :null}
                </div>
              </div>
            </div>  
          </div>
          
          {<ModalTipoCambio
            openModal = {openModalTipoCambio}
            handleCloseModal = {handleCloseModalTipoCambio}
            loadingButtonAprobar = {loadingButtonAprobar}
            setLoadingButtonAprobar = {setLoadingButtonAprobar}
            setTabla = {setConsolidado}
            tabla = {consolidado}
            usuario = {props.usuario}
            setOpenModal = {setOpenModalTipoCambio}
          />}
        </Modal>
      </>
    )
  }

  export default ModalConsolidadoDetalle